import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProjectConfigurationComponent } from "./configuration.component";
import { ConfigurationResolver } from "./configuration.resolver";
import { ElementsConfigurationComponent } from "./steps/elements/elements.component";
import { ElementsGuard } from "./steps/elements/elements.guard";
import { ConfigurationStepExportsComponent } from "./steps/exports/exports.component";
import { ExportsGuard } from "./steps/exports/exports.guard";
import { FluxConfigurationComponent } from "./steps/flux/flux.component";
import { FluxGuard } from "./steps/flux/flux.guard";
import { ConfigurationStepProjectComponent } from "./steps/project/project.component";
import { ProjectGuard } from "./steps/project/project.guard";
import { ConfigurationStepTemplateComponent } from "./steps/template/template.component";
import { TemplateGuard } from "./steps/template/template.guard";
import { WorkflowConfigurationComponent } from "./steps/workflow/workflow.component";
import { WorkflowGuard } from "./steps/workflow/workflow.guard";

const projectConfiguration: Routes = [
    {
        path: "",
        component: ProjectConfigurationComponent,
        children: [
            {
                path: "general",
                component: ConfigurationStepProjectComponent,
                resolve: { project: ProjectGuard },
            },
            {
                path: "elements",
                component: ElementsConfigurationComponent,
                resolve: { elements: ElementsGuard },
            },
            {
                path: "exports",
                component: ConfigurationStepExportsComponent,
                resolve: { exports: ExportsGuard },
            },
            {
                path: "workflows",
                component: WorkflowConfigurationComponent,
                resolve: { workflow: WorkflowGuard },
            },
            {
                path: "templates",
                component: ConfigurationStepTemplateComponent,
                resolve: { templates: TemplateGuard },
            },
            {
                path: "flux",
                component: FluxConfigurationComponent,
                resolve: { flux: FluxGuard },
            }
        ],
        resolve: { data: ConfigurationResolver }
    }
];

@NgModule({
    imports: [RouterModule.forChild(projectConfiguration)],
    exports: [RouterModule],
})
export class ProjectConfigurationRoutingModule { }
