import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faEye, faSave } from "@fortawesome/free-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { GroupService } from "app/core/services/admin/group/group.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { DataService } from "app/core/services/global/data/data.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { MaestroProject } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { ProjectDocument } from "app/shared/models/project-document";
import { environment } from "src/environments";
import { ProjectConfigurationStepperService } from "../../../../../../../core/services/project/project/stepper.service";
import { ConfigurationStepComponent } from "../../../../../../../shared/models/project/step-component.interface";

@Component({
    selector: "app-configuration-step-project",
    templateUrl: "./project.component.html",
    styleUrls: ["./project.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigurationStepProjectComponent extends ConfigurationStepComponent<MaestroProject> implements OnInit {
    project: MaestroProject;
    faSave = faSave;
    faTimes = faEye;
    activeRoute: number;
    resources: any;
    form: FormGroup;
    submitted: boolean;
    @ViewChild("groupDisplayModal", { static: true }) private groupDisplayModal: SwalComponent;

    /*
    projectGroups: any = [];
    isGroupLoad: boolean = false;
    auths: any
    */
    userGroups: any = []; // User's groups list with children from admin
    childrenFromOwnerGroup: any = []; // Extracted children

    ownerGroup: any = null; // Project's owner group id (nullable)
    associatedGroups: any = []; // Project's groups list filtered without owner group
    acl: ACL;
    mergedSelectableGroups: any = [];
    base64Files: any = [];
    conversionCompleted = true;
    @ViewChild("fileInput") fileInput!: ElementRef;
    selectedFiles: FileList | null = null;
    validExtensions: any = [];
    projectUrl: string;

    displaySwal: any;

    @Output() eventSubmitted = new EventEmitter<any>();

    constructor(
        private formBuilder: FormBuilder,
        private projectService: ProjectService,
        private _route: ActivatedRoute,
        private _stepper: ProjectConfigurationStepperService,
        private router: Router,
        private _dataService: DataService,
        private _userService: UserService,
        private _groupService: GroupService,
        private _translateService: TranslateService,
        private _swalModalService: SwalModalService
    ) {
        super();
        this.form = new FormGroup({
            documents: new FormControl(),
        });
    }

    ngOnInit(): void {
        this.acl = this._userService.getUserAclFromToken();
        this.resources = this._route.snapshot.data.project;
        this.project = this.resources.project;
        this.userGroups = this.resources.userGroups;
        this.ownerGroup = this.project.idOwnerGroup;
        this.projectUrl = environment.projectsUrl;

        let mergedData;

        if (this.ownerGroup) {
            const owner = this.resources.projectGroups.find((g) => g.id === this.ownerGroup);

            if (owner) {
                mergedData = [...[owner], ...this.userGroups];
            } else {
                mergedData = [...this.userGroups];
            }
        } else {
            mergedData = [...this.resources.projectGroups, ...this.userGroups];
        }

        let uniqueSelectableGroups = this._dataService.uniqueData(mergedData);
        this.mergedSelectableGroups = uniqueSelectableGroups.sort((a, b) => a.name.localeCompare(b.name));

        this.form = this._initForm();
        // setTimeout(() => this.switchStepper(), 100);
        this._stepper.setCurrentStep(0);
        // this.auths = this.initAuths();
        this.filterAssociatedGroups();
        // this.form.value.description = this.formValueDescriptionWithoutTags;
    }

    handleTextTooltip() {
        if (this.form.controls["title"].invalid && this.form.controls["description"].invalid) {
            return this._translateService.instant("general.obligatory");
        } else if (this.form.controls["title"].invalid) {
            return `${this._translateService.instant("general.fieldObligatory")} ${this._translateService.instant("projects.conf.title")} ${this._translateService.instant("general.isRequired")}`;
        } else if (this.form.controls["description"].invalid) {
            return `${this._translateService.instant("general.fieldObligatory")} ${this._translateService.instant("projects.conf.description")} ${this._translateService.instant(
                "general.isRequired"
            )}`;
        }
    }

    openPopupGroup(type: number) {
        this.displaySwal = "";
        if (type === 1) {
            const idGroup = this.ownerGroup;
            this._groupService.findById(idGroup).subscribe((requestGroup) => {
                const arrayUsers = requestGroup.data.users;
                this.displaySwal += "<strong>" + requestGroup.data.name + "</strong>";
                this.displaySwal +=
                    '<table class="table-user"><thead></thead><tr><th>' + this._translateService.instant("table.name") + "</th><th>" + this._translateService.instant("table.email") + "</th></tr>";
                arrayUsers.forEach((user) => {
                    this.displaySwal += "<tr><td>" + user.name + "</td><td><a href='mailto:" + user.data["email"] + "'>" + user.data["email"] + "</a></td></tr>";
                });
                this.displaySwal += "</table>";
            });
            this.groupDisplayModal.fire();
        } else if (type === 2) {
            const idGroups = this.form.value.associatedGroups;
            idGroups.forEach((idGroup) => {
                this._groupService.findById(idGroup).subscribe((requestGroup) => {
                    const arrayUsers = requestGroup.data.users;
                    this.displaySwal += "<strong>" + requestGroup.data.name + "</strong>";
                    this.displaySwal +=
                        '<table class="table-user"><thead></thead><tr><th>' + this._translateService.instant("table.name") + "</th><th>" + this._translateService.instant("table.email") + "</th></tr>";
                    arrayUsers.forEach((user) => {
                        this.displaySwal += "<tr><td>" + user.name + "</td><td><a href='mailto:" + user.data["email"] + "'>" + user.data["email"] + "</a></td></tr>";
                    });
                    this.displaySwal += "</table><br>";
                });
            });
            this.groupDisplayModal.fire();
        }
    }

    /**
     * Init form
     *
     * @returns
     */
    protected _initForm(): FormGroup {
        return this.formBuilder.group({
            title: [this.project.title, Validators.required],
            description: [this.project.description, [Validators.required, Validators.max]],
            productsImport: [this.project.productsImport],
            ownerGroup: [{ value: [], disabled: !this.mergedSelectableGroups.length || !this.acl.MAESTRO_PROJECT_PROJECTS_UPDATE }],
            associatedGroups: [{ value: [], disabled: true }],
        });
    }

    isDeactivable(): boolean {
        return this.form.pristine;
    }

    onSubmit() {
        this.submitted = true;

        if (this.form.valid) {
            this._submit();
        }
    }

    onBack() {
        this.router.navigate(["projects"]);
    }

    switchStepper(): void {
        if (this.form.value.productsImport) {
            this._stepper.withProduct.next(true);
            // this._stepper.setStepsWithProducts();
        } else {
            this._stepper.withProduct.next(false);
            // this._stepper.setStepsNoProduct();
        }

        this._stepper.setSteps();
    }

    protected _formToObject(form: FormGroup): MaestroProject {
        return {
            title: form.value.title.trim(),
            description: form.value.description.trim(),
            productsImport: form.value.productsImport,
            idUser: this.project.idUser,
        };
    }

    /**
     * Save project's information
     */
    private _submit() {
        const formOwnerGroup = this.form.value.ownerGroup;
        const ownerGroup = formOwnerGroup && formOwnerGroup.length ? formOwnerGroup[0] : null;
        const associatedGroups = this.form.value.associatedGroups;

        const project = {
            lastStep: this._stepper.isLastStep(),
            data: {
                id: this.project.id,
                ...this._formToObject(this.form),
                groupIds: {
                    ownerId: ownerGroup,
                    associatedIds: associatedGroups ? associatedGroups : [],
                },
                documents: this.base64Files,
            },
        };

        return this.projectService.update(project.data.id, project).subscribe((data) => {
            this.project.idOwnerGroup = this.ownerGroup = ownerGroup;
            this.project.title = project.data.title;
            this.project.description = project.data.description;
            this.project.projectDocuments = data.data.projectDocuments;
            this.projectService.withProduct = this.form.value.productsImport;
            this.project.productsImport = this.form.value.productsImport;
            this.selectedFiles = null;
            this.submitted = false;
            this._stepper.validStep(0);
            this.eventSubmitted.emit(project);
            this._stepper.goToNext(project.data.id);
        });
    }

    setDescription($event): void {
        this.form.controls.description.setValue($event);
    }

    // isCheck(indexGroup, code){
    //     let result = false;
    //     if(this.projectGroups.length > 0){
    //         let group = this.projectGroups[indexGroup];
    //         if(group.auths !== null){
    //             // let auth = this.project.auth[indexAuth];
    //             result = group.auths.includes(code);
    //         }
    //     }

    //     return result;
    // }

    // changeAuthGroup(indexGroup, code){
    //     if(this.projectGroups[indexGroup].auths === null){
    //         this.projectGroups[indexGroup].auths = [];
    //     }
    //     if(!this.projectGroups[indexGroup].auths.includes(code)){
    //         this.projectGroups[indexGroup].auths.push(code);
    //     }
    //     else{
    //         let index = this.projectGroups[indexGroup].auths.indexOf(code);
    //         this.projectGroups[indexGroup].auths.splice(index, 1);
    //     }
    // }

    // onChange($event){
    //     if(this.isGroupLoad){
    //         $event.forEach(group => {
    //             if(!this.projectGroups.find(projGroup => projGroup.id == group.id)){
    //                 group['auths'] = null;
    //                 this.projectGroups.push(group);
    //             }
    //         });

    //         this.projectGroups.forEach(projGroup => {
    //             if(!$event.find(group => projGroup.id == group.id)){
    //                 let index = this.projectGroups.findIndex(group => group.id == projGroup.id);
    //                 this.projectGroups.splice(index,1);
    //             }
    //         });
    //     }
    // }

    // initAuths(){
    //     const auths = []
    //     this.resources.auth.forEach(auth => {
    //         auths.push({
    //             code: auth,
    //             action: auth.split('_')[2]+"_"+auth.split('_')[3]
    //         });
    //     });
    //     return auths;
    // }

    filterAssociatedGroups() {
        if (this.ownerGroup) {
            let projectGroups = [...this.resources.projectGroups];

            const owner = projectGroups.find((g) => g.id === this.ownerGroup);

            if (owner) {
                this.setOwnerGroup(owner.id);

                let ownerData = this.userGroups.find((g) => {
                    return g.id === owner.id;
                });

                if (ownerData) {
                    this.childrenFromOwnerGroup = this._groupService.getRecursiveGroups(ownerData.data); // Get full level of sub-groups
                } else {
                    let childrenFromOwnerGroup = projectGroups.filter((group) => group.id !== this.ownerGroup);
                    this.childrenFromOwnerGroup = this._groupService.getRecursiveGroups(childrenFromOwnerGroup);
                }

                const associated = projectGroups.filter((g) => g.id !== this.ownerGroup).map((g) => g.id);

                this.form.get("associatedGroups").setValue(associated);

                if (owner || associated.length) {
                    this.form.controls["associatedGroups"].enable();
                }
            }
        } else if (1 === this.userGroups.length && !this.project.idUser) {
            this.setOwnerGroup(this.userGroups[0].id);

            this.childrenFromOwnerGroup = this.userGroups[0].data;

            this.setAssociatedGroups();
        }
    }

    defineAssociableGroups(event: any) {
        this.ownerGroup = event.value[0] ? event.value[0] : null;

        this.form.get("associatedGroups").reset("");

        if (this.ownerGroup) {
            let firstLevelchildrenFromOwnerGroup = this.mergedSelectableGroups.find((g) => g.id === this.ownerGroup).data;
            this.childrenFromOwnerGroup = this._groupService.getRecursiveGroups(firstLevelchildrenFromOwnerGroup);

            if (this.childrenFromOwnerGroup.length) {
                this.setAssociatedGroups();
            }
        } else {
            this.form.controls["associatedGroups"].disable();
        }
    }

    setOwnerGroup(id: number) {
        this.form.get("ownerGroup").setValue([id]);
    }

    setAssociatedGroups() {
        const associated = this.childrenFromOwnerGroup.map((g) => g.id);
        this.form.get("associatedGroups").setValue(associated);
        this.form.controls["associatedGroups"].enable();
    }

    onFileSelect(event, isFromDrag = false) {
        const allowedExtensions = ["doc", "docx", "xls", "xlsx", "pdf", "txt", "csv", "png", "jpg", "jpeg", "gif"];

        const files = isFromDrag ? event.dataTransfer.files : (event.target as HTMLInputElement).files;
        this.selectedFiles = files;
        this.form.patchValue({ documents: this.selectedFiles });

        this.conversionCompleted = false;
        for (let i = 0; i < files.length; i++) {
            const extension = files[i].name.split(".").pop().toLowerCase();
            if (allowedExtensions.indexOf(extension) !== -1) {
                this.validExtensions[i] = true;
                this._dataService.convertFile(files[i]).subscribe((base64) => {
                    this.base64Files.push({ filename: files[i].name, mimeType: files[i].type, size: files[i].size, base64: base64 });
                });
            } else {
                this.validExtensions[i] = false;
            }
        }
        this.conversionCompleted = true;
    }

    removeFile(index: number) {
        const files = [];
        for (let i = 0; i < this.selectedFiles.length; i++) {
            if (i !== index) {
                files.push(this.selectedFiles[i]);
            }
        }
        this.selectedFiles = files.length ? Object.assign(files) : null;
        this.form.patchValue({ documents: this.selectedFiles });
        if (this.base64Files.length) {
            this.base64Files.splice(index, 1);
        }
    }

    removeUploadedFile(projectDocument: ProjectDocument) {
        this._swalModalService.delete().then((result) => {
            if (result.value) {
                return this.projectService.deleteProjectDocument(projectDocument.id).subscribe(() => {
                    for (let i = 0; i < this.project.projectDocuments.length; i++) {
                        if (this.project.projectDocuments[i].id === projectDocument.id) {
                            this.project.projectDocuments.splice(i, 1);
                        }
                    }
                });
            }
        });
    }

    changeDropZoneStyle(isDragOver: boolean) {
        const dropZone = document.getElementById("dropZone");
        if (isDragOver) {
            dropZone?.classList.add("drag-over");
        } else {
            dropZone?.classList.remove("drag-over");
        }
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        this.onFileSelect(event, true);
    }

    onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = "copy";
        this.changeDropZoneStyle(true);
    }

    onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.changeDropZoneStyle(false);
    }

    onClick() {
        this.fileInput.nativeElement.click();
    }

    openDocument(id: number, filename: string) {
        this.projectService.openDocument(id, filename);
        return false;
    }
}
