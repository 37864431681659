import { Injectable } from "@angular/core";
import { CanActivate, Resolve, UrlTree } from "@angular/router";
import { UserService } from "app/core/services/admin/user/user.service";
import { MaestroUsers } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { Observable } from "rxjs";

@Injectable()
export class UserListGuard implements CanActivate, Resolve<MaestroUsers> {
    constructor(private _service: UserService) {}

    resolve() {
        return this._service.getAll();
    }
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return (this._service.getUserAclFromToken() as ACL).SYSPAD_ADMIN_USER_LIST === 1;
    }
}
