import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { ThumbnailMenu } from "app/shared/models/project/thumbnail";

@Component({
    selector: "app-page-thumbnail-menu",
    templateUrl: "./page-thumbnail-menu.component.html",
    styleUrls: ["./page-thumbnail-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageThumbnailMenuComponent {
    @Input() disabled: boolean;
    @Input() menu: ThumbnailMenu;
    @Output() menuClick: EventEmitter<any> = new EventEmitter();

    readonly faCog = faCog;
}
