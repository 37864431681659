import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { TagListComponent, TagListResolver } from "./list";

const tagsRoutes: Routes = [
    { path: "", component: TagListComponent, resolve: { tags: TagListResolver } },
    { path: ":id", component: TagListComponent, resolve: { tags: TagListResolver }, runGuardsAndResolvers: "always" },
];

@NgModule({
    imports: [RouterModule.forChild(tagsRoutes)],
    exports: [RouterModule],
})
export class TagRoutingModule {}
