<div class="card mb-2 p-0">
    <div class="card-header text-center d-flex">
        <ng-container *ngIf="!updateName">
            <h4 class="font-weight-bold text-primary mb-0 pb-0 mx-auto">{{ dataModel.name }}</h4>
            <fa-icon (click)="updateName = true" class="text-warning font-lg pointer" [icon]="faEdit"></fa-icon>
        </ng-container>
        <ng-container *ngIf="updateName">
            <input class="form-control form-control-sm col-9 col-md-4 mx-auto text-center font-weight-bolder" type="text" [(ngModel)]="dataModel.name">
            <fa-icon *ngIf="dataModel.name !== nameAtOpening" (click)="saveName()" class="text-primary font-lg pointer" [icon]="faSave"></fa-icon>
            <fa-icon (click)="updateName = false" class="text-danger font-lg pointer ml-2" [icon]="faClose"></fa-icon>
        </ng-container>
    </div>

    <div class="card-body p-0">
        <div class="form-group mx-3 row mt-3">
            <label class="col-3 col-form-label font-weight-bold">{{ 'dam.types' | translate }}</label>
            <div class="col-9">
                <ng-multiselect-dropdown (onSelect)="setTypes()" (onDeSelect)="setTypes()" placeholder="{{ 'general.select' | translate }}" [(ngModel)]="dataModel.types" [ngModelOptions]="{ standalone: true }" [settings]="settings" [data]="types" id="multiple-select-tags" class="tags-selector"></ng-multiselect-dropdown>
            </div>
        </div>

        <form [formGroup]="formArray" class="mt-2">
            <div class="table-responsive">
                <table class="table table-striped mb-0">
                    <thead>
                        <tr>
                            <th style="border: 0;" class="text-center text-truncate" scope="col">{{ "pim.elementTypes.fieldName" | translate }}</th>
                            <th style="border: 0;" class="text-center text-truncate" scope="col">{{ "pim.elementTypes.fieldType" | translate }}</th>
                            <th style="border: 0;" class="text-center text-truncate" scope="col">{{ "pim.elementTypes.fieldPreviewable" | translate }}</th>
                            <th style="border: 0;" class="text-center text-truncate" scope="col">{{ "table.actions" | translate }}</th>
                            <!-- @TODO: To implement in ts and back
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{ "pim.elementTypes.mandatory" | translate }}</th>
                            -->
                        </tr>
                    </thead>
                    <tbody [appSortable]="sortableJSOptions">
                        <tr *ngFor="let control of formArray.controls; let i = index">
                            <td style="min-width: 10rem;">
                                <input class="form-control" [ngClass]="control.controls.name.valid ? 'is-valid' : 'is-invalid'" type="text" [formControl]="control.controls.name">
                                <div *ngIf="control.controls.name.invalid && !control.controls.name.pristine" class="invalid-feedback">
                                    <span *ngIf="control.controls.name.hasError('required')">{{ "pim.elementTypes.error.required" | translate }}</span>
                                </div>
                            </td>
                            <td style="min-width: 10rem;">
                                <select class="form-control" [ngClass]="control.controls.fieldType.valid ? 'is-valid' : 'is-invalid'" [formControl]="control.controls.fieldType">
                                    <option *ngFor="let fieldType of fieldTypes | filterType:control.controls.fieldType.value:(control.controls.id.value !== -1)" [value]="fieldType">{{ "fieldType." + fieldType | translate}}</option>
                                </select>
                                <div *ngIf="control.controls.fieldType.invalid && !control.controls.fieldType.pristine" class="invalid-feedback">
                                    <span *ngIf="control.controls.fieldType.hasError('required')">{{ "pim.elementTypes.error.required" | translate }}</span>
                                </div>
                            </td>
                            <td class="text-center">
                                <label class="switch switch-success mb-0" style="padding-top: 5px;">
                                    <input type="checkbox" class="switch-input" [formControl]="control.controls.previewable">
                                    <span class="switch-slider"></span>
                                </label>
                            </td>
                            <!-- @TODO: To implement in ts and back
                            <td class="text-center">
                                <label class="switch switch-success mb-0" style="padding-top: 5px;">
                                    <input type="checkbox" class="switch-input" [formControl]="control.controls.mandatory">
                                    <span class="switch-slider"></span>
                                </label>
                            </td>
                            -->
                            <td class="d-flex text-center">
                                <button class="ml-auto btn btn-danger" (click)="deleteField(i)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                                <button class="btn btn-primary ml-1 sortable mr-auto">
                                    <fa-icon [icon]="faSort"></fa-icon>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="formArray.controls.length === 0">
                            <td colspan="7" class="text-center font-weight-bold">{{ "general.no.data" | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
    <div class="card-footer">
        <div class="row">
            <span class="ml-auto"></span>
            <button class="btn btn-primary" *ngIf="isOnePreview()" (click)="setAllNoPreview()">
                <fa-icon [icon]="faNoPreview"></fa-icon>
                <span class="d-none d-md-inline-block ml-1">{{ 'general.field.nopreview' | translate }}</span>
            </button>
            <button class="btn btn-primary ml-2" *ngIf="isOneNoPreview()" (click)="setAllPreview()">
                <fa-icon [icon]="faPreview"></fa-icon>
                <span class="d-none d-md-inline-block ml-1">{{ 'general.field.preview' | translate }}</span>
            </button>
            <div class="btn-group ml-2">
                <button class="btn btn-primary" (click)="addField()">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.field.add' | translate }}</span>
                </button>
                <button [disabled]="formArray.invalid || formArray.controls.length === 0 || formArray.pristine" class="btn btn-primary" (click)="saveTab()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
