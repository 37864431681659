<form [formGroup]="form">
    <div class="form-group">
        <div class="form-group row">
            <label for="title" class="col-sm-4 col-form-label"
                [ngClass]="{ 'text-danger': submitted && form.controls['title'].invalid }">
                {{ 'projects.conf.title' | translate }}
                <span  class="red-stars">*</span>
            </label>
            <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="title"
                    [ngClass]="{ 'is-invalid': form.controls['title'].invalid }" />
                <small *ngIf="!form.controls['title'].pristine && form.controls['title'].hasError('required')"
                    class="text-danger form-text first-letter">
                    {{ 'general.required' | translate }}
                </small>
            </div>
        </div>
        <div class="form-group row">
            <label for="description" class="col-sm-4 col-form-label">
                {{ 'projects.conf.description' | translate }}
                <span  class="red-stars">*</span>
            </label>
            <div class="col-sm-8">
               <textarea [ngClass]="{ 'is-invalid': form.controls['description'].invalid }" class="form-control" (focusOut)="setDescription($event)" formControlName="description" [value]="form.value.description" cols="100" rows="3"></textarea>
                <small
                    *ngIf="!form.controls['description'].pristine && form.controls['description'].hasError('required')"
                    class="text-danger">
                    {{ 'general.required' | translate }}
                </small>
            </div>
        </div>
        <div class="form-group row">
            <label for="ownerGroup" class="col-sm-3 col-form-label">
                {{ 'admin.groups.owner' | translate }}
            </label>
            <div class="col-sm-1" style="text-align: right;">
                <button *ngIf="ownerGroup" style="margin-top: 10px;" class="btn btn-primary btn-primary-filter btn-sm" type="button" (click)="openPopupGroup(1)">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
            </div>
            <div class="col-sm-8">
                <!-- @TODO: See console warning and fix it -->
                <p-multiSelect formControlName="ownerGroup" [options]="mergedSelectableGroups" optionLabel="name" optionValue="id" [selectionLimit]="1" defaultLabel="{{ (userGroups.length ? 'admin.groups.selectOwner' : 'admin.groups.noGroup') | translate }}" [style]="{'width':'100%'}" (onChange)="defineAssociableGroups($event)"></p-multiSelect>
            </div>
        </div>
        <div class="form-group row">
            <label for="associatedGroups" class="col-sm-3 col-form-label">
                {{ 'admin.groups.associated' | translate }}
            </label>
            <div class="col-sm-1" style="text-align: right;">
                <button *ngIf="form.value['associatedGroups'] && form.value['associatedGroups'] != ''" style="margin-top: 10px;" class="btn btn-primary btn-primary-filter btn-sm" type="button" (click)="openPopupGroup(2)">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
            </div>
            <div class="col-sm-8">
                <!-- @TODO: See console warning and fix it -->
                <p-multiSelect formControlName="associatedGroups" [options]="childrenFromOwnerGroup" optionLabel="name" optionValue="id" defaultLabel="{{ (childrenFromOwnerGroup.length ? 'admin.groups.selectAssociated' : 'admin.groups.noGroup') | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label">
                {{ 'projects.conf.documents' | translate }}
            </label>
            <div class="col-sm-8">
                <div class="drop-zone" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (click)="onClick()">
                    <span>{{ 'projects.conf.dropbox' | translate }}</span>
                    <!--<input type="file" name="documents" multiple (change)="onFileSelected($event)"> -->
                    <input type="file" name="documents" multiple #fileInput id="documents" (change)="onFileSelect($event)" accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, .csv, .png, .jpg, .jpeg, .gif"/>
                </div>
                <ul *ngIf="selectedFiles">
                    <li *ngFor="let file of selectedFiles; let i = index">
                        {{ file.name }}
                        <button (click)="removeFile(i)" class="delete-button">x</button>
                        <span *ngIf="!validExtensions[i]" class="alert-msg">{{ 'projects.conf.invalid-extension' | translate }}</span>
                    </li>
                </ul>
                <ul *ngIf="project.projectDocuments">
                    <li *ngFor="let doc of project.projectDocuments; let i = index">
                        <a href="#" (click)="openDocument(doc.id, doc.filename);">{{ doc.filename }}</a>
                        <button (click)="removeUploadedFile(doc)" class="delete-button">×</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="productsImport" class="col-sm-4 col-form-label">
            {{ 'projects.conf.import' | translate }}
        </label>
        <label class="switch switch-success col-1">
            <input type="checkbox" class="form-control switch-input" formControlName="productsImport" (change)="switchStepper()">
            <span class="switch-slider" style="width: 40px;"></span>
        </label>
        <!-- <div class="col-sm-10">
        </div> -->
    </div>

    <div class="row">
        <div class="col-6 text-left">
            <p class="red-stars text-size">{{ "general.obligatory" | translate }}</p>
            <div class="btn-group">
                <button class="btn btn-danger" (click)="onBack()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-2">{{ 'general.cancel' | translate }}</span>
                </button>
            </div>
        </div>
        <div class="col-6 text-right">
            <div class="btn-group">
                <button  [ngbTooltip]="handleTextTooltip()" class="btn btn-primary" [disabled]="form.invalid || form.value.description === '<p></p>' || conversionCompleted === false"
                    (click)="onSubmit()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>

</form>

<swal #groupDisplayModal [showConfirmButton]="false">
    <div *swalPortal>
        <span [innerHtml]="displaySwal"></span>
    </div>
</swal>
