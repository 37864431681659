import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class DownloadJsxService {
    private _enableSource = new BehaviorSubject<boolean>(false);
    private _enabled: boolean;

    readonly enable$: Observable<boolean> = this._enableSource.asObservable();

    /**
     * Set enable to determine if we want to display a search bar in the breacrumb
     */
    set enable(a: boolean) {
        if (this._enabled !== a) {
            this._enableSource.next(a);
        }
    }
}
