import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { FieldsetListComponent } from "./fieldset-list.component";
import { FieldsetListGuard } from "./fieldset-list.guard";
import { FieldsetListResolver } from "./fieldset-list.resolver";

@NgModule({
    imports: [SharedModule, SharedComponentsModule],
    declarations: [FieldsetListComponent],
    exports: [],
    providers: [FieldsetListGuard, FieldsetListResolver],
})
export class FieldsetListModule {}
