import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { TemplateService } from "app/core/services/project/template/template.service";
import { IMaestroEntity } from "app/shared/models";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TwigCreateModule } from "./twig-create.module";

@Injectable({
    providedIn: TwigCreateModule,
})
export class TwigCreateGuard implements CanActivate, Resolve<IMaestroEntity[]> {
    constructor(private _service: TemplateService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<IMaestroEntity[]> {
        const twigId = route.params.id;
        if (twigId) {
            return forkJoin([this._service.getExportTypes().pipe(map((r) => r.data)), this._service.getTwig(twigId).pipe(map((r) => r.data))]);
        }
        return this._service.getExportTypes().pipe(map((r) => r.data));
    }
}
