import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ThumbsnailContainerComponent } from "./thumbsnail-container.component";
import { ThumbsnailContainerResolver } from "./thumbsnail-container.resolver";

@NgModule({
    imports: [SharedModule, SharedComponentsModule],
    declarations: [ThumbsnailContainerComponent],
    providers: [ThumbsnailContainerResolver],
})
export class ThumbsnailContainerModule { }