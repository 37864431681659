import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ProjectService } from "app/core/services/project/project/project.service";
import { TemplateConfigurationService } from "app/core/services/project/project/template.service";
import { forkJoin, Observable } from "rxjs";
import { concatMap, defaultIfEmpty, map } from "rxjs/operators";
import { TemplateConfigurationModule } from "./template.module";

@Injectable({
    providedIn: TemplateConfigurationModule,
})
export class TemplateGuard implements CanActivate, Resolve<any> {
    constructor(private _service: TemplateConfigurationService, private projectService: ProjectService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const projectId = route.parent.parent.parent.params.id;

        return forkJoin([
            this._service.getConfigurations(projectId),
            this.projectService.getById(projectId),
            // this.projectService.getAuths(projectId),
        ]).pipe(
            concatMap((data) =>
                forkJoin([
                    this._service.getFieldsByElementTypes(
                        projectId,
                        (data[0] as any).data.map((config) => config.id ? config.id : 0)
                    ),
                ]).pipe(
                    defaultIfEmpty(<any>{
                        projectId: projectId,
                        elTypes: (data[0] as any).data,
                        project: data[1],
                        fieldListByType: [],
                        fieldsetListByType: [],
                        // auths: (data[2] as any).data
                    }),
                    map(
                        (templatesResult) =>
                            <any>{
                                projectId: projectId,
                                elTypes: (data[0] as any).data,
                                project: data[1],
                                fieldListByType: templatesResult[0],
                                // auths: (data[2] as any).data
                            }
                    )
                )
            )
        );
    }
}
