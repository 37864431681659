import { Component, OnDestroy } from "@angular/core";
import { faIndent } from "@fortawesome/free-solid-svg-icons";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { MenuItems } from "app/shared/components/breadcrumb";
import { MAESTRO_ROUTES as routes } from "app/shared/routes/index";

@Component({
    template: "<router-outlet></router-outlet>",
})
export class ProjectsComponent implements OnDestroy {
    private readonly _baseRoute = `/${routes.projects.base}/`;

    /**
     * Cog items
     */
    readonly menuItems: MenuItems = [
        {
            icon: faIndent,
            routerLink: this._baseRoute + routes.projects.list,
            text: "project.projects.title",
        },
        {
            icon: faIndent,
            routerLink: this._baseRoute + routes.projects.templates,
            text: "project.templates.title",
        },
        // {
        //     icon: faIndent,
        //     routerLink: this._baseRoute + routes.projects.twig,
        //     text: "project.twig.title",
        // },
        {
            icon: faIndent,
            routerLink: this._baseRoute + routes.projects.variables,
            text: "project.variables.title",
        },
    ];

    constructor(private _layout: LayoutService) {
        this._layout.menu.items = this.menuItems;
        this._layout.breadcrumb.setPath({ routerLink: `/${routes.projects.base}`, name: "breadcrumb.project.home" }, 0);
    }

    ngOnDestroy() {
        this._layout.menu.items = null;
        this._layout.breadcrumb.setPath(null, 0);
    }
}
