import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { NgModule } from "@angular/core";
import { DashboardViewComponent } from "./features/dashboard-view/dashboard-view.component";
import { DashboardViewResolver } from "./features/dashboard-view/dashboard-view.resolver";

const dashboardRoutes: Routes = [
    {
        path: "",
        component: DashboardComponent,
        children: [
            {
                path: "",
                component: DashboardViewComponent,
                resolve: { dashboard: DashboardViewResolver },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(dashboardRoutes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
