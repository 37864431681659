import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { VariableListComponent } from "./list/variable-list.component";




const variableRouting: Routes = [
    {
        path: "",
        component: VariableListComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(variableRouting)],
    exports: [RouterModule],
})
export class VariableRoutingModule {}
