import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { ListCascadeService } from "app/core/services/pim/listcascade.service";
import { Observable } from "rxjs";

@Injectable()
export class ListCascadeListResolver implements Resolve<any> {
    constructor(private service: ListCascadeService) {}

    resolve(): Observable<any> {
        return this.service.getListCascades();
    }
}
