import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {Router} from '@angular/router';
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "app/shared/models";
import {Subscription} from "rxjs";
import {SidebarService} from "../../..";
import {FormDisplayedService} from "app/core/services/global/layout/form-displayed.service";

@Component({
    selector: "app-sidebar-routing-form",
    templateUrl: "./sidebar-routing-form.component.html",
    styleUrls: ["./sidebar-routing-form.component.scss"],
})
export class SidebarRoutingFormComponent implements OnInit, OnDestroy {
    @Output() validSubmit: EventEmitter<Tab> = new EventEmitter();
    @Output() delete: EventEmitter<Tab> = new EventEmitter();
    @Input() currentMode: string;
    @Input() aclOptions: {
        delete: number;
        edit: number;
        create: number;
    };
    @Input() allOptions;
    private _errorSubmit: Subscription;
    controlInput: boolean;
    private _currentNametabs: Subscription;
    nameTabs;
    formDisplayed: boolean
    private previousNameTabs: string;
    currentName: string;

    constructor(fb: FormBuilder, private sidebarService: SidebarService, private formDisplayedService: FormDisplayedService) {
        this.tabForm = fb.group({
            [this.tabNameInput]: ["", Validators.required],
        });
    }

    optionName = [];

    ngOnInit(): void {
        this._errorSubmit = this.sidebarService.errorSubmit$.subscribe((def) => this.controlInput = def);
        this._currentNametabs = this.sidebarService.tabName$.subscribe((def) => {
            this.nameTabs = def;
            if (this.previousNameTabs !== this.nameTabs) {
                this.previousNameTabs = this.nameTabs;
                this.tabForm.get(this.tabNameInput).setValue(this.nameTabs);

            }
        });
        this.formDisplayedService.formDisplayed$.subscribe(value => {
            this.formDisplayed = value;
        });

        this.sidebarService.getCurrentTabName.subscribe(data => {
            this.currentName = data;
        })


    }

    ngOnDestroy() {
        this._currentNametabs.unsubscribe();
    }

    readonly faPlus = faPlus;
    readonly faEdit = faEdit;
    readonly faTrashAlt = faTrashAlt;

    readonly tabForm: FormGroup;
    readonly tabNameInput = "tabForm";


    onCancel() {
        this.sidebarService.errorSubmit = false;
        this._closeForm();
    }

    onCreate() {
        this.tabForm.get(this.tabNameInput).setValue("");
        this._openForm();
        this._changeMode("create");
    }

    onEdit() {
        if (this.tabForm.get(this.tabNameInput)) {
            this.controlInput = true
        }
        this.tabForm.get(this.tabNameInput).setValue(this.nameTabs);
        this._openForm();
        this._changeMode("edit");

    }

    onCreateSubmit() {
        if (this.tabForm.valid) {
            this.validSubmit.emit({name: this.tabForm.value[this.tabNameInput]});
        }

        this._closeForm();
    }

    onUpdateSubmit() {
        if (this.tabForm.valid) {
            this.validSubmit.emit({
                id: 69,
                name: this.tabForm.value[this.tabNameInput],
            });
        }
    }

    private _closeForm() {
        this.formDisplayedService.setFormDisplayed(false);
    }


    private _changeMode(mode: "create" | "edit") {
        this.currentMode = mode;
    }

    private _openForm() {
        this.formDisplayedService.setFormDisplayed(true);
        this.controlInput = false;
    }

}
