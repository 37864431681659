import { NgModule } from "@angular/core";
import { SharedComponentsModule } from "app/shared";
import { PrivateComponent } from "./private.component";
import { PrivateRoutingModule } from "./private.routing";
@NgModule({
    declarations: [PrivateComponent],
    imports: [PrivateRoutingModule, SharedComponentsModule],
    providers: [],
})
export class PrivateModule {}
