import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { UserService } from "app/core/services/admin/user/user.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { MaestroProject } from "app/shared/models";
import { MaestroWorkflow } from "app/shared/models/maestro-entity/workflow";
import { WorkflowConfiguration, WorkflowConfigurations } from "app/shared/models/project/workflow-configuration";
import { MAESTRO_ROUTES } from "app/shared/routes/routes";
import Swal from "sweetalert2";
import { ProjectConfigurationStepperService } from "../../../../../../../core/services/project/project/stepper.service";
import { WorkflowConfigurationService } from "../../../../../../../core/services/project/project/workflow.service";
import { ConfigurationStepComponent } from "../../../../../../../shared/models/project/step-component.interface";

@Component({
    selector: "app-configuration-step-workflow",
    templateUrl: "./workflow.component.html",
})
export class WorkflowConfigurationComponent extends ConfigurationStepComponent<WorkflowConfigurations> implements OnInit {
    WorkflowConfigurations;

    submitted: boolean;

    workflows: MaestroWorkflow[] = [];
    resources: any;
    // indexTab = 1; // @TODO: To remove if no more usefull
    page: boolean;
    isWorkable: boolean;
    canUpdate: boolean;
    workflowId: number = 0;
    tabIndex: number = 0;
    canUpdateWorkflow: boolean = false;
    userId: number;

    form: FormGroup;
    readonly tabFormArray = this._formBuilder.array([]);
    readonly faSave = faSave;
    exports: WorkflowConfigurations;
    projectId: number;
    readonly routes = MAESTRO_ROUTES;
    @ViewChild("historyModal", { static: true }) private swal: SwalComponent;

    project: MaestroProject;

    @Output() eventSubmitted = new EventEmitter<any>();

    constructor(
        private _stepper: ProjectConfigurationStepperService,
        private _formBuilder: FormBuilder,
        private _service: WorkflowConfigurationService,
        private _translate: TranslateService,
        private _route: ActivatedRoute,
        private _swalModalService: SwalModalService,
        private _userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        this.resources = this._route.snapshot.data.workflow;
        this.workflows = this.resources.workflows.data;
        this.exports = this.resources.configs.data;
        const acl = this._userService.getUserAclFromToken();
        this.userId = this._userService.getUserIdFromToken();
        
        this.canUpdate = false;
        if (this.exports && this.exports.length > 0) {
            this.isWorkable = true;
            this._findWorkflowName();
            this.projectId = this.resources.projectId;
            this.project = this.resources.project;
            this.canUpdate = /*this.resources.auths.includes("ON_PROJECT_EXPORT_EDIT") ||*/ acl.MAESTRO_PROJECT_PROJECTS_UPDATE === 1;
            this.canUpdateWorkflow = acl.MAESTRO_ADMIN_WORKFLOW_UPDATE === 1
            this.form = this._initForm(this.exports);

            // @TODO: To remove if no more usefull :
            // this.form.get("tabs")["controls"].forEach((tab) => {
            //     tab.get("workflow").valueChanges.subscribe((value) => {
            //         this.changeWorkflow(tab, value);
            //     });
            // });
        }
        this._stepper.setCurrentStep(3);
    }

    isDeactivable(): boolean {
        return true;
    }

    /**
     * Save configuration
     *
     * @returns
     */
    onSubmit(): void {
        let errorDate = false;
        for (const tab of this.form.value.tabs) {
            if (tab && tab.steps) {
                for (let index = 0; index < tab.steps.length - 2; index++) {
                    if (!tab.steps[index].dueDate || !tab.steps[index + 1].dueDate) {
                        continue;
                    }
                    if (tab.steps[index].dueDate > tab.steps[index + 1].dueDate) {
                        errorDate = true;
                        break;
                    }
                }
            }
            if (errorDate) {
                break;
            }
        }

        if (errorDate) {
            this._swalModalService.error(this._translate.instant("modal.date"));
            return;
        }
        this._formToObject(this.form);
        this._service.saveConfiguration(this.projectId, this._formToObject(this.form)).subscribe((data) => {
            this._stepper.validStep(3);
            this.eventSubmitted.emit(this._formToObject(this.form));
            this._stepper.goToNext(this.projectId);
        });
    }

    /**
     * Inif form
     * @param configs
     * @returns
     */
    protected _initForm(configs: WorkflowConfigurations): FormGroup {
        configs.forEach((exp) => this.tabFormArray.push(this._initTabForm(exp)));

        return this._formBuilder.group({
            tabs: this.tabFormArray,
        });
    }

    /**
     * Init global form
     *
     * @param maestroExport
     * @returns
     */
    private _initTabForm(maestroExport: WorkflowConfiguration) {
        const steps = maestroExport.workflow.steps || [];
        const formArray = this._formBuilder.array(
            steps.map((step) =>
                this._formBuilder.group({
                    id: step.id,
                    title: step.name,
                    dueDate: step.dueDate,
                })
            )
        );
        let workflow = this.workflows.find((workflow) => {
            return workflow.id === maestroExport.workflow.id;
        });

        return this._formBuilder.group({
            workflow: [{ value: workflow ? [workflow] : null, disabled: !this.canUpdate }],
            steps: formArray,
            page: { value: true, disabled: !this.canUpdate },
            type: { value: maestroExport.type, disabled: !this.canUpdate },
            title: { value: maestroExport.name, disabled: !this.canUpdate },
            id: { value: maestroExport.id, disabled: !this.canUpdate },
        });
    }

    protected _formToObject(form: FormGroup): any {
        return {
            lastStep: this._stepper.isLastStep(),
            data: form.get("tabs").value.map(
                (data) =>
                    <WorkflowConfiguration>{
                        id: data.id,
                        type: data.type,
                        page: data.page,
                        workflow: data.workflow
                            ? data.workflow[0]
                                ? {
                                      id: data.workflow[0] ? this.workflows.find((workflow) => workflow.id == data.workflow[0].id).id : null,
                                      name: data.workflow[0] ? data.workflow[0].name : null,
                                      steps: data.steps,
                                  }
                                : null
                            : null,
                        // version: data.version[0],
                        // fields: data.fields,
                        // elements: data.elements,
                    }
            ),
        };
    }

    /**
     * Find workflow name
     */
    private _findWorkflowName() {
        this.exports.forEach((exp) => {
            if (exp.workflow.id) {
                let listWorkflow = this.workflows;

                const workflow = listWorkflow.find((workflow) => {
                    return workflow.id === exp.workflow.id;
                });

                exp.workflow.name = undefined !== workflow ? workflow.name : " ";
            }
        });
    }

    /**
     * Change workflow
     *
     * @param tab
     * @param value
     */
    changeWorkflow(tab, value) {
        // @TODO: To remove if no more usefull :
        // if (this.indexTab > this.form.get("tabs")["controls"].length) {
        const index = this.exports.findIndex((exp) => {
            return exp.id === tab.get("id").value;
        });
        
        if (value.length > 0) {
            let workflowData = this.workflows.find((workflow) => workflow.id == value[0].id);

            this.form.get("tabs")["controls"][index].removeControl("steps");
            if (workflowData) {
                this._service.getSteps(workflowData.id, this.form.get("tabs")["controls"][index]["controls"].page.value).subscribe((data) => {
                    const steps = data.data.places || [];

                    this.canUpdateWorkflow = this.canUpdateWorkflow || data.data.createdBy === this.userId ;
                    const formArray = this._formBuilder.array(
                        steps.map((step) =>
                            this._formBuilder.group({
                                id: { value: step.id, disabled: !this.canUpdate },
                                title: { value: step.name, disabled: !this.canUpdate },
                                dueDate: [{ value: step.dueDate, disabled: !this.canUpdate }],
                            })
                        )
                    );

                    this.form.get("tabs")["controls"][index].addControl("steps", formArray);
                });
            }
        }
        // @TODO: To remove if no more usefull :
        // }
        // this.indexTab++;
    }

    onBack() {
        this._stepper.goToPrevious(this.projectId);
    }

    openWorkflowModal(data, index){
        this.workflowId = data;
        this.tabIndex = index;
        this.swal.fire();
    }

    reloadWorkflow(event){
        if(event){
            this.form.get("tabs")["controls"][this.tabIndex].removeControl("steps");
            this._service.getSteps(this.workflowId, true).subscribe((data) => {
                const steps = data.data || [];
                const formArray = this._formBuilder.array(
                    steps.map((step) =>
                        this._formBuilder.group({
                            id: { value: step.id, disabled: !this.canUpdate },
                            title: { value: step.name, disabled: !this.canUpdate },
                            dueDate: [{ value: step.dueDate, disabled: !this.canUpdate }],
                        })
                    )
                );
    
                this.form.get("tabs")["controls"][this.tabIndex].addControl("steps", formArray);
                Swal.close();
            });
        }else {
            Swal.close();
        }
        

    }
}
