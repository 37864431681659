import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ElementService } from "app/core/services/pim/element.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { environment } from "src/environments";

@Component({
    selector: "app-order-data",
    templateUrl: "./order-data.component.html",
})
export class OrderDataComponent implements OnInit {
    @Input() data: any[] = [];
    @Input() filterProperty: string = "name"; // Multiple fields can be defines using a comma separates list
    @Input() module: string = "";
    @Input() key: string = ""; // Media key, used to retrieve which type of media are ordered

    @Output() reorderMedia: EventEmitter<any> = new EventEmitter();

    moduleUrl: string;
    reordered: boolean = false;
    copyData: any[] = [];
    display: boolean = false;

    constructor(private _thumbnailsService: ThumbnailsService, private _elementService: ElementService) {}

    ngOnInit(): void {
        if ("dam" === this.module) {
            this.moduleUrl = environment.damUrl;
        }

        this.copyData = [...this.data];
    }

    save() {
        const medias = this._elementService.manageMedia(false, this.data);

        this.reordered = false;

        this.reorderMedia.emit({ medias: medias, key: this.key, allMedias: [] });
    }

    resetPosition() {
        // this.data = [...this.copyData]; // @TODO: If not saved, order is not reloaded (copy of this.data on init which replace current) but when we reload nothing happened in interface (Angular/Component does not detect changes in nested objects or arrays) ...
    }
}
