import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-draggable-item',
    template: `
        <div draggable="true" (dragstart)="onDragStart($event)">
            {{ item.item_text }}
        </div>
    `,
})
export class DraggableItemComponent {
    @Input() item: any;

    onDragStart(event: DragEvent) {
        event.dataTransfer.setData('text/plain', JSON.stringify(this.item));
    }
}
