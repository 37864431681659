import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { ActivatedRouteSnapshot, ActivationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ACL } from "app/shared/models/acl";
import { filter } from "rxjs/operators";
import { MAESTRO_ROUTES } from "../shared/routes/routes";

@Component({
    templateUrl: "./private.component.html",
})
export class PrivateComponent implements OnInit {
    @ViewChild("content", { static: true }) elementView: ElementRef;

    contentHeight: string;

    private _acl: ACL;

  

    @HostListener("window:resize")
    onResize() {
        this.contentHeight = this._computeViewportHeight();
    }

    constructor(
        router: Router, private _layout: LayoutService,
        private translate: TranslateService,
        private _authService: AuthService,
        ) {
        router.events.pipe(filter((e) => e instanceof ActivationEnd)).subscribe((event: ActivationEnd) => {
            this.initLayout(event);
        });
    }
    //Pour le bug de la barre verticale, pas forcement la meilleur solution parce que le ngAfterContentChecked se fait en boucle.
    ngAfterContentChecked() {
        this.contentHeight = this._computeViewportHeight();
    }

    ngOnInit() {
        this._acl = this._authService.getUserAclFromToken();

        this.contentHeight = this._computeViewportHeight();
        // $("main")
        //     .before()
        //     .on("click", () => {
        //         $("body").removeClass("sidebar-lg-show sidebar-show");
        //     });
    }

    private _computeViewportHeight(): string {
        return window.innerHeight - this.elementView.nativeElement.offsetTop + "px";
    }

    /**
     * works for now but not the best way
     */
    private initLayout(event: ActivationEnd) {
        const path = event.snapshot.routeConfig.path;

        switch (path) {
            case MAESTRO_ROUTES.pim.element:
                this._initPimFieldsetLAyout(event.snapshot);
                break;
            case MAESTRO_ROUTES.pim.elementType:
                this._initRegularPimLayout(event.snapshot, "elementTypes");
                break;
            case MAESTRO_ROUTES.pim.fieldset:
                this._initRegularPimLayout(event.snapshot, "fieldsets");
                break;
            case MAESTRO_ROUTES.pim.list:
                this._initRegularPimLayout(event.snapshot, "lists");
                break;
            case MAESTRO_ROUTES.admin.users:
                this._initRegularAdminLayout(event.snapshot, "users");
                break;
            case MAESTRO_ROUTES.admin.roles:
                this._initRegularAdminLayout(event.snapshot, "roles");
                break;
            case MAESTRO_ROUTES.admin.group:
                this._initRegularAdminLayout(event.snapshot, "groups");
                break;
            case MAESTRO_ROUTES.admin.languages:
                this._initRegularAdminLayout(event.snapshot, "languages");
                break;
        }
    }

    /**
     * check if there's an id in the root
     * (for the moment it works well)
     */
    private hasChildrenId(snapshot: ActivatedRouteSnapshot) {
        return snapshot && snapshot.params.id;
    }

    private _initPimFieldsetLAyout(snapshot: ActivatedRouteSnapshot) {
        this._initRegularPimLayout(snapshot, "elementType");
        if (this.hasChildrenId(snapshot.firstChild)) {
            this._layout.breadcrumb.setPath({ routerLink: `/${MAESTRO_ROUTES.pim.base}/${snapshot.routeConfig.path}`, name: this.translate.instant("general.products") }, 1);
        } else {
            this._layout.breadcrumb.setPath(null, 1);
        }
    }

    private _initRegularPimLayout(snapshot: ActivatedRouteSnapshot, entity: string) {
        if (this.hasChildrenId(snapshot.firstChild)) {
            this._layout.breadcrumb.setPath({ routerLink: `/${MAESTRO_ROUTES.pim.base}/${snapshot.routeConfig.path}`, name: "pim." + entity + ".title" }, 1);
        } else {
            this._layout.breadcrumb.setPath(null, 1);
        }
    }

    private _initRegularAdminLayout(snapshot: ActivatedRouteSnapshot, entity: string) {
        const link = this._acl && this._acl.MAESTRO_MODULE_ADMIN_MODULE ? `/${MAESTRO_ROUTES.admin.base}/${snapshot.routeConfig.path}` : null;

        this._layout.breadcrumb.setPath({ routerLink: link, name: this.translate.instant("sidebar." + entity) }, 1);
    }
}
