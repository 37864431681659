export const query = {
    query: `{
    getDashboard
    {
      configurations
      { 
        id,
        name,
        type,
        active,
        default,
        createdBy,
        configurationToBlocs
        {
          position,
          bloc,
          {
            id,
            name,
            blocType,
            {
              name,
              type,
              level
            }
            dataTypes
            {
              name,
              type,
              dataOptions
              {
                name,
                module,
                method,
                parameter,
                dataOptionProperties
                {
                  property
                }
              }
            }
          }
        }
        
      }
    }
  }`,
};
