import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { PageImageTabComponent } from "./page-image-tab.component";

@NgModule({
    declarations: [PageImageTabComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [PageImageTabComponent],
})
export class PageImageTabModule {}
