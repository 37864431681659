import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ProjectConfigurationStepperService } from "../../../../../core/services/project/project/stepper.service";
import { ProjectConfigurationComponent } from "./configuration.component";
import { ConfigurationResolver } from "./configuration.resolver";
import { ProjectConfigurationRoutingModule } from "./configuration.routing";
import { ElementsConfigurationModule } from "./steps/elements/elements.module";
import { ExportsConfigurationModule } from "./steps/exports/exports.module";
import { FluxConfigurationModule } from "./steps/flux/flux.module";
import { SettingsConfigurationModule } from "./steps/project/project.module";
import { TemplateConfigurationModule } from "./steps/template/template.module";
import { WorkflowConfigurationModule } from "./steps/workflow/workflow.module";

@NgModule({
    declarations: [ProjectConfigurationComponent],
    imports: [
        ProjectConfigurationRoutingModule,
        SettingsConfigurationModule,
        ElementsConfigurationModule,
        ExportsConfigurationModule,
        WorkflowConfigurationModule,
        TemplateConfigurationModule,
        FluxConfigurationModule,
        SharedComponentsModule,
        SharedModule,
    ],
    providers: [ProjectConfigurationStepperService, ConfigurationResolver],
})
export class ProjectConfigurationModule {}
