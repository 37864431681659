<a class="card-parameters dropdown-toggle" href="#" id="dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <fa-icon [icon]="faCog"></fa-icon>
</a>

<div class="dropdown-menu" aria-labelledby="dropdown">
    <ng-container *ngIf="!disabled">
        <a *ngFor="let item of menu" class="dropdown-item" (click)="menuClick.emit(item.action)">
            {{ item.title }}
        </a>
    </ng-container>
</div>
