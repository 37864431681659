import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { SwalModalService } from "app/core/services/global/modal/modal.service";

/**
 * This guard is used to control if there is any unsaved modification in a form
 */
@Injectable()
export class ConfirmGuard implements CanDeactivate<any> {
    constructor(private _swal: SwalModalService) {}

    canDeactivate(target: any): boolean | Promise<boolean> {
        if (!target.formArray) {
            return true;
        }
        if (target.formArray.dirty) {
            return this._swal.unsavedConfirm().then((result) => {
                if (result.value) {
                    return true;
                }
                return false;
            });
        }
        return true;
    }
}
