import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorPickerMaestroComponent } from "./color-picker-maestro.component";

@NgModule({
    declarations: [ColorPickerMaestroComponent],
    exports: [ColorPickerMaestroComponent],
    imports: [SharedModule, ColorPickerModule],
})
export class ColorPickerMaestroModule {}
