import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { activatedModules } from "src/environments/activated-modules";
import { RoleService } from "../../../../../core/services/admin/role/role.service";

@Component({
    templateUrl: "./role-form.component.html",
    styleUrls: ["./role-form.component.scss"],
})
export class RoleFormComponent implements OnInit, OnDestroy {
    authorizations: Array<any> = [];
    roleId: number;
    acl: any;
    module: string;

    countRequest = 0;

    constructor(
        private _roleService: RoleService,
        private _route: ActivatedRoute,
        private _layout: LayoutService,
        private _toaster: ToastService,
        private _translate: TranslateService,
        private _userService: UserService,
        private _authService: AuthService
    ) {}

    ngOnInit() {
        this.module = this._route.snapshot.data.module;
        this.roleId = this._route.snapshot.params["id"];

        /**
         * Get role from the api
         */
        this._roleService.getActions(this.roleId).subscribe((result: any) => {
            const data = result.data;
            this.acl = data.list;
            this._layout.breadcrumb.setPath({ routerLink: null, name: data.profile }, 2);
            this._setAuthorizations();
        });
    }

    ngOnDestroy() {
        this._layout.breadcrumb.setPath(null, 2);
    }

    /**
     * Update an authorization
     *
     * @param roleId
     * @param authorizationId
     * @param access
     * @param actionId
     */
    changeAuthorization(roleId: number, authorizationId: number, access, actionId: number) {
        access = !access;
        this._layout.spinner.disable();
        this.countRequest++;
        this._roleService.updateAuthorization(roleId, authorizationId, access, actionId).subscribe((result) => {
            this.countRequest--;

            if (this.countRequest === 0) {
                this._layout.spinner.activate();
            }

            this._toaster.show({ message: this._translate.instant("admin.roles.aclSaved"), type: "success" });

            const data = result.data;
            this._updateAuthorization(data.codeModule, data.codeEntity, data.codeAction, data.access);

            const profileId = this._userService.getUserProfileIdFromToken();

            if (roleId == profileId) {
                this._authService.refreshToken(true);
            }
        });
    }

    /**
     * Set authorizations from the role
     */
    private _setAuthorizations() {
        const aclsStructure = ["PIM", "DAM", "PROJECT", "ADMIN"];
        const tempAcls = [];
        this.acl.forEach((acl) => {
            if (tempAcls[acl.code[0]] === undefined) {
                tempAcls[acl.code[0]] = [];
                tempAcls[acl.code[0]]["actions"] = [];
            }

            if (tempAcls[acl.code[0]][acl.code[1]] === undefined) {
                tempAcls[acl.code[0]][acl.code[1]] = [];
            }

            if (tempAcls[acl.code[0]][acl.code[1]][acl.code[2]] === undefined) {
                tempAcls[acl.code[0]][acl.code[1]][acl.code[2]] = [];
            }

            tempAcls[acl.code[0]][acl.code[1]][acl.code[2]].push(acl);

            tempAcls[acl.code[0]]["actions"][acl.code[2]] = true;
        });

        const arrayAcl = [];
        for (const moduleName of Object.keys(tempAcls)) {
            const module = {
                name: moduleName,
                actions: [],
                elements: [],
            };

            for (const actionName of Object.keys(tempAcls[moduleName]["actions"])) {
                module.actions.push(actionName);
            }

            for (const elementName of Object.keys(tempAcls[moduleName])) {
                if (elementName === "actions") continue;

                // Clone module actions for ordering
                const tmpActions = Object.assign([], tempAcls[moduleName]["actions"]);

                const element = {
                    name: elementName,
                    actions: [],
                };

                // Force actions order
                for (const actionName of Object.keys(tempAcls[moduleName][elementName])) {
                    tmpActions[actionName] = {
                        action_id: tempAcls[moduleName][elementName][actionName][0].action_id,
                        access: tempAcls[moduleName][elementName][actionName][0].access,
                        authorization_id: tempAcls[moduleName][elementName][actionName][0].authorization_id,
                    };
                }

                for (const actionName of Object.keys(tmpActions)) {
                    const action = {
                        name: actionName,
                        value: tmpActions[actionName],
                    };
                    element.actions.push(action);
                }
                module.elements.push(element);
            }

            const moduleNameLower = module.name.toLocaleLowerCase();
            if (activatedModules[moduleNameLower]) {
                if (module.name === "PIM" && !activatedModules.dam) {
                    module.elements = module.elements.filter((e) => e.name !== "MEDIA");
                }

                const index = aclsStructure.indexOf(module.name);
                arrayAcl.splice(index, 0, module);
            } else if ("module" === moduleNameLower) {
                arrayAcl.unshift(module); // Push at the beginning
            }
        }

        this.authorizations = arrayAcl;
    }

    /**
     * Update authorization stored in component
     * @param codeModule
     * @param codeEntity
     * @param codeAction
     * @param access
     */
    private _updateAuthorization(codeModule: string, codeEntity: string, codeAction: string, access: boolean) {
        const objectModule = this.authorizations.find((module) => module.name === codeModule);
        const objectEntity = objectModule.elements.find((entity) => entity.name === codeEntity);
        const objectAction = objectEntity.actions.find((action) => action.name === codeAction);

        objectAction.value.access = access;
    }
}
