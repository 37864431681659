import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { PageImageTabModule } from "./components/page-image-tab/page-image-tab.module";
import { PageOptionTabModule } from "./components/page-option-tab/page-option-tab.module";
import { PageTextTabModule } from "./components/page-text-tab/page-text-tab.module";
import { ExportPageViewComponent } from "./export-page-view.component";
import { ExportPageViewRoutingModule } from "./export-page-view.routing";
import { ExportPageResolver } from "./export-page.resolver";

@NgModule({
    declarations: [ExportPageViewComponent],
    imports: [ExportPageViewRoutingModule, PageTextTabModule, PageImageTabModule, PageOptionTabModule, SharedModule, SharedComponentsModule],
    providers: [ExportPageResolver],
})
export class ExportPageViewModule {}
