import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ProjectListComponent } from "./project-list.component";
import { ProjectListResolver } from "./project-list.resolver";

@NgModule({
    imports: [SharedModule, SharedComponentsModule],
    declarations: [ProjectListComponent],
    exports: [ProjectListComponent],
    providers: [ProjectListResolver],
})
export class ProjectListModule {}
