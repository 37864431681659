import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { PageThumbnailMenuComponent } from "./menu/page-thumbnail-menu.component";
import { PageThumbnailComponent } from "./page-thumbnail.component";

@NgModule({
    imports: [SharedModule],
    declarations: [PageThumbnailMenuComponent, PageThumbnailComponent],
    exports: [PageThumbnailComponent],
})
export class PageThumbnailModule {}
