import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class MaestroValidators {
    /**
     * Regex for password
     */
    static password(control: AbstractControl): ValidationErrors {
        let error = null;
        const password_regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_#])[A-Za-z\d@$!%*?&-_#]{8,}$/;

        if (!password_regexp.test(control.value)) {
            error = { invalidPassword: true };
        }
        return error;
    }

    /**
     * Must match validator
     *
     * @param formControlName
     * @returns
     */
    static mustMatch(formControlName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            let hasError = null;
            if (control.parent) {
                const matchControl = control.parent.get(formControlName);
                if (matchControl) {
                    hasError = matchControl.value !== control.value ? { mustMatch: true } : null;
                } else {
                    hasError = { mustMatch: true };
                }
            } else {
                hasError = { mustMatch: true };
            }
            return hasError;
        };
    }
}
