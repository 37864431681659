import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { ListService } from "app/core/services/pim/list.service";
import { MaestroLists } from "app/shared/models";
import { Observable } from "rxjs";

@Injectable()
export class ListListResolver implements Resolve<MaestroLists> {
    constructor(private service: ListService) {}

    resolve(): Observable<MaestroLists> {
        return this.service.getLists();
    }
}
