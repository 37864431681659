import { Pipe, PipeTransform } from "@angular/core";
import { MaestroTags } from "app/shared/models";

@Pipe({
    name: "selectedTags",
})
export class SelectedTagsPipe implements PipeTransform {
    transform(tags: MaestroTags, selected = true): any[] {
        // return tags.filter(tag => tag.selected === selected);
        const array = [];
        tags.forEach((tag) => {
            if (tag.selected === selected) {
                array.push(tag);
            }
        });
        return array;
    }
}
