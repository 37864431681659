import { NgModule } from "@angular/core";

import { ElementTypeListModule } from "./list/element-type-list.module";
import { ElementTypeUpdateModule } from "./update/element-type-update.module";
import { ElementTypeRoutingModule } from "./element-type.routing";

@NgModule({
    imports: [ElementTypeRoutingModule, ElementTypeListModule, ElementTypeUpdateModule],
})
export class ElementTypeModule {}
