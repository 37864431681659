import { Directive, HostListener, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { SidebarService } from "app/core/services/global/sidebar/sidebar.service";
import { Subscription } from "rxjs";

@Directive({
    selector: "[appSidebarButton]",
})
export class SidebarButtonDirective implements OnInit, OnDestroy {
    private _subscription: Subscription;
    constructor(private _renderer: Renderer2, private _sidebarService: SidebarService) {}

    @HostListener("click") onClick() {
        this._toggle();
    }

    ngOnInit(): void {
        this._subscription = this._sidebarService.enable$.subscribe((enable) => {
            enable ? this._open() : this._close();
        });
    }

    ngOnDestroy(): void {
        this._close();
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    private _toggle() {
        const clazz = "sidebar-lg-show";
        if (document.body.className.indexOf(clazz) > -1) {
            this._close();
        } else {
            this._open();
        }
    }

    private _open() {
        this._renderer.addClass(document.body, "sidebar-lg-show");
        this._renderer.addClass(document.body, "sidebar-show");
    }

    private _close() {
        this._renderer.removeClass(document.body, "sidebar-lg-show");
        this._renderer.removeClass(document.body, "sidebar-show");
    }
}
