import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { TemplateService } from "app/core/services/project/template/template.service";
import { MaestroTemplates } from "app/shared/models";
import { Observable } from "rxjs";

@Injectable()
export class TemplatesListGuard implements CanActivate, Resolve<MaestroTemplates> {
    constructor(private _service: TemplateService) {}

    canActivate(ext: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MaestroTemplates> {
        return this._service.getAll();
    }
}
