import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { MaestroTags } from "app/shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class DataviewResolver implements Resolve<SidebarData> {
    constructor(private _service: ThumbnailsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SidebarData> {
        const url = state.url;
        return this._service.getSidebarData(url).pipe(map((data: any) => <SidebarData>{ tags: data.data }));
    }
}

export interface SidebarData {
    tags: MaestroTags;
}























