<app-maestro-header></app-maestro-header>
<app-breadcrumb></app-breadcrumb>
<div class="app-body">
    <app-sidebar></app-sidebar>
    <main class="main">
        <app-alert-message></app-alert-message>
        <div #content class="container-fluid" [style.height]="contentHeight">
            <div class="animated fadeIn">
                <router-outlet></router-outlet>
            </div>
        </div>
    </main>
</div>
