<ul id="sidebarAccordion" class="nav">
  <ng-container *ngFor="let item of options | sidebarTreeNode; let i = index">
    <li *ngIf="item.total" class="nav-item nav-dropdown">
      <a
        class="nav-link nav-dropdown-toggle"
        data-toggle="collapse"
        attr.data-target="{{ '#itemMenu' + i }}"
        attr.aria-expanded="{{ i ? 'false' : 'true' }}"
      >
        <fa-icon [icon]="item.icon" [fixedWidth]="true"></fa-icon>
        &nbsp; <span class="nav-item-text">{{ item.title | translate }}</span>
        <span class="badge badge-primary">{{ item.total }}</span>
      </a>

      <div
        id="{{ 'itemMenu' + i }}"
        class="item-body nav-dropdown pt-3 open"
        [ngClass]="i ? 'collapse' : 'show'"
        data-parent="#sidebarAccordion"
      >
        <tree-root [nodes]="item.data" [options]="item.config"></tree-root>
      </div>
    </li>
  </ng-container>
</ul>
