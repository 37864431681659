import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
    selector: "[appDebounceInput],[debounceInput]",
})
export class DebounceInputDirective implements OnDestroy {
    @Input() debounceTime = 500;
    @Output() debounceInput = new EventEmitter();
    private _inputSource = new Subject();
    private _clickSub: Subscription;

    constructor() {
        this._clickSub = this._inputSource.pipe(debounceTime(this.debounceTime)).subscribe((e) => this.debounceInput.emit(e));
    }

    ngOnDestroy() {
        this._clickSub.unsubscribe();
    }

    @HostListener("input", ["$event"])
    inputEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this._inputSource.next(event);
    }
}
