<div class="row overflow-auto">
    <div class="col-lg-12 bs-stepper">
        <div class="bs-stepper-header" role="tablist">
            <ng-container *ngFor="let step of steps; let i = index">
                <div *ngIf="i !== 0" class="line"></div>
                <div class="step" [ngClass]="{ active: step.state === state.Current }">
                    <div *ngIf='disabledIfNotValidBefore(step)' class="step-trigger" (click)="stepClick.emit(step)">
                        <span class="bs-stepper-circle"
                            [ngClass]="{ 'bg-danger': !step.valid && !isCurrentStep(step), 'bg-info': isCurrentStep(step), 'bg-success': step.valid && !isCurrentStep(step)}">{{
                            i + 1 }}</span>
                        <span class="bs-stepper-label">{{ 'projects.step.' + step.title | translate }}</span>
                    </div>
                    <div *ngIf='!disabledIfNotValidBefore(step)' class="stepper-disabled">
                        <span class="bs-stepper-circle" [ngClass]="{'bg-secondary': !step.valid}">{{ i + 1 }}</span>

                        <span class="bs-stepper-label">{{ 'projects.step.' + step.title | translate }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>