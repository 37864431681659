import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroRole, MaestroRoles } from "app/shared/models";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class RoleService {
    constructor(private _http: HttpClient) {}

    /**
     * Return the list of the roles
     * @returns
     */
    getAll(): Observable<MaestroRoles> {
        return this._http.get<MaestroRoles>(`${environment.adminApiUrl}/profiles.json`);
    }

    /**
     * Create a new role
     * @param body
     * @returns
     */
    create(body: any): Observable<MaestroRole> {
        return this._http.post(`${environment.adminApiUrl}/profiles/create.json`, body);
    }

    /**
     * Delete a role
     * @param id
     * @returns
     */
    deleteById(id: number): Observable<any> {
        return this._http.delete(`${environment.adminApiUrl}/role/${id}.json`);
    }

    /**
     * Get authorizations for a specific role
     * @param id
     * @returns
     */
    getActions(id: number): Observable<any> {
        return this._http.get(`${environment.adminApiUrl}/role/actions/${id}.json`);
    }

    /**
     * Update an authorization for a specific role
     * @param roleId
     * @param authorizationId
     * @param access
     * @param actionId
     * @returns
     */
    public updateAuthorization(roleId: number, authorizationId: number, access, actionId: number) {
        const formData = new FormData();
        formData.append("access", access);
        formData.append("_method", "PUT");

        return this._http.post<any>(`${environment.adminApiUrl}/change_value/${authorizationId}/${actionId}/${roleId}.json`, formData);
    }
}
