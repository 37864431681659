import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { TemplateService } from "app/core/services/project/template/template.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Subscription } from "rxjs";

@Component({
    selector: "app-twig-list",
    templateUrl: "./twig-list.component.html",
    styleUrls: ["./twig-list.component.scss"],
})
export class TwigListComponent implements OnInit {
    table: [string, string][];
    columns: string[];
    _twigs: any;

    private _actionButtonSub: Subscription;
    readonly actions: ActionButtonsDef = [
        { type: ActionType.Update, right: "MAESTRO_PROJECT_PROJECTS_UPDATE" },
        //{ type: ActionType.Delete, right: "MAESTRO_PROJECT_PROJECTS_DELETE" },
    ];
    private _exportTypes = [];

    constructor(
        private _layout: LayoutService,
        private _service: TemplateService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _tableDataPipe: TableDataPipe,
        private _userService: UserService
    ) {}

    ngOnInit(): void {
        this._twigs = this._route.snapshot.data.twigs.data;

        const acl: ACL = this._userService.getUserAclFromToken();

        if (acl.MAESTRO_PROJECT_TEMPLATE_CREATE === 1) {
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "project.twig.create";
            this._actionButtonSub = this._layout.actionButton.click$.subscribe((_) => this.onCreateButtonClick());
        }

        this._service.getExportTypes().subscribe((response) => {
            this._exportTypes = response.data;
            this._twigs.forEach((t) => {
                const et = this._exportTypes.find((e) => e.id === t.exportType);
                if (et) {
                    t.exportType = et.name;
                } else {
                    t.exportType = "";
                }
            });

            this._initList(this._twigs);
        });
    }

    /**
     * Init the list
     * @param templates
     */
    private _initList(twigs: any) {
        const formatedData = this._tableDataPipe.transform(twigs);
        this.columns = formatedData[0];

        const trueColumns = [];

        this.columns.forEach((c) => {
            trueColumns.push("twigs." + c);
        });

        this.columns = trueColumns;

        this.table = formatedData[1];
    }

    /**
     * Handle action click
     * @param event
     */
    onActionClick(event: ActionEvent) {
        const id = event.rowId;
        switch (event.action) {
            case ActionType.Delete:
                // this._service.deleteById(id).subscribe(() => {
                //     this._initList(this._templates.filter((t) => t.id !== id));
                // });
                break;
            case ActionType.Update:
                this._router.navigate([id, MAESTRO_ROUTES.actions.update], { relativeTo: this._route });
                break;
        }
    }

    onCreateButtonClick() {
        this._router.navigate([MAESTRO_ROUTES.actions.create], { relativeTo: this._route });
    }
}
