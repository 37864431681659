import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { FieldsetService } from "app/core/services/pim/fieldset.service";
import { ListService } from "app/core/services/pim/list.service";
import { FieldsetResources } from "app/shared/models/pim/fieldset-resources";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class FiledsetUpdateResolver implements Resolve<FieldsetResources> {
    constructor(private _fieldsetService: FieldsetService, private _listService: ListService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<FieldsetResources> {
        return forkJoin([this._fieldsetService.getFieldset(route.params.id).pipe(map((r) => r.data)), this._listService.getLists().pipe(map((r) => r.data))]).pipe(
            map((data) => ({ fieldset: data[0], lists: data[1].map((v) => ({ id: v.id, name: v.name })) }))
        );
    }
}
