<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col">
                <app-form-header [data]="element | elementToFormHeader: 'preview'"></app-form-header>
            </div>
            <div class="col text-right">
                <app-action-buttons buttonOutlined="true" [actions]="actions" (action)="onActionClick($event, element.id)" ></app-action-buttons>
            </div>
        </div>

        <div class="row preview-tab-content" *ngFor="let tab of element.tabs; let i = index">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <h3>{{ tab.name }}</h3>
                    </div>
                </div>
                <div class="row" *ngFor="let field of tab.fields">
                    <div class="col">
                        <span class="d-inline-block" style="font-weight: bold;">{{ field.name }} :&nbsp;</span>
                        <span class="d-inline-block" style="margin-bottom: 10px">
                            <div *ngIf="field.values && field.values.length > 0">
                                <app-base-preview [values]="field.values"></app-base-preview>
                            </div>
                            <div *ngIf="field.collections && field.collections.length > 0">
                                <app-fieldset [collections]="field.collections"></app-fieldset>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
