import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ProjectService } from "app/core/services/project/project/project.service";
import { MaestroProject } from "app/shared/models";
import { Observable } from "rxjs";

@Injectable()
export class ProjectResolver implements Resolve<MaestroProject> {
    constructor(private service: ProjectService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<MaestroProject> {
        const projectId = route.params.id;

        return this.service.getById(projectId);
    }
}
