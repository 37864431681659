// @TODO : Remove this module when datatable.module will be full implemented

import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ActionButtonsModule } from "../action-buttons/action-buttons.module";
import { TableComponent } from "./table.component";

@NgModule({
    imports: [SharedModule, ActionButtonsModule],
    declarations: [TableComponent],
    exports: [TableComponent],
})
export class TableModule { }
