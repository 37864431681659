import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Observable, ReplaySubject } from "rxjs";

@Injectable()
export class DataService {
    constructor() {}

    uniqueData(arrayData: any, property: string = "id") {
        let map = new Map();

        for (const data of arrayData) {
            map.set(data[property], data);
        }

        const uniqueData = [...map.values()];

        return uniqueData;
    }

    convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(btoa(event.target.result.toString()));

        return result;
    }

    /**
     * Extract object ids
     * @param selectedRows
     * @param searchDataParameter
     * @param archiveHd
     * @param attribute
     * @returns
     */
    getIdsForArchive(selectedRows: any, searchDataParameter: string, archiveHd: boolean = false, attribute: string = "id") {
        const ids = selectedRows.map((row) => {
            return row[attribute];
        });

        return { [searchDataParameter]: ids, archiveHd: archiveHd };
    }

    /**
     * Remove one object
     * @param selectedRows
     * @param rowId
     * @param attribute
     * @returns
     */
    removeFromSelection(selectedRows: any, rowId: string | number, attribute: string = "id") {
        return selectedRows.filter((r) => r[attribute] !== rowId);
    }

    formatDateValue(value) {
        if (4 === value.indexOf("/")) {
            value = value.split("/").reverse().join("/");
        }

        return value;
    }

    downloadFile(data: any, fileName: string, type: string = "application/pdf") {
        // Base64 data to ByteArray (binary string to ASCII chars [= byte array]) to Blob
        const byteArray = new Uint8Array(
            atob(data)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([byteArray], { type: type });

        saveAs(blob, fileName);
    }

    expandCollapse(action, list) {
        list.forEach((node) => {
            if (action == "expand") {
                this.expandRecursive(node, true);
            } else {
                this.expandRecursive(node, false);
            }
        });
    }

    private expandRecursive(node, isExpand: boolean) {
        node.expanded = isExpand;

        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    getSelectedValue(listSelected: any, event, avoidDuplicate: boolean = false) {
        let value = [];

        listSelected.forEach((valueSelect) => {
            let parentId = null;
            if (valueSelect.parent) {
                parentId = valueSelect.parent.id;
                value = this.partialSelectedParent(value, valueSelect, avoidDuplicate);
            }

            const found = value.find((val) => val.id == valueSelect.id);

            if (!avoidDuplicate || (avoidDuplicate && !found)) {
                value.push({
                    id: valueSelect.id,
                    name: valueSelect.name,
                    key: valueSelect.key,
                    partialSelected: valueSelect.partialSelected ? valueSelect.partialSelected : null,
                    selected: event && event.node.id == valueSelect.id ? true : valueSelect.selected,
                    parentId: valueSelect.parentId ? valueSelect.parentId : parentId,
                });
            }
        });

        return value;
    }

    partialSelectedParent(value, selected, avoidDuplicate: boolean = false) {
        let parent = selected.parent;
        let parentId = null;
        if (parent.parent) {
            parentId = parent.parent.id;
            value = this.partialSelectedParent(value, parent, avoidDuplicate);
        }

        const found = value.find((val) => val.id == parent.id);

        if (!avoidDuplicate || (avoidDuplicate && !found)) {
            let result = {
                id: parent.id,
                name: parent.name,
                key: parent.key,
                partialSelected: true,
                parentId: parentId,
            };

            value.push(result);
        }

        return value;
    }
}
