import {NgModule} from "@angular/core";
import {SharedModule} from "app/shared";
import {FlatplanComponent} from "./flatplan.component";
import {PageThumbnailModule} from "./page-thumbnail/page-thumbnail.module";
import {SortableDirective} from "./sortable.directive";

@NgModule({
    imports: [PageThumbnailModule, SharedModule],
    declarations: [FlatplanComponent, SortableDirective],
    exports: [FlatplanComponent, SortableDirective],
})
export class FlatplanModule {
}
