import { marks as basicMarks, nodes } from "ngx-editor";
import { DOMOutputSpec, Schema } from "prosemirror-model";

// This lets to manage <sup> & <sub> tags and was taken from https://github.com/bymounib/ngx-editor-add-subscript-superscript-tools/tree/main
const marks = Object.assign({}, basicMarks, {
    sup: {
        toDOM(): DOMOutputSpec {
            return ["sup"];
        },
        parseDOM: [{ tag: "sup" }],
    },
    sub: {
        toDOM(): DOMOutputSpec {
            return ["sub"];
        },
        parseDOM: [{ tag: "sub" }],
    },
});

const schema = new Schema({
    nodes,
    marks,
});

export default schema;
