import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProjectConfigurationStepperService } from "app/core/services/project/project/stepper.service";
import { Steps, StepState } from "./steps";

@Component({
    selector: "app-stepper",
    templateUrl: "./stepper.component.html",
})
export class StepperComponent {
    @Input() steps: Steps<any>;
    currentStep: any;
    @Output() stepClick: EventEmitter<Steps<any>> = new EventEmitter();
    state = StepState;

    constructor(private _stepperService: ProjectConfigurationStepperService) { }

    ngOnInit() {

        this._stepperService.currentStep$.subscribe((step) => {
            this.currentStep = step;
        });
    }

    disabledIfNotValidBefore(step) {
        return this._stepperService.isAllValidBefore(step);
    }

    isCurrentStep(step) {
        return step.id === this.currentStep.id;
    }
}
