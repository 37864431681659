import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageInformationService } from "app/core/services/admin/user/MessageInformation.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { MAESTRO_ROUTES } from "app/shared/routes";

@Component({
    selector: "app-message-form",
    templateUrl: "./message-form.component.html",
    styleUrls: ["./message-form.component.scss"],
})
export class MessageFormComponent implements OnInit {
    form: FormGroup;
    id;
    messageInformation;
    isUpdate: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private _MessageInformationService: MessageInformationService,
        private route: ActivatedRoute,
        private _router: Router,
        private _toaster: ToastService,
        private _translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.route.params.subscribe((param) => {
            this.id = +param["id"];

            this.form = this.formBuilder.group({
                message: ["", Validators.required],
                startDate: ["", Validators.required],
                endDate: ["", Validators.required],
            });

            if (this.id) {
                this.isUpdate = true;
                this._MessageInformationService.getMessage().subscribe((message) => {
                    this.messageInformation = message.data.find((data) => data.id === Number(this.id));
                    this.form.patchValue({
                        message: this.messageInformation.message,
                        startDate: this.messageInformation.startDate,
                        endDate: this.messageInformation.endDate,
                    });
                });
            } else {
                this.isUpdate = false;
            }
        });
    }

    onSubmit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const formValue = this.form.value;

        if (this.isUpdate) {
            this._MessageInformationService.updateMessage(formValue, this.id).subscribe(() => {
                this._toaster.show({ message: this._translate.instant("notification.toasterUpdate"), type: "success" });
                this._MessageInformationService.emitEventMessage();
                this.onBack();
            });
        } else {
            this._MessageInformationService.createMessage(formValue).subscribe(() => {
                this._toaster.show({ message: this._translate.instant("notification.toaster"), type: "success" });
                this._MessageInformationService.emitEventMessage();
                this.onBack();
            });
        }
    }

    onBack() {
        this._router.navigate([`${MAESTRO_ROUTES.admin.base}/${MAESTRO_ROUTES.admin.message.base}`]);
    }
}
