import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { TwigListComponent } from "./twig-list.component";

@NgModule({
    declarations: [TwigListComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [TwigListComponent],
})
export class TwigListModule {}
