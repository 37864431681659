<div class="card-body p-0">
    <form [formGroup]="formArray" class="mt-2">
        <ul [sortableSection]="sortableJSOptionsSection" [sortableEnabled]="isSortable" ngbNav #nav="ngbNav"
            class="nav-tabs mx-1" (navChange)="onTabChange($event)"
            style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; max-width: 100%;">
            <li *ngFor="let section of sections; let id = index" [ngbNavItem]="id" class="sortable-section">
                <a ngbNavLink class="text-truncate d-flex">
                    <ng-container *ngIf="!updateName || currentOpen !== id">
                        <span
                            [ngClass]="{ 'font-weight-bold text-info': nav.activeId === id, 'text-success' : formArray.controls[id].valid, 'text-danger': !formArray.controls[id].valid }">{{
                                section.name
                            }}</span>
                        <span [ngbTooltip]="'tooltip.update' | translate"
                              class="position-relative ml-2 font-weight-light"
                              (click)="updateName = true; currentOpen = id; focusSelectedNameSection(id)">
                            <fa-icon class="text-warning" [icon]="faEdit"></fa-icon>
                        </span>
                        <span [ngbTooltip]="'tooltip.delete' | translate"
                              class="position-relative ml-2 font-weight-light" *ngIf="section.name !== unnamedSection"
                              (click)="deleteSection(section, id)">
                            <fa-icon class="text-danger" [icon]="faTrash"></fa-icon>
                        </span>
                        <span [ngbTooltip]="'tooltip.sort' | translate"
                              class="position-relative ml-2 font-weight-light">
                            <fa-icon class="text-primary" [icon]="faArrowsAltH"></fa-icon>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="updateName && currentOpen === id">
                        <input id="section-name-{{id}}"
                               class="form-control form-control-sm mx-auto text-center font-weight-bolder" type="text" required
                               [value]="section.name">
                        <button [ngbTooltip]="tooltipText" triggers="click:blur" id="faSave-{{id}}"
                                class="position-relative btn-style ml-1">
                            <fa-icon (click)="saveName(section, id)" class="text-primary" [icon]="faSave"></fa-icon>
                        </button>
                        <!-- <button class="position-relative ml-1 btn-style font-weight-light">
                            <fa-icon (click)="updateName = false;isSortable =true" class="text-danger" [icon]="faClose"></fa-icon>
                        </button> -->
                    </ng-container>
                </a>
                <ng-template ngbNavContent>
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <thead>
                            <tr>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "pim.elementTypes.fieldName" | translate
                                    }}
                                </th>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "pim.elementTypes.protocol" | translate
                                    }}
                                </th>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "pim.elementTypes.fieldType" | translate
                                    }}
                                </th>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "pim.elementTypes.fieldSource" | translate
                                    }}
                                </th>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "pim.elementTypes.fieldListMultiple" | translate
                                    }}
                                </th>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "pim.elementTypes.fieldPreviewable" | translate
                                    }}
                                </th>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "pim.elementTypes.fieldMandatory" | translate
                                    }}
                                </th>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "indicator.name" | translate
                                    }}
                                </th>
                                <th style="border: 0;" class="text-center text-truncate" scope="col">{{
                                        "table.actions" | translate
                                    }}
                                </th>
                            </tr>
                            </thead>
                            <tbody [appSortable]="sortableJSOptions">
                            <tr *ngFor="let control of formArray.controls[id].controls; let i = index">
                                <td style="min-width: 10rem;">
                                    <input class="form-control"
                                           [ngClass]="control.controls.name.valid ? 'is-valid' : 'is-invalid'"
                                           type="text" [formControl]="control.controls.name">
                                    <div *ngIf="control.controls.name.invalid && !control.controls.name.pristine"
                                         class="invalid-feedback">
                                            <span *ngIf="control.controls.name.hasError('required')">
                                                {{ "pim.elementTypes.error.required" | translate }}
                                            </span>
                                    </div>
                                    <div *ngIf="control.controls.name.invalid" class="invalid-feedback">
                                            <span *ngIf="control.controls.name.hasError('uniqueValue')">
                                                {{ "pim.elementTypes.error.uniqueField" | translate }}
                                            </span>
                                        <!-- <span *ngIf="control.controls.name.hasError('uniqueValueCrossTab')">
                                            {{"pim.elementTypes.error.uniqueCrossTabField" | translate }}
                                        </span> -->
                                    </div>
                                </td>
                                <td style="min-width: 10rem;">
                                    <input class="form-control"
                                           [ngClass]="control.controls.protocol.valid ? 'is-valid' : 'is-invalid'"
                                           type="text" [formControl]="control.controls.protocol">
                                    <div *ngIf="control.controls.protocol.invalid" class="invalid-feedback">
                                            <span *ngIf="control.controls.protocol.hasError('uniqueValue')">{{
                                                    "pim.elementTypes.error.unique" | translate
                                                }}</span>
                                        <span *ngIf="control.controls.protocol.hasError('uniqueValueCrossTab')">{{
                                                "pim.elementTypes.error.uniqueCrossTab" | translate
                                            }}</span>
                                    </div>
                                </td>
                                <td style="min-width: 10rem;">
                                    <!-- Keep in case, in future, we will want to save a default table value -->
                                    <!-- (change)="controlTableDefaultValue($event, formArray.controls[id], i)" -->
                                    <select class="form-control"
                                            [ngClass]="control.controls.fieldType.valid ? 'is-valid' : 'is-invalid'"
                                            [formControl]="control.controls.fieldType">
                                        <option
                                            *ngFor="let fieldType of fieldTypes | filterType:control.controls.fieldType.value:(control.controls.id.value !== -1)"
                                            [value]="fieldType">{{ "fieldType." + fieldType | translate }}
                                        </option>
                                    </select>
                                    <div *ngIf="control.controls.fieldType.invalid && !control.controls.fieldType.pristine"
                                         class="invalid-feedback">
                                            <span *ngIf="control.controls.fieldType.hasError('required')">{{
                                                "pim.elementTypes.error.required" | translate }}</span>
                                        </div>
                                    </td>
                                    <td style="min-width: 10rem;"
                                        *ngIf="control.value.fieldType !== 'list' && control.value.fieldType !== 'fieldset' && control.value.fieldType !== 'productLink' && control.value.fieldType !== 'table' && control.value.fieldType !== 'listcascade'">
                                        <input class="form-control" type="text" disabled
                                            value="{{ 'pim.elementTypes.noSource' | translate }}">
                                    </td>
                                    <td style="min-width: 10rem;" *ngIf="control.value.fieldType === 'list'">
                                        <select class="form-control"
                                            [ngClass]="(control.controls.source.valid || control.controls.source.disabled) ? 'is-valid' : 'is-invalid'"
                                            [formControl]="control.controls.source">
                                        <option *ngFor="let list of lists" [value]="list.id">{{ list.name }}
                                        </option>
                                    </select>
                                    <div *ngIf="control.controls.source.invalid && !control.controls.source.pristine && !control.controls.source.disabled"
                                         class="invalid-feedback">
                                            <span *ngIf="control.controls.source.hasError('required')">{{
                                                    "pim.elementTypes.error.required" | translate
                                                }}</span>
                                    </div>
                                </td>

                                <td style="min-width: 10rem;" *ngIf="control.value.fieldType === 'fieldset'">
                                    <section class="flex">
                                        <div>
                                            <select class="form-control"
                                                    [ngClass]="(control.controls.source.valid || control.controls.source.disabled) ? 'is-valid' : 'is-invalid'"
                                                    [formControl]="control.controls.source">
                                                <option *ngFor="let fieldset of fieldsets" [value]="fieldset.id">{{
                                                        fieldset.name
                                                    }}
                                                </option>
                                            </select>
                                            <div *ngIf="control.controls.source.invalid && !control.controls.source.pristine && !control.controls.source.disabled"
                                                 class="invalid-feedback">
                                            <span *ngIf="control.controls.source.hasError('required')">{{
                                                    "pim.elementTypes.error.required" | translate
                                                }}</span>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div *ngIf="inputs">
                                                <button (click)="openLinkInputRef(formArray.controls[id], i)"
                                                        [ngbTooltip]="'pim.fieldsets.inputReference'| translate"
                                                        class="ml-1 btn btn-primary">
                                                    <fa-icon [icon]="faLink"></fa-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                    <figure class="w-100 text mt-1" *ngIf="control.controls.source.value && control.value.collectionReferent">
                                        <div class="text-xs font-bold">
                                            {{ "pim.fieldsets.fieldReference"  | translate }} :
                                        </div>
                                        <figcaption class=" font-italic text-xs">
                                            <span>{{ getInputRefName(control.controls.source.value, control.value.collectionReferent) }}</span>
                                        </figcaption>

                                    </figure>
                                </td>
                                <td style="min-width: 10rem;" *ngIf="control.value.fieldType === 'productLink'">
                                    <select class="form-control"
                                            [ngClass]="(control.controls.source.valid || control.controls.source.disabled) ? 'is-valid' : 'is-invalid'"
                                            [formControl]="control.controls.source">
                                        <option *ngFor="let elementType of elementTypes" [value]="elementType.id">{{
                                                elementType.name
                                            }}
                                        </option>
                                    </select>
                                    <div *ngIf="control.controls.source.invalid && !control.controls.source.pristine && !control.controls.source.disabled"
                                         class="invalid-feedback">
                                            <span *ngIf="control.controls.source.hasError('required')">{{
                                                "pim.elementTypes.error.required" | translate }}</span>
                                        </div>
                                    </td>
                                    <td style="min-width: 10rem;" *ngIf="control.value.fieldType === 'listcascade'">
                                        <select class="form-control"
                                            [ngClass]="(control.controls.source.valid || control.controls.source.disabled) ? 'is-valid' : 'is-invalid'"
                                            [formControl]="control.controls.source">
                                            <option *ngFor="let listcascade of listcascades" [value]="listcascade.id">{{
                                                listcascade.name }}</option>
                                        </select>
                                    </td>
                                    <td style="min-width: 10rem;" class="tableOverlayPanelContainer"
                                        *ngIf="control.value.fieldType === 'table'">
                                        <div class="row ml-1">
                                            <button class="btn btn-primary btn-block text-left col-10"
                                                data-toggle="modal" data-target="#tableModal"
                                                (click)="op.hide(); openConfigureTableModal(formArray.controls[id], i)">
                                            {{ 'general.table.configure' | translate }}
                                        </button>

                                        <i class="pi pi-eye col-2" type="button"
                                           (click)="openConfigureTableModal(formArray.controls[id], i); op.toggle($event)"></i>
                                        <!-- (mouseover)="openConfigureTableModal(formArray.controls[id], i); op.toggle($event)"
                                            (mouseout)="op.toggle($event)" -->
                                        <!-- Can lead to error console about expression changed after checked -->
                                    </div>

                                    <!--<div id="{{'overlayPanelDiv-' + i}}"></div>-->
                                    <ng-template #overlayPanelDiv></ng-template>
                                    <!-- @TODO: Fix "ERROR Cannot append [object Object] to [object HTMLDivElement]" -->

                                    <p-overlayPanel #op class="tableOverlayPanel" [appendTo]="overlayPanelDiv"
                                                    [showCloseIcon]="true">
                                        <app-form-table [id]="'overlayPanelTable-' + i" [cells]="currentTable"
                                                        [overlayPanelMode]="true">
                                        </app-form-table>
                                    </p-overlayPanel>

                                    <div *ngIf="formArray.controls[id].value[currentControlIndex]"
                                         class="modal fade" id="tableModal" tabindex="-1" role="dialog"
                                         aria-labelledby="tableModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-xl" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="tableModalLabel">
                                                        {{ title }}
                                                    </h5>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <app-form-table
                                                        [id]="formArray.controls[id].value[currentControlIndex].id"
                                                        [cells]="currentTable" (cellsEmitter)="setCells($event)">
                                                    </app-form-table>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary"
                                                            data-dismiss="modal">{{
                                                            'general.close' | translate }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center" *ngIf="control.value.fieldType === 'list' || control.value.fieldType === 'media'">
                                        <label class="switch switch-success mb-0" style="padding-top: 5px;">
                                            <input type="checkbox" class="switch-input"
                                                [formControl]="control.controls.multiple">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </td>
                                    <td *ngIf="control.value.fieldType !== 'list' && control.value.fieldType !== 'media'"></td>
                                    <td class="text-center" *ngIf="control.value.fieldType !== 'media'">
                                        <label class="switch switch-success mb-0" style="padding-top: 5px;">
                                            <input type="checkbox" class="switch-input"
                                                [formControl]="control.controls.previewable">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </td>
                                    <td *ngIf="control.value.fieldType == 'media'"></td>
                                    <td class="text-center" *ngIf="control.value.fieldType !== 'listcascade' && control.value.fieldType !== 'media'">
                                        <label class="switch switch-success mb-0" style="padding-top: 5px;">
                                            <input type="checkbox" class="switch-input"
                                                   [formControl]="control.controls.mandatory">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </td>
                                    <td *ngIf="control.value.fieldType == 'listcascade' || control.value.fieldType == 'media'"></td>
                                    <td class="text-center" *ngIf="control.value.fieldType !== 'listcascade' && control.value.fieldType !== 'media'">
                                        <label class="switch switch-success mb-0" style="padding-top: 5px;">
                                            <input type="checkbox" class="switch-input"
                                                   [formControl]="control.controls.salabilityIndicator">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </td>
                                    <td *ngIf="control.value.fieldType == 'listcascade' || control.value.fieldType == 'media'"></td>
                                    <td class="d-flex text-center">
                                        <button [ngbTooltip]="'tooltip.delete'| translate" class="ml-auto btn btn-danger"
                                            (click)="deleteField(formArray.controls[id], i)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                    <button [ngbTooltip]="'tooltip.send' | translate"
                                            *ngIf="section.id !== -1 && control.controls.id.value !== -1"
                                            class="btn btn-warning text-white ml-1"
                                            (click)="openChangeSectionModal(formArray.controls[id], section.id, i)">
                                            <fa-icon [icon]="faSend"></fa-icon>
                                        </button>
                                        <button [ngbTooltip]="'tooltip.sort'| translate" class="btn btn-primary ml-1 sortable mr-auto">
                                            <fa-icon [icon]="faSort"></fa-icon>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="formArray.controls[id].controls.length === 0">
                                    <td colspan="9" class="text-center font-weight-bold">{{ "general.no.data"
                                        | translate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-template>
            </li>
            <li class="nav-item non-sortable" (click)="addSection()"
                [ngStyle]="{'pointer-events' : updateName ? 'none' : 'all'}">
                <a disabled class="nav-link">
                    <fa-icon class="text-primary" [icon]="faPlus"></fa-icon>
                </a>
            </li>
        </ul>

        <div class="mx-1 mb-2" [ngbNavOutlet]="nav"></div>
    </form>
</div>
<div class="card-footer">
    <div class="row">
        <span class="ml-auto"></span>
        <button class="btn btn-primary" *ngIf="isOnePreview()" (click)="setAllNoPreview()">
            <fa-icon [icon]="faNoPreview"></fa-icon>
            <span class="d-none d-md-inline-block ml-1">{{ 'general.field.nopreview' | translate }}</span>
        </button>
        <button class="btn btn-primary ml-2" *ngIf="isOneNoPreview()" (click)="setAllPreview()">
            <fa-icon [icon]="faPreview"></fa-icon>
            <span class="d-none d-md-inline-block ml-1">{{ 'general.field.preview' | translate }}</span>
        </button>
        <div class="btn-group ml-2">
            <button class="btn btn-primary" (click)="addField()">
                <fa-icon [icon]="faPlus"></fa-icon>
                <span class="d-none d-md-inline-block ml-1">{{ 'general.field.add' | translate }}</span>
            </button>
            <button [disabled]="formArray.invalid || formArray.controls.length === 0 || formArray.pristine"
                    class="btn btn-primary" (click)="saveTab()">
                <fa-icon [icon]="faSave"></fa-icon>
                <span class="d-none d-md-inline-block ml-1">{{ 'general.save' | translate }}</span>
            </button>
            <!-- {{ canChangeTab() }} -->
        </div>
    </div>
</div>

<swal #changeSectionModal title="{{ 'pim.elementTypes.change.sectionOrTab' | translate }}"
      confirmButtonText="{{ 'general.save' | translate }}" [showCloseButton]="true">
    <div *swalPortal>
        <div class="row form-group">
            <label class="col">{{ 'pim.elementTypes.change.destination' | translate }}</label>

            <div class="mb-1">
                <button class="btn-primary mr-2" pButton type="button" label="{{ 'datatable.expand' | translate }}"
                        (click)="expandRecursive(true)"></button>
                <button class="btn-primary" pButton type="button" label="{{ 'datatable.collapse' | translate }}"
                        (click)="expandRecursive(false)"></button>
            </div>
            <p-tree class="w-100" [value]="tabTypeList" selectionMode="single" [filter]="true" filterMode="strict"
                    [(selection)]="selectedSectionOrTab"></p-tree>
        </div>
    </div>
</swal>

<swal #linkInputRef [showCloseButton]="true" [showConfirmButton]="true">
    <div *swalPortal>
        <form [formGroup]="formLinkInputRef" class="mt-2">
            <div class="row input-group mt-3">
                <label class="mb-3 text alert alert-info">
                    <i class="pi pi-info-circle"></i>
                    {{ "pim.fieldsets.labelReference" | translate }}
                </label>

                <select class="custom-select" formControlName="input">
                    <option [value]="null"></option>
                    <option *ngFor="let input of inputs[actualSection.controls[actualField].get('source').value]" [value]="input.id">
                        {{ input.name }}
                    </option>
                </select>
            </div>
        </form>
    </div>
</swal>
