import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class AdminWorkflowService {
    constructor(private _http: HttpClient) {}

    /**
     * Get the list of users
     * @returns
     */
    getUsers(): Observable<any> {
        return this._http.get<any>(`${environment.pimUrl}/list_user.json`);
    }

    /**
     * Get the list of available's commands for a specific modulem
     * @param module
     * @returns
     */
    getCommands(module: string): Observable<any> {
        let env = this.getEnv(module);
        return this._http.get<any>(`${environment[env + "Url"]}/list_command.json`);
    }

    /**
     * Return element types
     * @returns
     */
    getElementTypes(): Observable<any> {
        return this._http.get<any>(`${environment.pimUrl}/element_type.json`);
    }

    /**
     * Return the list of transitions
     * @param module
     * @param objectId
     * @returns
     */
    getTransitions(module: string, objectId: number): Observable<any> {
        let env = this.getEnv(module);
        return this._http.get<any>(`${environment[env + "Url"]}/configuration/${objectId}.json`);
    }

    /**
     * Delete a step
     *
     * @param module
     * @param placeId
     * @param options
     * @returns
     */
    deletePlace(module: string, placeId: number, options: any): Observable<any> {
        let env = this.getEnv(module);
        return this._http.delete<any>(`${environment[env + "Url"]}/place/${placeId}.json`, options);
    }

    /**
     * Save the workflow
     *
     * @param module
     * @param formPlace
     * @param formTransition
     * @param formConfiguration
     * @returns
     */
    save(module: string, formPlace: FormGroup, formTransition: FormGroup, formConfiguration: FormGroup): Observable<any> {
        let env = this.getEnv(module);
        return this._http.post<any>(`${environment[env + "Url"]}/configuration.json`, {
            places: formPlace.value.places,
            transitions: formTransition.value.transitions,
            configuration: formConfiguration.value,
            module: module

        });
    }

    getEnv(module: string){
        let env = module;
        if(module === "cdf"){
            env = 'projects';
        }

        return env;
    }
}
