import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { UserService } from "app/core/services/admin/user/user.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { IMaestroEntity } from "app/shared/models";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SettingsConfigurationModule } from "./project.module";

@Injectable({
    providedIn: SettingsConfigurationModule,
})
export class ProjectGuard implements CanActivate, Resolve<IMaestroEntity[]> {
    constructor(private _service: ProjectService, private _userService: UserService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<IMaestroEntity[]> {
        const projectId = route.parent.parent.parent.params.id;

        if (projectId) {
            return forkJoin([
                this._service.getGroups(projectId),
                // this._service.groupAuths(),
                // this._service.getAuths(projectId),
                this._userService.getGroupsAndAssociated(true),
            ]).pipe(
                map(
                    (data) =>
                        <any>{
                            project: route.parent.parent.parent.data.project.data,
                            projectGroups: (data[0] as any).data,
                            userGroups: (data[1] as any).data,
                            // auth: (data[2] as any).data,
                            // auths: (data[3] as any).data,
                        }
                )
            );
        }
    }
}
