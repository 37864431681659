import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { CascadeSelectModule } from 'primeng-lts/cascadeselect';
import { FluxConfigurationComponent } from "./flux.component";

@NgModule({
    declarations: [FluxConfigurationComponent],
    imports: [SharedModule, SharedComponentsModule, DragDropModule, CascadeSelectModule],
    exports: [FluxConfigurationComponent],
})
export class FluxConfigurationModule {}
