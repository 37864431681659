import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { MessageFormComponent } from "./message-form.component";

@NgModule({
    declarations: [MessageFormComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [MessageFormComponent],
})
export class MessageFormModule {}
