import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroQuery } from "app/shared/models/dashboard";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments";

@Injectable()
export class DashboardService {
    constructor(private _http: HttpClient) {}

    getDashboardData(query: MaestroQuery): Observable<any> {
        return this._http.post(`${environment.dashboardUrl}/graphql.json`, query).pipe(catchError((_) => of([])));
    }
}
