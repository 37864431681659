<div id="flatplan-view" class="d-none d-md-block"></div>

<app-flatplan class="d-none d-md-block" [pages]="flatplan" [covers]="covers" (movePage)="onPageMove($event)"
    (pageAction)="onPageAction($event)" (reInitCover)="createCovers()"
    *ngIf="views[0] && viewType === views[0].type && 'thumbnail' === views[0].options">
</app-flatplan>

<app-pages-list *ngIf="!views.length || views[1] && viewType === views[1].type && 'list' === views[1].options" [pages]="flatplan" [activePanel]="globalMode" (movePage)="onPageMove($event)"
    (pageAction)="onPageAction($event)" [versionName]="this.versionName" [isFlatplan]="isExportFlatplan">
</app-pages-list>

<swal #newPage [width]="'95%'" title="{{ (isExportFlatplan ? 'projects.flatplan.new' : 'projects.flatplan.newExp') | translate }}" confirmButtonText="{{ 'general.create' | translate }}"
    (close)="isOpen = false" (cancel)="isOpen = false" [preConfirm]="preConfirm()" (confirm)="sendForm(pageMassCreationForm.value)" [showCancelButton]="true"
    cancelButtonText="{{ 'general.cancel' | translate }}" [cancelButtonColor]="'#FF0000'">
    <form *swalPortal [formGroup]="pageMassCreationForm">
        <div class="modal-body">
            <table class="table table-striped mb-0">
                <thead>
                    <tr>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'projects.flatplan.name' | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'general.template' | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'general.products' | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'table.actions' | translate }}</th>
                    </tr>
                </thead>
                <tbody [appSortable]="sortableJSOptions">
                    <tr *ngFor="let control of pageMassCreationForm.controls; let i = index">
                        <td class="td-cell">
                            <input type="text" [formControl]="control.controls.name" class="form-control"/>
                        </td>
                        <td class="td-cell">
                            <p-multiSelect id="template" [formControl]="control.controls.template" [options]="templateList" optionLabel="name" [selectionLimit]="1" defaultLabel="{{ 'breadcrumb.project.flatplan.noTemplate' | translate }}" [style]="{'width':'100%'}" (onChange)="updatePageProductLength(control)"></p-multiSelect>
                            <label for="template" class="col col-form-label text-center">
                                <span *ngIf="control.get('template').value.length">{{ control.get('template').value[0].nbPage + ' ' + ('projects.flatplan.page' | translate) }} / {{ control.get('template').value[0].nbProduct + ' ' + ('projects.flatplan.product' | translate) }}</span>
                            </label>
                        </td>
                        <td class="td-cell">
                            <p-multiSelect id="elements" [formControl]="control.controls.elementList" [options]="elements" optionLabel="name" defaultLabel="{{ 'breadcrumb.project.flatplan.noElement' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                            <label for="elements" class="col col-form-label text-center">
                                <div *ngIf="control.get('template').value.length && control.get('elementList').value.length">
                                    <span>{{ 'projects.flatplan.selectedProducts' | translate }} : {{ control.get('elementList').value.length }}</span>
                                    <span *ngIf="+control.get('elementsLength').value > 0">
                                        <br>
                                        {{ 'projects.flatplan.massCreation' | translate }} : {{ (Math.ceil((+control.get('elementList').value.length / +control.get('elementsLength').value))) }}
                                        <i class="pi pi-info-circle" pTooltip="{{ 'projects.flatplan.massCreationInfo' | translate }}<br><br><em>{{ 'projects.flatplan.massCreationExample' | translate }}</em>" tooltipZIndex="9999" [escape]="false"></i>
                                    </span>
                                </div>
                            </label>
                        </td>
                        <td>
                            <button [disabled]="pageMassCreationForm.length === 1" [ngbTooltip]="'tooltip.delete'| translate" class="btn btn-danger" (click)="pageMassCreationForm.removeAt(i)">
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button [disabled]="pageMassCreationForm.length === 1" [ngbTooltip]="'tooltip.move'| translate"  class="btn btn-primary ml-1 sortable">
                                <fa-icon [icon]="faSort"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="pageMassCreationForm.push(getBaseForm(false))">
                <fa-icon [icon]="faPlus"></fa-icon>
                <span class="d-none d-md-inline-block ml-1">{{ (isExportFlatplan ? 'breadcrumb.project.flatplan.add' : 'breadcrumb.project.flatplan.addExp') | translate }}</span>
            </button>
        </div>
    </form>
</swal>

<swal #updatePage title="{{ 'projects.flatplan.configurePage' | translate }}"
    confirmButtonText="{{ 'general.update' | translate }}" (close)="isOpen = false" (cancel)="isOpen = false" [preConfirm]="preConfirm()"
    (confirm)="warningUpdate(false)" [showCancelButton]="true" cancelButtonText="{{ 'general.cancel' | translate }}"
    [cancelButtonColor]="'#FF0000'">
    <form *swalPortal [formGroup]="form">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.name' | translate }}
                </label>
                <div class="col-8">
                    <input type="text" formControlName="name" class="form-control"/>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'general.template' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="template" [options]="templateList" optionLabel="name" [selectionLimit]="1" defaultLabel="{{ 'breadcrumb.project.flatplan.noTemplate' | translate }}" [style]="{'width':'100%'}" (onChange)="updatePageProductLength()"></p-multiSelect>
                </div>
            </div>
            <div class="form-group row"  *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="pageLength" class="form-control"
                    [attr.disabled]="true" />
                </div>
            </div>
            <div class="form-group row"  *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.element' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="elementsLength" class="form-control"
                    [attr.disabled]="true" />
                </div>
            </div>
            <div class="form-group row" >
                <label class="col-4 col-form-label text-left">
                    {{ 'general.products' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="elementList" [options]="elements" optionLabel="name" [selectionLimit]="selectElementLimit" defaultLabel="{{ 'breadcrumb.project.flatplan.noElement' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>
        </div>
    </form>
</swal>

<swal #updatePageDuplicate title="{{ 'projects.flatplan.configurePage' | translate }}"
    confirmButtonText="{{ 'general.update' | translate }}" (close)="isOpenDuplicate = false" (cancel)="isOpenDuplicate = false" [preConfirm]="preConfirm()"
    (confirm)="warningUpdate(true)" [showCancelButton]="true" cancelButtonText="{{ 'general.cancel' | translate }}"
    [cancelButtonColor]="'#FF0000'">
    <form *swalPortal [formGroup]="formUpdateDuplicate">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.name' | translate }}
                </label>
                <div class="col-8">
                    <input type="text" formControlName="name" class="form-control"/>
                </div>
            </div>
            <div class="form-group row" *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="pageLength" class="form-control" [attr.disabled]="true"/>
                </div>
            </div>
            <div class="form-group row" *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.element' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="elementsLength" class="form-control" [attr.disabled]="true"/>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'general.products' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="elementList" [options]="elements" optionLabel="name" [selectionLimit]="selectElementLimit" defaultLabel="{{ 'breadcrumb.project.flatplan.noElement' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>
        </div>
    </form>
</swal>

<swal #newPageDuplicate [width]="'95%'" title="{{ 'projects.flatplan.duplicatePages' | translate }}" confirmButtonText="{{ 'general.create' | translate }}"
    (close)="isOpenDuplicate = false" (cancel)="isOpenDuplicate = false" [preConfirm]="preConfirmDuplicate()" (confirm)="sendForm(pageMassDuplicationForm.value)" [showCancelButton]="true" [showConfirmButton]="true"
    cancelButtonText="{{ 'general.cancel' | translate }}" [cancelButtonColor]="'#FF0000'">
    <form *swalPortal [formGroup]="pageMassDuplicationForm">
        <div class="modal-body">
            <table class="table table-striped mb-0">
                <thead>
                    <tr>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'projects.flatplan.name' | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'general.referringPage' | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'general.products' | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'table.actions' | translate }}</th>
                    </tr>
                </thead>
                <tbody [appSortable]="sortableJSOptions">
                    <tr *ngFor="let control of pageMassDuplicationForm.controls; let i = index">
                        <td class="td-cell">
                            <input type="text" [formControl]="control.controls.name" class="form-control"/>
                        </td>
                        <td class="td-cell">
                            <p-multiSelect id="referringPage" [formControl]="control.controls.pages" [options]="pagesMaster" optionLabel="name" [selectionLimit]="1" defaultLabel="{{ 'general.select' | translate }}" [style]="{'width':'100%'}" (onChange)="updatePageDupliProductLength(control)"></p-multiSelect>
                            <label for="referringPage" class="col col-form-label text-center">
                                <span *ngIf="control.get('pages').value.length">{{ control.get('pages').value[0].pageLength + ' ' + ('projects.flatplan.page' | translate) }} / {{ control.get('pages').value[0].elementLength + ' ' + ('projects.flatplan.product' | translate) }}</span>
                            </label>
                        </td>
                        <td class="td-cell">
                            <p-multiSelect id="elements" [formControl]="control.controls.elementList" [options]="elements" optionLabel="name" defaultLabel="{{ 'breadcrumb.project.flatplan.noElement' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                            <label for="elements" class="col col-form-label text-center">
                                <div *ngIf="control.get('elementList').value.length">
                                    <span>{{ 'projects.flatplan.selectedProducts' | translate }} : {{ control.get('elementList').value.length }}</span>
                                    <span *ngIf="+control.get('elementsLength').value > 0">
                                        <br>
                                        {{ 'projects.flatplan.massCreation' | translate }} : {{ (Math.ceil((+control.get('elementList').value.length / +control.get('elementsLength').value))) }}
                                        <i class="pi pi-info-circle" pTooltip="{{ 'projects.flatplan.massCreationInfo' | translate }}<br><br><em>{{ 'projects.flatplan.massCreationExample' | translate }}</em>" tooltipZIndex="9999" [escape]="false"></i>
                                    </span>
                                </div>
                            </label>
                        </td>
                        <td>
                            <button [disabled]="pageMassDuplicationForm.length === 1" [ngbTooltip]="'tooltip.delete'| translate" class="btn btn-danger" (click)="pageMassDuplicationForm.removeAt(i)">
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button [disabled]="pageMassDuplicationForm.length === 1" [ngbTooltip]="'tooltip.move'| translate"  class="btn btn-primary ml-1 sortable">
                                <fa-icon [icon]="faSort"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="pageMassDuplicationForm.push(getDupliForm())">
                <fa-icon [icon]="faPlus"></fa-icon>
                <span class="d-none d-md-inline-block ml-1">{{ (isExportFlatplan ? 'breadcrumb.project.flatplan.add' : 'breadcrumb.project.flatplan.addExp') | translate }}</span>
            </button>
        </div>
    </form>
</swal>

<swal #newFlatplan title="{{ (isExportHtml ? 'projects.html.new' : 'projects.flatplan.create') | translate }}"
    confirmButtonText="{{ 'general.create' | translate }}" (cancel)="redirectToHome()" (close)="isOpenFlatplan = false"
    [preConfirm]="preConfirmFlatplan()" (confirm)="sendFormFlatplan(formFlatplan.value)">
    <form *swalPortal [formGroup]="formFlatplan">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-7 col-form-label text-left"
                    [ngClass]="{ 'text-danger': submittedFlatplan && formFlatplan.controls['pageLength'].invalid }">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-5">
                    <input type="number" min="0" formControlName="pageLength" class="form-control" />
                </div>
            </div>
        </div>
    </form>
</swal>

<swal [customClass]="'col-10'" #preview title="{{ 'projects.flatplan.preview' | translate }}"
    [showConfirmButton]="false" [showCancelButton]="false" [showCloseButton]="true">
    <div *swalPortal>
        <embed *ngIf="!isExportHtml" style="width: 100%; height: 85vh;" [src]="previewUrl" type="application/pdf">
        <iframe *ngIf="isExportHtml" style="width: 100%; height: 85vh;" [src]="previewUrl"></iframe>
    </div>
</swal>

<swal #importPDF title="{{'projects.flatplan.importPDF' | translate}}" [preConfirm]="preConfirmImportPDF()" (close)="OnCloseImportPdfModal()" [showCloseButton]="true" confirmButtonText="{{ 'general.create' | translate }}">
    <div *swalPortal>
        <input type="file" id="file-to-upload" accept="application/pdf" (change)="showPDF($event)"/>
        <embed *ngIf="selectPDF != ''" style="width: 100%; height: 85vh;" [src]="selectPDF"  type="application/pdf">
        <label *ngIf="selectPDF != '' && !savePdf" style="color: red;"> {{'projects.flatplan.onePagePdf' | translate}} </label>
    </div>

</swal> 
