import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { faComment, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { SocialService } from "app/core/services/project/export/social/social.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { UploadService } from "app/core/services/upload/upload.service";
import { Thumb } from "app/shared/models/thumb";

@Component({
    selector: "app-facebook",
    templateUrl: "./facebook.component.html",
})
export class FacebookComponent implements OnInit {
    facebookForm: FormGroup;
    medias = [];
    mediaTypes: {
        id: number;
        extension: string;
        header: string;
    }[] = [];

    mediasPreview = [];

    socialNetwork = [];

    lang: string;

    readonly like = faThumbsUp;
    readonly comment = faComment;
    readonly share = faShare;

    constructor(
        private _fb: FormBuilder,
        private _facebookService: SocialService,
        private _toastService: ToastService,
        private _translateService: TranslateService,
        private _damService: ThumbnailsService,
        private _uploadService: UploadService,
        private _sanitizer: DomSanitizer,
        private _layout: LayoutService,
        private _route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.facebookForm = this._fb.group({
            id: [-1],
            theme: ["", Validators.required],
            dateStart: ["", Validators.required],
            dateEnd: ["", Validators.required],
            textContent: ["", Validators.required],
            medias: [[]],
            likeable: [true],
            commentable: [true],
            shareable: [true],
            diffusion: ["", Validators.required],
            who: ["", Validators.required],
            //pageId: ["", Validators.required],
            hashtag: [""],
            socialNetwork: [[], Validators.required]
        });

        this.lang = this._translateService.currentLang;

        this.facebookForm.controls.medias.valueChanges.subscribe((values) => {
            this.mediasPreview = [];
            values.forEach((value) => {
                const media: Thumb = this.medias.find((m) => m.id === value.id);
                const header = this.mediaTypes.find((mt) => mt.extension === media.extension).header;
                this.mediasPreview.push(this._sanitizer.bypassSecurityTrustResourceUrl("data:" + header + ";base64," + media.thumb));
            });
        });

        this._uploadService.getMediaTypes().subscribe((mediaTypes) => {
            this.mediaTypes = mediaTypes;
        });

        this._damService.getMedias().subscribe((medias) => {
            this.medias = medias.filter((media) => ["png", "jpeg", "jpg", "gif", "tiff", "mp4", "avi"].includes(media.extension));
        });

        this._damService.getImages();

        this.socialNetwork = ["linkedin"];

        this._layout.breadcrumb.setPath({ name: this._route.parent.snapshot.data.export.name, routerLink: null }, 2);
    }

    /**
     * Publish the configuration to Facebook.
     *
     */
    publish(): void {
        if (this.facebookForm.invalid) {
            return;
        }

        const content = this.facebookForm.value;
        let exportId = this._route.parent.snapshot.data.export.id;
        this._facebookService.publishFacebook(exportId, content).subscribe(() => {
            this._toastService.show({ message: this._translateService.instant("projects.export.social.published"), type: "success" });
        });
    }
}
