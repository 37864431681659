import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { GroupService } from "app/core/services/admin/group/group.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { Observable, forkJoin, } from "rxjs";
import { map } from "rxjs/operators";


 

@Injectable()
export class UserListResolver implements Resolve<any> {


    constructor
    (
      private _groupeService: GroupService,
      private _userService: UserService
      
    ) 
       {}

 

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
      
        return forkJoin([
          this._groupeService.getAll(),
          this._userService.getProfiles()
        ]).pipe(
          map(
              (data: any) =>
                  <any>{
                      groups: data[0],
                      profiles: data[1],
                  }
          )
      );;
    }

}