import { FormGroup } from "@angular/forms";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { CanDeactivateComponent } from "app/shared/models";

export abstract class ConfigurationStepComponent<T> implements CanDeactivateComponent {
    submitted: boolean;
    readonly form: FormGroup;
    readonly faSave = faSave;

    abstract isDeactivable(): boolean;
    abstract onSubmit(): void;
    protected abstract _initForm(data?: T): FormGroup;
    protected abstract _formToObject(data?: FormGroup): T;
}
