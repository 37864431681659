import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { TwigCreateComponent } from "./twig-create.component";

@NgModule({
    declarations: [TwigCreateComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [TwigCreateComponent],
})
export class TwigCreateModule {}
