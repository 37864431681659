import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { SidebarContentComponent } from "../..";

@Component({
    selector: "app-sidebar-classic",
    templateUrl: "./sidebar-classic.component.html",
})
export class SidebarClassicComponent implements SidebarContentComponent<any>, OnInit {
    @ViewChild("list", { static: true }) list;

    faSort = faSort;
    options: any;
    currentFragment: any;

    constructor(private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        const url = this.router.routerState.snapshot.url;;
        this.route.fragment.subscribe((a) => {
            if (a) {
                this.currentFragment = a
            }
        });
        switch (true) {
            case url.includes('dam'):
                this.currentFragment = { name: 'dam' };
                break;
            case url.includes('pim'):
                this.currentFragment = { name: 'pim' };
                break;
            case url.includes('project'):
                this.currentFragment = { name: 'project' };
                break;
            default:
                break;
        }
    }
}
