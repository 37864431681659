import { NgModule } from "@angular/core";
import { ProjectDashboardModule } from "./dashboard/dashboard.module";
import { ProjectComponent } from "./project.component";
import { ProjectResolver } from "./project.resolver";
import { ProjectRoutingModule } from "./project.routing";

@NgModule({
    declarations: [ProjectComponent],
    imports: [ProjectRoutingModule, ProjectDashboardModule],
    providers: [ProjectResolver],
})
export class ProjectModule {}
