import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { AdminModuleGuard } from "app/core/guards/admin-module.guard";
import { DamModuleGuard } from "app/core/guards/dam-module.guard";
import { DashboardModuleGuard } from "app/core/guards/dashboard-module.guard";
import { UploadModuleGuard } from "app/core/guards/upload-module.guard";
import { AdminModule } from "app/modules/admin/admin.module";
import { DamModule } from "app/modules/dam/dam.module";
import { DashboardModule } from "app/modules/dashboard/dashboard.module";
import { UploadModule } from "app/modules/upload/upload.module";
import { activatedModules } from "src/environments/activated-modules";
import { PrivateComponent } from "./private.component";
import { PimModule } from "app/modules/pim/pim.module";
import { PimModuleGuard } from "app/core/guards/pim-module.guard";
import { ProjectsModuleGuard } from "app/core/guards/projects-module.guard";
import { ProjectsModule } from "app/modules/projects/projects.module";

const privateRoutes: Routes = [
    {
        path: ``,
        redirectTo: `${MAESTRO_ROUTES.dashboard.base}`,
        pathMatch: "full",
    },
    {
        path: "",
        component: PrivateComponent,
        children: [
            {
                canLoad: [UploadModuleGuard],
                path: "upload",
                loadChildren: () => UploadModule,
            },
        ],
    },
];

if (activatedModules.pim) {
    privateRoutes[1].children.push({
        canLoad: [PimModuleGuard],
        path: MAESTRO_ROUTES.pim.base,
        loadChildren: () => PimModule,
    });
}

if (activatedModules.dam) {
    privateRoutes[1].children.push({
        canLoad: [DamModuleGuard],
        path: MAESTRO_ROUTES.dam.base,
        loadChildren: () => DamModule,
    });
}

if (activatedModules.project) {
    privateRoutes[1].children.push({
        canLoad: [ProjectsModuleGuard],
        path: MAESTRO_ROUTES.projects.base,
        loadChildren: () => ProjectsModule,
    });
}

if (activatedModules.dashboard) {
    privateRoutes[1].children.push({
        canLoad: [DashboardModuleGuard],
        path: MAESTRO_ROUTES.dashboard.base,
        loadChildren: () => DashboardModule,
    });
}

if (activatedModules.admin) {
    privateRoutes[1].children.push({
        canLoad: [AdminModuleGuard],
        path: MAESTRO_ROUTES.admin.base,
        loadChildren: () => AdminModule,
    });
}

@NgModule({
    imports: [RouterModule.forChild(privateRoutes)],
    exports: [RouterModule],
})
export class PrivateRoutingModule {}
