import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ListListComponent, ListListResolver } from "./list";
import { ListUpdateComponent, ListUpdateResolver } from "./update";
import { ListListGuard } from "./list/list-list.guard";
import { ListUpdateGuard } from "./update/list-update.guard";
import { ConfirmGuard } from "app/core/guards/confirm.guard";

const listRoutes: Routes = [
    { path: "", component: ListListComponent, resolve: { lists: ListListResolver }, canActivate: [ListListGuard] },
    { path: "update/:id", component: ListUpdateComponent, resolve: { resources: ListUpdateResolver }, canActivate: [ListUpdateGuard], canDeactivate: [ConfirmGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(listRoutes)],
    exports: [RouterModule],
})
export class ListRoutingModule {}
