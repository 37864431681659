import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { SidebarClassicModule } from "./components/sidebar-classic/sidebar-classic.module";
import { SidebarRoutingModule } from "./components/sidebar-routing/sidebar-routing.module";
import { SidebarTreeNodeModule } from "./components/sidebar-tree-node/sidebar-tree-node.module";
import { SidebarComponent } from "./sidebar.component";

@NgModule({
    imports: [SidebarTreeNodeModule, SidebarRoutingModule, SidebarClassicModule, SharedModule],
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
})
export class SideBarModule {}
