import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faEdit, faSave, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { NgbNav, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { CodeModel } from "@ngstack/code-editor";
import { TemplateService } from "app/core/services/project/template/template.service";
import { IMaestroEntity } from "app/shared/models";
import { MAESTRO_ROUTES } from 'app/shared/routes';

@Component({
  selector: 'app-twig-create',
  templateUrl: './twig-create.component.html',
  styleUrls: ['./twig-create.component.scss']
})
export class TwigCreateComponent implements OnInit {


  faSave = faSave;
  faTimesCircle = faTimesCircle;
  faEdit = faEdit;
  currentSection: number = 0;
  update: number = 0;
  rename: number = 1;
  variables: any;
  console = console;

  form: FormGroup;
  code: string = `<!DOCTYPE html>
<html>
    <head>
        {% block head %}
            <title>{% block title %}{% endblock %} - My Webpage</title>
        {% endblock %}
    </head>
    <body>
        <div id="content">{% block content %}{% endblock %}</div>
        <div id="footer">{% block footer %}{% endblock %}</div>
    </body>
</html>`;

codeModel: CodeModel = {
  language: "twig",
  uri: "main.twig.html",
  value: this.code,
  dependencies: ["@types/node", "@ngstack/translate", "@ngstack/code-editor"],
};

options = {
  contextmenu: false,
  minimap: {
      enabled: false,
  },
  autoIndent: true,
  tabCompletion: false,
};

updateMode = false;
exportTypes: IMaestroEntity[];


@ViewChild("nav") nav: NgbNav;


  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _templateService: TemplateService,
    private fb: FormBuilder
    ) { }

  ngOnInit(): void {
    if(this._route.snapshot.data.exportType){
      this.exportTypes = this._route.snapshot.data.exportType;
      this.form = this.fb.group({
        twigName: ["", Validators.required],
        exportType: ["", Validators.required],
        twigContent: [this.codeModel],
        twigId: [""],
      });
    }
    else{
      this.exportTypes = this._route.snapshot.data.twig[0];
      this.prepareForm(this._route.snapshot.data.twig[1]);
    }

  }

  onSubmit()
  {
    let twig = this.form.value;
    const routeId = this._route.snapshot.params.id;
    if(this.updateMode)
    {
      this._templateService.updateTwig(twig.twigId, twig.twigName, twig.twigContent.value, twig.exportType, routeId).subscribe(() => {
        this.onBack();
      });

    }
    else{
      this._templateService.createTwig(twig.twigName, twig.twigContent.value, twig.exportType, null).subscribe(() => {
        this.onBack();
      })
    }
  }

  prepareForm(twig: any){

    this.updateMode = true;

    this.codeModel = {
      language: "twig",
      uri: "main.twig.html",
      value: twig.content,
      dependencies: ["@types/node", "@ngstack/translate", "@ngstack/code-editor"],
    };



    this.form = this.fb.group({
      twigName: [twig.name, Validators.required],
      exportType: [twig.exportType, Validators.required],
      twigContent: [this.codeModel],
      twigId: [twig.id],
    });

  }

  onBack(){
    this._router.navigate(["/projects", MAESTRO_ROUTES.projects.twig]);
  }

  twigValide(){
    let result = false;
    let twig = this.codeModel.value;
    if(twig.indexOf("<?xml") === 0 || twig.indexOf("<!DOCTYPE html>") === 0 || twig.indexOf("{") === 0 ){
      result = true;
    }

    return result;
  }

  /**
     * Update current section when switching
     * @param event
     */
   onTabChange(event: NgbNavChangeEvent): void {
      this.currentSection = event.nextId;
    }

}
