import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ViewButtonsComponent } from "./view-buttons.component";

@NgModule({
    imports: [SharedModule],
    declarations: [ViewButtonsComponent],
    exports: [ViewButtonsComponent],
})
export class ViewButtonsModule {}
