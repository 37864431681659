import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { MAESTRO_ROUTES } from "app/shared/routes";

import { RoleListComponent } from "./list/role-list.component";
import { RoleListGuard } from "./list/role-list.guard";
import { RoleFormComponent } from "./form/role-form.component";

const roleRoutes: Routes = [
    {
        path: "",
        component: RoleListComponent,
        canActivate: [RoleListGuard],
        resolve: { roles: RoleListGuard },
    },
    {
        path: `:id/${MAESTRO_ROUTES.actions.update}`,
        component: RoleFormComponent,
        data: { entity: "user", module: "adminApi", mode: "update" },
    },
];

@NgModule({
    imports: [RouterModule.forChild(roleRoutes)],
    exports: [RouterModule],
})
export class RoleRoutingModule {}
