import { Injectable } from "@angular/core";
import { MaestroElements, MaestroVersions } from "app/shared/models";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments";
import { ElementConfiguration, ElementConfigurations } from "../../../../shared/models/project/elements-configuration";
import { ProjectService } from "./project.service";

@Injectable()
export class ElementsConfigurationService extends ProjectService {
    /**
     * Get configurations for config step 2
     *
     * @param projectId
     * @returns
     */
    getConfiguration(projectId: number): Observable<ElementConfigurations> {
        return this._http.get(`${environment.projectsUrl}/project/${projectId}/product.json`).pipe(
            map((configs: any) =>
                configs.data.map(
                    (config) =>
                        <ElementConfiguration>{
                            ...config,
                            elements: config.elements.map((id) => ({ id, name: "" })),
                            fields: config.fields.map((id) => ({ id, name: "" })),
                            tags: config.tags.map((id) => ({ id })),
                            version: { id: config.version },
                        }
                )
            )
        );
    }

    /**
     * Save configuration for step 2
     * @param projectId
     * @param configs
     * @returns
     */
    saveConfiguration(projectId: number, configs: any) {
        let body = configs.data.map((config) => {
            return {
                ...config,
                version: config.version.id,
                fields: config.fields.map((field) => field.id),
                elements: config.elements.map((element) => element.id),
                tags: config.tags.map((tag) => tag.id),
            };
        });

        body = {
            lastStep: configs.lastStep,
            data: body,
        };

        return this._http.post(`${environment.projectsUrl}/project/${projectId}/product.json`, body);
    }

    /**
     * Get available versions when select a data model
     * @param elementTypeId
     * @returns
     */
    getVersionsByElementTypes(elementTypeIds: string) {
        return this._http.get(`${environment.projectsUrl}/element_type/versions.json?elementTypes=${elementTypeIds}`);
    }

    /**
     * Get elements with a specific data model & specific version
     * @param versionId
     * @param elementTypeId
     * @returns
     */
    getElementsByElementType(versionId: number, elementTypeId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/version/${versionId}/element_type/${elementTypeId}/elements.json`);
    }

    /**
     * Get fields from a data model
     * @param versionId
     * @param elementTypeId
     * @returns
     */
    getFieldsByElementType(versionId: number, elementTypeId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/version/${versionId}/element_type/${elementTypeId}/fields.json`);
    }

    /**
     * Get versions list group by data model
     * @param elementTypeIds
     * @returns
     */
    getVersionListByTypes(elementTypeIds: number[]): Observable<{ [typeId: number]: MaestroVersions }> {
        return forkJoin([this.getVersionsByElementTypes(JSON.stringify(elementTypeIds))]).pipe(
            map((resultArray) => {
                const mapping = {};
                Object.keys(resultArray[0]["data"]).forEach((key) => {
                    if (!mapping[key]) {
                        mapping[key] = [];
                    }
                    mapping[key] = resultArray[0]["data"][key];
                });

                return mapping;
            })
        );
    }

    /**
     * Get elements group by data model
     *
     * @param versionId
     * @param elementTypeIds
     * @returns
     */
    getElementsByElementTypes(versionId, elementTypeIds: number[]): Observable<{ [typeId: string]: MaestroElements }> {
        return forkJoin(elementTypeIds.map((id) => this.getElementsByElementType(versionId, id))).pipe(
            map((resultArray: any) => {
                const elementsAndTagsMapping = { elements: [], tags: [] };

                resultArray.forEach((maestroElements, index) => {
                    elementsAndTagsMapping.elements[elementTypeIds[index]] = maestroElements.data.elements;
                    elementsAndTagsMapping.tags[elementTypeIds[index]] = maestroElements.data.tags;
                });
                return elementsAndTagsMapping;
            })
        );
    }

    getElementAndFieldList(versionId: number, elementTypeIds: number[]): Observable<{ elementByType; fieldsByType; tagsByType }> {
        return forkJoin([this.getElementsByElementTypes(versionId, elementTypeIds), this.getFieldsByElementTypes(versionId, elementTypeIds)]).pipe(
            map((array) => ({
                elementByType: array[0].elements,
                fieldsByType: array[1],
                tagsByType: array[0].tags,
            }))
        );
    }

    private getFieldsByElementTypes(versionId: number, elementTypeIds: number[]): Observable<{ [typeId: string]: MaestroElements }> {
        return forkJoin(elementTypeIds.map((id) => this.getFieldsByElementType(versionId, id))).pipe(
            map((resultArray: any) => {
                const elementsMapping = {};
                resultArray.forEach((maestroElements, index) => {
                    elementsMapping[elementTypeIds[index]] = maestroElements.data;
                });
                return elementsMapping;
            })
        );
    }
}
