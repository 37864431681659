import { Pipe, PipeTransform } from "@angular/core";
import { Subscription } from "rxjs";

@Pipe({
    name: "unsubscribe",
})
export class UnsubscribePipe implements PipeTransform {
    transform(array: Subscription[]): void {
        return array.forEach((s: Subscription) => s.unsubscribe());
    }
}
