<div class="row justify-content-between">
    <div class="col-6 p-1" id="facebookConfig">
        <form class="p-1 bg-white border-primary border rounded-lg" [formGroup]="facebookForm">
            <div class="form-group">
                <label class="font-weight-bold" for="socialNetwork">{{ "projects.export.social.facebook.socialNetwork" | translate }}</label>
                <app-drop-down formControlName="socialNetwork" [list]="socialNetwork" [type]="'multi'"></app-drop-down>
            </div>
            <div class="form-group">
                <label class="font-weight-bold" for="theme">{{ "projects.export.social.facebook.theme" | translate }}</label>
                <input formControlName="theme" type="text" class="form-control" id="theme">
            </div>
            <div class="form-group">
                <label class="font-weight-bold" for="dateStart">{{ "projects.export.social.facebook.dateStart" | translate }}</label>
                <input formControlName="dateStart" type="date" class="form-control" id="dateStart">
            </div>
            <div class="form-group">
                <label class="font-weight-bold" for="dateEnd">{{ "projects.export.social.facebook.dateEnd" | translate }}</label>
                <input formControlName="dateEnd" type="date" class="form-control" id="dateEnd">
            </div>


            <div class="form-group">
                <label class="font-weight-bold" for="medias">{{ "projects.export.social.facebook.media" | translate }}</label>
                <app-drop-down formControlName="medias" [list]="medias" [type]="'multi'"></app-drop-down>
            </div>
            <div class="form-group">
                <label class="font-weight-bold" for="textContent">{{ "projects.export.social.facebook.textContent" | translate }}</label>
                <textarea maxlength="63206" formControlName="textContent" class="form-control" id="textContent" rows="3"></textarea>
            </div>
            <div class="form-group">
                <label class="font-weight-bold" for="hashtag">{{ "projects.export.social.facebook.hashtag" | translate }}</label>
                <input formControlName="hashtag" type="text" class="form-control" id="hashtag">
            </div>
            <div class="row p-0 m-0">
                <div class="col-6 p-0 m-0 mb-2">
                    <span class="font-weight-bold">{{ "projects.export.social.facebook.likeable" | translate }}</span>
                    <label class="ml-2 pt-3 switch switch-pill switch-primary">
                        <input type="checkbox" class="switch-input" formControlName="likeable">
                        <span class="switch-slider"></span>
                    </label>
                    <br>
                    <span class="font-weight-bold">{{ "projects.export.social.facebook.commentable" | translate }}</span>
                    <label class="ml-2 pt-3 switch switch-pill switch-primary">
                        <input type="checkbox" class="switch-input" formControlName="commentable">
                        <span class="switch-slider"></span>
                    </label>
                    <br>
                    <span class="font-weight-bold">{{ "projects.export.social.facebook.shareable" | translate }}</span>
                    <label class="ml-2 pt-3 switch switch-pill switch-primary">
                        <input type="checkbox" class="switch-input" formControlName="shareable">
                        <span class="switch-slider"></span>
                    </label>
                </div>

                <div class="col-6 p-0 m-0 mb-2">
                    <div class="form-group">
                        <label class="font-weight-bold" for="diffusion">{{ "projects.export.social.facebook.diffusion" | translate }}</label>
                        <select formControlName="diffusion" class="form-control" id="diffusion">
                            <option> Tout le monde </option>
                            <option> Amis seulement </option>
                            <option> Personne </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold" for="who">{{ "projects.export.social.facebook.who" | translate }}</label>
                        <select formControlName="who" class="form-control" id="who">
                            <option> Tout le monde </option>
                            <option> Amis seulement </option>
                            <option> Personne </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="d-flex">
                <button *ngIf="facebookForm.value.id !== -1" type="button" class="mt-2 btn btn-danger">{{ "projects.export.social.delete" | translate }}</button>
                <button *ngIf="facebookForm.value.id !== -1" type="button" [disabled]="facebookForm.invalid" class="mt-2 btn btn-update">{{ "projects.export.social.update" | translate }}</button>
                <button *ngIf="facebookForm.value.id === -1" type="button" [disabled]="facebookForm.invalid" (click)="publish()" class="mt-2 btn btn-primary btn-block">{{ "projects.export.social.publish" | translate }}</button>
            </div>
        </form>
    </div>
    <div class="col-6 p-1" id="facebookPreview">
        <div class="bg-white p-1 border-primary border rounded-lg">
            <div class="row">
                <img class="col-3 mb-2" *ngFor="let media of mediasPreview" [src]="media" alt="A media">
            </div>

            <!-- <span class="font-weight-light font-italic text-primary">{{ facebookForm.value.dateStart ? (("projects.export.social.publicationDate" | translate) + " " + (facebookForm.value.dateStart | date:'shortDate':'':lang)) : ("projects.export.social.now" | translate) }}</span> -->
            <span class="font-weight-light font-italic text-primary">{{ facebookForm.value.dateStart}}</span>

            <hr *ngIf="mediasPreview.length > 0">
            <div style="min-height: 7rem;">{{ facebookForm.value.textContent }}</div>
            <hr>
            <div class="row justify-content-center mb-2 font-weight-bold text-info">
                <div class="col-4 text-center pointer">
                    <ng-container *ngIf="facebookForm.value.likeable">
                        <fa-icon [icon]="like"></fa-icon>
                        <span class="ml-1">{{ "projects.export.social.like" | translate }}</span>
                    </ng-container>
                </div>
                <div class="col-4 text-center pointer">
                    <ng-container *ngIf="facebookForm.value.commentable">
                        <fa-icon [icon]="comment"></fa-icon>
                        <span class="ml-1">{{ "projects.export.social.comment" | translate }}</span>
                    </ng-container>
                </div>
                <div class="col-4 text-center pointer">
                    <ng-container *ngIf="facebookForm.value.shareable">
                        <fa-icon [icon]="share"></fa-icon>
                        <span class="ml-1">{{ "projects.export.social.share" | translate }}</span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
