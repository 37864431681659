import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { UploadComponent } from "./upload.component";
import { UploadRoutingModule } from "./upload.routing";
import { UploaderComponent } from "./uploader/uploader.component";

@NgModule({
    declarations: [UploaderComponent, UploadComponent],
    imports: [UploadRoutingModule, SharedModule],
})
export class UploadModule {}
