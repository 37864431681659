import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { FieldsetService } from "app/core/services/pim/fieldset.service";
import { MaestroFieldsets } from "app/shared/models";
import { Observable } from "rxjs";
import { FieldsetListModule } from "./fieldset-list.module";

@Injectable()
export class FieldsetListResolver implements Resolve<MaestroFieldsets> {
    constructor(private service: FieldsetService) {}

    resolve(): Observable<MaestroFieldsets> {
        return this.service.getFieldsets();
    }
}
