import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ProjectWorkflowListComponent } from "./project-workflow-list.component";
import { ProjectWorkflowListGuard } from "./project-workflow-list.guard";

@NgModule({
    declarations: [ProjectWorkflowListComponent],
    imports: [SharedComponentsModule, SharedModule],
    providers: [ProjectWorkflowListGuard],
})
export class ProjectWorkflowListModule {}
