import { Injectable } from "@angular/core";
import { MaestroElement, Tabs } from "app/shared/models";
import { Document, HeadingLevel, Packer, Paragraph, Table, TableCell, TableRow, TextRun, WidthType } from "docx";
import { saveAs } from "file-saver";

@Injectable()
export class ElementExportDocxService {
    element: MaestroElement;
    tabs: Tabs = [];
    docxContent: any[];

    generate(element, tabs) {
        this.element = element;
        this.tabs = tabs;

        const doc = new Document();

        /**
         * Titre
         */
        this.docxContent = [];
        this.docxContent.push(
            new Paragraph({
                text: this.element.name,
                heading: HeadingLevel.HEADING_1,
            })
        );

        /**
         * Type
         */
        this.docxContent.push(this._newParagraph("Datamodel : " + this.element.elementType, true));

        /**
         * Gestion des tabs et de leurs champs
         */
        this.tabs.forEach((tab) => {
            this.docxContent.push(
                new Paragraph({
                    text: tab.name,
                    heading: HeadingLevel.HEADING_2,
                })
            );

            const rows = [];
            rows.push(
                this._newRow([
                    { text: "Champ", bold: true },
                    { text: "Valeur", bold: true },
                ])
            );

            tab.fields.forEach((field) => {
                this.formatFieldDocx(field).forEach((row) => {
                    rows.push(row);
                });
            });

            this.docxContent.push(
                new Table({
                    rows: rows,
                    width: {
                        size: 1000,
                        type: WidthType.PERCENTAGE,
                    },
                })
            );
        });

        /**
         * Intégration du contenu
         */
        doc.addSection({
            properties: {},
            children: this.docxContent,
        });

        /**
         * Téléchargement du document
         */
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, this.element.name + ".docx");
        });
    }

    private formatFieldDocx(field): Array<TableRow> {
        /**
         * Gestion des champs simples
         */
        if (field.elementValue) {
            if (field.elementValue[0].length > 1) {
                return [this._newRow([{ text: "error", bold: true }, { text: "value is array" }])];
            }
            if (Array.isArray(field.elementValue[0].value)) {
                return [this._newRow([{ text: field.name, bold: true }, { text: JSON.stringify(field.elementValue[0].value) }])];
            }

            return [this._newRow([{ text: field.name, bold: true }, { text: field.elementValue[0].value }])];
        } else if (field.collections) {
            /**
             * Gestion des collections
             */
            const collectionRows = [];

            collectionRows.push(
                this._newRow([
                    { text: "Champ", bold: true },
                    { text: "Valeur", bold: true },
                ])
            );

            field.collections.forEach((collection) => {
                collection.elementValueView.forEach((collectionField) => {
                    collectionRows.push(
                        this._newRow([{ text: collectionField.input, bold: true }, { text: Array.isArray(collectionField.value) ? collectionField.value.join(", ") : collectionField.value }])
                    );
                });
            });

            return [
                new TableRow({
                    children: [
                        this._newCell(field.name, true),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: collectionRows,
                                    width: {
                                        size: 1000,
                                        type: WidthType.PERCENTAGE,
                                    },
                                }),
                            ],
                        }),
                    ],
                }),
            ];
        } else if (field.sections) {
            /**
             * Gestion des sections
             */
            const sectionRows: TableRow[] = [];
            Object.keys(field.sections).forEach((section) => {
                sectionRows.push(this._newRow([{ text: section, bold: true }]));

                field.sections[section].forEach((sectionField) => {
                    this.formatFieldDocx(sectionField).forEach((sectionRow) => {
                        sectionRows.push(sectionRow);
                    });
                });
            });
            return sectionRows;
        } else {
            return [this._newRow([{ text: "error", bold: true }, { text: "unknown type" }])];
        }
    }

    /**
     * Génération d'une ligne de tableau à cellules variables
     * @param data: { text: string; bold?: boolean }
     */
    private _newRow(data: { text: string; bold?: boolean }[]): TableRow {
        const cells = [];
        data.forEach((cell) => {
            cells.push(this._newCell(cell.text, cell.bold));
        });
        return new TableRow({
            children: cells,
        });
    }

    /**
     * Génération d'une cellule de tableau
     * @param text: string
     * @param bold: boolean
     */
    private _newCell(text, bold = null): TableCell {
        return new TableCell({
            children: [this._newParagraph(text, bold)],
        });
    }

    /**
     * Génération d'un paragraphe
     * @param text: string
     * @param bold: boolean
     */
    private _newParagraph(text, bold = null): Paragraph {
        return new Paragraph({
            children: [
                new TextRun({
                    text: text,
                    bold: bold,
                }),
            ],
        });
    }
}
