import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES as routes } from "app/shared/routes";
import { ProjectConfigurationModule } from "./configuration/configuration.module";
import { ProjectDashboardComponent } from "./dashboard/dashboard.component";
import { ProjectDashboardResolver } from "./dashboard/dashboard.resolver";
import { ProjectExportModule } from "./export/export.module";
import { ProjectComponent } from "./project.component";
import { ProjectResolver } from "./project.resolver";

const projectRouting: Routes = [
    {
        path: ":id",
        redirectTo: `:id/${routes.projects.dashboard}`,
    },
    {
        path: ":id",
        component: ProjectComponent,
        resolve: { project: ProjectResolver },
        children: [
            {
                path: routes.projects.dashboard,
                component: ProjectDashboardComponent,
                resolve: { resources: ProjectDashboardResolver },
            },
            { path: routes.projects.configure, loadChildren: () => ProjectConfigurationModule },
            { path: routes.projects.export, loadChildren: () => ProjectExportModule },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(projectRouting)],
    exports: [RouterModule],
})
export class ProjectRoutingModule { }
