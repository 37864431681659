import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ElementService } from "app/core/services/pim/element.service";
import { MaestroElement } from "app/shared/models";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ElementViewResolver implements Resolve<ElementViewResources> {
    constructor(private _elementService: ElementService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ElementViewResources> {
        return forkJoin([this._elementService.getElement(route.params.id), this._elementService.getElementTabs(route.params.id)]).pipe(
            map((data: any) => <ElementViewResources>{ element: data[0].data, tabs: data[1].data })
        );
    }
}

export class ElementViewResources {
    element: MaestroElement;
    tabs: any;
}
