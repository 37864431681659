        <div>
            <button *ngIf="!field.addCollection && field.editable === true" class="btn" (click)="addCollection(field, nav)">
                <fa-icon class="text-primary" [icon]="faPlus"></fa-icon>
            </button>
        </div>
        <ul ngbNav #nav="ngbNav" class="nav-tabs mt-3" [ngClass]="{ 'not-sortable': disabled}" style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; width: 500px;" [appSortable]="sortableJSOptions">
            <li *ngFor="let fieldset of field.value; let i = index" [ngbNavItem]="i">
                <a ngbNavLink class="sortable">
                    <div>{{ (i + 1) }}
    
                        <span *ngIf="!disabled" class="position-relative ml-2 font-weight-light">
                            <fa-icon (click)="removeCollection($event, i,nav)" class="text-danger" [icon]="faTrash"></fa-icon>
                        </span>
            </div>
        </a>
        <ng-template ngbNavContent>
            <div class="form-group row" *ngFor="let subField of fieldset">
                <label class="col-12 col-form-label text-truncate" for="">{{ subField.name }}</label>
                <div class="col-12">
                    <!-- Text -->
                    <input [disabled]="disabled || !subField.editable" (change)="updateValue($event.target.value, subField)" value="{{ subField.value }}" type="text" class="form-control"
                           *ngIf="subField.fieldType === 'text'"/>
                    <!-- Number -->
                    <input [disabled]="disabled || !subField.editable" (change)="updateValue($event.target.value, subField)" (keypress)="checkIfNumber($event)" value="{{ subField.value }}"
                           type="number" class="form-control" *ngIf="subField.fieldType === 'number'"/>
                    <!-- Textarea -->
                    <app-text-editor [disabled]="disabled || !subField.editable" *ngIf="subField.fieldType === 'textarea'" [value]="subField.value"
                                     (focusOut)="updateValue($event, subField)"></app-text-editor>
                    <!-- Checkbox -->
                    <div class="form-group" *ngIf="subField.fieldType === 'checkbox'">
                        <div class="col-10">
                            <label class="switch switch-pill switch-primary">
                                <input [disabled]="disabled || !subField.editable"
                                       (change)="updateValue($event.target.checked, subField)" type="checkbox"
                                       class="switch-input" [checked]="subField.value === '1'"
                                       value="{{ subField.value }}">
                                <span class="switch-slider"></span>
                            </label>
                        </div>
                    </div>

                    <!-- Date -->
                    <app-date-picker *ngIf="subField.fieldType === 'date'"
                                     [active]="!disabled || subField.editable" (dateSelect)="updateValue($event, subField)" class="form-control p-0"
                                     [minDate]="{ year: 1930, month: 1, day: 1 }" [value]="subField.value"></app-date-picker>

                    <!-- List / Simple selection -->
                    <select [disabled]="disabled || !subField.editable || blockList" (change)="updateValueList($event.target.value, subField)" [(ngModel)]="subField.value" class="form-control"
                            *ngIf="subField.fieldType === 'list'">
                        <option></option>
                        <option *ngFor="let value of subField.listValue">{{ value }}</option>
                    </select>
                    <!-- List / Multiple selection -->
                    <app-edit-list-multiple [disabled]="blockList" (saveList)="updateValueList($event, subField)" [field]="subField" *ngIf="subField.fieldType === 'list multiple'"></app-edit-list-multiple>

                    <!-- Fieldset -->
                    <app-edit-fieldset [field]="subField" *ngIf="subField.fieldType === 'fieldset' || subField.fieldType === 'productLink'"></app-edit-fieldset>

                    <!-- Table -->
                    <app-edit-table *ngIf="subField.fieldType === 'table'" [isInput]="true" [field]="subField" (_saveValue)="_saveValue($event)"></app-edit-table>

                    <!-- Color -->
                    <app-color-picker-maestro
                        *ngIf="subField.fieldType === 'colors'"
                        (colorSelect)="updateValue($event, subField)"
                        [value]="subField.value"></app-color-picker-maestro>
                        
                    <!-- Media -->
                    <ng-container *ngIf="subField.fieldType === 'media'">
                        <app-edit-media [loadDataOnInit]="false" (mediasSaved)="updateValue($event, subField)" [medias]="subField.value" class="col-10 p-0" [disabled]="disabled || !subField.editable" [pageDuplicateId]="undefined !== pageDuplicateId ? pageDuplicateId : -1" [singleSelection]="!subField.multiple" [forElementMedia]="false" [idElementValue]="subField.valuePimId" [idElementValueProject]="subField.valueProjectId ? subField.valueProjectId : -1"></app-edit-media>
                    </ng-container>
                </div>
                <div class="col-2" *ngIf="subField.fieldType !== 'productLink' && subField.fieldType !== 'fieldset'">
                    <svg *ngIf="subField.valueProjectId && !subField.isLoading" (click)="emitHistory(subField.valueProjectId, subField.fieldType)" style="width: 1.1rem; height: 1.1rem"
                         class="mt-2 pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <div *ngIf="subField.isLoading" class="spinner-border mx-auto text-primary spinner-border-sm align-middle" style="vertical-align: -webkit-baseline-middle !important;"
                         role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

