import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { RoleListComponent } from "./role-list.component";
import { RoleListGuard } from "./role-list.guard";

@NgModule({
    declarations: [RoleListComponent],
    imports: [SharedModule, SharedComponentsModule],
    providers: [RoleListGuard],
})
export class RoleListModule {}
