<ul ngbNav #nav="ngbNav" class="nav-tabs"
    style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; max-width: 100%;">
    <li ngbNavItem (click)="displaySortableStates = [];" *ngFor="let sectionName of sectionsNames; let i = index"
        [ngbNavItem]="i">
        <a ngbNavLink class="text-truncate" [ngClass]="{ 'font-weight-bold': nav.activeId === i }">{{ sectionName !== 'unnamedSection' ? sectionName : ('general.unnamedSection' | translate) }}</a>
        <ng-template ngbNavContent>
            <div *ngFor="let field of sections[sectionName]; let sf = index" class="row mb-1">
                <div style="width: 100%;"
                    [ngClass]="{'row' : field.fieldType === 'fieldset','col-11' : field.fieldType !='fieldset'}"
                    class=" form-group d-flex p-0">
                    <label [ngClass]="{'col-12' : field.fieldType === 'fieldset','col-3' :field.fieldType !='fieldset','ml-3' : field.fieldType === 'fieldset' }" class="col-form-label font-weight-bold text-truncate">
                        <i class="mr-1 text-danger pi pi-times-circle" *ngIf="(field.mandatory && field.fieldType !== 'fieldset' && field.fieldType !== 'checkbox'
                        && (!field.elementValue ||  isValueEmpty(field.elementValue[0]?.value) 
                        || ['',null,undefined,'<p></p>',false,'null'].includes(field.elementValue[0].value))) 
                        || (field.fieldType === 'fieldset' && (!field.collections || field.collections.length === 0))" pTooltip="{{ 'pim.elementTypes.fieldMandatory' | translate }}"></i>
                        
                        <i class="mr-1 text-warning pi pi-exclamation-triangle" *ngIf="(field.salabilityIndicator && field.fieldType !== 'fieldset' && field.fieldType !== 'checkbox'
                        && (!field.elementValue || isValueEmpty(field.elementValue[0].value) 
                        || ['',null,undefined,'<p></p>',false,'null'].includes(field.elementValue[0].value))) 
                        ||  (field.fieldType === 'fieldset' && (!field.collections || field.collections.length === 0))" pTooltip="{{ 'indicator.name' | translate }}"></i>
                        <span pTooltip="{{ field.name }}">{{ field.name }}</span>
                    </label>
                    <!-- Text & Number-->
                    <input (change)="saveField(field, $event.target.value, sectionName)" [disabled]="!setValue"
                        class="form-control col-9" [value]="field.elementValue ? field.elementValue[0].value : null"
                        *ngIf="field.fieldType === 'text' || field.fieldType === 'number'" type="{{ field.fieldType }}"
                        step="0.01">
                    <!-- Select -->
                    <select (change)="saveField(field, $event.target.value, sectionName)" [disabled]="!setValue"
                        class="form-control col-9" *ngIf="field.fieldType === 'list' && !field.multiple">
                        <option></option>
                        <option
                            [selected]="checkOptionSelected(field.elementValue ? field.elementValue[0].value : null, value)"
                            *ngFor="let value of field.list" [value]="value.id">{{ value.name }}
                        </option>
                    </select>
                    <!-- Checkbox -->
                    <label class="switch switch-pill switch-primary" *ngIf="field.fieldType === 'checkbox'">
                        <input (change)="saveField(field, $event.target.checked, sectionName)" [disabled]="!setValue" type="checkbox"
                            class="switch-input col-10"
                            [checked]="field.elementValue ? field.elementValue[0].value == '1' || field.elementValue[0].value == true : false">
                        <span class="switch-slider"></span>
                    </label>
                    <!-- Date -->
                    <app-date-picker (dateSelect)="saveField(field, $event, sectionName)" class="col-9 p-0"
                        [minDate]="{ year: 1930, month: 1, day: 1 }"
                        [value]="field.elementValue ? field.elementValue[0].value : null" [active]="setValue"
                        *ngIf="field.fieldType === 'date'"></app-date-picker>
                    <!--Multiselect + PL&ndash;&gt;-->
                    <ng-multiselect-dropdown (click)="getOldListValue(field.elementValue[0].value)" (onSelectAll)="askToSelectOrDeselectAll(field, field.elementValue[0], sectionName)"
                        (onDeSelectAll)="askToSelectOrDeselectAll(field, field.elementValue[0], sectionName)"
                        (onDeSelect)="saveField(field, field.elementValue[0].value, sectionName)"
                        (onSelect)="saveField(field, field.elementValue[0].value, sectionName)" [disabled]="!setValue"
                        [(ngModel)]="field.elementValue[0].value" class="p-0"
                        [ngClass]="field.elementValue[0].value == null || field.elementValue[0].value.length == 0 ? 'col-9' : (!displaySortableStates[sf] ? 'col-7' : 'col-4')"
                        *ngIf="field.fieldType === 'productLink' || (field.fieldType === 'list' && field.multiple)"
                        [settings]="dropdownSettings" [data]="field.list"
                        placeholder="{{ 'general.select' | translate }}">
                    </ng-multiselect-dropdown>
                    <div *ngIf="displaySortableStates[sf] && displaySortableStates[sf] == true" class="col p-0 ml-1">
                        <div [appSortable]="sortablejsOptionsItems" class="d-flex flex-wrap">
                            <div *ngFor="let item of field.elementValue[0].value; let index = index"
                                class="item-selection">
                                <div class="item-content">
                                    <div class="truncate-text">
                                        <p class="text-item">{{ item.name }}</p>
                                    </div>
                                    <span (click)="deleteListValue(sectionName,sf,index)"
                                        class="font-weight-bold mx-1 cursor-pointer">x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-0 text-right" [ngClass]="{'col' : !displaySortableStates[sf], 'col-1' : displaySortableStates[sf] && displaySortableStates[sf] == true}"
                        *ngIf="(field.fieldType === 'productLink' || (field.fieldType === 'list' && field.multiple)) && field.elementValue[0].value !== null && field.elementValue[0].value?.length > 0">
                        <button
                            (click)="displaySortable(sectionName, sf)"
                            class="btn btn-primary" *ngIf="!displaySortableStates[sf]; else elseBlock"
                            ngbTooltip="{{ 'general.reorderList' | translate }}">
                            <i class="pi pi-arrows-h" style="font-size: 1rem"></i>
                        </button>

                        <ng-template #elseBlock>
                            <button class="btn btn-primary" (click)="displaySortable(sectionName, sf)" ngbTooltip="{{ 'general.hideList' | translate }}">
                                <i class="pi pi-eye-slash"  style="font-size: 1rem"></i>
                            </button>

                        </ng-template>
                    </div>
                    <!-- <section class="w-100 col-9" *ngIf="field.fieldType === 'productLink' || (field.fieldType === 'list' && field.multiple)">
                        <div class="row">
                            <div [ngClass]="!displaySortableStates[sf] ?'col-11' : 'col-5'">
                            </div>

                        </div>
                    </section> -->

                    <!--                    <div class="container" *ngIf="field.fieldType === 'productLink' || (field.fieldType === 'list' && field.multiple)">-->
                    <!--                        <div class="row">-->
                    <!--                            <div-->
                    <!--                                [appSortable]="sortablejsOptionsListLeft" class="col-6">-->
                    <!--                                <div class="selected" *ngFor="let list of field.list" id="{{ list.id}}">{{ list.name }}</div>-->
                    <!--                            </div>-->
                    <!--                            <div [appSortable]="sortablejsOptionsListRight" class="col-6"></div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <br />

                    <!-- ListCascade -->
                     <div class="col-9" *ngIf="field.fieldType === 'listcascade'">
                         <div class="mb-2 row p-0">
                             <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="_dataService.expandCollapse('expand',field.list)">{{ 'datatable.expand' | translate }}</button>
                             <button type="button" class="btn btn-primary ml-1" data-dismiss="modal" (click)="_dataService.expandCollapse('collapse',field.list)">{{ 'datatable.collapse' | translate }}</button>
                         </div>
                         <div class="row">
                             <p-tree class="col-12 p-0" [value]="getSelectedListCascade(field.list, field.elementValue[0].value)" selectionMode="checkbox"  [(selection)]="field.elementValue[0].value" [filter]="true" filterMode="strict" [propagateSelectionUp]="true" [propagateSelectionDown]="false" (onNodeSelect)="onSelect(field, sectionName, $event)" (onNodeUnselect)="onSelect(field, sectionName, $event)"></p-tree>
                         </div>
                     </div>
                    <!-- Textarea -->
                    <app-text-editor (focusOut)="saveField(field, $event, sectionName)" class="col-9 p-0" [disabled]="!setValue"
                        [value]="field.elementValue ? field.elementValue[0].value : null"
                        *ngIf="field.fieldType === 'textarea'"></app-text-editor>
                    <!-- Color -->
                    <app-color-picker-maestro (colorSelect)="saveField(field, $event, sectionName)"
                        *ngIf="field.fieldType === 'colors'"
                        [value]="field.elementValue ? field.elementValue[0].value : null"></app-color-picker-maestro>

                    <!-- Fieldset -->

                    <ng-container *ngIf="field.fieldType === 'fieldset'">
                        <div class="d-block col-12 ml-3">
                            <ul  [id]="'fs-' + field.id + '-' + sectionName" [appSortable]="sortableJSOptions" ngbNav
                                #navFs="ngbNav" class=" nav-tabs col-10" [ngClass]="{ 'not-sortable': !setValue}" 
                                style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; max-width: 100%;margin-left: 2.2em;">
                                <li (click)="displaySortableStates =[]" class="d-inline-block" ngbNavItem
                                    *ngFor="let collection of field.collections; let j = index" [ngbNavItem]="j">
                                    <!--
                                    <a class="text-truncate d-flex sortable fieldset" ngbNavLink>
                                        {{ (j + 1) }}
                                        <span class="position-relative ml-2 font-weight-light">
                                            <fa-icon (click)="removeCollection($event, field, collection.id)"
                                                     class="text-danger" [icon]="faTrash"></fa-icon>
                                        </span>
                                    </a>
                                    -->
                                    <a class="text-truncate d-flex sortable fieldset" ngbNavLink>
                                        <ng-container
                                            *ngIf="!(field.collectionReferent && isString(getInputValueRef(collection.elementValueView, field.collectionReferent, j)))">
                                            <span>{{ (j + 1) }}</span>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="field.collectionReferent && isString(getInputValueRef(collection.elementValueView, field.collectionReferent, j))">
                                            <span class="content"
                                                title="{{ getInputValueRef(collection.elementValueView, field.collectionReferent, j) }}">
                                                {{ getInputValueRef(collection.elementValueView,
                                                field.collectionReferent, j) }}
                                            </span>
                                        </ng-container>
                                        <span class="position-relative ml-2 font-weight-light">
                                            <fa-icon (click)="removeCollection($event, field, collection.id)"
                                                class="text-danger" [icon]="faTrash"></fa-icon>
                                        </span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <div *ngFor="let collectionField of collection.elementValueView; let inputIndex = index;"
                                            class="row mb-1">
                                            <div class="col-11 p-0 form-group d-flex flex-wrap">
                                                <label [ngClass]="{'col-12': collectionField.fieldType === 'fieldset','col-2' :collectionField.fieldType != 'fieldset' }"
                                                    class=" px-0 col-form-label text-truncate font-weight-bold">
                                                    <i class="mr-1 text-danger pi pi-times-circle" *ngIf="(collectionField.mandatory && collectionField.fieldType !== 'checkbox' 
                                                        && (!collectionField.value || isValueEmpty(collectionField.value) 
                                                        || ['<p></p>', false, undefined,'','null'].includes(collectionField.value)))" pTooltip="{{ 'pim.elementTypes.fieldMandatory' | translate }}">
                                                    </i>
                                                    
                                                    <i class="mr-1 text-warning pi pi-exclamation-triangle" *ngIf="(collectionField.salabilityIndicator && collectionField.fieldType !== 'checkbox' 
                                                        && (!collectionField.value || isValueEmpty(collectionField.value) 
                                                        || ['<p></p>',false,undefined,'','null'].includes(collectionField.value)))" pTooltip="{{ 'indicator.name' | translate }}">
                                                    </i>
                                                    <span pTooltip="{{ collectionField.input }}">{{ collectionField.input }}</span>
                                                </label>
                                                <!-- Text & Number-->
                                                <input
                                                    (change)="saveCollectionField(collectionField, collection.id, collectionField.id, collectionField.inputId, $event.target.value, sectionName, sf, j, inputIndex, true)"
                                                    [disabled]="!setValue" class="form-control col-9"
                                                    [value]="collectionField.value"
                                                    *ngIf="collectionField.fieldType === 'text' || collectionField.fieldType === 'number'"
                                                    type="{{ collectionField.fieldType }}" step="0.01">
                                                <!-- Select -->
                                                <select
                                                    (change)="saveCollectionField(collectionField, collection.id, collectionField.id, collectionField.inputId, $event.target.value, sectionName, sf, j, inputIndex, true)"
                                                    [disabled]="!setValue" class="form-control col-9"
                                                    *ngIf="collectionField.fieldType === 'list' && !collectionField.multiple">
                                                    <option></option>
                                                    <option
                                                        [selected]="checkOptionSelected(collectionField.value, value)"
                                                        *ngFor="let value of collectionField.list" [value]="value.id">{{
                                                        value.name
                                                        }}
                                                    </option>
                                                </select>
                                                <!-- Checkbox -->
                                                <label class="switch switch-pill switch-primary"
                                                    *ngIf="collectionField.fieldType === 'checkbox'">
                                                    <input
                                                        (change)="saveCollectionField(collectionField, collection.id, collectionField.id, collectionField.inputId, $event.target.checked, sectionName, sf, j, inputIndex, true)"
                                                        [disabled]="!setValue" type="checkbox" class="switch-input"
                                                        [checked]="collectionField.value === '1' || collectionField.value === true">
                                                    <span class="switch-slider"></span>
                                                </label>
                                                <!-- Date -->
                                                <app-date-picker
                                                    (dateSelect)="saveCollectionField(collectionField, collection.id, collectionField.id, collectionField.inputId, $event, sectionName, sf, j, inputIndex, true)"
                                                    class="col-9 p-0" [minDate]="{ year: 1930, month: 1, day: 1 }"
                                                    [value]="collectionField.value" [active]="setValue"
                                                    *ngIf="collectionField.fieldType === 'date'"></app-date-picker>
                                                <!-- Multiselect -->

                                                <ng-multiselect-dropdown (click)="getOldListValue(collectionField.value)"
                                                    (onSelectAll)="askToSelectOrDeselectAllFieldset(collectionField, collection, $event, sectionName)"
                                                    (onDeSelectAll)="askToSelectOrDeselectAllFieldset(collectionField, collection, $event, sectionName)"
                                                    (onDeSelect)="saveCollectionField(collectionField, collection.id, collectionField.id, collectionField.inputId, collectionField.value, sectionName, sf, j, inputIndex, true)"
                                                    (onSelect)="saveCollectionField(collectionField, collection.id, collectionField.id, collectionField.inputId, collectionField.value, sectionName, sf, j, inputIndex, true)"
                                                    [disabled]="!setValue" [(ngModel)]="collectionField.value"
                                                    class="p-0"
                                                    [ngClass]="collectionField.value == null || collectionField.value.length == 0 ? 'col-9' : (displayCollection(sf,j,inputIndex) &&(collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' && collectionField.multiple ))?'col-8' : 'col-4')"
                                                    *ngIf="collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' && collectionField.multiple)"
                                                    [settings]="dropdownSettings" [data]="collectionField.list"
                                                    placeholder="{{ 'general.select' | translate }}">
                                                </ng-multiselect-dropdown>

                                                <div *ngIf="!displayCollection(sf,j,inputIndex) &&(collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' &&
                                                collectionField.multiple))" class="col ml-1 p-0">
                                                    <div [appSortable]="sortablejsOptionsFieldset"
                                                        class="d-flex flex-wrap">
                                                        <div *ngFor="let collectionItem of collectionField.value; let ci = index"
                                                            class="item-selection">
                                                            <div class="item-content">
                                                                <div class="truncate-text">
                                                                    <p>{{ collectionItem.name }}</p>
                                                                </div>
                                                                <span (click)="deleteOptionsFieldset(ci,collection.id)"
                                                                    class="font-weight-bold mx-1 cursor-pointer">x</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-0 text-right" [ngClass]="{'col' : displayCollection(sf,j,inputIndex), 'col-1' : !displayCollection(sf,j,inputIndex) &&(collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' &&
                                                collectionField.multiple))}"

                                                    *ngIf="(collectionField.value != null && collectionField.value.length > 0) && (collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' && collectionField.multiple))">
                                                    <button
                                                        (click)="displaySortable(sectionName, sf,j,inputIndex)"
                                                        *ngIf="displayCollection(sf,j,inputIndex) else elseBlock"
                                                        ngbTooltip="{{ 'general.reorderList' | translate }}"
                                                        class="btn btn-primary">
                                                        <i class="pi pi-arrows-h" style="font-size: 1rem"></i>
                                                    </button>

                                                    <ng-template #elseBlock>
                                                        <button
                                                            ngbTooltip="{{ 'general.hideList' | translate }}"
                                                            (click)="displaySortable(sectionName, sf,j,inputIndex)"
                                                            class="btn btn-primary">
                                                            <i class="pi pi-eye-slash" style="font-size: 1rem"></i>
                                                        </button>
                                                    </ng-template>
                                                </div>

                                                <!-- ListCascade -->
                                                <div class="col-9" *ngIf="collectionField.fieldType === 'listcascade'">
                                                    <div class="mb-2 row p-0">
                                                        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="_dataService.expandCollapse('expand',collectionField.list)">{{ 'datatable.expand' | translate }}</button>
                                                    <button type="button" class="btn btn-primary ml-1" data-dismiss="modal" (click)="_dataService.expandCollapse('collapse',collectionField.list)">{{ 'datatable.collapse' | translate }}</button>
                                                    </div>
                                                    <div class="row">
                                                        <p-tree class="col-12 p-0" [value]="getSelectedListCascade(collectionField.list, collectionField.value)" selectionMode="checkbox"  [(selection)]="collectionField.value" [filter]="true" filterMode="strict" [propagateSelectionUp]="true" [propagateSelectionDown]="false" (onNodeSelect)="onSelectInput(collectionField, collection.id, collectionField.id, collectionField.inputId, sectionName, $event)" (onNodeUnselect)="onSelectInput(collectionField, collection.id, collectionField.id, collectionField.inputId, sectionName)"></p-tree>
                                                    </div>
                                                </div>
                                                
                                                
                                                <!-- Textarea -->
                                                <app-text-editor
                                                    (focusOut)="saveCollectionField(collectionField, collection.id, collectionField.id, collectionField.inputId, $event, sectionName, sf, j, inputIndex, true)"
                                                    class="col-9 p-0" [disabled]="!setValue"
                                                    [value]="collectionField.value"
                                                    *ngIf="collectionField.fieldType === 'textarea'"></app-text-editor>

                                                <!-- Table -->
                                                <ng-container *ngIf="collectionField.fieldType === 'table'">
                                                    <ng-template  class="p-0"
                                                        *ngTemplateOutlet="tableTemplate; context: {field: field, section: section, sectionName: sectionName, collectionId: collection.id, inputId: collectionField.inputId, collectionBisId: null, inputBisId: null}"></ng-template>
                                                </ng-container>

                                                <!-- Fieldset-->
                                                <ng-container *ngIf="collectionField.fieldType === 'fieldset'">
                                                    <ng-template
                                                        *ngTemplateOutlet="fieldsetInput; context: {field: field, section: section, sectionName: sectionName, collectionId: collection.id,
                                                        collections: collectionField.value, indexCollection: j, input: collectionField,sf:sf, inputIndex:inputIndex}"></ng-template>

                                                    <div *ngIf="(collectionField.value == null || !collectionField.value.length) && collectionField.inputs && collectionField.inputs.length"
                                                        role="alert" style="width: 100%;"
                                                        class="px-2 p-2 ml-5 col-12 text-center alert alert-warning ">
                                                        {{ "pim.element.noCollection" | translate }}
                                                    </div>
                                                    <div *ngIf="collectionField.inputs && !collectionField.inputs.length"
                                                        role="alert" style="width: 100%;"
                                                        class="px-2 p-2 ml-5 col-12 text-center alert alert-warning ">
                                                        {{ "pim.element.noInputs" | translate }}
                                                    </div>
                                                </ng-container>

                                                <!-- Color -->
                                                <app-color-picker-maestro class="col-9 p-0"
                                                    (colorSelect)="saveCollectionField(collectionField, collection.id, collectionField.id, collectionField.inputId, $event, sectionName, sf, j, inputIndex, true)"
                                                    *ngIf="collectionField.fieldType === 'colors'"
                                                    [value]="collectionField.value ? collectionField.value : null"></app-color-picker-maestro>

                                                <!-- Media -->
                                                <ng-container *ngIf="collectionField.fieldType === 'media'">
                                                    <app-element-update-media class="col-10 p-0" [singleSelection]="!collectionField.multiple" [forElementMedia]="false" [idElementValue]="collectionField.id" [elementMediasFromParent]="collectionField.value"></app-element-update-media>
                                                </ng-container>
                                            </div>

                                            <!-- History -->
                                            <div class=" col-1 p-0 text-center" style="right: 5px"
                                                [ngStyle]="{'display':collectionField.fieldType === 'fieldset' || collectionField.fieldType === 'media' ? 'none' : null}">
                                                    <svg ngbTooltip="{{ 'tooltip.history' | translate }}"
                                                        (click)="openHistory(collectionField.id, collectionField.fieldType)"
                                                        *ngIf="!collectionField.isLoading && (collectionField.value !== null || (collectionField.fieldType === 'date' || collectionField.fieldType === 'list'))"
                                                        style="width: 1.1rem; height: 1.1rem;" class="pointer" fill="none"
                                                        stroke="currentColor" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                    </svg>
                                                    <div *ngIf="collectionField.isLoading"
                                                        class="spinner-border mx-auto text-primary spinner-border-sm align-middle"
                                                        style="vertical-align: -webkit-baseline-middle !important;"
                                                        role="status">
                                                        <span class="sr-only">{{ 'general.loading' | translate }}</span>
                                                    </div>
                                            </div>
                                            <div class="col-12"
                                                *ngIf="collectionField && collectionField.fieldType === 'productLink' && collectionField.value && collectionField.value.length > 0">
                                                <div class="row"
                                                    (click)="changeDisplay(field, j, collectionField.inputId)"
                                                    class="pointer text-center text-muted">
                                                    <span class="pi pi-fw "
                                                        [ngClass]="(displayAll && displayAll.length > 0 && displayAll[field.id][j][collectionField.inputId] === true) ? 'pi-chevron-circle-up' : 'pi-chevron-circle-down'"></span>
                                                    <span
                                                        *ngIf="displayAll && displayAll.length > 0 && !displayAll[field.id][j][collectionField.inputId]">
                                                        {{ "general.moreDetails"|translate }} </span>
                                                    <span
                                                        *ngIf="displayAll && displayAll.length > 0 && displayAll[field.id][j][collectionField.inputId]">
                                                        {{ "general.lessDetails"|translate }} </span>
                                                </div>
                                                <div
                                                    *ngIf="displayAll && displayAll.length > 0 && !displayAll[field.id][j][collectionField.inputId]">
                                                </div>
                                                <div class="row"
                                                    *ngIf="displayAll && displayAll.length > 0 && displayAll[field.id][j][collectionField.inputId]">
                                                    <div class="col-sm-12">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <div
                                                                    *ngFor="let product of displayListProductLink(collectionField.value, collectionField.list)">
                                                                    <a [routerLink]="['/pim', 'element', 'update',product.id]"
                                                                        target="_blank">{{ product.name }}</a> <fa-icon class="text-primary ml-2" (click)="getPreview(product)" [icon]="faEye"></fa-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                                <li class="nav-item not-sortable " *ngIf="setValue">
                                    <a *ngIf="!field.addCollection && field.inputs && field.inputs.length > 0" class="nav-link position-relative" href
                                        (click)="controlFieldsetSource($event, field,navFs)">
                                        <fa-icon class="text-primary" [icon]="faPlus"></fa-icon>
                                    </a>
                                    <a *ngIf="field.addCollection" class="nav-link" href
                                        (click)="$event.preventDefault()">
                                        <div class="spinner-border mx-auto text-primary spinner-border-sm"
                                            role="status">
                                            <span class="sr-only">{{ 'general.loading' | translate }}</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <div style=" margin-left:  2.2em;" *ngIf="field.collections.length > 0" class="px-2 "
                                [ngbNavOutlet]="navFs"></div>
                            <div role="alert" style="width: 90%;" id="size-fieldset" *ngIf="field.collections.length === 0 && field.inputs && field.inputs.length > 0"
                                class="px-2 p-2 ml-4  text-center alert alert-warning ">
                                {{ "pim.element.noCollection" | translate }}
                            </div>
                            <div role="alert" style="width: 90%;" id="size-fieldset" *ngIf="field.inputs && field.inputs.length === 0" class="px-2 p-2 ml-4  text-center alert alert-warning ">
                                {{ "pim.element.noInputs" | translate }}
                            </div>
                        </div>
                    </ng-container>

                    <!-- Table -->
                    <ng-container *ngIf="field.fieldType === 'table'">
                        <ng-template
                            *ngTemplateOutlet="tableTemplate; context: {field: field, section: section, sectionName: sectionName, collectionId: null, inputId: null, collectionBisId: null, inputBisId: null}"></ng-template>
                    </ng-container>

                    <!-- Media -->
                    <ng-container *ngIf="field.fieldType === 'media'">
                        <app-element-update-media class="col-10 p-0" [singleSelection]="!field.multiple" [forElementMedia]="false" [idElementValue]="field.elementValue[0].id" [elementMediasFromParent]="field.elementValue[0].value"></app-element-update-media>
                    </ng-container>
                </div>
                <div *ngIf="field.fieldType !== 'fieldset' && field.fieldType !== 'media'" class="col-1 p-0 text-center"
                    title="{{ 'tooltip.history' | translate }}">
                    <svg (click)="openHistory(field.elementValue[0].id, field.fieldType)"
                        *ngIf="!field.isLoading && (field.elementValue && field.elementValue[0].id && (field.elementValue[0].value !== null || (field.fieldType === 'date' || field.fieldType === 'list' || field.fieldType === 'productLink')))"
                        style="width: 1.1rem; height: 1.1rem;display:inline;" class="mt-2 pointer mx-auto" fill="none"
                        stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <div *ngIf="field.isLoading"
                        class="spinner-border mx-auto text-primary spinner-border-sm align-middle"
                        style="vertical-align: -webkit-baseline-middle !important;" role="status">
                        <span class="sr-only">{{ 'general.loading' | translate }}</span>
                    </div>

                </div>
                <div class="col-12"
                    *ngIf="field.fieldType === 'productLink' && field.elementValue[0].value && field.elementValue[0].value.length > 0">
                    <div class="row" (click)="changeDisplay(field)" class="pointer text-center text-muted">
                        <span class="pi pi-fw "
                            [ngClass]="(displayAll && displayAll.length > 0 && displayAll[field.id] === true) ? 'pi-chevron-circle-up' : 'pi-chevron-circle-down'"></span>
                        <span *ngIf="displayAll && displayAll.length > 0 && !displayAll[field.id]">
                            {{ "general.moreDetails"|translate }} </span>
                        <span *ngIf="displayAll && displayAll.length > 0 && displayAll[field.id]">
                            {{ "general.lessDetails"|translate }} </span>
                    </div>
                    <div *ngIf="displayAll && displayAll.length > 0 && !displayAll[field.id]"></div>
                    <div class="row" *ngIf="displayAll && displayAll.length > 0 && displayAll[field.id]">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <div
                                        *ngFor="let product of displayListProductLink(field.elementValue[0].value, field.list)">
                                        <a [routerLink]="['/pim', 'element', 'update',product.id]" target="_blank">{{
                                            product.name }}</a> <fa-icon class="text-primary ml-2" (click)="getPreview(product)" [icon]="faEye" style="cursor:pointer;"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav"></div>

<ng-template #tableTemplate let-field="field" let-section="section" let-sectionName="sectionName"
    let-collectionId="collectionId" let-inputId="inputId" let-collectionBisId="collectionBisId"
    let-inputBisId="inputBisId">
    <div class="ml-3 tableOverlayPanelContainer">
        <div class="row">
            <!--btn-block text-left col-10-->
            <button class="btn btn-primary" #target data-toggle="modal" data-target="#tableModal"
                (click)="openConfigureTableModal(field, collectionId, inputId, collectionBisId, inputBisId)">
                {{ 'general.table.configure' | translate }}
            </button>

            <i class="pi pi-eye col-2" type="button"
                (click)="openConfigureTableModal(field, collectionId, inputId, collectionBisId, inputBisId); op.toggle($event)"></i>
            <!-- (mouseover)="openConfigureTableModal(field); op.toggle($event)"
                (mouseout)="op.toggle($event)" -->
            <!-- Can lead to error console about expression changed after checked -->
        </div>

        <ng-template #overlayPanelDiv></ng-template>
        <!-- @TODO: Fix "ERROR Cannot append [object Object] to [object HTMLDivElement]" -->

        <p-overlayPanel #op class=" my-overlayPanel tableOverlayPanel" [appendTo]="body" [showCloseIcon]="true">
            <app-form-table [id]="'overlayPanelTable-' + sf" [cells]="currentTable"
                [overlayPanelMode]="true"></app-form-table>
        </p-overlayPanel>
        <div class="modal fade" id="tableModal" tabindex="-1" role="dialog" aria-labelledby="tableModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="tableModalLabel">
                            {{ 'general.table.configure' | translate }} {{ currentTitle }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <app-form-table [id]="sf" [cells]="currentTable"
                            [importFromSource]="controlSource(sections[sectionName])" [sources]="tableSources"
                            [disabledImportTable]="importTableDisabled"
                            (cellsEmitter)="saveTable(sections[sectionName], currentTable, $event, sectionName)"
                            (importTableFromSource)="importTableFromSource(sections[sectionName], $event, collectionBisId, inputBisId, sectionName)">
                        </app-form-table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">
                            {{ 'general.close' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #fieldsetInput let-field="field" let-section="section" let-sectionName="sectionName"
    let-collectionId="collectionId" let-collections="collections" let-indexCollection="indexCollection"
    let-input="input" let-sf="sf" let-inputField="inputIndex">
    <div class="d-block
    col-12 p-0 ml-5">
        <ul   [id]="'fs-' + field.id + '-' + sectionName + '-'+ collectionId +'-'+input.id"
            [appSortable]="sortableJSOptionsLvl2" ngbNav #navInput="ngbNav" [ngClass]="{ 'not-sortable': !setValue}" class="nav-tabs col-9"
            style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; max-width: 100%;">
            <li (click)="displaySortableStates =[]" class="d-inline-block" ngbNavItem
                *ngFor="let collection of collections; let ind = index " [ngbNavItem]="ind">
                <a class="text-truncate d-flex sortable fieldset" ngbNavLink>
                    <span
                        *ngIf="!input.collectionReferent && getFieldsetInputValueRef(collection, input.collectionReferent, ind)">
                        {{ (ind + 1) }}
                        <span class="position-relative ml-2 font-weight-light">
                            <fa-icon (click)="removeCollectionInput(ind, collectionId, field, input, collections, sectionName)"
                                class="text-danger" [icon]="faTrash"></fa-icon>
                        </span>
                    </span>
                    <span
                        *ngIf="input.collectionReferent && getFieldsetInputValueRef(collection, input.collectionReferent, ind)"
                        container="body"
                        [ngbTooltip]="getFieldsetInputValueRef(collection, input.collectionReferent, ind)">
                        {{ getFieldsetInputValueRef(collection, input.collectionReferent, ind) }}
                        <span class="position-relative ml-2 font-weight-light">
                            <fa-icon (click)="removeCollectionInput(ind, collectionId, field, input, collections, sectionName)"
                                class="text-danger" [icon]="faTrash"></fa-icon>
                        </span>
                    </span>
                </a>
                <ng-template ngbNavContent>
                    <div *ngFor="let collectionField of collection; let inputIndex = index" class="row mb-1">
                        <div class="col-11 p-0 form-group d-flex">
                            <label class="col-2 px-0 col-form-label text-truncate font-weight-bold">
                                <i class="mr-1 text-danger pi pi-times-circle" *ngIf="(collectionField.mandatory && collectionField.fieldType !== 'checkbox' 
                                    && (!collectionField.elementValue)
                                    || ['',undefined,'<p></p>',null,'null'].includes(collectionField.elementValue)
                                    || isValueEmpty(collectionField.elementValue))" pTooltip="{{ 'pim.elementTypes.fieldMandatory' | translate }}">
                                </i>
                                <i class="mr-1 text-warning pi pi-exclamation-triangle" *ngIf="(collectionField.salabilityIndicator && collectionField.fieldType !== 'checkbox' 
                                    && (!collectionField.elementValue)
                                    || ['',undefined,'<p></p>',null,'null'].includes(collectionField.elementValue)
                                    || isValueEmpty(collectionField.elementValue))" pTooltip="{{ 'indicator.name' | translate }}">
                                </i>
                                <span pTooltip="{{ collectionField.name }}">{{ collectionField.name }}</span>
                            </label>
                            <!-- Text & Number-->
                            <input
                                (change)="saveValueInput($event.target.value, field, ind, inputIndex, input,collections, sectionName)"
                                [disabled]="!setValue" class="form-control col-10"
                                [value]="collectionField.elementValue"
                                *ngIf="collectionField.fieldType === 'text' || collectionField.fieldType === 'number'"
                                type="{{ collectionField.fieldType }}" step="0.01">
                            <!-- Select -->
                            <select
                                (change)="saveValueInput($event.target.value, field, ind, inputIndex, input,collections, sectionName)"
                                [disabled]="!setValue" class="form-control col-10"
                                *ngIf="collectionField.fieldType === 'list' && !collectionField.multiple">
                                <option></option>
                                <option [selected]="checkOptionSelected(collectionField.elementValue, value)"
                                    *ngFor="let value of collectionField.list" [value]="value.id">{{
                                    value.name
                                    }}
                                </option>
                            </select>
                            <!-- Checkbox -->
                            <label class="switch switch-pill switch-primary"
                                *ngIf="collectionField.fieldType === 'checkbox'">
                                <input
                                    (change)="saveValueInput($event.target.checked, field, ind, inputIndex, input,collections, sectionName)"
                                    [disabled]="!setValue" type="checkbox" class="switch-input"
                                    [checked]="collectionField.elementValue == '1' || collectionField.elementValue === true">
                                <span class="switch-slider"></span>
                            </label>
                            <!-- Date -->
                            <app-date-picker
                                (dateSelect)="saveValueInput($event, field, ind, inputIndex, input,collections, sectionName)"
                                class="col-10 p-0" [minDate]="{ year: 1930, month: 1, day: 1 }"
                                [value]="collectionField.elementValue" [active]="setValue"
                                *ngIf="collectionField.fieldType === 'date'"></app-date-picker>

                            <!-- Colors -->
                            <app-color-picker-maestro
                                class="col-9 p-0"
                                (colorSelect)="saveValueInput($event, field, ind, inputIndex, input,collections, sectionName)"
                                *ngIf="collectionField.fieldType === 'colors'"
                                [value]="collectionField.elementValue ? collectionField.elementValue : null">
                            </app-color-picker-maestro>

                            <!-- Multiselect -->
                            <ng-multiselect-dropdown (click)="getOldListValue(collectionField.elementValue)"
                                (onSelectAll)="askToSelectOrDeselectAllFieldsetInFieldset($event, field, ind, inputIndex, input,collections, sectionName, collectionField)"
                                (onDeSelectAll)="askToSelectOrDeselectAllFieldsetInFieldset($event, field, ind, inputIndex, input,collections, sectionName, collectionField)"
                                (onDeSelect)="saveValueInput(collectionField.elementValue, field, ind, inputIndex, input,collections, sectionName)"
                                (onSelect)="saveValueInput(collectionField.elementValue, field, ind, inputIndex, input,collections, sectionName)"
                                [disabled]="!setValue" [(ngModel)]="collectionField.elementValue" class="p-0"
                                [ngClass]="collectionField.elementValue == null || collectionField.elementValue.length == 0 ? 'col-10' : (displayCollection(sf,indexCollection,inputField,ind,inputIndex) &&(collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' && collectionField.multiple)) ?'col-9' : 'col-5')"
                                *ngIf="collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' && collectionField.multiple)"
                                [settings]="dropdownSettings" [data]="collectionField.list"
                                placeholder="{{ 'general.select' | translate }}">
                            </ng-multiselect-dropdown>
                            <div *ngIf="collectionField.elementValue != null && collectionField.elementValue.length > 0 && !displayCollection(sf,indexCollection,inputField,ind,inputIndex) &&(collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' &&
                                collectionField.multiple))" class="col p-0 ml-1">
                                <div [appSortable]="sortablejsOptionsFieldsetValueInput" class="d-flex flex-wrap">
                                    <div *ngFor="let collectionValueInput of collectionField.elementValue; let ci = index"
                                        class="item-selection">
                                        <div class="item-content">
                                            <div class="truncate-text">
                                                <p>{{ collectionValueInput.name }}</p>
                                            </div>
                                            <span (click)="deleteOptionsValueInput(ci)"
                                                class="font-weight-bold ml-1 mr-1 cursor-pointer">x</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="(collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' && collectionField.multiple)) &&
                            collectionField.elementValue?.length > 0  && collectionField.elementValue !== null" class="p-0 text-right" [ngClass]="{'col' : displayCollection(sf,indexCollection,inputField,ind,inputIndex), 'col-1' : !displayCollection(sf,indexCollection,inputField,ind,inputIndex) &&(collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' &&
                            collectionField.multiple))}">
                                <button *ngIf="displayCollection(sf,indexCollection,inputField,ind,inputIndex); else elseBlock" (click)="displaySortable(sectionName, sf,indexCollection,inputField,ind,inputIndex,collections,field)"
                                    ngbTooltip="{{ 'general.reorderList' | translate }}"
                                    class="btn btn-primary">
                                    <i class="pi pi-arrows-h" style="font-size: 1rem"></i>
                                </button>

                                <ng-template #elseBlock>
                                    <button ngbTooltip="{{ 'general.hideList' | translate }}"
                                        (click)="displaySortable(sectionName, sf,indexCollection,inputField,ind,inputIndex,collections)"
                                        class="btn btn-primary">
                                        <i class="pi pi-eye-slash" style="font-size: 1rem"></i>
                                    </button>

                                </ng-template>
                            </div>
                            <!-- <section class="w-100 col-11 " *ngIf="collectionField.fieldType === 'productLink' || (collectionField.fieldType === 'list' && collectionField.multiple)">
                                <div class="row">

                                </div>

                            </section> -->
                            <!-- ListCascade -->
                            <div class="col-10" *ngIf="collectionField.fieldType === 'listcascade'">
                                <div class="mb-2 row p-0">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="_dataService.expandCollapse('expand',collectionField.list)">{{ 'datatable.expand' | translate }}</button>
                                    <button type="button" class="btn btn-primary ml-1" data-dismiss="modal" (click)="_dataService.expandCollapse('collapse',collectionField.list)">{{ 'datatable.collapse' | translate }}</button>
                                </div>
                                <div class="row">
                                    <p-tree class="col-12 p-0" *ngIf="collectionField.fieldType === 'listcascade'" [value]="collectionField.list" selectionMode="checkbox"  [(selection)]="collectionField.elementValue" [filter]="true" filterMode="strict" [propagateSelectionUp]="true" [propagateSelectionDown]="false" (onNodeSelect)="saveValueInput($event, field, ind, inputIndex, input,collections, sectionName, $event, 'select')" (onNodeUnselect)="saveValueInput($event, field, ind, inputIndex, input,collections, sectionName, $event, 'unselect')"></p-tree>
                                </div>
                            </div>
                            
                            <!-- Textarea -->
                            <app-text-editor
                                (focusOut)="saveValueInput($event, field, ind, inputIndex, input,collections, sectionName)"
                                class="col-10 p-0" [disabled]="!setValue" [value]="collectionField.elementValue"
                                *ngIf="collectionField.fieldType === 'textarea'"></app-text-editor>

                            <!-- Table -->
                            <ng-container *ngIf="collectionField.fieldType === 'table'">
                                <ng-template
                                    *ngTemplateOutlet="tableTemplate; context: {field: field, section: section, sectionName: sectionName, collectionId: collectionId, inputId: input.inputId, collectionBisId: ind, inputBisId: inputIndex}"></ng-template>
                            </ng-container>

                            <!-- Media -->
                            <ng-container *ngIf="collectionField.fieldType === 'media'">
                                <app-element-update-media (mediaChange)="saveValueInput($event, field, ind, inputIndex, input,collections, sectionName)" class="col-10 p-0" [singleSelection]="!collectionField.multiple" [forElementMedia]="false" [idElementValue]="input.id" [indexCollection]="+ind" [idInput]="collectionField.id" [elementMediasFromParent]="collectionField.elementValue"></app-element-update-media>
                                <!-- WARNING : input.id refer to element value id-->
                            </ng-container>
                        </div>

                        <div class="col-12"
                            *ngIf="collectionField.fieldType === 'productLink' && collectionField.elementValue.length > 0">
                            <div class="row"
                                (click)="changeDisplay(field, indexCollection, input.inputId, ind, collectionField.id)"
                                class="pointer text-center text-muted">
                                <span class="pi pi-fw "
                                    [ngClass]="(displayAll && displayAll.length > 0 && displayAll[field.id][indexCollection][input.inputId][ind] && displayAll[field.id][indexCollection][input.inputId][ind][collectionField.id] === true) ? 'pi-chevron-circle-up' : 'pi-chevron-circle-down'"></span>
                                <span
                                    *ngIf="displayAll && displayAll.length > 0 && displayAll[field.id][indexCollection][input.inputId][ind] && !displayAll[field.id][indexCollection][input.inputId][ind][collectionField.id]">
                                    {{ "general.moreDetails"|translate }} </span>
                                <span
                                    *ngIf="displayAll && displayAll.length > 0 && displayAll[field.id][indexCollection][input.inputId][ind] && displayAll[field.id][indexCollection][input.inputId][ind][collectionField.id]">
                                    {{ "general.lessDetails"|translate }} </span>
                            </div>
                            <div
                                *ngIf="displayAll && displayAll.length > 0 && displayAll[field.id][indexCollection][input.inputId][ind] && !displayAll[field.id][indexCollection][input.inputId][ind][collectionField.id]">
                            </div>
                            <div class="row"
                                *ngIf="displayAll && displayAll.length > 0 && displayAll[field.id][indexCollection][input.inputId][ind] && displayAll[field.id][indexCollection][input.inputId][ind][collectionField.id]">
                                <div class="col-sm-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div
                                                *ngFor="let product of displayListProductLink(collectionField.elementValue, collectionField.list)">
                                                <a [routerLink]="['/pim', 'element', 'update',product.id]"
                                                    target="_blank">{{ product.name }}</a> <fa-icon class="text-primary ml-2" (click)="getPreview(product)" [icon]="faEye"></fa-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- If we need a history for all field make back function before -->
                        <!-- <div class="col-1 p-0 text-center">
                            <svg ngbTooltip="{{ 'tooltip.history' | translate }}"
                                (click)="openHistory(collectionField.id, collectionField.fieldType)"
                                *ngIf="!collectionField.isLoading && collectionField.elementValue"
                                style="width: 1.1rem; height: 1.1rem;" class="mt-2 pointer mx-auto"
                                fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <div *ngIf="collectionField.isLoading"
                                class="spinner-border mx-auto text-primary spinner-border-sm align-middle"
                                style="vertical-align: -webkit-baseline-middle !important;"
                                role="status">
                                <span class="sr-only">{{'general.loading' | translate}}</span>
                            </div>
                        </div> -->
                    </div>
                </ng-template>
            </li>
            <li class="nav-item not-sortable" *ngIf="setValue">
                <span *ngIf="!field.addCollection && input.inputs && input.inputs.length" class="nav-link"
                    (click)="controlFieldsetSource($event, field, navInput, collectionId, input, sectionName)">
                    <fa-icon class="text-primary" [icon]="faPlus"></fa-icon>
                </span>
                <span *ngIf="field.addCollection" class="nav-link" (click)="$event.preventDefault()">
                    <div class="spinner-border mx-auto text-primary spinner-border-sm" role="status">
                        <span class="sr-only">{{ 'general.loading' | translate }}</span>
                    </div>
                </span>
            </li>
        </ul>
        <div *ngIf="field.collections.length > 0" class="px-2" [ngbNavOutlet]="navInput"></div>
        <div *ngIf="field.collections.length === 0" class="px-2 p-2 tab-content text-center text-muted">            {{
            "pim.element.noCollection" | translate }}       
        </div>
    </div>

</ng-template>

<p-dialog [(visible)]="displaySourceSelection" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}" baseZIndex="9999">
    <ng-template pTemplate="header">
        <div class="container">
            <div class="row"><strong>{{ 'pim.fieldsets.linkedSource' | translate }}</strong></div>
            <div class="row">{{ 'pim.fieldsets.selectSource' | translate }}</div>
        </div>
    </ng-template>

    <div class="p-field-checkbox ml-5 mb-2">
        <p-radioButton name="sourceSelection" value="null" label="{{ 'general.none' | translate }}"
            [(ngModel)]="selectedSource"></p-radioButton>
    </div>

    <div *ngFor="let source of fieldsetSources | keyvalue">
        <h5 *ngIf="source.value.length" class="mb2"><u>{{ source.key }} :</u></h5>
        <div *ngFor="let field of source.value" class="p-field-checkbox ml-5 mb-2">
            <p-radioButton [inputId]="field.id" name="sourceSelection" [value]="field"
                [(ngModel)]="selectedSource"></p-radioButton>
            <label [for]="field.id">{{ field.name }} ({{ field.protocol }})</label>
        </div>
    </div>

    <p-footer>
        <button icon="pi pi-check" (click)="displaySourceSelection=false; processSource()"
            class="btn btn-primary p-button-text">{{ 'general.validate' | translate }}</button>
        <button icon="pi pi-times" (click)="displaySourceSelection=false" class="btn btn-secondary">{{ 'general.cancel'
            | translate }}</button>
    </p-footer>
</p-dialog>

<swal width="50%" #elementPreviewModal data-backdrop="false" class="modal fade" id="elementPreviewModal" tabindex="-1" role="dialog" aria-labelledby="elementPreviewModalLabel" aria-hidden="true">
    <div *swalPortal>
        <h5 class="modal-title">{{elementPreview.name}}</h5>
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" >
                <div class="modal-body" *ngIf="elementPreview.values.length > 0">
                    <div class="row" *ngFor="let value of elementPreview.values" >
                        <div class="col-4"><b>{{ value.name }}:</b></div>
                        <div class="col-7 value" *ngIf="value.values" >
                            <div *ngFor="let val of value.values">
                                <span *ngIf="value.fieldType == 'date'">{{ _dataService.formatDateValue(val) }}</span>
                                <span *ngIf="value.fieldType != 'date'">{{ val }}</span>
                            </div>
                        </div>
                        <div class="col-8 value" *ngIf="value.collections">
                            <div class="row" *ngFor="let collection of value.collections; let i = index">

                                <div class="col-12">
                                    <div class="row" *ngFor="let input of collection">
                                        <div class="col-3"><b>{{ input.name }} {{i+1}}:</b></div>
                                        <div class="col-8" *ngIf="input.value">
                                            <div class="row" *ngFor="let inputVal of input.value; let col = index">
                                                <span *ngIf="Array.isArray(inputVal)" style="width: 100%;">
                                                    <div class="row pl-0" *ngFor="let inputCollection of inputVal">
                                                        <b class="col-6">{{ inputCollection.name}} {{ col + 1 }} : </b>
                                                        <span class="col-6" *ngIf="inputCollection.fieldType == 'date'">{{ _dataService.formatDateValue(inputCollection.values)}}</span>
                                                        <span class="col-6" *ngIf="inputCollection.fieldType != 'date'">{{ inputCollection.values}}</span>
                                                    </div>
                                                </span>
                                                <span *ngIf="!Array.isArray(inputVal)">
                                                    <span *ngIf="input.fieldType == 'date'">{{ _dataService.formatDateValue(inputVal) }}</span>
                                                    <span *ngIf="input.fieldType != 'date'">{{ inputVal }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="elementPreview.values.length == 0" role="alert" class="text-center alert alert-warning modal-body">
                    {{ "general.noFieldPreview" | translate }}
                </div>
            </div>
        </div>
    </div>
</swal>
