export const ExportType = 
    [
        {id:2,name :"cdf", chanel: [{id:1, name:"brochure"}, {id:2, name:"product_print"}], categorie: "print"},
        {id:9 ,name :"booklet", chanel: [{id:3, name:"booklet"}], categorie: "print"},
        {id:6, name:"marketing", chanel: [{id:4, name:"poster"},{id:5, name:"flyer"}, {id:6, name:"sticker"}, {id:7, name:"plv"}, {id:8, name:"display"}, {id:9, name:"postal"} ], categorie: "print"},
        {id:5, name:"html", chanel: [{id:1, name:"product_web"},{id:2, name:"landing_page"},{id:3, name:"emailing"},{id:4, name:"banner"}], categorie: "web"},
        // {id:8, name:"social", chanel: [{id:5, name:"social"}], categorie: "web"},
        {id:3, name:"csv", chanel: [{id:1, name:"csv"}], categorie: "flux"},
        {id:7, name:"xml", chanel: [{id:2, name:"xml"}], categorie: "flux"},
        {id:10, name:"json", chanel: [{id:3, name:"json"}], categorie: "flux"},
        {id:4, name:"excel", chanel: [{id:4, name:"excel"}], categorie: "flux"}
    ];