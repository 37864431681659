<div *ngIf="isWorkable">
    <div *ngIf="_templatesByExportType" class="form-group" [formGroup]="form">
        <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="currentNav = $event.nextId">
            <li *ngFor="let exportTab of formArray.controls; let i = index" [id]="'export' + i" [ngbNavItem]="i">
                <a ngbNavLink>
                    <app-form-label [invalid]="exportTab.invalid" [valid]="exportTab.valid">
                        {{ exportTab.get('title').value }}
                        <span *ngIf="(formArray.controls.length > 1 || i) && canUpdate" class="position-relative pl-2 font-weight-light" (click)="onDeleteTab(i)">
                            <fa-icon class="text-danger" [icon]="faTrash"></fa-icon>
                        </span>
                    </app-form-label>
                </a>
                <ng-template ngbNavContent>
                    <form [formGroup]="exportTab">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">
                                {{ 'projects.conf.title' | translate }}
                            </label>
                            <div class="col-sm-8">
                                <input class="form-control" formControlName="title" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">
                                {{ 'projects.conf.description' | translate }}
                            </label>
                            <div class="col-sm-8">
                                <app-text-editor [value]="exportTab.value.description" (focusOut)="setDescription(exportTab, $event)"></app-text-editor>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">
                                {{ 'projects.conf.categories.title' | translate }}
                            </label>
                            <div class="col-sm-8">
                                <!-- <app-drop-down (onSelect)="onSelectCategorie($event)" [settings]="dropdownSettingsSingle" [list]="categories" formControlName="categorie" [selected]="exportTab.get('categorie').value"></app-drop-down> -->
                                <ng-multiselect-dropdown
                                    *ngIf="categories"
                                    [settings]="dropdownSettingsSingle"
                                    [placeholder]="placeholder"
                                    [settings]="settings"
                                    [data]="categories"
                                    [disabled]="disabled"
                                    formControlName="categorie"
                                    (onSelect)="onSelectCategorie($event, i, exportTab)"
                                >
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="exportTab.get('categorie').value.length">
                            <label class="col-sm-4 col-form-label">
                                {{ 'projects.conf.category' | translate }}
                            </label>
                            <div class="col-sm-8">
                                <!-- <app-drop-down [settings]="dropdownSettingsSingle" [list]="exportTypeList[exportTab.value.id]" formControlName="exportType" [selected]="exportTab.get('exportType').value"></app-drop-down> -->
                                <ng-multiselect-dropdown
                                    *ngIf="exportTypeList[i]"
                                    [settings]="dropdownSettingsSingle"
                                    [placeholder]="placeholder"
                                    [data]="exportTypeList[i]"
                                    [disabled]="disabled"
                                    formControlName="exportType"
                                    (onSelect)="onSelectExportType($event, exportTab, i)"
                                >
                                </ng-multiselect-dropdown>
                            </div>
                        </div>


                        <div class="form-group row" *ngIf="exportTab.get('categorie').value.length && exportTab.get('exportType').value.length && ('csv' != exportTab.get('exportType').value[0].name.toLowerCase() && 'json' != exportTab.get('exportType').value[0].name.toLowerCase()) && exportTab.get('templates') && (undefined !== templateList[currentTypeExport[i]] &&  templateList[currentTypeExport[i]].length)">
                            <div class="col-12">
                                <div class="row form-group">
                                    <label class="col-sm-3 col-form-label">
                                        {{ 'projects.export.templates' | translate }}
                                    </label>
                                    <div class="col-sm-1" style="text-align: right;">
                                        <button *ngIf="exportTab.get('templates').value && exportTab.get('templates').value.length" class="btn btn-primary btn-primary-filter btn-sm" type="button" (click)="showPreviews()">
                                            <fa-icon [icon]="faTimes"></fa-icon>
                                        </button>
                                    </div>
                                    <div class="col-sm-8">
                                        <!-- <app-drop-down [type]="multiple ? 'multi' : 'single'" [settings]="multipe ? dropdownSettings : dropdownSettingsSingle" [list]="exportTab.value.exportType[0] ? templateList[currentTypeExport] : []" formControlName="templates" [selected]="exportTab.get('templates').value"></app-drop-down> -->
                                        <ng-multiselect-dropdown
                                            *ngIf="exportTypeList[i]"
                                            [placeholder]="placeholder"
                                            [settings]="multiple[i] ? dropdownSettings : dropdownSettingsSingle"
                                            [data]="exportTab.value.exportType[0] ? templateList[currentTypeExport[i]] : []"
                                            [disabled]="disabled"
                                            formControlName="templates"
                                        >
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="exportTab.get('exportType').value.length && ('csv' != exportTab.get('exportType').value[0].name.toLowerCase() && 'json' != exportTab.get('exportType').value[0].name.toLowerCase()) && exportTab.get('exportType').value.length && exportTab.get('templates') && !exportTab.get('templates').value.length && (!templateList[currentTypeExport[i]] || templateList[currentTypeExport[i]].length === 0)" class="form-group row">
                            <div class="col-12">
                                <button class="btn btn-warning col-12" [routerLink]="['/projects', 'templates', 'create']">
                                    {{ "projects.export.no.template" | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="exportTab.get('exportType').value.length && exportTab.get('exportType').value[0] && exportTab.get('categorie').value.length && useProduct && ((exportTab.get('templates') && exportTab.get('templates').value.length) || ('flux' == exportTab.get('categorie').value[0].name.toLowerCase() && 'xml' != exportTab.get('exportType').value[0].name.toLowerCase()))" >
                            <label class="col-sm-4 col-form-label">
                                {{ 'pim.elementTypes.title' | translate }}
                            </label>
                            <div class="col-sm-8">
                                <app-export-element-tabs [formArray]="exportTab.get('typeTabs')" [elementsByType]="elements | elementsByType" [showErrors]="submitted" >
                                </app-export-element-tabs>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </li>
            <li class="nav-item" *ngIf="canUpdate">
                <a class="nav-link" href (click)="addTab($event)">
                    <fa-icon class="text-primary" [icon]="faPlus"></fa-icon>
                </a>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
    <div *ngIf="_templatesByExportType" class="row">
        <div class="col-6 text-left">
            <div class="btn-group">
                <button class="btn btn-danger" (click)="onBack()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-2">{{ 'general.cancel' | translate }}</span>
                </button>
            </div>
        </div>
        <div class="col-6 text-right">
            <div class="btn-group">
                <button class="btn btn-primary" [disabled]="form.invalid|| form.value.description === '<p></p>'" (click)="onSubmit()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span *ngIf="isGrantedToSave()" class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }}</span>
                    <span *ngIf="!isGrantedToSave()" class="d-none d-md-inline-block ml-2">{{ 'general.next' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isWorkable">
    <label> {{ 'projects.export.emptyProduct' | translate }} </label>
</div>
