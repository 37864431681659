<div class="card-body">
    <form [formGroup]="form">
        <div class="row mb-1">
            <div class="col-11 form-group d-flex p-0">
                <label class="col-3 col-form-label font-weight-bold text-truncate">Copyright</label>
                <input class="form-control col-9" formControlName="copyright" step="0.01" (change)="saveField()">
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-11 form-group d-flex p-0">
                <label class="col-3 col-form-label font-weight-bold text-truncate">{{ 'dam.legend' | translate }}</label>
                <input class="form-control col-9" formControlName="legend" step="0.01" (change)="saveField()">
            </div>
        </div>
        <div class="row mb-1">
            <div
                class="col-11 form-group d-flex p-0">
                <label [ngStyle]="{'color': dateCompare() ? 'red' : 'black'}" class="col-3 col-form-label font-weight-bold text-truncate">{{ 'dam.dateValid' | translate }}</label>
                <div style="width: 100%" [ngStyle]="{'border': dateCompare() ? '1px solid red' : 'none'}">
                    <app-date-picker [active]="true" formControlName="dateValid" (dateSelect)="saveField()" class="col-9 p-0" [minDate]="{ year: 1930, month: 1, day: 1 }"></app-date-picker>
                </div>
            </div>
        </div>
    </form>
</div>
