import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { AuthService } from "../services/admin/auth/auth.service";

/**
 * This guard is used to control if the user is loggedIn or not
 */
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private _authService: AuthService, private _router: Router) {}

    canActivate(): boolean | UrlTree {
        const loggedIn = this._authService.isLoggedIn();
        const hasToken = this._authService.currentUserValue;

        if (!loggedIn) {
            if (null !== hasToken) {
                this._authService.logout(true);
            } else {
                this._authService.logout();
            }
        }

        return loggedIn;
    }
}
