import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { HomeService } from "app/core/services/pim/home.service";
import { PimHomeResources } from "app/shared/models/pim/pim-home-resources";
import { Observable } from "rxjs";

@Injectable()
export class PimHomeResolver implements Resolve<PimHomeResources> {
    constructor(private _homeService: HomeService) {}

    resolve(): Observable<PimHomeResources> {
        return this._homeService.homeResources();
    }
}
