import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { MAESTRO_ROUTES } from "app/shared/routes";

import { ProjectWorkflowListComponent } from "./list/project-workflow-list.component";
import { ProjectWorkflowListGuard } from "./list/project-workflow-list.guard";
import { WorkflowFormComponent } from "../form/workflow-form.component";

const projectWorkflowRoutes: Routes = [
    {
        path: "",
        component: ProjectWorkflowListComponent,
        canActivate: [ProjectWorkflowListGuard],
        resolve: { workflows: ProjectWorkflowListGuard },
    },
    {
        path: `:id/${MAESTRO_ROUTES.actions.update}`,
        component: WorkflowFormComponent,
        data: { entity: "workflow", module: "projects" },
        runGuardsAndResolvers: "always",
    },
    {
        path: `${MAESTRO_ROUTES.actions.create}`,
        component: WorkflowFormComponent,
        data: { entity: "workflow", module: "projects" },
    },
];

@NgModule({
    imports: [RouterModule.forChild(projectWorkflowRoutes)],
    exports: [RouterModule],
})
export class ProjectWorkflowRoutingModule {}
