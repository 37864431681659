import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faAngleDoubleLeft, faAngleDoubleRight, faCheckCircle, faEdit, faEye, faEyeSlash, faMinusSquare, faPlus, faSitemap, faSort, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { ProjectService } from "app/core/services/project/project/project.service";
import { ProjectConfigurationStepperService } from "app/core/services/project/project/stepper.service";
import { DROPOWN_SETTINGS } from "app/shared/components/form/drop-down/settings";
import { IMaestroEntity, MaestroElementTypes, MaestroElements, MaestroProject, MaestroVersions } from "app/shared/models";
import { ElementConfigurations } from "app/shared/models/project/elements-configuration";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import Swal from "sweetalert2";

@Component({
    selector: "app-configuration-step-flux",
    templateUrl: "./flux.component.html",
    styleUrls: ["./flux.component.scss"],
})
export class FluxConfigurationComponent implements OnInit {
    submitted: boolean;

    form: FormGroup;
    elementTypes: MaestroElementTypes;
    projectId: number;
    elementConfigurations: ElementConfigurations;
    canUpdate: boolean;
    colonneActive: number;
    inputActive: Array<any> = [];

    faSort = faSort;
    faTrash = faTrash;
    faPlus = faPlus;
    faEye = faEye;
    faEyeSlash = faEyeSlash;
    checkCircle = faCheckCircle;
    timesCircle = faTimesCircle;
    faSitemap = faSitemap;
    faEdit = faEdit;
    faMinusSquare = faMinusSquare;
    faAngleDoubleRight = faAngleDoubleRight;
    faAngleDoubleLeft = faAngleDoubleLeft;
    init: boolean = true;
    cascadefieldList = [];
    separatorFlux: string = ";";
    exportId;

    colFilter = [];

    @ViewChild(NgbNav) nav: any;

    @Output() eventSubmitted = new EventEmitter<any>();

    versionsByType: { [typeId: number]: MaestroVersions } = {};
    elementsByType: { [typeId: number]: MaestroElements } = {};
    fieldsByType: { [typeId: number]: IMaestroEntity[] } = {};
    project: MaestroProject;

    dropdownSettings: IDropdownSettings = DROPOWN_SETTINGS.multiple;

    @Input() elements: any;

    flux: Array<any> = [];

    formArray: FormGroup;
    tableForm: FormGroup;
    modalMoveForm: FormGroup;

    headers: Array<any> = [];

    @ViewChild("changeColPositionModal") private changeColPosition: SwalComponent;

    readonly dropdownListSettings: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        enableCheckAll: false,
        idField: "id",
        textField: "name",
        selectAllText: this._translate.instant("general.select_all"),
        unSelectAllText: this._translate.instant("general.unselet_all"),
    };

    constructor(
        private _route: ActivatedRoute,
        private _fb: FormBuilder,
        private projectService: ProjectService,
        private _stepper: ProjectConfigurationStepperService,
        private _translate: TranslateService
    ) {}

    ngOnInit(): void {
        this._stepper.setCurrentStep(5);
        this.projectId = this._route.snapshot.data.flux.projectId;
        this.flux = this._route.snapshot.data.flux.flux;
        
        
        let keys = Object.keys(this.flux);
        
        this.exportId = keys[0];

        this.tableForm = this._fb.group({
            exports: this._fb.array([]),
            // 
        });
        
        this.listFieldCascade();
        keys.forEach(key => {            
            
            let colonnes = this._fb.array([], [this.uniqueValueFieldProtocol()]);
            if (this.flux[key]["json"] && this.flux[key]["json"]["colonnes"] && this.flux[key]["json"]["colonnes"].length > 0) {
                let colonneInput = [];
                this.flux[key]["json"]["colonnes"].forEach((colonne) => {
                    const formElementTypes = this._fb.array([]);
                    if (this.flux[key]["elementTypes"].length > 0) {
                        this.flux[key]["elementTypes"].forEach((elementType) => {
                            let fluxElementType = colonne.elementTypes.find((elemType) => {
                                return elemType.name == elementType.name;
                            });

                            formElementTypes.push(
                                this._fb.group({
                                    id: [elementType.id],
                                    name: [elementType.name],
                                    fields: [fluxElementType ? fluxElementType.fields : []],
                                    concat: [fluxElementType ? fluxElementType.concat : ""],
                                    list: [fluxElementType ? fluxElementType.list : this.flux[key]["fields"][elementType.name]],
                                })
                            );
                        });
                    }

                    colonnes.push(
                        this._fb.group({
                            protocol: [colonne.protocol, Validators.required],
                            elementTypes: formElementTypes,
                            colonneLink: [colonne.colonneLink],
                            type: [colonne.type ?? "fields"],
                            colParent: [colonne.colParent],
                            idParent: [colonne.idParent],
                            removeCol: [true],
                            bgColor: false,
                        })
                    );
                    if (colonne.colonneLink && colonne.colonneLink.length > 0) {
                        colonneInput = colonneInput.concat(colonne.colonneLink);
                    }
                });

                (this.tableForm.controls.exports as FormArray).push(
                    this._fb.group({
                        id: key,
                        name: this.flux[key].name,
                        type: this.flux[key].type,
                        separatorFlux: this.flux[key]["separator"],
                        colonnes: colonnes, 
                    })
                )

                this.addType(colonneInput, key);
                this.makeTabeColor(key);
            } else {
                

                (this.tableForm.controls.exports as FormArray).push(
                    this._fb.group({
                        id: key,
                        name: this.flux[key].name,
                        type: this.flux[key].type,
                        separatorFlux: this.flux[key]["separator"],
                        colonnes: this.newCol(key), 
                    })
                )
            }

            if(this.flux[key].type == 'EXCEL')
            {
                let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
                let search = exportIds.indexOf(key); 

                this.tableForm.controls.exports["controls"][search]['controls'].separatorFlux.disable();
            }
        })         
                      
    }

    createDefaultFlux(exportId) {
        Swal.fire({
            title: this._translate.instant("modal.createDeafault"),
            showCancelButton: true,
            confirmButtonText: this._translate.instant("general.Tovalidate"),
            cancelButtonText: this._translate.instant("general.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
                let search = exportIds.indexOf(exportId); 
                
                let colonnes = this._fb.array([], this.uniqueValueFieldProtocol());

                let listProtocolCreate = [];
                let allFields = [];
                let col = 0;
                this.flux[exportId]["elementTypes"].forEach((elementType) => {
                    allFields = allFields.concat(this.flux[exportId]["fields"][elementType.name]);
                });

                if (allFields.length > 0) {
                    allFields.forEach((field) => {
                        let checkProto = listProtocolCreate.findIndex((proto) => field.protocol == proto || field.name == proto);
                        let indexElementType = 0;
                        if (checkProto < 0) {
                            const formElementTypes = this._fb.array([]);
                            let colProto = "";
                            let columnProto = "";
                            let colLink = [];
                            let indexElemType = 0;
                            let item = null;
                            this.flux[exportId]["elementTypes"].forEach((elementType) => {
                                let indexField = this.flux[exportId]["fields"][elementType.name].findIndex((elementTypeField) => {
                                    let control;

                                    if (field.protocol && elementTypeField.protocol) {
                                        control = field.protocol == elementTypeField.protocol;
                                    } else {
                                        control = field.name == elementTypeField.name;
                                    }

                                    return control;
                                });
                                let selectField = this.flux[exportId]["fields"][elementType.name][indexField];

                                if (colProto == "") {
                                    if (selectField && selectField["protocol"]) {
                                        if (selectField["type"] == "productLink") {
                                            colProto = selectField["protocol"] + "_PL";
                                        } else if (selectField["type"] == "fieldset") {
                                            colProto = selectField["protocol"] + "_FS";
                                        } else {
                                            colProto = selectField["protocol"];
                                        }

                                        columnProto = selectField["protocol"];
                                    } else {
                                        colProto = field["name"];

                                        columnProto = field["name"];
                                    }
                                }

                                if (selectField) {
                                    item = selectField;
                                    indexElementType = indexElemType;
                                }

                                formElementTypes.push(
                                    this._fb.group({
                                        id: [elementType.id],
                                        name: [elementType.name],
                                        fields: [selectField ? [selectField] : []],
                                        concat: [""],
                                        list: [this.flux[exportId]["fields"][elementType.name]],
                                    })
                                );
                                indexElemType++;
                            });

                            (colonnes as FormArray).push(
                                this._fb.group({
                                    protocol: [colProto, Validators.required],
                                    elementTypes: formElementTypes,
                                    type: ["field"],
                                    colonneLink: [colLink],
                                    colParent: [],
                                    idParent: [],
                                    removeCol: [true],
                                    bgColor: false,
                                })
                            );

                            listProtocolCreate.push(columnProto);

                            let colonne = colonnes["controls"][col];
                            let listColonneLink = [];
                            if (item && (item["type"] == "productLink" || item["type"] == "fieldset")) {
                                let inputs = item["inputs"];
                                let nbInputs = inputs.length;
                                let indexInput = col + 1;

                                inputs.forEach((input) => {
                                    const fbInputElementTypes = this._fb.array([]);
                                    if (this.flux[exportId]["elementTypes"].length > 0) {
                                        this.flux[exportId]["elementTypes"].forEach((fluxElementType) => {
                                            fbInputElementTypes.push(
                                                this._fb.group({
                                                    id: [fluxElementType.id],
                                                    name: [fluxElementType.name],
                                                    fields: [[input]],
                                                    concat: [""],
                                                    list: [inputs],
                                                })
                                            );
                                        });
                                    }


                                    let inputProtocol = input.protocol ? input.protocol : input.name;
                                    if(!inputProtocol.includes("{X}")){
                                        inputProtocol = inputProtocol + "{X}";
                                    }
                                    inputProtocol = colProto + "-" + inputProtocol;
                                    (colonnes as FormArray).push(
                                        this._fb.group({
                                            protocol: [inputProtocol, Validators.required],
                                            elementTypes: fbInputElementTypes,
                                            colonneLink: [[]],
                                            colParent: [col],
                                            idParent: [item.id],
                                            type: item["type"] == "productLink" ? "field" : "input",
                                            removeCol: [false],
                                        })
                                    );

                                    listColonneLink.push(indexInput);
                                    indexInput++;
                                });

                                colonne['controls'].elementTypes["controls"].forEach((colonneElementType) => {
                                    let newList = [];

                                    newList = this.flux[exportId]["fields"][colonneElementType.value.name].filter((field) => {
                                        return field.source == item.source && field.type == item.type;
                                    });

                                    colonneElementType.get("list").setValue(newList);
                                });

                                colonne.get("colonneLink").setValue(listColonneLink);

                                this.changeColLink(indexInput, nbInputs, "add", exportId);
                                col = indexInput;
                            } else {
                                col++;
                            }
                        }
                    });
                }                

                this.tableForm.controls.exports["controls"][search] = this._fb.group({
                    id: exportId,
                    name: this.flux[exportId].name,
                    separatorFlux: this.flux[exportId]["separator"],
                    colonnes: colonnes,
                });
            }

            this.makeTabeColor(exportId);
        });
    }

    getInputControlName(indexField: number, indexInput: number): string {
        return `fields.${indexField}.inputs.${indexInput}.value`;
    }

    /**
     * Save configuration
     */
    onSubmit() {
        
        this.projectService.saveFlux(this.projectId, this.tableForm.value).subscribe((data) => {
            // this._stepper.validStep(5);
            // this._stepper.goToNext(this.projectId);
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        const fields = this.formArray.get("fields") as FormArray;
        moveItemInArray(fields.controls, event.previousIndex, event.currentIndex);
        moveItemInArray(fields.value, event.previousIndex, event.currentIndex);
        // Mettre à jour this.formArray.value avec les nouvelles valeurs
        this.formArray.patchValue({
            fields: fields.value,
        });
    }

    newCol(exportId) {

        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
        let search = exportIds.indexOf(exportId); 
        const elementTypes = this._fb.array([]);
        if (this.flux[exportId]["elementTypes"].length > 0) {
            this.flux[exportId]["elementTypes"].forEach((elementType) => {
                elementTypes.push(
                    this._fb.group({
                        id: [elementType.id],
                        name: [elementType.name],
                        fields: [[]],
                        concat: [""],
                        list: [this.flux[exportId]["fields"][elementType.name]],
                    })
                );
            });
        }

        let colonnes = this._fb.array([], [this.uniqueValueFieldProtocol()]);

        colonnes.push(
            this._fb.group({
                protocol: ["", Validators.required],
                elementTypes: elementTypes,
                type: ["field"],
                colonneLink: [[]],
                colParent: [],
                idParent: [],
                removeCol: [true],
                bgColor: false,
            })
        );
        if(this.tableForm.controls.exports["controls"][search]){
            (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).push(
                this._fb.group({
                    protocol: ["", Validators.required],
                    elementTypes: elementTypes,
                    type: ["field"],
                    colonneLink: [[]],
                    colParent: [],
                    idParent: [],
                    removeCol: [true],
                    bgColor: false,
                })
            );
            this.activeAndDesactiveCol((this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).length - 1);
        }


        this.makeTabeColor(exportId);
        return colonnes;
    }

    public onSelect(item: any, indexColonne, indexElementType, exportId) {
        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
        let search = exportIds.indexOf(exportId); 
        let listColonneLink = [];

        let colonne = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][indexColonne];

        let elementType = colonne.controls.elementTypes["controls"][indexElementType];
        if (item) {
            let selectField = null;
            if (colonne.value.type == "input") {
                let indexParent = this.flux[exportId]["fields"][elementType.value.name].findIndex((elementTypeField) => colonne.value.idParent == elementTypeField.id);
                let selectParent = this.flux[exportId]["fields"][elementType.value.name][indexParent];
                if (selectParent && selectParent["inputs"]) {
                    let indexField = selectParent["inputs"].findIndex((field) => item.id == field.id);
                    selectField = selectParent["inputs"][indexField];
                }
            } else {
                let indexField = this.flux[exportId]["fields"][elementType.value.name].findIndex((elementTypeField) => item.id == elementTypeField.id);
                selectField = this.flux[exportId]["fields"][elementType.value.name][indexField];
            }
            if (colonne.value.protocol == "") {
                if (selectField && selectField["protocol"]) {
                    if (selectField["type"] == "productLink") {
                        colonne.get("protocol").setValue(selectField["protocol"] + "_PL");
                    } else if (selectField["type"] == "fieldset") {
                        colonne.get("protocol").setValue(selectField["protocol"] + "_FS");
                    } else {
                        colonne.get("protocol").setValue(selectField["protocol"]);
                    }
                } else {
                    colonne.get("protocol").setValue(item["name"]);
                }
            }

            if (selectField && (selectField["type"] == "productLink" || selectField["type"] == "fieldset") && colonne.value.colonneLink.length <= 0) {
                let inputs = selectField["inputs"];
                let nbInputs = inputs.length;
                let indexInput = indexColonne + 1;
                inputs.forEach((input) => {
                    const fbElementTypes = this._fb.array([]);
                    if (this.flux[exportId]["elementTypes"].length > 0) {
                        this.flux[exportId]["elementTypes"].forEach((fluxElementType) => {
                            fbElementTypes.push(
                                this._fb.group({
                                    id: [fluxElementType.id],
                                    name: [fluxElementType.name],
                                    fields: [[input]],
                                    concat: [""],
                                    list: [inputs],
                                })
                            );
                        });
                    }

                    (this.tableForm.controls.colonnes as FormArray).insert(
                        indexInput,
                        this._fb.group({
                            protocol: [input.protocol ? input.protocol : input.name, Validators.required],
                            elementTypes: fbElementTypes,
                            colonneLink: [[]],
                            colParent: [indexColonne],
                            idParent: [item.id],
                            type: selectField["type"] == "productLink" ? "field" : "input",
                            removeCol: [false],
                        })
                    );

                    listColonneLink.push(indexInput);
                    indexInput++;
                });

                colonne.controls.elementTypes["controls"].forEach((colonneElementType) => {
                    let newList = [];

                    newList = this.flux[exportId]["fields"][colonneElementType.value.name].filter((field) => {
                        return field.source == selectField.source && field.type == selectField.type;
                    });

                    colonneElementType.get("list").setValue(newList);
                });

                colonne.get("colonneLink").setValue(listColonneLink);

                this.changeColLink(indexInput, nbInputs, "add", exportId);
            }
        }
    }
    public onDeSelect(item: any, indexColonne, indexElementType, exportId) {
        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
        let search = exportIds.indexOf(exportId); 
        let colonne = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][indexColonne];
        let elementType = colonne.controls.elementTypes["controls"][indexElementType];
        let removeCol = true;
        let indexField = this.flux[exportId]["fields"][elementType.value.name].findIndex((elementTypeField) => item.id == elementTypeField.id);
        let DeSelectField = this.flux[exportId]["fields"][elementType.value.name][indexField];

        if (DeSelectField && (DeSelectField["type"] == "productLink" || DeSelectField["type"] == "fieldset") && colonne.value.colonneLink.length > 0) {
            colonne.controls.elementTypes["controls"].forEach((colonneElementType) => {
                colonneElementType.value.fields.forEach((field) => {
                    let colField = this.flux[exportId]["fields"][colonneElementType.value.name][indexField];
                    let filter = this.flux[exportId]["fields"][colonneElementType.value.name].filter((field) => {
                        return field.source == colField.source && field.type == colField.type;
                    });
                    if (filter.length > 0) {
                        removeCol = false;
                    }
                });
            });

            if (removeCol) {
                let nbInputs = colonne.value.colonneLink.length;
                colonne.value.colonneLink.reverse().forEach((indexColonne) => {
                    (this.tableForm.controls.colonnes as FormArray).removeAt(indexColonne);
                });
                colonne.get("colonneLink").setValue([]);
                colonne.controls.elementTypes["controls"].forEach((colonneElementType) => {
                    colonneElementType.get("list").setValue(this.flux[exportId]["fields"][colonneElementType.value.name]);
                });

                this.changeColLink(indexColonne, nbInputs, "remove", exportId);
            }
        }
    }

    public addType(indexInput, exportId) {
        let index = 0;
        
        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
        let search = exportIds.indexOf(exportId);        
        
        this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"].forEach((colonne) => {
            if (indexInput.includes(index)) {
                colonne.get("type").setValue("input");
                colonne.get("removeCol").setValue(false);
            }

            index++;
        });
    }

    public removeCol(indexColRemove, exportId) {
        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
        let search = exportIds.indexOf(exportId);
        Swal.fire({
            title: this._translate.instant("modal.confirmRemove"),
            showCancelButton: true,
            confirmButtonText: this._translate.instant("general.Tovalidate"),
            cancelButtonText: this._translate.instant("general.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let col = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][indexColRemove];
                if (col.value.colonneLink) {
                    let nbInputs = col.value.colonneLink.length;
                    col.value.colonneLink.reverse().forEach((indexInput) => {
                        (this.tableForm.controls.colonnes as FormArray).removeAt(indexInput);
                    });

                    this.changeColLink(indexColRemove, nbInputs + 1, "remove", exportId);
                } else {
                    this.changeColLink(indexColRemove, 1, "remove", exportId);
                }

                (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).removeAt(indexColRemove);
                this.makeTabeColor(exportId);
            }
        });
    }

    public changeColLink(indexColonne, nbInputs, action, exportId) {

        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
        let search = exportIds.indexOf(exportId); 

        for (let index = indexColonne; index < this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"].length; index++) {
            let col = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][index];
            if (col.value.colonneLink && col.value.colonneLink.length > 0) {
                for (let indexCol = 0; indexCol < col.value.colonneLink.length; indexCol++) {
                    if (action == "add") {
                        col.value.colonneLink[indexCol] += nbInputs;
                    } else {
                        col.value.colonneLink[indexCol] -= nbInputs;
                    }
                }
            }
        }
    }

    activeAndDesactiveCol(index) {
        if (this.colonneActive == index) {
            this.colonneActive = null;
        } else {
            this.colonneActive = index;
        }
    }

    openModalDragAndDrop(indexColonne, exportId) {
        this.modalMoveForm = this._fb.group({
            action: ["before"],
            newPosition: [""],
        });
        this.headers = [];
        let index = 0;
        let nbCol = 1;
        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        let search = exportIds.indexOf(exportId); 
        this.tableForm.value.exports[search].colonnes.forEach((colonne) => {
            if (colonne.removeCol) {
                this.headers.push({
                    id: index,
                    protocol: colonne.protocol,
                });
            }
            index++;
        });

        

        this.changeColPosition.fire().then((result) => {
            if (result.isConfirmed) {
                if (this.modalMoveForm.value.action == "after") {
                    let checkColLink = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][this.modalMoveForm.value.newPosition].value.colonneLink;
                    if (checkColLink && checkColLink.length > 0) {
                        this.modalMoveForm.get("newPosition").setValue(parseInt(this.modalMoveForm.value.newPosition) + 1 + checkColLink.length);
                    } else {
                        this.modalMoveForm.get("newPosition").setValue(parseInt(this.modalMoveForm.value.newPosition) + 1);
                    }
                }
                let col = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][indexColonne];

                (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).insert(this.modalMoveForm.value.newPosition, col);

                let newColLink = [];
                if (col.value.colonneLink && col.value.colonneLink.length > 0) {
                    let linkIndex = 1;
                    nbCol = col.value.colonneLink.length + 1;

                    col.value.colonneLink.forEach((link) => {
                        if (indexColonne > parseInt(this.modalMoveForm.value.newPosition)) {
                            let linkCol = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][link + linkIndex];
                            (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).insert(parseInt(this.modalMoveForm.value.newPosition) + linkIndex, linkCol);
                            linkIndex++;
                        } else {
                            let linkCol = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][link];
                            (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).insert(parseInt(this.modalMoveForm.value.newPosition) + linkIndex, linkCol);
                        }
                    });
                }
                let beginIndex = 0;
                let lastIndex = 0;
                if (indexColonne > this.modalMoveForm.value.newPosition) {
                    beginIndex = this.modalMoveForm.value.newPosition;
                    lastIndex = indexColonne;
                    if (col.value.colonneLink && col.value.colonneLink.length > 0) {
                        col.value.colonneLink.reverse().forEach((link) => {
                            if (link > lastIndex) {
                                lastIndex = link;
                            }
                            (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).removeAt(link + nbCol);
                        });
                    }
                    (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).removeAt(indexColonne + nbCol);
                } else {
                    beginIndex = indexColonne;
                    lastIndex = this.modalMoveForm.value.newPosition;
                    if (col.value.colonneLink && col.value.colonneLink.length > 0) {
                        col.value.colonneLink.reverse().forEach((link) => {
                            (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).removeAt(link);
                        });
                    }
                    (this.tableForm.controls.exports["controls"][search]['controls'].colonnes as FormArray).removeAt(indexColonne);
                    newColLink.forEach((col) => {
                        if (col > lastIndex) {
                            lastIndex = col;
                        }
                    });
                }

                this.changeColLinkAfterMove(beginIndex, lastIndex, exportId);
                this.makeTabeColor(exportId);
            }
        });
    }

    changeColLinkAfterMove(begin, last, exportId) {

        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
        let search = exportIds.indexOf(exportId); 

        for (let index = begin; index < last; index++) {
            let colLink = this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][index].value.colonneLink;
            let indexCol = 1;
            let newColLink = [];

            if (colLink && colLink.length > 0) {
                colLink.forEach((link) => {
                    newColLink.push(parseInt(index) + indexCol);
                    indexCol++;
                });
            }
            if (newColLink.length > 0) {
                this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"][index].get("colonneLink").setValue(newColLink);
            }
        }
    }

    listFieldCascade(filtre = "") {
        this.cascadefieldList = [];
        this.cascadefieldList.push({
            id: null,
            name: "No Filter",
        });
        this.flux[this.exportId]["elementTypes"].forEach((elementType) => {
            let listfield = this.flux[this.exportId]["fields"][elementType.name].sort((a, b) => a.name.localeCompare(b.name));

            if (filtre != "" && filtre.length > 2) {
                listfield = listfield.filter((field) => {
                    return field.name.toLowerCase().includes(filtre.toLowerCase());
                });
            }
            this.cascadefieldList.push({
                id: elementType.id,
                name: elementType.name,
                fields: listfield,
            });
        });
    }

    onFilter(event: any, exportId) {
        this.colFilter = [];
        let indexCol = 0;
        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        let search = exportIds.indexOf(exportId); 

        this.tableForm.value.exports[search].colonnes.forEach((colonne) => {
            colonne.elementTypes.forEach((elementType) => {
                let index = elementType.fields.findIndex((field) => {
                    return event.value.id == field.id;
                });

                if (index >= 0) {
                    this.colFilter.push(indexCol);
                }
            });
            indexCol++;
        });

        if (this.colFilter.length > 0) {
            document.getElementById(this.colFilter[0]).scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }

    makeTabeColor(exportId) {
        let colPair = false;

        let exportIds = this.tableForm.value.exports.map((exp) => exp.id);
        
        let search = exportIds.indexOf(exportId); 

        if(this.tableForm.controls.exports["controls"][search]){
            this.tableForm.controls.exports["controls"][search]['controls'].colonnes["controls"].forEach((col) => {
                if (col.value.removeCol) {
                    col.get("bgColor").setValue(colPair);
                    colPair = !colPair;
                }
            });
        }

    }

    activeColonneInput(indexColonne: number) {
        if (this.inputActive.includes(indexColonne)) {
            this.inputActive.splice(this.inputActive.indexOf(indexColonne), 1);
        } else {
            this.inputActive.push(indexColonne);
        }
    }

    /**
     * Validator to control that fieldProtocol is unique in each tab
     * @returns
     */
    uniqueValueFieldProtocol(): ValidatorFn {
        return (formArray: FormArray): null => {
            let values = [];
            formArray.value.forEach((fields) => {
                values = values.concat(fields);
            });
            values = values.map((v) => v.protocol);

            formArray.controls.forEach((form: FormGroup) => {
                const control = form.controls.protocol;
                if (values.filter((v: string) => v.trim().toLowerCase() === control.value.trim().toLowerCase()).length > 1 && control.value) {
                    control.setErrors({ uniqueValue: true });
                } else {
                    const errors = control.errors;
                    if (errors && errors.uniqueValue) {
                        delete errors.uniqueValue;
                    }
                    if (errors && Object.keys(errors).length === 0) {
                        control.setErrors(null);
                    } else {
                        control.setErrors(errors);
                    }
                }
            });

            return null;
        };
    }
}
