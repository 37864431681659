import { NgModule } from "@angular/core";
import { GroupService } from "app/core/services/admin/group/group.service";
import { SharedModule } from "app/shared";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { UserFormComponent } from "./form/user-form.component";
import { UserListModule } from "./list/user-list.module";
import { UserRoutingModule } from "./user.routing";

@NgModule({
    declarations: [UserFormComponent, ChangePasswordComponent],
    imports: [UserRoutingModule, UserListModule, SharedModule],
    providers: [GroupService]
})
export class UserModule {}
