
import {Component, OnDestroy, OnInit} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {TreeNode} from "@circlon/angular-tree-component";
import {faEye, faIndent, faTag, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "app/core/services/admin/user/user.service";
import {HistoryService} from "app/core/services/global/history/history.service";
import {LayoutService} from "app/core/services/global/layout/layout.service";
import {ToastService} from "app/core/services/global/toast/toast.service";
import {TreeService} from "app/core/services/global/tree/tree.service";
import {TagService} from "app/core/services/tag/tag.service";
import {PreviewService} from "app/core/services/thumbnails/preview.service";
import {ThumbnailsService} from "app/core/services/thumbnails/thumbnails.service";
import {SidebarTreeNodeComponent, SidebarTreeNodeOptions} from "app/shared/components/sidebar";
import {MediaType} from "app/shared/helpers/media-type.helper";
import {ThumbButtons} from "app/shared/helpers/thumb-buttons";
import {MaestroTag, MaestroTags} from "app/shared/models";
import {ACL} from "app/shared/models/acl";
import {LazyLoadDataModel} from "app/shared/models/lazy-load-data";
import {Thumb} from "app/shared/models/thumb";
import {UnsubscribePipe} from "app/shared/pipes";
import {MAESTRO_ROUTES} from "app/shared/routes";
import {saveAs} from "file-saver";
import * as JsZip from "jszip";
import {LazyLoadEvent, SelectItem} from "primeng-lts/api";
import {Subscription} from "rxjs";
import {environment} from "src/environments";
import {SidebarData} from "./thumbsnail-container.resolver";

@Component({
    selector: "app-thumbsnail-container",
    templateUrl: "./thumbsnail-container.component.html",
})
export class ThumbsnailContainerComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    public buttons = new ThumbButtons();
    public medias: Thumb[] = [];
    public filterOptions: { id: number; name: string; type: string; types?: string[] }[] = [];
    public faTimes = faTimes;
    public faTrash = faTrash;
    public faEye = faEye;
    public cart: { id: number; name: string; thumb: string; thumbPath: string }[] = [];
    public openCart = false;
    public damUrl: string;
    state: string = "all";
    currentDatatableFilters: LazyLoadEvent = {}; // Use to reload data when filter with tag/media type & action like delete
    sortOptions: SelectItem[] = [];
    recordsTotal: number = 0;
    recordsFiltered: number = 0;
    globalFilter: string = "";
    allFilters = {
        filterTags: {
            data: [],
            cascadeSelect: true,
            field: "",
        },
    };

    mediaParam: Thumb = null;


    constructor(
        private service: ThumbnailsService,
        private layout: LayoutService,
        private router: Router,
        private route: ActivatedRoute,
        private unsuscribe: UnsubscribePipe,
        private translate: TranslateService,
        private _previewService: PreviewService,
        private _sanitizer: DomSanitizer,
        private _historyService: HistoryService,
        private _layout: LayoutService,
        private _toastService: ToastService,
        private _treeService: TreeService,
        private _tagService: TagService,
        private _userService: UserService
    ) {}

    ngOnInit() {
        (document.getElementsByClassName("app-body")[0] as HTMLElement).style.overflowY = "hidden";

        const resources: SidebarData = this.route.snapshot.data.resources;

        const acl: ACL = this._userService.getUserAclFromToken();

        this.layout.sidebar.enable = true;
        this.layout.sidebar.sideBarDef = null;

        if (acl.SYSPAD_DAM_MEDIA_CREATE) {
            this.layout.actionButton.enable = true;
            this.layout.actionButton.title = "dam.upload";
            this.subscriptions.push(this.layout.actionButton.click$.subscribe((_) => this.onUploadPageClick())); // Subscribe to the function that update medias
        }

        if (!acl.MAESTRO_DAM_PICTO_DELETE) {
            this.buttons.delete = false;
        }

        this.layout.breadcrumb.setPath({routerLink: null, name: this.translate.instant("breadcrumb.dam.thumbnails")}, 1);

        /**
         * Configure the sidebar
         */
        this.layout.sidebar.sideBarDef = {
            component: SidebarTreeNodeComponent,
            options: <SidebarTreeNodeOptions>[
                {
                    title: "Tags",
                    icon: faTag,
                    click: this.addFilterTag.bind(this),
                    data: resources.tags.sort((a, b) => a.name.localeCompare(b.name)),
                },
                {
                    title: "Types",
                    icon: faIndent,
                    click: this.addTypeTag.bind(this),
                    data: [
                        {
                            id: 1,
                            name: "Images",
                            types: MediaType.image,
                        },
                        {
                            id: 2,
                            name: "Videos",
                            types: MediaType.video,
                        },
                        {
                            id: 3,
                            name: "Audio",
                            types: MediaType.audio,
                        },
                        {
                            id: 4,
                            name: "Page Layout Files",
                            types: MediaType.page,
                        },
                        {
                            id: 5,
                            name: "Drawing",
                            types: MediaType.drawing,
                        },
                        {
                            id: 6,
                            name: "Documents",
                            types: MediaType.misc,
                        },
                        {
                            id: 7,
                            name: "Picto",
                        },
                    ].filter((d) => (acl.MAESTRO_DAM_PICTO_LIST !== 1 ? d.id !== 7 : true)),
                }
            ],
        };

        this.cart = JSON.parse(localStorage.getItem("maestro_dam_cart")) || [];

        this._initData(resources);
        this.damUrl = environment.damUrl;

        this.getData(this.currentDatatableFilters);

    }

    ngOnDestroy(): void {
        this.layout.breadcrumb.setPath(null, 1);
        this.unsuscribe.transform(this.subscriptions);
        (document.getElementsByClassName("app-body")[0] as HTMLElement).style.overflowY = "auto";
        this.layout.sidebar.enable = false;
        this.layout.sidebar.sideBarDef = null;
    }

    /**
     * Init data
     *
     * @param resources
     */
    private _initData(resources: any) {
        let tagsCopy = resources.tags.map((t) => {
            return {...t};
        }); // Cut reference to sidebar tags
        this._initFilters(tagsCopy);
    }

    /**
     * Init filters with tags
     * @param tags
     */
    private _initFilters(tags: MaestroTags) {
        const nodeTags = this._treeService.createTreeNode(tags, "name", "label", true); // Need to keep the name for sidebar display

        this.allFilters.filterTags.data = nodeTags;
    }

    private onUploadPageClick() {
        this.router.navigate([MAESTRO_ROUTES.upload.base]);
    }

    public addFilterTagFromThumb(tag: MaestroTag): void {
        if (!this.filterOptions.find((f) => f.name === tag.name && f.type === "tag")) {
            this.filterOptions.push({id: tag.id, name: tag.name, type: "tag"});
            this.loadList(this.currentDatatableFilters);
        }
    }

    /**
     * Add a tag to the filters
     * @param node
     */
    private addFilterTag(node: TreeNode): void {
        const tag: MaestroTag = node.data;

        this.addFilterTagFromThumb(tag);
    }

    /**
     * Add a type (docs, images, videos, etc) to the filters
     * @param node
     */
    private addTypeTag(node: TreeNode): void {
        if (!this.filterOptions.find((f) => f.name === node.data.name && f.type === "type")) {
            if (node.data.name !== "Picto") {
                this.filterOptions = this.filterOptions.filter((f) => f.type !== "type");
            }
            if (node.data.name === "Picto" && !this.filterOptions.find((f) => f.type === "picto")) {
                this.filterOptions.push({id: node.data.id, name: node.data.name, type: "picto"});
            } else if (node.data.name !== "Picto") {
                this.filterOptions.push({id: node.data.id, name: node.data.name, type: "type", types: node.data.types});
            }
            this.loadList(this.currentDatatableFilters);
        }
    }

    /**
     * Remove a filter option from the filter's list
     * @param name
     */
    public removeFilterOption(name: string): void {
        for (let i = 0; i < this.filterOptions.length; i++) {
            if (this.filterOptions[i].name === name) {
                this.filterOptions.splice(i, 1);
            }
        }
        this.loadList(this.currentDatatableFilters);
    }

    public removeAllFilterOptions(): void {
        this.filterOptions.length = 0;
        this.loadList(this.currentDatatableFilters);
    }

    /**
     * Add a media to the cart
     *
     * @param media
     */
    addToCart(media: Thumb): void {
        if (!this.cart.map((m) => m.id).includes(media.id)) {
            this.cart.push({id: media.id, name: media.name, thumb: media.thumb, thumbPath: media.thumbPath});
            this._storeCart();
        }
    }

    /**
     * Remove from cart
     *
     * @param id
     */
    removeFromCart(id: number): void {
        this.cart = this.cart.filter((m) => m.id !== id);
        this._storeCart();
    }

    /**
     * Download the cart in a zip file
     */
    downloadCart() {
        const zip = new JsZip();
        let filesAdded = 0;
        this._layout.spinner.show();

        this.cart.forEach((media) => {
            this._previewService.getDownloadFile(media.id).subscribe((data) => {
                zip.file(media.name, data.data.file, {base64: true});
                this._historyService
                    .setHistory({
                        scenario: "download",
                        objectId: media.id,
                        userId: this._userService.getUserIdFromToken(),
                        module: "dam",
                        className: "Media",
                        value: '{"name": "mediaName"}',
                        version: 0,
                    })
                    .subscribe();
                filesAdded++;

                if (filesAdded === this.cart.length) {
                    this._toastService.show({message: this.translate.instant("projects.export.pending_download"), type: "info"});
                    zip.generateAsync({type: "blob"}).then(
                        function (content) {
                            saveAs(content, "medias.zip");
                            this.emptyCart();
                            this._layout.spinner.hide();
                        }.bind(this)
                    );
                }
            });
        });
    }

    getMediaThumb(extension: string, thumb: string): any {
        return this.sanitize(`data:image/${extension};base64,${thumb}`);
    }

    /**
     * Empty cart
     *
     */
    emptyCart(): void {
        this.cart = [];
        localStorage.removeItem("maestro_dam_cart");
    }

    /**
     * Store cart in the local storage
     */
    private _storeCart(): void {
        localStorage.setItem("maestro_dam_cart", JSON.stringify(this.cart));
    }

    /**
     * Sanitize the url
     *
     * @param url
     * @returns
     */
    sanitize(url: string): SafeResourceUrl {
        return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    loadList(event: LazyLoadEvent) {

        event.globalFilter = this.globalFilter; // Need to be forced since dataview will not bind it himself
        let customFilters = {tags: [], type: []}; // Same as properties used in back function repository

        if (this.filterOptions.length) {
            this.filterOptions.map((f) => {
                if ("tag" === f.type) {
                    return customFilters.tags.push(f.id);
                } else if ("type" === f.type) {
                    return (customFilters.type = f.types);
                }
            });
        }

        const requestFilters: LazyLoadDataModel = {lazyLoadFilters: event, customFilters: customFilters, state: this.state};

        this.subscriptions.push(
            this.service.getDataviewMedias(JSON.stringify(requestFilters)).subscribe((resp) => {
                const data = resp.data;
                this.medias = data.medias;
                let translatedOptions = [];
                this.sortOptions = data.sortOptions.forEach((option) => {
                    option.label = this.translate.instant("dataview.sortField." + option.label) + " " + this.translate.instant("dataview.sortParameter." + option.translationKey);
                    translatedOptions.push(option);
                });

                this.sortOptions = translatedOptions;
                this.recordsTotal = data.recordsTotal;
                this.recordsFiltered = data.recordsFiltered;
                this.currentDatatableFilters = event;
            })
        );
    }

    getData(event: LazyLoadEvent) {
        if (this.route.queryParams['_value'].id) {
            let customFilters = {tags: [], type: []}
            const requestId: LazyLoadDataModel =
                {
                    lazyLoadFilters: event,
                    customFilters: customFilters,
                    state: this.state,
                    id: this.route.queryParams['_value'].id
                };
            this.service.getDataviewMedias((JSON.stringify(requestId))).subscribe((resp) => {
                const data = resp.data;
                if (data.medias[0]) {
                    this.service.setMediaParam(data.medias[0]);
                } else {
                    this.service.setMediaParam(null);
                }
            })

        } else {
            this.service.setMediaParam(null);
        }
    }


    changeHideExpireMedia(event) {
        this.state = event;
        this.loadList(this.currentDatatableFilters);
    }

    loadListWithSearch(value: string) {
        this.globalFilter = value;

        if ("" !== value) {
            this.currentDatatableFilters.first = 0;
        }

        this.loadList(this.currentDatatableFilters);
    }

    onDeleteMedia(id: number) {
        this.service.deleteMedia(id).subscribe(() => {
            this.removeAndReload(id);
        });
    }

    removeAndReload(id: number) {
        this.removeFromCart(id);
        this.loadList(this.currentDatatableFilters);
    }

    massTagSelection(event: any): void {
        const objectIds = this.cart.map((m) => m.id);
        event["objectIds"] = objectIds;

        this._tagService.manageEntitiesTag("media", event.dataIds, event.objectIds, event.add).subscribe();
    }
}
