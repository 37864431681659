<ng-multiselect-dropdown
    *ngIf="list"
    [placeholder]="placeholder"
    [settings]="settings"
    [data]="list"
    [disabled]="disabled"
    [(ngModel)]="selectedItems"
    (ngModelChange)="onChange($event)"
>
</ng-multiselect-dropdown>
