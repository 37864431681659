export class ThumbButtons {
    preview = true;
    create = true;
    fill = true;
    delete = true;
    unlink = false;
    image = true;
    cart = true;
    send = true;
    crop = true;
    select = false;
    module = "dam";

    static asPimButtons(): ThumbButtons {
        const thumbButtons = new ThumbButtons();
        thumbButtons.module = "pim";
        thumbButtons.create = false;
        thumbButtons.fill = false;
        thumbButtons.delete = false;
        thumbButtons.unlink = true;
        thumbButtons.image = false;
        thumbButtons.cart = false;
        thumbButtons.send = false;
        thumbButtons.crop = false;
        thumbButtons.select = false;
        return thumbButtons;
    }
    
    static asPageButtons(): ThumbButtons {
        const thumbButtons = new ThumbButtons();
        thumbButtons.module = "page";
        thumbButtons.preview = false;
        thumbButtons.create = false;
        thumbButtons.fill = false;
        thumbButtons.delete = false;
        thumbButtons.unlink = false;
        thumbButtons.image = false;
        thumbButtons.cart = false;
        thumbButtons.send = false;
        thumbButtons.crop = false;
        thumbButtons.select = true;
        return thumbButtons;
    }
}
