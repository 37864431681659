import { Component, OnDestroy } from "@angular/core";
import { faCog, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { BreadcrumbMenuService } from "app/core/services/global/layout/menu.service";

import { Subscription } from "rxjs";

@Component({
    selector: "app-bread-crumb-menu",
    templateUrl: "./bread-crumb-menu.component.html",
})
export class BreadCrumbMenuComponent implements OnDestroy {
    items: MenuItem[];
    enabled: boolean;

    readonly faCog = faCog;
    private readonly _sub: Subscription;

    constructor(_service: BreadcrumbMenuService) {
        this._sub = _service.items$.subscribe((menu) => {
            this.items = menu;
            this.enabled = !!menu && !!menu.length;
        });
    }

    ngOnDestroy(): void {
        this._sub.unsubscribe();
    }
}

class MenuItem {
    icon: IconDefinition;
    routerLink: any[] | string;
    text: string;
}

export type MenuItems = MenuItem[];
