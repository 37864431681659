import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { faArrowsAltH, faArrowsAltV, faRedoAlt, faTrash, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { PreviewService } from "app/core/services/thumbnails/preview.service";
import { ExportElementMedia } from "app/shared/models/project/export-element.model";
import { CropperPosition, Dimensions, ImageCroppedEvent, ImageCropperComponent, ImageTransform } from "ngx-image-cropper";
import { map } from "rxjs/operators";

@Component({
    selector: "app-cropper",
    templateUrl: "./cropper.component.html",
})
export class CropperComponent implements OnInit {
    @Output() cropped = new EventEmitter<{ croppedImage: ImageCroppedEvent; transform: ImageTransform; rotation: number; format: string }>();
    @Output() removeCrop = new EventEmitter<any>();
    
    @Input() defaultRatio: boolean = false;
    @Input() module: string = "dam";
    imageWidth: number;
    imageHeight: number;
    imageBase64: any = "";
    croppedImage: ImageCroppedEvent;
    canvasRotation = 0;
    rotation = 0;
    degree = 0;
    scale = 1;
    cropperPos: CropperPosition = {x1:0,y1:0, x2:0,y2:0};
    currentImage : any;
    isCrop: boolean;

    containWithinAspectRatio = false;
    maintainAspectRatio = false;
    ratio = 1;

    transform: ImageTransform = {
        flipH: false,
        flipV: false,
        rotate: 0,
        scale: 1,
    };

    flipH = false;
    flipV = false;

    formats: { name: string; ratio: string[] }[];

    private _format: string;

    readonly faLeft = faUndoAlt;
    readonly faRight = faRedoAlt;
    readonly faFlipV = faArrowsAltV;
    readonly faFlipH = faArrowsAltH;
    readonly faTrash = faTrash;

    @ViewChild("angularCropper") public cropper: ImageCropperComponent;

    @ViewChild("cropSwal") cropSwal: SwalComponent;

    constructor(
        private _previewService: PreviewService,
        private _modalService: SwalModalService
        ) {}

    ngOnInit(): void {}

    openCrop(mediaId: number, imageWidth?: number, imageHeight?: number, transform?: ImageTransform, currentImage?: ExportElementMedia): void {
        this.resetImage();
        this.imageHeight = imageHeight;
        this.imageWidth = imageWidth;
        
        this.currentImage = currentImage
        if (transform) {
            this.transform = transform;
            this.rotation = transform.rotate;
        }

        // Get the preview for the media
        this._previewService
            .getPreview(mediaId, 'true')
            .pipe(map((r) => r.data))
            .subscribe((preview) => {
                this.imageBase64 = `data:image/${preview.metadata.metadata.type};base64,${preview.file}`;
                // Open the modal to crop the image
                this.cropSwal.fire().then((result) => {
                    if (result.value) {
                        this.cropped.emit({ croppedImage: this.croppedImage, transform: this.transform, rotation: this.degree, format: this._format });
                    }
                });
            });
        if (this.defaultRatio) {
            // Get available formats
            this._previewService
                .getFormats()
                .pipe(map((r: any) => r.data))
                .subscribe((formats) => {
                    this.formats = formats;
                });
        }
    }

    /**
     * [WIP]
     * @param event
     */
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event;  
    }

    /**
     * [WIP]
     */
    imageLoaded() {
        // Do something if necessary
    }

    /**
     * @param sourceImageDimensions
     */
    cropperReady(sourceImageDimensions: Dimensions) {
        // By default set the aspect ratio only if we have width & height (used in project's module)
        if (this.imageWidth && this.imageHeight) {
            this.setAspectRatio(this.imageWidth / this.imageHeight, "");
        }
    }

    /**
     * Set aspect ratio
     *
     * @param ratio
     */
    setAspectRatio(ratio: number | string, format: string): void {
        if (typeof ratio === "string") {
            if (ratio.length > 1) {
                ratio = Number(ratio.split("/")[0]) / Number(ratio.split("/")[1]);
            } else {
                ratio = Number(ratio);
            }
        }
        this.ratio = ratio;
        this._format = format;
        this.maintainAspectRatio = true;
        if(this.module === "projects" && this.currentImage.width > 0 && this.currentImage.height > 0){
            this.cropperPos = {
                x1:this.currentImage.x,
                y1:this.currentImage.y,
                x2:this.currentImage.x + this.currentImage.width,
                y2:this.currentImage.y + this.currentImage.height
            }     
            this.isCrop = true;
        } else {
            this.isCrop = false;
        }
    }

    /**
     * Set no ratio
     */
    setNoRatio(): void {
        this.ratio = 1;
        this.maintainAspectRatio = false;
        this._format = "";
    }

    loadImageFailed() {
        //TODO : Check if this method is usefull
        //console.log("Load failed");
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH,
        };
        this.canvasRotation = this.canvasRotation % 4;
        let deg = this.canvasRotation * 90;
        if (deg < 0) {
            deg = 360 + deg;
        }
        this.degree = deg;
    }

    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH,
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV,
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    zoomIn() {
        this.scale += 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation,
        };
    }

    deleteCrop() {
        this._modalService.deleteWithContent("modal.deleteCrop").then((result) => {
            if (result.value) {       
                this.removeCrop.emit();
            }
        });
    }
}
