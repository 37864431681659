import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "app/core/services/admin/common.service";
import { GroupService } from "app/core/services/admin/group/group.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { MaestroGroups } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ConfirmationService } from "primeng-lts/api";
import { Subscription } from "rxjs";

@Component({
    selector: "app-group-list",
    templateUrl: "./group-list.component.html",
    styleUrls: ["./group-list.component.scss"],
})
export class GroupListComponent implements TableView, OnInit, OnDestroy {
    private subscription: Subscription;
    table: [string, string][];
    columns: string[];
    public actions: ActionButtonsDef = [];
    private acl: ACL;
    private static envParamControl: string = "enable_group_control";

    constructor(
        private _layout: LayoutService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _tableDataPipe: TableDataPipe,
        private _modal: SwalModalService,
        private _translateService: TranslateService,
        private _modalService: SwalModalService,
        private _groupService: GroupService,
        private _commonService: CommonService,
        private _confirmationService: ConfirmationService,
        private _userService: UserService
    ) {}

    ngOnInit() {
        const groups: MaestroGroups = this._route.snapshot.data.groups.data;

        this._initList(groups);

        this.acl = this._userService.getUserAclFromToken();

        this._commonService.checkParamControl(GroupListComponent.envParamControl).subscribe((data) => {
            const enableGroupControl = data.data;

            /**
             * Prepare the create button
             */
            if (!enableGroupControl && this.acl.MAESTRO_ADMIN_GROUP_CREATE) {
                this._layout.actionButton.enable = true;
                this._layout.actionButton.title = "admin.create.group";
                this.subscription = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
            }

            if (this.acl.MAESTRO_ADMIN_GROUP_UPDATE) {
                this.actions.push({ type: ActionType.Update, right: "MAESTRO_ADMIN_GROUP_UPDATE" });
            }

            if (!enableGroupControl && this.acl.MAESTRO_ADMIN_GROUP_DELETE) {
                this.actions.push({ type: ActionType.Delete, right: "MAESTRO_ADMIN_GROUP_DELETE" });
            }
        });
    }

    ngOnDestroy() {
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._layout.breadcrumb.setPath(null, 1);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * Init the list using the tableDataPipe
     * @param groups
     */
    private _initList(groups: MaestroGroups) {
        const groupsWithoutChildren = this._groupService.removeChildrenFromGroup(groups);
        const formatedData = this._tableDataPipe.transform(groupsWithoutChildren);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    /**
     * Handle the action click
     * @param event
     */
    onActionClick(event: ActionEvent): void {
        const id = event.rowId;
        switch (event.action) {
            /**
             * If update redirect
             */
            case ActionType.Update:
                this._router.navigate([id, MAESTRO_ROUTES.actions.update], { relativeTo: this._route });
                break;
            case ActionType.Delete:
                this._delete(id);
                break;
            default:
                break;
        }
    }

    /**
     * Open the create modal
     */
    onCreate() {
        this._openModal();
    }

    /**
     * Create a new group
     * @param title
     */
    private _create(title) {
        this._groupService.create({ name: title }).subscribe(() => {
            this._groupService.getAll().subscribe((groups) => this._initList(groups.data));
        });
    }

    /**
     * Define and open a popup to create a new group
     */
    private _openModal() {
        this._modal
            .open({
                title: this._translateService.instant("admin.groups.create"),
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: this._translateService.instant("general.create"),
                confirmButtonColor: "#20a8d8",
                showCancelButton: true,
                cancelButtonText: this._translateService.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                html: '<div class="form-group">' + '<input class="form-control" type="text" placeholder="" id="ModalFieldsetTitle">' + "</div>",
            })
            .then((result) => {
                if (result.value) {
                    this._create($("#ModalFieldsetTitle").val());
                }
            });
    }

    /**
     * Delete a group
     *
     * @param id
     */
    private _delete(id: number) {
        if (id) {
            this._groupService.getAssociatedProjects(id).subscribe((data) => {
                const projects = data.data;

                if (projects.length) {
                    this._layout.sidebar.enable = false;
                    const message = `
                        <h3 class="text-danger">
                            ${this._translateService.instant("admin.groups.cannotDelete", { projectsNumber: projects.length })}
                        </h3>
                        <br>
                        <hr>
                        <u>${this._translateService.instant("admin.groups.concernedProjects")} :</u>
                        ${projects.map((project) => {
                            let badgeColor;

                            if (project.archived) {
                                badgeColor = "badge-warning text-white";
                            } else {
                                badgeColor = "badge-primary";
                            }

                            return ` <span class="badge badge-pill ${badgeColor} mr-1">${project.name}</span>`;
                        })}
                        <br><br><hr>
                        <b>
                            <span class="badge badge-pill badge-warning text-white mr-1">
                                ${this._translateService.instant("projects.archived")}
                            </span>
                            |
                            <span class="badge badge-pill badge-primary mr-1">
                                ${this._translateService.instant("projects.notArchived")}
                            </span>
                        </b>
                        `;

                    this._confirmationService.confirm({
                        header: this._translateService.instant("general.warning"),
                        message: message,
                        icon: "pi pi-exclamation-triangle",
                        acceptLabel: this._translateService.instant("general.close"),
                        rejectVisible: false,
                        acceptButtonStyleClass: "bg-primary",
                        accept: () => {
                            this._layout.sidebar.enable = true;
                        },
                        reject: () => {
                            this._layout.sidebar.enable = true;
                        },
                    });
                } else {
                    this._groupService.delete(id).subscribe((data) => {
                        this._groupService.getAll().subscribe((groups) => this._initList(groups.data));
                        this._modalService.success(this._translateService.instant("general.deleted"));
                    });
                }
            });
        }
    }
}
