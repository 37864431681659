import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ProjectExportComponent } from "./export.component";
import { ProjectExportGuard } from "./export.guard";
import { FlatplanViewComponent } from "./flatplan/flatplan-view.component";
import { FlatplanViewResolver } from "./flatplan/flatplan-view.resolver";
import { ExportPageViewModule } from "./page/export-page-view.module";
import { FacebookComponent } from "./social/facebook/facebook.component";

const exportRoutes: Routes = [
    {
        path: "",
        canActivate: [ProjectExportGuard],
        children: [
            {
                path: ":id",
                component: ProjectExportComponent,
                resolve: { export: ProjectExportGuard },
                children: [
                    {
                        path: MAESTRO_ROUTES.projects.page,
                        loadChildren: () => ExportPageViewModule,
                    },
                    {
                        path: MAESTRO_ROUTES.projects.exportType.social.social,
                        component: FacebookComponent,
                    },

                    {
                        path: MAESTRO_ROUTES.projects.html,
                        component: FlatplanViewComponent,
                        resolve: { resources: FlatplanViewResolver },
                    },
                    {
                        path: MAESTRO_ROUTES.projects.exportType.flatplan,
                        component: FlatplanViewComponent,
                        resolve: { resources: FlatplanViewResolver },
                    },
                    {
                        path: MAESTRO_ROUTES.projects.exportType.marketing,
                        component: FlatplanViewComponent,
                        resolve: { resources: FlatplanViewResolver },
                    },
                    {
                        path: MAESTRO_ROUTES.projects.exportType.booklet,
                        component: FlatplanViewComponent,
                        resolve: { resources: FlatplanViewResolver },
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(exportRoutes)],
    exports: [RouterModule],
})
export class ProjectExportRoutingModule { }
