<!-- Modal -->
<div class="row" *ngIf="form">
    <div class="col-12">
        <h3 *ngIf="ressource.group">
            {{ 'admin.groups.configure' | translate }}
        </h3>
        <h3 *ngIf="!ressource.group">{{ 'admin.groups.create' | translate }}</h3>
    </div>
</div>
<div class="tableComponent row">
    <div class="col-12">
        <div class="card">
            <form *ngIf="form" [formGroup]="form" (ngSubmit)="formSubmit()" autocomplete="off">
                <div class="card-body">
                    <div class="form-group row">
                        <label class="col-5 col-md-2" for="name" translate>{{ 'admin.groups.name' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <input type="text" id="name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': formControls.name.dirty && formControls.name.errors }"/>
                            <div *ngIf="formControls.name.dirty && formControls.name.errors" class="invalid-feedback">
                                <div *ngIf="formControls.name.errors.required">
                                    {{ 'general.required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        
                        <label class="col-5 col-md-2" >{{ 'admin.groups.users' | translate }}</label>
                            
                        <div class="col-7 col-md-10">
                            
                            <ng-multiselect-dropdown formControlName="users" placeholder="{{ 'general.select' | translate }}" [settings]="dropdownGroupsSettings" [data]="ressource.users"></ng-multiselect-dropdown>
                        </div>    
                    </div>
                    <div class="form-group row">
                        <label class="col-5 col-md-2" >{{ 'admin.groups.associated' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <ng-multiselect-dropdown formControlName="groups" placeholder="{{ 'general.select' | translate }}" [settings]="dropdownGroupsSettings" [data]="mergedAssociableGroups"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="row">
                        <button class="btn btn-danger" type="button" (click)="onBack()">
                            <!-- <fa-icon [icon]="faTimes"></fa-icon> -->
                            <span class="d-none d-md-inline-block ml-2">{{ 'general.close' | translate }}</span>
                        </button>
                        <div class="btn-group ml-auto">
                            <button type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="formSubmit()">
                                <!-- <fa-icon [icon]="faSave"></fa-icon> -->
                                <span class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
