import { MaestroPage } from "app/shared/models";

/**
 * TODO : Merge this with the global menu component
 */
export enum ThumbnailMenuAction {
    PageDelete,
    TempalteEdit,
    ElementsEdit,
    Preview,
    Edit,
    UpdatePage,
    ImportPDF
}

export interface ThumbnailItem {
    title: string;
    action: ThumbnailMenuAction;
}

export type ThumbnailMenu = ThumbnailItem[];

export type ThumbnailItemEvent = MenutItemEvent<MaestroPage, ThumbnailMenuAction>;

/*generic*/
interface MenutItemEvent<T, Action> {
    action: Action;
    data: T;
}
