import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faCog, faDownload } from "@fortawesome/free-solid-svg-icons";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { ExportType, MaestroExport } from "app/shared/models";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { saveAs } from 'file-saver';
import PerfectScrollbar from "perfect-scrollbar";
import { ProjectDashboardService } from "../../../../../../core/services/project/project/dashboard.service";

@Component({
    selector: "[app-export-card]",
    templateUrl: "./export-card.component.html",
})
export class ExportCardComponent implements OnInit, OnDestroy {
    @Input() readonly export: MaestroExport = {};
    @ViewChild("versionSwal", { static: true }) versionSwal: SwalComponent;

    bgColor = "#ff0000";
    bgColorDarkened = "#ff0000";
    borderColor = "#ff0000";
    textColor = "black";
    configureRoute: string;
    editRoute: string;
    newVersion: FormGroup;

    readonly faCog = faCog;
    readonly faEdit = faEdit;
    readonly faDownload = faDownload;
    readonly projectRoutes = MAESTRO_ROUTES.projects;
    ps: PerfectScrollbar;

    isWorkable = false;

    constructor(
        private _dashboardService: ProjectDashboardService,
        private _fb: FormBuilder,) {}

    ngOnInit(): void {
        this.newVersion = this._fb.group({
            origin: ["", Validators.required],
            existing: [""],
            new: [""],
        });
        if (this.export) {
            this.isWorkable = this.export.type.toLowerCase() !== ExportType.Csv.toLowerCase() && this.export.type.toLowerCase() !== ExportType.Xml.toLowerCase();

            this.bgColor = this.export.color;
            document.documentElement.style.setProperty("--card-export-color", this.bgColor);
            this.bgColorDarkened = this.darken(this.bgColor, -10);
            this.borderColor = this.darken(this.bgColor, -20);
            this.textColor = this.luminance(this.bgColor);

            document.querySelectorAll(".description").forEach((description) => {
                this.ps = new PerfectScrollbar(description, {
                    wheelSpeed: 1,
                    minScrollbarLength: 20,
                });
            });

            this._initActionRoute();
        }
    }

    ngOnDestroy(): void {
        this.ps.destroy();
        this.ps = null; // to make sure garbages are collected
    }

    private darken(color, percent): string {
        const num = parseInt(color.replace("#", ""), 16);
        const amt = Math.round(2.55 * percent);
        const R = (num >> 16) + amt;
        const B = ((num >> 8) & 0x00ff) + amt;
        const G = (num & 0x0000ff) + amt;
        return "#" + (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255)).toString(16).slice(1);
    }

    private luminance(col): string {
        const threshold = 0.5;

        if (col[0] === "#") {
            col = col.slice(1);
        }

        const num = parseInt(col, 16);
        let r = num >> 16;
        let b = (num >> 8) & 0x00ff;
        let g = num & 0x0000ff;

        r = r * 0.2126;
        g = g * 0.7152;
        b = b * 0.0722;

        const sum = r + g + b;
        let lightness = sum / 255;
        lightness = (lightness - threshold) * -10000000;
        return lightness === 0 ? "black" : "white";
    }

    private _initActionRoute() {
        const projectIdRoute = `/${this.projectRoutes.base}/${this.export.project.id}`;
        this.editRoute = `${projectIdRoute}/${this.projectRoutes.export}/${this.export.id}`;
        this.configureRoute = `/${projectIdRoute}/${this.projectRoutes.configure}/${this.projectRoutes.steps.exports}`;
    }

    /**
     * Download CSV | XML
     */
    downloadFile(): void {
        switch (this.export.type.toLowerCase()) {
            case ExportType.Csv.toLowerCase():
                this.downloadCsv();
                break;
            case ExportType.Xml.toLowerCase():
                this.downloadXml();
                break;
        }
    }

    private downloadCsv(): void {
        this._dashboardService.getCsv(this.export.id).subscribe((response) => {
            saveAs(response.body, this.export.name + "-" + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".csv");
        });
    }

    // This one is commented because not ready in back.
    private downloadXml(): void {
        // this._dashboardService.getXml(this.export.id).subscribe(response => {
        //     saveAs(response.body, "exportXML-" + new Date().toISOString().slice(0, 10).replace(/-/g, "") +  ".csv")
        // });
    }
}
