import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { Observable } from "rxjs";

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private _authService: AuthService, private _router: Router) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        const loggedIn = this._authService.isLoggedIn();
        if (loggedIn) {
            this._router.navigate([MAESTRO_ROUTES.public.base]);
        }
        return !loggedIn;
    }
}
