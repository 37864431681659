import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faPlusCircle, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ListCascadeService } from "app/core/services/pim/listcascade.service";
import { TreeDragDropService } from 'primeng-lts/api';
declare var jQuery: any;

@Component({
    selector: "app-listcascade-update",
    templateUrl: "./listcascade-update.component.html",
    providers: [TreeDragDropService]
})
export class ListCascadeUpdateComponent implements OnInit {
    list: any = [];
    selectedValue: any = null;
    selectedValues: any[] = [];
    lastId: number = 0;
    elementsSeparator: "";
    chips: string[] = [];
    name: string = "";
    currentValue: string = "";
    changeList = false;
    updateName = false;
    infoAddValue = "";


    readonly faSave = faSave;
    readonly faPlus = faPlusCircle;
    readonly faTrashAlt = faTrashAlt;
    readonly faEdit = faEdit;
    readonly faClose = faTimes;


    @ViewChild("createModal", { static: false }) createModal: ElementRef;
    @ViewChild("updateModal", { static: false }) updateModal: ElementRef;

    constructor(
        private _swalModalService: SwalModalService,
        private _listcascadeService: ListCascadeService,
        private _router: Router,
        private _translate: TranslateService,
        private _route: ActivatedRoute
    ) {
    }
    ngOnInit(): void {
        this.name = this._route.snapshot.data.list.name;
        this.list = this._route.snapshot.data.list.listValues ?? [];
        this.lastId = this._route.snapshot.data.list.lastId;

        this.infoAddValue = this._translate.instant("general.addNewValueEnter");
    }

    onSelect(event) {
        this.selectedValues = [];

        this.selectedValues.push(event.node);
        this.selectedValue = event.node
        this.currentValue = event.node.name;
    }

    onUnselect() {
        this.selectedValue = null
        this.currentValue = "";
    }

    addValue() {
        if (this.chips.length) {
            this.changeList = true;
            this.chips.forEach(chip => {
                this.lastId = this.lastId + 1;
                if (this.selectedValue == null) {
                    this.list.push({
                        children: null,
                        id: this.lastId,
                        key: this.lastId,
                        label: chip,
                        name: chip,
                        parent: null,
                        selected: false
                    })
                } else {
                    if (this.selectedValue.children == null) {
                        this.selectedValue.children = [];
                    }
                    this.selectedValue.children.push({
                        children: null,
                        id: this.lastId,
                        key: this.lastId,
                        label: chip,
                        name: chip,
                        parent: this.selectedValue,
                        selected: false
                    })
                }

            });
        }
        this.chips = [];
        jQuery(this.createModal.nativeElement).modal("hide");
    }

    updateValue() {

        this.selectedValue.name = this.currentValue;
        this.selectedValue.label = this.currentValue;
        jQuery(this.updateModal.nativeElement).modal("hide");
    }

    /**
     * Open create modal
     */
    openCreateModal() {
        jQuery(this.createModal.nativeElement).modal("show");
    }

    /**
     * Open create modal
     */
    openUpdateModal() {
        jQuery(this.updateModal.nativeElement).modal("show");
    }


    saveList() {
        let newList = this.fixRecursive(this.list);
        this._listcascadeService.updateListCascade(this._route.params["_value"].id, newList, this.lastId, this.name).subscribe((data) => {
        });
    }

    fixRecursive(list) {
        list.forEach(value => {
            value.parent = null;
            if (value.children && value.children.length > 0) {
                this.fixRecursive(value.children);
            }
        });

        return list;

    }

    deleteValue() {
        this._swalModalService.delete().then((result) => {
            if (result.isConfirmed) {
                if (this.selectedValue.parent){
                    let parent = this.selectedValue.parent;
                    let index = parent.children.map(child => child.id).indexOf(this.selectedValue.id);
                    parent.children.splice(index, 1);
                } else {
                    let index = this.list.map(value => value.id).indexOf(this.selectedValue.id);
                    this.list.splice(index, 1);
                }
                this.onUnselect();
            }
        })
    }

    saveName(newName){
        this.changeList = true;
        this.name = newName;
    }

    expandCollapse(action){
        this.list.forEach(node => {
            if(action == "expand"){
                this.expandRecursive(node, true);
            } else {
                this.expandRecursive(node, false);
            }
        });
        
        
    }

    private expandRecursive(node, isExpand:boolean){
        node.expanded = isExpand;
        if (node.children){
            node.children.forEach( childNode => {
                this.expandRecursive(childNode, isExpand);
            } );
        }
    }
}