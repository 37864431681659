import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ElementService } from "app/core/services/pim/element.service";
import { VersionService } from "app/core/services/pim/version.service";
import { ElementListResources } from "app/shared/models/pim/pim-home-resources";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ElementListResolver implements Resolve<ElementListResources> {
    constructor(private service: ElementService, private versionService: VersionService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ElementListResources> {
        return forkJoin([this.service.getSidebarData(), this.versionService.getVersions()]).pipe(
            map(
                (data) =>
                    <any>{
                        tags: data[0].tags,
                        types: data[0].types,
                        versions: data[1].data.list,
                    }
            )
        );
    }
}
