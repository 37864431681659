import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { MediaService } from "app/core/services/dam/media.service";
import { MediaModel } from "app/shared/models/media-model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class EditFormResolver implements Resolve<MediaModel> {
    constructor(private _service: MediaService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<MediaModel> {
        const mediaId = route.params.id;
        return this._service.getMedia(mediaId).pipe(map((r: any) => r.data));
    }
}
