import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ElementUpdateComponent } from "./element-update.component";
import { ElementUpdateGuard } from "./element-update.guard";
import { ElementUpdateResolver } from "./element-update.resolver";
import { ElementUpdateMediaComponent } from "./media/element-update-media.component";
import { ElementUpdateTabComponent } from "./tab/element-update-tab.component";

const pimElementUpdateRoutes: Routes = [
    {
        path: `${MAESTRO_ROUTES.actions.update}/:id`,
        component: ElementUpdateComponent,
        resolve: { resources: ElementUpdateResolver },
        canActivate: [ElementUpdateGuard],
        children: [
            {
                path: "media",
                component: ElementUpdateMediaComponent,
            },
            {
                path: ":tabId",
                component: ElementUpdateTabComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(pimElementUpdateRoutes)],
    exports: [RouterModule],
})
export class ElementUpdateRoutingModule {}
