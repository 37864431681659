<div *ngIf="isWorkable">
    <div class="form-group">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem *ngFor="let elementType of typesFormArray.controls; let i = index">
                <a ngbNavLink>
                    <app-form-label [invalid]="elementType.invalid" [valid]="elementType.valid">
                        {{ elementType.get('title').value }}
                    </app-form-label>
                </a>
                <ng-template ngbNavContent>
                    <!-- <app-template-configuration-tabs [forElement]="elementType.get('forElement').value" [fields]="elementType.get('fields')"></app-template-configuration-tabs> -->
                    <app-template-form [forElement]="elementType.get('forElement').value" [fieldsArray]="elementType.get('fields')"></app-template-form>
                </ng-template>
            </li>
        </ul>
    
        <div [ngbNavOutlet]="nav"></div>
    
    </div>
    <div class="row">
        <div class="col-6 text-left">
            <div class="btn-group">
                <button class="btn btn-danger" (click)="onBack()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-2">{{ 'general.cancel' | translate }}</span>
                </button>
            </div>
        </div>
        <div class="col-6 text-right">
            <div class="btn-group">
                <button class="btn btn-primary" [disabled]="form.invalid || form.value.description === '<p></p>'" (click)="onSubmit()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span *ngIf="canUpdate" class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }}</span>
                    <span *ngIf="!canUpdate" class="d-none d-md-inline-block ml-2">{{ 'general.next' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isWorkable">
    <label> {{ 'projects.export.emptyExport' | translate }} </label>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{'width':'100%'}"></p-confirmDialog>