import { Injectable } from "@angular/core";
import { FlatplanViewType } from "app/shared/helpers/bread-crumb-flatplan-toolbar";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class BreadcrumbFlatplanToolbarService {
    private _maxpagesSource = new BehaviorSubject<Number>(this.maxpages || 0);
    private _nbpagesSource = new BehaviorSubject<Number>(this.nbpages || 0);
    private _enabledSource = new BehaviorSubject<Boolean>(false);
    private _switcherEnabledSource = new BehaviorSubject<Boolean>(false);
    private _flatplanViewSource = new BehaviorSubject<FlatplanViewType>(FlatplanViewType.Image);
    private _idFlatplanSource = new BehaviorSubject<Number>(this.idFlatplan || 0);
    private _exportTypeSource = new BehaviorSubject<string>(this.exportType || "");

    readonly maxpages$ = this._maxpagesSource;
    readonly nbpages$ = this._nbpagesSource;
    readonly enabled$ = this._enabledSource;
    readonly switcherEnabled$ = this._switcherEnabledSource;
    readonly flatplanView$ = this._flatplanViewSource;
    readonly idFlatplan$ = this._idFlatplanSource;
    readonly exportType$ = this._exportTypeSource;

    private _maxpages: Number;
    private _nbpages: Number;
    private _enabled: Boolean;
    private _switcherEnabled: Boolean;
    private _flatplanView: FlatplanViewType;
    private _idFlatplan: Number;
    private _exportType: string;

    /**
     * Set value for maxpages (only used in project)
     */
    set maxpages(maxpages: Number) {
        this._maxpages = maxpages;
        this._maxpagesSource.next(this._maxpages);
    }

    /**
     * Set value for nbpages (only used in project)
     */
    set nbpages(nbpages: Number) {
        this._nbpages = nbpages;
        this._nbpagesSource.next(this._nbpages);
    }

    /**
     * Set value for enable to determine if we display the component (only used in project)
     */
    set enabled(value: Boolean) {
        this._enabled = value;
        this._enabledSource.next(this._enabled);
    }

    /**
     * Set value for switcher to determine if we can switch between multiple layout (only used in project)
     */
    set switcherEnabled(value: Boolean) {
        this._switcherEnabled = value;
        this._switcherEnabledSource.next(this._switcherEnabled);
    }

    set flatplanView(value: FlatplanViewType) {
        this._flatplanView = value;
        this._flatplanViewSource.next(this._flatplanView);
    }

    set idFlatplan(value: Number) {
        this._idFlatplan = value;
        this._idFlatplanSource.next(this._idFlatplan);
    }

    set exportType(value: string) {
        this._exportType = value;
        this._exportTypeSource.next(this._exportType);
    }
}
