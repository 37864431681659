import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { MaestroValidators } from "app/shared/validators/maestro.validators";
import { PasswordService } from "../../../core/services/admin/auth/password.service";

@Component({
    selector: "app-change-password",
    templateUrl: "./change-password.component.html",
})
export class ChangePasswordComponent {
    readonly faKey = faKey;

    token: string;
    readonly form: FormGroup;
    readonly routes = MAESTRO_ROUTES;

    constructor(
        private passwordService: PasswordService,
        private router: Router,
        formBuilder: FormBuilder,
        route: ActivatedRoute,
        private _swalService: SwalModalService,
        private _translateService: TranslateService
    ) {
        this.token = route.snapshot.params.token;
        this.form = formBuilder.group({
            password: ["", [Validators.required, MaestroValidators.password]],
            confirmPassword: ["", [Validators.required, MaestroValidators.mustMatch("password")]],
        });
    }

    change() {
        this.passwordService.change(this.form.value.password, this.token).subscribe(() => {
            this._swalService.success(this._translateService.instant("general.updatedPassword"));
            this.router.navigate(["login"]);
        });
    }

    get formControls() {
        return this.form.controls;
    }
}
