import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ActionButtonsComponent } from "./action-buttons.component";

@NgModule({
    imports: [SharedModule],
    declarations: [ActionButtonsComponent],
    exports: [ActionButtonsComponent],
})
export class ActionButtonsModule {}
