import { NgModule } from "@angular/core";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { TreeModule } from "primeng-lts/tree";
import { ElementTypeUpdateComponent } from "./element-type-update.component";
import { ElementTypeUpdateGuard } from "./element-type-update.guard";
import { ElementTypeUpdateResolver } from "./element-type-update.resolver";
import { ElementTypeUpdateRoutingModule } from "./element-type-update.routing";
import { ElementTypeUpdateTabComponent } from "./tab/element-type-update-tab.component";

@NgModule({
    imports: [SharedModule, SharedComponentsModule, ElementTypeUpdateRoutingModule, SweetAlert2Module, TreeModule],
    declarations: [ElementTypeUpdateComponent, ElementTypeUpdateTabComponent],
    providers: [ElementTypeUpdateResolver, ElementTypeUpdateGuard],
})
export class ElementTypeUpdateModule {}
