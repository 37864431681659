import { Component, OnInit } from "@angular/core";

@Component({
    template: "<router-outlet></router-outlet>",
})
export class AdminWorkflowComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
