import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ElementService } from "app/core/services/pim/element.service";
import { Tab } from "app/shared/models";
import { Observable } from "rxjs";

@Injectable()
export class ElementViewTabResolver implements Resolve<Tab> {
    constructor(private _service: ElementService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Tab> {
        return this._service.getTabById(route.params.tabId);
    }
}
