import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { DashboardService } from "app/core/services/dashboard/dashboard.service";
import { Observable } from "rxjs";
import { MaestroDashboard } from "../../../../shared/models/dashboard";
import { query } from "../queries/dashboard-view.query";
import { DashboardViewModule } from "./dashboard-view.module";

@Injectable({ providedIn: DashboardViewModule })
export class DashboardViewResolver implements Resolve<MaestroDashboard> {
    constructor(private service: DashboardService) {}

    resolve(): Observable<MaestroDashboard> {
        return this.service.getDashboardData(query);
    }
}
