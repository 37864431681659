import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments";

@Injectable()
export class NotificationService {
    constructor(private _http: HttpClient) {}

    listNotifications(userId: number, index: number,filter:string){
        return this._http.get<any>(`${environment.adminApiUrl}/user/${userId}/notifications.json?param=${index}&filter=${filter}`);
    }

    seeNotifications(ids: any[]){
        return this._http.put<any>(`${environment.adminApiUrl}/notifications.json`, ids);
    }

    nbNewNotification(userId: number){
        return this._http.get<any>(`${environment.adminApiUrl}/user/${userId}/notifications/count.json`);
    }

    readNotification(id: number){
        return this._http.get<any>(`${environment.adminApiUrl}/notification/${id}.json`);
    }

    UpdateReadAllAction(){
        return  this._http.get<any>(`${environment.adminApiUrl}/notifications/read.json`);
    }


}
