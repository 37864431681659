import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { PanelModule } from "primeng-lts/panel";
import { ProgressBarModule } from "primeng-lts/progressbar";
import { PanelProgressBarComponent } from "./panel-progress-bar.component";

@NgModule({
    declarations: [PanelProgressBarComponent],
    imports: [SharedModule, PanelModule, ProgressBarModule],
    exports: [PanelProgressBarComponent],
})
export class PanelProgressBarModule {}
