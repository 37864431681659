import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { GroupService } from "app/core/services/admin/group/group.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { ProjectService } from "app/core/services/project/project/project.service";
// import { ProjectService } from "app/core/services/project/project/project.service";
import { ProjectDashboardResources } from "app/shared/models/project/dashboard";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProjectDashboardService } from "../../../../../core/services/project/project/dashboard.service";
import { ProjectDashboardModule } from "./dashboard.module";

@Injectable({ providedIn: ProjectDashboardModule })
export class ProjectDashboardResolver implements Resolve<ProjectDashboardResources> {
    constructor(
        private service: ProjectDashboardService,
        private groupService: GroupService,
        /*private projectService: ProjectService*/ private _projectService: ProjectService,
        private _userService: UserService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ProjectDashboardResources> {
        const projectId = route.parent.params.id;

        const project = route.parent.data.project.data;

        return forkJoin([
            this.service.getDashboardByProjectId(projectId),
            this.service.getPageStep(projectId),
            // this.projectService.getAuths(projectId)
            this._projectService.getGroups(projectId),
            this._userService.getGroupsAndAssociated(true),
        ]).pipe(
            map(
                (data) =>
                    <ProjectDashboardResources>{
                        exports: (data[0] as any).map((exp) => ({ ...exp, project: (data[1] as any).data })),
                        project: project,
                        pageStep: data[1].data,
                        // auths: (data[2] as any).data
                        projectGroups: (data[2] as any).data,
                        userGroups: (data[3] as any).data
                    }
            )
        );
    }
}
