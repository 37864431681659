<div class="card-header" [ngStyle]="{ 'background-color': bgColorDarkened, color: textColor, 'border-color': borderColor }">
    <div class="row">
        <div class="col-7 export-title font-weight-bold" [title]="export.name">
            {{ export.name }}
        </div>
        <div class="col-5">
            <div class="btn-group float-right">
                <span>{{ 'projects.type' | translate }} {{ export.type }}</span>
            </div>
        </div>
    </div>
</div>
<div class="card-body" [ngStyle]="{ 'background-color': bgColor, color: textColor, 'border-color': borderColor }">
    <p class="description" [innerHTML]="export.description"></p>

    <div class="brand-card-body">
        <div *ngIf="isWorkable" [ngStyle]="{ 'border-color': textColor }">
            <div class="text-value">{{ export.elementLength }}</div>
            <div class="text-uppercase text-muted small">{{ 'general.products' | translate }}</div>
        </div>
        <div *ngIf="isWorkable">
            <div class="text-value">{{ export.pageLength }} / {{ export.maxLength }}</div>
            <div class="text-uppercase text-muted small">{{ 'projects.pages' | translate }}</div>
        </div>
    </div>
</div>
<div class="card-footer" [ngStyle]="{ 'background-color': bgColorDarkened, 'border-color': borderColor }">
    <div class="row">
        <div class="btn-group col-12">
            <button *ngIf="isWorkable" [ngClass]="{ btn: true, 'btn-outline-dark': textColor === 'black', 'btn-outline-light': textColor === 'white' }" [routerLink]="editRoute">
                <fa-icon [icon]="faEdit"></fa-icon> {{ 'projects.work' | translate }}
            </button>
            <button *ngIf="!isWorkable" [ngClass]="{ btn: true, 'btn-outline-dark': textColor === 'black', 'btn-outline-light': textColor === 'white' }" (click)="downloadFile()">
                <fa-icon [icon]="faDownload"></fa-icon> {{ 'general.download' | translate }}
            </button>
        </div>
    </div>
</div>


