<div class="row">
    <h3 class="mx-3">{{ (isUpdate === false ? 'admin.message.title' : 'admin.message.update') | translate }}</h3>
</div>
<div class="tableComponent row">
  <div class="col-lg-12">
    <div class="card">
      <form  *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card-body">
          <div class="form-group row">
            <label class="col-2" for="message">{{ "admin.message.labelMessage" | translate }}</label>
            <div class="col-10">
              <input class="form-control" type="text" formControlName="message">
              <span class="fs-error" *ngIf="form.controls['message'].hasError('required')">{{ "admin.message.requiredMessage" | translate}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2" for="startDate">{{ "admin.message.labelStartDate" | translate }}</label>
            <div class="col-10">
              <app-date-picker [minDate]="{ year: 1930, month: 1, day: 1 }" [active]="true" formControlName="startDate">
              </app-date-picker>
              <span class="fs-error" *ngIf="form.controls['startDate'].hasError('required')">{{ "admin.message.requiredStartDate" | translate}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2" for="endDate">{{ "admin.message.labelEndDate" | translate }}</label>
            <div class="col-10">
              <app-date-picker [minDate]="{ year: 1930, month: 1, day: 1 }" [active]="true" formControlName="endDate">
              </app-date-picker>
              <span class="fs-error" *ngIf="form.controls['endDate'].hasError('required')">{{ "admin.message.requiredEndDate" | translate}}</span>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <button (click)="onBack()" class="btn btn-danger">{{ 'general.previous' | translate }}</button>
            <div class="btn-group ml-auto">
              <button type="submit" [disabled]="form.invalid" class="btn btn-primary">{{ "general.save" | translate }}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>