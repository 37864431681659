import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "parseVarTwig",
})
export class ParseVarTwigPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const regex = /__([a-zA-Z0-9_]+)__/gm;
        let m;
        let arrayData;
        let str = "";
        m = regex.exec(value);
        if (m[1] !== null) {
            arrayData = m[1].split("_");
            if (arrayData.length > 0) {
                arrayData.forEach((data, index) => {
                    if (0 === index) {
                        str += data.charAt(0).toUpperCase() + data.substring(1).toLowerCase() + " ";
                    } else {
                        str += data + " ";
                    }
                });
            } else {
                str = m[1];
            }
        }

        return str;
    }
}
