import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class ListService {
    filterSearch: BehaviorSubject<string> = new BehaviorSubject("");
    
    constructor(private _http: HttpClient) {}

    /**
     * Get lists
     * @returns
     */
    getLists(): Observable<any> {
        return this._http.get(`${environment.pimUrl}/list.json`);
    }

    /**
     * Get list by id
     * @param id
     * @returns
     */
    getList(id: number): Observable<any> {
        return this._http.get(`${environment.pimUrl}/list/${id}.json`);
    }

    /**
     * Create new List
     * @param listName
     * @returns
     */
    createList(listName: string): Observable<{ id: number }> {
        const formData = new FormData();
        formData.append("name", listName);
        return this._http.post<{ id: number }>(`${environment.pimUrl}/list/create.json`, formData);
    }

    /**
     * Delete a list
     * @param elementId
     * @returns
     */
    deleteList(elementId: number): Observable<{ id: number }> {
        return this._http.delete<{ id: number }>(`${environment.pimUrl}/list/${elementId}.json`);
    }

    /**
     * Update list values
     * @param id
     * @param body
     * @returns
     */
    updateFormField(id: number, body: any): Observable<any> {
        return this._http.put(`${environment.pimUrl}/list/${id}/form_field_update.json`, body);
    }

    /**
     * Change list name
     * @param id
     * @param name
     * @returns
     */
    changeName(id: number, name: string): Observable<any> {
        return this._http.post(`${environment.pimUrl}/list/${id}/name.json`, { name: name });
    }

    /**
     * 
     * @param listId 
     * @param start 
     * @returns 
     */
    getStory(listId: number, start: number): Observable<any> {
        return this._http.post(`${environment.pimUrl}/list/${listId}/story.json`, {"start": start});
    }
}
