import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { ListListComponent } from "./list-list.component";
import { ListListGuard } from "./list-list.guard";
import { ListListResolver } from "./list-list.resolver";

@NgModule({
    imports: [SharedModule, SharedComponentsModule],
    declarations: [ListListComponent],
    exports: [],
    providers: [ListListGuard, ListListResolver],
})
export class ListListModule {}
