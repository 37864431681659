import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faAlignJustify, faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { DataModelService } from "app/core/services/dam/datamodel.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { MaestroElementTypes } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { Subscription } from "rxjs";

@Component({
    selector: "app-list",
    templateUrl: "./list.component.html",
})
export class ListComponent implements TableView, OnInit, OnDestroy {
    table: [string, string][];
    columns: string[];

    faTimes: IconDefinition = faTimes;
    faAlignJustify: IconDefinition = faAlignJustify;

    private actionButtonSource: Subscription;
    private _searchSubscription: Subscription;
    readonly actions: ActionButtonsDef = [];

    private _dataModels: MaestroElementTypes;

    constructor(
        private _dataModelService: DataModelService,
        private _elementTableDataPipe: TableDataPipe,
        private _translate: TranslateService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _modalService: SwalModalService,
        private _layout: LayoutService,
        private _authService: AuthService
    ) {}

    ngOnInit(): void {
        this._dataModels = this._route.snapshot.data.resources;
        this._initList(this._dataModels);

        const acl: ACL = this._authService.getUserAclFromToken();

        if (acl.MAESTRO_DAM_DATAMODEL_CREATE) {
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "dam.mediaType.create";
            this.actionButtonSource = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
        }

        if (acl.MAESTRO_DAM_DATAMODEL_UPDATE) {
            this.actions.push({ type: ActionType.Update, right: "MAESTRO_DAM_DATAMODEL_UPDATE" });
        }

        if (acl.MAESTRO_DAM_DATAMODEL_DELETE) {
            this.actions.push({ type: ActionType.Delete, right: "MAESTRO_DAM_DATAMODEL_DELETE" });
        }

        this._layout.breadcrumb.setPath({ routerLink: null, name: "pim.elementTypes.title" }, 1);
        this._layout.searchInput.enable = true;
        this._searchSubscription = this._layout.searchInput.filter$.subscribe((value) => this.onFilter(value));
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
        if (this.actionButtonSource) {
            this.actionButtonSource.unsubscribe();
        }
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._searchSubscription.unsubscribe();
        this._layout.searchInput.enable = false;
    }

    /**
     * When filtering this function is called to filter the list
     *
     * @param value
     * @returns
     */
    onFilter(value: string): void {
        if (value.length < 3) {
            this._initList(this._dataModels);
            return;
        }
        this._initList(
            this._dataModels.filter((elementType) => {
                return elementType.name.toLowerCase().includes(value.toLowerCase());
            })
        );
    }

    /**
     * Handle action click
     *
     * @param event
     */
    onActionClick(event: ActionEvent) {
        const id = event.rowId;
        switch (event.action) {
            case ActionType.Delete:
                this._delete(id);
                break;
            case ActionType.Update:
                this._router.navigate(["update", id], { relativeTo: this._route });
                break;
        }
    }

    onCreate() {
        this._openCreateModal();
    }

    /**
     * Delete a data model
     *
     * @param id
     */
    private _delete(id: number) {
        if (id) {
            this._dataModelService.deleteDataModel(id).subscribe((data) => {
                this._dataModelService.getDataModels().subscribe((elements: any) => this._initList(elements.data));
                this._modalService.success(this._translate.instant("general.deleted"));
            });
        }
    }

    /**
     * Create a new data model
     *
     * @param name
     * @param open
     */
    private _create(name: string) {
        if (name) {
            this._dataModelService.createDataModel(name).subscribe((data: any) => {
                this._modalService.success(this._translate.instant("general.created"));
                this._dataModelService.getDataModels().subscribe((elements: any) => this._initList(elements.data));
            });
        }
    }

    /**
     * Init the list using tableDataPipe
     *
     * @param dataModel
     */
    private _initList(dataModel: MaestroElementTypes) {
        const formatedData = this._elementTableDataPipe.transform(dataModel);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    /**
     * Open the create modal
     */
    private _openCreateModal() {
        this._modalService
            .open({
                title: this._translate.instant("breadcrumb.pim.elementTypes.create"),
                preConfirm: () => {
                    return [$("#ModalElementTitle").val(), $("#ModalElementImport").val()];
                },

                showCloseButton: true,
                confirmButtonText: this._translate.instant("general.create"),
                confirmButtonColor: "#4dbd74",
                showCancelButton: true,
                cancelButtonText: this._translate.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                html: '<div class="form-group">' + '<input class="form-control" type="text" placeholder="" id="ModalElementTitle">' + "</div>",
            })
            .then((result) => {
                if (result.value) {
                    this._create($("#ModalElementTitle").val() as string);
                }
            });
    }
}
