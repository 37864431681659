import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { WorkflowComponent } from "./workflow.component";

@NgModule({
    declarations: [WorkflowComponent],
    imports: [SharedModule],
    exports: [WorkflowComponent],
})
export class WorkflowModule {}
