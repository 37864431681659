<app-workflow *ngIf="workflow" [display]="workflow | workflow" [canSetValue]="setValue" (transitionKey)="actionWorkflow($event)"></app-workflow>

<app-panel-progress-bar *ngIf="element && element.tabs.length" [element]="element" key="salabilityIndicator"></app-panel-progress-bar>
<app-panel-progress-bar *ngIf="element && element.tabs.length" [element]="element" key="mandatory"></app-panel-progress-bar>

<div class="row mt-3">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <app-form-header *ngIf="versions.elementVersions" [data]="element | elementToFormHeader: 'edit'" [versions]="versions.elementVersions" [updateName]="updateName" [versionName]="versionName" [referent]="this.element.referent" [disabled]="versions.validVersions.length === 0" [disabledHeader]="!setValue" (version)="onVersionChange($event)" (newVersion)="onNewVersion()" (nameChange)="onSaveName($event)"></app-form-header>
                <ul class="list-none p-0 m-0">
                    <li class="row flex align-items-center py-2 px-2  flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">
                            <span *ngIf="!setValue" class="ml-3"><span *ngIf="!tagAsSalabilityIndicator">{{ 'tags.title' | translate }}</span><span *ngIf="tagAsSalabilityIndicator">{{ 'general.publishOnSites' | translate }}</span></span>
                            <div *ngIf="setValue" (click)="showTagList = !showTagList" class="ml-3">
                                <span class="pointer"><span *ngIf="!tagAsSalabilityIndicator">{{ 'tags.title' | translate }}</span><span *ngIf="tagAsSalabilityIndicator">{{ 'general.publishOnSites' | translate }}</span></span>
                                <span class="pi pi-fw pointer ml-1" [ngClass]="showTagList ? 'pi-chevron-circle-up' : 'pi-chevron-circle-down'"></span>
                            </div>
                        </div>
                        <div *ngIf="!showTagList" class="col row justify-content-start">
                            <div *ngIf="!selectedTags.length" [ngClass]="tagAsSalabilityIndicator ? 'alert alert-danger' : 'alert alert-warning'" class="fade show col" role="alert">
                                <i class="pi pi-exclamation-triangle"></i>
                                <span>&nbsp;&nbsp;{{ 'general.noPublish' | translate }}</span>
                                <span *ngIf="tagAsSalabilityIndicator"> : {{ 'general.noPublishOnSites' | translate }}&nbsp;</span>
                                <button *ngIf="!tagAsSalabilityIndicator" type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div *ngFor="let tag of selectedTags" class="text-center justify-content-center">
                                <span class="badge badge-pill mr-4">{{ tag.name }}</span>
                            </div>
                        </div>
                        <div *ngIf="setValue && showTagList" class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                            <p-tree [value]="tagNodes" selectionMode="checkbox" [filter]="true" filterMode="strict" [(selection)]="selectedTags" [propagateSelectionUp]="false" [propagateSelectionDown]="false" (onNodeSelect)="addTag($event)" (onNodeUnselect)="removeTag($event)" (onNodeExpand)="onExpand($event)"></p-tree>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="mt-3" *ngIf="!noTab; else noTabAvailable">
    <router-outlet></router-outlet>
</div>
<ng-template #noTabAvailable>
    <app-element-no-field-data [link]="routerLink" buttonTitle="button.editType">
        <p>
            {{ noTabMessage }} <b> {{ noTabName }}</b>
        </p>
    </app-element-no-field-data>
</ng-template>


<swal #versionSwal title="{{ 'version.new' | translate }}" [showConfirmButton]="false" [showCloseButton]="true">
    <form *swalPortal [formGroup]="newVersion">
        <div class="row">
            <div class="col-12"><p class="font-weight-bold text-center">{{ 'version.select.origin' | translate }}</p></div>
        </div>
        <div class="form-group row">
            <label class="col-4 col-form-label">{{ 'version.origin' | translate }}</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="origin">
                    <option></option>
                    <option *ngFor="let version of versions.validVersions; let i = index" [value]="version.elementId">{{ version.title }} -v.{{ version.version_number }}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <hr style="margin-top: 10px;" />
            </div>
            <div class="col-auto font-weight-bold">{{ 'version.then' | translate }}</div>
            <div class="col">
                <hr style="margin-top: 10px;" />
            </div>
        </div>
        <div class="row">
            <div class="col-12"><p class="font-weight-bold text-center">{{ 'version.existing' | translate }}</p></div>
        </div>
        <div class="row form-group">
            <label class="col-4 col-form-label">{{ 'version.label' | translate }}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <select class="form-control ng-pristine ng-valid ng-touched" formControlName="existing">
                        <option></option>
                        <option *ngFor="let version of versions.allVersions; let i = index" [value]="version.id">{{ version.title }}</option>
                    </select>
                    <div class="input-group-append">
                        <button class="btn btn-primary" (click)="createVersion('old')" [disabled]="newVersion.value.origin === '' || newVersion.value.existing === ''">
                            <fa-icon [icon]="faSave"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <hr style="margin-top: 10px;" />
            </div>
            <div class="col-auto font-weight-bold">{{ 'version.or' | translate }}</div>
            <div class="col">
                <hr style="margin-top: 10px;" />
            </div>
        </div>
        <div class="row">
            <div class="col-12"><p class="font-weight-bold text-center" translate>{{ 'version.create' | translate }}</p></div>
        </div>
        <div class="row">
            <label class="col-4 col-form-label">{{ 'version.label' | translate }}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="text-widget textarea-widget form-control ng-pristine ng-valid ng-touched" type="text" formControlName="new"/>
                    <div class="input-group-append">
                        <button class="btn btn-primary" (click)="createVersion('new')" [disabled]="newVersion.value.origin === '' || newVersion.value.new === ''">
                            <fa-icon [icon]="faSave"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <br><hr><br>
        <div class="row">
            <label class="col-4 col-form-label">{{ 'tags.title' | translate }}</label>
            <div class="col-sm-8">
                <p-tree scrollHeight="200px" [value]="tagNodes" selectionMode="checkbox" [filter]="true" filterMode="strict" [(selection)]="newVersion.value.tags" [propagateSelectionUp]="false" [propagateSelectionDown]="false" (onNodeExpand)="onExpand($event)"></p-tree>
            </div>
        </div>
    </form>
</swal>
