<div *ngIf="isWorkable">
    <div class="form-group">
        <ng-container *ngIf="exports && exports.length; else noExports">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li *ngFor="let tab of form.get('tabs')['controls']; let i = index" [ngbNavItem]="i" [disabled]="tab.value.type !== 'CDF' && tab.value.type !== 'HTML' && tab.value.type !== 'Marketing' && tab.value.type !== 'BOOKLET' ">
                    <a ngbNavLink>
                        <app-form-label [invalid]="tab.invalid" [valid]="tab.valid">
                            {{ tab.get('title').value }}
                        </app-form-label>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="row" [formGroup]="tab">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">{{ 'projects.conf.workflow' | translate }}</label>
                                    <div class="col-sm-8">
                                        <p-multiSelect formControlName="workflow" [options]="workflows" optionLabel="name" [selectionLimit]="1" (onChange)="changeWorkflow(tab, $event.value)" [style]="{'width':'100%'}" defaultLabel="{{ 'general.select' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                                    </div>
                                    <a *ngIf="tab.get('workflow').value && tab.get('workflow').value.length > 0 && canUpdateWorkflow" class="col-sm-1" (click)="openWorkflowModal(tab.get('workflow').value[0].id, i)" routerLinkActive="active" >{{ 'general.edit' | translate}}</a>
                                    <!-- <a *ngIf="tab.get('workflow').value " class="col-sm-1" [routerLink]="['/' + routes.admin.base, routes.admin.workflow.base, routes.admin.workflow.project, tab.get('workflow').value[0].id, routes.actions.update]" routerLinkActive="active" >modifier</a> -->
                                </div>
                            </div>
                        </div>
                        <app-configuration-workflow-step-tab-form *ngIf="tab.get('workflow').value?.length && tab.get('steps')" [isFlatplan]="tab.get('page').value ? true : false" [tabFormArray]="tab.get('steps')" [showErrors]="submitted" [canUpdate]="canUpdate"></app-configuration-workflow-step-tab-form>
                    </ng-template>
                </li>
            </ul>
    
            <div [ngbNavOutlet]="nav"></div>
        </ng-container>
    
    
        <ng-template #noExports>
            <p>{{ 'projects.conf.nothing' | translate }}</p>
        </ng-template>
    </div>
    
    <div class="row">
        <div class="col-6 text-left">
            <div class="btn-group">
                <button class="btn btn-danger" (click)="onBack()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-2">{{ 'general.cancel' | translate }}</span>
                </button>
            </div>
        </div>
        <div class="col-6 text-right">
            <div class="btn-group">
                <button class="btn btn-primary" [disabled]="form.invalid || form.value.description === '<p></p>'" (click)="onSubmit()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isWorkable">
    <label> {{ 'projects.export.emptyExport' | translate }} </label>
</div>

<swal #historyModal [showConfirmButton]="false" title="{{ 'history.title' | translate }}">
    <div *swalPortal>
        <app-workflow-form [moduleModal]="'projects'" [id]="workflowId" (onSaveWorkflow)="reloadWorkflow($event)"></app-workflow-form>
    </div>
</swal>

