<a *ngIf="enabled" id="breadcrumbMenu" data-toggle="dropdown" class="nav-item nav-link dropdown-toggle mr-md-2">
    <fa-icon [icon]="faCog" [fixedWidth]="false"></fa-icon>
</a>
<div class="dropdown-menu">
    <div class="dropdown-items">
        <a *ngFor="let item of items" class="dropdown-item dropdown-link" routerLinkActive="active" [routerLink]="item.routerLink" [id]="'link-' + item.text">
            <fa-icon [icon]="item.icon" [fixedWidth]="true"></fa-icon>
            <span class="d-inline-block ml-1"  [id]="'span-' + item.text">
                {{ 'breadcrumb.' + item.text | translate }}
            </span>
        </a>
    </div>
</div>
