<div class="btn-group component-view-button">
    <ng-container *ngFor="let view of views" [ngSwitch]="view.type">
        <button *ngSwitchCase="viewType.List" type="button" class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="{{ 'tooltip.list' | translate }}" [ngClass]="{ active: selected === view, 'btn-outline-primary': buttonOutlined, 'btn-primary': !buttonOutlined }" (debounceClick)="onButtonClick(view)">
            <fa-icon [icon]="faAlignJustify" [fixedWidth]="true"></fa-icon>
            <span *ngIf="view.text" class="d-inline-block">&nbsp;{{ view.text | translate }}</span>
        </button>
        <button *ngSwitchCase="viewType.Products" type="button" class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="{{ 'tooltip.list' | translate }}" [ngClass]="{ active: selected === view, 'btn-outline-primary': buttonOutlined, 'btn-primary': !buttonOutlined }" (debounceClick)="onButtonClick(view)">
            <fa-icon [icon]="faAlignJustify" [fixedWidth]="true"></fa-icon>
            <span *ngIf="view.text" class="d-inline-block">&nbsp;{{ view.text | translate }}</span>
        </button>
        <button *ngSwitchCase="viewType.Preview" type="button" class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="{{ 'tooltip.preview' | translate }}" [ngClass]="{ active: selected === view, 'btn-outline-primary': buttonOutlined, 'btn-primary': !buttonOutlined }" (debounceClick)="onButtonClick(view)">
            <fa-icon [icon]="faColumns" [fixedWidth]="true"></fa-icon>
            <span *ngIf="view.text" class="d-inline-block">&nbsp;{{ view.text | translate }}</span>
        </button>
        <button *ngSwitchCase="viewType.Thumbnails" type="button" class="btn btn-sm d-none d-md-block" data-toggle="tooltip" data-placement="top" title="{{ 'tooltip.flatplan' | translate }}" [ngClass]="{ active: selected === view, 'btn-outline-primary': buttonOutlined, 'btn-primary': !buttonOutlined }" (debounceClick)="onButtonClick(view)">
            <fa-icon [icon]="faTh" [fixedWidth]="true"></fa-icon>
            <span *ngIf="view.text" class="d-inline-block">&nbsp;{{ view.text | translate }}</span>
        </button>


        <button *ngSwitchCase="viewType.DamList" type="button" class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="{{ 'tooltip.list' | translate }}" [ngClass]="{ active: selected === view, 'btn-outline-primary': buttonOutlined, 'btn-primary': !buttonOutlined }" (debounceClick)="onButtonClick(view)">
            <fa-icon [icon]="faAlignJustify" [fixedWidth]="true"></fa-icon>
            <span *ngIf="view.text" class="d-inline-block">&nbsp;{{ view.text | translate }}</span>
        </button>
        <button *ngSwitchCase="viewType.DamGrid" type="button" class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="{{ 'tooltip.list' | translate }}" [ngClass]="{ active: selected === view, 'btn-outline-primary': buttonOutlined, 'btn-primary': !buttonOutlined }" (debounceClick)="onButtonClick(view)">
            <fa-icon [icon]="faTh" [fixedWidth]="true"></fa-icon>
            <span *ngIf="view.text" class="d-inline-block">&nbsp;{{ view.text | translate }}</span>
        </button>
        <button *ngSwitchCase="viewType.DamTile" type="button" class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="{{ 'tooltip.list' | translate }}" [ngClass]="{ active: selected === view, 'btn-outline-primary': buttonOutlined, 'btn-primary': !buttonOutlined }" (debounceClick)="onButtonClick(view)">
            <fa-icon [icon]="faTile" [fixedWidth]="true"></fa-icon>
            <span *ngIf="view.text" class="d-inline-block">&nbsp;{{ view.text | translate }}</span>
        </button>
    </ng-container>
</div>
