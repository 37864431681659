import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { MaestroCommons } from "app/shared/models/maestro-entity/common";
import { MaestroUser, MaestroUsers } from "app/shared/models/maestro-entity/user";
import {  Observable ,BehaviorSubject} from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class UserService {
    filterGroups: BehaviorSubject<{ id: number; name: string }[]> = new BehaviorSubject([]);
    constructor(private _http: HttpClient, private _authService: AuthService) {}

    /**
     * Get the list of users
     * @returns
     */
    getAll(): Observable<MaestroUsers> {
        return this._http.get<MaestroUsers>(`${environment.adminApiUrl}/user.json`);
    }

    /**
     * Get All users by group and group assoc
     * @param group_id
     * @returns
     */
    getUsersByGroupsAndAssoc(group_id): Observable<any> {
        return this._http.get<MaestroUsers>(`${environment.adminApiUrl}/group/${group_id}/assoc/users.json`);
    }

    /**
     * Get a specific user
     * @param id
     * @returns
     */
    getById(id: number): Observable<MaestroUser> {
        return this._http.get<MaestroUser>(`${environment.adminApiUrl}/user/${id}.json`);
    }

    /**
     * Delete a user using his id
     * @param id
     * @returns
     */
    deleteById(id: number): Observable<any> {
        return this._http.delete(`${environment.adminApiUrl}/user/${id}.json`);
    }

    /**
     * Create a new user
     *
     * @param formValue
     * @param profile
     * @returns
     */
    createUser(formValue: any, profile: string, groups: any ): Observable<any> {
        const formData = new FormData();

        formData.append("lastName", formValue.lastName.trim());
        formData.append("firstName", formValue.firstName.trim());
        formData.append("email", formValue.email.trim());
        formData.append("profile", profile);
        formData.append("groups", groups);
        formData.append("sendEmail", formValue.sendEmail);
        formData.append("sendMail", formValue.isWorkflowMail.length > 0 ? JSON.stringify(formValue.isWorkflowMail) : "[]");

        return this._http.post<any>(`${environment.adminApiUrl}/user/create.json`, formData);
    }

    /**
     * Update an user
     *
     * @param idUser
     * @param lastName
     * @param firstName
     * @param email
     * @param profile
     * @returns
     */
    updateUser(idUser: string, lastName: string, firstName: string, email: string, profile: string, groups: any, isWorkflowMail?: any): Observable<any> {
        const formData = new FormData();
        formData.append("lastName", lastName.trim());
        formData.append("firstName", firstName.trim());
        formData.append("email", email.trim());
        formData.append("profile", profile);
        formData.append("groups", groups);
        formData.append("sendMail", isWorkflowMail.length > 0 ? JSON.stringify(isWorkflowMail): "[]");

        // Not the best practice at all...
        formData.append("_method", "PUT");
        return this._http.post<any>(`${environment.adminApiUrl}/user/${idUser}.json`, formData);
    }

    /**
     * Update an user's password
     *
     * @param idUser
     * @param oldPassword
     * @param newPassword
     * @returns
     */
    updateUserPassword(idUser: string, oldPassword: string, newPassword: string): Observable<any> {
        const formData = new FormData();
        formData.append("oldpassword", oldPassword);
        formData.append("newpassword", newPassword);

        return this._http.post<any>(`${environment.adminApiUrl}/user/${idUser}/password.json`, formData);
    }

    /**
     * Get the list of roles/profiles
     * @returns
     */
    public getProfiles() {
        return this._http.get<any>(`${environment.adminApiUrl}/profiles.json`);
    }

    /**
     * Check if an email is already used
     * @param email
     * @param id
     * @returns
     */
    public check(email: string, id: any) {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("email", email.trim());
        return this._http.post<any>(`${environment.adminApiUrl}/user/check/email.json`, formData);
    }

    /**
     * Get user's groups and associated
     * @returns
     */
    getGroupsAndAssociated(loadAllGroups: boolean = false): Observable<MaestroCommons> {
        const id = this.getUserIdFromToken();

        let params = new HttpParams().set("loadAllGroups", loadAllGroups.toString());

        return this._http.get<MaestroCommons>(`${environment.adminApiUrl}/user/${id}/group.json`, { params: params });
    }

    getUserIdFromToken() {
        return this._authService.getUserIdFromToken();
    }

    getUserProfileIdFromToken() {
        return this._authService.getUserProfileIdFromToken();
    }

    getUserAclFromToken() {
        return this._authService.getUserAclFromToken();
    }

    seeNotifications(ids: any[]){
        return this._http.put<any>(`${environment.adminApiUrl}/notifications.json`, ids);
    }

    readNotifications(id: number){
        return this._http.get<any>(`${environment.adminApiUrl}/notification/${id}.json`);
    }

    getDatatableElements(filter: any): Observable<any> {
        return this._http.post(`${environment.adminApiUrl}/datatable/user.json`, filter);
    }
}
