import { NgModule } from "@angular/core";


import { ListCascadeListModule } from "./list/listcascade-list.module";
import { ListCascadeRoutingModule } from "./listcascade.routing";
import { ListCascadeUpdateModule } from "./update/listcascade-update.module";

@NgModule({
    imports: [ListCascadeRoutingModule, ListCascadeListModule, ListCascadeUpdateModule],
})
export class ListCascadeModule {}
