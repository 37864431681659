export const query = {
    query: `{
        getBlocs
        {
            id,
            name,
            blocType
            {
                name,
                type,
                level
            }
            dataTypes
            {
                name,
                type,
                dataOptions
                    {
                        name,
                        module
                    }
            }  
        }
  }`,
};
