<div class="tableComponent row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h3 class="text-primary text-center">
          <span class="text-right ml-3">{{ "admin.message.titleAdmin" | translate }}</span>
        </h3>
        <hr />
        <div class="text-center">
        </div>
        <div *ngIf="!messageInformation || !messageInformation.length" class="alert alert-warning">
          {{ "admin.message.alertMessage" | translate }}
        </div>
        <div *ngIf="messageInformation && messageInformation.length"  class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <ng-container>
                  <th scope="col">
                    <span>{{ 'admin.message.labelMessage' | translate }}</span>
                  </th>
                </ng-container>
                <th>{{ 'history.action' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let message of messageInformation">
                <td >
                  <span>{{ message.message }}</span>
                </td>
                <td>
                  <div class="btn-group component-action-button">
                    <button (click)="onUpdate(message.id)" id="update" [ngbTooltip]="'pim.actions.edit'| translate"  class="btn btn-sm btn-outline-primary">
                    <fa-icon [icon]="faTools" [fixedWidth]="true"></fa-icon>
                  </button>
                  <button (click)="deleteMessage(message.id)" container="body" [ngbTooltip]="'tooltip.delete'|translate" id="delete" class="btn btn-sm btn-outline-danger"  >
                      <fa-icon [icon]="faTrash" [fixedWidth]="true"></fa-icon>
                  </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>