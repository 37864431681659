<div class="tableOverlayPanelContainer">
    <div class="row ml-1">
        <button [disabled]="field.editable === false" class="btn btn-primary btn-block text-center col-10" data-toggle="modal" [attr.data-target]="'#tableModal' + currentUniqueId" (click)="openConfigureTableModal()">
            {{ 'general.table.configure' | translate }}
        </button>

        <i class="pi pi-eye col-1" type="button" (click)="openConfigureTableModal(); op.toggle($event)"></i>
        <!-- (mouseover)="openConfigureTableModal(); op.toggle($event)"
                (mouseout)="op.toggle($event)")-->
        <!-- Can lead to error console about expression changed after checked -->
    </div>

    <ng-template #overlayPanelDiv></ng-template>
    <!-- @TODO: Fix "ERROR Cannot append [object Object] to [object HTMLDivElement]" -->

    <p-overlayPanel  #op class="tableOverlayPanel" [appendTo]="overlayPanelDiv" [showCloseIcon]="true">
        <app-form-table [id]="'overlayPanelTable-' + currentUniqueId" [cells]="currentTable" [overlayPanelMode]="true"></app-form-table>
    </p-overlayPanel>

    <div *ngIf="field && currentUniqueId" class="modal fade" [attr.id]="'tableModal' + currentUniqueId" tabindex="-1" role="dialog" aria-labelledby="tableModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="tableModalLabel">
                        {{ 'general.table.configure' | translate }} {{ currentTitle }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- @TODO: Find a way to disable import buttons like in section-tab.component.html with controlSource method -->
                    <app-form-table [isPimVariable]="isPimVariable" [id]="currentUniqueId" [cells]="currentTable"
                        [importFromSource]="true" [sources]="tableSources"
                        (cellsEmitter)="saveTable(currentTable, $event)"
                        (importTableFromSource)="importTableFromSource($event)">
                    </app-form-table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'general.close' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
