import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { TextEditorComponent } from "./text-editor.component";

@NgModule({
    declarations: [TextEditorComponent],
    exports: [TextEditorComponent],
    imports: [SharedModule],
})
export class TextEditorModule {}
