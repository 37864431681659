import { Directive, ElementRef, OnInit, Input } from "@angular/core";
import { Sortable, MultiDrag } from "sortablejs";

Sortable.mount(new MultiDrag());

@Directive({
    selector: "[appSortable]",
})
export class SortableDirective implements OnInit {
    @Input() appSortable;
    sortable: Sortable;
    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.sortable = Sortable.create(this.el.nativeElement, this.appSortable);
    }

    public toArray = function () {
        return this.sortable.toArray();
    };

    public sort = function (positions) {
        this.sortable.sort(positions);
    };
}
