import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

@Injectable()
export class SwalModalService {
    isActivated = true;

    constructor(private translate: TranslateService) {}

    /**
     * Display a modal error
     *
     * @param text
     * @returns
     */
    error(text): Promise<SweetAlertResult> {
        if (!this.isActivated) {
            return;
        }
        return Swal.fire({
            title: this.translate.instant("modal.error"),
            html: text,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "primary",
        });
    }

    /**
     * Display a success modal
     * @param title
     * @param timer
     * @returns
     */
    success(title, timer = 1500, showConfirmButton: boolean = false): Promise<SweetAlertResult> {
        if (!this.isActivated) {
            return;
        }
        return Swal.fire({
            icon: "success",
            title: title,
            showConfirmButton: showConfirmButton,
            confirmButtonColor: "primary",
            timer: timer,
        });
    }

    /**
     * Open a modal usiong options
     *
     * @param sweetAlertOptions
     * @returns
     */
    open(sweetAlertOptions: SweetAlertOptions): Promise<SweetAlertResult> {
        if (!this.isActivated) {
            return;
        }
        return Swal.fire(sweetAlertOptions);
    }

    /**
     * Open a unsaved confirmation modal
     *
     * @returns
     */
    unsavedConfirm(): Promise<SweetAlertResult> {
        if (!this.isActivated) {
            return;
        }
        return Swal.fire({
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "primary",
            title: this.translate.instant("modal.unsaved.title"),
            text: this.translate.instant("modal.unsaved.text"),
            cancelButtonText: this.translate.instant("modal.no"),
            confirmButtonText: this.translate.instant("modal.yes"),
        });
    }

    /**
     * Open a confirm modal
     *
     * @param sweetAlertOptions
     * @returns
     */
    confirm(sweetAlertOptions: SweetAlertOptions) {
        if (!this.isActivated) {
            return;
        }
        return Swal.fire(
            Object.assign(
                {
                    title: this.translate.instant("modal.confirm"),
                    text: this.translate.instant("modal.sure"),
                    icon: "warning",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: "primary",
                    confirmButtonText: this.translate.instant("modal.yes"),
                    cancelButtonText: this.translate.instant("modal.no"),
                },
                sweetAlertOptions
            )
        );
    }
    confirmText(sweetAlertOptions: SweetAlertOptions) {
        if (!this.isActivated) {
            return;
        }
        return Swal.fire(
            Object.assign(
                {
                    title: this.translate.instant("modal.confirm"),
                    text: this.translate.instant("modal.sure"),
                    input: "text",
                    inputPlaceholder: "Note",
                    icon: "warning",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: "primary",
                    confirmButtonText: this.translate.instant("modal.yes"),
                    cancelButtonText: this.translate.instant("modal.no"),
                },
                sweetAlertOptions
            )
        );
    }

    /**
     * Open a confirm modal for a deletion
     * @param content
     * @returns
     */
    delete(content: boolean = false) {
        if (!this.isActivated) {
            return;
        }
        return this.confirm({
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            title: this.translate.instant("modal.delete"),
            html: content ? this.translate.instant("modal.elementType") : this.translate.instant("modal.revert"),
            confirmButtonText: this.translate.instant("modal.yes"),
            cancelButtonText: this.translate.instant("modal.no"),
            confirmButtonColor: "primary",
        });
    }

    deleteWithContent(content: string) {
        if (!this.isActivated) {
            return;
        }
        return this.confirm({
            showCancelButton: true,
            focusConfirm: false,
            title: this.translate.instant("modal.delete"),
            text: this.translate.instant(content),
            confirmButtonText: this.translate.instant("modal.yes"),
            cancelButtonText: this.translate.instant("modal.no"),
            confirmButtonColor: "primary",
        });
    }

    /**
     * Open a modal to ask confirmation before deleting a section (pim)
     * @returns
     */
    deleteSection() {
        if (!this.isActivated) {
            return;
        }

        return this.confirm({
            showCancelButton: true,
            title: this.translate.instant("modal.delete"),
            text: this.translate.instant("modal.deleteEt"),
            confirmButtonText: this.translate.instant("modal.yes"),
            cancelButtonText: this.translate.instant("modal.no"),
            confirmButtonColor: "primary",
        });
    }

    /**
     * Open a modal to ask confirmation before deleting a group page (project)
     * @returns
     */
    deleteGroupPage(name: string, pageLength: number) {
        if (!this.isActivated) {
            return;
        }
        return this.confirm({
            showCancelButton: true,
            title: this.translate.instant("modal.delete"),
            text: this.translate.instant("modal.groupPage") + name + ` (${pageLength} ${pageLength > 1 ? this.translate.instant("modal.pages") : this.translate.instant("modal.page")})`,
            confirmButtonText: this.translate.instant("modal.yes"),
            cancelButtonText: this.translate.instant("modal.no"),
            confirmButtonColor: "primary",
        });
    }

    /**
     * Show an info modal when a project's configuration is not completed
     * @param content
     * @returns
     */
    info(content) {
        if (!this.isActivated) {
            return;
        }
        return Swal.fire({
            icon: "info",
            title: this.translate.instant("modal.info"),
            html: content,
            confirmButtonText: this.translate.instant("modal.ok"),
            confirmButtonColor: "primary",
        } as SweetAlertOptions);
    }

    updatePage() {
        if (!this.isActivated) {
            return;
        }
        return this.confirm({
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            title: this.translate.instant("projects.flatplan.updatePage"),
            text: this.translate.instant("modal.updatePage"),
            confirmButtonText: this.translate.instant("modal.yes"),
            cancelButtonText: this.translate.instant("modal.no"),
            confirmButtonColor: "primary",
        });
    }

    historyModalWorkflow(html) {
        if (!this.isActivated) {
            return;
        }
        return Swal.fire({
            icon: "info",
            html: html,
        });
    }

    async import(importType: string, importInfos: any, importData: string) {
        let infos = "";

        importInfos.forEach((info) => {
            return (infos += `<p>${info}</p>`);
        });

        const note = "product" == importData ? `<hr><span><small>${this.translate.instant("import.columnInfos.fieldset")}</small></span>` : "";

        const { value: file }: any = await Swal.fire({
            html: `
                    <i class="pi pi-info-circle" type="button" data-toggle="collapse" data-target="#collapseInfos" aria-expanded="false" aria-controls="collapseInfos"></i>
                    <a target="_self" href="../../../../../assets/${importType}" download="${importType}">${this.translate.instant(`import.download.${importData}`)}</a>
                    <div class="collapse show" id="collapseInfos">
                        <div class="card card-body text-left">
                            ${infos}
                            <hr>
                            <span><strong class='text-danger'>*</strong> <small>${this.translate.instant("pim.elementTypes.fieldMandatory")}</small></span>
                            ${note}
                        </div>
                    </div>
                `,
            title: this.translate.instant(`import.${importData}`),
            input: "file",
            inputAttributes: {
                accept: ".csv, .xls, .xlsx",
                "aria-label": this.translate.instant("import.uploadFile"),
            },
            confirmButtonText: this.translate.instant("import.uploadData"),
        });

        let data = null;

        const acceptedType = ["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

        if (file) {
            if (acceptedType.includes(file.type)) {
                data = file;
            } else {
                this.error(this.translate.instant("import.notSupportedFile", { expected: "text/csv or application/vnd.ms-excel", received: file.type }));
            }
        }

        return data;
    }
}
