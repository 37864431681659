import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { FieldsetService } from "app/core/services/pim/fieldset.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { MaestroFieldsets } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";

@Component({
    selector: "app-fieldset-list",
    templateUrl: "./fieldset-list.component.html",
})
export class FieldsetListComponent implements TableView, OnInit, OnDestroy {
    table: [string, string][];
    columns: string[];

    history: any;
    showDiff: boolean;
    startHistory: number;
    elementStory: number;
    objectKeys = Object.keys;

    readonly actions: ActionButtonsDef = [];

    private _searchSubscription: Subscription;

    private _fieldsets: MaestroFieldsets;

    private actionButtonSub: Subscription;

    @ViewChild("historyModal", { static: true }) private swal: SwalComponent;

    acl: ACL;

    constructor(
        private _fieldsetService: FieldsetService,
        private _translateService: TranslateService,
        private _modalService: SwalModalService,
        private _elementTableDataPipe: TableDataPipe,
        private _router: Router,
        private _route: ActivatedRoute,
        private _layout: LayoutService,
        private _authService: AuthService
    ) {}

    ngOnInit(): void {
        this.acl = this._authService.getUserAclFromToken();

        if (this.acl.SYSPAD_PIM_FIELDSET_CREATE) {
            this.actions.push({ type: ActionType.Duplicate, right: "SYSPAD_PIM_FIELDSET_CREATE" });
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "pim.fieldsets.create";
            this.actionButtonSub = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
        }
        if (this.acl.SYSPAD_PIM_FIELDSET_UPDATE) {
            this.actions.push({ type: ActionType.Update, right: "SYSPAD_PIM_FIELDSET_UPDATE" });
        }
        /*if( this.acl.SYSPAD_PIM_ELEMENTTYPE_READ) {
            this.actions.push({ type: ActionType.Story, right: "SYSPAD_PIM_FIELDSET_READ"});
        }*/
        if (this.acl.SYSPAD_PIM_FIELDSET_DELETE) {
            this.actions.push({ type: ActionType.Delete, right: "SYSPAD_PIM_FIELDSET_DELETE" });
        }

        this._fieldsets = this._route.snapshot.data.fieldsets.data;
        this.onFilter(this._fieldsetService.filterSearch.value);
        this._layout.breadcrumb.setPath({ routerLink: null, name: "pim.fieldsets.title" }, 1);
        this._layout.searchInput.enable = true;
        this._layout.searchInput.currentValue = this._fieldsetService.filterSearch.value;
        this._searchSubscription = this._layout.searchInput.filter$.subscribe((value) => {
            this.onFilter(value);
            this._layout.searchInput.currentValue = this._fieldsetService.filterSearch.value;
        });
    }

    /**
     * Filter the list with the user's input
     * @param value
     * @returns
     */
    onFilter(value: string): void {
        if (value.length < 3) {
            this._initList(this._fieldsets);
            return;
        }

        this._fieldsetService.filterSearch.next(value);
        this._initList(
            this._fieldsets.filter((fieldset) => {
                return fieldset.name.toLowerCase().includes(value.toLowerCase());
            })
        );
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 2);
        if (undefined !== this.actionButtonSub) {
            this.actionButtonSub.unsubscribe();
        }
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._searchSubscription.unsubscribe();
        this._layout.searchInput.enable = false;
    }

    /**
     * Handle action click
     *
     * @param event
     */
    onActionClick(event: ActionEvent) {
        const id = event.rowId;
        switch (event.action) {
            case ActionType.Delete:
                this._delete(id);
                break;
            case ActionType.Update:
                this._router.navigate(["update", id], { relativeTo: this._route });
                break;
            case ActionType.View:
                this._router.navigate(["view", id], { relativeTo: this._route });
                break;
            case ActionType.Story:
                this._fieldsetService.getStory(id, 0).subscribe((data) => {
                    this.getStory(data);
                });
                break;
            case ActionType.Duplicate:
                Swal.fire({
                    title: this._translateService.instant("pim.fieldsets.duplicate"),
                    showCancelButton: true,
                    confirmButtonText: this._translateService.instant("general.duplication"),
                    cancelButtonText: this._translateService.instant("general.cancel"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this._fieldsetService.duplicate(id).subscribe((res) => {
                            if (this.acl.SYSPAD_PIM_FIELDSET_UPDATE) {
                                this._router.navigate(["update", res.data], { relativeTo: this._route });
                            } else {
                                this._fieldsetService.getFieldsets();
                            }
                        });
                    }
                });
                break;
        }
    }

    onCreate() {
        this._openCreateModal();
    }

    /**
     * Init the list
     * @param elementTypes
     */
    private _initList(elementTypes: MaestroFieldsets) {
        const formatedData = this._elementTableDataPipe.transform(elementTypes);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    /**
     * Delete a fieldset
     *
     * @param id
     */
    private _delete(id: number) {
        this._fieldsetService.deleteFieldset(id).subscribe((data) => {
            this._modalService.success(this._translateService.instant("general.deleted"));
            this._fieldsetService.getFieldsets().subscribe((fieldsets: any) => this._initList(fieldsets.data));
        });
    }

    /**
     * Create a fieldset
     *
     * @param name
     * @param open
     */
    private _create(name, open = false) {
        if (name) {
            this._fieldsetService.createFieldset(name).subscribe(
                (data: any) => {
                    this._modalService.success(this._translateService.instant("general.created"));
                    if (open) {
                        this._router.navigate(["update", data.data.id], { relativeTo: this._route });
                    } else {
                        this._fieldsetService.getFieldsets().subscribe((elements: any) => {
                            this._initList(elements.data);
                        });
                    }
                },
                (response) => {
                    response = response.error;
                    this._modalService.error(this._translateService.instant("response.error." + response.key + "." + response.method, { key: response.data }));
                }
            );
        }
    }

    /**
     * Open the create modal
     *
     * @returns
     */
    private _openCreateModal() {
        return this._modalService
            .open({
                title: this._translateService.instant("fieldsets.create"),
                showCloseButton: true,
                confirmButtonText: this.acl.SYSPAD_PIM_FIELDSET_UPDATE ? this._translateService.instant("general.create_open") : this._translateService.instant("general.create"),
                confirmButtonColor: "$maestro",
                showCancelButton: true,
                cancelButtonText: this._translateService.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                html: '<div class="form-group">' + '<input class="form-control" type="text" id="ModalFieldsetTitle">' + "</div>",
            })
            .then((result) => {
                if (result.value) {
                    this._create(
                        $("#ModalFieldsetTitle").val(),
                        result.dismiss || !this.acl.SYSPAD_PIM_FIELDSET_UPDATE ? false : true // dismiss = create only
                    );
                }
            });
    }

    /**
     * Get All Element's Story
     * @param id
     */
    getStory(data: any) {
        this.history = [];
        if (data.type === "success") {
            this.history = data.data.story;
            this.elementStory = data.data.id;
            this.startHistory = 10;
        }
        this.swal.fire();
    }
}
