import { Component, Input } from "@angular/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { ACL } from "app/shared/models/acl";

@Component({
    selector: "app-element-no-field-data",
    templateUrl: "./element-no-field-data.component.html",
})
export class ElementNoFieldDataComponent {
    @Input() link: any[] | string;
    @Input() buttonTitle: string;

    acl: ACL;

    constructor(private _authService: AuthService) {}

    ngOnInit() {
        this.acl = this._authService.getUserAclFromToken();
    }
}
