<swal [width]="'95%'" #cropSwal title="{{ 'image.crop' | translate }}" [allowOutsideClick]="false" [showCancelButton]="true" [cancelButtonColor]="'#FF0000'" confirmButtonText="{{ 'general.save' | translate }}" cancelButtonText="{{ 'general.cancel' | translate }}">
    <div *swalPortal>
        <div class="row">
            <div *ngIf="defaultRatio" class="col-3">
                <h6>Formats</h6>
                <table class="table table-responsive d-block">
                    <tbody>
                        <tr *ngFor="let format of formats">
                            <td>{{ format.name }}</td>
                            <td>
                                <button class="btn btn-sm btn-primary mr-2 mb-1" (click)="setAspectRatio(ratio, format.name)" *ngFor="let ratio of format.ratio">{{ ratio }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button class="btn btn-primary btn-block mt-2" (click)="setNoRatio()">{{ "image.no_ratio" | translate }}</button>
            </div>
            <div [ngClass]="defaultRatio ? 'col-9' : 'col-12'">
                <image-cropper #angularCropper *ngIf="isCrop" [cropper]="cropperPos" [imageBase64]="imageBase64" [maintainAspectRatio]="maintainAspectRatio" [aspectRatio]="ratio" [containWithinAspectRatio]="containWithinAspectRatio" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
                <image-cropper #angularCropper *ngIf="!isCrop" [imageBase64]="imageBase64" [maintainAspectRatio]="maintainAspectRatio" [aspectRatio]="ratio" [containWithinAspectRatio]="containWithinAspectRatio" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>
        </div>

        <hr>
        <div class="btn-group">
            <button type="button" class="btn btn-outline-primary" (click)="rotateLeft()">
                <fa-icon [icon]="faLeft"></fa-icon>
            </button>
            <button type="button" class="btn btn-outline-primary" (click)="rotateRight()">
                <fa-icon [icon]="faRight"></fa-icon>
            </button>
        </div>
        <div class="btn-group">
            <button type="button" class="ml-3 btn btn-outline-primary" (click)="flipHorizontal()">
                <fa-icon [icon]="faFlipH"></fa-icon>
            </button>
            <button type="button" class="btn btn-outline-primary" (click)="flipVertical()">
                <fa-icon [icon]="faFlipV"></fa-icon>
            </button>
        </div>
        <div class="btn-group">
            <button type="button" class="ml-3 btn btn-outline-danger" (click)="deleteCrop()" title="{{ 'projects.template.removeCrop' | translate }}">
                <fa-icon [icon]="faTrash"></fa-icon>
            </button> 
        </div>
    </div>
</swal>
