<div class="row mb-3" *ngIf="filterTypes.length > 0 || filterTags.length > 0">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <span>{{ 'filter.elementType' | translate }} : </span><span *ngIf="filterTypes.length === 0">{{
                            'general.filter' | translate }}</span>
                        <div *ngFor="let type of filterTypes" class="btn-group" role="group"
                            aria-label="Button group with nested dropdown">
                            <!--<button class="btn btn-primary btn-sm" type="button" (click)="removeFilterType(type.id)">
                                <fa-icon [icon]="faTimes"></fa-icon>
                            </button>-->
                            <button class="btn btn-primary btn-primary-filter btn-sm mr-2" type="button" disabled>
                                {{ type.name }}
                            </button>
                        </div>
                    </li>
                    <br *ngIf="filterTypes.length > 0 && filterTags.length > 0" />
                    <br *ngIf="filterTypes.length > 0 && filterTags.length > 0" />
                    <li class="list-group-item">
                        <span>{{ 'filter.tag' | translate }} : </span><span *ngIf="filterTags.length === 0">{{
                            'general.filter' | translate }}</span>
                        <div *ngFor="let tag of filterTags" class="btn-group" role="group"
                            aria-label="Button group with nested dropdown">
                            <!--<button class="btn btn-primary btn-sm" type="button" (click)="removeFilterTag(tag.id)">
                                <fa-icon [icon]="faTimes"></fa-icon>
                            </button>-->
                            <button class="btn btn-primary btn-primary-filter btn-sm mr-2" type="button" disabled>
                                <span *ngIf="tag.hasOwnProperty('name'); else elseBlock">{{ tag.name }}</span>
                                <ng-template #elseBlock>
                                    <span *ngIf="tag.hasOwnProperty('label')">{{ tag.label }}</span>
                                </ng-template>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="tableComponent row">
    <div class="col-lg-12">
        <div class="row">
            <div [class]="previewElements.length ? 'col-md-3' : 'col-md-12'">
                <app-datatable tableTitle="{{ 'pim.element.title' | translate }}"
                    [actions]="actions" (action)="onActionClick($event)"
                    searchDataParameter="element"
                    [expandableRow]="true"
                    [selectRows]="true" [exportRows]="true" affLink="{{affLink}}"
                    linkColName="name" baseLink="/pim/element/update/" (lazyEvent)="loadList($event)"
                    [tableRows]="tableRows" [tableColumns]="tableColumns" [totalRecords]="totalRecords"
                    [recordsFiltered]="recordsFiltered" [globalFilterFields]="globalFilterFields"
                    [_selectedColumns]="_selectedColumns" [exportFilename]="exportFilename" [filterable]="true"
                    [filters]="allFilters" [subData]="subData" [subDataType]="subDataType"
                    (subDataCall)="loadSubList($event)" (massTagStart)="massTag($event)"
                    (massTagEnd)="loadList(currentDatatableFilters)"
                    [shortPaginatorIsFirstPage]="shortPaginatorIsFirstPage"
                    [shortPaginatorIsLastPage]="shortPaginatorIsLastPage" (shortPaginatorPrev)="shortPaginatorPrev()"
                    (shortPaginatorReset)="shortPaginatorReset()" (shortPaginatorNext)="shortPaginatorNext()"
                    [first]="currentDatatableFilters.first" [preFilter]="preFilter"
                    (nodeSelected)="nodeSelected = $event" [showAdvancedSearch]="true" (changeAdvancedSearch)="changeAdvancedSearch($event)" 
                    (clearFilters)="cleanPrefilters($event)" sortOrder="1" (removeElements)="loadList(currentDatatableFilters)">
                    <!-- 
                        [hideViewButtonIfEditAllowed]="false"
                    -->
                </app-datatable>
            </div>
            <div id="previewPanel" *ngIf="previewElements.length > 0" class="col-md-9">
                <app-list-preview [actions]="actions" [elements]="previewElements"> </app-list-preview>
            </div>
        </div>
    </div>
</div>

<swal #historyModal [showConfirmButton]="false" title="{{ 'history.title' | translate }}">
    <div *swalPortal>
        <app-modal-history [history]="history" [service]="_elementService" [objectId]="elementStory"
            [start]="startHistory"></app-modal-history>
    </div>
</swal>

<!-- @TODO: Create a shared component ? -->
<div #createModal data-backdrop="false" class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ "breadcrumb.pim.element.create" | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="chips = []">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="alert alert-info alert-dismissible fade show" role="alert">
                    <i class="pi pi-info-circle"></i> {{ elementsSeparator }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                                       
                <div class="form-group p-fluid">
                    <p-chips class="form-control" [(ngModel)]="chips" allowDuplicate="false" class="w-100">
                        <ng-template let-item pTemplate="item">
                            <span>{{item}}</span>
                        </ng-template>
                    </p-chips>
                </div>
                               
                <div class="form-group">
                    <select class="form-control" id="modalElements">
                        <option *ngFor="let elementType of elementTypes" value="{{ elementType.id }}">{{ elementType.name }}</option>
                    </select>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="chips = []">{{ 'general.close' | translate }}</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="create()" [disabled]="!chips.length">{{ 'general.save' | translate }}</button>
            </div>
        </div>
    </div>
</div>