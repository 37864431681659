import { Injectable } from "@angular/core";
import { SidebarService } from "app/shared/components/sidebar";
import { SpinnerService } from "../spinner/spinner.service";
import { ActionButtonService } from "./action.button.service";
import { BreadcrumbFlatplanToolbarService } from "./bread-crumb-flatplan-toolbar.service";
import { BreadcrumbService } from "./breadcrumb.service";
import { DownloadJsxService } from "./download-jsx.service";
import { GeneralActionButtonsService } from "./general.action.buttons.service";
import { ImportButtonService } from "./import.button.service";
import { BreadcrumbMenuService } from "./menu.service";
import { SearchInputService } from "./search-input.service";
import { ViewButtonsService } from "./view.buttons.service";

/**
 * This service is used to regroup all services that help the developer to confingure the global display (search, spinner, sidebar, toolbar, breadcrumb, etc...)
 */
@Injectable()
export class LayoutService {
    constructor(
        public actionButton: ActionButtonService,
        public importButton: ImportButtonService,
        public menu: BreadcrumbMenuService,
        public breadcrumb: BreadcrumbService,
        public searchInput: SearchInputService,
        public sidebar: SidebarService,
        public spinner: SpinnerService,
        public generalActionButtons: GeneralActionButtonsService,
        public viewButtons: ViewButtonsService,
        public flatplanToolbar: BreadcrumbFlatplanToolbarService,
        public downloadJsx: DownloadJsxService
    ) {}
}
