import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "app/core/services/admin/user/user.service";
import { DataService } from "app/core/services/global/data/data.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ElementService } from "app/core/services/pim/element.service";
import { ElementExportService } from "app/core/services/pim/export.service";
import { VersionService } from "app/core/services/pim/version.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { SidebarRoutingComponent, SidebarRoutingOptions } from "app/shared/components/sidebar";
import { MaestroElement, Tabs } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ElementViewResources } from "./element-view.resolver";

@Component({
    selector: "app-element-view",
    templateUrl: "./element-view.component.html",
    styleUrls: ["./element-view.component.scss"],
})
export class ElementViewComponent implements OnInit, OnDestroy {
    element: MaestroElement;
    tabs: Tabs = [];
    versions: any[] = [];
    noTabs: boolean;
    noFormMessage: string;
    noFormName: string;

    readonly actions: ActionButtonsDef = [];

    readonly routerLink = ["../../../", MAESTRO_ROUTES.pim.elementType, MAESTRO_ROUTES.actions.update];
    readonly noFieldMessage = `Aucun élément n'est renseigné pour le type`;

    private readonly _noTabsMessage = `Aucune Tab n'a été renseigné pour le type `;
    private readonly _baseRoute = `/${MAESTRO_ROUTES.pim.base}/${MAESTRO_ROUTES.pim.element}/${MAESTRO_ROUTES.actions.view}`;
    versionName: string;
    generalActionButtonsSubscription: any;

    constructor(
        private _route: ActivatedRoute,
        private _layout: LayoutService,
        private _router: Router,
        private _versionService: VersionService,
        private _exportElement: ElementExportService,
        private _userService: UserService,
        private _elementService: ElementService,
        private _dataService: DataService
    ) {}

    ngOnInit(): void {
        const resources: ElementViewResources = this._route.snapshot.data.viewResources;
        this.element = resources.element;
        this.tabs = resources.tabs;
        this._checkRoute();
        this._initSideBar();
        this._getVersionsByElement(this.element.referent);
        this._checkData();
        const acl: ACL = this._userService.getUserAclFromToken();

        if (acl.SYSPAD_PIM_ELEMENT_UPDATE) {
            this.actions.push({ type: ActionType.FormUpdate, right: "SYSPAD_PIM_ELEMENT_UPDATE" });
        }
        if (acl.SYSPAD_PIM_ELEMENT_READ) {
            this.actions.push({ type: ActionType.DownloadPdf, right: "SYSPAD_PIM_ELEMENT_READ" });
        }
        this._layout.generalActionButtons.enable = true;
        this._layout.generalActionButtons.actions = this.actions;
        this.generalActionButtonsSubscription = this._layout.generalActionButtons.click$.subscribe((event: ActionEvent) => {
            this.onActionClick(event);
        });
    }

    ngOnDestroy(): void {
        this.generalActionButtonsSubscription.unsubscribe();
        this._layout.generalActionButtons.enable = false;
        this._layout.sidebar.enable = false;
        this._layout.sidebar.sideBarDef = null;
    }

    private _initSideBar() {
        if (this.tabs && this.tabs.length) {
            this._layout.sidebar.enable = true;
            this._layout.sidebar.sideBarDef = {
                component: SidebarRoutingComponent,
                options: <SidebarRoutingOptions>{
                    absoluteRoute: this._baseRoute + "/" + this.element.id,
                    data: this.tabs,
                    dataDontMove: [],
                    aclOptions: {
                        create: 0,
                        delete: 0,
                        edit: 0,
                    },
                    clicked: () => null,
                },
            };
        }
    }

    onVersionChange(id: any) {
        this._versionService.elementsByVersion(this.element.referent, id.id, id.version_number).subscribe((data) => {
            this.element = data.data;
            this._getVersionsByElement(this.element.referent);
            this._router.navigate(["../" + this.element.id + "/" + this.element.tabs[0].id], {
                replaceUrl: true,
                relativeTo: this._route,
            });
        });
    }

    _getVersionsByElement(id: number) {
        this._versionService.getVersionsByElement(id).subscribe((data) => {
            this.versions = data.data["list"];
            this.versionName = this._versionName();
        });
    }

    private _checkRoute() {
        const route = this._route;

        if (!route.snapshot.firstChild && this.tabs.length && this.tabs[0]) {
            this._router.navigate([this.tabs[0].id], { replaceUrl: true, relativeTo: route }).finally(() => this._checkData());
        }
    }

    private _checkData() {
        if (!this.tabs || (this.tabs && !this.tabs.length)) {
            this.noFormMessage = this._noTabsMessage;
            this.noFormName = this.element.elementType;
        }

        if (this.noFormMessage) {
            this.routerLink.push(this.element.elementTypeId.toString());
        }
    }

    private _versionName() {
        const versionElement = this.versions.filter((version) => version.id === this.element.version)[0];
        return versionElement.title + " - v." + this.element.version_number.toFixed(2);
    }

    onActionClick(event: ActionEvent) {
        const action = typeof event === "object" ? event["action"] : event;
        switch (action) {
            case ActionType.FormUpdate:
            case ActionType.Update:
                this._router.navigate([MAESTRO_ROUTES.pim, MAESTRO_ROUTES.pim.element, MAESTRO_ROUTES.actions.update, this.element.id]);
                break;
            case ActionType.Export:
                this._exportElement.generate(this.element.id, event["export"]);
                break;
            case ActionType.DownloadPdf:
                this._elementService.exportPdf([this.element.id]).subscribe((res) => {
                    this._dataService.downloadFile(res.data.blob, res.data.fileName);
                });
                break;
        }
    }
}
