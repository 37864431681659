import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { DialogModule } from "primeng-lts/dialog";
import { OrderListModule } from "primeng-lts/orderlist";
import { OrderDataComponent } from "./order-data.component";

@NgModule({
    declarations: [OrderDataComponent],
    imports: [SharedModule, OrderListModule, DialogModule],
    exports: [OrderDataComponent],
})
export class OrderDataModule {}
