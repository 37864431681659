import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { DamComponent } from "./dam.component";
import { DamRoutingModule } from "./dam.routing";
import { ThumbsnailContainerModule } from "./features/thumbsnail-container/thumbsnail-container.module";

@NgModule({
    imports: [DamRoutingModule, SharedModule, SharedComponentsModule, ThumbsnailContainerModule],
    declarations: [DamComponent],
})
export class DamModule {}
