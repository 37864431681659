import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UploadComponent } from "./upload.component";
import { UploaderComponent } from "./uploader/uploader.component";

const routes: Routes = [
    {
        path: "",
        component: UploadComponent,
        children: [{ path: "", component: UploaderComponent }],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UploadRoutingModule {}
