import { DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { DiffDirective } from "ng-diff-match-patch";
import { UnsubscribePipe } from ".";
import { CapitalizeFirstLetterPipe } from "./capitalize-first-letter.pipe";
import { ElementToFormHeaderPipe } from "./element-to-form-header.pipe";
import { ElementsByTypePipe } from "./elements-by-type.pipe";
import { FilterTypePipe } from "./filter-type.pipe";
import { ParseVarTwigPipe } from "./parse-var-twig.pipe";
import { SelectedTagsPipe } from "./selected-tags.pipe";
import { TableDataPipe } from "./table-data.pipe";
import { WorkflowPipe } from "./workflow.pipe";

const pipes = [UnsubscribePipe, ElementsByTypePipe, ParseVarTwigPipe, FilterTypePipe, WorkflowPipe, SelectedTagsPipe, TableDataPipe, ElementToFormHeaderPipe, CapitalizeFirstLetterPipe];

@NgModule({
    declarations: [...pipes],
    exports: [...pipes],
    providers: [...pipes, DatePipe, DiffDirective],
})
export class PipesModule { }
