import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faSave, faStepBackward, faStepForward } from "@fortawesome/free-solid-svg-icons";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { ExportsConfigurationService } from "../../../../../core/services/project/project/exports.service";
import { ProjectConfigurationStepperService } from "../../../../../core/services/project/project/stepper.service";
import { TemplateConfigurationService } from "../../../../../core/services/project/project/template.service";
import { WorkflowConfigurationService } from "../../../../../core/services/project/project/workflow.service";
import { ProjectConfigurationStep, ProjectConfigurationSteps } from "../../../../../shared/models/project/project-configuration-step";

@Component({
    selector: "app-project-configuration",
    templateUrl: "./configuration.component.html",
})
export class ProjectConfigurationComponent implements OnInit, OnDestroy {
    readonly faStepForward = faStepForward;
    readonly faStepBackward = faStepBackward;
    readonly faSave = faSave;

    public steps: ProjectConfigurationSteps;
    currentStep: ProjectConfigurationStep;

    public stepProject: any;
    public stepExports: any;
    public stepElements: any;
    public stepWorkflow: any;
    public stepTemplates: any;

    public idProject: any;
    public isWithPim: boolean;

    constructor(
        private _layout: LayoutService,
        private _stepperService: ProjectConfigurationStepperService,
        private _route: ActivatedRoute,
        private _projectService: ProjectService,
        private workflowService: WorkflowConfigurationService,
        private exportService: ExportsConfigurationService,
        private templateService: TemplateConfigurationService
    ) {}

    ngOnInit(): void {
        /**
         * Configure the stepper
         */

        let withProduct = false;
        let withWorkflow = false;
        let withTemplate = false;
        let withFlux = false;

        if(this._projectService.withProduct){
            withProduct = true;
        }
        const exportTypes = this._route.snapshot.data.data.exportTypes; 
        const module = this._route.snapshot.data.data.module;
        if (
            exportTypes.filter((str) => ["CDF","BOOKLET","HTML","Marketing"].includes(str)).length > 0
        ) {
            if(module['workflow'] != 0){
                withWorkflow = true;
            }
            withTemplate = true;
            
        } else if(
            exportTypes.filter((str) => ["XML"].includes(str)).length > 0 
        ) {
            withTemplate = true;
        } 
        if(exportTypes.filter((str) => ["CSV", "JSON","EXCEL"].includes(str)).length > 0 ){
            withFlux = true;
        }
        this._route.parent.parent.params.subscribe((data) => {            
            this.idProject = data.id
        });
        
        this._stepperService.withProduct.next(withProduct);
        this._stepperService.withTemplate.next(withTemplate);
        this._stepperService.withWorkflow.next(withWorkflow);
        this._stepperService.withFlux.next(withFlux);
        this._stepperService.setSteps( /*, data.auths*/);
        this._stepperService.steps$.subscribe((steps) => {
            
            this.steps = steps;
        });

        // Prepare each step's information

        this.stepAlreadyValid();
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
    }

    /**
     * Change step
     * @param step
     */
    onStepClick(step: ProjectConfigurationStep) {        
        this._stepperService.goToStep(step, this.idProject, step.title);
    }

    stepAlreadyValid() {
        let projectId = this.idProject;
        this._stepperService.getValidStep(projectId).subscribe((lastStepValid) => {
            this.steps.forEach((step) => {
                if (step.id <= lastStepValid.data) {
                    this._stepperService.validStep(step.id);
                } else {
                    this._stepperService.invalidStep(step.id);
                }
            });
        });
    }
}
