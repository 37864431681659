import { Component,Input } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-template-form",
    templateUrl: "./template-form.component.html",
    styleUrls:['./template-form.component.scss'],
})


export class TemplateFormComponent {
    @Input() fieldsArray;
    @Input() fieldset;
    @Input() forElement;
    @Input() isFieldset = false;
    nodes: any[];

    constructor(private _translate:TranslateService){
        
    }




    clear(event,field){
        if(event.value.name == this._translate.instant("general.noneAssociations")){
            field.get('value').setValue([]);
        }
    }
    clearButton(field){;
        field.get('value').setValue([])
    }
}

