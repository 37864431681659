import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class TabService {
    constructor(private _http: HttpClient) {}

    /**
     * Delete a tab from a data model
     *
     * @param id
     * @returns
     */
    deleteTypeTab(id: number): Observable<boolean> {
        return this._http.delete<boolean>(`${environment.pimUrl}/tab_type/${id}.json`);
    }

    /**
     * Create a new tab in data model
     *
     * @param elementTypeId
     * @param name
     * @returns
     */
    createTypeTab(elementTypeId: number, name: string) {
        return this._http.post(`${environment.pimUrl}/tab_type.json`, { id: elementTypeId, name });
    }

    /**
     * Update a tab
     *
     * @param typeTabId
     * @param name
     * @returns
     */
    updateTypeTab(typeTabId: number, name: string) {
        return this._http.put(`${environment.pimUrl}/tab_type.json`, { id: typeTabId, name });
    }

    /**
     * Change tab's order
     *
     * @param elementTypeId
     * @param positions
     * @returns
     */
    updateTabPosition(elementTypeId: number, positions: number[]) {
        return this._http.put(`${environment.pimUrl}/tab_type/position.json`, { element_Type_Id: elementTypeId, array: positions });
    }

    moveFieldToAnotherTabSection(fieldId: number, sectionId: number, tabTypeId: number) {
        return this._http.put(`${environment.pimUrl}/move/field/${fieldId}/to/section/${sectionId}/tab_type/${tabTypeId}.json`, {});
    }
}
