<ul ngbNav #nav="ngbNav" class="nav-tabs" style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; max-width: 100%;">
    <li *ngFor="let templateForm of templateFormArray.controls; let i = index" [ngbNavItem]="i">
        <a ngbNavLink>
            <app-form-label [invalid]="templateForm.invalid" [valid]="templateForm.valid">
                {{ templateForm.get('title').value }}
            </app-form-label>
        </a>
        <ng-template ngbNavContent>
            <app-template-form [forElement]="forElement" [fieldsArray]="fields"></app-template-form>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
