<div class="card-header">
    {{ bloc.name }}
</div>
<div class="card-body">
    <div *ngIf="lists.length > 0; else elseBlockA">
        <nav>
            <div class="nav nav-tabs" id="nav-list-tabs" role="tablist">
                <a *ngFor="let list of lists; let i = index" [ngClass]="{ 'nav-item nav-link': true, active: i === 0 }"
                    id="nav-{{ list.module }}-tab" data-toggle="tab" href="#nav-{{ list.module }}" role="tab"
                    [attr.aria-controls]="'nav-' + list.module" [attr.aria-selected]="i === 0 ? 'true' : 'false'">{{
                    list.module }}
                </a>
            </div>
        </nav>
        <div class="tab-content" id="nav-list-content">
            <div *ngFor="let list of lists; let i = index" [ngClass]="{ 'tab-pane fade': true, 'show active': i === 0 }"
                id="nav-{{ list.module }}" role="tabpanel" [attr.aria-labelledby]="'nav-' + list.module + '-tab'">
                <table class="table table-striped" *ngIf="list.items.length > 0; else elseBlockB">
                    <tr *ngFor="let item of list.items; let j = index">
                        <td style="width: 70%;">{{ item.name }}</td>
                        <td>
                            <span class="badge badge-primary">{{ list.module }}</span>
                        </td>
                        <td>
                            <button [disabled]="!acl.SYSPAD_PIM_ELEMENT_UPDATE" class="btn btn-sm btn-primary item" (click)="accessTo(list.module, item.id)">
                                {{ 'general.go' | translate }}
                            </button>
                        </td>
                    </tr>
                </table>

                <ng-template #elseBlockB>
                    <div class="alert alert-info" role="alert">
                        {{ 'general.no.data' | translate }}
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <ng-template #elseBlockA>
        <div class="alert alert-info" role="alert">
            {{ 'general.no.list' | translate }}
        </div>
    </ng-template>
</div>