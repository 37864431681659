<ng-container *ngIf="!formDisplayed; else formView">
    <form [formGroup]="tabForm">
        <div class="btn-group">
            <button container="body"  [ngbTooltip]="'tooltip.addTab'| translate" *ngIf="aclOptions.create" type="submit" (click)="onCreate()" class="btn btn-primary">
                <fa-icon [icon]="faPlus"></fa-icon>
            </button>
            <button container="body" [ngbTooltip]="'tooltip.update'| translate" *ngIf="aclOptions.edit" type="button" class="btn btn-primary" (click)="onEdit()">
                <fa-icon [icon]="faEdit"></fa-icon>
            </button>
            <button container="body" [ngbTooltip]="'tooltip.delete'| translate" *ngIf="aclOptions.delete" type="button" class="btn btn-danger" (click)="delete.emit()">
                <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
        </div>
    </form>
</ng-container>

<ng-template #formView>
    <ng-container [ngSwitch]="currentMode">
        <form *ngSwitchCase="'create'" [formGroup]="tabForm">
            <div class="form-group">
                <label class="first-letter">
                    {{ 'pim.elementTypes.tab.name' | translate }}
                </label>
                <input type="text" [ngClass]="{'invalid-input' :controlInput }" [formControlName]="tabNameInput" class="form-control"/>
            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-danger float-right" (click)="onCancel()">
                    {{ 'general.cancel' | translate }}
                </button>
                <button type="submit" (click)="onCreateSubmit()" class="btn btn-primary">
                    {{ 'general.create' | translate }}
                </button>
            </div>
        </form>

        <form *ngSwitchCase="'edit'" [formGroup]="tabForm">
            <div class="form-group">
                <label class="first-letter">
                    {{ 'pim.elementTypes.tab.name' | translate }}
                </label>
                <input type="text" [ngClass]="{'invalid-input' :controlInput }" [formControlName]="tabNameInput" class="form-control"/>
            </div>

            <div class="btn-group">
                <button type="button" class="btn btn-danger float-right" (click)="onCancel()">
                    {{ 'general.cancel' | translate }}
                </button>
                <button type="submit" (click)="onUpdateSubmit()" class="btn btn-primary">
                    {{ 'general.update' | translate }}
                </button>
            </div>
        </form>
    </ng-container>
</ng-template>
