import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { PasswordService } from "../../../core/services/admin/auth/password.service";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent {
    readonly routes = MAESTRO_ROUTES;
    readonly form: FormGroup;
    readonly faUser = faUser;

    submitted: boolean;

    constructor(private passwordService: PasswordService, private _modal: SwalModalService, formBuilder: FormBuilder, private translateService: TranslateService) {
        this.form = formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this._resetPassword(this.form.value.email);
        }
    }

    private _resetPassword(email: string) {
        this.passwordService.reset(email).subscribe((_) => {
            this._modal.success(this.translateService.instant("forget_password.success.content"));
        });
    }
}
