import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ListCascadeService } from "app/core/services/pim/listcascade.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ListCascadeUpdateResolver implements Resolve<any> {
    constructor(private service: ListCascadeService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.service.getListCascade(route.params.id).pipe(map((r) => r.data));
    }
}
