import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class PageOptionTabService {

    constructor(private _http: HttpClient) {}

    /**
     * Save an option
     *
     * @param exportId
     * @param optionId
     * @param value
     * @param id
     * @param pageId
     * @returns
     */
    saveOption(exportId: number, value: any, id: number, pageId: number, pageDuplicateId?: number, variableProject?: number): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/export/${exportId}/option/${variableProject}.json`, {
            value: value,
            id: id,
            pageId: pageId,
            pageDuplicateId: pageDuplicateId,
        });
    }

    importPDF(pageOptionId: string, pdf: File, base64, pageDuplicateId): Observable<any>{
        const formData = new FormData();
        formData.append(`file`, pdf);
        formData.append(`content`, base64);
        formData.append(`pageDuplicateId`, pageDuplicateId);
        return this._http.post(`${environment.projectsUrl}/page/option/${pageOptionId}/import.json`,  formData);
    }
}
