import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class VariableProjectService {
    constructor(protected _http: HttpClient) {}

    /**
     * Get all projects
     * @returns
     */
    getAllSort(): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/variables_sort.json`);
    }

    /**
     * Create a new project
     * @param data
     * @returns
     */
    create(data): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/variables.json`, { variables: data });
    }



    getDatatableVariables(filter: any): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/variables/datatable.json`, filter);
    }

    deleteVariable(id: number): Observable<any> {
        return this._http.delete(`${environment.projectsUrl}/variable_project/${id}.json`);
    }
}
