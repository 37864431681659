import { NgModule } from "@angular/core";

import { ListRoutingModule } from "./list.routing";

import { ListListModule } from "./list/list-list.module";
import { ListUpdateModule } from "./update/list-update.module";

@NgModule({
    imports: [ListRoutingModule, ListListModule, ListUpdateModule],
})
export class ListModule {}
