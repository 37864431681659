import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments";
import { ProjectService } from "./project.service";

@Injectable()
export class WorkflowConfigurationService extends ProjectService {
    /**
     * Get workflow group by export (step 4 workflow)
     * @param ids
     * @returns
     */
    getWorkflowByExportIds(ids: number[]): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/workflow/export_id.json`, ids);
    }

    /**
     * Get workflow steps
     *
     * @param workflowId
     * @param page
     * @returns
     */
    getSteps(workflowId: number, page: boolean): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/workflow/${workflowId}/places.json`, { page: page });
    }

    /**
     * Get configuration for step 4 (workflow)
     * @param projectId
     * @returns
     */
    getConfiguration(projectId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/project/${projectId}/workflow.json`);
    }

    /**
     * Save workflow configuration
     *
     * @param projectId
     * @param maestroExports
     * @returns
     */
    saveConfiguration(projectId: number, maestroExports) {
        return this._http.post(`${environment.projectsUrl}/project/${projectId}/workflow.json`, maestroExports).pipe(catchError((_) => of(null)));
    }
}
