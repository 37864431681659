<div class="row">
    <div class="col-md-8 mx-auto">
        <div class="card-group">
            <div class="card p-4">
                <div class="card-body">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <h1>{{ 'forget_password.title' | translate }}</h1>
                        <p class="text-muted">{{ 'forget_password.subtitle' | translate }}</p>

                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="email">
                                        <fa-icon [icon]="faUser"></fa-icon>
                                    </label>
                                </div>
                                <input type="text" id="email" formControlName="email" class="form-control" placeholder="{{ 'login.email.field' | translate }}" required="required" [ngClass]="{ 'is-invalid': submitted && form.controls['email'].invalid }"/>
                            </div>
                            <ng-container *ngIf="submitted">
                                <small *ngIf="form.controls['email'].hasError('required')" class="text-danger form-text">
                                    {{ 'login.email.error.required' | translate }}
                                </small>
                                <small *ngIf="form.controls['email'].hasError('email') && !form.controls['email'].hasError('required')" class="text-danger form-text">
                                    {{ 'login.email.error.invalid' | translate }}
                                </small>
                            </ng-container>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <button type="submit" class="btn btn-primary px-4">{{ 'general.send' | translate }}</button>
                            </div>
                            <div class="col-6 text-right">
                                <a class="btn btn-link px-0" [routerLink]="'/' + routes.public.login">{{ 'forget_password.back' | translate }}</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
