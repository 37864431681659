import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { MessageFormComponent } from "./form/message-form.component";
import { MessageComponent } from "./message.component";

const messageRoutes: Routes = [
    {
        path: "",
        component: MessageComponent,
    },
    {
        path: MAESTRO_ROUTES.actions.create,
        component: MessageFormComponent,
    },
    {
        path: `${MAESTRO_ROUTES.actions.update}/:id`,
        component: MessageFormComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(messageRoutes)],
    exports: [RouterModule],
})
export class MessageRoutingModule {}
