<app-page-thumbnail-menu *ngIf="exist" [disabled]="!mouseOver" [menu]="pageMenu" (menuClick)="onMenuClick($event)">
</app-page-thumbnail-menu>

<ul ngbNav #nav="ngbNav" class="nav-tabs pt-2" style="border: 1px #bbbbbb solid;">
    <li>
        <span class="col-12"></span>
        <div #homeImage [ngStyle]="{ height: imageHeight }" class="tab-pane home-image">
            <div *ngIf="(imageTabOver && enableTabOver )" class="cdf-page-hover">
                <div *ngIf="exist">
                    <div class="col-12 text-center">
                        <div class="row" role="group">
                            <div class="col-5">

                                <button  [disabled]="!previewUrl" type="button" class="btn btn-primary show-preview"
                                    (click)="onPreview()">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                </button>
                            </div>
                            <div class="col-5">
                                <button type="button" class=" btn btn-warning text-white"
                                [disabled]="!(page.template && page.template.name !== '') || !isTemplateExist()" (click)="onPageEdit()">
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                            </div>
                            <div class="col-5">
                                <button [disabled]="!previewUrl" type="button" class="btn btn-primary" (click)="downloadFile(page.name, previewUrl)">
                                    <fa-icon [icon]="faDownload"></fa-icon>
                                </button>
                            </div>
                            <div class="col-5">
                                <button *ngIf="exist" class="btn btn-primary    " ngbNavLink (click)="showInfo(page)">
                                    <fa-icon [icon]="faInfoCircle"></fa-icon>
                                </button>
                            </div>
                            
                            
                            <!--
                            <a *ngIf="previewUrl" type="button" class="btn btn-primary" [download]="page.name" [href]="previewUrl['changingThisBreaksApplicationSecurity']">
                                <fa-icon [icon]="faDownload"></fa-icon>
                            </a>
                            -->
                            
                            
                        </div>
                    </div>
                    <!-- <div class="col-12">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <b>{{ 'projects.export.templates' | translate }} : </b>
                                <a *ngIf="page.template.name !== '' && acl.MAESTRO_PROJECT_TEMPLATE_UPDATE; else elseBlockTemplate" href="{{ '/projects/templates/' + page.templateId + '/update' }}" target="_blank">{{ page.template.name }}</a>
                                <ng-template #elseBlockTemplate>
                                    {{ page.template.name === "" ? ("general.no.template" | translate) : page.template.name }}
                                </ng-template>
                                <br/>
                                <b>{{ 'general.products' | translate }} : </b>
                                <br *ngIf="page.elements.length === 0"><span *ngIf="page.elements.length === 0">{{
                                "general.no.element" | translate }}</span>
                                <ul *ngIf="page.elements.length > 0">
                                    <li *ngFor="let product of page.elements">
                                            <a *ngIf="acl.SYSPAD_PIM_ELEMENT_READ; else elseBlockElement" href="{{ '/pim/element/view/' + product.id }}" target="_blank">{{ product.name }}</a>
                                            <ng-template #elseBlockElement>
                                                {{ product.name }}
                                            </ng-template>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div> -->

                </div>
            </div>

            <ng-container *ngIf="!isTemplateExist()">
                <div class="noPreview" [ngStyle]="{height:(homeImage.offsetWidth + 25)+'px' }">
                    <div class="noPreviewText">
                        <span class="fa-stack fa-3x">
                          <i class="fas fa-file-code text-danger"></i>
                          <i class="fa fa-slash fa-stack-1x text-muted"></i>
                        </span>
                        <p class="text-muted mt-3">{{ 'projects.flatplan.gabAssoc' | translate }}</p>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isTemplateExist()">
                <img *ngIf="previewUrl" [src]="previewUrl" style="height: 100%; width: 100%; object-fit: contain; object-position: center;" [alt]="page.name"/>
                <ng-container *ngIf="!isProductsExist() && !previewUrl">
                    <div class="noPreview" [ngStyle]="{height:(homeImage.offsetWidth + 25)+'px' }">
                        <div class="noPreviewText">
                            <span class="fa-stack fa-3x">
                              <i class="fab fa-product-hunt text-danger"></i>
                              <i class="fa fa-slash fa-stack-1x text-muted"></i>
                            </span>
                            <p class="text-muted mt-3">{{ 'projects.flatplan.productAssoc' | translate }}</p>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isProductsExist() && !previewUrl">
                    <div class="noPreview" [ngStyle]="{height:(homeImage.offsetWidth + 25)+'px' }">
                        <div class="noPreviewText">
                            <span class="fa-stack fa-3x">
                                <i class="fa fa-eye text-warning"></i>
                                <i class="fa fa-slash fa-stack-1x text-muted"></i>
                            </span>
                            <p class="text-muted mt-3">{{ 'projects.flatplan.genAssoc' | translate }}</p>
                        </div>
                    </div>
                    <!--<div *ngIf="!previewUrl" class="noPreview" [ngStyle]="{ height:(homeImage.offsetWidth)+'px' }">
                        <div class="noPreviewText">
                            <span class="fa-stack fa-3x">
                                <i class="fas fa-cog fa-spin fa-fw"></i>
                            </span>
                            <p>{{ "response.success.preview.getPreview" | translate}}</p>
                        </div>
                    </div>-->
                </ng-container>
            </ng-container>
        </div>

        <ng-template #voidThumbnail>
            <div class="tab-pane no-product show active text-center" [ngStyle]="{ height: imageHeight }">
                <button class="btn btn-lg btn-primary" (click)="onProductAdd(page)">
                    <fa-icon [icon]="faPlusSquare"></fa-icon>
                </button>
            </div>
        </ng-template>
    </li>
    
</ul>


<swal #info>
    <div *swalPortal>
        <div class="modal-header">
            <h1><b><span>{{ infoPage.name }}</span></b></h1>
        </div>
        <div class="modal-body">
            <div class="row">
                <span class="col-3">Gabarit:</span>
                <span class="col-9"><a href="{{ '/projects/templates/' + infoPage.templateId  + '/update'}}" target="_blank">{{ infoPage.template.name }}</a></span>
            </div>
            <div class="row"><span class="col-3">Elements: </span>
                <div class="col-9">
                    <span *ngFor="let elem of infoPage.elements">
                        <a href="{{ '/pim/element/view/' + elem.id }}" target="_blank">{{ elem.name }}</a> <br>
                    </span>
                </div>
            </div>
        </div>
    </div>
</swal>
