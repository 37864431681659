import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class PageThumbnailService {
    private _currentHeadingSource = new Subject<string>();
    private _enableMouseOverSource = new BehaviorSubject<boolean>(true);

    readonly currentHeading$ = this._currentHeadingSource.asObservable();
    readonly enableMouseOver$ = this._enableMouseOverSource.asObservable();

    private _currentHeading: string;
    private _enableMouseOver: boolean;

    set currentHeading(currentHeading: string) {
        this._currentHeading = currentHeading;
        this._currentHeadingSource.next(this._currentHeading);
    }
    set enableMouseOver(value: boolean) {
        this._enableMouseOver = value;
        this._enableMouseOverSource.next(this._enableMouseOver);
    }
}
