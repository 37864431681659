import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { ChipsModule } from "primeng-lts/chips";
import { HomeTableComponent } from "./home-table/home-table.component";
import { HomeWorkflowTableComponent } from "./home-workflow-table/home-workflow-table.component";
import { PimHomeComponent } from "./home.component";
import { PimHomeResolver } from "./home.resolver";

@NgModule({
    declarations: [PimHomeComponent, HomeTableComponent, HomeWorkflowTableComponent],
    imports: [SharedModule, SharedComponentsModule, ChipsModule],
    exports: [PimHomeComponent],
    providers: [PimHomeResolver],
})
export class PimHomeModule {}
