import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { faClock, faEdit, faPlus, faSave, faTimes, faTools } from "@fortawesome/free-solid-svg-icons";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { FormHeaderData } from "./form-header";

@Component({
    selector: "app-form-header",
    templateUrl: "./form-header.component.html",
    styleUrls: ["./form-header.component.scss"],
})
export class FormHeaderComponent implements OnInit, OnDestroy {
    @Input() data: FormHeaderData;
    @Input() versions: any[];
    @Input() places: any[];
    @Input() updateName: boolean;
    @Input() versionName: string;
    @Input() referent: number;
    @Input() disabledHeader = false;
    @Output() version: EventEmitter<any> = new EventEmitter();
    @Output() newVersion: EventEmitter<null> = new EventEmitter();
    @Output() nameChange: EventEmitter<string> = new EventEmitter();

    @Input() disabled = true;

    version_id: number;

    readonly faClock = faClock;
    readonly faPlus = faPlus;
    readonly faTools = faTools;
    readonly faSave = faSave;

    readonly faEdit = faEdit;
    readonly faClose = faTimes;
    dataName: string;
    nameAtOpening: string;
    displayAll: boolean = false;

    constructor(private _layout: LayoutService) {
        this.updateName = false;
    }

    ngOnInit() {
        this._layout.breadcrumb.setPath({ routerLink: null, name: this.data.title }, 2);
        this.version_id = this.data.version;
        this.dataName = this.data.title;
        this.nameAtOpening = this.dataName;
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 2);
    }

    onChange(id: number, version_number: number) {
        this.version.emit({ id: id, version_number: version_number });
    }

    onNewVersionClick() {
        this.newVersion.emit();
    }

    onSaveName(name) {
        this.nameChange.emit(name);
        this.nameAtOpening = this.dataName;
    }

    updateNameFunction()
    {
        this.updateName = !this.updateName;
        this.dataName = this.nameAtOpening;
    }

    changeDisplay(){
        this.displayAll = !this.displayAll;
    }
}
