import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ExportElementTabsComponent } from "./export-element-tabs/export-element-tabs.component";
import { ConfigurationStepExportsComponent } from "./exports.component";

@NgModule({
    declarations: [ConfigurationStepExportsComponent, ExportElementTabsComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [ConfigurationStepExportsComponent],
})
export class ExportsConfigurationModule {}
