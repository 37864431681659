<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <app-form-header [data]="element | elementToFormHeader: 'view'" (version)="onVersionChange($event)" [versions]="versions" [versionName]="versionName"></app-form-header>
            </div>
        </div>
    </div>
</div>

<div class="row justify-content-md-center">
    <div class="col-lg-12">
        <div *ngIf="!noFormMessage; else noViewAvailable" style="margin-top: 10px">
            <router-outlet></router-outlet>
        </div>
        <ng-template #noViewAvailable>
            <app-element-no-field-data [link]="routerLink" buttonTitle="button.editType">
                <p>
                    {{ noFormMessage }} <b> {{ noFormName }}</b>
                </p>
            </app-element-no-field-data>
        </ng-template>
    </div>
</div>
