import { IMaestroEntity } from "./_maestro.entity";
import { MaestroElements } from "./element";
import { MaestroTemplate } from "./template";

export interface MaestroPage extends IMaestroEntity {
    group: string;
    folio?: number;
    elements?: MaestroElements;
    thumbnailSrc?: string;
    template?: MaestroTemplate;
    status?: MaestroPageStatus;
    imageSrc?: string;
    heading?: string;
    name?: string;
    height?: number;
    width?: number;
    elementLength?: number;
    pageLength?: number;
    exist?: boolean;
    index?: number;
    pageDuplicateId?: number;
    previews: [string];
    templateId: number;
    isImport?: boolean;
    cover?: boolean;
}

export type MaestroPages = MaestroPage[];

export enum MaestroPageStatus {
    Todo = "todo",
    Processing = "processing",
    Done = "done",
}
