import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { ACL } from "app/shared/models/acl";
import { Observable } from "rxjs";
import { AuthService } from "../services/admin/auth/auth.service";

/**
 * This guard is used to control the access to the dam module
 */
@Injectable()
export class DamModuleGuard implements CanLoad {
    constructor(private _authService: AuthService, private _router: Router) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const acl: ACL = this._authService.getUserAclFromToken();

        let result;
        if (acl.SYSPAD_DAM_MEDIA_LIST === 1) {
            result = true;
        } else {
            result = this._router.parseUrl("/");
        }

        return result;
    }
}
