import { NgModule } from "@angular/core";
import { CommonService } from "app/core/services/admin/common.service";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { UserListComponent } from "./user-list.component";
import { UserListGuard } from "./user-list.guard";
import { UserListResolver } from "./user-list.resolver";

@NgModule({
    declarations: [UserListComponent],
    imports: [SharedModule, SharedComponentsModule],
    providers: [UserListGuard, CommonService,UserListResolver],
})
export class UserListModule {}
