import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle, faTools } from "@fortawesome/free-solid-svg-icons";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { BreadcrumbFlatplanToolbarService } from "app/core/services/global/layout/bread-crumb-flatplan-toolbar.service";
import { PageService } from "app/core/services/project/export/page.service";
import { FlatplanViewType } from "app/shared/helpers/bread-crumb-flatplan-toolbar";
import swal from "sweetalert2";

@Component({
    selector: "app-bread-crumb-flatplan-toolbar",
    templateUrl: "./bread-crumb-flatplan-toolbar.component.html",
})
export class BreadCrumbFlatplanToolbarComponent implements OnInit, OnDestroy {
    FlatplanViewType = FlatplanViewType;

    public maxpages: Number;
    public nbpages: Number;
    public idFlatplan: Number;
    public exportType: string;
    public switcher: Boolean;
    public switcherActiveButton: FlatplanViewType;

    public faImage = faImage;
    public faInfo = faInfoCircle;
    public faTools = faTools;

    private readonly _sub = [];

    @ViewChild("maxPage") private maxPage: SwalComponent;
    isOpenFlatplan: boolean = false;
    formFlatplan: FormGroup;

    constructor(private _service: BreadcrumbFlatplanToolbarService, private _route: ActivatedRoute, private fb: FormBuilder, private _serviceFlatplan: PageService) {}

    ngOnInit(): void {
        this._sub.push(
            this._service.maxpages$.subscribe((maxpages) => {
                this.maxpages = maxpages;
            })
        );
        this._sub.push(this._service.nbpages$.subscribe((nbpages) => (this.nbpages = nbpages)));
        this._sub.push(this._service.idFlatplan$.subscribe((idFlatplan) => (this.idFlatplan = idFlatplan)));
        this._sub.push(this._service.exportType$.subscribe((exportType) => (this.exportType = exportType)));
        this._sub.push(this._service.switcherEnabled$.subscribe((value) => (this.switcher = value)));
        this._sub.push(
            this._service.flatplanView$.subscribe((view) => {
                this.switcherActiveButton = view;
            })
        );
    }

    ngOnDestroy(): void {
        this._sub.forEach((sub) => sub.unsubscribe());
    }

    onSwitchView(view: FlatplanViewType) {
        this._service.flatplanView = view;
    }

    openModalMaxPage() {
        this.formFlatplan = this.fb.group({
            pageLength: [this.maxpages, [Validators.required, Validators.min(0)]],
        });
        this.maxPage.fire();
    }

    saveMaxPage(value) {
        this._serviceFlatplan.updateMaxPage(this.idFlatplan, value.pageLength).subscribe(() => {
            this.maxpages = value.pageLength;
        });
    }

    preConfirmFlatplan(): void {
        if (this.isOpenFlatplan) {
            if (!this.formFlatplan.valid) {
                swal.disableButtons();
            } else {
                swal.enableButtons();
            }
        }
    }
}
