<div class="tableComponent row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body table-responsive">
                <h3 class="text-primary text-center">{{ (isFlatplan ? 'projects.flatplan.products' : 'projects.flatplan.productsExp') | translate }}</h3>
                <hr>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>{{ 'general.products' | translate }}</th>
                            <th>{{ 'table.pageNames' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of products; let i = index">
                            <td>{{ product.name }}</td>
                            <td>
                                <p *ngFor="let page of product.page" [routerLink]="page.templateId ? ['..', 'page', page.id] : null" [ngClass]="page.templateId ? ['pointer', 'text-primary'] : none">
                                    {{ '' != page.name ? page.name : 'table.templates.noPageName' | translate }} {{ page.workflow ? ' - ' + page.workflow : '' }}
                                </p>
                            </td>                            
                        </tr>
                        <tr *ngIf="products.length === 0">
                            <td class="text-center font-weight-bold" colspan="5">
                                {{ "general.no.data" | translate }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
