<span class="pageNumberEdit">
    <strong *ngIf="exportType === 'cdf'" class="d-inline-block m-2" [ngClass]="nbpages !== maxpages ?(nbpages > maxpages ? 'text-danger' : 'text-info') : 'text-primary'">{{ 'projects.flatplan.length.page' | translate }} : {{ nbpages }}/{{ maxpages }}</strong>
    <strong *ngIf="exportType !== 'cdf'" class="d-inline-block m-2">{{ 'projects.flatplan.length.export' | translate }} : {{ nbpages }}</strong>
    <button class="btn btn-sm" *ngIf="exportType === 'cdf'" (click)="openModalMaxPage()">
        <fa-icon [icon]="faTools"></fa-icon>
    </button>
</span>

<div class="btn-group" *ngIf="switcher">
    <button class="btn btn-primary" [ngClass]="{ active: switcherActiveButton === FlatplanViewType.Image }"
        (debounceClick)="onSwitchView(FlatplanViewType.Image)">
        <fa-icon [icon]="faImage"></fa-icon>
    </button>
</div>

<swal #maxPage title="{{ 'projects.export.update_maxpage' | translate }}"
    confirmButtonText="{{ 'general.create' | translate }}" (close)="isOpenFlatplan = false"
    [preConfirm]="preConfirmFlatplan()" (confirm)="saveMaxPage(formFlatplan.value)">
    <form *swalPortal [formGroup]="formFlatplan">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-7 col-form-label text-left"
                    [ngClass]="{ 'text-danger': submittedFlatplan && formFlatplan.controls['pageLength'].invalid }">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-5">
                    <input type="number" min="0" formControlName="pageLength" class="form-control" />
                </div>
            </div>
        </div>
    </form>
</swal>