import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroFieldsets } from "app/shared/models";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class FieldsetService {
    filterSearch: BehaviorSubject<string> = new BehaviorSubject("");

    constructor(private _http: HttpClient) {}

    /**
     * Get all fieldsets
     * @returns
     */
    getFieldsets(): Observable<MaestroFieldsets> {
        return this._http.get<MaestroFieldsets>(`${environment.pimUrl}/fieldset.json`);
    }

    /**
     * Get all fieldsets
     * @returns
     */
    getInputs(): Observable<MaestroFieldsets> {
        return this._http.get<MaestroFieldsets>(`${environment.pimUrl}/inputs.json`);
    }

    /**
     * Get a specific fieldset
     * @param id
     * @returns
     */
    getFieldset(id: number): Observable<any> {
        return this._http.get(`${environment.pimUrl}/fieldset/${id}.json`);
    }

    /**
     * Create a new fieldset
     * @param fieldsetName
     * @returns
     */
    createFieldset(fieldsetName: string): Observable<{ id: number }> {
        const formData = new FormData();
        formData.append("name", fieldsetName);

        return this._http.post<{ id: number }>(`${environment.pimUrl}/fieldset/create.json`, formData);
    }

    /**
     * Delete a fieldset
     * @param fieldsetId
     * @returns
     */
    deleteFieldset(fieldsetId: number): Observable<{ id: number }> {
        return this._http.delete<{ id: number }>(`${environment.pimUrl}/fieldset/${fieldsetId}.json`);
    }

    /**
     * Update the fieldset model
     *
     * @param fieldsetId
     * @param body
     * @returns
     */
    updateFormField(fieldsetId: number, body: any): Observable<any> {
        return this._http.put(`${environment.pimUrl}/fieldset/${fieldsetId}/form_field_update.json`, body);
    }

    /**
     * Remove a collection
     *
     * @param collectionId
     * @returns
     */
    removeCollection(collectionId: number): Observable<any> {
        return this._http.delete(`${environment.pimUrl}/collection/${collectionId}.json`);
    }

    /**
     * Change the name of a fieldset
     * @param id
     * @param name
     * @returns
     */
    changeName(id: number, name: string) {
        return this._http.post(`${environment.pimUrl}/fieldset/${id}/name.json`, { name: name });
    }

    getStory(fieldsetId: number, start: number): Observable<any> {
        return this._http.post(`${environment.pimUrl}/fieldset/${fieldsetId}/story.json`, { start: start });
    }

    duplicate(id: number, keepProtocol: boolean = false): Observable<any> {
        return this._http.post(`${environment.pimUrl}/fieldset/${id}/duplicate.json`, { keepProtocol: keepProtocol });
    }
}
