import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class ImportButtonService {
    private _enabledSource: Subject<boolean> = new Subject<boolean>();
    private _clickSource: Subject<void> = new Subject<void>();
    private _titleSource: Subject<string> = new BehaviorSubject<string>("");

    readonly enabled$ = this._enabledSource.asObservable();
    readonly click$ = this._clickSource.asObservable();
    readonly title$ = this._titleSource.asObservable();

    private _title: string;
    private _enable: boolean;

    /**
     * Set value for enable to determine if we have to display the buttons
     */
    set enable(value: boolean) {
        if (this._enable !== value) {
            this._enable = value;
            this._enabledSource.next(value);
        }
    }

    /**
     * Set value for title
     */
    set title(value: string) {
        if (this._title !== value) {
            this._title = value;
            this._titleSource.next(value);
        }
    }

    click() {
        this._clickSource.next();
    }
}
