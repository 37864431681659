import { NgModule } from "@angular/core";

import { TagRoutingModule } from "./tag.routing";

import { TagListModule } from "./list/tag-list.module";

@NgModule({
    imports: [TagRoutingModule, TagListModule],
})
export class TagModule {}
