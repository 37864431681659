import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DamWorkflowListComponent } from "./list/dam-workflow-list.component";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { DamWorkflowListGuard } from "./list/dam-workflow-list.guard";
import { WorkflowFormComponent } from "../form/workflow-form.component";

const damWorkflowRoutes: Routes = [
    {
        path: "",
        component: DamWorkflowListComponent,
        canActivate: [DamWorkflowListGuard],
        resolve: { workflows: DamWorkflowListGuard },
    },
    {
        path: `:id/${MAESTRO_ROUTES.actions.update}`,
        component: WorkflowFormComponent,
        data: { entity: "workflow", module: "dam" },
        runGuardsAndResolvers: "always",
    },
    {
        path: `${MAESTRO_ROUTES.actions.create}`,
        component: WorkflowFormComponent,
        data: { entity: "workflow", module: "dam" },
    },
];

@NgModule({
    imports: [RouterModule.forChild(damWorkflowRoutes)],
    exports: [RouterModule],
})
export class DamWorkflowRoutingModule {}
