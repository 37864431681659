import { NgModule } from "@angular/core";
import { TreeModule } from "@circlon/angular-tree-component";
import { SharedModule } from "app/shared/shared.module";
import { SidebarTreeNodeComponent } from "./sidebar-tree-node.component";
import { SidebarTreeNodePipe } from "./sidebar-tree-node.pipe";

@NgModule({
    imports: [TreeModule, SharedModule],
    declarations: [SidebarTreeNodeComponent, SidebarTreeNodePipe],
    entryComponents: [SidebarTreeNodeComponent],
})
export class SidebarTreeNodeModule {}
