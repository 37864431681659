import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faEdit, faSave, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { MaestroValidators } from "app/shared/validators/maestro.validators";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  faSave = faSave;
  faTimes = faTimesCircle;
  faEdit = faEdit;
  form: FormGroup;
  userId = this.route.snapshot.params["id"];

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private _translate: TranslateService,
    private router: Router,
    private _toaster: ToastService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      oldpassword: ["", [Validators.required, MaestroValidators.password]],
      newpassword: ["", [Validators.required, MaestroValidators.password]],
      confirmPassword: ["", [Validators.required, MaestroValidators.mustMatch("newpassword")]]
    })
    
  }

  get formControls() {
    return this.form.controls;
  }

  /**
  * Back to user's configuration
  */
  onBack() {
    this.router.navigate([MAESTRO_ROUTES.admin.base, MAESTRO_ROUTES.admin.users, this.userId,MAESTRO_ROUTES.actions.update]);
  }

  savePassword()
  {
      this.userService
          .updateUserPassword(this.userId, this.form.value.oldpassword, this.form.value.newpassword)
          .subscribe(() => {
            this._toaster.show({
              message: this._translate.instant("general.updatedPassword"),
              type: "success",
            });
            this.router.navigate([MAESTRO_ROUTES.admin.base, MAESTRO_ROUTES.admin.users, this.userId, MAESTRO_ROUTES.actions.update]);
          });  
  }

}
