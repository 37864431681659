<div *ngIf="mode === 'gridItem'" class="position-relative h-100">
    <div class="card m-2 h-100" [ngStyle]="{'border': media.isExpired ? '1px red solid' : ''}" [title]="media.isExpired ? translate.instant('dam.expired') + media.dateValid :''  | translate">
        <div *ngIf="media.id" class="px-2 py-1 mt-2 ml-2 position-absolute right-0 rounded card-thumb-format"
            [ngClass]="{ 'pointer': poppedUp }" (click)="selectExtension.emit({id: media.extension.toUpperCase()})">
            {{ media.extension | uppercase }}
        </div>
        <div *ngIf="media.id && media.isExpired" class="px-2 py-1 mt-2 mr-2 position-absolute left-0 rounded card_expired">
            {{ "dam.expire" | translate | uppercase }}
        </div>
        <div class="image-container" class="p-1">
            <img *ngIf="media.id && media.extension && media.extension.indexOf('mp3') > -1 && !media.thumbPath;"
                src="{{ damUrl+'/default/default-audio.jpg' }}" [id]="'img-' + media.id"
                class="card-thumb overflow-auto" alt="{{ media.name }}" />

            <img *ngIf="media.id && media.extension && !(media.extension.indexOf('mp3') > -1)"
                src="{{ (media.thumbPath) ? damUrl+'/'+media.thumbPath : (media.thumb ? getThumb({ binImage: media.thumb }) : damUrl+'/default/default.png') }}"
                [id]="'img-' + media.id" class="card-thumb overflow-auto" alt="{{ media.name }}" />
            <!-- (click)="open()" [ngClass]="{ 'pointer': !selectable }" -->
            <img *ngIf="!media.id" src="{{ media.thumbPath ? media.thumbPath : media.link ? media.link : damUrl+'/default/default.png' }}"
            [id]="'img-' + media.id" class="card-thumb overflow-auto" style="max-height: 235px; object-fit: contain" alt="{{ media.name }}" (error)="thumbnailsService.setDefaultImage($event)"/>
        </div>
        <div class="card-body p-1">
            <h5 *ngIf="media.id && media.name" [ngbTooltip]="media.name" class="card-title text-truncate">{{ media.name }}</h5>
            <h5 *ngIf="!media.id && media.link" [ngbTooltip]="media.name ? media.name : media.link" class="card-title text-truncate">
                <a href="{{ media.link }}" target="_blank">{{ media.name ? media.name : media.link }}</a>
            </h5>
            <div *ngIf="media.id" class="card-text d-flex flex-column">
                <div >
                    <small>{{ "general.date.create" | translate }} : </small>
                    <small>{{ media.dateCreate ? media.dateCreate : ("general.notAvailable" | translate) }}</small>
                </div>
                <div>
                    <small>{{ "general.date.update" | translate }} : </small>
                    <small>{{ media.dateUpdate ? media.dateUpdate : ("general.notAvailable" | translate) }}</small>
                </div>
                <div class="d-flex flex-row flex-wrap">
                    <div *ngFor="let tag of media.tags | slice:0:6" class="badge badge-pill badge-primary mt-1 mr-1 tags">
                        <span class="mr-1 tags-name" [title]="tag.name" [ngClass]="{ 'pointer': !minified }"
                              (click)="tagFiltered.emit(tag)">
                            {{ tag.name }}
                        </span>
                        <span *ngIf="canAddTag" class="pointer tags-close" (click)="removeTag(media, tag.id)">x</span>
                    </div>
                    <span *ngIf="canAddTag" (click)="openTagModal(media);"
                          class="badge badge-pill  badge-primary mt-1 mr-1 pointer"
                          [ngbTooltip]="'general.addTag' | translate">
                        +
                    </span>
                </div>
            </div>
        </div>
        <div class="card-footer py-1" *ngIf="buttons">
            <div class="row" style="justify-content: space-around;">
                <div class="btn-group">
                    <button [ngbTooltip]="this.translate.instant('dam.show')" *ngIf="media.id && buttons.preview"
                        class="btn btn-sm btn-sm-primary" (click)="open()">
                        <fa-icon [icon]="faEye"></fa-icon>
                    </button>
                    <button [ngbTooltip]="this.translate.instant('dam.image')" *ngIf="buttons.image"
                            class="btn btn-sm btn-sm-primary" (click)="showPopupChangeMedia(media.id)">
                        <fa-icon [icon]="faImage"></fa-icon>
                    </button>
                    <button [ngbTooltip]="this.translate.instant('dam.cart')" *ngIf="buttons.cart"
                            class="btn btn-sm btn-sm-primary" (click)="addCart.emit(media)">
                        <fa-icon [icon]="faBucket"></fa-icon>
                    </button>


                </div>
                <div class="btn-group">
                    <div *ngIf="buttons.module == 'page'" class="card-footer justify-content-end">
                        <div class="btn-group text-right" role="group">
                            <button type="button" *ngIf="buttons.select" class="btn btn-primary"
                                    (click)="selectedMedia(media)">
                                <fa-icon [icon]="faHandPointer"></fa-icon>
                            </button>
                            <a type="button"
                               *ngIf="buttons.crop && (media.extension === 'jpg' || media.extension === 'png' || media.extension === 'jpeg')"
                               class="btn btn-warning" (click)="openCropMedia(media)">
                                <fa-icon [icon]="faCrop"></fa-icon>
                            </a>
                        </div>
                    </div>
                    <button [ngbTooltip]="this.translate.instant('dam.crop')"
                            *ngIf="buttons.module != 'page' && buttons.crop && (media.extension === 'jpg' || media.extension === 'png' || media.extension === 'jpeg')"
                            class="btn btn-sm btn-sm-primary" (click)="cropMedia(media.id)">
                        <fa-icon [icon]="faCrop"></fa-icon>
                    </button>
                    <button [ngbTooltip]="this.translate.instant('dam.edit')" *ngIf="buttons.fill"
                            class="btn btn-sm btn-sm-primary" (click)="editMedia(media.id)">
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <button [ngbTooltip]="this.translate.instant('dam.delete')" *ngIf="buttons.delete"
                            class="btn btn-sm btn-sm-danger" (click)="deleteMedia(media.id)">
                        <fa-icon [icon]="faTrashAlt"></fa-icon>
                    </button>
                    <button [ngbTooltip]="this.translate.instant('dam.unlink')" *ngIf="buttons.unlink"
                            class="btn btn-sm btn-sm-danger" (click)="unlinkMedia(media.id)">
                        <fa-icon [icon]="faUnlink"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="selectable">
            <div *ngIf="!findMedia(linkedMedias, media.id, true); else elseBlock">
                <label class="switch switch-sm switch-primary m-0">
                    <input type="checkbox" class="switch-input" [value]="media.id" (change)="selectedMedia($event)"
                        [checked]="findMedia(selectedMedias, media.id)" [disabled]="singleSelection && (linkedMedias.length || (selectedMedias.length && !selectedMedias.includes(media.id)) || linkAdded)"/>
                    <span class="switch-slider"></span>
                </label>
            </div>
            <ng-template #elseBlock>
                <i class="pi pi-link text-primary h5" [ngbTooltip]="'modal.alreadyLinked' | translate"></i>
            </ng-template>
        </div>
    </div>
</div>

<div *ngIf="mode === 'listItem'">
    <div class="row pb-1 mb-2 overflow-auto">
        <div class="col-5 col-md-3">
            <div class="px-2 py-1 mx-4 my-1 position-absolute right-0 rounded card-thumb-format">
                {{ media.extension | uppercase }} - <span [ngClass]="getIconClass()"></span>
            </div>
            <img [src]="media.thumbPath ? damUrl+'/'+media.thumbPath : getThumb({ binImage: media.thumb })"
                 [id]="'img-' + media.id" class="card-thumb w-100" alt="{{ media.name }}"/>
            <!-- (click)="open()" [ngClass]="{ 'pointer': !selectable }" -->
        </div>
        <div class="col-4 col-md-6">
            <h5 class="card-title text-truncate">{{ media.name }}</h5>
            <div class="card-text">
                <div>
                    <small>{{ "general.date.create" | translate }} : </small>
                    <small>{{ media.dateCreate ? media.dateCreate : ("general.notAvailable" | translate) }}</small>
                </div>
                <div>
                    <small>{{ "general.date.update" | translate }} : </small>
                    <small>{{ media.dateUpdate ? media.dateUpdate : ("general.notAvailable" | translate) }}</small>
                </div>
                <div class="tags">
                    <span *ngFor="let tag of media.tags" class="badge badge-pill badge-primary mr-1">
                        <span class="mr-1" [ngClass]="{ 'pointer': !minified }" (click)="tagFiltered.emit(tag)">{{
                                tag.name
                            }}</span>
                        <span *ngIf="canAddTag" class="pointer" (click)="removeTag(media, tag.id)">x</span>
                    </span>
                    <span *ngIf="canAddTag" (click)="openTagModal(media);"
                          class="badge badge-pill badge-primary mr-1 pointer"
                          [ngbTooltip]="'general.addTag' | translate">+</span>
                </div>
            </div>
        </div>
        <div class="col">
            <button [ngbTooltip]="this.translate.instant('dam.cart')" placement="bottom" *ngIf="buttons.cart"
                    class="btn btn-primary mt-1 mr-2" (click)="addCart.emit(media)">
                <fa-icon [icon]="faBucket"></fa-icon>
            </button>
            <!--
                    TO DELETE IF WE DON'T KEEP EMAIL SEND
            <button [ngbTooltip]="this.translate.instant('general.sendEmail')" placement="bottom" *ngIf="buttons.send"
                class="btn btn-primary mt-1 mr-2" (click)="sendByMail(media)">
                <fa-icon [icon]="faEmail"></fa-icon>
            </button>-->
            <button [ngbTooltip]="this.translate.instant('dam.show')" placement="bottom" *ngIf="buttons.preview"
                    class="btn btn-primary mt-1 mr-2" (click)="open()">
                <fa-icon [icon]="faEye"></fa-icon>
            </button>
            <button [ngbTooltip]="this.translate.instant('dam.image')" placement="bottom" *ngIf="buttons.image"
                    class="btn btn-primary mt-1 mr-2" (click)="showPopupChangeMedia(media.id)">
                <fa-icon [icon]="faImage"></fa-icon>
            </button>

            <button [ngbTooltip]="this.translate.instant('dam.crop')" placement="bottom"
                    *ngIf="buttons.crop && (media.extension === 'jpg' || media.extension === 'png' || media.extension === 'jpeg')"
                    class="btn btn-primary mt-1 mr-2" (click)="cropMedia(media.id)">
                <fa-icon [icon]="faCrop"></fa-icon>
            </button>
            <button [ngbTooltip]="this.translate.instant('dam.edit')" placement="bottom"
                    *ngIf="buttons.fill && media.model" class="btn btn-primary mt-1 mr-2" (click)="editMedia(media.id)">
                <fa-icon [icon]="faEdit"></fa-icon>
            </button>
            <button [ngbTooltip]="this.translate.instant('dam.delete')" placement="bottom" *ngIf="buttons.delete"
                    class="btn btn-danger mt-1 mr-2" (click)="deleteMedia(media.id)">
                <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
            <button [ngbTooltip]="this.translate.instant('dam.unlink')" placement="bottom" *ngIf="buttons.unlink"
                    class="btn btn-danger mt-1 mr-2" (click)="unlinkMedia(media.id)">
                <fa-icon [icon]="faUnlink"></fa-icon>
            </button>
        </div>
    </div>
</div>

<swal #changeMedia title="{{ 'modal.updateMedia' | translate }}" confirmButtonText="{{ 'modal.upload' | translate }}">
    <div *swalPortal>
        <input class="form-control" id="newFile" type="file" accept="{{ acceptList }}">
        <div class="mx-1 mt-3" *ngIf="acl.MAESTRO_DAM_PICTO_CREATE === 1">
            <table>
                <tr>
                    <td>
                        <label class="font-weight-bold">{{ "upload.picto" | translate }}</label>
                    </td>
                    <td class="pt-1 pl-2">
                        <label class="switch switch-pill switch-primary">
                            <input id="picto" type="checkbox" class="switch-input">
                            <span class="switch-slider"></span>
                        </label>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</swal>

<swal #addTagModal title="TBD" confirmButtonText="{{ 'modal.add' | translate }}">
    <div *swalPortal>
        <form [formGroup]="formAddTag">
            <app-drop-down [type]="'multi'" [list]="filteredTags" formControlName="tag"></app-drop-down>
        </form>
    </div>
</swal>

<app-cropper #cropper [defaultRatio]="true" (cropped)="croppedMedia($event)"></app-cropper>

<swal #datamodel title="{{ 'modal.datamodel' | translate }}" confirmButtonText="{{ 'modal.confirm' | translate }}">
    <div *swalPortal>
        <span *ngIf="media.model" class="text-danger font-weight-bold">{{
                "modal.mediadam.updateDm" | translate
            }}</span>
        <form class="mt-2" [formGroup]="dataModelForm">
            <app-drop-down [type]="'single'" [list]="dataModels" formControlName="dm"></app-drop-down>
        </form>
    </div>
</swal>
