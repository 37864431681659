import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class WorkflowService {
    constructor(private _http: HttpClient) {}

    /**
     * Get workflow
     * @param environment
     * @param entity
     * @param objectId
     * @returns
     */
    display(environment: string, entity: string, objectId: number) {
        return this._http.get(`${environment}/${entity}/${objectId}/workflow/display.json`);
    }

    /**
     * Get autorization for workflow
     * @param environment
     * @param entity
     * @param objectId
     * @returns
     */
    getUserGrantSetValue(environment: string, entity: string, objectId: number) {
        return this._http.get(`${environment}/${entity}/${objectId}/set_value.json`);
    }

    /**
     * Change step workflow
     * @param environment
     * @param validation
     * @returns
     */
    validationWorkflow(environment: string, validation: any) {
        return this._http.post(`${environment}/current_place.json`, validation);
    }

    /**
     * Get current step
     * @param environment
     * @param entity
     * @param objectId
     * @returns
     */
    getCurrentPlace(environment: string, entity: string, objectId: number) {
        return this._http.get(`${environment}/${entity}/${objectId}/place.json`);
    }
}
