import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class SocialService {
    constructor(private _http: HttpClient) {}

    /**
     * Publish to facebook
     */
    publishFacebook(exportId: number, ...someParameters: any): Observable<any> {
        let post = someParameters.shift();
        return this._http.post(`${environment.projectsUrl}/v1/export/${exportId}/socialpost.json`, {
            title: post.theme,
            socialNetwork: post.socialNetwork,
            message: post.textContent,
            hashtag: post.hashtag,
            dateBeginPost: post.dateStart,
            dateEndPost: post.dateEndPost,
            mediasId: post.medias,
            canLike: post.shareable,
            canComment: post.commentable,
            canShare: post.shareable,
            postDiffusion: post.diffusion,
            postResponse: post.who,

        });
    }
}
