import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { UserFormComponent } from "./form/user-form.component";
import { UserListComponent } from "./list/user-list.component";
import { UserListGuard } from "./list/user-list.guard";
import { UserListResolver } from "./list/user-list.resolver";



const userRoutes: Routes = [
    {
        path: "",
        component: UserListComponent,
        canActivate: [UserListGuard],
        resolve: { users: UserListGuard,filters: UserListResolver},
    },
    {
        path: MAESTRO_ROUTES.actions.create,
        component: UserFormComponent,
        data: { entity: "user", module: "adminApi", mode: "create" },
    },
    {
        path: `:id/${MAESTRO_ROUTES.actions.update}`,
        component: UserFormComponent,
        data: { entity: "user", module: "adminApi", mode: "update" },
    },
    {
        path: `:id/${MAESTRO_ROUTES.actions.update}/${MAESTRO_ROUTES.public.password.base}`,
        component: ChangePasswordComponent,
        data: { entity: "user", module: "adminApi", mode: "update" },
    },
];

@NgModule({
    imports: [RouterModule.forChild(userRoutes)],
    exports: [RouterModule],
})
export class UserRoutingModule {}
