import { NgModule } from "@angular/core";
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ConfirmationService } from "primeng-lts/api";
import { CascadeSelectModule } from 'primeng-lts/cascadeselect';
import { TemplateFormComponent } from "./form/template-form.component";
import { TemplateConfigurationTabsComponent } from "./tabs/template-configuration-tabs.component";
import { ConfigurationStepTemplateComponent } from "./template.component";

@NgModule({
    declarations: [ConfigurationStepTemplateComponent, TemplateConfigurationTabsComponent, TemplateFormComponent],
    imports: [SharedModule, SharedComponentsModule, CascadeSelectModule, NgbAlertModule],
    exports: [ConfigurationStepTemplateComponent],
    providers: [ConfirmationService],
})
export class TemplateConfigurationModule {}
