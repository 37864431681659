import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ChartDataset, ChartOption, ChartTitle, MaestroBlocData } from "app/shared/models/dashboard";
import { Chart } from "node_modules/chart.js";

@Component({
    selector: "[app-dashboard-chart-level-1]",
    templateUrl: "./chart-level-1.component.html",
    // styleUrls: ['../chart.component.scss'],
})
export class ChartLevel1Component implements OnInit {
    @Input() bloc: MaestroBlocData;
    labels: string[] = [];
    datasets: ChartDataset[] = [];
    data: number[] = [];
    options: ChartOption = {};

    @ViewChild("myChart", { static: true })
    myChart: ElementRef;
    chart: any;

    defaultColor: string = "rgba(255,255,255,0.9)";

    ngOnInit() {
        if (this.bloc && this.bloc.data) {
            this._getData();
        }
    }

    _getData() {
        let colors: string[] = [
            `rgba(255, 217, 0, 0.9)`, // Maestro yellow
            `rgba(255, 193, 7, 0.9)`, // Maestro warning
            `rgba(248, 108, 107, 0.9)`, // Maestro danger
            `rgba(99, 194, 222, 0.9)`, // Maestro info
            `rgba(32, 168, 216, 0.9)`, // Maestro primary
            `rgba(240, 243, 245, 0.9)`, // Maestro light
            `rgba(228, 229, 230, 0.9)`, // Maestro grey
            `rgba(164, 183, 193, 0.9)`, // Maestro secondary
            `rgba(41, 54, 61, 0.9)`, // Maestro dark
            // `rgba(78, 184, 170, 0.9)`, // Maestro green
            // `rgba(77, 189, 116, 0.9)`, // Maestro success
        ]; // TODO : long term get colors cases from database

        for (const [key, value] of Object.entries(this.bloc.data)) {
            // const color = `rgba(255,255,255, ${Math.floor(Math.random() * (7 - 3) + 3) / 10})`;

            for (const object of value) {
                this.labels.push(object.label);
                this.data.push(object.data);

                const color = `rgba(
                    ${Math.floor(Math.random() * 256)}, 
                    ${Math.floor(Math.random() * 256)}, 
                    ${Math.floor(Math.random() * 256)}, 
                    0.9)`;
                colors.push(color);
            }

            this.labels = this.labels.filter((v, i, a) => a.indexOf(v) === i);

            const dataset: ChartDataset = {
                label: key,
                data: this.data,
                backgroundColor: colors,
                borderColor: colors,
            };

            this.datasets.push(dataset);
            this.data = [];
            colors = [];
        }

        this._getOption();
        this.drawChart();
    }

    _getOption() {
        const title: ChartTitle = {
            display: true,
            text: this.bloc.name,
            fontColor: this.defaultColor,
            fontSize: 16,
        };

        let scales: any = {};
        let legendPosition: string = "bottom";
        let legendDisplay = true;

        if ("pie" !== this.bloc.type && "doughnut" !== this.bloc.type) {
            scales = {
                xAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            fontColor: this.defaultColor,
                        },
                        gridLines: {
                            display: false,
                            //color: this.defaultColor,
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            fontColor: this.defaultColor,
                        },
                        gridLines: {
                            display: false,
                            //color: this.defaultColor,
                        },
                    },
                ],
            };
            legendPosition = "top";
            legendDisplay = this.datasets.length > 1 ? true : false;;
        }


        const option: ChartOption = {
            title: title,
            // TODO : add in model
            legend: {
                display: legendDisplay,
                labels: {
                    // This more specific font property overrides the global property
                    fontColor: this.defaultColor
                },
                position: legendPosition
            },
            scales: scales,
            responsive: true,
            maintainAspectRatio: false
        };

        this.options = option;
    }

    drawChart() {
        const canvas = this.myChart.nativeElement.getContext("2d");
        this.chart = new Chart(canvas, {
            type: this.bloc.type as any,
            data: {
                labels: this.labels,
                datasets: this.datasets,
            },
            options: this.options,
        });
    }
}
