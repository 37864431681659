import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { PrivateModule } from "./private/private.module";
import { PublicModule } from "./public/public.module";
import { ErrorInterceptor, JwtInterceptor, SpinnerInterceptor } from "./shared/interceptors";
import { MAESTRO_ROUTES } from "./shared/routes";

const routes: Routes = [
    /* To remove when email is fix */
    {
        path: `forgot-password/:token/change`,
        redirectTo: `${MAESTRO_ROUTES.public.password.base}/${MAESTRO_ROUTES.public.password.change}/:token`,
    },

    /**
     * as long as mastro got void base path, this should be the last declaration,
     * Maestro is like a "PrivateModule" here.
     */
    { path: MAESTRO_ROUTES.public.privateBase, canActivate: [AuthGuard], loadChildren: () => PrivateModule },
    { path: MAESTRO_ROUTES.public.base, loadChildren: () => PublicModule },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "reload",
            anchorScrolling: "enabled",
            relativeLinkResolution: "legacy",
        }),
    ],
    exports: [RouterModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
})
export class AppRoutingModule {}
