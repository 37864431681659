import { NgModule } from "@angular/core";
import { GroupService } from "app/core/services/admin/group/group.service";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { TableModule } from "primeng-lts/table";
import { ProjectDashboardComponent } from "./dashboard.component";
import { ExportCardComponent } from "./export-card/export-card.component";
@NgModule({
    declarations: [ProjectDashboardComponent, ExportCardComponent],
    imports: [SharedModule, SharedComponentsModule, TableModule],
    providers: [GroupService],
})
export class ProjectDashboardModule {}
