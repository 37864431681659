import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { TableService } from "app/core/services/global/table/table.service";
import { PageTextTabService } from "app/core/services/project/export/page-text-tab.service";
import { ExportElementValue } from "app/shared/models/project/export-element.model";

@Component({
    selector: "app-edit-table",
    templateUrl: "./edit-table.component.html",
})
export class EditTableComponent implements OnInit {
    @Input() isInput: boolean = false;
    @Input() field: any; // Element Field or Input data

    @Output() _saveValue: EventEmitter<ExportElementValue> = new EventEmitter();

    currentTable: string = ""; // Table in JSON
    currentTitle: string = "";
    currentId: number = -1;
    currentIdProject: boolean = false;
    currentUniqueId: number | string;
    isPimVariable: boolean = false;
    

    tableSources: string[] = ["model", "product"];

    constructor(private _pageService: PageTextTabService, private _tableService: TableService, private cdRef: ChangeDetectorRef,private _userService: UserService,
        ) {}

    ngOnInit(): void {}

    /**
     * Configure a table field
     */
    openConfigureTableModal(): void {
        this.currentUniqueId = this.isInput ? `${this.field.collection}-${this.field.variableProjectId}` : this.field.variableProjectId;        
        
        if(this.field.pimInputId){
            this.isPimVariable = true;
        }
        try {
            this.currentTable = JSON.parse(this.field.value);

        } catch (error) {
            // In case we add a field without configure it (it will create en element value with "" as value and break parse)
            this.currentTable = null;
        }

        this.currentTitle = this.isInput ? this.field.name : this.field.key;

        if (this.field.valueProjectId) {
            this.currentId = this.field.valueProjectId;
            this.currentIdProject = true;
        } else {
            this.currentId = this.field.valuePimId;
            this.currentIdProject = false;
        }
        
        this.cdRef.detectChanges(); // Avoid ExpressionChangedAfterItHasBeenCheckedError
    }

    /**
     * Import table from element type field default value
     * @param source
     */
    importTableFromSource(source: string): void {
        const isFieldset = this.field.hasOwnProperty("collection") && 0 < this.field.collection ? true : false;
        const parentId = isFieldset ? this.field.collection : this.field.productId; // Can be an element or a collection id
        const fieldId = isFieldset ? this.field.pimInputId : this.field.elemTypeFieldId; // Can be an element type field or input id
        let value;

        if ("product" === source) {
            this._pageService.getPimValue(parentId, fieldId, isFieldset).subscribe((objectValue) => {
                if (objectValue.data.length && objectValue.data[0].hasOwnProperty("value")) {
                    value = objectValue.data[0].value;
                } else {
                    value = null;
                }

                this.setValue(value);
            });
        } else if ("model" === source) {
            this._pageService.getPimField(fieldId, isFieldset).subscribe((objectValue) => {
                if (objectValue.data.hasOwnProperty("defaultValue")) {
                    value = objectValue.data.defaultValue;
                } else {
                    value = null;
                }

                this.setValue(value);
            });
        } else {
            this._tableService.importError(source);
        }
    }

    /**
     * Set currentTable value after request
     * @param value
     */
    setValue(value: any) {
        const oldCurrentTable = this.currentTable;
        this.currentTable = value ? JSON.parse(value) : "";
        this.cdRef.detectChanges(); // Avoid ExpressionChangedAfterItHasBeenCheckedError

        this.saveTable(oldCurrentTable, this.currentTable);
    }

    /**
     * Emit event to save a table
     *
     * @param oldValue
     * @param newValue
     */
    saveTable(oldValue: string, newValue: string): void {
        if (this.field) {
            const val = null === oldValue ? "" : oldValue;

            if (0 !== val.localeCompare(newValue)) {
                this.field.value = JSON.stringify(newValue);
                
                this._saveValue.emit(this.field);
                this.currentTable = newValue;
            }
        }
    }
}
