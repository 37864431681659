import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faBars, faFilter, faHome, faSave } from "@fortawesome/free-solid-svg-icons";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { UserService } from "app/core/services/admin/user/user.service";
import { TemplateService } from "app/core/services/project/template/template.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-jsx",
    templateUrl: "./jsx.component.html",
    styleUrls: ["./jsx.component.scss"],
})

export class JsxComponent implements OnInit {

    @Input() downloadJsx: boolean;
    uploadImage: File[];
    canChangeJSX: boolean = false;
    readonly faBars = faBars;
    readonly faHome = faHome;
    readonly faPlusSquare = faPlusSquare;
    readonly faFilter = faFilter;
    readonly faSave = faSave;

    @ViewChild("downloadJsxModal", { static: true }) private downloadJsxModal: SwalComponent;

    constructor(private _templateService : TemplateService, private _userService : UserService){}


    ngOnInit(): void {
        const localUser = this._userService.getUserIdFromToken();
        this._userService.getById(localUser).subscribe((data) => {
            if (data.data.internalCode == "ADMIN") {
                this.canChangeJSX = true;
            }
        });        
    }

    openJsxModal(){
        this.uploadImage = [];
        this.downloadJsxModal.fire();
    }

    download(){
        
        this._templateService.downloadJsx();
    }

    upload(){        
        if (this.uploadImage == null) {
            alert("Please select file");
        } else {
            this._templateService.uploadJsx(this.uploadImage).subscribe((data) => {
                Swal.close();
            });
        }
    }

    handleFileInput(files: File[]) {
        this.uploadImage = files;
    }
}