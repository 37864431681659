import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DropTargetOptions, HttpClientUploadService, InputFileOptions } from "@wkoza/ngx-upload";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { map } from "rxjs/operators";
import { UploadService } from "../../../core/services/upload/upload.service";

@Component({
    selector: "app-uploader",
    templateUrl: "./uploader.component.html",
    styleUrls: ["./uploader.component.scss"],
})
export class UploaderComponent implements OnInit, OnDestroy {
    private _acceptList = [];

    options: DropTargetOptions;

    optionsInput: InputFileOptions;

    readonly faUpload = faCloudUploadAlt;
    readonly routes = MAESTRO_ROUTES;

    acl: ACL;

    public disabled = true;

    claroAvailable = false;

    constructor(
        private _layoutService: LayoutService,
        private _router: Router,
        private _uploadService: UploadService,
        public uploader: HttpClientUploadService,
        private _toaster: ToastService,
        private _translate: TranslateService,
        private _userService: UserService
    ) {}

    ngOnInit() {
        this.acl = this._userService.getUserAclFromToken();
        // Get available media types
        this._uploadService.getMediaTypes().subscribe((data) => {
            this._acceptList = [];

            data.map((v) => v.header).forEach((header) => {
                this._acceptList.push(...header.split(","));
            });

            this.options = {
                color: "dropZoneColor",
                colorDrag: "dropZoneColorDrag",
                colorDrop: "dropZoneColorDrop",
                multiple: true,
                accept: this._acceptList,
            };

            this.optionsInput = {
                multiple: true,
                accept: this._acceptList,
            };
        });

        this._uploadService.claroIsAvailable().subscribe((data) => {
            this.claroAvailable = data.data;
        })

        this.disabled = true;
        this.uploader.onAddToQueue$.subscribe(() => (this.disabled = false));
        this.uploader.onSuccess$.subscribe(() => {
            this.disabled = true;
            this._router.navigate(["/dam", "thumbnails"]);
        });
        this._layoutService.breadcrumb.setPath({ name: "upload.title", routerLink: null }, 0);
    }

    ngOnDestroy(): void {
        this.uploader.queue.length = 0;
        this.uploader.progressTotal = 0;
    }

    /**
     * Upload all media
     */
    upload(): void {
        this.disabled = true;
        let isPicto = false;
        let isClaro = false;

        if (this.acl.MAESTRO_DAM_PICTO_CREATE === 1) {
            isPicto = $("#picto:checked").length === 1;
        }

        if (this.claroAvailable === true) {
            isClaro = $("#claro:checked").length === 1;
        }

        this._uploadService
            .uploadMedias(
                this.uploader.queue.map((fi) => fi.file),
                isPicto,
                isClaro
            )
            .pipe(map((r: any) => r.data))
            .subscribe(
                (data) => {
                    data = data.map((d) => d.name);
                    this.disabled = false;
                    this.uploader.queue.forEach((f) => {
                        if (data.includes(f.file.name)) {
                            f.progress = 100;
                            setTimeout(() => {
                                if (this.uploader.queue.includes(f)) {
                                    f ? f.remove() : null;
                                }
                            }, 3000);
                        }
                    });
                    this._toaster.show({ message: this._translate.instant("upload.done"), type: "success" });
                },
                () => {
                    this.disabled = false;
                }
            );
    }
}
