import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { PanelModule } from "primeng-lts/panel";
import { TabViewModule } from "primeng-lts/tabview";
import { DataviewModule } from "../../dataview/dataview.module";
import { FormMediaComponent } from "./form-media.component";

@NgModule({
    declarations: [FormMediaComponent],
    exports: [FormMediaComponent],
    imports: [SharedModule, PanelModule, TabViewModule, DataviewModule],
})
export class FormMediaModule {}
