<div class="modal fade" id="appTreeTagModal" tabindex="-1" role="dialog" aria-labelledby="appTreeTagModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="container">
                    <div class="row">
                        <button type="button" class="close text-right mb-1" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                    </div>
                    <div class="row">
                        <h3>
                            {{ 'massAction.object.tag' | translate }} {{ 'massAction.mass' | translate }} {{
                            'massAction.selection' | translate }} ( {{ dataLength }} ) :
        
                            <span *ngIf="massTagAdd" class="text-primary"><b>{{ 'massAction.mode.add' | translate }}</b></span>
                            <span *ngIf="!massTagAdd" class="text-danger"><b>{{ 'massAction.mode.remove' | translate }}</b></span>
                        </h3>
        
                        <button class="btn ml-auto" [ngClass]="massTagAdd ? 'btn-danger' : 'btn-primary'"
                            (click)="massTagAdd = !massTagAdd; massTagNode = []">
                            {{ 'massAction.mode.change' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <button type="button" class="btn btn-primary ml-3 mr-1 mb-1"
                    (click)="_treeService.expandCollapseAll('filterTreeTags', value)">
                    <span *ngIf="_treeService.nodesState['filterTreeTagsExpanded']">{{
                        'datatable.collapse'
                        |
                        translate }}</span>
                    <span *ngIf="!_treeService.nodesState['filterTreeTagsExpanded']">{{
                        'datatable.expand' |
                        translate }}</span>
                    </button>
                    <label class="ml-5 mt-1 mr-1" for="propagateSelectionUp">{{ 'general.propagate.selection.up' | translate }}</label>
                    <div>
                        <label class="switch switch-success mb-0" style="padding-top: 5px;">
                            <input id="propagateSelectionUp" type="checkbox" class="switch-input" [checked]="propagateSelectionUp" (change)="propagateSelectionUp = !propagateSelectionUp">
                            <span class="switch-slider"></span>
                        </label>
                    </div>

                    <label class="ml-5 mt-1 mr-1" for="propagateSelectionDown">{{ 'general.propagate.selection.down' | translate }}</label>
                    <div>
                        <label class="switch switch-success mb-0" style="padding-top: 5px;">
                            <input id="propagateSelectionDown" type="checkbox" class="switch-input" [checked]="propagateSelectionDown" (change)="propagateSelectionDown = !propagateSelectionDown">
                            <span class="switch-slider"></span>
                        </label>
                    </div>
                </div>
                <div>
                    <p-tree [value]="value" [selectionMode]="selectionMode" [(selection)]="massTagNode"
                        [filter]="filter" [filterMode]="filterMode" [propagateSelectionUp]="propagateSelectionUp"
                        [propagateSelectionDown]="propagateSelectionDown" [scrollHeight]="scrollHeight"
                        (onNodeSelect)="massTagSelection($event, massTagAdd)"
                        (onNodeUnselect)="massTagSelection($event, !massTagAdd)">
                    </p-tree>
                </div>
            </div>
        </div>
    </div>
</div>