import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { PageOptionTabService } from "app/core/services/project/export/page-option-tab.service";
import { ExportElement } from "app/shared/models/project/export-element.model";

@Component({
    selector: "app-page-option-tab",
    templateUrl: "./page-option-tab.component.html",
})
export class PageOptionTabComponent implements OnInit {
    @Input() product: ExportElement;

    private _exportId: number;
    private _pageId: number;

    constructor(
        private _pageOptionService: PageOptionTabService,
        private _route: ActivatedRoute,
        private _loader: SpinnerService,
        private _toaster: ToastService,
        private _translate: TranslateService
    ) {}

    ngOnInit() {
        this._exportId = this._route.parent.parent.parent.snapshot.params.id;
        this._pageId = this._route.snapshot.params.id;
        
    }

    updateValue(newValue: string, field: any): void {
        field.value = newValue;
        this._saveValue(field);
    }

    private _saveValue(field: any) {
        this._loader.disable();
        
        this._pageOptionService.saveOption(this._exportId, field.value, field.id, this._pageId, this._route.queryParams["_value"]["duplicate"], field.variableProject).subscribe(
            (data) => {
                this._loader.activate();
                this._toaster.show({
                    message: this._translate.instant("general.savedfield", { key: field.name }),
                    type: "success",
                });

                field.id = data.data;
            },
            () => {
                this._toaster.show({
                    message: this._translate.instant("general.nosavedfield", { key: field.name }),
                    type: "danger",
                });
            }
        );
    }

    checkIfNumber(event: any) {
        if (event.which !== 8 && isNaN(Number(String.fromCharCode(event.which)))) {
            event.preventDefault(); //stop character from entering input
        }
    }
}
