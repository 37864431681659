import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { UserService } from "app/core/services/admin/user/user.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { MaestroProjects } from "app/shared/models";
import { Observable } from "rxjs";

@Injectable()
export class ProjectListResolver implements Resolve<MaestroProjects> {
    constructor(private service: ProjectService, private _userService: UserService) {}

    resolve(): Observable<any> {
        return this._userService.getGroupsAndAssociated();
    }
}
