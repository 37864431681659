import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ChartCallback, ChartDataset, ChartOption, ChartTitle, ChartTooltip, MaestroBlocData, MaestroData } from "app/shared/models/dashboard";
import { Chart } from "node_modules/chart.js";

@Component({
    selector: "[app-dashboard-chart-level-2]",
    templateUrl: "./chart-level-2.component.html",
})
export class ChartLevel2Component implements OnInit {
    @Input() bloc: MaestroBlocData;
    labels: string[] = [];
    datasets: ChartDataset[] = [];
    data: number[] = [];
    options: ChartOption = {};

    @ViewChild("myChart", { static: true })
    myChart: ElementRef;
    chart: any;

    defaultColor: string = "rgba(255,255,255,0.9)";

    ngOnInit() {
        if (this.bloc && this.bloc.data) {
            this._getData();
        }
    }

    _getData() {
        const colors = []; // TODO : long term get colors cases from database

        for (const [key, value] of Object.entries(this.bloc.data)) {
            const tooltipCallbackData = [];

            for (const object of value) {
                this.labels.push(object.label);
                this.data.push(object.data);

                if (object.data <= 50) {
                    colors.push("rgba(255, 255, 255, 0.3)");
                } else if (object.data >= 51 && object.data <= 99) {
                    colors.push("rgba(255, 255, 255, 0.5)");
                } else {
                    colors.push("rgba(255, 255, 255, 0.7)");
                }

                const callbackData: MaestroData[] = [];

                // Tricky control to avoid to display tooltip with too much data like in pim which is cut (Pim length = x, Project length = actually  max 2)
                const tooMuchTooltipData = object.chartDatas && object.chartDatas.length > 2;

                if (!tooMuchTooltipData) {
                    for (const chartData of object.chartDatas) {
                        // TODO : add if tooltip.enabled = true when added to database model
                        callbackData.push(chartData);
                    }
                }

                tooltipCallbackData.push(callbackData);
            }

            const dataset: ChartDataset = {
                label: key,
                data: this.data,
                backgroundColor: colors,
                borderColor: colors,
            };

            this.datasets.push(dataset);
            this.data = [];

            this._getOption(tooltipCallbackData);
        }

        this.drawChart();
    }

    _getOption(tooltipCallbackData: any) {
        const callback: ChartCallback = {
            title: function (tooltipItem, data) {
                return `${tooltipItem[0]./*label*/ yLabel} : ${tooltipItem[0]./*value*/ xLabel}%`;
            },
            label: function (tooltipItem, data) {
                const extracted = [];
                for (const extract of tooltipCallbackData[tooltipItem["index"]]) {
                    if (false === extract.rate || !extract.hasOwnProperty("rate")) {
                        extracted.push(`${extract.label} : ${extract.data}`);
                    } else {
                        extracted.push(`${extract.label} : ${extract.data}%`);
                    }
                }
                return extracted;
            },
            // labelColor: function(tooltipItem, chart) {
            // TODO : find a way to pass different color .. actually color is shared ...
            // return {
            //   borderColor: 'rgb(255, 0, 0)',
            //   backgroundColor: 'rgb(255, 0, 0)',
            // };
            // return {
            //   borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
            //     Math.random() * 256
            //   )}, 0.5)`,
            //   backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
            //     Math.random() * 256
            //   )}, 0.5)`,
            // };
            // return (tooltip.callbacks.labelColor = { borderColor: 'rgb(255, 0, 0)', backgroundColor: 'rgb(255, 0, 0)' });
            // for (let extract of tooltipCallbackData[tooltipItem['index']]) {
            //   if (extract.data < 50) {
            //     return {
            //       borderColor: 'rgb(255, 0, 0)',
            //       backgroundColor: 'rgb(255, 0, 0)'
            //   };
            //   } else {
            //     return {
            //       borderColor: 'rgb(0, 255, 0)',
            //       backgroundColor: 'rgb(0, 255, 0)'
            //   };
            //   }
            // }
            // },
        };

        const tooltip: ChartTooltip = {
            callbacks: callback,
            displayColors: false,
            intersect: false,

            /*enabled: false, // Disable the on-canvas tooltip to use custom tooltip
            custom: function (tooltipModel: ChartCustomTooltip) { // Custom Tooltip
                var tooltipEl = document.getElementById('chartjs-tooltip'); // Tooltip Element

                if (!tooltipEl) {
                    tooltipEl = document.createElement('div'); // Create element on first render
                    tooltipEl.id = 'chartjs-tooltip';
                    var mainStyle = 'background-color: rgba(0, 0, 0, 0.8)';
                    mainStyle += '; color: white';
                    mainStyle += '; border-radius: 6px';
                    tooltipEl.innerHTML = '<table style="' + mainStyle + '"></table>';
                    document.body.appendChild(tooltipEl);
                }

                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = "0"; // Hide if no tooltip
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function (title) {
                        innerHtml += '<tr><th style="padding: 2px 4px">' + title + '</th></tr>'; // Data Title
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach(function (body, i) {
                        var colors = tooltipModel.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span style="' + style + '"></span>';
                        // innerHtml += '<tr><td>' + span + body + '</td></tr>';
                        body.forEach(element => {
                            innerHtml += '<tr><td style="padding: 0px 4px">' + span + element + '</td></tr>'; // Data
                        });
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                var position = this._chart.canvas.getBoundingClientRect(); // `this` will be the overall tooltip

                // Display, position, and set styles for font
                tooltipEl.style.opacity = "1";
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }*/
        };

        const title: ChartTitle = {
            display: true,
            text: this.bloc.name,
            fontColor: this.defaultColor,
            fontSize: 16,
        };

        const scales: any = {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        fontColor: this.defaultColor,
                        // TODO : find a way to display 100 as max value
                    },
                    gridLines: {
                        display: false,
                        color: this.defaultColor,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        fontColor: this.defaultColor,
                        // TODO : find a way to display 100 as max value
                    },
                    gridLines: {
                        display: false,
                        color: this.defaultColor,
                    },
                },
            ],
        };

        const option: ChartOption = {
            tooltips: tooltip,
            title: title,
            // TODO : add in model
            legend: {
                display: false,
            },
            // TODO : add in model
            scales: scales,
            responsive: true,
            maintainAspectRatio: false,
        };

        this.options = option;
    }

    drawChart() {
        const canvas = this.myChart.nativeElement.getContext("2d");
        this.chart = new Chart(canvas, {
            type: this.bloc.type as any,
            data: {
                labels: this.labels,
                datasets: this.datasets,
            },
            options: this.options,
        });
    }
}
