<div class="btn-group component-action-button">
    <ng-container *ngFor="let item of menu" [ngSwitch]="item.action">
        <button id="edit" *ngSwitchCase="4" class="btn btn-sm btn-outline-primary" (click)="menuClick.emit(item.action)" pTooltip="{{ item.title }}">
            <fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon>
        </button>

        <button id="update" *ngSwitchCase="5" class="btn btn-sm btn-outline-primary" (click)="menuClick.emit(item.action)" pTooltip="{{ item.title }}">
            <fa-icon [icon]="faTools" [fixedWidth]="true"></fa-icon>
        </button>

        <button id="delete" *ngSwitchCase="0" class="btn btn-sm btn-outline-danger" (click)="menuClick.emit(item.action)"pTooltip="{{ item.title }}">
            <fa-icon [icon]="faTrash" [fixedWidth]="true"></fa-icon>
        </button>
    </ng-container>
</div>