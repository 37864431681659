import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-history',
  templateUrl: './modal-history.component.html',
  styleUrls: ['./modal-history.component.scss']
})
export class ModalHistoryComponent implements OnInit {

  filterTypes: { id: number; name: string }[];

    filterTags: { id: number; name: string }[];
    objectKeys = Object.keys;

    @Input() history: any;
    @Input() service: any;
    @Input() objectId: any;
    @Input() start: any;

  constructor() { }

  ngOnInit(): void {
  }

  getMoreStory() {
    this.service.getStory(this.objectId, this.start).subscribe((data) => {
        if(data.type === "success")
        {
            this.history = this.history.concat(data.data.story);
            this.start += 10;
        }
    });
}

}
