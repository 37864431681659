import { NgModule } from "@angular/core";
import { WorkflowFormModule } from "./form/workflow-form.module";
import { AdminWorkflowComponent } from "./workflow.component";
import { AdminWorkflowRoutingModule } from "./workflow.routing";

@NgModule({
    declarations: [AdminWorkflowComponent],
    imports: [AdminWorkflowRoutingModule, WorkflowFormModule],
})
export class AdminWorkflowModule {}
