import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES as routes } from "app/shared/routes";
import { TwigCreateComponent } from "./create/twig-create.component";
import { TwigCreateGuard } from "./create/twig-create.guard";
import { TwigListComponent } from "./list/twig-list.component";
import { TwigListGuard } from "./list/twig-list.guard";




const twigRouting: Routes = [
    {
        path: "",
        component: TwigListComponent,
        canActivate: [TwigListGuard],
        resolve: { twigs: TwigListGuard },
    },{
        path: `:id/${routes.actions.update}`,
        component: TwigCreateComponent,
        canActivate: [TwigCreateGuard],
        resolve: { twig: TwigCreateGuard },
    },{
        path: `${routes.actions.create}`,
        component: TwigCreateComponent,
        canActivate: [TwigCreateGuard],
        resolve: { exportType: TwigCreateGuard },
    }
];

@NgModule({
    imports: [RouterModule.forChild(twigRouting)],
    exports: [RouterModule],
})
export class TwigRoutingModule {}
