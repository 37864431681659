import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faEdit, faEye, faEyeSlash, faSave, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlusCircle, faSort, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DataModelService } from "app/core/services/dam/datamodel.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { UploadService } from "app/core/services/upload/upload.service";
import { DamDataModel } from "app/shared/models/dam/data-model";
import { fieldTypes } from "app/shared/models/field-type.enum";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-update",
    templateUrl: "./update.component.html",
})
export class UpdateComponent implements OnInit {
    dataModel: DamDataModel;
    updateName: boolean;
    nameAtOpening: string;

    readonly faEdit = faEdit;
    readonly faClose = faTimes;
    readonly faSave = faSave;
    readonly faPlus = faPlusCircle;
    readonly faPreview = faEye;
    readonly faNoPreview = faEyeSlash;
    readonly faTrash = faTrashAlt;
    readonly faSort = faSort;

    readonly settings: IDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        allowSearchFilter: true,
        searchPlaceholderText: this._translate.instant("general.search"),
        enableCheckAll: false,
        noDataAvailablePlaceholderText: this._translate.instant("general.no.data"),
    };

    fieldTypes = fieldTypes;

    formArray: FormArray;

    /**
     * Sortable config for fields
     */
    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }
            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(this.formArray.controls, from, to);
            array_move(this.formArray.value, from, to);
            this.formArray.markAsDirty();
        }.bind(this),
    };

    private readonly _baseRoute = `/${MAESTRO_ROUTES.dam.base}/${MAESTRO_ROUTES.dam.elementType}/${MAESTRO_ROUTES.actions.update}`;

    private _ngUnsubscribe: Subject<void>;

    types = [];

    constructor(
        private _dataModelService: DataModelService,
        private _layout: LayoutService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _translate: TranslateService,
        private _toaster: ToastService,
        private _spinner: SpinnerService,
        private _modal: SwalModalService,
        private _fb: FormBuilder,
        private _uploadService: UploadService
    ) {
        this._ngUnsubscribe = new Subject();
        this.updateName = false;
    }

    ngOnInit(): void {
        const authorizedFieldTypes = ["text", "number", "date", "checkbox"];
        this.fieldTypes = this.fieldTypes
            .filter((ft) => authorizedFieldTypes.includes(ft))
            .sort((a, b) => this._translate.instant("fieldType." + a).localeCompare(this._translate.instant("fieldType." + b)));

        /**
         * Get data from the resolver
         */
        this._route.data.pipe(takeUntil(this._ngUnsubscribe)).subscribe((data: { resources: DamDataModel }) => {
            this.dataModel = data.resources;
            this.nameAtOpening = this.dataModel.name;
            this._initData();

            this._layout.breadcrumb.setPath({ routerLink: "/dam/datamodel", name: "pim.elementTypes.title" }, 1);
            this._layout.breadcrumb.setPath({ routerLink: null, name: this.dataModel.name }, 2);

            this._uploadService.getMediaTypes(this.dataModel.id).subscribe((types) => {
                this.types = types.map((t) => ({ id: t.id, name: t.extension, isDisabled: t.isDisabled }));
            });
        });
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
        this._layout.breadcrumb.setPath(null, 2);
        this._layout.sidebar.enable = false;
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
    }

    /**
     * Save name of the data model
     */
    saveName() {
        this._spinner.disable();
        this._dataModelService.changeName(this.dataModel.id, this.dataModel.name).subscribe((data) => {
            this.updateName = false;
            this.nameAtOpening = this.dataModel.name;
            this._layout.breadcrumb.setPath({ routerLink: null, name: this.dataModel.name }, 2);
            this._toaster.show({
                message: this._translate.instant("pim.elementTypes.elementTypeNameSaved"),
                type: "success",
            });
            this._spinner.activate();
        });
    }

    /**
     * Delete a field
     *
     * @param formArray
     * @param index
     */
    deleteField(index: number): void {
        this._modal.delete().then((result) => {
            if (result.value) {
                this.formArray.removeAt(index);
                this.saveTab();
            }
        });
    }

    setAllPreview(): void {
        this.formArray.controls.forEach((c: FormGroup) => {
            c.controls.previewable.setValue(true);
        });
        this.formArray.markAsDirty();
    }

    setAllNoPreview(): void {
        this.formArray.controls.forEach((c: FormGroup) => {
            c.controls.previewable.setValue(false);
        });
        this.formArray.markAsDirty();
    }

    /**
     * Add a new field
     */
    addField(): void {
        const formGroup = this._fb.group({
            id: [-1, Validators.required],
            name: ["", [Validators.required]],
            fieldType: ["", Validators.required],
            previewable: [false],
        });

        this.formArray.push(formGroup);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
    }

    /**
     * Save the current tab
     * @returns
     */
    saveTab(): void {
        if (this.formArray.invalid) {
            return;
        }

        const fields = [];
        this.formArray.controls.forEach((formGroup: FormGroup) => {
            const object = {
                FieldId: formGroup.controls.id.value,
                Nom: formGroup.controls.name.value.trim(),
                Preview: formGroup.controls.previewable.value,
                "Type de champ": formGroup.controls.fieldType.value,
            };

            fields.push(object);
        });
        const payload = {
            model: {
                "Contener Field": fields,
            },
        };

        this._dataModelService.updateDataModel(this.dataModel.id, payload).subscribe(() => {
            this._dataModelService
                .getById(this.dataModel.id)
                .pipe(map((r) => r.data))
                .subscribe((dataModel) => {
                    this.dataModel = dataModel;
                    this._initData();
                });
        });
    }

    private _initData(): void {
        this.formArray = this._fb.array([]);

        this.dataModel.fields.forEach((field) => {
            this.formArray.push(
                this._fb.group({
                    id: [field.id, Validators.required],
                    name: [field.name, [Validators.required]],
                    fieldType: [field.type, Validators.required],
                    previewable: [field.preview],
                })
            );
        });
    }

    /**
     * Set extension for the datamodel
     */
    setTypes(): void {
        this._spinner.disable();
        this._dataModelService
            .setTypes(
                this.dataModel.id,
                this.dataModel.types.map((t) => t.id)
            )
            .subscribe(() => {
                this._toaster.show({ message: this._translate.instant("dam.extension.saved"), type: "success" });
                this._spinner.activate();
            });
    }

    isOnePreview(): boolean {
        return this.formArray.controls.map((fg: FormGroup) => fg.value.previewable as boolean).some((previewable) => previewable);
    }

    isOneNoPreview(): boolean {
        return this.formArray.controls.map((fg: FormGroup) => fg.value.previewable as boolean).some((previewable) => !previewable);
    }
}
