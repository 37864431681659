import { Injectable } from "@angular/core";
import { TemplateService } from "app/core/services/project/template/template.service";
import { MaestroElements, MaestroElementType, MaestroExports } from "app/shared/models";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments";
import { ExportConfigurationElementTypes } from "../../../../shared/models/project/export-configuration";

@Injectable()
export class ExportsConfigurationService extends TemplateService {
    /**
     * Get configuration for step 3 (export)
     */
    getConfiguration(projectId: number): Observable<ExportConfigurationElementTypes> {
        return this._http.get<ExportConfigurationElementTypes>(`${environment.projectsUrl}/project/${projectId}/exports.json`).pipe(catchError((_) => of([])));
    }

    /**
     * Save export configuration
     *
     * @param projectId
     * @param body
     * @returns
     */
    saveConfiguration(projectId: number, body: any) {
        return this._http.put(`${environment.projectsUrl}/project/${projectId}/exports.json`, body);
    }

    /**
     * Delete an export
     *
     * @param exportId
     * @returns
     */
    deleteConfiguration(exportId: number) {
        return this._http.delete(`${environment.projectsUrl}/export/${exportId}.json`);
    }

    /**
     * Get elements selected in step 2 (elements)
     * @param id
     * @returns
     */
    getElements(id: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/project/${id}/elements.json`).pipe(
            map((response: any) => {
                const elements = [];
                response.data.forEach((data: { type: MaestroElementType; elements: MaestroElements }) => {
                    elements.push(...data.elements.map((el) => ({ ...el, type: data.type })));
                });
                return elements;
            })
        );
    }

    /**
     * Get export types for a project
     * @param id
     * @returns
     */
    getExportTypesById(id: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/project/${id}/exports_type.json`).pipe(catchError((_) => of([])));
    }
}
