import { Component, Input, OnInit } from "@angular/core";
import { DataService } from "app/core/services/global/data/data.service";


@Component({
    selector: "app-view-tab-value",
    templateUrl: "./view-tab-value.component.html",
})
export class ViewTabValueComponent implements OnInit {
    @Input() data: any;
    constructor(private _dataService: DataService) { }

    ngOnInit() { }

    getCollections(value: string){
        return JSON.parse(value);
    }

     getList(input){
        let result = "";
        let list = input["list"].map(listValue => listValue["id"]);
        if(!input.multiple){
    
            let index = list.findIndex(listValue => listValue == input.elementValue);
            if(index != -1){
                result = list[index].name;
            }
        }else {
            input.elementValue.forEach(val => {
                let index = list.findIndex(listValue => listValue == val);
                if(result != ""){
                    result += ", ";
                }
                if(index != -1){
                    result += list[index].name;
                }
            })
        }
        
        return result;
     }

     getProductLink(elements){
        let result = "";
        
        if(elements.length > 0){
            let products = elements.map(element => element.name);

            products.forEach(product =>  {
                if(result != ""){
                    result += ", ";
                }
                result += product;
                
            })
        }

        return result;
     }
     getListcascadeValue(listcascade){       
        let result = "";
        if(listcascade.elementValue.length > 0){
            listcascade.elementValue.forEach(valueList => {
                if(valueList["selected"]){
                    if(result == ""){
                        result += valueList['name'];
                    } else {
                        result += ", "+valueList['name'];
                    }
                }
            });
        }
        return result;
     }
}
