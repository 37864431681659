import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EditFormComponent } from "app/shared/components/dam-metadata/edit-form.component";
import { EditFormResolver } from "app/shared/components/dam-metadata/edit-form.resolver";
import { MAESTRO_ROUTES as routes } from "app/shared/routes";
import { TagModule } from "../tag/tag.module";
import { DamComponent } from "./dam.component";
import { DataModelModule } from "./features/data-model/data-model.module";
import { ThumbsnailContainerComponent } from "./features/thumbsnail-container/thumbsnail-container.component";
import { ThumbsnailContainerResolver } from "./features/thumbsnail-container/thumbsnail-container.resolver";
const damRoutes: Routes = [
    {
        path: "",
        component: DamComponent,
        children: [
            {
                path: `${routes.dam.list}`,
                component: ThumbsnailContainerComponent,
                resolve: { resources: ThumbsnailContainerResolver },
            },
            {
                path: `${routes.dam.media}/${routes.dam.editForm}/:id`,
                component: EditFormComponent,
                resolve: { resources: EditFormResolver },
            },
            { path: routes.dam.elementType, loadChildren: () => DataModelModule },
            { path: routes.dam.tag, loadChildren: () => TagModule },
            { path: "", redirectTo: routes.dam.list, pathMatch: "full" },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(damRoutes)],
    exports: [RouterModule],
})
export class DamRoutingModule {}
