import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ChipsModule } from "primeng-lts/chips";
import { ElementListComponent } from "./element-list.component";
import { ElementListGuard } from "./element-list.guard";
import { ElementListResolver } from "./element-list.resolver";
import { ListPreviewComponent } from "./list-preview/list-preview.component";
import { BasePreviewComponent } from "./list-preview/preview-element/base-preview/base-preview.component";
import { FieldsetComponent } from "./list-preview/preview-element/fieldset/fieldset.component";
import { PreviewElementComponent } from "./list-preview/preview-element/preview-element.component";

@NgModule({
    imports: [SharedModule, SharedComponentsModule, ChipsModule],
    declarations: [ElementListComponent, PreviewElementComponent, ListPreviewComponent, FieldsetComponent, BasePreviewComponent],
    providers: [ElementListGuard, ElementListResolver],
})
export class ElementListModule {}
