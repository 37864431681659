import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { WorkflowFormComponent } from "./workflow-form.component";

@NgModule({
    declarations: [WorkflowFormComponent],
    exports: [WorkflowFormComponent],
    imports: [SharedModule],
})
export class WorkflowFormModule {}
