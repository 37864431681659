import { Component, OnInit } from "@angular/core";

import { MAESTRO_ROUTES } from "app/shared/routes/routes";
import { activatedModules } from "src/environments/activated-modules";

@Component({
    selector: "app-not-found",
    templateUrl: "./not-found.component.html",
})
export class NotFoundComponent implements OnInit {
    readonly routes = MAESTRO_ROUTES;

    activatedModules = activatedModules;

    constructor() {}

    ngOnInit() {}
}
