import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ElementTypeListComponent } from "./element-type-list.component";
import { ElementTypeListGuard } from "./element-type-list.guard";
import { ElementTypeListResolver } from "./element-type-list.resolver";

@NgModule({
    imports: [SharedModule, SharedComponentsModule],
    declarations: [ElementTypeListComponent],
    providers: [ElementTypeListGuard, ElementTypeListResolver],
})
export class ElementTypeListModule {}
