import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class ListCascadeService {
    filterSearch: BehaviorSubject<string> = new BehaviorSubject("");
    
    constructor(private _http: HttpClient) {}

    /**
     * Get lists
     * @returns
     */
    getListCascades(): Observable<any> {
        return this._http.get(`${environment.pimUrl}/listcascades.json`);
    }

    /**
     * Get list by id
     * @param id
     * @returns
     */
    getListCascade(id: number): Observable<any> {
        return this._http.get(`${environment.pimUrl}/listcascade/${id}.json`);
    }

    /**
     * Create new List
     * @param listName
     * @returns
     */
    createListCascade(listName: string): Observable<{ id: number }> {
        const formData = new FormData();
        formData.append("name", listName);
        return this._http.post<{ id: number }>(`${environment.pimUrl}/listcascade/create.json`, formData);
    }

    updateListCascade(id: number, listValues: any, lastId: number, name: string){      
       
        return this._http.post<any>(`${environment.pimUrl}/listcascade/${id}.json`, {
            listValues: listValues,
            lastId: lastId,
            name: name
        })
    }

    deleteListCascade(id: number){
        return this._http.delete<any>(`${environment.pimUrl}/listcascade/${id}.json`);
    }
}
