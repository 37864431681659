export interface Step<T> {
    id: number;
    data: T;
    title: string;
    state: StepState;
    valid: boolean;
    // auth: string;
}

export type Steps<T> = Step<T>[];

export enum StepState {
    Disabled,
    Enabled,
    Current,
}
