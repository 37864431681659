import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { DashboardComponent } from "./dashboard.component";
import { DashboardRoutingModule } from "./dashboard.routing";
import { DashboardViewModule } from "./features/dashboard-view/dashboard-view.module";

@NgModule({
    imports: [DashboardRoutingModule, DashboardViewModule, SharedModule],
    declarations: [DashboardComponent],
})
export class DashboardModule {}
