<div class="card mb-2 p-0">
    <div class="card-header text-center d-flex">
        <ng-container *ngIf="!updateName">
            <h4 class="font-weight-bold text-primary mb-0 pb-0 mx-auto">{{ this.list.name }}</h4>
            <fa-icon container="body"  [ngbTooltip]="'pim.actions.edit'| translate" (click)="updateName = true" class="text-warning font-lg pointer" [icon]="faEdit"></fa-icon>
        </ng-container>
        <ng-container *ngIf="updateName">
            <input class="form-control form-control-sm col-9 col-md-4 mx-auto text-center font-weight-bolder" type="text" [(ngModel)]="this.list.name">
            <fa-icon *ngIf="list.name !== nameAtTheOpening" (click)="saveName()" class="text-primary font-lg pointer" [icon]="faSave"></fa-icon>
            <fa-icon (click)="updateName = false" class="text-danger font-lg pointer ml-2" [icon]="faClose"></fa-icon>
        </ng-container>
    </div>
    <div class="card-body p-0">
        <form [formGroup]="formArray">
            <table class="table table-striped mb-0">
                <thead>
                    <tr>
                        <th style="border: 0;" class="text-center" scope="col">{{ "pim.lists.value" | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ "table.actions" | translate }}</th>
                    </tr>
                </thead>
                <tbody [appSortable]="sortableJSOptions">
                    <tr *ngFor="let control of formArray.controls; let i = index">
                        <td class="col-10">
                            <input class="form-control" [ngClass]="control.controls.name.valid ? 'is-valid' : 'is-invalid'" type="text" [formControl]="control.controls.name">
                            <div *ngIf="control.controls.name.invalid && !control.controls.name.pristine" class="invalid-feedback">
                                <span *ngIf="control.controls.name.hasError('required')">{{ "pim.lists.error.required" | translate }}</span>
                                <span *ngIf="control.controls.name.hasError('uniqueValue')">{{ "pim.lists.error.unique" | translate }}</span>
                            </div>
                        </td>
                        <td class="d-flex justify-content-center">
                            <button [disabled]="disableListFirstButton()" [ngbTooltip]="'tooltip.delete'| translate" class="btn btn-danger" (click)="deleteField(i)">
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                            <button [ngbTooltip]="'tooltip.move'| translate"  class="btn btn-primary ml-1 sortable">
                                <fa-icon [icon]="faSort"></fa-icon>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="formArray.controls.length === 0">
                        <td colspan="2" class="text-center font-weight-bold">{{ "general.no.data" | translate}}</td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="btn btn-primary mr-2 ml-auto" (click)="sortFields()">
                <fa-icon [icon]="faSortAlphaDown"></fa-icon>
                <span class="d-none d-md-inline-block ml-1">{{ 'general.sort' | translate }}</span>
            </div>
            <div class="btn-group">
                <button class="btn btn-primary" (click)="addField()">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.field.add' | translate }}</span>
                </button>
                <button [disabled]="formArray.invalid || formArray.controls.length === 0 || formArray.pristine" class="btn btn-primary" (click)="saveList()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
    <!-- <button (click)="addHeader()">Add Header</button> -->
    <!-- <div *ngFor="let header of headercascade; let i = index" >
        {{ header }}
    </div> -->
    
</div>

<ng-template #list let-list="list" let-ids="ids">
    <select>
        <option *ngFor="let value of list;let i = index">{{ value.name }}</option>
        
    </select>
    <button (click)="addValue(ids, 'test')">new val</button>
</ng-template>
