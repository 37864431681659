// @TODO : Remove this component when datatable.component will be full implemented

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { faAlignJustify, faChevronLeft, faChevronRight, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "app/core/services/pim/home.service";
import { MaestroElements } from "app/shared/models";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { ActionEvent, ActionType } from "../action-buttons/action";

@Component({
    selector: "app-table",
    templateUrl: "./table.component.html",
})
/**
 * need to create a real component were we can define what kind of cells we need for each columns
 * we can also use à lib like ag-grid
 */
export class TableComponent {
    @Input() table: [string, string][];
    @Input() tableTitle: string;
    @Input() columns: string[];
    @Input() actions: ActionType[];
    @Input() preview: MaestroElements;
    @Input() hideViewButtonIfEditAllowed = false;
    @Input() type: string;
    @Output() action: EventEmitter<ActionEvent> = new EventEmitter();
    // @Output() selectPreview: EventEmitter<number[]> = new EventEmitter();
    @Output() select: EventEmitter<number> = new EventEmitter();

    elementIds: number[] = [];

    readonly faChevronRight = faChevronRight;
    readonly faChevronLeft = faChevronLeft;
    readonly faAlignJustify: IconDefinition = faAlignJustify;
    readonly rowsPerPageOptions: number[] = [5, 10, 15, 20, 30];

    constructor(private sanitizer: DomSanitizer, private _translateService: TranslateService, private _homeService: HomeService, private _elementTableDataPipe: TableDataPipe) {}

    onActionClick(action: ActionType, rowId: number) {
        this.action.emit({ action, rowId });
    }

    onSelect(id: number) {
        this.select.emit(id);
    }

    safeHtml(value: string): SafeHtml {
        if (value === "workflowEnd") {
            value = this._translateService.instant("workflowEnd");
        }
        if (value === null) {
            value = "";
        }
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    /**
     * Let to not display action button when there is "contradictory" information (like a created and deleted element in same time)
     * @param row
     * @returns
     */
    controlActionShow(row: any): boolean {
        let result: boolean = false;

        if ((!this.preview || this.preview.length === 0) && row[1][2] !== "delete") {
            const id = row[0];

            const occurences = this.table.filter((d) => id === d[0]);

            const found = occurences.find((d) => "delete" === d[1][2]);

            if (!found) {
                result = !result;
            }
        }

        return result;
    }

    /**
     * Change the number of displayed data
     * @param event
     */
    loadData(event: any): void {
        switch (this.type) {
            case "lastActions":
                this._homeService.lastAction(event.value).subscribe((res) => {
                    this.table = this._elementTableDataPipe.transform(res.data)[1];
                });
                break;
            case "lastUpdates":
                this._homeService.lastUpdates(event.value).subscribe((res) => {
                    this.table = this._elementTableDataPipe.transform(res.data)[1];
                });
                break;
            case "lastWorkflows":
                this._homeService.workflow(event.value).subscribe((res) => {
                    this.table = this._homeService.manageWorkflowData(res.data);
                });
                break;
        }
    }
}
