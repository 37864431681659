import { Component, Input, OnChanges, SimpleChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DropDownItems } from "./drop-down";
import { DROPOWN_SETTINGS } from "./settings";


@Component({
    selector: "app-drop-down",
    templateUrl: "./drop-down.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropDownComponent),
            multi: true,
        },
    ],
})
export class DropDownComponent implements ControlValueAccessor, OnChanges {
    @Input() type: "multi" | "single" | "custom";
    @Input() settings: any = DROPOWN_SETTINGS.single;
    @Input() placeholder = this.translate.instant("general.select");
    @Input() list = [];
    @Input() selected: [];

    selectedItems: DropDownItems;
    @Input() disabled: boolean;
    

    private readonly _singleSettings = DROPOWN_SETTINGS.single;
    private readonly _multiSettings = DROPOWN_SETTINGS.multiple;
    readonly _onChange: Array<(_: any) => void> = [];
    readonly _onTouched: Array<() => void> = [];

    constructor(private translate: TranslateService) {}

    ngOnChanges(changes: SimpleChanges): void {
        this._initSettings();
        for (let propNames in changes){
            let change = changes[propNames];
            if(Array.isArray(change.currentValue)){
                this.selectedItems = this.selected ? this.selected : (change && change.currentValue ? change.currentValue : []);
            }

        }
        
        if (changes.list && this.list && this.selectedItems) {
            this.selectedItems = this._checkSelectedItems(this.selectedItems);
        }
        
    }

    onChange(newValue) {
        this._onChange.forEach((cb) => {
            cb(newValue);
        });
        
        
    }

    writeValue(value: DropDownItems): void {
        this.selectedItems = this.list ? this._checkSelectedItems(value || []) : value;
        if (!this.selectedItems || (this.selectedItems.length <= 0)) {
            this.selectedItems = [];
        }
        
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange.push(fn);
    }
    registerOnTouched(fn: () => void): void {
        this._onTouched.push(fn);
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private _initSettings() {
        switch (this.type) {
            case "multi":
                this.settings = this._multiSettings;
                break;
            case "single":
                this.settings = this._singleSettings;
                break;
            case "custom":
                break;
            default:
                this.settings = this._singleSettings;
                break;
        }
        this.settings.searchPlaceholderText = this.translate.instant("general.search");
        this.settings.selectAllText = this.translate.instant("general.select_all");
        this.settings.unSelectAllText = this.translate.instant("general.unselect_all");

        this.settings.noDataAvailablePlaceholderText = this.translate.instant("general.no.data");
    }

    /**
     * utils => service
     */

    private _checkSelectedItems(items: DropDownItems = []): DropDownItems {
        
        if (items.length === 1 && (items[0] === null || items[0] === undefined)) {
            items = [];
        }
        
        let select = [];

        items.forEach((item) => {
            item = this.list.find((listItem) => {
                if ((item as any).version) {
                    return (item as any).version === listItem.version;
                } else if (item.id) {
                    return item.id === listItem.id;
                } else {
                    return item.name === listItem.name;
                }
            });
            if(item){
                select.push(item);
            }
        })

        return select;
        
    }
}
