import { NgModule } from "@angular/core";
import { SidebarButtonDirective } from "app/shared/directives/sidebar-button.directive";
import { ActionRightDirective } from "./action-right.directive";
import { DebounceClickDirective } from "./debounce-click.directive";
import { DebounceInputDirective } from "./debounce-input.directive";
import { SortableDirective } from "./sortable.directive";
import { SortableSectionDirective } from "./sortable-section.directive";
const directives = [ActionRightDirective, DebounceClickDirective, DebounceInputDirective, SidebarButtonDirective, SortableDirective,SortableSectionDirective];

@NgModule({
    declarations: [...directives],
    exports: [...directives],
})
export class DirectivesModule {}
