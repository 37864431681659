import { Injectable } from "@angular/core";
import { TreeNode } from "primeng-lts/api";

@Injectable()
export class TreeService {
    public nodesState = {}; // Contains all nodes state to manage interface

    constructor() {}

    /**
     * Recursive function that create an array of TreeNode object by replacing wrong key like 'name' by the good one like 'label'
     * @param data
     * @param oldKey
     * @param newKey
     * @returns
     */
    createTreeNode(data: any, oldKey: string, newKey: string, removeOldKey: boolean = false): TreeNode[] {
        let newData = [];

        data.forEach((element) => {
            if (element.hasOwnProperty(oldKey)) {
                element[newKey] = element[oldKey];

                if (removeOldKey) {
                    delete element[oldKey];
                }
            }

            element["key"] = element[newKey].toLowerCase().trim();

            if (element.hasOwnProperty("children") && null !== element.children && element.children.length) {
                this.createTreeNode(element.children, oldKey, newKey);
            }

            newData.push(element);
        });

        return newData;
    }

    /**
     * Manage nodesState variable
     * @param filterList
     */
    expandCollapseAll(filterList: string, filters: any): void {
        const propertyName = filterList + "Expanded";
        let propertyValue = true;

        if (this.nodesState[propertyName]) {
            propertyValue = !this.nodesState[propertyName];
        }

        this.nodesState[propertyName] = propertyValue;

        filters.forEach((node) => {
            this.expandRecursive(node, propertyValue);
        });
    }

    /**
     * Expand or collapse nodes recursively
     * @param node
     * @param isExpand
     */
    expandRecursive(node: TreeNode, isExpand: boolean): void {
        node.expanded = isExpand;

        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    /**
     * Find nested node by id
     * @param filters
     * @param filterId
     * @returns
     */
    findRecursive(filters: any[], filterId: number) {
        return filters.reduce((previousFilter, currentFilter) => {
            let data = null;

            if (previousFilter) {
                data = previousFilter;
            } else if (currentFilter.id === filterId) {
                data = currentFilter;
            } else if (currentFilter.hasOwnProperty("children") && null !== currentFilter.children && currentFilter.children.length) {
                data = this.findRecursive(currentFilter.children, filterId);
            }

            return data;
        }, null);
    }

    getNestedData(data: any, keySearch: string, keyData: string | null = null) {
        let nestedData = [];

        if (undefined !== data && null !== data) {
            data.forEach((d) => {
                if (null !== keyData && undefined !== d[keyData]) {
                    nestedData.push(d[keyData]);
                } else {
                    nestedData.push(d);
                }

                if (undefined !== d[keySearch] && null !== d[keySearch]) {
                    const arr = Array.isArray(d[keySearch]) ? d[keySearch] : [d[keySearch]];
                    let subData = this.getNestedData(arr, keySearch, keyData);

                    if (subData.length) {
                        nestedData = [...nestedData, ...subData];
                    }
                }
            });
        }

        return nestedData;
    }

    deepCopy(src) {
        let target = Array.isArray(src) ? [] : {};
        for (let key in src) {
          let v = src[key];
          if (v) {
            if (typeof v === "object") {
              target[key] = this.deepCopy(v);
            } else {
              target[key] = v;
            }
          } else {
            target[key] = v;
          }
        }
      
        return target;
    }
}
