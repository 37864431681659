import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "app/core/services/global/data/data.service";
import { ElementService } from "app/core/services/pim/element.service";
import { environment } from "environments/environment";

@Component({
    selector: "app-panel-progress-bar",
    templateUrl: "./panel-progress-bar.component.html",
})
export class PanelProgressBarComponent implements OnInit {
    @Input() element: any;
    @Input() key: string;

    translationKey: string;

    indicators = {
        tabs: [], // Contains the whole type (mandatory, salabilityIndicator) fields tree
        tabsCount: 0, // Number of tabs containing type (mandatory, salabilityIndicator) fields
        sectionsCount: 0, // Number of sections containing type (mandatory, salabilityIndicator) fields
        fieldsCount: 0, // Number of type (mandatory, salabilityIndicator) fields
        inputsCount: 0, // Number of type (mandatory, salabilityIndicator) inputs + sub inputs
        emptyFieldsCount: 0, // Number of empty type (mandatory, salabilityIndicator) fields
        emptyInputsCount: 0, // Number of empty type (mandatory, salabilityIndicator) inputs + sub inputs
    };

    indicatorsMinified = [];

    showCompleteSalabilityIndicatorsInterface: boolean = environment.showCompleteSalabilityIndicatorsInterface;
    showMinifiedSalabilityIndicatorsInterface: boolean = environment.showMinifiedSalabilityIndicatorsInterface;
    displayMandatoryIndicator: boolean = environment.displayMandatoryIndicator;
    displaySalabilityIndicator: boolean = environment.displaySalabilityIndicator;

    constructor(private _translate: TranslateService, private _dataService: DataService, private _elementService: ElementService) {}

    ngOnInit(): void {
        this.translationKey = this.key === "salabilityIndicator" ? "salability" : this.key;
        this._initIndicators();

        this._elementService.eventIndicators$.subscribe((data) => {
            this.updateIndicators(data);
        });
    }

    _initIndicators() {
        this.element.tabs.forEach((tab: any) => {
            if ("media" !== tab.id && tab.fields.length && !Array.isArray(tab.fields[0])) {
                this.indicators.tabs[this.indicators.tabsCount] = {
                    name: tab.name,
                    indicator: 0,
                    total: 0,
                    sections: [],
                };

                if (!tab.fields[0].hasOwnProperty("sections")) {
                    tab.fields = [{ sections: { [this._translate.instant("general.unnamedSection")]: tab.fields } }];
                }

                tab.fields.forEach((obj) => {
                    Object.entries(obj.sections).map(([name, fields]: any) => {
                        this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount] = {
                            name: name,
                            indicator: 0,
                            total: 0,
                            fields: [],
                        };

                        fields.forEach((field: any) => {
                            if (!Array.isArray(field) && field.hasOwnProperty("id")) {
                                if (field.hasOwnProperty(this.key) && field[this.key]) {
                                    const isEmpty = this.switchType(field);

                                    if (!("fieldset" === field.fieldType)) {
                                        let val = isEmpty
                                            ? "date" === field.fieldType || "colors" === field.fieldType || "table" === field.fieldType
                                                ? this.getFieldValue(field, "field")
                                                : null
                                            : this.getFieldValue(field, "field");

                                        this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields /*[this.indicators.fieldsCount] =*/
                                            .push({
                                                name: field.name,
                                                protocol: field.protocol,
                                                value: val,
                                                type: field.fieldType,
                                                isEmpty: isEmpty,
                                            });

                                        if (isEmpty) {
                                            this.indicators.tabs[this.indicators.tabsCount].indicator++;
                                            this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].indicator++;
                                        }
                                    }

                                    if ("fieldset" === field.fieldType) {
                                        if (field.collections.length) {
                                            // Only check inputs
                                            let index = this.indicators.fieldsCount; //> 0 ? this.indicators.fieldsCount /*- 1*/ : 0;
                                            let fieldInputsTotal = 0;
                                            // let collectionIndex = 1; // Search "cominfos"

                                            let mergedFieldCollectionInputValues = this.getMergedCollectionInputValues(field.collections, "input");
                                            field.collections.forEach((collection) => {
                                                let collectionInputs = [];

                                                collection.elementValueView.forEach((input) => {
                                                    if (input.hasOwnProperty(this.key) && input[this.key]) {
                                                        const isEmpty = this.switchType(input, "input");
                                                        if (undefined === this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index]) {
                                                            this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index] = {
                                                                name: field.name,
                                                                protocol: field.protocol,
                                                                indicator: 0,
                                                                total: 0,
                                                                collections: [],
                                                                colIndicator: 0,
                                                                value: isEmpty ? null : this.getFieldValue(field, "field"),
                                                                concatColVal: mergedFieldCollectionInputValues,
                                                                type: field.fieldType,
                                                                isEmpty: isEmpty,
                                                            };
                                                        }

                                                        if (!("fieldset" === input.fieldType)) {
                                                            collectionInputs.push({
                                                                name: input.input,
                                                                // protocol: input.protocol, // Not provided by response,
                                                                // collectionIndex: collectionIndex, // Search "cominfos"
                                                                value: isEmpty ? ("date" === input.fieldType ? this.getFieldValue(input, "input") : null) : this.getFieldValue(input, "input"),
                                                                type: input.fieldType,
                                                                isEmpty: isEmpty,
                                                            });

                                                            if (isEmpty) {
                                                                this.indicators.tabs[this.indicators.tabsCount].indicator++;
                                                                this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].indicator++;
                                                                undefined !== this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].indicator
                                                                    ? this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].indicator++
                                                                    : (this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].indicator = 1);
                                                            }
                                                        }

                                                        this.indicators.inputsCount++;
                                                        this.indicators.tabs[this.indicators.tabsCount].total++;
                                                        this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].total++;
                                                        fieldInputsTotal++;

                                                        if ("fieldset" === input.fieldType) {
                                                            // SUBFIELDSET
                                                            if (input.value && input.value.length) {
                                                                let fieldSubInputsTotal = 0;
                                                                let fieldSubInputsIndicator = 0;
                                                                let subCollectionInputs = [];
                                                                // let subCollectionIndex = 1; // Search "cominfos"

                                                                if (undefined === this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index]) {
                                                                    // Will add field every time we enter in subfieldset
                                                                    this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index] = {
                                                                        name: field.name,
                                                                        protocol: field.protocol,
                                                                        indicator: 0,
                                                                        total: 0,
                                                                        collections: [],
                                                                        colIndicator: 0,
                                                                        value: isEmpty ? null : this.getFieldValue(field, "field"),
                                                                        type: field.fieldType,
                                                                        isEmpty: isEmpty,
                                                                    };
                                                                }

                                                                input.value.forEach((subCollection) => {
                                                                    let subInputs = [];

                                                                    subCollection.forEach((subInput) => {
                                                                        if (subInput.hasOwnProperty(this.key) && subInput[this.key]) {
                                                                            const isEmpty = this.switchType(subInput, "subInput");

                                                                            subInputs.push({
                                                                                name: subInput.name,
                                                                                // protocol: subInput.protocol, // Not provided by response,
                                                                                // subCollectionIndex: subCollectionIndex,
                                                                                value: isEmpty
                                                                                    ? "date" === subInput.fieldType
                                                                                        ? this.getFieldValue(subInput, "subInput")
                                                                                        : null
                                                                                    : this.getFieldValue(subInput, "subInput"),
                                                                                type: subInput.fieldType,
                                                                                isEmpty: isEmpty,
                                                                            });

                                                                            if (isEmpty) {
                                                                                this.indicators.tabs[this.indicators.tabsCount].indicator++;
                                                                                this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].indicator++;

                                                                                undefined !==
                                                                                this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].indicator
                                                                                    ? this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].indicator++
                                                                                    : (this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[
                                                                                          index
                                                                                      ].indicator = 1);
                                                                                fieldSubInputsIndicator++;
                                                                            }

                                                                            this.indicators.inputsCount++;
                                                                            this.indicators.tabs[this.indicators.tabsCount].total++;
                                                                            this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].total++;
                                                                            fieldSubInputsTotal++;
                                                                            fieldInputsTotal++;
                                                                        }
                                                                    });

                                                                    subCollectionInputs.push(subInputs);
                                                                    // subCollectionIndex++; Search "cominfos"
                                                                });

                                                                collectionInputs.push({
                                                                    name: input.input,
                                                                    // protocol: field.protocol,
                                                                    indicator: fieldSubInputsIndicator,
                                                                    total: fieldSubInputsTotal,
                                                                    collections: subCollectionInputs,
                                                                    colIndicator: subCollectionInputs.length,
                                                                    value: isEmpty ? null : this.getFieldValue(input, "input"),
                                                                    concatColVal: this.getMergedCollectionInputValues(input.value, "subInput"),
                                                                    type: input.fieldType,
                                                                    isEmpty: isEmpty,
                                                                });
                                                            } else {
                                                                this.indicators.tabs[this.indicators.tabsCount].indicator++;
                                                                this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].indicator++;
                                                                this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].indicator++;

                                                                let subInputs = [];

                                                                input.inputs.forEach((subInput) => {
                                                                    if (subInput.hasOwnProperty(this.key) && subInput[this.key]) {
                                                                        subInputs.push({
                                                                            name: subInput.name,
                                                                            value: null,
                                                                            type: subInput.fieldType,
                                                                            isEmpty: true,
                                                                        });
                                                                    }
                                                                });

                                                                collectionInputs.push({
                                                                    name: input.input,
                                                                    value: null,
                                                                    type: input.fieldType,
                                                                    isEmpty: true,
                                                                    subInputs: subInputs,
                                                                });
                                                            }
                                                        }
                                                    }
                                                });

                                                if (
                                                    undefined !==
                                                    this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[
                                                        index
                                                    ] /*&& collectionInputs.length */ /*Search "cominfos"*/
                                                ) {
                                                    // Will push empty collectionInputs to keep index in fieldset and subfieldset
                                                    undefined !== this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].collections
                                                        ? this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].collections.push(collectionInputs)
                                                        : (this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].collections = [collectionInputs]);

                                                    // Search "cominfos"
                                                    // this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].collections.push({
                                                    //     collectionInputs: collectionInputs,
                                                    //     collectionIndex: collectionIndex,
                                                    // });

                                                    this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].colIndicator++;
                                                }

                                                // collectionIndex++; // Search "cominfos"
                                            });

                                            if (undefined !== this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index]) {
                                                this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields[index].total = fieldInputsTotal;
                                            }
                                        } else {
                                            this.indicators.tabs[this.indicators.tabsCount].indicator++;
                                            this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].indicator++;

                                            let inputs = [];

                                            field.inputs.forEach((input) => {
                                                if (input.hasOwnProperty(this.key) && input[this.key]) {
                                                    inputs.push({
                                                        name: input.name,
                                                        value: null,
                                                        type: input.fieldType,
                                                        isEmpty: true,
                                                    });
                                                }
                                            });

                                            this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].fields.push({
                                                name: field.name,
                                                protocol: field.protocol,
                                                value: null,
                                                type: field.fieldType,
                                                isEmpty: true,
                                                inputs: inputs,
                                            });
                                        }
                                    }

                                    this.indicators.fieldsCount++;
                                    this.indicators.tabs[this.indicators.tabsCount].total++;
                                    this.indicators.tabs[this.indicators.tabsCount].sections[this.indicators.sectionsCount].total++;
                                }
                            }
                        });

                        this.indicators.sectionsCount++;
                    });

                    this.indicators.tabsCount++;
                });
            }
        });

        if (this.showMinifiedSalabilityIndicatorsInterface) {
            this.setMinifiedData();
        }
    }

    getFieldValue(field, type) {
        let value: any = null;
        const property = field.hasOwnProperty("elementValue") ? "elementValue" : "value";

        switch (field.fieldType) {
            case "date":
                if ("input" === type || "subInput" === type) {
                    value = field[property];
                } else {
                    value = field[property][0].value;
                }

                if (null !== value) {
                    value = this._dataService.formatDateValue(value);
                }

                break;
            case "textarea":
            case "number":
            case "text":
                if ("input" === type || "subInput" === type) {
                    value = field[property];
                } else {
                    value = field[property][0].value;
                }
                break;
            case "colors":
                if ("input" === type) {
                    value = field[property] ? true : false;
                } else if ("subInput" === type) {
                    value = false;
                } else {
                    value = field[property][0].value ? true : false;
                }
                break;
            case "table":
                if ("input" === type || "subInput" === type) {
                    value = field[property] && "null" !== field[property] ? true : false;
                } else {
                    value = field[property][0].value && "null" !== field[property][0].value ? true : false;
                }

                break;
            case "list":
            case "productLink":
                if (("list" === field.fieldType && field.multiple) || "productLink" === field.fieldType) {
                    let listValue = "";
                    let length;
                    if ("input" === type || "subInput" === type) {
                        length = field[property] ? field[property].length : 0;
                    } else {
                        length = field[property][0].value.length;
                    }

                    for (let i = 0; i < length; i++) {
                        if (i < length - 1) {
                            if ("input" === type || "subInput" === type) {
                                listValue += field[property][i].name + " | ";
                            } else {
                                listValue += field[property][0].value[i].name + " | ";
                            }
                        } else {
                            if ("input" === type || "subInput" === type) {
                                listValue += field[property][i].name;
                            } else {
                                listValue += field[property][0].value[i].name;
                            }
                        }
                    }

                    listValue ? (value = listValue) : null;
                } else {
                    if ("input" === type) {
                        value = field[property] && field[property].name ? field[property].name : null;
                    } else if ("subInput" === type) {
                        value = field[property] && field[property].length ? field.list.find((v) => v.id == field[property]).name : null;
                    } else {
                        value = field[property][0].value.name;
                    }
                }

                break;

            case "checkbox":
                if ("input" === type || "subInput" === type) {
                    value = field[property];
                } else {
                    value = field[property][0].value;
                }

                if (value == 1) {
                    value = true;
                } else {
                    value = false;
                }

                break;
            // case "fieldset":
        }

        return value;
    }

    switchType(field, type: string = "field") {
        let emptyType = false;
        let value = null;

        if (field.hasOwnProperty("elementValue")) {
            if (Array.isArray(field.elementValue) && field.elementValue.length) {
                if (field.elementValue.find(Boolean)) {
                    let obj = field.elementValue.find(Boolean);

                    if (obj.hasOwnProperty("value")) {
                        value = obj.value;
                    } else {
                        value = obj.name;
                    }
                }
            } else {
                value = field.elementValue;
            }
        } else {
            value = field.value;
        }

        switch (field.fieldType) {
            // case 'section': // Ignored
            case "fieldset": // Only check if there is min one collection
                if (
                    ("field" === type && !field.collections.length) ||
                    ("input" === type && field.value && !field.value.length) ||
                    ("subInput" === type && ((field.hasOwnProperty("value") && !field.value.length) || (field.hasOwnProperty("elementValue") && !field.elementValue.length)))
                ) {
                    emptyType = true;
                }
                break;
            case "list":
                if (
                    null === value ||
                    (!field.multiple && !value.hasOwnProperty("id") && (null === value || !value.length)) ||
                    (field.multiple && Array.isArray(value) && !value.length) /*find(Boolean).hasOwnProperty("id")*/
                ) {
                    emptyType = true;
                }
                break;
            case "textarea":
                if (null === value || "<p></p>" === value || !value.length) {
                    emptyType = true;
                }
                break;
            // case "checkbox": // Nonsense
            case "colors":
                if ("subInput" !== type && (null === value /*|| "" === value*/ || !value.length)) {
                    emptyType = true;
                }
                break;
            case "date":
            case "number":
            case "table":
            case "text":
            case "productLink":
                if (null === value /*|| "" === value*/ || !value.length) {
                    emptyType = true;
                }
                break;
        }

        if ("salabilityIndicator" === this.key && !emptyType && "date" === field.fieldType && this.compareDateValue(value)) {
            emptyType = true;
        }

        if (!emptyType && "table" === field.fieldType && "null" === value) {
            emptyType = true;
        }

        if ("field" === type && emptyType) {
            this.indicators.emptyFieldsCount++;
        } else if (("input" === type || "subInput" === type) && emptyType) {
            this.indicators.emptyInputsCount++;
        }

        return emptyType;
    }

    compareDateValue(value) {
        let minorToCurrent = false;

        const index = value.indexOf(" | ");

        if (-1 === index) {
            minorToCurrent = new Date(value) < new Date(new Date().toDateString());
        } else {
            const arr = value.split(" | ");

            arr.forEach((element) => {
                if (new Date(element) < new Date(new Date().toDateString())) {
                    return (minorToCurrent = true);
                }
            });
        }

        return minorToCurrent;
    }

    calculateCompletionRate(part: number, total: number) {
        let percentage = Math.round((100 * part) / total);

        return 100 - percentage;
    }

    getMergedCollectionInputValues(collections: any, type: string) {
        let values = {};

        for (let i = 0; i < collections.length; i++) {
            let data;

            if (collections[i].hasOwnProperty("elementValueView")) {
                data = collections[i].elementValueView;
            } else {
                data = collections[i];
            }

            // data = data.filter((d) => d.fieldType !== "checkbox");
            data = data.filter((d) => d.salabilityIndicator === true); // Avoid decal field - value with wrong index matching

            for (let j = 0; j < data.length; j++) {
                const sub = this.getFieldValue(data[j], type);

                if (values.hasOwnProperty(j)) {
                    if (true !== values[j]) {
                        values[j] =
                            "" !== values[j]
                                ? values[j] + (null !== sub ? (true === sub ? "✓" : false === sub ? "✕" : sub) : i == collections.length - 1 ? "\xa0|\xa0" : " ")
                                : null !== sub
                                ? sub
                                : " ";
                    }
                } else {
                    values[j] = null !== sub ? (true === sub ? "✓" : false === sub ? "✕" : sub) : " ";
                }

                if (i != collections.length - 1) {
                    values[j] += " |\xa0";
                }
            }
        }

        return values;
    }

    setMinifiedData() {
        this.indicators.tabs.forEach((tab) => {
            tab.sections.forEach((section) => {
                section.fields.forEach((field) => {
                    if ("fieldset" === field.type) {
                        if (field.hasOwnProperty("collections") && field.collections.length) {
                            for (let c = 0; c < field.collections.length; c++) {
                                for (let i = 0; i < field.collections[c].length; i++) {
                                    const input = field.collections[c][i];

                                    if ("fieldset" === input.type) {
                                        if (input.hasOwnProperty("collections") && input.collections.length) {
                                            for (let j = 0; j < input.collections[0].length; j++) {
                                                const subInput = input.collections[0][j];
                                                let val = input.concatColVal[j];

                                                if (subInput.type == "textarea" && val) {
                                                    val = val.replaceAll("<p>", "");
                                                    val = val.replaceAll("</p>", "");
                                                }

                                                this.indicatorsMinified.push({
                                                    name: subInput.name,
                                                    value: val,
                                                    type: subInput.type,
                                                    isEmpty: this.inputIsEmpty(input.collections, j),
                                                });
                                            }
                                        } else if (input.hasOwnProperty("subInputs") && input.subInputs.length) {
                                            if (i === 0) {
                                                // Only for first collection otherwise it will repeat sub input name (without values) in interface
                                                input.subInputs.forEach((subInput) => {
                                                    this.indicatorsMinified.push(subInput);
                                                });
                                            }
                                        }
                                    } else {
                                        if (c === 0) {
                                            let val = field.concatColVal[i];

                                            if (input.type == "textarea" && val) {
                                                val = val.replaceAll("<p>", "");
                                                val = val.replaceAll("</p>", "");
                                            }

                                            this.indicatorsMinified.push({
                                                name: input.name,
                                                value: val,
                                                type: input.type,
                                                isEmpty: this.inputIsEmpty(field.collections, i),
                                            });
                                        }
                                    }
                                }
                            }
                        } else if (field.hasOwnProperty("inputs") && field.inputs.length) {
                            field.inputs.forEach((input) => {
                                this.indicatorsMinified.push(input);
                            });
                        }
                    } else {
                        let val = field.value;

                        if (field.type == "textarea" && val) {
                            val = val.replace("<p>", "");
                            val = val.replace("</p>", "");
                        }

                        this.indicatorsMinified.push({
                            name: field.name,
                            value: val,
                            type: field.type,
                            isEmpty: field.isEmpty,
                        });
                    }
                });
            });
        });
    }

    inputIsEmpty(collections, index) {
        let isEmpty = false;

        collections.forEach((collection) => {
            if (collection[index].isEmpty === true) {
                isEmpty = true;
            }
        });

        return isEmpty;
    }

    updateIndicators(data: any) {
        this.indicators = {
            tabs: [],
            tabsCount: 0,
            sectionsCount: 0,
            fieldsCount: 0,
            inputsCount: 0,
            emptyFieldsCount: 0,
            emptyInputsCount: 0,
        };

        this.indicatorsMinified = [];

        if (data.data) {
            let tab = this.element.tabs.find((tab) => tab.id == data.tabId);

            if (tab && data.data.sectionName) {
                if (!data.data.isSubInput) {
                    if (data.data.inputFieldset) {
                        let input =
                            tab.fields[0].sections[data.data.sectionName][data.data.inputFieldset.indexField].collections[data.data.inputFieldset.indexCollection].elementValueView[
                                data.data.inputFieldset.indexInput
                            ];

                        if (input) {
                            if ("list" == input.fieldType && !input.multiple) {
                                let val = input.list.find((l) => l.id == data.data.payload.value);

                                if (val) {
                                    input.value = val;
                                }
                            } else {
                                input.value = data.data.payload.value;
                            }
                        }
                    } else {
                        let field = tab.fields[0].sections[data.data.sectionName].find((f) => f.id == data.data.field.id);

                        if (field) {
                            if ("list" == field.fieldType && !field.multiple) {
                                let val = field.list.find((l) => l.id == data.data.payload.value);

                                if (val) {
                                    field.elementValue[0].value = val;
                                }
                            } else if ("listcascade" == field.fieldType) {
                                field.elementValue[0].value = JSON.parse(data.data.payload.value);
                            } else {
                                field.elementValue[0].value = data.data.payload.value;
                            }
                        }
                    }
                }
            }
        }

        this._initIndicators();
    }
}

// cominfos : Used by html to know collection index since without empty sub array in field.collections it will not calculate it
