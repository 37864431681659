import { Component, ComponentFactoryResolver, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { faEdit, faGlobe, faImages, faMagic, faTasks } from "@fortawesome/free-solid-svg-icons";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Subscription } from "rxjs";
import { activatedModules } from "src/environments/activated-modules";
import { SidebarService } from "../../../core/services/global/sidebar/sidebar.service";
import { ISidebarContentComponent, SidebarContentDef } from "./sidebar";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, OnDestroy {
    @ViewChild("sidebarContent", { read: ViewContainerRef, static: true }) sidebarContent: ViewContainerRef;

    private _subscription: Subscription;
    private _component: Type<ISidebarContentComponent>;
    private _instance: ISidebarContentComponent;

    readonly dashboardIcon = faTasks;
    readonly pimIcon = faEdit;
    readonly projectIcon = faMagic;
    readonly damIcon = faImages;
    readonly globe = faGlobe;

    readonly routes = MAESTRO_ROUTES;

    activatedModules;

    constructor(service: SidebarService, private componentFactoryResolver: ComponentFactoryResolver) {
        this._subscription = service.componentDef$.subscribe((def) => this._initSidebar(def));
        
    }

    ngOnInit(): void {
        this.activatedModules = activatedModules;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private _initSidebar(def: SidebarContentDef) {
        if (def && def.component) {
            if (this._component !== def.component) {
                this._component = def.component;
                this.sidebarContent.clear();
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(def.component);
                this._instance = this.sidebarContent.createComponent(componentFactory).instance;
            }
            this._instance.options = def.options;
        } else {
            this._component = null;
            this._instance = null;
            this.sidebarContent.clear();
        }
    }
}
