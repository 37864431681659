import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { UserService } from "app/core/services/admin/user/user.service";

@Directive({
    selector: "[appActionRight]",
})
export class ActionRightDirective implements OnInit {
    @Input() appActionRight: any;

    private acl;

    constructor(private element: ElementRef, private _userService: UserService) {}

    ngOnInit(): void {
        this.acl = this._userService.getUserAclFromToken();

        let displayed = this.appActionRight.right ? "block" : "none";

        if (
            this.appActionRight.hasOwnProperty("groupRight") &&
            undefined !== this.appActionRight.groupRight &&
            this.appActionRight.hasOwnProperty("operation") &&
            undefined !== this.appActionRight.groupRight[this.appActionRight.operation]
        ) {
            displayed = this.appActionRight.groupRight[this.appActionRight.operation] ? "block" : "none";
        }

        this.element.nativeElement.style.display = displayed;
    }
}
