import { NgModule } from "@angular/core";
import { CommonService } from "app/core/services/admin/common.service";
import { GroupService } from "app/core/services/admin/group/group.service";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { GroupListComponent } from "./group-list.component";

@NgModule({
    declarations: [GroupListComponent],
    imports: [SharedModule, SharedComponentsModule],
    providers: [GroupService, CommonService],
})
export class GroupListModule {}
