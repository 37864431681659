import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class CommonService {
    constructor(private _http: HttpClient) {}

    /**
     * Return a boolean that indicates if we can create/delete resources
     * @returns
     */
    checkParamControl(param: string): Observable<any> {
        return this._http.post<any>(`${environment.adminApiUrl}/env/param/control.json`, { param: param });
    }
}
