import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { DialogModule } from "primeng-lts/dialog";
import { PanelModule } from "primeng-lts/panel";
import { PickListModule } from "primeng-lts/picklist";
import { TabViewModule } from "primeng-lts/tabview";
import { EditFieldsetComponent } from "./components/edit-fieldset/edit-fieldset.component";
import { EditListMultipleComponent } from "./components/edit-list-multiple/edit-list-multiple.component";
import { EditMediaComponent } from "./components/edit-media/edit-media.component";
import { EditProductLinkComponent } from "./components/edit-product-link/edit-product-link.component";
import { EditTableComponent } from "./components/edit-table/edit-table.component";
import { PageTextTabComponent } from "./page-text-tab.component";

@NgModule({
    declarations: [PageTextTabComponent, EditFieldsetComponent, EditListMultipleComponent, EditProductLinkComponent, EditTableComponent, EditMediaComponent],
    imports: [SharedModule, SharedComponentsModule, PanelModule, TabViewModule, DialogModule, PickListModule, PanelModule],
    exports: [PageTextTabComponent],
})
export class PageTextTabModule {}
