import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ChartLevel1Component } from "./chart-level-1/chart-level-1.component";
import { ChartLevel2Component } from "./chart-level-2/chart-level-2.component";
import { ChartLevel3Component } from "./chart-level-3/chart-level-3.component";

@NgModule({
    imports: [SharedModule, SharedComponentsModule],
    declarations: [ChartLevel1Component, ChartLevel2Component, ChartLevel3Component],
    exports: [ChartLevel1Component, ChartLevel2Component, ChartLevel3Component],
})
export class ChartModule {}
