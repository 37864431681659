import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { DamWorkflowListComponent } from "./dam-workflow-list.component";
import { DamWorkflowListGuard } from "./dam-workflow-list.guard";

@NgModule({
    declarations: [DamWorkflowListComponent],
    imports: [SharedModule, SharedComponentsModule],
    providers: [DamWorkflowListGuard],
})
export class DamWorkflowListModule {}
