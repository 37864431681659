import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, UrlTree } from "@angular/router";
import { GroupService } from "app/core/services/admin/group/group.service";
import { MaestroGroups } from "app/shared/models";
import { Observable } from "rxjs";

@Injectable()
export class GroupListGuard implements Resolve<MaestroGroups>, CanActivate {
    constructor(private _service: GroupService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<MaestroGroups> {
        return this._service.getAll();
    }
}
