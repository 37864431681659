import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES as routes } from "app/shared/routes/routes";
import { DamWorkflowModule } from "./dam/dam-workflow.module";
import { WorkflowFormComponent } from "./form/workflow-form.component";
import { PimWorkflowModule } from "./pim/pim-workflow.module";
import { ProjectWorkflowModule } from "./project/project-workflow.module";
import { AdminWorkflowComponent } from "./workflow.component";

const projectRoutes: Routes = [
    {
        path: routes.actions.create,
        component: WorkflowFormComponent,
    },
    {
        path: "",
        component: AdminWorkflowComponent,
        children: [
            {
                path: routes.admin.workflow.dam,
                loadChildren: () => DamWorkflowModule,
            },
            {
                path: routes.admin.workflow.pim,
                loadChildren: () => PimWorkflowModule,
            },
            {
                path: routes.admin.workflow.project,
                loadChildren: () => ProjectWorkflowModule,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(projectRoutes)],
    exports: [RouterModule],
})
export class AdminWorkflowRoutingModule {}
