import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ElementViewComponent } from "./element-view.component";
import { ElementViewResolver } from "./element-view.resolver";
import { ElementViewTabResolver } from "./tab/view-tab.resolver";
import { ElementViewTabComponent } from "./tab/view-tab.component";
import { ElementViewGuard } from "./element-view.guard";
import { MAESTRO_ROUTES } from "app/shared/routes";

const pimElementViewRoutes: Routes = [
    {
        path: `${MAESTRO_ROUTES.actions.view}/:id`,
        component: ElementViewComponent,
        resolve: { viewResources: ElementViewResolver },
        canActivate: [ElementViewGuard],
        children: [
            {
                path: ":tabId",
                component: ElementViewTabComponent,
                resolve: { tab: ElementViewTabResolver },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(pimElementViewRoutes)],
    exports: [RouterModule],
})
export class ElementViewRoutingModule {}
