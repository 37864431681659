import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES as routes } from "app/shared/routes";
import { AdminComponent } from "./admin.component";
import { GroupModule } from "./features/group/group.module";
import { MessageModule } from "./features/message/message.module";
import { RoleModule } from "./features/role/role.module";
import { UserModule } from "./features/user/user.module";
import { AdminWorkflowModule } from "./features/workflow/workflow.module";

const adminRoutes: Routes = [
    {
        path: "",
        component: AdminComponent,
        children: [
            {
                path: routes.admin.users,
                loadChildren: () => UserModule,
            },
            {
                path: routes.admin.roles,
                loadChildren: () => RoleModule,
            },
            {
                path: routes.admin.workflow.base,
                loadChildren: () => AdminWorkflowModule,
            },
            {
                path: routes.admin.group,
                loadChildren: () => GroupModule,
            },

            {
                path: routes.admin.message.base,
                loadChildren: () => MessageModule,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(adminRoutes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {}
