import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap"; 
import { AlertMessageComponent } from "./alert-message.component";

@NgModule({
    imports: [SharedModule,NgbAlertModule],
    declarations: [AlertMessageComponent],
    providers: [AlertMessageModule],
    exports: [AlertMessageComponent],
})
export class AlertMessageModule {}
