import {LayoutModule} from "@angular/cdk/layout";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgbDatepickerModule, NgbNavModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CodeEditorModule} from "@ngstack/code-editor";
import {TranslateModule} from "@ngx-translate/core";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {NgxUploadModule} from "@wkoza/ngx-upload";
import {DiffMatchPatchModule} from "ng-diff-match-patch";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {ClipboardModule} from "ngx-clipboard";
import {NgxEditorModule} from "ngx-editor";
import {ImageCropperModule} from "ngx-image-cropper";
import {NgxPaginationModule} from "ngx-pagination";
import {ConfirmDialogModule} from "primeng-lts/confirmdialog";
import {DropdownModule} from "primeng-lts/dropdown";
import {MultiSelectModule} from "primeng-lts/multiselect";
import {OverlayPanelModule} from "primeng-lts/overlaypanel";
import {TooltipModule} from "primeng-lts/tooltip";
import {TreeModule} from "primeng-lts/tree";
import {DirectivesModule} from "./directives/directives.module";
import {PipesModule} from "./pipes/pipes.module";
import {DraggableItemModule} from "app/shared/components/DraggableItem/DraggableItem.module";

@NgModule({
    exports: [
        DraggableItemModule,
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        DirectivesModule,
        PipesModule,
        NgbNavModule,
        NgbDatepickerModule,
        NgxPaginationModule,
        SweetAlert2Module,
        NgxUploadModule,
        NgbTooltipModule,
        NgMultiSelectDropDownModule,
        DiffMatchPatchModule,
        ClipboardModule,
        NgxEditorModule,
        ImageCropperModule,
        CodeEditorModule,
        OverlayPanelModule,
        TreeModule,
        TooltipModule,
        LayoutModule,
        DropdownModule,
        MultiSelectModule,
        ConfirmDialogModule,
    ],
})
export class SharedModule {
}
