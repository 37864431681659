import { Injectable } from "@angular/core";
import { ActionEvent } from "app/shared/components/action-buttons/action";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class GeneralActionButtonsService {
    private _enabledSource: Subject<boolean> = new Subject<boolean>();
    private _clickSource: Subject<ActionEvent> = new Subject<ActionEvent>();
    private _actionsSource: Subject<any[]> = new BehaviorSubject<any[]>([]);

    readonly enabled$ = this._enabledSource.asObservable();
    readonly click$ = this._clickSource.asObservable();
    readonly actions$ = this._actionsSource.asObservable();

    private _actions: any[];
    private _enable: boolean;

    /**
     * Set value for enable to determine if general action buttons are displayed
     */
    set enable(value: boolean) {
        if (this._enable !== value) {
            this._enable = value;
            this._enabledSource.next(value);
        }
    }

    /**
     * Configure the actions to display
     */
    set actions(value: any[]) {
        if (this._actions !== value) {
            this._actions = value;
            this._actionsSource.next(value);
        }
    }

    click(event: ActionEvent) {
        this._clickSource.next(event);
    }
}
