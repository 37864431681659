import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ViewTabValueComponent } from "./view-tab-value/view-tab-value.component";
import { ElementViewTabComponent } from "./view-tab.component";
import { ElementViewTabResolver } from "./view-tab.resolver";

@NgModule({
    declarations: [ElementViewTabComponent, ViewTabValueComponent],
    imports: [SharedModule, SharedComponentsModule],
    providers: [ElementViewTabResolver],
})
export class ElementViewTabModule {}
