import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
export class SearchInputService {
    private _enableSource = new BehaviorSubject<boolean>(false);
    private _currentValueSource = new BehaviorSubject<string>("");
    private _filterTriggeredSource = new Subject<string>();
    private _enabled: boolean;

    readonly filter$: Observable<string> = this._filterTriggeredSource.asObservable();
    readonly enable$: Observable<boolean> = this._enableSource.asObservable();
    readonly currentValue$: Observable<string> = this._currentValueSource.asObservable();

    /**
     * Set enable to determine if we want to display a search bar in the breacrumb
     */
    set enable(a: boolean) {
        if (this._enabled !== a) {
            this._enableSource.next(a);
        }
    }

    /**
     * Set the currentValue in the search bar
     */
    set currentValue(value: string) {
        this._currentValueSource.next(value);
    }

    /**
     * Filter informations using a provided function
     *
     * @param value
     */
    filter(value: string) {
        this._filterTriggeredSource.next(value);
    }
}
