import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { DialogModule } from "primeng-lts/dialog";
import { PanelModule } from "primeng-lts/panel";
import { RadioButtonModule } from "primeng-lts/radiobutton";
import { TabViewModule } from "primeng-lts/tabview";
import { ElementUpdateComponent } from "./element-update.component";
import { ElementUpdateGuard } from "./element-update.guard";
import { ElementUpdateResolver } from "./element-update.resolver";
import { ElementUpdateRoutingModule } from "./element-update.routing";
import { ElementUpdateMediaComponent } from "./media/element-update-media.component";
import { ElementUpdateTabComponent } from "./tab/element-update-tab.component";
import { SectionTabComponent } from "./tab/section-tab/section-tab.component";

@NgModule({
    imports: [ElementUpdateRoutingModule, SharedModule, SharedComponentsModule, DialogModule, RadioButtonModule, TabViewModule, PanelModule],
    declarations: [ElementUpdateComponent, ElementUpdateTabComponent, ElementUpdateMediaComponent, SectionTabComponent],
    providers: [ElementUpdateResolver, ElementUpdateGuard],
})
export class ElementUpdateModule {}
