import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { TemplateService } from "app/core/services/project/template/template.service";
import { IMaestroEntity } from "app/shared/models";
import { Observable } from "rxjs";
import { TwigListModule } from "./twig-list.module";

@Injectable({
    providedIn: TwigListModule,
})
export class TwigListGuard implements CanActivate, Resolve<IMaestroEntity[]> {
    constructor(private _service: TemplateService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<IMaestroEntity[]> {
        return this._service.getTwigs();
    }
}
