import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ChipsModule } from "primeng-lts/chips";
import { FieldsetModule } from "primeng-lts/fieldset";
import { TagListComponent } from "./tag-list.component";
import { TagListGuard } from "./tag-list.guard";
@NgModule({
    imports: [SharedModule, SharedComponentsModule, FieldsetModule, ChipsModule],
    declarations: [TagListComponent],
    providers: [TagListGuard],
})
export class TagListModule {}
