import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { ViewType } from "app/shared/components/view-buttons";
import { ThumbButtons } from "app/shared/helpers/thumb-buttons";
import { Thumb } from "app/shared/models/thumb";

interface GetThumbParams {
    binImage: any;
}

@Component({
    selector: "app-grid",
    templateUrl: "./grid.component.html",
    styleUrls: ["./grid.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class GridComponent implements OnInit, AfterContentChecked {
    @Input() medias: Thumb[];
    @Input() buttons: ThumbButtons;
    @Input() viewType: ViewType;
    @Input() dataModels = [];

    @Input() tags: any;

    viewTypeEnum = ViewType;
    date: Date;

    private _actualMedias: Thumb[];

    mediasColumns: Thumb[][] = [[], [], [], []];

    @Output() public unlinked = new EventEmitter<number>();
    @Output() public openModal = new EventEmitter<Thumb>();
    @Output() public addCart = new EventEmitter<Thumb>();
    @Output() public tagFiltered = new EventEmitter<any>();
    @Output() public tagRemoved = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {
        this._actualMedias = this.medias;
        let currentIndex = 0;
        this.mediasColumns = [[], [], [], []];
        this.medias.forEach((thumb) => {
            this.mediasColumns[currentIndex].push(thumb);
            currentIndex++;
            if (currentIndex === 4) {
                currentIndex = 0;
            }
        });
    }

    ngAfterContentChecked(): void {
        if (this.medias !== this._actualMedias) {
            let currentIndex = 0;
            this.mediasColumns = [[], [], [], []];
            this.medias.forEach((thumb) => {
                this.mediasColumns[currentIndex].push(thumb);
                currentIndex++;
                if (currentIndex === 4) {
                    currentIndex = 0;
                }
            });
        }
    }

    /**
     * Open modal
     */
    open(media: Thumb): void {
        this.openModal.emit(media);
    }

    /**
     * Emit the event to unlink a media
     * @param id
     */
    unlinkMedia(id: number): void {
        this.unlinked.emit(id);
    }

    /**
     * Refresh the list
     *
     */
    removeMedia(id: number): void {
        this._actualMedias = this.medias;
        let currentIndex = 0;
        this.mediasColumns = [[], [], [], []];
        this.medias = this.medias.filter((m) => m.id !== id);
        this.medias.forEach((thumb) => {
            this.mediasColumns[currentIndex].push(thumb);
            currentIndex++;
            if (currentIndex === 4) {
                currentIndex = 0;
            }
        });
    }
}
