import { Component, OnDestroy, OnInit } from "@angular/core";
import { faTag, faIndent } from "@fortawesome/free-solid-svg-icons";
import { MAESTRO_ROUTES as routes } from "app/shared/routes/routes";
import { LayoutService } from "app/core/services/global/layout/layout.service";

@Component({
    selector: "app-dam",
    templateUrl: "./dam.component.html",
})
export class DamComponent implements OnInit, OnDestroy {
    private readonly _baseRoute = `/${routes.dam.base}/`;

    constructor(private _layout: LayoutService) {}

    ngOnInit(): void {
        this._layout.breadcrumb.setPath({ routerLink: `/${routes.dam.base}`, name: "breadcrumb.dam.home" }, 0);
        this._layout.menu.items = [
            {
                icon: faTag,
                routerLink: this._baseRoute + routes.dam.tag,
                text: "tags.title",
            },
            {
                icon: faIndent,
                routerLink: this._baseRoute + routes.dam.elementType,
                text: "pim.elementTypes.title",
            },
        ];
    }

    ngOnDestroy() {
        this._layout.menu.items = null;
        this._layout.breadcrumb.setPath(null, 0);
    }
}
