<div class="card mb-2 p-0">
    <div class="card-header text-center d-flex">
        <ng-container *ngIf="!updateName">
            <h4 class="font-weight-bold text-primary mb-0 pb-0 mx-auto">{{ elementType.name }}</h4>
            <fa-icon container="body"[ngbTooltip]="'pim.actions.edit'| translate" (click)="updateName = true" class="text-warning font-lg pointer" [icon]="faEdit"></fa-icon>
        </ng-container>
        <ng-container *ngIf="updateName">
            <input class="form-control form-control-sm col-9 col-md-4 mx-auto text-center font-weight-bolder" type="text" [(ngModel)]="elementType.name">
            <fa-icon *ngIf="elementType.name !== nameAtOpening" (click)="saveName()" class="text-primary font-lg pointer" [icon]="faSave"></fa-icon>
            <fa-icon (click)="updateName = false" class="text-danger font-lg pointer ml-2" [icon]="faClose"></fa-icon>
        </ng-container>
    </div>
    <div class="card-body" *ngIf="elementType.tabs.length === 0">
        {{ noFieldMessage }}
    </div>
    <div class="card-body" *ngIf="!hasSelected && elementType.tabs.length > 0">
        {{ noSelectMessage }}
    </div>
    <router-outlet></router-outlet>
</div>
