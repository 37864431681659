<div class="row justify-content-md-center">
    <div class="col-lg-12">
        <div *ngFor="let option of product.options">
            <div class="form-group row">
                <label class="col-sm-7 col-form-label text-truncate" for="">{{ option.name }}</label>
                <div class="col-sm-5">
                    <!-- Text -->
                    <input (change)="updateValue($event.target.value, option)" value="{{ option.value }}" type="text" class="form-control" *ngIf="option.type === 'text'"/>
                    <!-- Number -->
                    <input (keypress)="checkIfNumber($event)" (blur)="updateValue($event.target.value, option)" value="{{ option.value }}" type="number" class="form-control" *ngIf="option.type === 'number'"/>
                    <!-- Textarea -->
                    <app-text-editor *ngIf="option.type === 'textarea'" [value]="option.value" (focusOut)="updateValue($event, option)"></app-text-editor>
                    <!-- Checkbox -->
                    <div  *ngIf="option.type === 'boolean'">
                        <label class="switch switch-lg switch-outline-primary-alt">
                            <input [(ngModel)]="option.value" (change)="updateValue($event.target.checked, option)" [checked]="option.value === '1'" value="{{ option.value }}" type="checkbox" floating-label class="switch-input" />
                            <span class="switch-slider"></span>
                        </label>
                    </div>
                    <!-- Color -->
                    <app-color-picker-maestro (colorSelect)="updateValue($event, option)" *ngIf="option.type === 'color'" [value]="option.value"></app-color-picker-maestro>

                    <!-- Date -->
                    <input (change)="updateValue($event.target.value, option)" value="{{ option.value }}" class="form-control" type="date" *ngIf="option.type === 'date'"/>
                </div>
            </div>
        </div>
    </div>
</div>
