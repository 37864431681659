import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES } from "app/shared/routes/routes";
import { ListData, MaestroBlocData } from "../../../../shared/models/dashboard";

@Component({
    selector: "[app-dashboard-list]",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
    @Input() bloc: MaestroBlocData;
    number = [1, 2, 3, 4];
    lists: ListData[] = []; // Contains 1 array foreach module render in tab by html
    acl: ACL;

    constructor(private router: Router, private _authService: AuthService) {}

    ngOnInit() {
        this.acl = this._authService.getUserAclFromToken();

        if (this.bloc && this.bloc.data) {
            this._getData();
        }
    }

    _getData() {
        for (const [key, value] of Object.entries(this.bloc.data)) {
            this.lists.push(value);
        }
    }

    accessTo(module: string, id: number) {
        switch (module) {
            case "PIM":
                this.router.navigateByUrl(`${MAESTRO_ROUTES.pim.base}/${MAESTRO_ROUTES.pim.element}/${MAESTRO_ROUTES.actions.update}/${id}`);
                break;

            // case 'Project':
            //   break;

            // case 'DAM':
            //   break;
        }
    }
}
