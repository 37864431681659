import {HttpClient} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CodeEditorModule} from "@ngstack/code-editor";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {NgxUploadModule} from "@wkoza/ngx-upload";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {NgxEditorModule} from "ngx-editor";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared";
import {DraggableItemModule} from "app/shared/components/DraggableItem/DraggableItem.module";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        CoreModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forRoot({
            loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]},
        }),
        NgxEditorModule.forRoot({
            locals: {
                // menu
                bold: "Gras",
                italic: "Italique",
                code: "Code",
                blockquote: "Blockquote",
                underline: "Souligné",
                strike: "Barré",
                bullet_list: "Liste à puces",
                ordered_list: "Liste à chiffres",
                heading: "Titre",
                h1: "Titre 1",
                h2: "Titre 2",
                h3: "Titre 3",
                h4: "Titre 4",
                h5: "Titre 5",
                h6: "Titre 6",
                align_left: "Aligner à gauche",
                align_center: "Centrer le texte",
                align_right: "Aligner à droite",
                align_justify: "Justifier le texte",
                text_color: "Couleur du texte",
                background_color: "Couleur de fond",

                // popups, forms, others...
                url: "URL",
                text: "Texte",
                openInNewTab: "Ouvrir dans un nouvel onglet",
                insert: "Insérer",
                altText: "Alt Text",
                title: "Titre",
                remove: "Supprimer",
            },
        }),
        NgxUploadModule.forRoot(),
        SweetAlert2Module.forRoot(),
        AppRoutingModule,
        CodeEditorModule.forRoot({
            baseUrl: 'assets/monaco',
            typingsWorkerUrl: 'assets/workers/typings-worker.js'
        }),
        BrowserAnimationsModule,
        DraggableItemModule
    ],
    bootstrap: [AppComponent],
    providers: [],
})
export class AppModule {
}
