import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
    selector: "[appDebounceClick],[debounceClick]",
})
export class DebounceClickDirective implements OnDestroy {
    @Input() debounceTime = 500;
    @Output() debounceClick = new EventEmitter();
    private _clickSource = new Subject();
    private _clickSub: Subscription;

    constructor() {
        this._clickSub = this._clickSource.pipe(debounceTime(this.debounceTime)).subscribe((e) => this.debounceClick.emit(e));
    }

    ngOnDestroy() {
        this._clickSub.unsubscribe();
    }

    @HostListener("click", ["$event"])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this._clickSource.next(event);
    }
}
