import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Tab } from "app/shared/models";
import { MAESTRO_ROUTES } from "app/shared/routes";

@Component({
    selector: "app-element-view-tab",
    templateUrl: "./view-tab.component.html",
})
export class ElementViewTabComponent implements OnInit {
    @Input() tab: Tab;
    noFormMessage: string;
    noFormName: string;

    readonly routerLink = ["../../../../", MAESTRO_ROUTES.pim.elementType, MAESTRO_ROUTES.actions.update];
    private readonly _noSectionMessage = `Aucun champs n'est renseigné pour la section `;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.data.subscribe((data: { tab: any }) => {
            this.tab = data.tab.data;
            this._checkData();
        });
    }

    _checkData() {
        if (this.tab.fields && !this.tab.fields.length) {
            this.noFormMessage = this._noSectionMessage;
            this.noFormName = this.tab.name;
        } else {
            this.noFormMessage = null;
        }
    }
}
