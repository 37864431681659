import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { activatedModules } from "src/environments/activated-modules";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
})
export class LoginComponent {
    loginForm: FormGroup;
    isSubmitted = false;

    readonly faUser = faUser;
    readonly faKey = faKey;
    readonly route = MAESTRO_ROUTES;

    constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder) {
        this.loginForm = this.formBuilder.group({
            email: ["", Validators.required],
            password: ["", Validators.required],
        });
    }

    get formControls() {
        return this.loginForm.controls;
    }

    login() {
        this.isSubmitted = true;
        if (this.loginForm.invalid) {
            return;
        }

        this.authService
            .login({
                email: this.formControls.email.value,
                password: this.formControls.password.value,
            })
            .subscribe((loggedIn) => {
                if (loggedIn) {
                    if (!activatedModules.dashboard) {
                        if (activatedModules.pim) {
                            this.router.navigate(["pim"]).then();
                            return;
                        }
                        if (activatedModules.dam) {
                            this.router.navigate(["dam"]).then();
                            return;
                        }
                        if (activatedModules.project) {
                            this.router.navigate(["projects"]).then();
                            return;
                        }
                        if (activatedModules.admin) {
                            this.router.navigate(["admin"]).then();
                            return;
                        }
                    }
                    this.router.navigate(["dashboard"]).then();
                }
            });
    }
}
