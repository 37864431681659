import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ElementTypeService } from "../services/pim/element-type.service";

/**
 * This guard is used to control if there is any unsaved modification in a form
 */
@Injectable()
export class ConfirmTabGuard implements CanDeactivate<any> {
    constructor(private _swal: SwalModalService, private service: ElementTypeService) {}

    canDeactivate(target: any): boolean | Promise<boolean> {  
        if (target.formArray && target.formArray.dirty) {
            return this._swal.unsavedConfirm().then((result) => {
                if (result.value) {
                    if(this.service.changeTab.value){
                        this.service.currentTab.next(this.service.changeTab.value);
                    }

                    this.service.changeTab.next(null);
                    return true;
                }
                return false;
            });
        } else if(target.changeList){
            return this._swal.unsavedConfirm().then((result) => {
                if (result.value) {
                    return true;
                } else {
                    return false;
                }
            })
        } else{
            return true;
        }
    }
}
