import { Injectable } from "@angular/core";
import { CanActivate, UrlTree } from "@angular/router";
import { UserService } from "app/core/services/admin/user/user.service";
import { ACL } from "app/shared/models/acl";
import { Observable } from "rxjs";

@Injectable()
export class ElementListGuard implements CanActivate {
    constructor(private _userService: UserService) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return (this._userService.getUserAclFromToken() as ACL).SYSPAD_PIM_ELEMENT_LIST === 1;
    }
}
