import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ListService } from "app/core/services/pim/list.service";
import { MaestroList } from "app/shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ListUpdateResolver implements Resolve<MaestroList> {
    constructor(private _service: ListService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<MaestroList> {
        return this._service.getList(route.params.id).pipe(map((r) => r.data));
    }
}
