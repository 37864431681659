import { Component, Input, OnInit } from "@angular/core";
import { FormArray } from "@angular/forms";

@Component({
    selector: "app-configuration-workflow-step-tab-form",
    templateUrl: "./step-tab-form.component.html",
})
export class WorkflowConfigurationTabFormComponent implements OnInit {
    @Input() tabFormArray: FormArray;
    @Input() showErrors: boolean;
    @Input() isFlatplan: boolean;
    @Input() canUpdate: boolean;

    constructor() {}

    ngOnInit(): void {
        if (this.tabFormArray.value[this.tabFormArray.value.length - 1] && this.tabFormArray.value[this.tabFormArray.value.length - 1].title === "workflowEnd") {
            this.tabFormArray.removeAt(this.tabFormArray.length - 1);
        }
    }
}
