import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ElementService } from "app/core/services/pim/element.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { Thumb } from "app/shared/models/thumb";
import { environment } from "src/environments";

@Component({
    selector: "app-edit-media",
    templateUrl: "./edit-media.component.html",
})
export class EditMediaComponent implements OnInit {
    @Input() idElementValueProject: number = -1;
    @Input() pageDuplicateId: number = -1;
    @Input() idElementValue: number;
    @Input() idInput: number = -1;
    @Input() indexCollection: number = -1;
    @Input() singleSelection: boolean = false;
    @Input() disabled: boolean = false;
    @Input() medias: string = ""; // Json encoded medias
    @Input() loadDataOnInit: boolean = true; // Used to avoid breaking call with fieldset that display dialogs and data foreach input before initialization click

    @Output() mediasSaved = new EventEmitter<any>();

    display: boolean = false;
    dataSource = [];
    dataTarget = [];
    moduleUrl: string;

    constructor(private _thumbnailService: ThumbnailsService, private _elementService: ElementService) {
        this.moduleUrl = environment.damUrl;
    }

    ngOnInit(): void {
        if (-1 !== this.idElementValueProject) {
            this.setDataTarget();
        }

        if (this.loadDataOnInit) {
            this.getData();
        }
    }

    ngOnDestroy() {
        this.display = false;
    }

    getData() {
        this.showDialog();

        this.getElementMedias();
    }

    showDialog() {
        this.display = true;
    }

    /**
     * Get all linked field's medias from PIM
     */
    getElementMedias() {
        if (null !== this.idElementValue) {
            this._elementService.getElementValueMedias(this.idElementValue, this.indexCollection, this.idInput).subscribe((medias: any) => {
                let source = [];
                let target = [];

                // Keep only image type
                const mediaData = medias.data.filter((m) => m.type === "image");

                for (const dataKey of mediaData) {
                    let isAlreadySelected: boolean = false;

                    if (this.dataTarget.length) {
                        const target = this.dataTarget.find((m) => m.id === dataKey.id);

                        if (undefined !== target) {
                            if (null === target.id) {
                                const targetLink = this.dataTarget.find((m) => m.link === dataKey.link);

                                if (undefined !== targetLink) {
                                    isAlreadySelected = true;
                                }
                            } else {
                                isAlreadySelected = true;
                            }
                        }
                    }

                    if (!isAlreadySelected) {
                        if (-1 === this.idElementValueProject) {
                            target.push(new Thumb().deserialize(dataKey));
                        } else {
                            source.push(new Thumb().deserialize(dataKey));
                        }
                    }
                }

                this.dataSource = source;
                this.dataTarget = [...this.dataTarget, ...target];

                this.getMediasMetadata(this.dataSource);
                this.getMediasMetadata(this.dataTarget);
            });
        }
    }

    getMediasMetadata(medias) {
        if (medias && medias.length) {
            medias.forEach((dataKey) => {
                if (dataKey && !dataKey.id && dataKey.link && dataKey.loadMetadata) {
                    this._thumbnailService.getUrlMetadata(dataKey.link).subscribe((metadata) => {
                        if (metadata.thumbPath.length) {
                            dataKey.metadataLink = metadata.thumbPath;
                        }

                        if (metadata.name.length) {
                            dataKey.metadataName = metadata.name;
                        }
                    });
                }
            });
        }
    }

    setDataTarget() {
        JSON.parse(this.medias).forEach((m) => {
            this.dataTarget.push(new Thumb().deserialize(m));
        });
    }

    reorderTarget() {
        let index = 1;

        this.dataTarget.forEach((item) => {
            item.position = index;
            index++;
        });
    }

    saveMedias() {
        this.dataTarget.forEach((target) => {
            // Avoid to store in db outdated external data which can change on the fly
            if (target.hasOwnProperty("metadataLink")) {
                delete target.metadataLink;
            }

            if (target.hasOwnProperty("metadataName")) {
                delete target.metadataName;
            }
        });

        this.mediasSaved.emit(this.dataTarget);
        this.display = false;
    }
}
