
<div id="cdf-helper" [style.top.px]="helper.top" [style.left.px]="helper.left"
    [style.visibility]="helper.visible ? 'visible' : 'hidden'"></div>

<div class="row">
    <div class="col-12">
        <h3 style="display: inline-block;">{{ 'projects.export.flatplan' | translate }}</h3>
        <button  *ngIf="pages.length > 0" type="button" class="btn btn-primary" style="float: right;" (click)="showAllPreviews()">
            <span>
                <fa-icon [icon]="faEye"></fa-icon>
            </span>
            <span class="d-inline-block ml-2">{{ 'projects.flatplan.overview' | translate }}</span>
        </button>

        <button  *ngIf="pages.length > 0" type="button" class="btn btn-primary" style="float: right; margin-right: 8px;" (click)="flipBook()">
            <span>
                <fa-icon [icon]="showFlipBook ? faEyeSlash : faEye"></fa-icon>
            </span>
            <span class="d-inline-block ml-2">{{ (showFlipBook ? 'projects.flatplan.hideFlipBook' : 'projects.flatplan.showFlipBook') | translate  }}</span>
        </button>

        <!-- <button  type="button" class="btn btn-primary" style="float: right; margin-right: 8px;" (click)="createCover()" *ngIf="showButtonAddCover">
            <span>
                <i class="fa fa-rotate-right text-mute"></i>
            </span>
            <span class="d-inline-block ml-2">Ré-initialiser les Covers</span>
        </button> -->
    </div>
</div>

<div class="row" id="cover">
    <div *ngFor="let cover of covers; let i = index" class="cdf-page" [attr.data-heading]="cover.heading"
        [attr.data-id]="version ? cover.pageDuplicateId : cover.id" [attr.data-folio]="cover.folio"
        (mouseenter)="this._pageThumbnail.currentHeading = cover.heading"
        (mouseleave)="this._pageThumbnail.currentHeading = null" (load)="mutation($event, cover.heading)"
        (click)="eventSelectAndUnselectGroupPages($event, cover)">
        <div class="card">
            <app-page-thumbnail style="height:inherit;" [version]="version"  [page]="cover" [flatplanView]="flatplanView" [exist]="cover.exist"
                (menuAction)="pageAction.emit($event)">
            </app-page-thumbnail>

            <div class="card-footer"  [ngStyle]="{ 'border-top-color': cover.status ? cover.status : null }" >
                <div *ngIf="exportType === 2" class="folio">
                    {{ romanize(cover.folio) }}
                </div>
                <div>{{ '' != cover.name ? cover.name : 'table.templates.noPageName' | translate }}</div>
            </div>
        </div>
    </div>
    <hr/>
</div>



<div class="row" id="cdf" [appSortable]="sortableJSOptions">
    <div class="ignore-elements col-1 cdf-page"></div>
    <div *ngFor="let page of pages; let i = index" class="cdf-page drag col-1" [attr.data-heading]="page.heading"
        [attr.data-id]="version ? page.pageDuplicateId : page.id" [attr.data-folio]="page.folio"
        (mouseenter)="this._pageThumbnail.currentHeading = page.heading"
        (mouseleave)="this._pageThumbnail.currentHeading = null" (load)="mutation($event, page.heading)"
        (click)="eventSelectAndUnselectGroupPages($event, page)">
        <div class="card">
            <app-page-thumbnail style="height:inherit;" [version]="version"  [page]="page" [flatplanView]="flatplanView" [exist]="page.exist"
                (menuAction)="pageAction.emit($event)">
            </app-page-thumbnail>

            <div class="card-footer"  [ngStyle]="{ 'border-top-color': page.status ? page.status : null }" >
                <div *ngIf="exportType === 2" class="folio">
                    {{ page.folio }}
                </div>
                <div>{{ '' != page.name ? page.name : 'table.templates.noPageName' | translate }}</div>
            </div>
        </div>
    </div>
</div>



<div class="row" id="div-flipbook" style="display: none">
    <div class="col-md-12 text-center">
        <div class="flip-book" id="flipbook">
            <div *ngFor="let number of [0,1]" class="page {{ (i === 0 || i === covers.length - 1) ? 'page-cover' : '' }} {{ i === 0 ? 'page-cover-top' : (i === covers.length - 1 ? 'page-cover-bottom' : '') }}" data-density="soft">
                <div class="page-content">
                    <img *ngIf="covers[number] && covers[number].previews.length === 0" src="assets/img/no_preview.png">
                    <img *ngIf="covers[number] && covers[number].previews.length > 0" src="data:image/jpeg;base64,{{ covers[number].previews[0] }}">
                </div>
            </div>
            <div *ngFor="let page of pages; let i = index" class="page {{ (i === 0 || i === pages.length - 1) ? 'page-cover' : '' }} {{ i === 0 ? 'page-cover-top' : (i === pages.length - 1 ? 'page-cover-bottom' : '') }}" data-density="soft">
                <div class="page-content">
                    <img *ngIf="page.previews.length === 0" src="assets/img/no_preview.png">
                    <img *ngIf="page.previews.length > 0" src="data:image/jpeg;base64,{{ page.previews[0] }}">
                    <img *ngIf="page.previews.length > 1 && page.folio % 2 === 0" src="data:image/jpeg;base64,{{ page.previews[0] }}">
                    <img *ngIf="page.previews.length > 1 && page.folio % 2 !== 0" src="data:image/jpeg;base64,{{ page.previews[1] }}">
                </div>
            </div>
            <div *ngFor="let number of [2,3]" class="page {{ (i === 0 || i === covers.length - 1) ? 'page-cover' : '' }} {{ i === 0 ? 'page-cover-top' : (i === covers.length - 1 ? 'page-cover-bottom' : '') }}" data-density="soft">
                <div class="page-content">
                    <img *ngIf="covers[number] && covers[number].previews.length === 0" src="assets/img/no_preview.png">
                    <img *ngIf="covers[number] && covers[number].previews.length > 0" src="data:image/jpeg;base64,{{ covers[number].previews[0] }}">
                </div>
            </div>
        </div>

        <div class="mt-2 text-center">
            <button type="button" class="btn-prev"><< {{ 'general.previous' | translate }}</button>
            [<span class="page-current">1</span> of <span class="page-total">-</span>]
            <button type="button" class="btn-next">{{ 'general.next' | translate }} >></button>
        </div>
    </div>
</div>

<swal [customClass]="'col-10'" #preview title="{{ 'projects.flatplan.preview' | translate }}"
      [showConfirmButton]="false" [showCancelButton]="false" [showCloseButton]="true">
    <div *swalPortal>
        <div *ngIf="previewsUrl.length === 0" class="alert alert-warning alert-dismissible fade show" role="alert">
            <i class="pi pi-exclamation-triangle"></i> {{ 'projects.flatplan.emptyMsg' | translate }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <embed *ngFor="let previewUrl of previewsUrl" style="width: 100%; height: 85vh;" [src]="previewUrl" type="application/pdf">
    </div>
</swal>
