import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { History } from "app/shared/models/history";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class HistoryService {
    constructor(private _http: HttpClient) {}

    /**
     * Get history for a pim value
     * @param id
     * @returns
     */
    getElementValueHistory(id: number): Observable<any> {
        return this._http.get(`${environment.historisationUrl}/story/element_value/pim/update/${id}.json`);
    }

    /**
     * Get dam history
     *
     * @param id
     */
    getDamValueHistory(id: number): Observable<any> {
        return this._http.get(`${environment.historisationUrl}/story/element_value/dam/update/${id}.json`);
    }

    /**
     * Set an history
     */
    setHistory(history: History): Observable<any> {
        return this._http.post(`${environment.historisationUrl}/trace.json`, history);
    }
}
