import { Pipe, PipeTransform } from "@angular/core";
import { MaestroElements } from "app/shared/models";

@Pipe({
    name: "elementsByType",
})
export class ElementsByTypePipe implements PipeTransform {
    transform(elements: MaestroElements): { [typeId: string]: MaestroElements } {
        const mapping = {};
        elements.forEach((element) => {
            const id = element.type.id;
            if (mapping[id]) {
                mapping[id].push(element);
            } else {
                mapping[id] = [element];
            }
        });

        return mapping;
    }
}
