import { Component, OnDestroy, OnInit } from "@angular/core";
import { LayoutService } from "app/core/services/global/layout/layout.service";

@Component({
    template: "<router-outlet></router-outlet>",
})
export class TemplateComponent implements OnInit, OnDestroy {
    constructor(private _layout: LayoutService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
    }
}
