export const DROPOWN_SETTINGS: { [mode: string]: any } = {
    single: {
        singleSelection: true,
        idField: "id",
        textField: "name",
        allowSearchFilter: true,
    },

    multiple: {
        singleSelection: false,
        idField: "id",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        allowSearchFilter: true,
    },
};
