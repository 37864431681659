import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-form-label",
    templateUrl: "./form-label.component.html",
})
export class FormLabelComponent implements OnInit {
    @Input() invalid: boolean;
    @Input() valid: boolean;

    constructor() {}

    ngOnInit() {}
}
