import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GroupService } from "app/core/services/admin/group/group.service";
import { DataService } from "app/core/services/global/data/data.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { MAESTRO_ROUTES } from "app/shared/routes";

@Component({
    selector: "app-group-form",
    templateUrl: "./group-form.component.html",
})
export class GroupFormComponent implements OnInit {
    ressource: any;
    form: FormGroup;
    activeRoute = this._route.snapshot.data.mode;

    dropdownGroupsSettings = {};
    mergedAssociableGroups: any;

    constructor(
        private formBuilder: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private _groupService: GroupService,
        private _translate: TranslateService,
        private _toaster: ToastService,
        private _dataService: DataService
    ) {}

    ngOnInit() {
        this.ressource = this._route.snapshot.data.ressource;

        const mergedData = [...this.ressource.group.children, ...this.ressource.group.associableGroups];
        let uniqueAssociableGroups = this._dataService.uniqueData(mergedData);
        this.mergedAssociableGroups = uniqueAssociableGroups.sort((a, b) => a.name.localeCompare(b.name));

        this.dropdownGroupsSettings = {
            singleSelection: false,
            idField: "id",
            textField: "name",
            selectAllText: this._translate.instant("general.select_all"),
            unSelectAllText: this._translate.instant("general.unselect_all"),
            allowSearchFilter: true,
        };

        this.initForm();
    }

    initForm() {
        let group = this.ressource.group;
        this.form = this.formBuilder.group({
            name: [group["name"] ? group["name"] : "", Validators.required],
            users: [group["users"] ? group["users"] : []],
            groups: [group["children"] ? group["children"] : []],
        });
    }

    get formControls() {
        return this.form.controls;
    }

    formSubmit() {
        if (this.form.invalid) {
            return;
        }

        if (this.ressource.group) {
            let userIds = this.form.value.users.map((user) => user.id);
            let childrenIds = this.form.value.groups.map((child) => child.id);
            this._groupService.update(this.ressource.groupId, { name: this.form.value.name, users: userIds, children: childrenIds }).subscribe(
                (data) => {
                    this._toaster.show({ type: "success", message: this._translate.instant("general.saved") });
                },
                (error) => {
                    this._toaster.show({ type: "danger", message: this._translate.instant("general.notSaved") });
                }
            );
        }
    }

    onBack() {
        this._router.navigateByUrl(`/${MAESTRO_ROUTES.admin.base}/${MAESTRO_ROUTES.admin.group}`);
    }
}
