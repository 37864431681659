import { NgModule } from "@angular/core";
import { CommonService } from "app/core/services/admin/common.service";
import { SharedModule } from "app/shared";
import { RoleFormComponent } from "./form/role-form.component";
import { RoleListModule } from "./list/role-list.module";
import { RoleRoutingModule } from "./role.routing";

@NgModule({
    declarations: [RoleFormComponent],
    imports: [RoleRoutingModule, RoleListModule, SharedModule],
    providers: [CommonService],
})
export class RoleModule {}
