import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { GroupFormComponent } from "./form/group-form.component";
import { GroupFormGuard } from "./form/group-form.guard";
import { GroupListComponent } from "./list/group-list.component";
import { GroupListGuard } from "./list/group-list.guard";



const groupRoutes: Routes = [
    {
        path: "",
        component: GroupListComponent,
        canActivate: [],
        resolve: {groups: GroupListGuard},
    },
    {
        path: `:id/${MAESTRO_ROUTES.actions.update}`,
        component: GroupFormComponent,
        resolve: { ressource: GroupFormGuard },
        data: {},
    },
];

@NgModule({
    imports: [RouterModule.forChild(groupRoutes)],
    exports: [RouterModule],
})
export class GroupRoutingModule {}
