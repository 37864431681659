import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { SidebarClassicComponent } from "./sidebar-classic.component";

@NgModule({
    imports: [SharedModule],
    declarations: [SidebarClassicComponent],
    entryComponents: [SidebarClassicComponent],
})
export class SidebarClassicModule {}
