import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { LoginComponent } from "./login.component";
import { LoginGuard } from "./login.guard";
import { LoginRoutingModule } from "./login.routing";

@NgModule({
    imports: [LoginRoutingModule, SharedModule],
    declarations: [LoginComponent],
    providers: [LoginGuard],
})
export class LoginModule {}
