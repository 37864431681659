import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class MediaService {
    constructor(private _http: HttpClient) {}

    /**
     * Get information for a specific media
     * @param id
     * @returns
     */
    getMedia(id: number): Observable<any> {
        return this._http.get(`${environment.damUrl}/media/${id}.json`);
    }

    /**
     * Update the model of a media
     *
     * @param mediaId
     * @param data
     * @returns
     */
    updateFormField(mediaId: number, data: any): Observable<any> {
        return this._http.put(`${environment.damUrl}/media/${mediaId}/form_field_update.json`, data);
    }

    /**
     * Update a field from a media's model
     * @param mediaId
     * @param data
     * @returns
     */
    updateField(mediaId: number, data: any): Observable<any> {
        return this._http.put(`${environment.damUrl}/media/${mediaId}/field.json`, data);
    }

    /**
     * Create a cropped image
     *
     * @param mediaId
     * @param base64
     * @param format
     */
    createCroppedImage(mediaId: number, crop: any, format: string = ""): Observable<any> {
        return this._http.post(`${environment.damUrl}/media/${mediaId}/crop.json`, { crop: crop, format });
    }

    /**
     * Save Copyright, Legend and validity date
     * 
     * @param mediaId
     * @param copyright
     * @param legend
     * @param dateValid
     */
    saveBaseField(mediaId: number, copyright: string, legend: string, dateValid: string){
        return this._http.put(`${environment.damUrl}/media/${mediaId}/base_form_update.json`, {copyright, legend, dateValid});
    }
}
