import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroGroup, MaestroGroups } from "app/shared/models";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class GroupService {
    constructor(private _http: HttpClient) {}

    /**
     * Return the list of the groups
     *
     * @returns
     */
    getAll(): Observable<any> {
        return this._http.get<any>(`${environment.adminApiUrl}/groups.json`);
    }

    /**
     * create a new group
     *
     * @param body
     * @returns
     */
    create(body: any): Observable<MaestroGroup> {
        return this._http.post<MaestroGroup>(`${environment.adminApiUrl}/group.json`, body);
    }

    /**
     * Return the group's data
     *
     * @param id
     * @returns
     */
    findById(id: number): Observable<any> {
        return this._http.get<any>(`${environment.adminApiUrl}/group/${id}.json`);
    }

    /**
     * Update the group's data
     *
     * @param id
     * @param body
     * @returns
     */
    update(id: number, body: any): Observable<MaestroGroup> {
        return this._http.put<MaestroGroup>(`${environment.adminApiUrl}/group/${id}.json`, body);
    }

    /**
     * Delete a group
     *
     * @param id
     * @returns
     */
    delete(id: number): Observable<MaestroGroup> {
        return this._http.delete<MaestroGroup>(`${environment.adminApiUrl}/group/${id}.json`);
    }

    /**
     * Get group's projects to see if can be deleted or not
     *
     * @param id
     * @returns
     */
    getAssociatedProjects(id: number): Observable<any> {
        return this._http.get<MaestroGroup>(`${environment.projectsUrl}/project/group/${id}.json`);
    }

    /**
     * Get nested groups
     * @param groups
     * @returns
     */
    getRecursiveGroups(groups) {
        let arrayGroups = [];

        groups.forEach((group) => {
            arrayGroups.push(group);
            if (group.data.length) {
                let subGroups = this.getRecursiveGroups(group.data);

                if (subGroups.length) {
                    arrayGroups = [...arrayGroups, ...subGroups];
                }
            }
        });

        return arrayGroups;
    }

    /**
     * Deconstruct each object to create new one without named attributes : avoid to appear in table as [Object object]
     * @param groups
     * @returns
     */
    removeChildrenFromGroup(groups: MaestroGroups) {
        return groups.map(({ data, ...keepAttrs }) => keepAttrs);
    }
}
