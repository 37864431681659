import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { PimWorkflowListComponent } from "./pim-workflow-list.component";
import { PimWorkflowListGuard } from "./pim-workflow-list.guard";

@NgModule({
    declarations: [PimWorkflowListComponent],
    imports: [SharedModule, SharedComponentsModule],
    providers: [PimWorkflowListGuard],
})
export class PimWorkflowListModule {}
