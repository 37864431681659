export enum ActionType {
    View,
    FormUpdate,
    Update,
    Export,
    Delete,
    Duplicate,
    Story,
    DownloadPdf,
}
export type ActionButtonsDef = ButtonDef[];
export type ActionButtonDef = ButtonDef;

interface ButtonDef {
    right?: Right;
    type: ActionType;
    groupRight?: any;
    // objectRight?: Right;
    operation?: string;
}

export class ActionEvent {
    action: ActionType;
    rowId: number;
}

declare type Right =
    | "SYSPAD_PIM_ELEMENT_LIST"
    | "SYSPAD_PIM_ELEMENT_READ"
    | "SYSPAD_PIM_ELEMENT_CREATE"
    | "SYSPAD_PIM_ELEMENT_UPDATE"
    | "SYSPAD_PIM_ELEMENT_UPDATE"
    | "SYSPAD_PIM_ELEMENT_DELETE"
    | "SYSPAD_PIM_ELEMENTTYPE_LIST"
    | "SYSPAD_PIM_ELEMENTTYPE_READ"
    | "SYSPAD_PIM_ELEMENTTYPE_CREATE"
    | "SYSPAD_PIM_ELEMENTTYPE_UPDATE"
    | "SYSPAD_PIM_ELEMENTTYPE_DELETE"
    | "SYSPAD_PIM_TAG_LIST"
    | "SYSPAD_PIM_TAG_READ"
    | "SYSPAD_PIM_TAG_CREATE"
    | "SYSPAD_PIM_TAG_UPDATE"
    | "SYSPAD_PIM_TAG_DELETE"
    | "SYSPAD_PIM_LIST_LIST"
    | "SYSPAD_PIM_LIST_READ"
    | "SYSPAD_PIM_LIST_CREATE"
    | "SYSPAD_PIM_LIST_UPDATE"
    | "SYSPAD_PIM_LIST_DELETE"
    | "SYSPAD_PIM_TAB_LIST"
    | "SYSPAD_PIM_TAB_READ"
    | "SYSPAD_PIM_TAB_CREATE"
    | "SYSPAD_PIM_TAB_UPDATE"
    | "SYSPAD_PIM_TAB_DELETE"
    | "SYSPAD_PIM_FIELDSET_LIST"
    | "SYSPAD_PIM_FIELDSET_READ"
    | "SYSPAD_PIM_FIELDSET_CREATE"
    | "SYSPAD_PIM_FIELDSET_UPDATE"
    | "SYSPAD_PIM_FIELDSET_DELETE"
    | "SYSPAD_ADMIN_USER_LIST"
    | "SYSPAD_ADMIN_USER_CREATE"
    | "SYSPAD_ADMIN_USER_UPDATE"
    | "SYSPAD_ADMIN_USER_DELETE"
    | "SYSPAD_ADMIN_ROLE_LIST"
    | "SYSPAD_ADMIN_ROLE_CREATE"
    | "SYSPAD_ADMIN_ROLE_UPDATE"
    | "SYSPAD_ADMIN_ROLE_DELETE"
    | "MAESTRO_PROJECT_PROJECTS_LIST"
    | "MAESTRO_PROJECT_PROJECTS_READ"
    | "MAESTRO_PROJECT_PROJECTS_CREATE"
    | "MAESTRO_PROJECT_PROJECTS_UPDATE"
    | "MAESTRO_PROJECT_PROJECTS_DELETE"
    | "SYSPAD_DAM_TAG_LIST"
    | "SYSPAD_DAM_TAG_READ"
    | "SYSPAD_DAM_TAG_CREATE"
    | "SYSPAD_DAM_TAG_UPDATE"
    | "SYSPAD_DAM_TAG_DELETE"
    | "MAESTRO_ADMIN_GROUP_LIST"
    | "MAESTRO_ADMIN_GROUP_READ"
    | "MAESTRO_ADMIN_GROUP_CREATE"
    | "MAESTRO_ADMIN_GROUP_UPDATE"
    | "MAESTRO_ADMIN_GROUP_DELETE"
    | "MAESTRO_ADMIN_WORKFLOW_UPDATE"
    | "MAESTRO_ADMIN_WORKFLOW_DELETE"
    | "MAESTRO_DAM_DATAMODEL_UPDATE"
    | "MAESTRO_DAM_DATAMODEL_DELETE";
