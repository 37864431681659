import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faCloudUploadAlt, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { DropTargetOptions, HttpClientUploadService, InputFileOptions } from "@wkoza/ngx-upload";
import { UserService } from "app/core/services/admin/user/user.service";
import { ElementService } from "app/core/services/pim/element.service";
import { TagService } from "app/core/services/tag/tag.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { UploadService } from "app/core/services/upload/upload.service";
import { MediaType } from "app/shared/helpers/media-type.helper";
import { ACL } from "app/shared/models/acl";
import { LazyLoadDataModel } from "app/shared/models/lazy-load-data";
import { Thumb } from "app/shared/models/thumb";
import { LazyLoadEvent, SelectItem } from "primeng-lts/api";
import { Subscription } from "rxjs";

@Component({
    selector: "app-form-media",
    templateUrl: "./form-media.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormMediaComponent),
            multi: true,
        },
    ],
})
export class FormMediaComponent implements OnInit, OnDestroy, ControlValueAccessor {
    private subscriptions: Subscription[] = [];

    public elementMedias: Thumb[] = [];
    public mediasModal: Thumb[] = [];
    public mediasLinkFiltered: Thumb[] = [];
    public selectedMedias: number[] = [];
    state: string = "all";
    private _acceptList = [];
    tabIndex = 0;

    options: DropTargetOptions = {
        color: "dropZoneColor",
        colorDrag: "dropZoneColorDrag",
        colorDrop: "dropZoneColorDrop",
        multiple: true,
        accept: this._acceptList,
    };

    optionsInput: InputFileOptions = {
        multiple: true,
        accept: this._acceptList,
    };

    readonly faUpload = faCloudUploadAlt;

    public disabled = true;

    claroAvailable = false;

    sortOptions: SelectItem[] = []; // Sort data based on media fields
    recordsFiltered: number = 0;
    globalFilter: string = "";
    currentDatatableFilters: LazyLoadEvent = {}; // Use to reload data when search filter is used
    filters: any = {
        tags: [],
        type: [], // Contains extension
    };

    formArray: FormArray;
    mediaTypes: string[] = [];

    readonly faPlus = faPlusCircle;
    readonly faTrash = faTrashAlt;

    @Input() id: string;
    @Input() disabledImport: boolean = false;
    @Input() singleSelection: boolean = false;
    acl: ACL;
    readonly faPlusSquare = faPlusSquare;

    @Output() mediaChange = new EventEmitter<any>();

    linkAdded = false;
    private mergedMediasModal = [];

    private _changeCallbacks: Array<(str: string) => any> = [];
    private _touchedCallbacks: Array<(str: string) => any> = [];

    constructor(
        private elementService: ElementService,
        private _thumbnailService: ThumbnailsService,
        public swalTargets: SwalPortalTargets,
        private _translateService: TranslateService,
        private _userService: UserService,
        private formBuilder: FormBuilder,
        private _uploadService: UploadService,
        public uploader: HttpClientUploadService,
        private _tagService: TagService
    ) {}

    ngOnInit(): void {
        this.acl = this._userService.getUserAclFromToken();
        this.formArray = this.formBuilder.array([]);
        this.mediaTypes = Object.keys(MediaType);

        // Get available media types
        this._uploadService.getMediaTypes().subscribe((data) => {
            this._acceptList = [];
            data.map((v) => v.header).forEach((header) => {
                this._acceptList.push(...header.split(","));
            });
            this.options.accept = this._acceptList;
            this.optionsInput.accept = this._acceptList;
        });

        this.uploader.onAddToQueue$.subscribe(() => (this.disabled = false));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.mediasLinkFiltered = [];
        this._thumbnailService.sendValuesObservables(this.mediasLinkFiltered);
    }

    writeValue(obj: any): void {
        this._onChange(obj);
    }

    registerOnChange(fn: (str: any) => any) {
        this._changeCallbacks.push(fn);
    }

    registerOnTouched(fn: (str: any) => any): void {
        this._touchedCallbacks.push(fn);
    }

    private _onChange(obj: any) {
        this._changeCallbacks.forEach((cb) => cb(obj));
    }

    /**
     * Prepare a media to be linked
     *
     * @param event
     */
    selectMedia(event: Event): void {
        const element = event.target as HTMLInputElement;
        const value = Number(element.value);

        if (element.checked) {
            const found = this.selectedMedias.find((id) => value === id);

            if (!found) {
                this.selectedMedias.push(value);
            }
        } else {
            for (let i = 0; i < this.selectedMedias.length; i++) {
                if (this.selectedMedias[i] === value) {
                    this.selectedMedias.splice(i, 1);
                }
            }
        }
    }

    /**
     * Link media to this element
     */
    addSelectedMedias(): void {
        let medias = this.elementService.manageMedia(true, this.elementMedias, this.selectedMedias, this.mergedMediasModal);

        if (this.formArray.length && this.formArray.valid) {
            this.formArray.controls.map((formControlMedia: FormGroup) => {
                if (formControlMedia.value.link && formControlMedia.value.type) {
                    let position = 1;

                    if (medias.length) {
                        // Find same type
                        let filtered = medias.filter((m) => {
                            return m.type == formControlMedia.value.type;
                        });

                        if (filtered.length) {
                            // Find last position
                            const lastMedia = filtered.reduce((prev, current) => (prev && prev.position > current.position ? prev : current));
                            position = lastMedia.position + 1;
                        }
                    }

                    medias.push({
                        id: null,
                        link: formControlMedia.value.link,
                        position: position,
                        type: formControlMedia.value.type,
                        loadMetadata: formControlMedia.value.loadMetadata,
                    });
                }
            });
        }

        this._onChange(JSON.stringify(medias));
        this.setElementMedias(medias);
    }

    private setElementMedias(medias): void {
        const values = [];

        for (const dataKey of medias) {
            let thumb = new Thumb().deserialize(dataKey);
            thumb.extension = "media";
            values.push(thumb);
        }

        this.mediasLinkFiltered = values;
    }

    loadList(event: LazyLoadEvent): void {
        event.globalFilter = this.globalFilter; // Need to be forced since dataview will not bind it himself

        const customFilters = this.getCustomFilters();

        const requestFilters: LazyLoadDataModel = { lazyLoadFilters: event, customFilters: customFilters, state: this.state };

        this.subscriptions.push(
            this._thumbnailService.getDataviewMedias(JSON.stringify(requestFilters)).subscribe((resp) => {
                const data = resp.data;

                this.mediasModal = data.medias; // Replaced when change pagination so only selected medias from last page index focused are added : need another variable to send to manageMedia() : mergedMediasModal

                data.medias.forEach((dm) => {
                    let found = this.mergedMediasModal.find((m) => m.id === dm.id);

                    if (undefined === found) {
                        this.mergedMediasModal.push(dm);
                    }
                });

                let translatedOptions = [];
                this.sortOptions = data.sortOptions.forEach((option) => {
                    option.label = this._translateService.instant("dataview.sortField." + option.label) + " " + this._translateService.instant("dataview.sortParameter." + option.translationKey);
                    translatedOptions.push(option);
                });

                this.sortOptions = translatedOptions;
                this.recordsFiltered = data.recordsFiltered;
                this.currentDatatableFilters = event;
            })
        );
    }

    loadListWithSearch(value: string): void {
        this.globalFilter = value;

        if ("" !== value) {
            this.currentDatatableFilters.first = 0;
        }

        this.loadList(this.currentDatatableFilters);
    }

    manageFilter(event: any, selected: boolean = true, filter: string = "tags"): void {
        const objectProperty = event.hasOwnProperty("id");
        if (objectProperty) {
            const found = this.filters[filter].find((t) => (objectProperty ? event.id === t.id : event === t));

            if (selected) {
                if (!found) {
                    this.filters[filter].push(event);
                    this._tagService.setTagsFilter(this.filters[filter]);
                    this.loadList(this.currentDatatableFilters);
                }
            } else {
                if (found) {
                    const index = this.filters[filter].findIndex((f) => (objectProperty ? event.id === f.id : event === f));
                    this.filters[filter].splice(index, 1);
                    this._tagService.setTagsFilter(this.filters[filter]);
                    this.loadList(this.currentDatatableFilters);
                }
            }
        } else {
            if (Array.isArray(event)) {
                this.filters[filter] = [];
                event.forEach((tag) => {
                    const objectProperty = event.hasOwnProperty("id");
                    const found = this.filters[filter].findIndex((t) => (objectProperty ? tag.id === t.id : tag === t));

                    if (found < 0) {
                        this.filters[filter].push(tag);
                    }
                });
                this._tagService.setTagsFilter(this.filters[filter]);
                this.loadList(this.currentDatatableFilters);
            }
        }
    }

    getCustomFilters(): any {
        let customFilters = { ...this.filters };

        Object.keys(customFilters).forEach((cf) => {
            customFilters[cf] = this.filters[cf].map((f) => f.id);
        });

        return customFilters;
    }

    changeHideExpireMedia(event) {
        this.state = event;
        this.loadList(this.currentDatatableFilters);
    }

    addMediaLink(): void {
        const formGroup = this.formBuilder.group({
            link: ["", Validators.required],
            type: ["", Validators.required],
            loadMetadata: [false],
        });

        this.formArray.push(formGroup);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
        this.linkAdded = true;
    }

    deleteMediaLink(index: number): void {
        this.formArray.removeAt(index);
        this.linkAdded = false;
    }

    upload() {
        let isPicto = false;
        let isClaro = false;

        if (this.acl.MAESTRO_DAM_PICTO_CREATE === 1) {
            isPicto = $("#picto:checked").length === 1;
        }

        if (this.claroAvailable === true) {
            isClaro = $("#claro:checked").length === 1;
        }

        this._uploadService
            .uploadMedias(
                this.uploader.queue.map((fi) => fi.file),
                isPicto,
                isClaro
            )
            .subscribe((data) => {
                this.loadList(this.currentDatatableFilters);

                const importedMedias = data.data;

                importedMedias.forEach((importedMedia) => {
                    this.selectedMedias.push(importedMedia.id);
                });

                this.uploader.queue = [];
            });
    }
}
