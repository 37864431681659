import { Injectable } from "@angular/core";
import { MaestroElement, Tabs } from "app/shared/models";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class ElementExportPdfService {
    element: MaestroElement;
    tabs: Tabs = [];
    pdfContent: any[];
    styles = {
        header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 5],
        },
        subheader: {
            fontSize: 12,
            bold: true,
            margin: [0, 0, 0, 10],
        },
        header_2: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
        },
        header_3: {
            fontSize: 14,
            bold: true,
        },
        tableExample: {
            margin: [0, 5, 0, 15],
        },
        tableOpacityExample: {
            margin: [0, 5, 0, 15],
            fillColor: "blue",
            fillOpacity: 0.3,
        },
        tableHeader: {
            bold: true,
            fontSize: 13,
            color: "black",
        },
    };

    generate(element, tabs) {
        this.element = element;
        this.tabs = tabs;
        this.pdfContent = [];

        /**
         * Titre
         */
        this.pdfContent.push({
            text: this.element.name,
            style: "header",
        });

        /**
         * Type
         */
        this.pdfContent.push({
            text: "Modèle de données : " + this.element.elementType,
            style: "subheader",
        });

        /**
         * Gestion des tabs et de leurs champs
         */
        this.tabs.forEach((tab, index) => {
            this.pdfContent.push({
                text: tab.name,
                style: "header_2",
                pageBreak: !index ? null : "before",
            });

            const rows = [];
            rows.push([
                { text: "Champ", style: "tableHeader" },
                { text: "Valeur", style: "tableHeader" },
            ]);

            tab.fields.forEach((field) => {
                this.formatFieldDocx(field).forEach((row) => {
                    rows.push(row);
                });
            });

            this.pdfContent.push({
                style: "tableExample",
                table: {
                    headerRows: 1,
                    widths: [150, "*"],
                    body: rows,
                },
            });
        });

        /**
         * Intégration du contenu
         */

        const documentDefinition = {
            content: this.pdfContent,
            styles: this.styles,
            footer: function (currentPage, pageCount) {
                return {
                    text: currentPage.toString() + " of " + pageCount,
                    alignment: "center",
                };
            },
        };
        pdfMake.createPdf(documentDefinition).download(this.element.name);
    }

    private formatFieldDocx(field) {
        /**
         * Gestion des champs simples
         */
        if (field.elementValue) {
            if (field.elementValue[0].length > 1) {
                return [["error", "value is array"]];
            }
            if (Array.isArray(field.elementValue[0].value)) {
                return [[{ text: field.name, bold: true }, JSON.stringify(field.elementValue[0].value)]];
            }
            return [[{ text: field.name, bold: true }, field.elementValue[0].value]];
        } else if (field.collections) {
            /**
             * Gestion des collections
             */
            const collectionRows = [];

            collectionRows.push([
                { text: "Champ", style: "tableHeader" },
                { text: "Valeur", style: "tableHeader" },
            ]);

            field.collections.forEach((collection) => {
                collection.elementValueView.forEach((collectionField) => {
                    collectionRows.push([{ text: collectionField.input, bold: true }, Array.isArray(collectionField.value) ? collectionField.value.join(", ") : collectionField.value]);
                });
            });

            return [
                [
                    { text: field.name, bold: true },
                    {
                        style: "tableExample",
                        table: {
                            widths: [150, "*"],
                            body: collectionRows,
                        },
                    },
                ],
            ];
        } else if (field.sections) {
            /**
             * Gestion des sections
             */
            const sectionRows = [];
            Object.keys(field.sections).forEach((section) => {
                sectionRows.push([{ text: section, colSpan: 2, style: "header_3" }, {}]);

                field.sections[section].forEach((sectionField) => {
                    this.formatFieldDocx(sectionField).forEach((sectionRow) => {
                        sectionRows.push(sectionRow);
                    });
                });
            });
            return sectionRows;
        } else {
            return [["error", "unknown type"]];
        }
    }
}
