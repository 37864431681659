import { NgModule } from "@angular/core";
import { PimHomeModule } from "./features/home/home.module";
import { PimComponent } from "./pim.component";
import { PimRoutingModule } from "./pim.routing";

@NgModule({
    imports: [PimRoutingModule, PimHomeModule],
    declarations: [PimComponent],
})
export class PimModule {}
