import { Component, OnInit } from "@angular/core";
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-toast",
    templateUrl: "./toast.component.html",
})
export class ToastComponent implements OnInit {
    messages: { message: string; type: string }[];

    readonly faCheck = faCheckCircle;
    readonly faWarning = faExclamationTriangle;
    readonly faDanger = faExclamationCircle;

    constructor() {}

    ngOnInit() {}
}
