import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES as routes } from "app/shared/routes";
import { ProjectListComponent } from "./features/list/project-list.component";
import { ProjectListResolver } from "./features/list/project-list.resolver";
import { ProjectModule } from "./features/project/project.module";
import { TemplatesModule } from "./features/templates/templates.module";
import { TwigModule } from "./features/twig/twig.module";
import { VariableModule } from "./features/variable/variable.module";
import { ProjectsComponent } from "./projects.component";

const projectRoutes: Routes = [
    {
        path: "",
        component: ProjectsComponent,
        children: [
            {
                path: routes.projects.list,
                component: ProjectListComponent,
                resolve: { projects: ProjectListResolver },
            },
            {
                path: routes.projects.templates,
                loadChildren: () => TemplatesModule,
            },
            {
                path: routes.projects.twig,
                loadChildren: () => TwigModule
            },
            {
                path: routes.projects.variables,
                loadChildren: () => VariableModule
            },
            { path: "", redirectTo: routes.projects.list, pathMatch: "full" },
            { path: "", loadChildren: () => ProjectModule },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(projectRoutes)],
    exports: [RouterModule],
})
export class ProjectsRoutingModule {}
