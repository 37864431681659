import { Component } from "@angular/core";
import { SidebarContentComponent } from "../..";

import { SidebarTreeNodeOptions } from "./sidebar-tree-node";

@Component({
    selector: "app-sidebar-tree-node",
    templateUrl: "./sidebar-tree-node.component.html",
    styleUrls: ["./sidebar-tree-node.component.scss"],
})
export class SidebarTreeNodeComponent implements SidebarContentComponent<SidebarTreeNodeOptions> {
    options: SidebarTreeNodeOptions;
}
