
<div class="sidebar" style="overflow:hidden">
    <nav class="sidebar-nav">
        <ul class="nav navbar-nav d-lg-none border-bottom border-primary">
            <li *ngIf="activatedModules.dashboard" class="nav-item">
                <a class="nav-link" [routerLink]="['/' + routes.dashboard.base]" routerLinkActive="active">
                    <fa-icon [icon]="dashboardIcon" [fixedWidth]="true"></fa-icon>
                    <span class="d-inline-block ml-1">
                        {{ 'header.dashboard' | translate }}
                    </span>
                </a>
            </li>

            <li *ngIf="activatedModules.pim" class="nav-item">
                <a class="nav-link" [routerLink]="['/' + routes.pim.base]" routerLinkActive="active">
                    <fa-icon [icon]="pimIcon" [fixedWidth]="true"></fa-icon>
                    <span class="d-inline-block ml-1">
                        {{ 'header.pim' | translate }}
                    </span>
                </a>
            </li>
            <li *ngIf="activatedModules.dam" class="nav-item">
                <a class="nav-link" [routerLink]="['/' + routes.dam.base]" routerLinkActive="active">
                    <fa-icon [icon]="damIcon" [fixedWidth]="true"></fa-icon>
                    <span class="d-inline-block ml-1">
                        {{ 'header.dam' | translate }}
                    </span>
                </a>
            </li>
            <li *ngIf="activatedModules.project" class="nav-item">
                <a class="nav-link" [routerLink]="['/' + routes.projects.base]" routerLinkActive="active">
                    <fa-icon [icon]="projectIcon" [fixedWidth]="true"></fa-icon>
                    <span class="d-inline-block ml-1">
                        {{ 'header.project' | translate }}
                    </span>
                </a>
            </li>
        </ul>

        <ng-template #sidebarContent></ng-template>
    </nav>
</div>
