<div class ="row text-right mb-3">
    <div class="col">
        <button class="btn btn-outline-primary" [ngClass]="{'text-white' : !selectedTags || !selectedTags.length || !actionUpdate }" [disabled]="!selectedTags || !selectedTags.length || !actionUpdate" (click)="onActionClick(actionUpdate)">
            {{ 'general.updateSelection' | translate }} <fa-icon [icon]="faTools" [fixedWidth]="true"></fa-icon>
        </button>
        <button class="btn btn-outline-danger ml-2" [ngClass]="{'text-white' : !selectedTags || !selectedTags.length || !actionDelete }" [disabled]="!selectedTags || !selectedTags.length || !actionDelete" (click)="onActionClick(actionDelete)">
            {{ 'general.deleteSelection' | translate }} <fa-icon [icon]="faTrash" [fixedWidth]="true"></fa-icon>
        </button>
    </div>
</div>

<div class="row text-center bg-white">
    <div class="col">
        <label for="propagateSelectionUp">{{ 'general.propagate.selection.up' | translate }}</label>
        <label class="switch switch-label switch-sm switch-outline-primary ml-2 pt-3">
            <input id="propagateSelectionUp" class="switch-input" type="checkbox" [checked]="propagateSelectionUp" (change)="propagateSelectionUp = !propagateSelectionUp"/>
            <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
        </label>
    </div>
    <div class="col">
        <label for="propagateSelectionDown">{{ 'general.propagate.selection.down' | translate }}</label>
        <label class="switch switch-label switch-sm switch-outline-primary ml-2 pt-3">
            <input id="propagateSelectionDown" class="switch-input" type="checkbox" [checked]="propagateSelectionDown" (change)="propagateSelectionDown = !propagateSelectionDown"/>
            <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
        </label>
    </div>
    <div class="col pt-3 pb-3">
        <button *ngIf="!allExpanded" class="btn btn-primary mr-2" (click)="expandRecursive(true)">{{ 'datatable.expand' | translate }}</button>
        <button *ngIf="allExpanded" class="btn btn-primary mr-2"(click)="expandRecursive(false)">{{ 'datatable.collapse' | translate }}</button>
    </div>
    <div class="col pt-3 pb-3">
        <button *ngIf="!allSelected" class="btn btn-primary mr-2" (click)="selectAll(true)">{{ 'datatable.selectAll' | translate }}</button>
        <button *ngIf="allSelected" class="btn btn-primary" (click)="selectAll(false)">{{ 'datatable.unselectAll' | translate }}</button>
    </div>
</div>

<div class="row">
    <p-tree class="w-100" [value]="tagNodes" selectionMode="checkbox" [filter]="true" filterMode="strict" [(selection)]="selectedTags" [propagateSelectionUp]="propagateSelectionUp" [propagateSelectionDown]="propagateSelectionDown"></p-tree>
</div>

<div #createModal data-backdrop="false" class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'breadcrumb.tags.create' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="chips = []">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="alert alert-info alert-dismissible fade show" role="alert">
                    <i class="pi pi-info-circle"></i> {{ tagsSeparator }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="form-group p-fluid">
                    <p-chips class="form-control" [(ngModel)]="chips" allowDuplicate="false" class="w-100">
                        <ng-template let-item pTemplate="item">
                            <span>{{item}}  <i class="pi pi-tag"></i></span>
                        </ng-template>
                    </p-chips>
                </div>
                <div class="form-group">
                    <select class="form-control" id="modalTagParent">
                        <option value="-1">{{ "tags.no_parent" | translate }}</option>
                        <option *ngFor="let tag of allTagsForCreation" value="{{ tag.id }}">{{ tag.label }}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="chips = []">{{ 'general.close' | translate }}</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="_create()" [disabled]="!chips.length">{{ 'general.save' | translate }}</button>
            </div>
        </div>
    </div>
</div>