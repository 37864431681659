import { Injectable } from "@angular/core";
import { MaestroElement, Tabs } from "app/shared/models";
import * as XLSX from "xlsx";

@Injectable()
export class ElementExportSheetService {
    element: MaestroElement;
    tabs: Tabs = [];
    excelContent: any[];

    generate(element, tabs) {
        this.element = element;
        this.tabs = tabs;
        this.excelContent = [];

        const wb = XLSX.utils.book_new();

        wb.Props = {
            Title: this.element.name,
        };

        /**
         * Gestion des tabs et de leurs champs
         */
        this.tabs.forEach((tab, index) => {
            const rows = [];
            rows.push(["Champ", "Valeur"]);

            tab.fields.forEach((field) => {
                this.formatFieldDocx(field).forEach((row) => {
                    rows.push(row);
                });
            });

            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
            XLSX.utils.book_append_sheet(wb, ws, tab.name);
        });

        /**
         * Intégration du contenu
         */
        XLSX.writeFile(wb, this.element.name + ".xlsx");
    }

    private formatFieldDocx(field) {
        /**
         * Gestion des champs simples
         */
        if (field.elementValue) {
            if (field.elementValue[0].length > 1) {
                return [["error", "value is array"]];
            }
            if (Array.isArray(field.elementValue[0].value)) {
                return [[field.name, JSON.stringify(field.elementValue[0].value)]];
            }
            return [[field.name, field.elementValue[0].value]];
        } else if (field.collections) {
            /**
             * Gestion des collections
             */
            let collectionRows = "";

            field.collections.forEach((collection) => {
                collection.elementValueView.forEach((collectionField) => {
                    collectionRows += collectionField.input + " : " + (Array.isArray(collectionField.value) ? collectionField.value.join(", ") : collectionField.value) + "\n";
                });
            });

            return [[field.name, collectionRows]];
        } else if (field.sections) {
            /**
             * Gestion des sections
             */
            const sectionRows = [];
            Object.keys(field.sections).forEach((section) => {
                sectionRows.push(["", ""]);
                sectionRows.push([section, ""]);

                field.sections[section].forEach((sectionField) => {
                    this.formatFieldDocx(sectionField).forEach((sectionRow) => {
                        sectionRows.push(sectionRow);
                    });
                });
            });
            return sectionRows;
        } else {
            return [["error", "unknown type"]];
        }
    }
}
