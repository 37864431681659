import { Injectable } from "@angular/core";
import { ViewButtonDef } from "app/shared/components/view-buttons";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class ViewButtonsService {
    private _enabledSource: Subject<boolean> = new Subject<boolean>();
    private _clickSource: Subject<ViewButtonDef> = new Subject<ViewButtonDef>();
    private _actionsSource: Subject<any[]> = new BehaviorSubject<any[]>([]);
    private _defaultSource: Subject<ViewButtonDef> = new Subject<ViewButtonDef>();

    readonly enabled$ = this._enabledSource.asObservable();
    readonly click$ = this._clickSource.asObservable();
    readonly actions$ = this._actionsSource.asObservable();
    readonly default$ = this._defaultSource.asObservable();

    private _actions: any[];
    private _enable: boolean;
    private _default: ViewButtonDef;

    /**
     * Set value for enable to determine if we have to dispaly all buttons
     */
    set enable(value: boolean) {
        if (this._enable !== value) {
            this._enable = value;
            this._enabledSource.next(value);
        }
    }

    /**
     * Set the different actions available
     */
    set actions(value: any[]) {
        if (this._actions !== value) {
            this._actions = value;
            this._actionsSource.next(value);
        }
    }

    /**
     * Set the default value for view buttons
     */
    set default(value: ViewButtonDef) {
        if (this._default !== value) {
            this._default = value;
            this._defaultSource.next(value);
        }
    }

    click(event: ViewButtonDef) {
        this._clickSource.next(event);
    }
}
