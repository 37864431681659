import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { FormHeaderComponent } from "./form-header.component";

@NgModule({
    imports: [SharedModule],
    declarations: [FormHeaderComponent],
    exports: [FormHeaderComponent],
})
export class FormHeaderModule {}
