import { Injectable } from "@angular/core";
import { MenuItems } from "app/shared/components/breadcrumb";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class BreadcrumbMenuService {
    private _itemsSource = new BehaviorSubject<MenuItems>(null);

    readonly items$ = this._itemsSource.asObservable();

    private _items: MenuItems;

    /**
     * Configure the items present in the dropdown under the cog
     */
    set items(menu: MenuItems) {
        this._items = menu;
        this._itemsSource.next(this._items);
    }
}
