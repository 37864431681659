<div class="row">
    <div [class]="data.imageSrc ? 'col-8' : 'col-12'">
        <ng-container *ngIf="!updateName">
            <div class="font-medium text-3xl text-900 ">
                {{ dataName }}
                <fa-icon *ngIf="nameChange.observers.length" (click)="updateNameFunction()" class="text-primary font-lg pointer line-height-3  float-right" [icon]="faEdit"></fa-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="updateName">
            <div class="d-flex">
                <input [disabled]="disabledHeader" class="form-control form-control-lg mb-2" [(ngModel)]="dataName" type="text" [value]="dataName"/>
                <fa-icon *ngIf="dataName !== nameAtOpening" (click)="onSaveName(dataName)" class="text-primary font-lg pointer ml-2" [icon]="faSave"></fa-icon>
                <fa-icon (click)="updateNameFunction()" class="text-muted font-lg pointer ml-2" [icon]="faClose"></fa-icon>
            </div>
        </ng-container>
    </div>
    <div *ngIf="data.imageSrc" class="col-4 ">
        <img [src]="data.imageSrc" style="max-width: 100%; height: auto" />
    </div>
</div>
<div (click)="changeDisplay()" class="pointer text-center text-muted">
    <span  class="pi pi-fw " [ngClass]="(displayAll === true) ? 'pi-chevron-circle-up' : 'pi-chevron-circle-down'"></span>
    <span *ngIf="!displayAll">{{ "general.moreDetails" | translate }}</span>
    <span *ngIf="displayAll">{{ "general.lessDetails" | translate }}</span>
</div>

<ul *ngIf="displayAll" class="list-none p-0 m-0">
    <li class="flex align-items-center py-2 px-2 flex-wrap">
        <div class="text-500 w-6 md:w-2 font-medium">{{ 'pim.elementTypes.title' | translate }}</div>
        <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
            {{ data.subTitle }}
        </div>
    </li>
    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap">
        <div class="text-500 w-6 md:w-2 font-medium">Version</div>
        <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
            <div class="btn-group btn-group-sm" *ngIf="versions && versions.length && version.observers.length">
                <div class="btn-group btn-group-sm">
                    <button [ngbTooltip]="versionName" [disabled]="versions.length <= 1" class="btn btn-primary rounded-right text-truncate" [ngClass]="{ 'dropdown-toggle': versions.length > 1 }" type="button" data-toggle="dropdown" aria-expanded="false" style="width: auto;" >
                        {{ versionName }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div *ngIf="versions.length > 1">
                            <a *ngFor="let dataVersion of versions; let i = index" class="dropdown-item" (click)="onChange(dataVersion.id, dataVersion.version_number)" >
                                {{ dataVersion.title }} - v.{{ dataVersion.version_number.toFixed(2) }}
                            </a>
                        </div>
                    </div>
                </div>
                <button *ngIf="!disabled" (click)="onNewVersionClick()" class="btn btn-primary">
                    <fa-icon [icon]="faPlus"></fa-icon>
                </button>
            </div>
        </div>
    </li>
    <li class="flex align-items-center py-2 px-2 border-top-1  surface-border flex-wrap">
        <div class="text-500 w-6 md:w-2 font-medium">{{ 'general.date.create' | translate }}</div>
        <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
            {{ data.creationDate }}
        </div>
    </li>
    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap" [ngClass]="(displayAll === true) ? 'border-bottom-1 surface-border' : ''">
        <div class="text-500 w-6 md:w-2 font-medium">{{ 'general.date.update' | translate }}</div>
        <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
            {{ data.updateDate }}
        </div>
    </li>
    <li *ngIf="data.tagList" class="flex align-items-center py-2 px-2 flex-wrap" >
        <div class="text-500 w-6 md:w-2 font-medium">{{ 'tags.title' | translate }}</div>
        <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
            <h5 *ngFor="let tag of data.tagList" class="d-inline">
                <span class="badge badge-primary d-inline-block">{{ tag }}</span>
            </h5>
        </div>
    </li>
</ul>

