import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ListCascadeService } from "app/core/services/pim/listcascade.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { Subscription } from "rxjs";

@Component({
    selector: "app-listcascade-list",
    templateUrl: "./listcascade-list.component.html",
})
export class ListCascadeListComponent implements OnInit, OnDestroy {

    table: [string, string][];
    columns: string[];

    acl: ACL;

    _lists: any;

    private actionButtonSource: Subscription;

    private _searchSubscription: Subscription;

    readonly actions: ActionButtonsDef = [];


    constructor(
        private _modalService: SwalModalService,
        private _translateService: TranslateService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _layout: LayoutService,
        private _tableDataPipe: TableDataPipe,
        private _authService: AuthService,
        private _listcascadeService: ListCascadeService
    ){

    }
    ngOnInit(): void {
        this.acl = this._authService.getUserAclFromToken();

        if (this.acl.SYSPAD_PIM_LIST_CREATE) {
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "pim.lists.create";
            this.actionButtonSource = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
        }
        if (this.acl.SYSPAD_PIM_LIST_UPDATE) {
            this.actions.push({ type: ActionType.Update, right: "SYSPAD_PIM_LIST_UPDATE" });
        }

        if (this.acl.SYSPAD_PIM_LIST_DELETE) {
            this.actions.push({ type: ActionType.Delete, right: "SYSPAD_PIM_LIST_DELETE" });
        }

        this._lists = this._route.snapshot.data.lists.data;

        this._initList(this._lists);
    }

    ngOnDestroy(): void {
        if (this.actionButtonSource) {
            this.actionButtonSource.unsubscribe();
        }
        
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
    }


    onCreate(){
        this._openCreateModal();
    }

    private _openCreateModal() {
        return this._modalService
            .open({
                title: this._translateService.instant("lists.create"),
                showCloseButton: true,
                confirmButtonText: this.acl.SYSPAD_PIM_LIST_UPDATE ? this._translateService.instant("general.create_open") : this._translateService.instant("general.create"),
                confirmButtonColor: "$maestro",
                showCancelButton: true,
                cancelButtonText: this._translateService.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                html: '<div class="form-group">' + '<input class="form-control" type="text" placeholder="" id="ModalListTitle">' + "</div>",
            })
            .then((result) => {
                if (result.value) {
                    this._create($("#ModalListTitle").val(), result.dismiss || !this.acl.SYSPAD_PIM_LIST_UPDATE ? false : true);
                }
            });
    }

    /**
     * Create a list
     *
     * @param name
     * @param open
     */
    private _create(name, open = false) {
        if (name) {
            this._listcascadeService.createListCascade(name).subscribe(
                (data: any) => {
                    this._modalService.success(this._translateService.instant("general.created"));
                    if (open) {
                        this._router.navigate(["update", data.data.id], { relativeTo: this._route });
                    } else {
                        this._listcascadeService.getListCascades().subscribe((elements: any) => this._initList(elements.data));
                    }
                },
                (response) => {
                    response = response.error;
                    this._modalService.error(this._translateService.instant("response.error." + response.key + "." + response.method, { data: response.data }));
                }
            );
        }
    }

    /**
     * Init the list
     *
     * @param elementTypes
     */
    private _initList(listcascades: any) {
        const formatedData = this._tableDataPipe.transform(listcascades);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    onActionClick(event: ActionEvent) {
        const id = event.rowId;
        switch (event.action) {
            case ActionType.Delete:
                this._listcascadeService.deleteListCascade(id).subscribe(() => {
                    this._listcascadeService.getListCascades().subscribe(data => {
                        this._lists = data.data;

                        this._initList(this._lists);
                    })
                });
                
                break;
            case ActionType.Update:
                this._router.navigate(["update", id], { relativeTo: this._route });
                break;
        }
    }
}
