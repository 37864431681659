import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { StepperComponent } from "./stepper.component";

@NgModule({
    declarations: [StepperComponent],
    exports: [StepperComponent],
    imports: [SharedModule],
})
export class StepperModule {}
