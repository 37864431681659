import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { MaestroProject, MaestroTemplate, MaestroTemplateField, MaestroTemplateLayout, MaestroTemplateLayouts } from "app/shared/models";
import { ConfirmationService } from "primeng-lts/api";
import { ProjectConfigurationStepperService } from "../../../../../../../core/services/project/project/stepper.service";
import { TemplateConfigurationService } from "../../../../../../../core/services/project/project/template.service";
import { ConfigurationStepComponent } from "../../../../../../../shared/models/project/step-component.interface";
import { TemplateConfiguration, TemplateConfigurations } from "./template-configuration";

@Component({
    selector: "app-configuration-step-template",
    templateUrl: "./template.component.html",
    styleUrls: ["./template.component.scss"],
})
export class ConfigurationStepTemplateComponent extends ConfigurationStepComponent<TemplateConfigurations> implements OnInit {
    submitted: boolean;
    readonly faSave = faSave;
    templatesData: TemplateConfigurations;
    form: FormGroup;
    readonly typesFormArray = this._formBuilder.array([]);
    projectId: number;
    isWorkable: boolean;
    canUpdate: boolean;
    fieldListByType;
    fieldsetListByType;
    project: MaestroProject;
    resources: any;

    constructor(
        private _formBuilder: FormBuilder,
        private _service: TemplateConfigurationService,
        private _stepper: ProjectConfigurationStepperService,
        private _route: ActivatedRoute,
        private _confirmationService: ConfirmationService,
        private _translate: TranslateService,
        private _userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        this.resources = this._route.snapshot.data.templates;

        if (!this._stepper.withTemplate.value) {
            this._stepper.go(0, this.resources.projectId);
        }
        this.templatesData = this.resources.elTypes;
        const acl = this._userService.getUserAclFromToken();
        this.canUpdate = false;
        if (this.templatesData && this.templatesData.length > 0) {
            this.isWorkable = true;
            this.fieldListByType = this.resources.fieldListByType;
            this.fieldsetListByType = [];
            this.projectId = this.resources.projectId;
            this.project = this.resources.project.data;
            this.canUpdate = /*this.resources.auths.includes("ON_PROJECT_EXPORT_EDIT") ||*/ acl.MAESTRO_PROJECT_PROJECTS_UPDATE === 1;
            this.form = this._initForm(this.templatesData);
            this.form.get("tabs")["controls"].forEach((tab) => {
                tab.get("fields")["controls"].forEach((field) => {
                    field.get("value").valueChanges.subscribe((value) => this.OnChangeFieldset(field, value));
                });
            });
        } else {
            this.isWorkable = false;
        }
        this._stepper.setCurrentStep(4);
    }

    isDeactivable(): boolean {
        return true;
    }

    onSubmit() {
        const breakingChanges = this.checkForBreakingChanges(this.form);
        if (!this.project.draft && breakingChanges.number) {
            const message = `
            <h3 class="text-danger">
                ${this._translate.instant("templates.breakingChanges.step5.removeProjectValues", {breakingChangesNumber: breakingChanges.number})}
            </h3>
            <br>
            <h4 class="text-danger">
                ${this._translate.instant("templates.breakingChanges.step5.invalidWorkflow")}
            </h4>
            <br><br><hr>
            <u>${this._translate.instant("templates.breakingChanges.step5.concernedFields")} :</u>
            ${breakingChanges.fields.map((field) => {
                return ` <span class="badge badge-pill badge-primary mr-1">${field}</span>`;
            })}
            <br><br>
            <b>${this._translate.instant("templates.breakingChanges.step5.continue?")}</b>
            `;
            this._confirmationService.confirm({
                header: this._translate.instant("templates.breakingChanges.step5.confirmationRequired"),
                message: message,
                icon: "pi pi-exclamation-triangle",
                acceptLabel: this._translate.instant("general.yes"),
                rejectLabel: this._translate.instant("general.no.title"),
                acceptButtonStyleClass: "bg-primary",
                rejectButtonStyleClass: "bg-danger",
                accept: () => {
                    this.submit();
                },
            });
        } else {
            this.submit();
        }
    }

    private submit() {
        this.submitted = true;

        this._service.saveConfigurations(this.projectId, this._formToObject(this.form)).subscribe(() => {
            this._stepper.validStep(4);
            this._stepper.goToNext(this.projectId);
        });
    }

    /**
     * Give information about breaking changes when replace or remove existing associations
     * @param form
     * @returns
     */
    private checkForBreakingChanges(form: FormGroup): any {
        let breakingChanges = {
            number: 0,
            fields: [],
        };

        const formValues = this.getFieldsValue(form.get("tabs")["controls"]);
        const databaseValues = this.getFieldsValue(this.templatesData, false);
        databaseValues.forEach((databaseValue) => {
            const formValue = formValues.find((formValue) => formValue.variableId === databaseValue.variableId);
            if (formValue) {
                const formValueLinkedFieldId = undefined !== formValue.linkedField && null !== formValue.linkedField ? formValue.linkedField.id : null;
                const databaseValueLinkedFieldId = undefined !== databaseValue.linkedField && null !== databaseValue.linkedField ? databaseValue.linkedField.id : null;
                if (formValueLinkedFieldId !== databaseValueLinkedFieldId) {
                    breakingChanges.number++;
                    breakingChanges.fields.push(formValue.twigName);
                } else if (formValue.isFieldset && databaseValue.isFieldset) {
                    databaseValue.fieldsetData.forEach((databaseCollection) => {
                        if (databaseCollection.linkedField) {
                            const formCollection = formValue.fieldsetData.find((collection) => collection.inputId === databaseCollection.inputId);
                            if (formCollection) {
                                if (formCollection.linkedField) {
                                    if (formCollection.linkedField.id !== databaseCollection.linkedField.id) {
                                        breakingChanges.number++;
                                        breakingChanges.fields.push(`${formValue.twigName} (${formCollection.twigName})`);
                                    }
                                } else {
                                    breakingChanges.number++;
                                    breakingChanges.fields.push(`${formValue.twigName} (${formCollection.twigName})`);
                                }
                            }
                        }
                    });
                }
            }
        });

        return breakingChanges;
    }

    /**
     * Get all link between PIM's element fields and Template's variables
     * @param templatesData
     * @param fromForm
     * @returns
     */
    private getFieldsValue(templatesData: any, fromForm: boolean = true): any[] {
        let fieldsValue = [];
        templatesData.forEach((dataModelTab) => {
            let fields;
            if (fromForm) {
                fields = dataModelTab.get("fields")["controls"];
            } else {
                fields = dataModelTab.fields;
            }
            fields.forEach((field) => {
                let linkedField;
                let twigName = null;
                if (fromForm) {
                    linkedField = field.value;
                    twigName = field.name;
                } else {
                    linkedField = field.linkedField;
                }
                const isFieldset = field.hasOwnProperty("fieldsets") ? true : false;
                let fieldsetData;
                let linkedFieldset;
                let collectionTwigName = null;
                if (isFieldset) {
                    fieldsetData = field.fieldsets.map((fieldset) => {
                        if (fromForm) {
                            linkedFieldset = Array.isArray(fieldset.value) ? fieldset.value[0] : fieldset.value;
                            collectionTwigName = fieldset.name;
                        } else {
                            linkedFieldset = fieldset.linkedField;
                        }
                        return {inputId: fieldset.id, linkedField: linkedFieldset, twigName: collectionTwigName};
                    });
                } else {
                    fieldsetData = null;
                }
                fieldsValue.push({
                    variableId: field.id,
                    linkedField: linkedField,
                    isFieldset: isFieldset,
                    fieldsetData: fieldsetData,
                    twigName: twigName,
                });
            });
        });
        return fieldsValue;
    }

    protected _initForm(configs: TemplateConfigurations): FormGroup {
        configs.forEach((config) => this.typesFormArray.push(this._initElTypeTabs(config)));
        const form = this._formBuilder.group({
            tabs: this.typesFormArray,
        });
        return form;
    }

    protected _formToObject(form: FormGroup): any {
        const fieldSave: MaestroTemplateLayouts = [];
        form.get("tabs")["controls"].forEach((tab) => {
            fieldSave.push(
                tab.get("fields").value.map(
                    (field) =>
                        <MaestroTemplateLayout>{
                            elementTypeId: tab.get("id").value,
                            id: field.id,
                            name: field.name,
                            value: field.value,
                            editable: field.editable ? field.editable : false,
                            fieldsets: field.fieldsets
                                ? field.fieldsets.map(
                                    (input) =>
                                        <MaestroTemplateLayout>{
                                            id: input.id,
                                            name: input.name,
                                            value: input.value,
                                            editable: input.editable,
                                        }
                                )
                                : null,
                        }
                )
            );
        });
        return {
            lastStep: this._stepper.isLastStep(),
            data: fieldSave.map(
                (temp) =>
                    <TemplateConfiguration>{
                        fieldList: temp,
                    }
            ),
        };
    }

    private _initElTypeTabs(config: TemplateConfiguration) {
        const formArray = this._formBuilder.array([]);
        const form = this._formBuilder.group({
            id: config.id,
            title: config.name,
            fields: formArray,
            forElement: config.forElement,
        });
        config.fields.forEach((field) => {
            formArray.push(this._initFormFields(config.id, field));
        });
        return form;
    }

    private _initFormTemplate(typeId: number, temp: MaestroTemplate) {
        const formArray = this._formBuilder.array([]);
        const form = this._formBuilder.group({
            id: temp.id,
            title: temp.name,
            fields: formArray,
        });
        temp.fields.forEach((field) => {
            formArray.push(this._initFormFields(typeId, field));
        });
        return form;
    }

    private _initFormFields(typeId, field: MaestroTemplateField) {
        const formArray = this._formBuilder.array([]);
        const list = [];
        if (field.fieldsets && field.fieldsets.length) {
            field.fieldsets.forEach((fieldset) => {
                formArray.push(this._initFormFieldset(typeId, fieldset, field.linkedField));
            });
        }
        if (typeId) {
            list.push({id: null, name: this._translate.instant("general.noneAssociations")});
            list.push(...this.filterFieldList(this.fieldListByType[typeId], field, "field"));
        }
        let fieldMatching = null;
        if (list.length > 0) {
            let allFields = [];
            list.forEach((tab) => {
                allFields = allFields.concat(tab.fields);
            });

            fieldMatching = allFields.find(
                (fieldList) => fieldList && this.normalizeString(fieldList.name.toLowerCase().trim()) == this.normalizeString(field.variableProjectName.toLowerCase().trim())
            );
        }

        const form = this._formBuilder.group({
            title: {value: field.variableProjectName, disabled: !this.canUpdate},
            value: [{value: field.linkedField ? field.linkedField : fieldMatching, disabled: !this.canUpdate}],
            name: [{value: field.name ? field.name : field.variableProjectName, disabled: !this.canUpdate}],
            list: [{value: list, disabled: !this.canUpdate}],
            id: {value: field.variableProjectId, disabled: !this.canUpdate},
            editable: {value: field.editable, disabled: !this.canUpdate},
            fieldType: {value: field.fieldType, disabled: !this.canUpdate},
        });

        if (field.fieldsets && field.fieldsets.length) {
            form.setControl("fieldsets", formArray);
        }

        return form;
    }

    private normalizeString(str) {
        return str
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
    }

    private filterFieldList(list, field, typeField): any {
        let result = [];

        switch (field.fieldType) {
            // case "text":
            // case "textarea":
            //     result = list.filter((fieldList) => ["text", "textarea", "number"].includes(fieldList.fieldType));
            //     break;
            case "fieldset":
            case "productLink":
                result = list.filter((fieldList) => ["productLink","fieldset"].includes(fieldList.fieldType));
                break;
            default:
                result = list.filter((fieldList) => !["fieldset", "productLink"].includes(fieldList.fieldType));
                break;
        }

        if (result.length > 0 && typeField === "field") {
            result = this.sortByTab(result);
        }

        return result;
    }

    private sortByTab(listField) {
        let result = [];

        listField.forEach((field) => {
            if (!result[field["tab"]]) {
                result[field["tab"]] = {fields: [], name: field["tab"]};
            }

            result[field["tab"]]["fields"].push(field);
        });

        return Object.values(result);
    }

    private _initFormFieldset(typeId, field: MaestroTemplateField, linkedField) {
        const formArray = this._formBuilder.array([]);
        const list = [];

        if (typeId) {
            if (linkedField) {
                const fieldsetType = this.fieldListByType[typeId].find((fieldset) => {
                    return fieldset && fieldset.id === linkedField.id;
                });

                if (fieldsetType) {
                    list.push(...this.filterFieldList(fieldsetType.fieldset, field, "input"));
                }
            }
        }

        const form = this._formBuilder.group({
            title: {value: field.variableProjectName, disabled: !this.canUpdate},
            value: [{value: field.linkedField ? field.linkedField : null, disabled: !this.canUpdate}],
            name: [{value: field.name ? field.name : field.variableProjectName, disabled: !this.canUpdate}],
            list: [{value: list, disabled: !this.canUpdate}],
            id: {value: field.variableProjectId, disabled: !this.canUpdate},
            editable: {value: field.editable, disabled: !this.canUpdate},
            fieldType: {value: field.fieldType, disabled: !this.canUpdate},
        });

        return form;
    }

    OnChangeFieldset(field, value) {
        if (field.get("fieldsets")) {
            field.get("fieldsets")["controls"].forEach((input) => {
                if (value.length === 0) {
                    input.get("list").setValue(value);
                    input.get("value").setValue([]);
                } else {
                    const fieldsetType = field.value.list
                        .flatMap((tab) => tab.fields)
                        .find((fieldset) => {
                            return fieldset && fieldset.id === value.id;
                        });
                    let valueInput = [];
                    if (input.get("value").value && input.get("value").value.length > 0) {
                        valueInput = valueInput = fieldsetType.fieldset.filter((fieldsetInput) => {
                            return fieldsetInput.id === input.get("value").value.id && fieldsetInput.name === input.get("value").value.name;
                        });
                    }

                    input.get("list").setValue(this.filterFieldList(fieldsetType.fieldset, input.value, "input"));
                    input.get("value").setValue(valueInput);
                }
            });
        }
    }

    onBack() {
        this._stepper.goToPrevious(this.projectId);
    }
}
