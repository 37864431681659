import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { TemplateCreateModule } from "./create/template-create.module";
import { TemplateComponent } from "./template.component";
import { TemplateRoutingModule } from "./template.routing";

@NgModule({
    declarations: [TemplateComponent],
    imports: [TemplateRoutingModule, TemplateCreateModule, SharedModule],
})
export class TemplateModule {}
