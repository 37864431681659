import { Component, OnInit } from "@angular/core";
import { MessageInformationService } from "app/core/services/admin/user/MessageInformation.service";
import { Alert } from "app/shared/models/Alert";

@Component({
    selector: "app-alert-message",
    templateUrl: "./alert-message.component.html",
})
export class AlertMessageComponent implements OnInit {
    displayAlertSystem = true;
    displayAlertUser = true;

    messageInformations = [];
    AlertSystems: Alert = {
        type: "danger",
        message: "",
    };

    AlertUser: Alert = {
        type: "warning",
        message: "",
    };

    constructor(private _messagesInformationServices: MessageInformationService) {}

    ngOnInit(): void {
        this.getMessage();

        this._messagesInformationServices.eventMessage$.subscribe(() => {
            this.getMessage();
        });
    }

    close(alert) {
        if (alert === "system") {
            this.displayAlertSystem = false;
        }

        if (alert === "user") {
            this.displayAlertUser = false;
        }
    }

    getMessage() {
        this._messagesInformationServices.getMessage().subscribe((response) => {
            let currentDate = new Date();
            let formattedDate = currentDate.toISOString().split("T")[0];

            this.messageInformations = response.data.filter((d) => d.startDate <= formattedDate && d.endDate >= formattedDate);

            let systemMessage = this.messageInformations.find((m) => m.isSystemMessage === true);

            if (systemMessage) {
                this.AlertSystems.message = systemMessage.message;
            } else {
                this.AlertSystems.message = "";
            }

            let userMessage = this.messageInformations.find((m) => m.isSystemMessage === false);

            if (userMessage) {
                this.AlertUser.message = userMessage.message;
            } else {
                this.AlertUser.message = "";
            }
        });
    }
}
