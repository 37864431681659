import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ToastComponent } from "./toast.component";

@NgModule({
    declarations: [ToastComponent],
    imports: [SharedModule],
    entryComponents: [ToastComponent],
})
export class ToastModule {}
