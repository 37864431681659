import { Step, StepState } from "app/shared/components/stepper";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ProjectConfigurationName } from "./project-configuration";

export declare type ProjectConfigurationSteps = ProjectConfigurationStep[];
export declare type ProjectConfigurationStep = Step<{
    name: ProjectConfigurationName;
    route: string;
}>;

export const PROJECT_CONFIGURATION_STEPS: ProjectConfigurationSteps = [
    {
        id: 0,
        data: {
            name: ProjectConfigurationName.Project,
            route: MAESTRO_ROUTES.projects.steps.project,
        },
        title: "general",
        state: StepState.Current,
        valid: false,
        // auth: "ON_PROJECT_PROJECT_READ",
    },
    {
        id: 1,
        data: {
            name: ProjectConfigurationName.Elements,
            route: MAESTRO_ROUTES.projects.steps.elements,
        },
        title: "elements",
        state: StepState.Disabled,
        valid: false,
        // auth: "ON_PROJECT_PROJECT_READ",
    },
    {
        id: 2,
        data: {
            name: ProjectConfigurationName.Exports,
            route: MAESTRO_ROUTES.projects.steps.exports,
        },
        title: "exports",
        state: StepState.Disabled,
        valid: false,
        // auth: "ON_PROJECT_EXPORT_EDIT",
    },
    {
        id: 3,
        data: {
            name: ProjectConfigurationName.Workflow,
            route: MAESTRO_ROUTES.projects.steps.workflow,
        },
        title: "workflows",
        state: StepState.Disabled,
        valid: false,
        // auth: "ON_PROJECT_EXPORT_EDIT",
    },
    {
        id: 4,
        data: {
            name: ProjectConfigurationName.Template,
            route: MAESTRO_ROUTES.projects.steps.template,
        },
        title: "templates",
        state: StepState.Disabled,
        valid: false,
        // auth: "ON_PROJECT_EXPORT_EDIT",
    },
    {
        id: 5,
        data: {
            name: ProjectConfigurationName.Flux,
            route: MAESTRO_ROUTES.projects.steps.flux,
        },
        title: "flux",
        state: StepState.Disabled,
        valid: false,
        // auth: "ON_PROJECT_EXPORT_EDIT",
    }
];
