import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { StepState } from "app/shared/components/stepper";
import { ACL } from "app/shared/models/acl";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments";
import { PROJECT_CONFIGURATION_STEPS, ProjectConfigurationStep, ProjectConfigurationSteps } from "../../../../shared/models/project/project-configuration-step";
import { UserService } from "../../admin/user/user.service";
import { LayoutService } from "../../global/layout/layout.service";

/**
 * This service is used to manage the stepper
 */
@Injectable()
export class ProjectConfigurationStepperService {
    private _steps: ProjectConfigurationSteps;
    private _currentStep: ProjectConfigurationStep;
    private readonly _currentStepSource = new BehaviorSubject<ProjectConfigurationStep>(null);
    private readonly _stepsSource = new BehaviorSubject<ProjectConfigurationSteps>(null);

    readonly currentStep$ = this._currentStepSource.asObservable();
    readonly steps$ = this._stepsSource.asObservable();

    withProduct: BehaviorSubject<boolean> = new BehaviorSubject(false);
    withWorkflow: BehaviorSubject<boolean> = new BehaviorSubject(false);
    withTemplate: BehaviorSubject<boolean> = new BehaviorSubject(false);
    withFlux: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private acl: ACL;
    constructor(private router: Router, private _layout: LayoutService, private _translate: TranslateService, protected _http: HttpClient, private _userService: UserService) {
        this.acl = this._userService.getUserAclFromToken();
        this._stepsSource.subscribe((steps) => (this._steps = steps));

        this._currentStepSource.subscribe((step) => (this._currentStep = step));
        this.setStepsWithProducts();
        this.init();
    }

    init() {
        this._currentStepSource.next(PROJECT_CONFIGURATION_STEPS[0]);
        this._layout.breadcrumb.setPath({ name: this._translate.instant("projects.step.general"), routerLink: null }, 2);
    }

    isLastStep(): boolean {
        return this.findIndexOfCurrentStep() === this._steps.length - 1;
    }

    validStep(id: number) {
        if (this._steps.find((s) => s.id === id)) {
            this._steps.filter((s) => s.id === id)[0].valid = true;
            this._steps.filter((s) => s.id === id)[0].state = StepState.Enabled;

            if (this._steps.filter((s) => s.id === id + 1)[0]) {
                this._steps.filter((s) => s.id === id + 1)[0].state = StepState.Enabled;
            }

            this._stepsSource.next(this._steps);
        }
    }

    isValidStep(id: number): boolean {
        return this._steps.find((s) => s.id === id) ? this._steps.find((s) => s.id === id).valid : false;
    }

    invalidStep(id: number): void {
        if (this._steps.find((s) => s.id === id)) {
            this._steps.filter((s) => s.id === id)[0].valid = false;
            this._stepsSource.next(this._steps);
        }
    }

    setNoWorkflow(): void {
        this._stepsSource.next([PROJECT_CONFIGURATION_STEPS[0], PROJECT_CONFIGURATION_STEPS[1], PROJECT_CONFIGURATION_STEPS[2], PROJECT_CONFIGURATION_STEPS[4]]);
    }

    setNoWorkflowAndNoTemplate(): void {
        this._stepsSource.next([PROJECT_CONFIGURATION_STEPS[0], PROJECT_CONFIGURATION_STEPS[1], PROJECT_CONFIGURATION_STEPS[2]]);
    }

    setStepsNoProduct(): void {
        this._stepsSource.next([PROJECT_CONFIGURATION_STEPS[0], PROJECT_CONFIGURATION_STEPS[2], PROJECT_CONFIGURATION_STEPS[3]]);
    }

    setStepsWithProducts(): void {
        this._stepsSource.next([...PROJECT_CONFIGURATION_STEPS]);
    }

    setSteps(/*, auths: any*/) {
        let steps = [PROJECT_CONFIGURATION_STEPS[0]];
        
        if (this.withProduct.getValue()) {
            steps.push(PROJECT_CONFIGURATION_STEPS[1]);
        }
        steps.push(PROJECT_CONFIGURATION_STEPS[2]);

        if (this.withWorkflow.getValue()) {
            steps.push(PROJECT_CONFIGURATION_STEPS[3]);
        }
        if (this.withTemplate.getValue()) {
            steps.push(PROJECT_CONFIGURATION_STEPS[4]);
        }
        if(this.withFlux.getValue()) {
            steps.push(PROJECT_CONFIGURATION_STEPS[5])
        }

        this._stepsSource.next(this.isGranted(steps /*, auths*/));
    }

    isWithProducts(): boolean {
        return this._steps.length > 3;
    }

    private findIndexOfStep(step: ProjectConfigurationStep): number {
        for (let index = 0; index < this._steps.length; index++) {
            if (this._steps[index].id === step.id) {
                return index;
            }
        }
        return -1;
    }

    findIndexOfCurrentStep(): number {
        return this.findIndexOfStep(this._currentStep);
    }

    isAllValidBefore(step: ProjectConfigurationStep): boolean {
        for (const value of this._steps) {
            if (step.id === value.id) {
                return true;
            }
            if (!value.valid) {
                return false;
            }
        }
    }

    goToStep(step: ProjectConfigurationStep, id: number, titleStep: string) {
        const nextStep = this.findIndexOfStep(step);

        if (this._steps[nextStep]) {
            this._currentStepSource.next(this._steps[nextStep]);
            this.router.navigate(["projects", id, "configure", titleStep]);
        }
    }

    go(numberStep: number, idProject: number){
        this.goToStep(this._steps[numberStep], idProject, this._steps[numberStep].title);
    }

    goToNext(id?: number) {
        const current = this.findIndexOfStep(this._currentStep);
        if (current === this._steps.length - 1) {
            this.router.navigate(["projects", id, "dashboard"]);
            return;
        }
        this.goToStep(this._steps[current + 1], id, this._steps[current + 1].title);
    }

    goToPrevious(id?: number) {
        const current = this.findIndexOfStep(this._currentStep);
        this.goToStep(this._steps[current - 1], id, this._steps[current - 1].title);
    }

    validCurrentStep(): void {
        PROJECT_CONFIGURATION_STEPS[this.findIndexOfStep(this._currentStep)].valid = true;
        this._steps.length === 3 ? this.setStepsNoProduct() : this.setStepsWithProducts();
    }

    invalidCurrentStep(): void {
        PROJECT_CONFIGURATION_STEPS[this.findIndexOfStep(this._currentStep)].valid = false;
        this._steps.length === 3 ? this.setStepsNoProduct() : this.setStepsWithProducts();
    }

    isAllValid(): boolean {
        for (const step of this._steps) {
            if (!step.valid) {
                return false;
            }
        }
        return true;
    }

    isLength(nb: number): boolean {
        return this._steps.length === nb;
    }

    setCurrentStep(step: number) {
        this._currentStepSource.next(PROJECT_CONFIGURATION_STEPS[step]);
        this._currentStepSource.subscribe((step) => (this._currentStep = step));
    }

    /**
     * Get the last valid step
     * @returns
     */
    getValidStep(projectId: number): any {
        return this._http.get(`${environment.projectsUrl}/project/${projectId}/valid-step.json`);
    }
    isGranted(steps: any /*, auths: any*/) {
        let stepper = [];
        steps.forEach((step) => {
            if (this.acl.MAESTRO_PROJECT_PROJECTS_UPDATE /*|| auths.includes(step.auth)*/) {
                stepper.push(step);
            }
        });
        return stepper;
    }
}
