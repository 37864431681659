import { NgModule } from "@angular/core";
import { GroupService } from "app/core/services/admin/group/group.service";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ConfigurationStepProjectComponent } from "./project.component";

@NgModule({
    declarations: [ConfigurationStepProjectComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [ConfigurationStepProjectComponent],
    providers: [GroupService]
})
export class SettingsConfigurationModule {}
