import { Component, Input } from "@angular/core";
import { FormArray } from "@angular/forms";

@Component({
    selector: "app-template-configuration-tabs",
    templateUrl: "./template-configuration-tabs.component.html",
})
export class TemplateConfigurationTabsComponent {
    @Input() templateFormArray: FormArray;
    @Input() forElement: boolean;
    FormArray = FormArray;

    window = window;
}
