import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { MessageFormModule } from "./form/message-form.module";
import { MessageComponent } from "./message.component";
import { MessageRoutingModule } from "./message.routing";

@NgModule({
    declarations: [MessageComponent],
    exports: [MessageComponent, RouterModule],
    imports: [SharedModule, SharedComponentsModule, MessageRoutingModule, MessageFormModule],
})
export class MessageModule {}
