import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ProjectWorkflowService } from "app/core/services/admin/workflow/project-workflow.service";
import { ElementsConfigurationService } from "app/core/services/project/project/elements.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { WorkflowConfigurationService } from "app/core/services/project/project/workflow.service";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { WorkflowConfigurationModule } from "./workflow.module";

@Injectable({
    providedIn: WorkflowConfigurationModule,
})
export class WorkflowGuard implements CanActivate, Resolve<any> {
    constructor(
        private projectService: ProjectService,
        private elementService: ElementsConfigurationService,
        private workflowService: WorkflowConfigurationService,
        private projectWorkflowService: ProjectWorkflowService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const projectId = route.parent.parent.parent.params.id;

        return forkJoin([
            this.projectService.getById(projectId),
            this.workflowService.getConfiguration(projectId),
            this.projectWorkflowService.getAll(),
            // this.projectService.getAuths(projectId),
        ]).pipe(
            map(
                (data) =>
                    <any>{
                        project: (data[0] as any).data,
                        configs: data[1],
                        workflows: data[2] as any,
                        projectId: projectId,
                        // auths: (data[3] as any).data
                    }
            )
        );
    }
}
