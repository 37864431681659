<ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li *ngFor="let form of formArray.controls; let i = index" [ngbNavItem]="i">
        <a ngbNavLink>
            <app-form-label [invalid]="form.invalid" [valid]="form.valid">
                {{ form.get('title').value }}
            </app-form-label>
        </a>
        <ng-template ngbNavContent>
            <div [formGroup]="form">
                <div class="row form-group">
                    <label class="col-sm-4 col-form-label">
                        {{ 'general.products' | translate }}
                    </label>
                    <div class="col-sm-8">
                        <app-drop-down type="multi" [settings]="dropdownSettings" [list]="elementsByType[form.get('id').value]" formControlName="elements" [selected]="form.get('elements').value"></app-drop-down>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
