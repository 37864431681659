<div class="tableComponent row">
    <div class="col-lg-12">
        <!-- @TODO: Change for datatable and let an user to read and edit his profile -->
        <!-- <app-table tableTitle="{{ 'admin.users.title' | translate }} : {{ table.length }} {{ (table.length > 1 ? 'general.results' : 'general.result') | translate }}" [table]="table" [columns]="columns" [actions]="actions" (action)="onActionClick($event)"></app-table> -->
        <app-datatable 
          tableTitle="{{ 'admin.users.title' | translate }}"
          [actions]="actions" (action)="onActionClick($event)"
                      searchDataParameter="element"
                      [expandableRow]="false"
                      [selectRows]="true" [exportRows]="true" affLink="{{affLink}}"
                      linkColName="name" baseLink="/pim/element/update/" (lazyEvent)="loadList($event)"
                      [tableRows]="tableRows" [tableColumns]="tableColumns" [totalRecords]="totalRecords"
                      [recordsFiltered]="recordsFiltered" [globalFilterFields]="globalFilterFields"
                      [_selectedColumns]="_selectedColumns" [exportFilename]="exportFilename" [filterable]="true"
                      [filters]="allFilters"  [subDataType]="subDataType"
                      (massTagEnd)="loadList(currentDatatableFilters)"
                      [shortPaginatorIsFirstPage]="shortPaginatorIsFirstPage"
                      [shortPaginatorIsLastPage]="shortPaginatorIsLastPage" (shortPaginatorPrev)="shortPaginatorPrev()"
                      (shortPaginatorReset)="shortPaginatorReset()" (shortPaginatorNext)="shortPaginatorNext()"
                      [first]="currentDatatableFilters.first" [preFilter]="preFilter"
                      (nodeSelected)="nodeSelected = $event" [showAdvancedSearch]="true" (changeAdvancedSearch)="changeAdvancedSearch($event)" (clearFilters)="cleanPrefilters($event)" sortOrder="1">
        >
    </app-datatable>
    </div>
</div>
