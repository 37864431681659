import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { LoginModule } from "./login/login.module";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PasswordModule } from "./password/password.module";
import { PublicComponent } from "./public.component";

const routes: Routes = [
    {
        path: "",
        component: PublicComponent,
        children: [
            { path: MAESTRO_ROUTES.public.login, loadChildren: () => LoginModule },
            { path: MAESTRO_ROUTES.public.password.base, loadChildren: () => PasswordModule },
            { path: MAESTRO_ROUTES.public.forbidden, component: ForbiddenComponent },
            { path: "**", component: NotFoundComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PublicRoutingModule {}
