import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { DataModelService } from "app/core/services/dam/datamodel.service";
import { DamDataModel } from "app/shared/models/dam/data-model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class DataModelUpdateResolver implements Resolve<DamDataModel> {
    constructor(private _dataModelService: DataModelService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<DamDataModel> {
        return this._dataModelService.getById(route.params.id).pipe(map((r) => r.data));
    }
}
