<form *ngIf="tableForm && !readOnly && !overlayPanelMode && !cellFormEnabled" [formGroup]="tableForm">
    <div class="row">
        <h3 class="col-4">
            {{ 'general.table.name' | translate }}
            <!--<span type="button" (click)="lockTable()" class="mr-1"
                data-toggle="tooltip" data-placement="right"
                title="{{ tableLocked ? ('general.table.unlock' | translate) : ('general.table.lock' | translate) }}">
                <i [ngClass]="tableLocked ? 'pi pi-lock' : 'pi pi-lock-open'"></i>
            </span>-->
        </h3>

        <div class="col-4 offset-4">
            <div *ngFor="let source of sources" class="col">
                <p-confirmPopup baseZIndex="999">
                </p-confirmPopup>

                <button *ngIf="!disabledImportTable" [disabled]="!importFromSource || !isPimVariable" (click)="confirm($event, source)" class="btn mt-1"
                    [ngClass]="{'btn-info': 'product' === source, 'btn-primary': 'product' !== source, 'opacity-25': !importFromSource}">
                    <i class="pi pi-table"></i>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.table.import.' + source | translate
                        }}</span>
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngFor="let control of tableForm.controls | keyvalue:onCompare" class="mt-1 col-4">
            <!--<div *ngIf="tableFormInputType[control.key] !== 'textarea'">-->
            <label for="control.key">{{ 'general.table.total.' + control.key | translate
                }}</label>
            <input id="{{control.key}}" class="form-control" [ngClass]="control.value.valid ? 'is-valid' : 'is-invalid'"
                type="{{tableFormInputType[control.key]}}" [formControl]="control.value">
            <div *ngIf="control.value.invalid && !control.value.pristine" class="invalid-feedback">
                <span>{{ 'general.table.error.value' | translate }} {{ 'general.table.error.min' | translate }} {{
                    getTableMinMaxValue('min',
                    (control.key | capitalizeFirstLetter)) }} & {{ 'general.table.error.max' | translate }} {{
                    getTableMinMaxValue('max',
                    (control.key | capitalizeFirstLetter)) }}</span>
            </div>
            <!--</div>-->
            <!--<div *ngIf="tableFormInputType[control.key] === 'textarea'">
                <label for="control.key">{{ 'general.table.' + control.key | translate }}</label>
                <textarea id="{{control.key}}" [formControl]="control.value" class="form-control"></textarea>
            </div>-->
        </div>
    </div>
    <div class="row">
        <button [disabled]="tableForm.invalid || tableForm.pristine || tableForm.disabled"
            class="btn btn-secondary col my-1 mx-3" (click)="saveTableForm()">
            <i class="pi pi-check"></i>
            <span class="d-none d-md-inline-block ml-1">{{ 'general.table.validate' | translate }}</span>
        </button>
    </div>

</form>

<form *ngIf="cellFormEnabled && !readOnly && !overlayPanelMode" [formGroup]="cellForm">
    <h3>
        {{ 'general.table.cell.header' | translate }} : {{ focusedCell ? focusedCell : 'general.table.cell.empty' |
        translate }} <span *ngIf="cellForm.disabled">({{ 'general.table.locked' | translate }})</span>
    </h3>
    <div *ngFor="let control of cellForm.controls | keyvalue:onCompare" class="mt-1">
        <div [hidden]="'position' === control.key || 'header' === control.key">
            <label for="control.key">{{ 'general.table.cell.' + control.key | translate }}</label>
            <input id="{{control.key}}" class="form-control" [ngClass]="control.value.valid ? 'is-valid' : 'is-invalid'"
                type="{{cellFormInputType[control.key]}}" [formControl]="control.value">
            <div *ngIf="control.value.invalid" class="invalid-feedback">
                <span>{{ 'general.table.error.value' | translate }} {{ 'general.table.error.min' | translate }} {{
                    getCellMinMaxValue('min', (control.key | capitalizeFirstLetter)) }} & {{ 'general.table.error.max' |
                    translate }} {{
                    getCellMinMaxValue('max', (control.key | capitalizeFirstLetter)) }}</span>
            </div>
        </div>
    </div>
    <button [disabled]="cellForm.invalid || cellForm.disabled" class="btn btn-secondary mt-1" (click)="saveCellForm()">
        <i class="pi pi-check"></i>
        <span class="d-none d-md-inline-block ml-1">{{ 'general.table.cell.validate' | translate }}</span>
    </button>
    <hr>
</form>

<p-table *ngIf="cols.length" [columns]="cols" [value]="rows" dataKey="id" (onEditInit)="onEditInit($event)"
    (onEditComplete)="onEditComplete($event)" (onEditCancel)="onEditCancel($event)"
    styleClass="p-datatable-gridlines p-datatable-striped">
    <ng-template pTemplate="header" let-columns>
        <tr *ngFor="let rowCol of rowCols">
            <th *ngFor="let col of rowCol; let c = index" [pEditableColumn]="col" [pEditableColumnField]="col.header"
                [pEditableColumnRowIndex]="c" [attr.colspan]="col.col" [attr.rowspan]="col.row"
                class="text-center text-white"
                [ngClass]="{emptyCol: col.empty, darkCol: !col.empty, errorCell: errorCell(col)}">
                <!-- [ngClass]="c % 2 === 0 ? 'evenCol' : 'oddCol'" -->
                <span *ngIf="!col.empty && col.header && !readOnly && !overlayPanelMode" type="button"
                    (click)="lockCell(col)" class="mr-1" data-toggle="tooltip" data-placement="bottom"
                    title="{{ col.locked ? ('general.table.cell.unlock' | translate) : ('general.table.cell.lock' | translate) }}">
                    <i [ngClass]="col.locked ? 'pi pi-lock' : 'pi pi-lock-open'"></i>
                </span>
                <span *ngIf="!col.empty && col.header && readOnly || !col.empty && col.header && overlayPanelMode"
                    class="mr-1">
                    <i [ngClass]="col.locked ? 'pi pi-lock' : 'pi pi-lock-open'"></i>
                </span>

                <p-cellEditor *ngIf="!col.empty && !readOnly && !overlayPanelMode">
                    <ng-template pTemplate="input">
                        <!-- <input pInputText type="text" [(ngModel)]="col.header" [disabled]="col.locked" /> -->
                        <textarea [(ngModel)]="col.header" [disabled]="col.locked"></textarea>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span>{{ col.header }}</span>
                    </ng-template>
                </p-cellEditor>
                <span *ngIf="readOnly || overlayPanelMode">{{ col.header }}</span>

            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let c = index" [pEditableColumn]="rowData"
                [pEditableColumnField]="rowData[col.field]" [pEditableColumnRowIndex]="c"
                class="text-center text-white darkRow" [ngClass]="{errorCell: errorCell(rowData)}">
                <!-- [ngClass]="c % 2 === 0 ? 'evenRow' : 'oddRow'" -->

                <span *ngIf="rowData[col.field] && !readOnly && !overlayPanelMode" type="button"
                    (click)="lockCell(rowData)" data-toggle="tooltip" data-placement="bottom"
                    title="{{ rowData.locked ? ('general.table.cell.unlock' | translate) : ('general.table.cell.lock' | translate) }}">
                    <i [ngClass]="rowData.locked ? 'pi pi-lock' : 'pi pi-lock-open'" class="mr-1">
                    </i>
                </span>
                <span *ngIf="rowData[col.field] && readOnly || rowData[col.field] && overlayPanelMode">
                    <i [ngClass]="rowData.locked ? 'pi pi-lock' : 'pi pi-lock-open'" class="mr-1"></i>
                </span>

                <p-cellEditor *ngIf="!readOnly && !overlayPanelMode">
                    <ng-template pTemplate="input">
                        <!--<input pInputText type="text" [(ngModel)]="rowData[col.field]"
                            [disabled]="!col.header || rowData.locked" />-->
                            <textarea [(ngModel)]="rowData[col.field]"
                            [disabled]="!col.header || rowData.locked"></textarea>
                        <br>
                        <small *ngIf="!col.header" id="rowData-help" class="text-danger">{{
                            'general.table.error.headerNeeded' | translate }}</small>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span>{{ rowData[col.field] }}</span>
                    </ng-template>
                </p-cellEditor>
                <span *ngIf="readOnly || overlayPanelMode">{{ rowData[col.field] }}</span>
            </td>
        </tr>
    </ng-template>
</p-table>
<!--<span>{{ note }}</span>-->

<div class="d-none">
    COLS {{ cols | json }}
    <br>
    <br>
    ROWS {{ rows | json }}
    <br>
    <hr>
    ROWCOLS {{ rowCols | json }}
</div>