import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListComponent } from "./list/list.component";
import { DataModelListResolver } from "./list/list.resolver";
import { UpdateComponent } from "./update/update.component";
import { DataModelUpdateResolver } from "./update/update.resolver";

const dataModelRoutes: Routes = [
    { path: "", component: ListComponent, resolve: { resources: DataModelListResolver } },
    { path: "update/:id", component: UpdateComponent, resolve: { resources: DataModelUpdateResolver } },
];

@NgModule({
    imports: [RouterModule.forChild(dataModelRoutes)],
    exports: [RouterModule],
})
export class DataModelRouting {}
