import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IMaestroEntity, MaestroElements, MaestroElementType, MaestroExports } from "app/shared/models";
import { saveAs } from "file-saver";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments";

@Injectable()
export class ProjectService {


    withProduct: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(protected _http: HttpClient) {}

    /**
     * Get all projects
     * @returns
     */
    getAll(): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/projects.json`);
    }

    /**
     * Get a specific project
     * @param id
     * @returns
     */
    getById(id: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/project/${id}.json`);
    }

    /**
     * Create a new project
     * @param data
     * @returns
     */
    create(data: { name: string }): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/project.json`, { title: data });
    }

    /**
     * Delete a project
     * @param id
     * @returns
     */
    deleteById(id: number): Observable<any> {
        return this._http.delete(`${environment.projectsUrl}/project/${id}.json`);
    }

    /**
     * Update project information
     *
     * @param projectId
     * @param data
     * @returns
     */
    update(projectId: number, data: any): any {
        return this._http.put(`${environment.projectsUrl}/project/${projectId}.json`, data);
    }

    /**
     * Get elements used in a project
     * @param id
     * @returns
     */
    getElements(id: number): Observable<MaestroElements> {
        return this._http.get<any>(`${environment.projectsUrl}/project/${id}/elements.json`).pipe(
            map(
                (response) => {
                    const elements = [];
                    response.data.forEach((data: { type: MaestroElementType; elements: MaestroElements }) => {
                        elements.push(...data.elements.map((el) => ({ ...el, type: data.type })));
                    });
                    return elements;
                },
                catchError((_) => of([]))
            )
        );
    }

    /**
     * Get export types
     * @param id
     * @returns
     */
    getExportTypes(id: number): Observable<MaestroExports> {
        return this._http.get<MaestroExports>(`${environment.projectsUrl}/project/${id}/exports_type.json`).pipe(catchError((_) => of([])));
    }

    /**
     * Get export types
     * @param id
     * @returns
     */
    getProjectExportTypes(id: number): Observable<MaestroExports> {
        return this._http.get<MaestroExports>(`${environment.projectsUrl}/project/${id}/export_types.json`).pipe(catchError((_) => of([])));
    }

    getModuleEnable(): Observable<any> {
        return this._http.get<any>(`${environment.projectsUrl}/module_enable.json`);
    }

    getElementTypes(): Observable<any> {
        return this._http.get<any>(`${environment.projectsUrl}/element_types.json`);
    }

    getElementsByElementsType(id: number, typeId: number): Observable<IMaestroEntity[]> {
        return this._http.get<any>(`${environment.projectsUrl}/project/${id}/element_type/${typeId}/elements.json`);
    }

    getFieldsElementTypes(id: number, typeIds: string): Observable<IMaestroEntity[]> {
        return this._http.get<any>(`${environment.projectsUrl}/project/${id}/element_type/fields.json?elementTypes=${typeIds}`);
    }

    getGroups(id: number): Observable<any> {
        return this._http.get<any>(`${environment.projectsUrl}/project/${id}/groups.json`);
    }

    // groupAuths() { // Currently commented because unused for instant
    //     return this._http.get<any>(`${environment.projectsUrl}/groups/auth.json`);
    // }

    // getAuths(id: number): Observable<any> { // Currently commented because unused for instant
    //     return this._http.get<any>(`${environment.projectsUrl}/project/${id}/groups/auth.json`);
    // }

    getVersion(id: number): Observable<any> {
        return this._http.get<any>(`${environment.projectsUrl}/version/${id}.json`);
    }

    getDatatableProjects(filter: any): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/datatable/project.json`, filter);
    }

    /**
     * Delete projectDocument
     *
     * @param projectDocumentId
     * @returns
     */
    deleteProjectDocument(projectDocumentId: number): Observable<any> {
        return this._http.delete(`${environment.projectsUrl}/project-document/${projectDocumentId}.json`);
    }

    openDocument(id: number, filename: string) {
        this._http.get(`${environment.projectsUrl}/project-document/${id}.json`, { responseType: 'blob' })
            .subscribe(response => {
                saveAs(response, filename);
            });
    }

    getFlux(id: number){
        return this._http.get(`${environment.projectsUrl}/project/${id}/flux.json`);
    }

    saveFlux(id: number, flux){
        return this._http.post(`${environment.projectsUrl}/project/${id}/flux.json`, {
            flux
        })
    }
}
