import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { ElementType } from "app/shared/models/pim/element-type.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ElementTypeUpdateResolver implements Resolve<ElementType> {
    constructor(private elementTypeService: ElementTypeService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ElementType> {
        return this.elementTypeService.getById(route.params.id).pipe(map((r) => r.data));
    }
}
