import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { FormLabelComponent } from "./form-label.component";

@NgModule({
    declarations: [FormLabelComponent],
    exports: [FormLabelComponent],
    imports: [SharedModule],
})
export class FormLabelModule {}
