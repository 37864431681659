import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { DatePickerComponent } from "./date-picker.component";

@NgModule({
    declarations: [DatePickerComponent],
    exports: [DatePickerComponent],
    imports: [SharedModule],
})
export class DatePickerModule {}
