import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { DataModelService } from "app/core/services/dam/datamodel.service";
import { MaestroElementTypes } from "app/shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class DataModelListResolver implements Resolve<MaestroElementTypes> {
    constructor(private service: DataModelService) {}

    resolve(): Observable<MaestroElementTypes> {
        return this.service.getDataModels().pipe(map((r: any) => r.data));
    }
}
