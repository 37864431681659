<ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li *ngFor="let step of tabFormArray.controls; let i = index" [ngbNavItem]="i">
        <a ngbNavLink>
            <app-form-label [invalid]="step.invalid" [valid]="step.valid">
                {{ step.get('title').value }}
            </app-form-label>
        </a>
        <ng-template ngbNavContent>
            <form [formGroup]="step">
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                        {{ 'projects.conf.due_date' | translate }}
                    </label>
                    <div class="col-sm-10">
                        <app-date-picker  [active]="canUpdate" formControlName="dueDate"></app-date-picker>
                        <!-- <input *ngIf="!canUpdate" type="text" formControlName="dueDate" /> -->
                    </div>
                </div>
            </form>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
