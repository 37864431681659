import { Injectable } from "@angular/core";
import { BreadcrumbDef, BreadcrumbItem } from "app/shared/models";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class BreadcrumbService {
    private pathSource = new BehaviorSubject<BreadcrumbDef>([]);
    private breadcrumbDef: BreadcrumbDef = [];

    readonly path$: Observable<BreadcrumbDef> = this.pathSource.asObservable();

    /**
     * Configure the path for the breadcrumb
     *
     * @param breadcrumbItem
     * @param pathIndex
     */
    setPath(breadcrumbItem: BreadcrumbItem, pathIndex: number) {
        if (breadcrumbItem) {
            this.breadcrumbDef[pathIndex] = breadcrumbItem;
        } else {
            this.breadcrumbDef.splice(pathIndex, 1);
        }
        
        this.pathSource.next(this.breadcrumbDef);
    }

    getPath(){
        return this.breadcrumbDef;
    }
}
