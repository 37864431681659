<!-- FULL DISPLAY -->
<!-- NOT USED FOR INSTANCE + NEED TO FIX % -->
<!--
<p-panel *ngIf="((key === 'mandatory' && displayMandatoryIndicator) || (key === 'salabilityIndicator' && displaySalabilityIndicator)) && showCompleteSalabilityIndicatorsInterface === true && showMinifiedSalabilityIndicatorsInterface === false && indicators && indicators.hasOwnProperty('tabs') && indicators.tabs.length && (indicators.hasOwnProperty('fieldsCount') && indicators.fieldsCount || indicators.hasOwnProperty('inputsCount') && indicators.inputsCount)" [toggleable]="true" [collapsed]="true">####[(collapsed)]="collapsed_1"####
    <ng-template pTemplate="header">
        ####{{ (collapsed_1 ? 'general.show' : 'general.hide') | translate }} {{ 'indicator.completion.global.' + translationKey | translate }}####
        {{ 'indicator.completion.global.' + translationKey | translate }}
    </ng-template>

    <p-progressBar [value]="calculateCompletionRate((indicators.emptyFieldsCount + indicators.emptyInputsCount), (indicators.fieldsCount + indicators.inputsCount))"></p-progressBar>
    <div *ngIf="calculateCompletionRate((indicators.emptyFieldsCount + indicators.emptyInputsCount), (indicators.fieldsCount + indicators.inputsCount)) < 100">
        <br>
        <p-panel [toggleable]="true" [collapsed]="true">####[(collapsed)]="collapsed_2"####
            <ng-template pTemplate="header">
                ####{{ (collapsed_2 ? 'general.show' : 'general.hide') | translate }} {{ 'indicator.completion.global.' + translationKey | translate }} {{ 'indicator.completion.tab' | translate }}####
                {{ 'general.show' | translate }} {{ 'indicator.completion.global.' + translationKey | translate }} {{ 'indicator.completion.tab' | translate }}
            </ng-template>
            <div *ngFor="let tab of indicators.tabs;">
                <div *ngIf="tab.hasOwnProperty('indicator') && tab.hasOwnProperty('total') && (0 != tab.indicator || 0 != tab.total)">
                    <div class="row">
                        <span class="col-3"><strong><u>{{ tab.name }}</u></strong></span>
                        <p-progressBar class="col" [value]="calculateCompletionRate(tab.indicator, tab.total)"></p-progressBar>
                    </div>
                    <p-panel *ngIf="tab.hasOwnProperty('sections') && tab.sections.length" [toggleable]="true" [collapsed]="true">
                        <ng-template pTemplate="header">
                            {{ 'general.show' | translate }} {{ 'indicator.completion.global.' + translationKey | translate }} {{ 'indicator.completion.section' | translate }}
                        </ng-template>
                        <div *ngFor="let section of tab.sections">
                            <div *ngIf="undefined !== section && null !== section && section.hasOwnProperty('indicator') && section.hasOwnProperty('total') && (0 != section.indicator || 0 != section.total)">
                                <div class="row">
                                    <span class="col-3">&nbsp;&nbsp;&nbsp;&nbsp;<strong>{{ section.name }}</strong></span>
                                    <p-progressBar class="col" [value]="calculateCompletionRate(section.indicator, section.total)"></p-progressBar>
                                </div>
                                <p-panel *ngIf="section.hasOwnProperty('fields') && section.fields.length" [toggleable]="true" [collapsed]="true">
                                    <ng-template pTemplate="header">
                                        {{ 'general.show' | translate }} {{ 'indicator.completion.field' | translate }}
                                    </ng-template>
                                    <div *ngFor="let field of section.fields">
                                        <div *ngIf="undefined !== field && null !== field && null === field.value">
                                            <div class="row">
                                                <span class="col-4" [ngClass]="{ 'text-danger': 'fieldset' !== field.type }">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ field.name }} <span *ngIf="field.hasOwnProperty('protocol') && field.protocol !== ''">({{ field.protocol }})</span></span>
                                                <p-progressBar class="col" *ngIf="field.hasOwnProperty('collections') && field.collections.length" [value]="calculateCompletionRate(field.indicator, field.total)"></p-progressBar>
                                            </div>
                                            <div *ngIf="field.hasOwnProperty('collections') && field.collections.length">
                                                <p-panel *ngIf="field.hasOwnProperty('collections') && field.collections.length" [toggleable]="true" [collapsed]="true">
                                                    <ng-template pTemplate="header">
                                                        {{ 'general.show' | translate }} {{ 'indicator.completion.fieldset' | translate }}
                                                    </ng-template>
                                                    <div *ngFor="let collection of field.collections; let i = index">
                                                        <div *ngIf="collection.length">
                                                        ####<div *ngIf="collection.collectionInputs.length">#### #### Search "cominfos" ####
                                                                <hr *ngIf="i != 0">
                                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em>Collection N°{{ i + 1 }}</em></span>
                                                                ####<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em>Collection N°{{ collection.collectionIndex }}</em></span>#### #### Search "cominfos" ####
                                                                <div *ngFor="let input of collection">
                                                                ####<div *ngFor="let input of collection.collectionInputs">#### #### Search "cominfos" ####
                                                                        <div *ngIf="undefined !== input && null !== input && null === input.value">
                                                                    <div class="row">
                                                                        <span class="col-4" [ngClass]="{ 'text-danger': 'fieldset' !== input.type }">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>{{ input.name }} <span *ngIf="input.hasOwnProperty('protocol') && input.protocol !== ''">({{ input.protocol }})</span></small></span>
                                                                        <p-progressBar class="col" *ngIf="input.hasOwnProperty('collections') && input.collections.length" [value]="calculateCompletionRate(input.indicator, input.total)"></p-progressBar>
                                                                    </div>
                                                                    <div *ngIf="input.hasOwnProperty('collections') && input.collections.length">
                                                                        <p-panel *ngIf="input.hasOwnProperty('collections') && input.collections.length" [toggleable]="true" [collapsed]="true">
                                                                            <ng-template pTemplate="header">
                                                                                {{ 'general.show' | translate }} {{ 'indicator.completion.fieldset' | translate }}
                                                                            </ng-template>
                                                                            <div *ngFor="let subCollection of input.collections; let j = index">
                                                                                <div *ngIf="subCollection.length">
                                                                                    <hr *ngIf="j != 0">
                                                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em>Collection N°{{ j + 1 }}</em></span>
                                                                                    <div *ngFor="let subInput of subCollection">
                                                                                        <div *ngIf="undefined !== subInput && null !== subInput && null === subInput.value">
                                                                                            <span class="text-danger">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>{{ subInput.name }} <span *ngIf="subInput.hasOwnProperty('protocol') && subInput.protocol !== ''">({{ subInput.protocol }})</span></small></span>
                                                                                        </div>
                                                                                    </div>  
                                                                                </div>
                                                                            </div>   
                                                                        </p-panel>                                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                    </div>   
                                                </p-panel>                                             
                                            </div>
                                        </div>
                                    </div>
                                </p-panel>
                            </div>
                        </div>
                    </p-panel>
                </div>
            </div>
        </p-panel>
    </div>
</p-panel>
-->

<!-- SIMPLE DISPLAY -->
<!-- NOT USED FOR INSTANCE + NEED TO FIX % -->
<!--
<p-panel *ngIf="((key === 'mandatory' && displayMandatoryIndicator) || (key === 'salabilityIndicator' && displaySalabilityIndicator)) && showCompleteSalabilityIndicatorsInterface === false && showMinifiedSalabilityIndicatorsInterface === false && indicators && indicators.hasOwnProperty('tabs') && indicators.tabs.length && (indicators.hasOwnProperty('fieldsCount') && indicators.fieldsCount || indicators.hasOwnProperty('inputsCount') && indicators.inputsCount)" [toggleable]="true" [collapsed]="false">####[(collapsed)]="collapsed_1"####
    <ng-template pTemplate="header">
        ####{{ (collapsed_1 ? 'general.show' : 'general.hide') | translate }} {{ 'indicator.completion.global.' + translationKey | translate }}####
        {{ 'indicator.completion.global.' + translationKey | translate }}
    </ng-template>

    <p-progressBar [value]="calculateCompletionRate((indicators.emptyFieldsCount + indicators.emptyInputsCount), (indicators.fieldsCount + indicators.inputsCount))"></p-progressBar>
    <div *ngIf="calculateCompletionRate((indicators.emptyFieldsCount + indicators.emptyInputsCount), (indicators.fieldsCount + indicators.inputsCount)) < 100">
        <br>
        ####{{ (collapsed_2 ? 'general.show' : 'general.hide') | translate }} {{ 'indicator.completion.global.' + translationKey | translate }} {{ 'indicator.completion.tab' | translate }}####
        <div *ngFor="let tab of indicators.tabs;">
            <div *ngIf="tab.hasOwnProperty('indicator') && tab.hasOwnProperty('total') && (0 != tab.indicator || 0 != tab.total)">
                <div *ngFor="let section of tab.sections">
                    <div *ngIf="undefined !== section && null !== section && section.hasOwnProperty('indicator') && section.hasOwnProperty('total') && (0 != section.indicator || 0 != section.total)">
                        <ng-template pTemplate="header">
                            {{ 'general.show' | translate }} {{ 'indicator.completion.field' | translate }}
                        </ng-template>
                        <div *ngFor="let field of section.fields">
                            <div *ngIf="undefined !== field && null !== field && null === field.value">
                                <div class="row">
                                    <span class="col-4" [ngClass]="{ 'text-danger': 'fieldset' !== field.type }">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ field.name }} <span *ngIf="field.hasOwnProperty('protocol') && field.protocol !== ''">({{ field.protocol }})</span></span>
                                </div>
                                <div *ngIf="field.hasOwnProperty('collections') && field.collections.length">
                                    <p-panel *ngIf="field.hasOwnProperty('collections') && field.collections.length" [toggleable]="true" [collapsed]="true">
                                        <ng-template pTemplate="header">
                                            {{ 'general.show' | translate }} {{ 'indicator.completion.fieldset' | translate }}
                                        </ng-template>
                                        <div *ngFor="let collection of field.collections; let i = index">
                                            <div *ngIf="collection.length">
                                            ####<div *ngIf="collection.collectionInputs.length">#### #### Search "cominfos" ####
                                                    <hr *ngIf="i != 0">
                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em>Collection N°{{ i + 1 }}</em></span>
                                                    ####<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em>Collection N°{{ collection.collectionIndex }}</em></span>#### #### Search "cominfos" ####
                                                    <div *ngFor="let input of collection">
                                                    ####<div *ngFor="let input of collection.collectionInputs">#### #### Search "cominfos" ####
                                                            <div *ngIf="undefined !== input && null !== input && null === input.value">
                                                        <div class="row">
                                                            <span class="col-4" [ngClass]="{ 'text-danger': 'fieldset' !== input.type }">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>{{ input.name }} <span *ngIf="input.hasOwnProperty('protocol') && input.protocol !== ''">({{ input.protocol }})</span></small></span>
                                                        </div>
                                                        <div *ngIf="input.hasOwnProperty('collections') && input.collections.length">
                                                            <p-panel *ngIf="input.hasOwnProperty('collections') && input.collections.length" [toggleable]="true" [collapsed]="true">
                                                                <ng-template pTemplate="header">
                                                                    {{ 'general.show' | translate }} {{ 'indicator.completion.fieldset' | translate }}
                                                                </ng-template>
                                                                <div *ngFor="let subCollection of input.collections; let j = index">
                                                                    <div *ngIf="subCollection.length">
                                                                        <hr *ngIf="j != 0">
                                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em>Collection N°{{ j + 1 }}</em></span>
                                                                        <div *ngFor="let subInput of subCollection">
                                                                            <div *ngIf="undefined !== subInput && null !== subInput && null === subInput.value">
                                                                                <span class="text-danger">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>{{ subInput.name }} <span *ngIf="subInput.hasOwnProperty('protocol') && subInput.protocol !== ''">({{ subInput.protocol }})</span></small></span>
                                                                            </div>
                                                                        </div>  
                                                                    </div>
                                                                </div>   
                                                            </p-panel>                                             
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                        </div>   
                                    </p-panel>                                             
                                </div>                                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-panel>
-->

<!-- MINIFIED DISPLAY -->
<p-panel *ngIf="((key === 'mandatory' && displayMandatoryIndicator) || (key === 'salabilityIndicator' && displaySalabilityIndicator)) && showMinifiedSalabilityIndicatorsInterface === true && indicatorsMinified && indicatorsMinified.length" [toggleable]="true" [collapsed]="false">
    <ng-template pTemplate="header">
        {{ 'indicator.' + translationKey | translate }}
    </ng-template>

    <div class="row row-cols-2">
        <div *ngFor="let field of indicatorsMinified">
            <div style="overflow: hidden; text-overflow: ellipsis;" class="mt-1 ml-3 text-truncate">
                <span [ngClass]="{ 'text-danger': field.isEmpty }"><strong>{{ field.name }} : </strong> <span *ngIf="field.hasOwnProperty('value') && field.value !== ''" [ngClass]="{ 'text-danger': 'date' === field.type && field.isEmpty }">{{ field.value === true ? '✓' : (field.value === false ? '✕' : field.value) }}</span></span>
            </div>
        </div>
    </div>
</p-panel>

<br>

<!--
    cominfos
    Will hide empty panel + progress bar but keep field name displayed since we can't know anymore if collections is empty cause there is no collections or no empty inputs
-->
