import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faEdit, faSave, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { GroupService } from "app/core/services/admin/group/group.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { DataService } from "app/core/services/global/data/data.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES } from "app/shared/routes";

@Component({
    selector: "app-user",
    templateUrl: "./user-form.component.html",
})
export class UserFormComponent implements OnInit, OnDestroy {
    faSave = faSave;
    faTimes = faTimesCircle;
    faEdit = faEdit;
    faKey = faKey;

    form: FormGroup;
    isSubmitted = false;
    activeRoute = this.route.snapshot.data.mode;
    userId = this.route.snapshot.params["id"];
    dataProfile: any;
    profiles = [];
    groups = [];
    module: string;

    receiveMail: any[] = [{
        id: "workflow",
        name: this._translate.instant("admin.users.actionMail.workflow")
    },{
        id:"mediaExpired",
        name: this._translate.instant("admin.users.actionMail.mediaExpired")
    }]

    currentIdUser: number;

    public acl: ACL;

    constructor(
        private _userService: UserService,
        private _groupService: GroupService,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private _translate: TranslateService,
        private _layout: LayoutService,
        private _toaster: ToastService,
        private _modal: SwalModalService,
        private _dataService: DataService,
        private _authService: AuthService
    ) {}

    ngOnInit() {
        this.currentIdUser = this._userService.getUserIdFromToken();

        this.acl = this._userService.getUserAclFromToken();

        if ("update" === this.activeRoute) {
            this.getUser();
        } else {
            this.getProfiles();
        }
    }

    ngOnDestroy() {
        this._layout.breadcrumb.setPath(null, 2);
    }

    /**
     * Init the form
     */
    initForm() {
        /**
         * If we have user's information
         */
        if (this.dataProfile) {
            this.form = this.formBuilder.group({
                lastName: [this.dataProfile["lastName"] ? this.dataProfile["lastName"] : "", Validators.required],
                firstName: [this.dataProfile["firstName"] ? this.dataProfile["firstName"] : "", Validators.required],
                email: [this.dataProfile["email"] ? this.dataProfile["email"] : "", [Validators.required, Validators.email]],
                profile: [
                    { value: -1, disabled: !this.acl.SYSPAD_ADMIN_ROLE_UPDATE || (this.dataProfile && this.dataProfile.hasOwnProperty("internalCode") && "MAESTRO" === this.dataProfile.internalCode) },
                    Validators.required,
                ],
                formGroups: [
                    {
                        value: this.dataProfile["groups"] ? this.dataProfile["groups"] : [],
                        disabled: !this.acl.MAESTRO_ADMIN_GROUP_UPDATE || (this.dataProfile && this.dataProfile.hasOwnProperty("internalCode") && "MAESTRO" === this.dataProfile.internalCode),
                    },
                ],
                isWorkflowMail: [this.dataProfile["sendMail"] && Array.isArray(this.dataProfile["sendMail"]) ? this.dataProfile["sendMail"] : []]
            });

            const prof = this.profiles.find((p) => p.name === this.dataProfile.profile);
            this.form.patchValue({ profile: prof ? prof.id : -1 });
        } else {
            this.form = this.formBuilder.group({
                lastName: ["", Validators.required],
                firstName: ["", Validators.required],
                email: ["", [Validators.required, Validators.email]],
                profile: [{ value: -1, disabled: this.dataProfile && this.dataProfile.hasOwnProperty("internalCode") && "MAESTRO" === this.dataProfile.internalCode }, Validators.required],
                formGroups: [{ value: [], disabled: this.dataProfile && this.dataProfile.hasOwnProperty("internalCode") && "MAESTRO" === this.dataProfile.internalCode }],
                sendEmail: [true],
                isWorkflowMail: [[]]
            });
        }
    }

    get formControls() {
        return this.form.controls;
    }

    /**
     * Submit the form
     */
    formSubmit(): void {
        this.isSubmitted = true;

        if (this.form.invalid) {
            return;
        }

        // Enable fields to send their content
        this.form.controls["profile"].enable();
        this.form.controls["formGroups"].enable();
        let groups = this.form.value.formGroups.map((g) => g.id);
        const prof = this.form.value.profile ? this.form.value.profile : "";
        /**
         * Update user
         */
        if (this.dataProfile) {
            this._userService.updateUser(this.dataProfile.id, this.form.value.lastName, this.form.value.firstName, this.form.value.email, prof, groups, this.form.value.isWorkflowMail).subscribe(() => {
                if (this._userService.getUserIdFromToken() === this.dataProfile.id) {
                    this._authService.refreshToken(true);
                }

                this.redirect("updatedUserData");
            });
            /**
             * Create user
             */
        } else {
            this._userService.createUser(this.form.value, prof, groups).subscribe(() => {
                this.redirect("createdUserData");
            });
        }
    }

    /**
     * Get user's information
     */
    getUser() {
        this._userService.getById(this.userId).subscribe((data) => {
            this.dataProfile = data.data;

            this.getProfiles();
        });
    }

    /**
     * Get profiles
     */
    getProfiles() {
        this._userService.getProfiles().subscribe((res) => {
            this.profiles = res.data;

            if ("update" === this.activeRoute && "Maestro" === this.dataProfile.profile) {
                this.profiles.push({ id: -1, name: "Maestro" });
            }

            if (this.dataProfile && this.dataProfile["profile"]) {
                this._layout.breadcrumb.setPath(
                    {
                        routerLink: null,
                        name: this.dataProfile["firstName"] + " " + this.dataProfile["lastName"],
                    },
                    2
                );
            }

            this.getGroups();
        });
    }

    getGroups() {
        if (this.acl.MAESTRO_ADMIN_GROUP_LIST) {
            this._groupService.getAll().subscribe((data) => {
                const groupsWithoutChildren = this._groupService.removeChildrenFromGroup(data.data);

                if (undefined !== this.dataProfile) {
                    const mergedData = [...this.dataProfile.groups, ...groupsWithoutChildren]; // Here we merge current edited user and current logged user groups
                    let uniqueAssociableGroups = this._dataService.uniqueData(mergedData);
                    this.groups = uniqueAssociableGroups.sort((a, b) => a.name.localeCompare(b.name)); // Here we order merged data
                } else {
                    this.groups = groupsWithoutChildren; // No need to order since data returned already ordered
                }

                this.initForm();
            });
        } else {
            this.groups = this.dataProfile.groups;
            this.initForm();
        }
    }

    /**
     * Return to user's list
     */
    onBack() {
        if (!this.acl.MAESTRO_MODULE_ADMIN_MODULE || !this.acl.SYSPAD_ADMIN_USER_LIST) {
            this.router.navigateByUrl(`/${MAESTRO_ROUTES.public.base}`);
        } else {
            this.router.navigateByUrl(`/${MAESTRO_ROUTES.admin.base}/${MAESTRO_ROUTES.admin.users}`); // this.router.navigate([MAESTRO_ROUTES.admin.base, MAESTRO_ROUTES.admin.users]);
        }
    }

    /**
     * Check if the email is available
     */
    onChange() {
        if ((this.dataProfile && this.formControls.email.value !== this.dataProfile.email && !this.formControls.email.errors) || !this.formControls.email.errors) {
            this._modal.isActivated = false;
            this._userService.check(this.form.get("email").value, null).subscribe(
                (data) => {
                    this.form.get("email").setErrors(null);
                    this._modal.isActivated = true;
                },
                (error) => {
                    this.form.get("email").setErrors({ duplicate: true });
                    this._modal.isActivated = true;
                }
            );
        }
    }

    changePassword() {
        this.router.navigate([MAESTRO_ROUTES.public.password.base], { relativeTo: this.route });
    }

    redirect(fragment: string) {
        this._toaster.show({
            message: this._translate.instant(`general.${fragment}`),
            type: "success",
        });

        this.onBack();
    }
}
