import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ElementTypeListResolver, ElementTypeListComponent } from "./list";
import { ElementTypeListGuard } from "./list/element-type-list.guard";

const ElementTypeRoutes: Routes = [{ path: "", component: ElementTypeListComponent, resolve: { elementTypes: ElementTypeListResolver }, canActivate: [ElementTypeListGuard] }];

@NgModule({
    imports: [RouterModule.forChild(ElementTypeRoutes)],
    exports: [RouterModule],
})
export class ElementTypeRoutingModule {}
