<div class="row">
    <div class="col-md-8 mx-auto">
        <div class="card p-4">
            <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                    <h1>{{ 'login.sign_in.title' | translate }}</h1>
                    <p class="text-muted">{{ 'login.sign_in.subtitle' | translate }}</p>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text" for="email">
                                <fa-icon [icon]="faUser"></fa-icon>
                            </label>
                        </div>
                        <input type="text" id="email" formControlName="email" class="form-control" placeholder="{{ 'login.email.field' | translate }}" required="required" [ngClass]="{ 'is-invalid': isSubmitted && formControls.email.errors }"/>
                        <div *ngIf="isSubmitted && formControls.email.errors" class="invalid-feedback">
                            <div *ngIf="formControls.email.errors.required">{{ 'login.email.error.required' | translate }}</div>
                        </div>
                    </div>
                    <div class="input-group mb-4">
                        <div class="input-group-prepend">
                            <label class="input-group-text" for="password">
                                <fa-icon [icon]="faKey"></fa-icon>
                            </label>
                        </div>
                        <input type="password" id="password" formControlName="password" class="form-control" placeholder="{{ 'login.password.field' | translate }}" required="required" [ngClass]="{ 'is-invalid': isSubmitted && formControls.password.errors }"/>
                        <div *ngIf="isSubmitted && formControls.password.errors" class="invalid-feedback">
                            <div *ngIf="formControls.password.errors.required">{{ 'login.password.error.required' | translate }}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button type="submit" class="btn btn-primary px-4">{{ 'login.sign_in.button' | translate }}</button>
                        </div>
                        <div class="col-6 text-right">
                            <a class="btn btn-link px-0" [routerLink]="['/', route.public.password.base]">{{ 'login.password.forget' | translate }}</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
