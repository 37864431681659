import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ElementViewComponent } from "./element-view.component";
import { ElementViewGuard } from "./element-view.guard";
import { ElementViewResolver } from "./element-view.resolver";
import { ElementViewRoutingModule } from "./element-view.routing";
import { ElementViewTabModule } from "./tab/view-tab.module";

@NgModule({
    imports: [ElementViewRoutingModule, ElementViewTabModule, SharedModule, SharedComponentsModule],
    declarations: [ElementViewComponent],
    providers: [ElementViewResolver, ElementViewGuard],
})
export class ElementViewModule {}
