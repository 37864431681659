import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { DropDownComponent } from "./drop-down.component";

@NgModule({
    declarations: [DropDownComponent],
    exports: [DropDownComponent],
    imports: [SharedModule],
})
export class DropDownModule {}
