import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ConfirmTabGuard } from "app/core/guards/confirm-tab.guard";
import { ListCascadeListComponent } from "./list/listcascade-list.component";
import { ListCascadeListResolver } from "./list/listcascade-list.resolver";
import { ListCascadeUpdateComponent } from "./update/listcascade-update.component";
import { ListCascadeUpdateResolver } from "./update/listcascade-update.resolver";

const listRoutes: Routes = [
    { path: "", component: ListCascadeListComponent, resolve: { lists: ListCascadeListResolver } },
    { path: "update/:id", component: ListCascadeUpdateComponent, resolve: {list: ListCascadeUpdateResolver}, 
    canDeactivate: [ConfirmTabGuard], },
];

@NgModule({
    imports: [RouterModule.forChild(listRoutes)],
    exports: [RouterModule],
})
export class ListCascadeRoutingModule {}
