import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { faEdit, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ThumbnailItemEvent, ThumbnailMenuAction } from "app/shared/models/project/thumbnail";
import { Subscription } from "rxjs";

@Component({
    selector: "app-products-list",
    templateUrl: "./products-list.component.html",
})
export class ProductsListComponent implements OnInit, OnDestroy {
    @Input() products: any[];
    @Input() isFlatplan: boolean = false;

    @Output() pageAction = new EventEmitter<ThumbnailItemEvent>();

    faTrash = faTrashAlt;
    faEye = faEye;
    faEdit = faEdit;
    private _subscriptions: Subscription[] = [];

    constructor(private _sanitizer: DomSanitizer, private _layout: LayoutService) {}

    ngOnInit() {}

    ngOnDestroy() {}

    getPagesInformation(product): string {
        let information = "";

        for (const page of product.pages) {
            information += `<p>${page.name} - ${page.workflow} - Page ${page.folio}</p>`;
        }

        return information;
    }

    emit(str: string, page: any): void {
        switch (str) {
            case "edit":
                this.pageAction.emit({ action: ThumbnailMenuAction.Edit, data: page });
                break;
            case "preview":
                this.pageAction.emit({ action: ThumbnailMenuAction.Preview, data: page });
                break;
            case "delete":
                this.pageAction.emit({ action: ThumbnailMenuAction.PageDelete, data: page });
        }
    }
}
