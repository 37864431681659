import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { ThumbnailMenu } from "app/shared/models/project/thumbnail";

@Component({
    selector: "app-page-thumbnail-menu",
    templateUrl: "./page-thumbnail-menu.component.html",
    styleUrls: ["./page-thumbnail-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageThumbnailMenuComponent {
    @Input() disabled: boolean;
    @Input() menu: ThumbnailMenu = [];
    @Output() menuClick: EventEmitter<any> = new EventEmitter();

    readonly faEdit = faEdit;
    readonly faTools = faTools;
    readonly faTrash = faTrashAlt;
}
