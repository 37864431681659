<div   *ngIf="display && display.display">
    <section class="d-flex justify-content-between  align-items-center"
        *ngIf="display.places && display.places.length > 0">
        <ul class="d-flex tab-workflow justify-content-between align-items-center w-100">
            <ng-container *ngFor="let place of places; let i = index">
                <li class="position-relative text-center"
                    [tooltipClass]="(i < indexCurrentPlace  && canSetValue) ? 'cancel-workflow' : (place.isActive && canSetValue) ? 'valid-workflow' : null"
                    [ngbTooltip]="((i < indexCurrentPlace && !place.disabled) && canSetValue) ? cancelWorkflow : (place.isActive && canSetValue)  ? validateTheWorkflow : null"
                    >
                    <button
                        (click)="getTransitionKey(display.places[i === indexCurrentPlace  ? i +1 :  i],$event)"
                        [ngClass]="{'forbidden-input': (i !== indexCurrentPlace - 1 && i !== indexCurrentPlace) || !canSetValue}"
                        [disabled]=" (i !== indexCurrentPlace - 1 && i !== indexCurrentPlace) || !canSetValue" >
                        <span class="circle"
                            [style]="(place.isActive && canSetValue) ? {'border-color' :'#0c83ff','color': '#0c83ff'} : (i < indexCurrentPlace) ? {'background-color' :'#00BDAC'} :  (canSetValue ? null : {'background-color' :'#C8CED3'})"
                        >
                        <i *ngIf="i === indexCurrentPlace && canSetValue" class="bi bi-pencil"></i>
                        <i *ngIf="i< indexCurrentPlace " class="bi bi-check-lg"></i>
                        <p class="number" *ngIf="(i > indexCurrentPlace - 1 && i !== indexCurrentPlace && !place.isValidated) || (i > indexCurrentPlace - 1 && !canSetValue)">{{ i + 1 }}
                        </p>
                        </span>
                    </button>
                    <p class="position-absolute style-position ff-title">
                        {{ place.title }}
                        <i *ngIf="display.places[i].transitions !== undefined" (click)="getInfoWorkflow(display.places[i])" title="{{ getTitle(place) }}" class="bi bi-clock"></i>
                    </p>
                </li>
                <!-- <div class="workflow-style" title="{{ getTitle(place) }}"
                    [ngClass]="{'is-validated': place.isValidated && !place.isActive, 'forbidden': i !== indexCurrentPlace - 1 && i !== indexCurrentPlace}">
                    <article
                        [style]="(place.isActive) ? {'border-color' :'#0c83ff','color': '#0c83ff'} : (i < indexCurrentPlace) ? {'background-color' :'#00BDAC'} :  null"
                        class="circle">
                        <i *ngIf="i === indexCurrentPlace" class="bi bi-pencil"></i>
                        <i *ngIf="i < indexCurrentPlace" class="bi bi-check-lg"></i>
                        <p *ngIf="i !== indexCurrentPlace - 1 && i !== indexCurrentPlace && !place.isValidated">{{ i + 1 }}
                        </p>
                    </article>
                    <div class="justify-content-between">
                        <span [style]="place.isActive ? { 'color' : '#0c83ff'} : null" class="text-center">{{ place.title
                            }}</span>

                        <i [ngClass]="{'forbidden-input': i !== indexCurrentPlace - 1 && i !== indexCurrentPlace,'d-none' : place.isValidated}"
                            (click)="getTransitionKey(display.places[i === indexCurrentPlace  ? i +1 :  i],$event)"
                            class="bi bi-circle pointer ml-1"></i>

                        <i *ngIf="i === indexCurrentPlace - 1"
                            (click)="getTransitionKey(display.places[i === indexCurrentPlace  ? i +1 :  i],$event)"
                            class=" ml-1 bi bi-x-circle pointer"></i>
                    </div> -->
                <!-- </div> -->

                <div [style]="i < indexCurrentPlace ? {'border' : '1px solid #00BDAC'} : {'border' : '1px solid #c8ced3'}" *ngIf="i % places.length != places.length -1" class="hr-style"></div>
            </ng-container>
        </ul>

    </section>
</div>
