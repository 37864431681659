import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { MediaService } from "app/core/services/dam/media.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { MaestroTags } from "app/shared/models";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ThumbsnailContainerResolver implements Resolve<SidebarData> {
    constructor(private _service: ThumbnailsService,private _mediaService: MediaService) { }

    // TODO : when dam tag ready get with thumbnails
    // resolve(): Observable<ThumbnailsResources> {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SidebarData> {
        const url = state.url;

        return  this._service.getSidebarData(url).pipe(map((data: any) => <SidebarData>{ tags: data.data }))
            
    }
}

// TODO : move in dedicated file ? in service ?
export interface SidebarData {
    tags: MaestroTags;
}

// export interface ThumbnailsResources extends SidebarData {
//   medias: MaestroMedias;
// }
