import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DashboardService } from "app/core/services/dashboard/dashboard.service";
import { ChartCallback, ChartCustomTooltip, ChartDataset, ChartOption, ChartTitle, ChartTooltip, MaestroBlocData, MaestroQuery } from "app/shared/models/dashboard";
import { ChartListItem } from "app/shared/models/dashboard/chart/chart-list-item";
import { Chart } from "node_modules/chart.js";

@Component({
    selector: "[app-dashboard-chart-level-3]",
    templateUrl: "./chart-level-3.component.html",
})
export class ChartLevel3Component implements OnInit {
    @Input() bloc: MaestroBlocData;

    list: object[] = [];

    @ViewChild("myChart", { static: true })
    myChart: ElementRef;
    chart: any;

    readonly form: FormGroup;

    constructor(private _formBuilder: FormBuilder, private _service: DashboardService) {
        this.form = this._initForm();
    }

    protected _initForm() {
        const form = this._formBuilder.group({
            item: [],
        });

        return form;
    }

    // TODO : add control for sub-query + select
    ngOnInit() {
        if (this.bloc && this.bloc.data) {
            if (Object.keys(this.bloc.subQuery).length > 0) {
                // We have a list in query wich will call subQuery when item will be selected
                this.drawChart();
                for (const [key, value] of Object.entries(this.bloc.data)) {
                    // TODO : find a better way to access bloc.data since it's a list there is only bloc.data value ... guess
                    if (value.length > 0) {
                        this.list = value;
                    }
                }

                this.form.get("item").setValue([this.list[0]]); // Set the default value
                this.form.get("item").valueChanges.subscribe((data) => this._getData(data)); // Listen for change
            }
        }
    }

    _getData(item: ChartListItem[]) {
        if (item.length > 0) {
            if (this.chart.data.labels.length > 0 || this.chart.data.datasets.length > 0) {
                this.removeChartData();
            }

            if (!this.bloc.isSample) {
                const regex: RegExp = /stringToReplaceByValue/g;
                const newQuery: string = this.bloc.subQuery.query.replace(regex, item[0].id);
                const subQuery: MaestroQuery = {
                    query: newQuery,
                };

                this._service.getDashboardData(subQuery).subscribe((result) => {
                    //  TODO : check case many subData ?
                    for (const subMethod of this.bloc.subMethods) {
                        for (const [key] of Object.entries(subMethod)) {
                            // TODO : add type parent-child-subChild cf back
                            const subMethodData = result.data[key];
                            if (subMethodData.chartDatas.length > 0) {
                                this.createChartData(subMethodData);
                            }
                        }
                    }
                });
            } else {
                this.createChartData(this.bloc.subQuery);
            }
        }
    }

    drawChart() {
        const canvas = this.myChart.nativeElement.getContext("2d");
        this.chart = new Chart(canvas, {
            type: this.bloc.type as any,
            data: {
                labels: [],
                datasets: [],
            },
            options: {},
        });
    }

    removeChartData() {
        this.chart.data = { labels: [], datasets: [] };
        this.chart.options = {};
        this.chart.update();
    }

    // TODO : add typage
    createChartData(subMethodData: any) {
        /* ChartDataset */
        const datasetLabel: string = subMethodData.name; // TODO : doesn't displayed in doughnut but displayed in bar chart ... set in options.title to display
        const datasetData: number[] = [];
        const datasetBackgroundColor: string[] = [
            // TODO : add to database model
            `rgba(255, 217, 0, 0.9)`, // Maestro yellow
            `rgba(255, 193, 7, 0.9)`, // Maestro warning
            `rgba(248, 108, 107, 0.9)`, // Maestro danger
            `rgba(99, 194, 222, 0.9)`, // Maestro info
            `rgba(32, 168, 216, 0.9)`, // Maestro primary
            `rgba(240, 243, 245, 0.9)`, // Maestro light
            `rgba(228, 229, 230, 0.9)`, // Maestro grey
            `rgba(164, 183, 193, 0.9)`, // Maestro secondary
            `rgba(41, 54, 61, 0.9)`, // Maestro dark
            // `rgba(78, 184, 170, 0.9)`, // Maestro green
            // `rgba(77, 189, 116, 0.9)`, // Maestro success
        ];
        const datatsetBorderColor: string[] = [
            // TODO : add to database model
            `rgba(255, 217, 0, 1)`, // Maestro yellow
            `rgba(255, 193, 7, 1)`, // Maestro warning
            `rgba(248, 108, 107, 1)`, // Maestro danger
            `rgba(99, 194, 222, 1)`, // Maestro info
            `rgba(32, 168, 216, 1)`, // Maestro primary
            `rgba(240, 243, 245, 1)`, // Maestro light
            `rgba(228, 229, 230, 1)`, // Maestro grey
            `rgba(164, 183, 193, 1)`, // Maestro secondary
            `rgba(41, 54, 61, 1)`, // Maestro dark
            // `rgba(78, 184, 170, 1)`, // Maestro green
            // `rgba(77, 189, 116, 1)`, // Maestro success
        ];
        const datasetBorderWidth = 1; // TODO : add to database model
        /* ChartDataset */

        /* ChartTooltip */
        // TODO : add to database model
        /*
        const tooltipEnabled = true;
        const tooltipMode = 'single';
        const tooltipBackgroundColor = '#FFF';
        const tooltipTitleFontSize = 16;
        const tooltipTitleFontColor = '#0066ff';
        const tooltipBodyFontColor = '#000';
        const tooltipBodyFontSize = 14;
        const tooltipDisplayColors = false;
        */
        /* ChartTooltip */

        /* ChartTitle */
        // TODO : add to database model
        const titleDisplay = true;
        const titleText = subMethodData.name;
        /* ChartTitle */

        const tooltipCallbackData = [];

        for (const chartData of subMethodData.chartDatas) {
            this.chart.data.labels.push(chartData.label);
            const chartDataDatasLength = chartData.datas.length;
            const length = chartDataDatasLength > 0 ? chartDataDatasLength : undefined;
            datasetData.push(length);

            // TODO : add if tooltip.enabled = true when added to database model
            const chartDataTooltips: string[] = [];
            for (const data of chartData.datas) {
                chartDataTooltips.push(data.subData);
            }

            tooltipCallbackData.push(chartDataTooltips);
        }

        function createChartCallback() {
            // TODO : add title = tooltipCallbackData.length
            const callback: ChartCallback = {
                // title: function(tooltipItem, data) { // TODO : add to database model
                //   return data['labels'][tooltipItem[0]['index']];
                // },
                label: function (tooltipItem, data) {
                    // TODO : add to database model
                    //return data['datasets'][0]['data'][tooltipItem['index']];
                    return tooltipCallbackData[tooltipItem["index"]];
                },
                // afterLabel: function(tooltipItem, data) { // TODO : add to database model
                //   var dataset = data['datasets'][0];
                //   var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset['_meta'][0]['total']) * 100);
                //   return '(' + percent + '%)';
                // },
            };

            return callback;
        }

        function createChartTooltip() {
            const tooltip: ChartTooltip = {
                /*
                enabled: tooltipEnabled,
                mode: tooltipMode,
                */
                callbacks: createChartCallback(),
                /*
                backgroundColor: tooltipBackgroundColor,
                titleFontSize: tooltipTitleFontSize,
                titleFontColor: tooltipTitleFontColor,
                bodyFontColor: tooltipBodyFontColor,
                bodyFontSize: tooltipBodyFontSize,
                displayColors: tooltipDisplayColors,
                */
                enabled: false, // Disable the on-canvas tooltip to use custom tooltip
                custom: function (tooltipModel: ChartCustomTooltip) {
                    // Custom Tooltip
                    var tooltipEl = document.getElementById("chartjs-tooltip"); // Tooltip Element

                    if (!tooltipEl) {
                        tooltipEl = document.createElement("div"); // Create element on first render
                        tooltipEl.id = "chartjs-tooltip";
                        var mainStyle = "background-color: rgba(0, 0, 0, 0.8)";
                        mainStyle += "; color: white";
                        mainStyle += "; border-radius: 6px";
                        tooltipEl.innerHTML = '<table style="' + mainStyle + '"></table>';
                        document.body.appendChild(tooltipEl);
                    }

                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = "0"; // Hide if no tooltip
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove("above", "below", "no-transform");
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add("no-transform");
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        // var titleLines = tooltipModel.title || [];
                        var bodyLines = tooltipModel.body.map(getBody);

                        // var innerHtml = '<thead>';
                        var innerHtml = "<tbody>";

                        // titleLines.forEach(function (title) {
                        //     innerHtml += '<tr><th style="padding: 2px 4px">' + "title" + '</th></tr>'; // Data Title
                        // });
                        // innerHtml += '</thead><tbody>';

                        bodyLines.forEach(function (body, i) {
                            var colors = tooltipModel.labelColors[i];
                            var style = "background:" + colors.backgroundColor;
                            style += "; border-color:" + colors.borderColor;
                            style += "; border-width: 2px";
                            var span = '<span style="' + style + '"></span>';
                            // innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            // body.forEach(element => {
                            //     innerHtml += '<tr><td style="padding: 2px 4px">' + span + element + '</td></tr>'; // Data
                            // });
                            let maxData = true;
                            for (let i = 0; i < 5; i++) {
                                if (body[i]) {
                                    innerHtml += '<tr><td style="padding: 2px 4px">' + span + body[i] + "</td></tr>"; // Data
                                } else {
                                    maxData = false;
                                }
                            }

                            maxData ? (innerHtml += '<tr><td style="padding: 2px 4px">' + span + "...</td></tr>") : null; // Data
                        });
                        innerHtml += "</tbody>";

                        var tableRoot = tooltipEl.querySelector("table");
                        tableRoot.innerHTML = innerHtml;
                    }

                    var position = this._chart.canvas.getBoundingClientRect(); // `this` will be the overall tooltip

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = "1";
                    tooltipEl.style.position = "absolute";
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
                    tooltipEl.style.pointerEvents = "none";
                },
            };

            return tooltip;
        }

        function createChartTitle() {
            const title: ChartTitle = {
                display: titleDisplay,
                text: titleText,
                fontColor: null,
            };
            return title;
        }

        function createChartOption() {
            const option: ChartOption = {
                circumference: 1 * Math.PI, // TODO : add to database model
                rotation: 1 * Math.PI, // TODO : add to database model
                tooltips: createChartTooltip(),
                title: createChartTitle(),
                /*legend: {
                    display: false,
                },*/
                responsive: true,
                maintainAspectRatio: false,
            };

            return option;
        }

        function createChartDataset() {
            const dataset: ChartDataset = {
                label: datasetLabel,
                data: datasetData,
                backgroundColor: datasetBackgroundColor,
                borderColor: datatsetBorderColor,
                borderWidth: datasetBorderWidth,
            };

            return dataset;
        }

        this.chart.data.datasets.push(createChartDataset());

        this.chart.options = createChartOption();

        this.chart.update();
    }
}
