import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroWorkflows } from "app/shared/models";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class DamWorkflowService {
    constructor(private _http: HttpClient) {}

    getAll(): Observable<MaestroWorkflows> {
        return this._http.get<MaestroWorkflows>(`${environment.damUrl}/workflow.json`);
    }

    deleteById(id: number): Observable<any> {
        return this._http.delete(`${environment.damUrl}/configuration/${id}.json`);
    }
}
