import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { TableService } from "app/core/services/global/table/table.service";
import { TreeService } from "app/core/services/global/tree/tree.service";
import { PageService } from "app/core/services/project/export/page.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { ACL } from "app/shared/models/acl";
import { LazyLoadDataModel } from "app/shared/models/lazy-load-data";
import { MaestroCommons } from "app/shared/models/maestro-entity/common";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { FilterMetadata, LazyLoadEvent } from "primeng-lts/api";
import { Subscription } from "rxjs";

@Component({
    selector: "app-project-list",
    templateUrl: "./project-list.component.html",
})
export class ProjectListComponent implements OnInit, OnDestroy {
    readonly actions: ActionButtonsDef = [
        { type: ActionType.View, right: "MAESTRO_PROJECT_PROJECTS_READ", /*objectRight: "ON_PROJECT_EXPORT_USE"*/ operation: "read" },
        { type: ActionType.FormUpdate, right: "MAESTRO_PROJECT_PROJECTS_UPDATE", /*objectRight:"ON_PROJECT_PROJECT_READ"*/ operation: "update" },
        { type: ActionType.Delete, right: "MAESTRO_PROJECT_PROJECTS_DELETE", operation: "delete" },
    ];

    private _actionButtonSub: Subscription;
    private _userGroups: MaestroCommons;
    private _acl: ACL;

    tableRows: any[] = [];
    tableColumns: any[] = [];
    totalRecords: number = 0;
    recordsFiltered: number = 0;
    currentDatatableFilters: LazyLoadEvent = {}; // Use to reload data when filter with tag/element type & action like duplicate/delete
    globalFilterFields: string[] = []; // Define which columns can be use to search data
    selectColumns: boolean = true; // Display a multiselect to show/hide columns
    _selectedColumns: any[] = []; // Define which columns are selected

    allFilters = {
        filterExportTypes: {
            data: [],
            cascadeSelect: false,
            field: "",
        },
        filterElementTypes: {
            data: [],
            cascadeSelect: false,
            field: "",
        },
        filterGroups: {
            data: [],
            cascadeSelect: false,
            field: "",
        },
        filterTags: {
            data: [],
            cascadeSelect: true,
            field: "",
        },
    };
    currentCustomFilters: any = {}; // Use to reload with the right filters
    shortPaginatorIsFirstPage: boolean = true;
    shortPaginatorIsLastPage: boolean = false;

    strictMode: boolean = false; // strict value given by datatable
    searchOnFieldValue: boolean = false; // search on export value given by datatable
    searchOnArchived: boolean = false; // Use in project to display archived projects

    constructor(
        private _layout: LayoutService,
        private _tableDataPipe: TableDataPipe,
        private _route: ActivatedRoute,
        private _router: Router,
        private _modal: SwalModalService,
        private _projectService: ProjectService,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _tableService: TableService,
        private _authService: AuthService,
        private _treeService: TreeService,
        private _pageService: PageService
    ) {}

    ngOnInit(): void {
        this._acl = this._userService.getUserAclFromToken();

        this.currentDatatableFilters.first = 0;

        this._layout.actionButton.enable = this._acl.MAESTRO_PROJECT_PROJECTS_CREATE ? true : false;
        this._layout.actionButton.title = "project.create";
        this._actionButtonSub = this._layout.actionButton.click$.subscribe((_) => this.onCreateButtonClick());
        this._route.data.subscribe((d) => {
            this._userGroups = d.projects.data;
        });
    }

    ngOnDestroy(): void {
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._actionButtonSub.unsubscribe();
    }

    onCreateButtonClick() {
        this._openModal();
    }

    /**
     * Create project
     * @param title
     */
    _create(title) {
        this._projectService.create({ name: title }).subscribe((res) => {
            this._router.navigate(["..", res.data, MAESTRO_ROUTES.projects.configure, MAESTRO_ROUTES.projects.general], { relativeTo: this._route });
        });
    }

    onActionClick(event: ActionEvent) {
        const id = event.rowId;
        const isDraft = this.tableRows.find((l) => {
            return Number(l.id) === id;
        }).draft;

        if (isDraft && ActionType.View === event.action) {
            this._modal.info(this._translateService.instant("modal.config"));
            return;
        }

        switch (event.action) {
            case ActionType.Delete:
                this._delete(id);
                break;
            case ActionType.FormUpdate:
                this._router.navigate(["..", id, MAESTRO_ROUTES.projects.configure, MAESTRO_ROUTES.projects.general], { relativeTo: this._route });
                break;
            case ActionType.View:
                this._router.navigate(["..", id, MAESTRO_ROUTES.projects.dashboard], { relativeTo: this._route });
                break;
        }
    }

    private _openModal() {
        this._modal
            .open({
                title: this._translateService.instant("breadcrumb.project.create"),
                input: "text",
                showCloseButton: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: this._translateService.instant("general.create"),
                cancelButtonText: this._translateService.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                confirmButtonColor: "#20a8d8",
            })
            .then((result) => {
                if (result.value) {
                    this._create(result.value);
                }
            });
    }

    /**
     * Delete a project
     *
     * @param id
     */
    private _delete(id: number) {
        this._projectService.deleteById(id).subscribe((_) => {
            this._modal.success(this._translateService.instant("general.deleted"));
            this.loadList(this.currentDatatableFilters);
        });
    }

    /**
     * Load data displayed in datatable
     * @param event
     */
    loadList(event: LazyLoadEvent): void {
        if (undefined !== event.filters) {
            this.setCustomFilters(event.filters);
        }

        const requestFilters: LazyLoadDataModel = {
            lazyLoadFilters: event,
            customFilters: this.currentCustomFilters,
            strictMode: this.strictMode,
            searchOnFieldValue: this.searchOnFieldValue,
            searchOnArchived: this.searchOnArchived,
        };

        this._projectService.getDatatableProjects(JSON.stringify(requestFilters)).subscribe((resp) => {
            const data = resp.data;
            this.allFilters.filterExportTypes.data = data.allExportTypes;
            this.allFilters.filterElementTypes.data = data.allElementTypes;
            this.allFilters.filterGroups.data = data.allGroups;

            const nodeTags = this._treeService.createTreeNode(data.allTags, "name", "label");
            this.allFilters.filterTags.data = nodeTags;
            const rows = this._initRows(data.rows);
            this.tableRows = Object.values(rows);
            this.tableColumns = Object.values(data.columns);
            this.totalRecords = data.recordsTotal;
            this.recordsFiltered = data.recordsFiltered;
            this.currentDatatableFilters = data.datatableFilters;
            this.setGlobalFilterFields();
            this._selectedColumns = this.tableColumns;
            this.shortPaginatorIsFirstPage = this.currentDatatableFilters.first === 0;
            this.shortPaginatorIsLastPage = this.currentDatatableFilters.first === this.recordsFiltered - this.tableRows.length;
        });
    }

    /**
     * Set custom filters formatted for request send to back
     * @param eventFilters
     */
    setCustomFilters(eventFilters: FilterMetadata): void {
        let customFilters = {};

        if (Object.keys(eventFilters).length) {
            Object.entries(eventFilters).map(([key, value]) => {
                if (this.allFilters.hasOwnProperty(key) && this.allFilters[key].field) {
                    let ids = [];
                    let subVal = value[0].value;

                    if (null !== subVal) {
                        ids = subVal.map((d) => d.id);
                    }

                    customFilters[this.allFilters[key].field] = ids;
                }
            });
        }

        this.currentCustomFilters = customFilters;
    }

    private _initRows(dataRows: any) {
        const groupIds = this._userGroups.map((g) => {
            return g.id;
        });

        let rows = [...dataRows];

        rows.forEach((row) => {
            if (groupIds.length && !groupIds.includes(row.idOwnerGroup) && row.idUser !== this._userService.getUserIdFromToken() && row.groups.length) {
                row.groupRight = {
                    read: true,
                    update: false,
                    delete: false,
                };
            }
        });

        return rows;
    }

    /**
     * Set global filters from LazyLoadEvent data
     */
    setGlobalFilterFields(): void {
        this.tableColumns.forEach((col) => {
            if (col.hasOwnProperty("field")) {
                if (col.hasOwnProperty("globalFilter") && col.globalFilter) {
                    this.globalFilterFields.push(col.field);
                }

                if (this.selectColumns && col.field) {
                    col.header = this._tableService.translateColumns(col); // Add columns header to display in columns selector
                }

                if (col.hasOwnProperty("filterList") && null !== col.filterList && col.hasOwnProperty("filterField") && null !== col.filterField && this.allFilters.hasOwnProperty(col.filterList)) {
                    this.allFilters[col.filterList].field = col.filterField;
                }
            }
        });
    }

    shortPaginatorPrev(): void {
        this.currentDatatableFilters.first = this.currentDatatableFilters.first - this.currentDatatableFilters.rows;
        this.loadList(this.currentDatatableFilters);
    }

    shortPaginatorReset(): void {
        this.currentDatatableFilters.first = 0;
        this.loadList(this.currentDatatableFilters);
    }

    shortPaginatorNext(): void {
        this.currentDatatableFilters.first = this.currentDatatableFilters.first + this.currentDatatableFilters.rows;
        this.loadList(this.currentDatatableFilters);
    }

    changeAdvancedSearch(event: any): void {
        if (this.hasOwnProperty(event.advancedSearchParam)) {
            this[event.advancedSearchParam] = event.advancedSearchValue;
        }
    }

    downloadArchive(event) {
        this._pageService.downloadArchive(event);
    }
}
