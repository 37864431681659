import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";

@Injectable()
export class MessageInformationService {
    private eventSourceMessage = new Subject<any>();
    eventMessage$ = this.eventSourceMessage.asObservable();

    constructor(private http: HttpClient) {}

    emitEventMessage() {
        this.eventSourceMessage.next();
    }

    getMessage(): Observable<any> {
        return this.http.get<any>(`${environment.adminApiUrl}/messages-information.json`);
    }

    deleteMessage(id: number): Observable<any> {
        return this.http.delete(`${environment.adminApiUrl}/message-information/delete/${id}.json`);
    }

    createMessage(formValue: any): Observable<any> {
        return this.http.post<any>(`${environment.adminApiUrl}/message-information/create.json`, formValue);
    }

    updateMessage(formValue: any, id: number): Observable<any> {
        return this.http.put(`${environment.adminApiUrl}/message-information/update/${id}.json`, formValue);
    }
}
