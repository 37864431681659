import { NgModule } from '@angular/core';
import { SharedModule } from "app/shared/shared.module";
import { TreeTagComponent } from './treeTag.component';

@NgModule({
    declarations: [
        TreeTagComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        TreeTagComponent
    ]
})
export class TreeTagModule { }
