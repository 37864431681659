import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from "@angular/core";
import { Subject } from "rxjs";
import { SpinnerComponent } from "../../../../modules/spinner/spinner.component";

@Injectable()
export class SpinnerService {
    readonly onDisplayChange: Subject<boolean> = new Subject<boolean>();
    spinnerComponent: ComponentRef<SpinnerComponent>;

    private showRequestCount = 0;
    private isDisplayed: boolean;

    private isActivable = true;

    /**
     * When display is updated change the visibility of the spinner
     */
    private set display(isDisplayed: boolean) {
        if (this.isDisplayed !== isDisplayed) {
            this.isDisplayed = isDisplayed;
            this.isDisplayed ? this.addSpinner() : this.removeSpinner();
            this.onDisplayChange.next(this.isDisplayed);
        }
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private appRef: ApplicationRef) {}

    /**
     * Disable the spinner
     */
    disable(): void {
        this.isActivable = false;
    }

    /**
     * Activate the spinner
     */
    activate(): void {
        this.isActivable = true;
    }

    /**
     * Show the spinner
     */
    show() {
        if (this.isActivable && ++this.showRequestCount) {
            this.display = true;
        }
    }

    /**
     * Hide the spinner
     */
    hide() {
        if (!--this.showRequestCount) {
            this.display = false;
        } else if (this.showRequestCount < 0) {
            this.showRequestCount = 0;
            this.display = false;
        }
    }

    private addSpinner() {
        this.spinnerComponent = this.componentFactoryResolver.resolveComponentFactory(SpinnerComponent).create(this.injector);
        this.appRef.attachView(this.spinnerComponent.hostView);
        const domElem = (this.spinnerComponent.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private removeSpinner() {
        this.appRef.detachView(this.spinnerComponent.hostView);
        this.spinnerComponent.destroy();
    }
}
