import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConfirmGuard } from "app/core/guards/confirm.guard";

import { FieldsetListComponent, FieldsetListResolver } from "./list";
import { FieldsetUpdateComponent, FiledsetUpdateResolver } from "./update";

const fieldsetRoutes: Routes = [
    { path: "", component: FieldsetListComponent, resolve: { fieldsets: FieldsetListResolver } },
    { path: "update/:id", component: FieldsetUpdateComponent, canDeactivate: [ConfirmGuard], resolve: { resources: FiledsetUpdateResolver } },
];

@NgModule({
    imports: [RouterModule.forChild(fieldsetRoutes)],
    exports: [RouterModule],
})
export class FieldsetRoutingModule {}
