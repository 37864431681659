<div style="overflow: auto;" class="table-responsive">
    <table  class="table table-striped">
        <thead>
            <tr>
                <th class="text-center">{{ 'general.table.editableField' | translate }}</th>
                <th>{{ 'general.table.twigVariable' | translate }}</th>
                <th>{{ 'general.table.variableName' | translate }}</th>
                <th *ngIf="forElement">{{ 'general.table.variableProduct' | translate }}</th>
                <th  *ngIf="forElement"></th>
                <th class="text-center" *ngIf="!isFieldset">{{ 'general.table.input' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let field of fieldsArray.controls" [formGroup]="field">
                <td class="text-center">  
                    <label class="switch switch-pill switch-primary row">
                        <input  class="switch-input"  type="checkbox" formControlName="editable"/>
                        <span class="switch-slider"></span>
                    </label>
                </td>
                <td>
                    {{ field.get('title').value }}
                </td>
                <td>
                    <input type="text" class="form-control" formControlName="name" />
                </td>
                <td  *ngIf="forElement">
                    <div *ngIf="!field.get('fieldsets') && forElement">
                        <!-- Warning : 'appendTo="self"' will cause console error (document.getElementById(...) is null) but it let to open cascade select and scroll into it -->
                        <p-cascadeSelect (onChange)="clear($event,field)" appendTo="body" showClear="true" [disabled]="field.get('list').value.length <= 0"  formControlName="value" [options]="field.get('list').value" placeholder="{{(field.get('list').value.length <= 0 ? 'general.noItem' : 'general.selectItem') | translate}}" [optionGroupChildren]="['fields']" optionLabel="name" optionGroupLabel="name" dataKey="id">
                        </p-cascadeSelect>
                      
                    </div>
                    <div   *ngIf="field.get('fieldsets') && field.get('list').value.length > 0 && forElement" >
                        <!-- Warning :'appendTo="self"' will cause console error (document.getElementById(...) is null) but it let to open cascade select and scroll into it -->
                        <p-cascadeSelect  (onChange)="clear($event,field)" appendTo="body" [showClearButton]="true" disabled="" formControlName="value" [options]="field.get('list').value" placeholder="Select Item" [optionGroupChildren]="['fields']" optionLabel="name" optionGroupLabel="name" dataKey="id"></p-cascadeSelect>
                    </div>
                </td>
                <td  *ngIf="forElement">
                    <button (click)="clearButton(field)"  type="button" class="close" aria-label="Close">
                        <span style="color: red;" aria-hidden="true">&times;</span>
                      </button>
                </td>
                <td *ngIf="!isFieldset">
                        <app-template-form [isFieldset]="true" *ngIf="field.get('fieldsets')" fieldset="true" [fieldsArray]="field.get('fieldsets')" [forElement]="forElement"></app-template-form>
                </td>
            </tr>
        </tbody>
    </table>

    <div   *ngIf="fieldsArray.controls.length == 0"  class="text-center alert alert-warning" role="alert">
        {{ 'projects.flatplan.variableAssoc' | translate }}
    </div>
