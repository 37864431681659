import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { FieldsetUpdateComponent } from "./fieldset-update.component";
import { FieldsetUpdateGuard } from "./fieldset-update.guard";
import { FiledsetUpdateResolver } from "./fieldset-update.resolver";

@NgModule({
    imports: [SharedModule, SharedComponentsModule],
    declarations: [FieldsetUpdateComponent],
    exports: [],
    providers: [FieldsetUpdateGuard, FiledsetUpdateResolver],
})
export class FieldsetUpdateModule {}
