import { Component, OnDestroy } from "@angular/core";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { MAESTRO_ROUTES } from "app/shared/routes";

@Component({
    selector: "app-dashboard-maestro",
    templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnDestroy {
    constructor(private _layout: LayoutService) {
        this._layout.breadcrumb.setPath({ routerLink: `/${MAESTRO_ROUTES.dashboard.base}`, name: "breadcrumb.dashboard" }, 0);
    }

    ngOnDestroy() {
        this._layout.breadcrumb.setPath(null, 0);
    }
}
