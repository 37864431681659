<div class="tableComponent row">
    <div class="col-lg-12">
        <div class="card">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="card-body">
                    <fieldset class="p-2" style="border: 1px solid lightgrey;" id="twigFs">
                        <legend class="text-center text-primary">{{ "templates.config.twig.title" | translate }}
                        </legend>
                        <h5 *ngIf="!updateMode" class="text-primary mt-3">{{ "templates.config.twig.create" | translate
                            }}</h5>
                        <h5 *ngIf="updateMode" class="text-primary mt-3">{{ "templates.config.twig.update" | translate
                            }}</h5>
                        <div class="form-row">
                            <div class="col-sm-6 form-group">
                                <label for="twigName">{{ "templates.config.twig.name" | translate }}</label>
                                <input formControlName="twigName" type="text" class="form-control" id="twigName">
                            </div>
                            <div class="col-sm-6 form-group">
                                <label for="exportType">{{ "templates.config.twig.exportType" | translate }}</label>
                                <select formControlName="exportType" class="form-control"
                                    id="exportType">
                                    <option></option>
                                    <option *ngFor="let exportType of exportTypes" [value]="exportType.id">{{
                                        exportType.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="twigName">{{ "templates.config.twig.content" | translate }}</label>
                            <ngs-code-editor style="height: 25rem;" theme="vs-dark" [codeModel]="codeModel"
                                [options]="options"></ngs-code-editor>
                                
                                <span *ngIf="!twigValide()" style="color: red;">
                                    {{ 'response.error.twig.create' | translate }}
                                </span>
                        </div>
                        
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-8">
                                    <button class="btn btn-danger" type="button" (click)="onBack()">
                                        <fa-icon [icon]="faTimesCircle"></fa-icon> {{ 'general.close' | translate | titlecase }}
                                    </button>
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group">
                                        <button type="button" [disabled]="!form.valid || !twigValide()" class="btn btn-block btn-primary" (click)="onSubmit()">
                                            <span class="d-none d-md-inline-block ml-2"  *ngIf="updateMode">
                                                {{ "templates.config.twig.updateButton" | translate }}
                                            </span>
                                            <span class="d-none d-md-inline-block ml-2" *ngIf="!updateMode">
                                                {{ "templates.config.twig.create" | translate }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </form>
        </div>
    </div>
</div>
