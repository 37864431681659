<ul #list id="sidebarAccordion" class="nav ">
    <li [attr.id]="item.id" *ngFor="let item of notSortableArray; let i = index" class="nav-item nav-dropdown" [ngClass]="{ open: rla?.isActive }">
        <span>
            <a *ngIf="options.absoluteRoute" #rla="routerLinkActive" class="nav-link" routerLinkActive="active" [routerLink]="[options.absoluteRoute + '/' + item.id]" (click)="onClickedItem(item)"
               skipLocationChange>
                <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
                {{ item.name }}
            </a>
            <span *ngIf="!options.absoluteRoute" class="nav-link pointer" (click)="onClickedItem(item)">
                <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon> <span *ngIf="item.name == 'template_data'">{{ "template_data"|translate }}</span><span
                *ngIf="item.name != 'template_data'">{{ item.name }}</span>
            </span>
        </span>
    </li>
    <div class="sortable_items">
        <li [attr.id]="item.id" *ngFor="let item of sortableArray; let i = index" class="nav-item nav-dropdown" [ngClass]="{ open: rla?.isActive }">
            <span>
                <a *ngIf="options.absoluteRoute" #rla="routerLinkActive" class="nav-link" routerLinkActive="active" [routerLink]="[options.absoluteRoute + '/' + item.id]" (click)="onClickedItem(item)"
                   skipLocationChange>
                    <span [ngbTooltip]="'tooltip.sort' | translate" container="body" *ngIf="options.aclOptions.edit || options.aclOptions.movable" class="btn btn-primary btn-sm handle active"
                          style="margin-right: 10px;">
                        <fa-icon [icon]="faSort"></fa-icon>
                    </span>
                    <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
                    {{ item.name }}
                </a>
                <span *ngIf="!options.absoluteRoute" class="nav-link pointer" (click)="onClickedItem(item)">
                    <span *ngIf="options.aclOptions.edit || options.aclOptions.movable" class="btn btn-primary btn-sm handle active" style="margin-right: 10px;">
                        <fa-icon [icon]="faSort"> </fa-icon>
                    </span>
                    <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon> <span *ngIf="item.name == 'template_data'">{{ "template_data"|translate }}</span><span
                    *ngIf="item.name != 'template_data'">{{ item.name }}</span>
                </span>
            </span>
        </li>
    </div>
</ul>

<app-sidebar-routing-form [allOptions]="options.data" *ngIf="options.editable"
                          (validSubmit)="options.validFormSubmit($event)"
                          (delete)="options.delete($event)"
                          [currentMode]="options.data.length === 0 ?'create' :null"
                          [aclOptions]="options.aclOptions">
</app-sidebar-routing-form>
