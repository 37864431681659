import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ElementNoFieldDataComponent } from "./element-no-field-data.component";

@NgModule({
    imports: [SharedModule],
    declarations: [ElementNoFieldDataComponent],
    exports: [ElementNoFieldDataComponent],
})
export class ElementNoFieldDataComponentModule { }
