import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { FlatplanModule } from "./components/flatplan/flatplan.module";
import { PagesListModule } from "./components/pages-list/pages-list.module";
import { ProductsListComponent } from "./components/products-list/products-list.component";
import { FlatplanViewComponent } from "./flatplan-view.component";
import { FlatplanViewResolver } from "./flatplan-view.resolver";
@NgModule({
    imports: [FlatplanModule, PagesListModule, SharedModule, SharedComponentsModule],
    declarations: [FlatplanViewComponent, ProductsListComponent],
    providers: [FlatplanViewResolver],
})
export class FlatplanViewModule {}
