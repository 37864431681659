import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from "@angular/core";
import { Subject } from "rxjs";
import { ToastComponent } from "../../../../shared/components/toast/toast.component";

@Injectable()
export class ToastService {
    toastComponent: ComponentRef<ToastComponent>;
    private isDisplayed: boolean;
    readonly onDisplayChange: Subject<boolean> = new Subject<boolean>();

    private _messages: { message: string; type: string }[] = [];

    private set display(isDisplayed: boolean) {
        if (this.isDisplayed !== isDisplayed) {
            this.isDisplayed = isDisplayed;
            this.isDisplayed ? this.addToaster() : this.removeToaster();
            this.onDisplayChange.next(this.isDisplayed);
        }
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private appRef: ApplicationRef) {}

    /**
     * Show a new toast message
     * @param message
     */
    show(message: { message: string; type: string }): void {
        this._messages.push(message);
        setTimeout(() => this.remove(message.message), 4000);
        this.display = true;
    }

    /**
     * Remove a message
     * @param message
     */
    private remove(message: string): void {
        let index = -1;
        for (let i = 0; i < this._messages.length; i++) {
            if (this._messages[i].message === message) {
                index = i;
            }
        }
        if (index > -1) {
            this._messages.splice(index, 1);
        }
    }

    /**
     * Add toaster
     */
    private addToaster() {
        this.toastComponent = this.componentFactoryResolver.resolveComponentFactory(ToastComponent).create(this.injector);
        this.appRef.attachView(this.toastComponent.hostView);
        this.toastComponent.instance.messages = this._messages;
        const domElem = (this.toastComponent.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    /**
     * Remove toaster
     */
    private removeToaster() {
        this.appRef.detachView(this.toastComponent.hostView);
        this.toastComponent.destroy();
    }
}
