import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { ActionButtonsDef, ActionType } from "app/shared/components/action-buttons";
import { TableDef } from "app/shared/components/table";
import { ACL } from "app/shared/models/acl";

@Component({
    selector: "app-home-table",
    templateUrl: "./home-table.component.html",
})
export class HomeTableComponent implements OnInit {
    @Input() data: TableDef;
    @Input() tableTitle: TableDef;
    @Input() type: string;

    @Output() action = new EventEmitter();

    readonly actions: ActionButtonsDef = [];

    constructor(private _userService: UserService) {}

    ngOnInit(): void {
        const acl: ACL = this._userService.getUserAclFromToken();
        if (acl.SYSPAD_PIM_ELEMENT_READ) {
            this.actions.push({ type: ActionType.View, right: "SYSPAD_PIM_ELEMENT_READ" });
        }
        if (acl.SYSPAD_PIM_ELEMENT_UPDATE) {
            this.actions.push({ type: ActionType.FormUpdate, right: "SYSPAD_PIM_ELEMENT_UPDATE" });
        }
    }

    onActionClick(data: { action: ActionType; rowId: number }) {
        this.action.emit(data);
    }
}
