import { NgModule } from "@angular/core";

import { ElementRoutingModule } from "./element.routing";

import { ElementListModule } from "./list/element-list.module";
import { ElementViewModule } from "./view/element-view.module";
import { ElementUpdateModule } from "./update/element-update.module";

@NgModule({
    imports: [ElementRoutingModule, ElementListModule, ElementViewModule, ElementUpdateModule],
})
export class ElementModule {}
