import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments";

@Injectable()
export class PasswordService {
    constructor(private _http: HttpClient) {}

    /**
     * Call the api to send an email for resetting the password
     * @param email
     * @returns
     */
    reset(email: string) {
        const formData = new FormData();
        formData.append("email", email);
        return this._http.post(`${environment.adminAuthUrl}/password/reset.json`, formData);
    }

    /**
     * Update the password
     * @param password
     * @param token
     * @returns
     */
    change(password: string, token: string) {
        const formData = new FormData();
        formData.append("password", password);
        return this._http.post(`${environment.adminAuthUrl}/password/${token}/change.json`, formData);
    }
}
