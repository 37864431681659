import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { Sortable } from "sortablejs";

@Directive({
    selector: "[appSortable]",
})
export class SortableDirective implements OnInit {
    @Input() appSortable: any;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        new Sortable(this.el.nativeElement, this.appSortable)
    }

   
}
