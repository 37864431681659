import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { GroupFormComponent } from "./form/group-form.component";
import { GroupFormGuard } from "./form/group-form.guard";
import { GroupRoutingModule } from "./group.routing";
import { GroupListGuard } from "./list/group-list.guard";
import { GroupListModule } from "./list/group-list.module";

@NgModule({
    declarations: [GroupFormComponent],
    imports: [GroupRoutingModule, GroupListModule, SharedModule],
    providers: [GroupListGuard, GroupFormGuard]
})
export class GroupModule {}
