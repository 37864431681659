import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { DatePickerModule } from "../form/date-picker/date-picker.module";
import { EditBaseFormComponent } from "./edit-base-form/edit-base-form.component";
import { EditFormComponent } from "./edit-form.component";
import { EditFormResolver } from "./edit-form.resolver";

@NgModule({
    imports: [DatePickerModule, SharedModule],
    declarations: [EditFormComponent, EditBaseFormComponent],
    providers: [EditFormResolver],
    exports: [EditFormComponent, EditBaseFormComponent],
})
export class EditFormModule {}
