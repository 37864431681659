import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class DataModelService {
    constructor(private _http: HttpClient) {}

    /**
     * Get all models
     * @returns
     */
    getDataModels(): Observable<any> {
        return this._http.get(`${environment.damUrl}/models.json`);
    }

    /**
     * Get data model by id
     * @param id
     */
    getById(id: number): Observable<any> {
        return this._http.get(`${environment.damUrl}/model/${id}.json`);
    }

    /**
     * Create a new data model
     *
     * @param name
     */
    createDataModel(name: string): Observable<any> {
        const formData = new FormData();
        formData.append("name", name);
        return this._http.post(`${environment.damUrl}/model.json`, formData);
    }

    /**
     * Update a data model
     *
     * @param id
     * @param body
     */
    updateDataModel(id: number, body: any): Observable<any> {
        return this._http.put(`${environment.damUrl}/model/${id}.json`, body);
    }

    /**
     * Update the name of a data model
     * @param id
     * @param name
     */
    changeName(id: number, name: string): Observable<any> {
        return this._http.put(`${environment.damUrl}/model/${id}/name.json`, { name });
    }

    /**
     * Delete a data model
     * @param id
     */
    deleteDataModel(id: number): Observable<any> {
        return this._http.delete(`${environment.damUrl}/model/${id}.json`);
    }

    /**
     * Set the model for a media
     *
     * @param id
     * @param dataModelId
     */
    setModel(id: number, dataModelId: number): Observable<any> {
        return this._http.put(`${environment.damUrl}/media/${id}/model.json`, { idModel: dataModelId });
    }

    /**
     * Set types for a datamodel
     *
     * @param id
     * @param types
     * @returns
     */
    setTypes(id: number, types: number[]): Observable<any> {
        return this._http.put(`${environment.damUrl}/model/${id}/types.json`, { types });
    }
}
