<div class="row">
    <div class="col-12">
        <h3 *ngIf="acl !== undefined">{{ 'admin.roles.manage' | translate }}</h3>
    </div>
</div>

<div class="row justify-content-md-center">
    <div class="col-lg-12">
        <ul class="nav nav-tabs" role="tablist">
            <li *ngFor="let module of authorizations; let i = index" class="nav-item">
                <a [ngClass]="{ 'nav-link': true, active: i === 0 }" data-toggle="tab" href="#module{{ i }}" role="tab">{{ module.name + '.title' | lowercase | translate }}</a>
            </li>
        </ul>
        <div class="tab-content">
            <div class="table-responsive" *ngFor="let module of authorizations; let i = index" [ngClass]="{ 'tab-pane': true, active: i === 0 }" id="module{{ i }}" role="tabpanel">
                <table class="table table-striped acl">
                    <thead>
                        <th>{{ 'admin.roles.page' | translate }}</th>
                        <th *ngFor="let action of module.actions" class="text-center">{{ 'actions.' + action | lowercase | translate }}</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of module.elements">
                            <td *ngIf="'PIM' == module.name && 'MEDIA' == element.name" translate>{{ 'admin.roles.' + element.name + 'link' | lowercase | translate }}</td>
                            <td *ngIf="'PIM' != module.name || 'MEDIA' != element.name" translate>{{ 'admin.roles.' + element.name | lowercase | translate }}</td>
                            <td *ngFor="let action of element.actions" class="text-center">
                                <label *ngIf="action.value.action_id !== undefined" class="switch switch-label switch-sm switch-outline-primary">
                                    <input class="switch-input" type="checkbox" [checked]="action.value.access === false ? '' : 'checked'" (change)="changeAuthorization(roleId, action.value.authorization_id, action.value.access, action.value.action_id)"/>
                                    <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
