import { Injectable } from "@angular/core";
import { MaestroFieldsets } from "app/shared/models";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments";
import { TemplateConfigurations } from "../../../../modules/projects/features/project/configuration/steps/template/template-configuration";
import { ProjectService } from "./project.service";

@Injectable()
export class TemplateConfigurationService extends ProjectService {
    /**
     * Get configurations for step 5 (template)
     * @param projectId
     * @returns
     */
    getConfigurations(projectId: number): Observable<TemplateConfigurations> {
        return this._http.get(`${environment.projectsUrl}/project/${projectId}/templates.json`).pipe(catchError((_) => of(null)));
    }

    /**
     * Save configurations for step 5 (template)
     *
     * @param projectId
     * @param body
     * @returns
     */
    saveConfigurations(projectId: number, body: TemplateConfigurations) {
        return this._http.post(`${environment.projectsUrl}/project/${projectId}/templates.json`, body).pipe(catchError((_) => of(null)));
    }

    /**
     * Group fields by data model to allow the user to select fields
     *
     * @param projectId
     * @param elementTypeIds
     * @returns
     */
    getFieldsByElementTypes(projectId: number, typeIds: number[]): Observable<{ [typeId: string]: MaestroFieldsets }> {

        return forkJoin([
            this.getFieldsElementTypes(projectId, JSON.stringify(typeIds)),
        ]).pipe(
            map((resultArray) => {
                const mapping = {};
                resultArray[0].forEach((field) => {
                    if(!mapping[field["elementTypeId"]]){
                        mapping[field["elementTypeId"]] = [];
                    }
                    mapping[field["elementTypeId"]][field["id"]] = field;
                });
                return mapping;
            })
        );
    }
}
