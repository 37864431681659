import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "app/core/services/admin/common.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { MaestroElementType, MaestroUsers } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { LazyLoadDataModel } from "app/shared/models/lazy-load-data";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { LazyLoadEvent,FilterMetadata, TableState } from "primeng-lts/api";
import { Subscription } from "rxjs";
import { TableService } from "app/core/services/global/table/table.service";
import { MaestroCommons } from "app/shared/models/maestro-entity/common";


@Component({
    selector: "app-user-list",
    templateUrl: "./user-list.component.html",
})
export class UserListComponent implements TableView, OnInit, OnDestroy {

    tableRows: any[] = [];
    tableColumns: any[] = [];
    totalRecords: number = 0;
    recordsFiltered: number = 0;
    currentDatatableFilters: LazyLoadEvent = {}; // Use to reload data when filter with tag/element type & action like duplicate/delete
    globalFilterFields: string[] = []; // Define which columns can be use to search data
    selectColumns: boolean = true; // Display a multiselect to show/hide columns
    _selectedColumns: any[] = []; // Define which columns are selected
    exportFilename: string = "maestro_pim_export_element";
    subDataType: string = "medias";
    affLink: boolean;
    searchOnArchived: boolean = false; // Use in project to display archived projects

    private _userGroups: MaestroCommons;

    allFilters = {
        filterGroups: {
            data: [],
            cascadeSelect: false,
            field: "",
        },
        filterProfil: {
            data: [],
            cascadeSelect: false,
            field: "",
        },
    };
    preFilter: any;
    currentCustomFilters: any = {}; // Use to reload with the right filters
    shortPaginatorIsFirstPage: boolean = true;
    shortPaginatorIsLastPage: boolean = false;
    nodeSelected: boolean = true; // Used to know in prefilter case if selected or not

    strictMode: boolean = false; // strict value given by datatable
    searchOnFieldValue: boolean = false; // search on element value and list value given by datatable
    private subscription: Subscription;
    table: [string, string][];
    columns: string[];

    private acl: ACL;
    public actions: ActionButtonsDef = [];
    private static envParamControl: string = "enable_user_control";
     groups: any[];

    constructor(
        private _layout: LayoutService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _tableDataPipe: TableDataPipe,
        private _userService: UserService,
        private _commonService: CommonService,
        private _tableService: TableService,
    ) {}

    ngOnInit(): void {
        const users: MaestroUsers = this.deleteInternalCode([...this._route.snapshot.data.users.data]);
        this._initList(users);
        this.acl = this._userService.getUserAclFromToken();
        this.currentDatatableFilters.first = 0;

        this._commonService.checkParamControl(UserListComponent.envParamControl).subscribe((data) => {
            const enableUserControl = data.data;

            /**
             * Prepare the create button
             */
            if (!enableUserControl && this.acl.SYSPAD_ADMIN_USER_CREATE) {
                this._layout.actionButton.enable = true;
                this._layout.actionButton.title = "admin.create.user";
                this.subscription = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
            }

            if (this.acl.SYSPAD_ADMIN_USER_UPDATE) {
                this.actions.push({ type: ActionType.Update, right: "SYSPAD_ADMIN_USER_UPDATE" });
            }

            if (!enableUserControl && this.acl.SYSPAD_ADMIN_USER_DELETE) {
                this.actions.push({ type: ActionType.Delete, right: "SYSPAD_ADMIN_USER_DELETE" });
            }
        });

        this._route.data.subscribe((d) => {
            this._userGroups = d.users.data;
        });

        this._initFilter();

    }

    ngOnDestroy() {
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._layout.breadcrumb.setPath(null, 1);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    cleanPrefilters(event: any) {
        if (event.clear && this.preFilter) {
            this.preFilter.data = [];
        }
    }
    shortPaginatorNext(): void {
        this.currentDatatableFilters.first = this.currentDatatableFilters.first + this.currentDatatableFilters.rows;
        this.loadList(this.currentDatatableFilters);
    }

    shortPaginatorReset(): void {
        this.currentDatatableFilters.first = 0;
        this.loadList(this.currentDatatableFilters);
    }

    changeAdvancedSearch(event: any): void {
        if (this.hasOwnProperty(event.advancedSearchParam)) {
            this[event.advancedSearchParam] = event.advancedSearchValue;
        }
    }

    setGlobalFilterFields(): void {
        this.tableColumns.forEach((col) => {
            if (col.hasOwnProperty("field")) {
                if (col.hasOwnProperty("globalFilter") && col.globalFilter) {
                    this.globalFilterFields.push(col.field);
                }

                if (this.selectColumns && col.field) {
                    col.header = this._tableService.translateColumns(col); // Add columns header to display in columns selector
                }

                if (col.hasOwnProperty("filterList") && null !== col.filterList && col.hasOwnProperty("filterField") && null !== col.filterField && this.allFilters.hasOwnProperty(col.filterList)) {
                    this.allFilters[col.filterList].field = col.filterField;
                }
            }
        });
    }


    private _initRows(dataRows: any) {
        const groupIds = this._userGroups.map((g) => g.id);
        const userId = this._userService.getUserIdFromToken();

        let rows = [...dataRows];
        rows.forEach((row) => {
            if (row && row.idOwnerGroup && row.groups) {
                if (groupIds.length && !groupIds.includes(row.idOwnerGroup) && row.idUser !== userId && row.groups.length) {
                    row.groupRight = {
                        read: true,
                        update: false,
                        delete: false,
                    };
                }
            }
        });

        return rows;
    }

    setCustomFilters(eventFilters: FilterMetadata): void {
        let customFilters = {};

        if (Object.keys(eventFilters).length) {
            Object.entries(eventFilters).map(([key, value]) => {
                if (this.allFilters.hasOwnProperty(key) && this.allFilters[key].field) {
                    let ids = [];
                    let subVal = value[0].value;

                    if (null !== subVal) {
                        ids = subVal.map((d) => d.id);
                    }

                    customFilters[this.allFilters[key].field] = ids;
                }
            });
        }

        this.currentCustomFilters = customFilters;
    }

    /**
     * Load data displayed in datatable
     * @param event
     */


    loadList(event: LazyLoadEvent): void {
        if (undefined !== event.filters) {
             this.setCustomFilters(event.filters);
        }

        const requestFilters: LazyLoadDataModel = {
            lazyLoadFilters: event,
            customFilters: this.currentCustomFilters,
            strictMode: this.strictMode,
            searchOnFieldValue: this.searchOnFieldValue,
            searchOnArchived: this.searchOnArchived,
        };


        this._userService.getDatatableElements(JSON.stringify(requestFilters)).subscribe((resp) => {

            const data = resp.data;
            const rows = this._initRows(data.rows);
            this.tableRows = Object.values(rows);

            this.tableColumns = Object.values(data.columns);

            this.totalRecords = data.recordsTotal;

            this.recordsFiltered = data.recordsFiltered;

            this.currentDatatableFilters = data.datatableFilters;

            this.setGlobalFilterFields();

            this._selectedColumns = this.tableColumns;

            this.shortPaginatorIsFirstPage = this.currentDatatableFilters.first === 0;
            this.shortPaginatorIsLastPage = this.currentDatatableFilters.first === this.recordsFiltered - this.tableRows.length;
        });
    }

    shortPaginatorPrev(): void {
        this.currentDatatableFilters.first = this.currentDatatableFilters.first - this.currentDatatableFilters.rows;
        this.loadList(this.currentDatatableFilters);
    }
    /**
     * Navigate to create form
     */
    onCreate() {
        this._router.navigate([MAESTRO_ROUTES.actions.create], { relativeTo: this._route });
    }

    /**
     * Handle click on action buttons
     *
     * @param event
     */
    onActionClick(event: ActionEvent): void {
        const id = event.rowId;
        switch (event.action) {
            /**
             * Navigate to update form
             */
            case ActionType.Update:
                this._router.navigate([id, MAESTRO_ROUTES.actions.update], { relativeTo: this._route });
                break;

            case ActionType.Delete:
                this._delete(id);
                break;

            default:
                break;
        }
    }

    /**
     * Init the list using the tableDataPipe
     * @param users
     */
    private _initList(users: MaestroUsers) {
        for (let index = 0; index < users.length; index++) {
            let mailAction = [];
            if(users[index]["sendMail"].length > 0){
                users[index]["sendMail"].forEach((action) => {
                    if(action['name']){
                        mailAction.push(action['name']);
                    }
                });
            }
            users[index]["sendMail"] = mailAction.length > 0 ? mailAction.toString() : '--';
        }
        const formatedData = this._tableDataPipe.transform(users);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    /**
     * Delete an user
     *
     * @param id
     */
    private _delete(id: number) {
        this._userService.deleteById(id).subscribe((_) => this._userService.getAll().subscribe((users) => this._initList(users["data"])));
    }

    private deleteInternalCode(users: any) {
        users.forEach((user) => {
            if (user.hasOwnProperty("internalCode")) {
                delete user.internalCode;
            }
        });

        return users;
    }

    _initFilter() {
         this.allFilters.filterGroups.data = this._route.snapshot.data.filters.groups.data;
        this.allFilters.filterProfil.data = this._route.snapshot.data.filters.profiles.data;
    }

}
