import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { TableModule } from "primeng-lts/table";
import { TooltipModule } from "primeng-lts/tooltip";
import { PageThumbnailMenuComponent } from "./menu/page-thumbnail-menu.component";
import { PagesListComponent } from "./pages-list.component";

@NgModule({
    imports: [SharedModule, TableModule, TooltipModule],
    declarations: [PagesListComponent, PageThumbnailMenuComponent],
    exports: [PagesListComponent],
})
export class PagesListModule {}
