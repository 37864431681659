import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { VariableProjectService } from "app/core/services/project/project/variable.service";
import { IMaestroEntity } from "app/shared/models";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { TemplateService } from "../../../../../../core/services/project/template/template.service";
import { TemplateCreateModule } from "./template-create.module";

@Injectable({
    providedIn: TemplateCreateModule,
})
export class TemplateCreateGuard implements CanActivate, Resolve<IMaestroEntity[]> {
    constructor(private _service: TemplateService, private _variableProjectService: VariableProjectService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<IMaestroEntity[]> {
        const templateId = route.params.id;
        if (templateId) {
            return forkJoin([
                this._service.getById(templateId).pipe(map((r) => r.data)), 
                this._service.getExportTypes().pipe(map((r) => r.data)),
                this._variableProjectService.getAllSort().pipe(map((r) => r.data))
            ]);
        }
        return forkJoin([
            this._service.getExportTypes().pipe(map((r) => r.data)),
            this._variableProjectService.getAllSort().pipe(map((r) => r.data))
        ]);
    }
}
