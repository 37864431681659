import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, UrlTree } from "@angular/router";
import { MaestroExport } from "app/shared/models";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Observable } from "rxjs";
import { ProjectExportService } from "../../../../../core/services/project/export/export.service";

@Injectable()
export class ProjectExportGuard implements Resolve<MaestroExport>, CanActivate {
    constructor(private _service: ProjectExportService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (route.firstChild) {
            return true;
        } else {
            return this.router.parseUrl(`/${MAESTRO_ROUTES.projects.base}/${route.parent.params.id}`);
        }
    }

    resolve(route: ActivatedRouteSnapshot): Observable<MaestroExport> {
        return this._service.getById(route.params.id);
    }
}
