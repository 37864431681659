<div class="row">
    <div class="col-md-8 mx-auto">
        <div class="card-group">
            <div class="card p-4">
                <div class="card-header">
                    <h1 class="display-3 text-center">404</h1>
                </div>
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <div class="clearfix">
                                <h4 class="pt-3">Oops! You're lost.</h4>
                                <p class="text-muted">The page you are looking for was not found.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 text-right">
                        <a *ngIf="activatedModules.pim" class="btn btn-link px-0" [routerLink]="['/' + routes.pim.base]">PIM </a>
                    </div>
                    <div class="col-4 text-center">
                        <a *ngIf="activatedModules.dam" class="btn btn-link px-0" [routerLink]="['/' + routes.dam.base]">DAM</a>
                    </div>
                    <div class="col-4 text-left">
                        <a *ngIf="activatedModules.project" class="btn btn-link px-0" [routerLink]="['/' + routes.projects.base]">Project</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
