<p-dialog header="{{ 'general.configureMedias' | translate }}" [(visible)]="display" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [maximizable]="true" [style]="{width: '50vw'}">
    <p-tabView>
        <p-tabPanel header="{{ 'dataview.reorder' | translate }}">
            <button type="button" class="btn btn-primary col mb-3" (click)="saveMedias()">{{ 'general.save' | translate }}</button>

            <p-pickList
                [source]="dataSource" 
                [target]="dataTarget" 
                sourceHeader="{{ 'general.configuration.available' | translate }}" 
                targetHeader="{{ 'general.configuration.selected' | translate }}" 
                dragdrop="true" 
                [responsive]="true" 
                [sourceStyle]="{'height':'500px'}" 
                [targetStyle]="{'height':'500px'}"
                (onMoveToTarget)="reorderTarget()"
                (onMoveToSource)="reorderTarget()"
                (onMoveAllToTarget)="reorderTarget()"
                (onTargetReorder)="reorderTarget()" 
                >
                <ng-template let-media pTemplate="item">
                    <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 250px;">
                        <div *ngIf="media.hasOwnProperty('extension') && media.extension" class="px-2 py-1 mx-4 my-1 position-absolute right-0 rounded card-thumb-format">
                            {{ media.extension | uppercase }} - <span [ngClass]="_thumbnailService.getIconClass(media.extension)"></span>
                        </div>
                        <img *ngIf="moduleUrl && media.id" [src]="media.thumbPath ? moduleUrl+media.thumbPath : _thumbnailService.getThumb({ binImage: media.thumb })" [id]="'img-' + media.id" alt="{{ media.name }}" width="96" />
                        <img *ngIf="moduleUrl && !media.id" [src]="media.metadataLink ? media.metadataLink : media.thumbPath ? media.thumbPath : media.link" [id]="'img-' + media.id" alt="{{ media.name }}" width="96" style="max-height: 124px; object-fit: contain" (error)="_thumbnailService.setDefaultImage($event)"/>
                        <span class="ml-1 card-title">{{ media.metadataName ? media.metadataName : media.name ? media.name : media.link }}</span>
                    </div>
                </ng-template>
            </p-pickList>
        </p-tabPanel>
        <!-- @TODO: Add tabPanel for adding links (disabled if source or target length and not multiple) -->
        <!-- @TODO: Add tabPanel for import media (disabled if source or target length and not multiple) -->
        <!-- @TODO: Differenciate media type (filter only img ? Or split pick-list ?) // filter directly from pim ? -->
    </p-tabView>
</p-dialog>

<button type="button" class="btn btn-primary" (click)="loadDataOnInit ? showDialog() : getData()" icon="pi pi-info-circle">{{ 'general.configureMedias' | translate }}</button>