<ul #list class="nav">
    <li
        *ngFor="let item of options.data; let i = index"
        class="nav-item"
        [ngClass]="{
            'nav-dropdown': item.sections && item.sections.length,
            open: rla?.isActive && item.sections && item.sections.length
        }"
    >
        <span>
            <a
                #rla="routerLinkActive"
                class="nav-link"
                [ngClass]="{ 'nav-dropdown-toggle': item.sections && item.sections.length }"
                routerLinkActive="active"
                [routerLink]="[options.absoluteRoute + '/' + item.id]"
            >
                <span *ngIf="options.editable" class="btn btn-primary btn-sm handle active" style="margin-right: 10px;">
                    <fa-icon [icon]="faSort"> </fa-icon>
                </span>
                <fa-icon [icon]="item.icon"></fa-icon>&nbsp;<span class="d-inline-block">{{ 'sidebar.' + item.name | translate }}</span>
            </a>
        </span>

        <div
            *ngIf="item.sections && item.sections.length"
            id="{{ 'itemMenu' + i }}"
            class="nav-dropdown"
            [ngClass]="rla.isActive ? 'open' : 'collapse'"
            data-parent="#sidebarAccordion"
        >
            <a
                class="nav-dropdown-item nav-link"
                *ngFor="let section of item.sections; let y = index"
                [ngClass]="{ active: currentFragment && currentFragment.name === section.name }"
                [routerLink]="[options.absoluteRoute + '/' + section.id]"
                [fragment]="section"
            >
                <fa-icon [icon]="section.icon"></fa-icon>
                <span class="d-inline-block ml-1">
                    {{ 'sidebar.section.' + section.name | translate }}
                </span>
            </a>
        </div>
    </li>
</ul>
