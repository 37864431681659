<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <img style="filter: drop-shadow(0 0 10px rgba(255,255,255,1));" class="card-img-top" src="/assets/img/logo_maestro_login.png" alt="logo"/>
                </div>
            </div>
            <router-outlet></router-outlet>
        </div>
    </main>
</div>
