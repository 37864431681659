import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { PasswordRoutingModule } from "./password.routing";

@NgModule({
    declarations: [ForgotPasswordComponent, ChangePasswordComponent],
    imports: [PasswordRoutingModule, SharedModule],
})
export class PasswordModule {}
