import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { ConfirmPopupModule } from 'primeng-lts/confirmpopup';
import { TableModule } from 'primeng-lts/table';
import { ConfirmationService } from "primeng/api";
import { FormTableComponent } from './form-table.component';

@NgModule({
  declarations: [
    FormTableComponent
  ],
  exports: [
    FormTableComponent
  ],
  imports: [
    TableModule, TranslateModule, CommonModule, ReactiveFormsModule, FormsModule, PipesModule, ConfirmPopupModule
  ],
  providers: [ConfirmationService]

})
export class FormTableModule { }
