import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faEdit, faEye, faFileExcel, faFilePdf, faFileWord, faShareSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faClone, faHistory, faTools } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ActionButtonsDef, ActionType } from "./action";

@Component({
    selector: "app-action-buttons",
    templateUrl: "./action-buttons.component.html",
})
export class ActionButtonsComponent implements OnInit {
    @Output() action: EventEmitter<ActionType> = new EventEmitter();
    @Input() actions: ActionButtonsDef = [];
    @Input() entity: string;
    @Input() buttonOutlined = false;
    @Input() hideViewIfEditAllowed = false;
    @Input() groupRight: any;

    actionType = ActionType;

    readonly faEye = faEye;
    readonly faEdit = faEdit;
    readonly faTools = faTools;
    readonly faTrash = faTrashAlt;
    readonly faShareSquare = faShareSquare;
    readonly faFileWord = faFileWord;
    readonly faFilePdf = faFilePdf;
    readonly faFileExcel = faFileExcel;
    readonly faClone = faClone;
    readonly faHistory = faHistory;

    public canEdit = false;

    constructor(private _modalService: SwalModalService, private _translate: TranslateService, private _userService: UserService) { }

    ngOnInit(): void {
        const acl = this._userService.getUserAclFromToken();
        if (acl["SYSPAD_PIM_ELEMENT_UPDATE"]) {
            this.canEdit = true;
        }
    }

    actionTooltip(action: string): string {
        switch (action) {
            case 'see':
                return this._translate.instant('pim.actions.see');
                break;
            case 'duplicate':
                return this._translate.instant('pim.actions.duplicate');
                break;
            case 'edit':
                return this._translate.instant('pim.actions.edit');
                break;
            default:
                '';
        }
    }


    /**
     * Get the tooltip text
     *
     * @param action
     * @returns
     */
    getTooltipText(action: ActionType): string {
        switch (action) {
            case ActionType.Delete:
                return this._translate.instant("tooltip.delete");
            case ActionType.Export:
                return this._translate.instant("tooltip.export");
            case ActionType.View:
                return this._translate.instant("tooltip.view");
            case ActionType.FormUpdate:
            case ActionType.Update:
                return this._translate.instant("tooltip.update");
        }
    }

    /**
     * Handle button click
     * Emit value depending of the action
     *
     * @param action
     */
    onButtonClick(action: ActionType, event) {
        switch (action) {
            case ActionType.Delete:
                let content = false;
                if (window.location.href.includes("element_type")) {
                    content = true;
                }
                if (window.location.href.includes("datamodel")) {
                    this._modalService.deleteWithContent("modal.mediadam.deleteDm").then((result) => {
                        if (result.value) {
                            this.action.emit(action);
                        }
                    });
                } else {
                    this._modalService.delete(content).then((result) => {
                        if (result.value) {
                            this.action.emit(action);
                        }
                    });
                }
                break;
            default:
                this.action.emit(action);
                break;
        }
    }

    initAction(action) {
        action["groupRight"] = this.groupRight;
        return action;
    }
}
