<div class="row">
    <button [disabled]="disabledImport" class="ml-3 btn btn-primary" #target data-toggle="modal" [attr.data-target]="'#' + id">
        <fa-icon [icon]="faPlusSquare"></fa-icon>
        {{ 'breadcrumb.pim.media.add' | translate }}
    </button>
</div>

<div class="row">
    <p-panel class="col-11" header="{{ mediasLinkFiltered.length + ' media(s)' }}" [toggleable]="false" [collapsed]="true">
        <!-- ON NE PEUT PAS GERER ET AFFICHER LES MEDIAS COMME ON LE FAIT DANS UNE FICHE CAR CELA IMPLIQUE DE RECUPERER LA STRUCTURE DE DONNEES DAM ET NON PIM = PLUS DE REQUETES -->
        <!--<app-dataview *ngIf="mediasLinkFiltered.length" 
            titleNotTooltip="pim.medias" 
            [filtered]="true" 
            type="pim"
            [dataviewContent]="mediasLinkFiltered" 
            [buttons]="buttons"
            [lazy]="false"
            [events]="eventsSubject.asObservable()"
            (filterExpireMedia)="changeHideExpireMedia($event)"
            (unlinked)="unlinkMedia($event)"
            (reorderMedia)="reorderMediaPosition($event)">
        </app-dataview>-->
    </p-panel>
</div>

<div class="modal fade" [attr.id]="id" tabindex="-1" role="dialog" aria-labelledby="mediaModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="mediaModalLabel">
                    {{ 'modal.media' | translate }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <p-tabView [(activeIndex)]="tabIndex">
                    <p-tabPanel header="{{ 'modal.media' | translate }}">
                        <div *ngIf="filters.tags.length" class="tags mb-1">
                            <span class="mr-1">{{ 'filter.tag' | translate }} : </span>
        
                            <span *ngFor="let tag of filters.tags" class="badge badge-pill badge-primary mr-1">
                                <span class="mr-1">{{ tag.name }}</span>
                                <span class="pointer" (click)="manageFilter(tag, false)">x</span>
                            </span>
                        </div>
                        <div *ngIf="filters.type.length" class="mb-1">
                            <span class="mr-1">{{ 'filter.extension' | translate }} : </span>
        
                            <span *ngFor="let ext of filters.type" class="badge badge-pill badge-primary mr-1">
                                <span class="mr-1">{{ ext.id }}</span>
                                <span class="pointer" (click)="manageFilter(ext, false, 'type')">x</span>
                            </span>
                        </div>
                        <app-dataview [dataviewContent]="mediasModal" (lazyEvent)="loadList($event)" [sortOptions]="sortOptions"
                            [totalRecords]="recordsFiltered" [minified]="true" [selectable]="true" [poppedUp]="true"
                            [selectedMedias]="selectedMedias" [linkedMedias]="elementMedias"
                            (searchEvent)="loadListWithSearch($event)" (selectMedia)="selectMedia($event)"
                            (tagFiltered)="manageFilter($event)" (filterExpireMedia)="changeHideExpireMedia($event)"
                            (selectExtension)="manageFilter($event, true, 'type')"
                            [first]="currentDatatableFilters.first ? currentDatatableFilters.first : 0"
                            [singleSelection]="singleSelection" [linkAdded]="linkAdded">
                        </app-dataview>
                        <!--
                        <div class="card-footer">
                            <button class="btn btn-primary" (click)="addSelectedMedias()">{{ 'modal.link' | translate }}</button>
                        </div>
                        -->
                    </p-tabPanel>
                    <p-tabPanel header="{{ 'modal.mediaLink' | translate }}">
                        <div *ngIf="mediaTypes.length" class="card-body p-0">
                            <form [formGroup]="formArray">
                                <div class="table-responsive">
                                    <table class="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th style="min-width: 10rem; border: 0;" class="text-center" scope="col">{{
                                                    'general.link' | translate }}</th>
                                                <th style="min-width: 10rem; border: 0;" class="text-center" scope="col">{{
                                                    'general.type' | translate }}</th>
                                                <th style="min-width: 10rem; border: 0;" class="text-center" scope="col">{{
                                                    'general.loadMetadata' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let control of formArray.controls; let i = index">
                                                <td style="min-width: 10rem;">
                                                    <input class="form-control"
                                                        [ngClass]="control.controls.link.valid ? 'is-valid' : 'is-invalid'"
                                                        type="url" pattern="https://.*" placeholder="https://..."
                                                        [formControl]="control.controls.link">
                                                    <div *ngIf="!control.controls.link.pristine && control.controls.link.invalid"
                                                        class="invalid-feedback">
                                                        <span *ngIf="control.controls.link.hasError('required')">{{
                                                            "general.required" | translate }}</span>
                                                    </div>
                                                </td>
                                                <td style="min-width: 10rem;">
                                                    <select class="form-control"
                                                        [ngClass]="control.controls.type.valid ? 'is-valid' : 'is-invalid'"
                                                        [formControl]="control.controls.type">
                                                        <option *ngFor="let mediaType of mediaTypes" [value]="mediaType">{{
                                                            "pim.mediasHelper." + mediaType | translate}}</option>
                                                    </select>
                                                    <div *ngIf="control.controls.type.invalid && !control.controls.type.pristine"
                                                        class="invalid-feedback">
                                                        <span *ngIf="control.controls.type.hasError('required')">{{
                                                            "general.required" | translate }}</span>
                                                    </div>
                                                </td>
                                                <td style="min-width: 10rem;">
                                                    <label class="switch switch-success mb-0">
                                                        <input type="checkbox" class="switch-input"
                                                            [formControl]="control.controls.loadMetadata">
                                                        <span class="switch-slider"></span>
                                                    </label>
                                                </td>
                                                <td style="min-width: 10rem;">
                                                    <button [ngbTooltip]="'tooltip.delete'| translate" class="btn btn-danger"
                                                        (click)="deleteMediaLink(i)">
                                                        <fa-icon [icon]="faTrash"></fa-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="btn-group ml-auto">
                                    <button class="btn btn-primary" (click)="addMediaLink()"
                                        [disabled]="singleSelection && (elementMedias.length || (selectedMedias.length || formArray.value.length))">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                        <span class="d-none d-md-inline-block mt-1 ml-2">{{ 'general.addLink' | translate
                                            }}</span>
                                    </button>
                                </div>
                            </div>
                            <!--<button class="btn btn-primary" (click)="addSelectedMedias()">{{ 'modal.link' | translate }}</button>-->
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="{{ 'breadcrumb.dam.upload' | translate }}">
                        <div class="bg-light border border-dashed border-secondary py-3 text-center justify-content-center"
                            [ngxDragAndDrop]="options" *ngxInputFile="optionsInput">
                            <fa-icon class="mx-auto font-5xl text-primary" [icon]="faUpload"></fa-icon>
                            <div class="font-weight-bold text-center">{{ 'upload.file' | translate }}</div>
                        </div>
        
                        <div class="card table-responsive">
                            <h3 class="text-center mt-1">
                                <span *ngIf="uploader.queue.length === 1">{{ 'upload.queue' | translate }} - {{
                                    uploader.queue.length }} {{ 'upload.item' | translate }}</span>
                                <span *ngIf="uploader.queue.length > 1">{{ 'upload.queue' | translate }} - {{
                                    uploader.queue.length }} {{ 'upload.items' | translate }}</span>
                                <span *ngIf="uploader.queue.length === 0">{{ 'upload.queue' | translate }} </span>
                            </h3>
        
                            <table class="table table-striped border-bottom m-0 text-center">
                                <thead>
                                    <tr>
                                        <th>{{ 'upload.name' | translate }}</th>
                                        <th>{{ 'upload.size' | translate }}</th>
                                        <th>{{ 'upload.progress' | translate }}</th>
                                        <th>{{ 'general.action' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let itemFile of uploader.queue"
                                        [ngClass]="{ 'table-success': itemFile.isSuccess, 'table-danger': itemFile.isError }">
                                        <td>{{ itemFile.file.name }}</td>
                                        <td>{{ itemFile.file.size / 1024 / 1024 | number: '1.0-2' }} MB</td>
                                        <td>
                                            <div>
                                                <ngb-progressbar type="success" showValue="true" [striped]="true"
                                                    [animated]="true" [value]="itemFile.progress"></ngb-progressbar>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-danger btn-sm" (click)="itemFile.remove()"
                                                [disabled]="itemFile.isSuccess || itemFile.uploadInProgress">
                                                {{ 'general.remove' | translate }}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="uploader.queue.length === 0">
                                        <td class="font-weight-bold text-center" colspan="4">{{ "general.no.data" | translate }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
        
                            <div class="mx-1 mt-3" *ngIf="acl.MAESTRO_DAM_PICTO_CREATE === 1">
                                <table>
                                    <tr>
                                        <td>
                                            <label class="font-weight-bold">{{ "upload.picto" | translate }}</label>
                                        </td>
                                        <td class="pt-1 pl-2">
                                            <label class="switch switch-pill switch-primary">
                                                <input id="picto" type="checkbox" class="switch-input">
                                                <span class="switch-slider"></span>
                                            </label>
                                        </td>
                                    </tr>
                                    <tr *ngIf="claroAvailable">
                                        <td>
                                            <label class="font-weight-bold">{{ "upload.claro" | translate }}</label>
                                        </td>
                                        <td class="pt-1 pl-2">
                                            <label class="switch switch-pill switch-primary">
                                                <input id="claro" type="checkbox" class="switch-input">
                                                <span class="switch-slider"></span>
                                            </label>
                                        </td>
                                    </tr>
                                </table>
        
                            </div>
        
                            <button [disabled]="this.uploader.queue.length === 0" type="button" class="btn btn-primary m-1"
                                (click)="upload()">
                                {{ 'upload.all' | translate }}
                            </button>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>

            <div class="modal-footer"> <!-- confirmButtonText="{{ 'modal.link' | translate }}" (confirm)="addSelectedMedias()" -->
                <button class="btn btn-primary" data-dismiss="modal" (click)="addSelectedMedias()">{{ 'general.validate' | translate }}</button>

                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    {{ 'general.close' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>