import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ProjectExportComponent } from "./export.component";
import { ProjectExportGuard } from "./export.guard";
import { ProjectExportRoutingModule } from "./export.routing";
import { FlatplanViewModule } from "./flatplan/flatplan-view.module";
import { ExportPageViewModule } from "./page/export-page-view.module";
import { FacebookComponent } from './social/facebook/facebook.component';

@NgModule({
    declarations: [ProjectExportComponent, FacebookComponent],
    imports: [ProjectExportRoutingModule, ExportPageViewModule, FlatplanViewModule, SharedModule, SharedComponentsModule],
    providers: [ProjectExportGuard],
})
export class ProjectExportModule {}
