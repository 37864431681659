import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ElementListComponent, ElementListResolver } from "./list";
import { ElementListGuard } from "./list/element-list.guard";

const pimElementRoutes: Routes = [
    {
        path: "",
        component: ElementListComponent,
        resolve: { resources: ElementListResolver },
        canActivate: [ElementListGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(pimElementRoutes)],
    exports: [RouterModule],
})
export class ElementRoutingModule {}
