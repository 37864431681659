import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConfirmTabGuard } from "app/core/guards/confirm-tab.guard";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ElementTypeUpdateComponent } from "./element-type-update.component";
import { ElementTypeUpdateGuard } from "./element-type-update.guard";
import { ElementTypeUpdateResolver } from "./element-type-update.resolver";
import { ElementTypeUpdateTabComponent } from "./tab/element-type-update-tab.component";

const pimElementTypeUpdateRoutes: Routes = [
    {
        path: `${MAESTRO_ROUTES.actions.update}/:id`,
        component: ElementTypeUpdateComponent,
        resolve: { resources: ElementTypeUpdateResolver },
        canActivate: [ElementTypeUpdateGuard],
        children: [
            {
                path: ":tabId",
                canDeactivate: [ConfirmTabGuard],
                component: ElementTypeUpdateTabComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(pimElementTypeUpdateRoutes)],
    exports: [RouterModule],
})
export class ElementTypeUpdateRoutingModule {}
