import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { TemplatesListComponent } from "./list/templates-list.component";
import { TemplatesListGuard } from "./list/templates-list.guard";

import { TemplateModule } from "./template/template.module";

const templatesRouting: Routes = [
    {
        path: "",
        component: TemplatesListComponent,
        canActivate: [TemplatesListGuard],
        resolve: { templates: TemplatesListGuard },
    },
    {
        path: "",
        loadChildren: () => TemplateModule,
    },
];

@NgModule({
    imports: [RouterModule.forChild(templatesRouting)],
    exports: [RouterModule],
})
export class TemplatesRoutingModule {}
