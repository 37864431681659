import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { ChipsModule } from "primeng-lts/chips";
import { ListCascadeUpdateComponent } from "./listcascade-update.component";
import { ListCascadeUpdateResolver } from "./listcascade-update.resolver";

@NgModule({
    imports: [SharedModule, SharedComponentsModule, ChipsModule],
    declarations: [ListCascadeUpdateComponent],
    exports: [],
    providers: [ListCascadeUpdateResolver],
})
export class ListCascadeUpdateModule {}