import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { SwalModalService } from "../modal/modal.service";

@Injectable()
export class TableService {
    constructor(private _translateService: TranslateService, private _swalModalService: SwalModalService) {}

    /**
     * Parse json data when display table history
     * @param value
     * @returns
     */
    parse(value: any) {
        let val = "";

        try {
            val = JSON.parse(value);
        } catch (error) {
            this.parseError(value);
        }

        return val;
    }

    /**
     * @param value
     */
    parseError(value: string): void {
        this.displayModal(`ERROR : Incorrect json content ${value}`);
    }

    /**
     * @param source
     */
    importError(source: string): void {
        this.displayModal(`ERROR : You are trying to import from an unhandled source ! (${source})`);
    }

    /**
     * Translate table columns
     * @param col
     * @returns
     */
    translateColumns(col: any) {
        return this._translateService.instant("table." + col.field);
    }

    /**
     * @param data
     */
    displayModal(data: string) {
        if (!environment.production) {
            this._swalModalService.error(data);
        } else {
            this._swalModalService.info("Production mode enabled, error can't be displayed");
        }
    }
}
