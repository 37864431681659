import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faAlignJustify, faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { DataService } from "app/core/services/global/data/data.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { MaestroElementTypes } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { Subscription } from "rxjs";

@Component({
    selector: "app-element-type-list",
    templateUrl: "./element-type-list.component.html",
})
export class ElementTypeListComponent implements TableView, OnInit, OnDestroy {
    table: [string, string][];
    columns: string[];

    faTimes: IconDefinition = faTimes;
    faAlignJustify: IconDefinition = faAlignJustify;

    history: any;
    showDiff: boolean;
    startHistory: number;
    elementStory: number;
    objectKeys = Object.keys;

    private actionButtonSource: Subscription;
    private importButtonSource: Subscription;
    private _searchSubscription: Subscription;
    readonly actions: ActionButtonsDef = [];

    private _elementTypes: MaestroElementTypes;

    @ViewChild("historyModal", { static: true }) private swal: SwalComponent;

    acl: ACL;

    constructor(
        private _elementTypeService: ElementTypeService,
        private _elementTableDataPipe: TableDataPipe,
        private _translate: TranslateService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _modalService: SwalModalService,
        private _layout: LayoutService,
        private _authService: AuthService,
        private _dataService: DataService,
        private _toastService: ToastService
    ) {}

    ngOnInit(): void {
        this._elementTypes = this._route.snapshot.data.elementTypes.data;
        this.onFilter(this._elementTypeService.filterSearch.value);
        this.acl = this._authService.getUserAclFromToken();
        if (this.acl.SYSPAD_PIM_ELEMENTTYPE_CREATE) {
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "pim.elementTypes.create";
            this.actionButtonSource = this._layout.actionButton.click$.subscribe((_) => this.onCreate());

            this._layout.importButton.enable = true;
            this._layout.importButton.title = "general.import";
            this.importButtonSource = this._layout.importButton.click$.subscribe((_) => this.onImport());
        }
        if (this.acl.SYSPAD_PIM_ELEMENTTYPE_UPDATE) {
            this.actions.push({ type: ActionType.Update, right: "SYSPAD_PIM_ELEMENTTYPE_UPDATE" });
        }
        /*if(this.acl.SYSPAD_PIM_ELEMENTTYPE_READ) {
            this.actions.push({ type: ActionType.Story, right: "SYSPAD_PIM_ELEMENTTYPE_READ"});
        }*/
        if (this.acl.SYSPAD_PIM_ELEMENTTYPE_DELETE) {
            this.actions.push({ type: ActionType.Delete, right: "SYSPAD_PIM_ELEMENTTYPE_DELETE" });
        }

        this._layout.breadcrumb.setPath({ routerLink: null, name: "pim.elementTypes.title" }, 1);
        this._layout.searchInput.enable = true;
        this._layout.searchInput.currentValue = this._elementTypeService.filterSearch.value;
        this._searchSubscription = this._layout.searchInput.filter$.subscribe((value) => {
            this.onFilter(value);
            this._layout.searchInput.currentValue = this._elementTypeService.filterSearch.value;
        });
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
        if (this.actionButtonSource) {
            this.actionButtonSource.unsubscribe();
        }
        if (this.importButtonSource) {
            this.importButtonSource.unsubscribe();
        }
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._layout.importButton.enable = false;
        this._layout.importButton.title = null;
        this._searchSubscription.unsubscribe();
        this._layout.searchInput.enable = false;
    }

    /**
     * When filtering this function is called to filter the list
     *
     * @param value
     * @returns
     */
    onFilter(value: string): void {
        if (value.length < 3) {
            this._initList(this._elementTypes);
            return;
        }
        this._elementTypeService.filterSearch.next(value);
        this._initList(
            this._elementTypes.filter((elementType) => {
                return elementType.name.toLowerCase().includes(value.toLowerCase());
            })
        );
    }

    /**
     * Handle action click
     *
     * @param event
     */
    onActionClick(event: ActionEvent) {
        const id = event.rowId;
        switch (event.action) {
            case ActionType.Delete:
                this._delete(id);
                break;
            case ActionType.Update:
                this._router.navigate(["update", id], { relativeTo: this._route });
                break;
            case ActionType.View:
                this._router.navigate(["view", id], { relativeTo: this._route });
                break;
            case ActionType.Story:
                this._elementTypeService.getStory(id, 0).subscribe((data) => {
                    this.getStory(data);
                });
                break;
        }
    }

    onCreate() {
        this._openCreateModal();
    }

    /**
     * Delete a data model
     *
     * @param id
     */
    private _delete(id: number) {
        if (id) {
            this._elementTypeService.deleteType(id).subscribe((res) => {
                this._elementTypeService.getElementTypes().subscribe((elements: any) => this._initList(elements.data));
                if (0 === res.data) {
                    this._modalService.success(this._translate.instant("general.deleted"), 1500, true);
                } else {
                    this._modalService.success(this._translate.instant("pim.elementTypes.deleted", { deletedElements: res.data }), null, true);
                }
            });
        }
    }

    /**
     * Create a new data model
     *
     * @param name
     * @param open
     */
    private _create(name, /*file,*/ open = false) {
        if (name) {
            this._elementTypeService.createElement(name, null).subscribe((data: any) => {
                this._modalService.success(this._translate.instant("general.created"));
                if (open) {
                    this._router.navigate(["update", data.data.id], { relativeTo: this._route });
                } else {
                    this._elementTypeService.getElementTypes().subscribe((elements: any) => this._initList(elements.data));
                }
            });
        }
    }

    /**
     * Init the list using tableDataPipe
     *
     * @param elementTypes
     */
    private _initList(elementTypes: MaestroElementTypes) {
        const formatedData = this._elementTableDataPipe.transform(elementTypes);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    /**
     * Open the create modal
     */
    private _openCreateModal() {
        this._modalService
            .open({
                title: this._translate.instant("breadcrumb.pim.elementTypes.create"),
                preConfirm: () => {
                    return [$("#ModalElementTitle").val(), $("#ModalElementImport").val()];
                },

                showCloseButton: true,
                confirmButtonText: this.acl.SYSPAD_PIM_ELEMENTTYPE_UPDATE ? this._translate.instant("general.create_open") : this._translate.instant("general.create"),
                confirmButtonColor: "$maestro",
                showCancelButton: true,
                cancelButtonText: this._translate.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                html: '<div class="form-group">' + '<input class="form-control" type="text" placeholder="" id="ModalElementTitle">' + "</div>",
                //+ '<div>' +
                // '<label>Importer</label><input class="form-control" type="file" placeholder="" id="ModalElementImport">' +
                // '</div>',
            })
            .then((result) => {
                if (result.value) {
                    this._create(
                        $("#ModalElementTitle").val(),
                        // $('#ModalElementImport').val(),
                        result.dismiss || !this.acl.SYSPAD_PIM_ELEMENTTYPE_UPDATE ? false : true // dismiss = create only
                    );
                }
            });
    }

    /**
     * Get All Element's Story
     * @param id
     */
    getStory(data: any) {
        this.history = [];
        if (data.type === "success") {
            this.history = data.data.story;
            this.elementStory = data.data.id;
            this.startHistory = 10;
        }
        this.swal.fire();
    }

    onImport() {
        const infos = [
            this._translate.instant("import.columnInfos.model"),
            this._translate.instant("import.columnInfos.tab"),
            this._translate.instant("import.columnInfos.position"),
            this._translate.instant("import.columnInfos.field"),
            this._translate.instant("import.columnInfos.protocol"),
            this._translate.instant("import.columnInfos.type"),
            this._translate.instant("import.columnInfos.multiple"),
            this._translate.instant("import.columnInfos.mandatory"),
        ];

        this._modalService.import("import-model-base.xlsx", infos, "model").then((fileData) => {
            if (fileData) {
                this._dataService.convertFile(fileData).subscribe((res: any) => {
                    const file = { filename: fileData.name, mimeType: fileData.type, size: fileData.size, base64: res };

                    this._elementTypeService.importFromFile(file).subscribe((res: any) => {
                        if (res.type === "success") {
                            this._toastService.show({ message: this._translate.instant("import.succeeds"), type: "success" });
                            this._elementTypeService.getElementTypes().subscribe((elements: any) => this._initList(elements.data));
                        }
                    });
                });
            }
        });
    }
}
