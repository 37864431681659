import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ExportType, MaestroExport } from "app/shared/models";
import { MAESTRO_ROUTES as routes } from "app/shared/routes";

@Component({
    selector: "app-project-export",
    templateUrl: "./export.component.html",
})
export class ProjectExportComponent implements OnInit, OnDestroy {
    private _export: MaestroExport;

    private readonly _projectsUrls = routes.projects;
    private _redirectionUrl = this._projectsUrls.exportType.flatplan;

    constructor(private _layout: LayoutService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        let baseMenuRoute = this.router.url;
        this._export = this.route.snapshot.data.export;
        let version = this.route.queryParams["_value"]["version"];
        // Redirection depending on the export type
        if (!this.route.snapshot.firstChild) {
            switch (this._export.type.toLowerCase()) {
                case ExportType.Cdf.toLowerCase():
                    this._redirectionUrl = this._projectsUrls.exportType.flatplan;
                    break;
                case ExportType.Html.toLowerCase():
                    this._redirectionUrl = this._projectsUrls.html;
                    break;
                case ExportType.Social.toLowerCase():
                    this._redirectionUrl = this._projectsUrls.exportType.social.social;
                    break;
                case ExportType.Marketing.toLowerCase():
                    this._redirectionUrl = this._projectsUrls.exportType.marketing;
                    break;
                case ExportType.Booklet.toLowerCase():                    
                    this._redirectionUrl = this._projectsUrls.exportType.booklet;
                    break;
                case ExportType.Xml.toLowerCase():
                case ExportType.Csv.toLowerCase():
                    return;
                default:
                    this._redirectionUrl = "";

            }

            this.router.navigate([this._redirectionUrl], { replaceUrl: true, relativeTo: this.route, queryParams: {version: version}});
        } else {
            baseMenuRoute = baseMenuRoute.substring(0, this.router.url.lastIndexOf("/"));
        }
    }

    ngOnDestroy() {
        this._layout.breadcrumb.setPath(null, 1);
        this._layout.breadcrumb.setPath(null, 2);
    }
}
