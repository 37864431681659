import { IMaestroEntity } from "app/shared/models";
import { MaestroProject } from "../project";
import { MaestroWorkflow } from "./workflow";

export interface MaestroExport extends IMaestroEntity {
    description?: string;

    type?: ExportType;
    // todo: check how type should work
    // ExportType |
    // string;
    project?: MaestroProject;
    workflow?: MaestroWorkflow;
    color?: string;
    page?: boolean;
    canal?: string;

    // dashboard
    elementLength?: number;
    pageLength?: number;
    maxLength?: number;
    versions?: IMaestroEntity[];
    groupNames?: string[];
    versionId?: number;

    ownerGroup?: number;
}

export type MaestroExports = MaestroExport[];
export type AllMaestroExports = MaestroExports[];

/**
 * todo: check this
 */
export enum ExportType {
    Html = "Html",
    Csv = "Csv",
    Xml = "Xml",
    Api = "Api",
    Classeur = "Classeur",
    Cdf = "Cdf",
    Print = "Print",
    CdfPage = "Cdf_page",
    Social = "Social",
    Marketing = "Marketing",
    Booklet = "Booklet",
}
