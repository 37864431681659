import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { PageOptionTabComponent } from "./page-option-tab.component";

@NgModule({
    declarations: [PageOptionTabComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [PageOptionTabComponent],
})
export class PageOptionTabModule {}
