<!-- TODO : find how see if bloc.data.OBJECT has data in array : add control + message when workflow = no steps & when steps = no element -->
<!-- CONTROL : Sélection d'un element type qui possède un workflow sans étapes -->
<!-- CONTROL : Sélection d'un element type qui possède workflow + étapes mais n'as pas d'element value (et donc d'element) associé aux étapes -->
<div class="card-body">
    <div *ngIf="list.length > 0; else elseBlock">
        <span style="font-size: 16px; opacity: 0.9">{{ bloc.name }}</span>
        <form [formGroup]="form">
            <!-- TODO : change for multi-select to let comparison between types ? => NOT FOR DOUGHNUT = NON SENSE-->
            <app-drop-down [list]="list" formControlName="item"> </app-drop-down>
        </form>
    </div> 
    <div style="min-width: 200px; min-height: 200px;"> <!-- This let to display doughnut in configuration but it move up it in real graph (use ngIf or ng-template don-t display canvas) -->
        <canvas [hidden]="!chart" #myChart></canvas>
    </div>
</div>
<ng-template #elseBlock>
    <div class="alert alert-info" role="alert">
        {{ 'dashboard.no_workflow' | translate }}
    </div>
</ng-template>