import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ExportPageViewComponent } from "./export-page-view.component";
import { ExportPageResolver } from "./export-page.resolver";

const exportPageRoutes: Routes = [
    {
        path: "",
        children: [
            {
                path: ":id",
                component: ExportPageViewComponent,
                resolve: { resources: ExportPageResolver },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(exportPageRoutes)],
    exports: [RouterModule],
    providers: [ExportPageResolver],
})
export class ExportPageViewRoutingModule {}
