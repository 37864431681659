import { Component, Input } from "@angular/core";
import { FormArray } from "@angular/forms";
import { DROPOWN_SETTINGS } from "app/shared/components/form/drop-down/settings";
import { MaestroElements, MaestroTemplates } from "app/shared/models";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
    selector: "app-export-element-tabs",
    templateUrl: "./export-element-tabs.component.html",
})
export class ExportElementTabsComponent {
    @Input() readonly showErrors: boolean;
    @Input() readonly formArray: FormArray;
    @Input() readonly templatesList: MaestroTemplates = [];
    @Input() readonly elementsByType: { [typeId: string]: MaestroElements };

    dropdownSettings: IDropdownSettings = DROPOWN_SETTINGS.multiple;
}
