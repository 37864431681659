import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { MaestroElementTypes } from "app/shared/models";
import { Observable } from "rxjs";

@Injectable()
export class ElementTypeListResolver implements Resolve<MaestroElementTypes> {
    constructor(private service: ElementTypeService) {}

    resolve(): Observable<MaestroElementTypes> {
        return this.service.getElementTypes();
    }
}
