import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { MaestroWorkflows } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Subscription } from "rxjs";
import { ProjectWorkflowService } from "../../../../../../core/services/admin/workflow/project-workflow.service";

@Component({
    selector: "app-project-workflow-list",
    templateUrl: "./project-workflow-list.component.html",
})
export class ProjectWorkflowListComponent implements TableView, OnInit, OnDestroy {
    table: [string, string][];
    columns: string[];
    readonly actions: ActionButtonsDef = [];
    private subscription: Subscription;

    private _workflows: MaestroWorkflows;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _tableDataPipe: TableDataPipe,
        private _layout: LayoutService,
        private _translate: TranslateService,
        private _projectWfService: ProjectWorkflowService,
        private _userService: UserService
    ) {}

    ngOnInit() {
        this._workflows = this._route.snapshot.data.workflows.data;
        this._initList(this._workflows);
        this._layout.breadcrumb.setPath({ routerLink: null, name: "Project" }, 2);
        const acl: ACL = this._userService.getUserAclFromToken();
        if (acl.MAESTRO_ADMIN_WORKFLOW_UPDATE) {
            this.actions.push({ type: ActionType.Update, right: "MAESTRO_ADMIN_WORKFLOW_UPDATE" });
        }
        if (acl.MAESTRO_ADMIN_WORKFLOW_DELETE) {
            this.actions.push({ type: ActionType.Delete, right: "MAESTRO_ADMIN_WORKFLOW_DELETE" });
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "admin.create.workflow";
            this._layout.breadcrumb.setPath({ routerLink: null, name: this._translate.instant("admin.workflow.title") }, 1);
            this.subscription = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
        }
    }

    ngOnDestroy() {
        this._layout.actionButton.title = null;
        this._layout.breadcrumb.setPath(null, 2);
        this._layout.breadcrumb.setPath(null, 1);
        this._layout.actionButton.enable = false;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onActionClick(event: ActionEvent): void {
        const id = event.rowId;
        switch (event.action) {
            case ActionType.Update:
                this._router.navigate([id, MAESTRO_ROUTES.actions.update], { relativeTo: this._route });
                break;
            case ActionType.Delete:
                this._projectWfService.deleteById(id).subscribe(() => {
                    this._workflows = this._workflows.filter((wf) => wf.id !== id);
                    this._initList(this._workflows);
                });
            default:
                break;
        }
    }

    /**
     * Init the list using tableDatePipe
     *
     * @param projectWorkflows
     */
    private _initList(projectWorkflows: MaestroWorkflows) {
        const formatedData = this._tableDataPipe.transform(projectWorkflows);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    onCreate() {
        this._router.navigate([MAESTRO_ROUTES.actions.create], { relativeTo: this._route });
    }
}
