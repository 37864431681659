<div class="row">
    <div class="col-md-12">
        <div class="card p-4">
            <div class="card-body">
                <ng-content></ng-content>
                <div class="btn-group">
                    <button *ngIf="buttonTitle && acl.SYSPAD_PIM_ELEMENTTYPE_UPDATE" type="button" class="btn btn-primary" [routerLink]="link">
                        <span class="d-inline-block">{{ buttonTitle | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
