import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { ListCascadeListComponent } from "./listcascade-list.component";
import { ListCascadeListResolver } from "./listcascade-list.resolver";

@NgModule({
    imports: [SharedModule, SharedComponentsModule],
    declarations: [ListCascadeListComponent],
    exports: [],
    providers: [ListCascadeListResolver],
})
export class ListCascadeListModule {}
