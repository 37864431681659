import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { faArrowAltCircleRight, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { RoleService } from "app/core/services/admin/role/role.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { BreadcrumbFlatplanToolbarService } from "app/core/services/global/layout/bread-crumb-flatplan-toolbar.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ProjectExportService } from "app/core/services/project/export/export.service";
import { MaestroPage, MaestroPages } from "app/shared/models";
import { ThumbnailItemEvent, ThumbnailMenuAction } from "app/shared/models/project/thumbnail";
import { PageFlip } from "page-flip";
import { Sortable } from "sortablejs";
import { PageThumbnailService } from "../../../../../../../../core/services/project/export/page-thumbnail.service";
import { FlatplanViewType } from "../../../../../../../../shared/models/project/bread-crumb-flatplan-toolbar";
import { SortableDirective } from "./sortable.directive";

@Component({
    selector: "app-flatplan",
    templateUrl: "./flatplan.component.html",
    styleUrls: ["./flatplan.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatplanComponent implements OnInit, OnDestroy {
    @Input() pages: MaestroPages = [];
    @Input() covers: MaestroPages = [];
    @Output() pageAction: EventEmitter<ThumbnailItemEvent> = new EventEmitter();
    @Output() movePage: EventEmitter<number[]> = new EventEmitter(); // array of page id
    @Output() reInitCover: EventEmitter<any> = new EventEmitter();

    @ViewChildren(SortableDirective) sortable;
    @ViewChild("preview") private preview: SwalComponent;

    helper: { top: number; left: number; visible: boolean } = {
        top: 5,
        left: 0,
        visible: false,
    };
    helperImages: any[];
    exportType = 2;
    flatplanView: FlatplanViewType;
    sortableOrigin = [];
    canDrop = false;
    isExportHtml: boolean = false;
    private blobUrl: string;
    version: any;

    readonly faEye = faEye;
    readonly faEyeSlash = faEyeSlash;
    readonly faArrowAltCircleRight = faArrowAltCircleRight;
    previewsUrl: SafeResourceUrl[] = [];
    private sanitizer: DomSanitizer;
    showFlipBook = false;
    flipBookConfigured = false;
    maxWidth = 652;
    maxHeight = 652;
    userId: any;
    showButtonAddCover: boolean = false;

    /**
     * Sortable options for page
     */
    readonly sortableJSOptions = {
        multiDrag: true,
        ghostClass: "placeholder",
        handle: ".toMove",
        selectedClass: "selected",
        scroll: true,
        filter: ".ignore-elements",
        draggable: ".drag",
        onChange: (event: any) => {
            /**
             * Hide placeholder and forbid drop if group
             */
            if (
                event.item.previousSibling instanceof HTMLElement &&
                event.item.nextSibling instanceof HTMLElement &&
                event.item.previousSibling.getAttribute("data-heading") === event.item.nextSibling.getAttribute("data-heading")
            ) {
                document.querySelector(".placeholder").classList.add("forbidden");
                this.canDrop = false;
            } else {
                document.querySelector(".placeholder").classList.remove("forbidden");
                this.canDrop = true;
            }
            if (event.item.nextSibling) {
                document.querySelectorAll('[data-heading="' + event.item.nextSibling.getAttribute("data-heading") + '"]').forEach((page) => {
                    page.classList.add("highlight");
                });
            }

            /**
             * Highlght groups
             */
            if (
                !(event.item.previousSibling instanceof HTMLElement) ||
                !(event.item.nextSibling instanceof HTMLElement) ||
                event.item.previousSibling.getAttribute("data-heading") !== event.item.nextSibling.getAttribute("data-heading")
            ) {
                document.querySelectorAll(".highlight").forEach((page) => page.classList.remove("highlight"));
            }
        },
        onStart: () => {
            /**
             * Change flatplan styling to ease page sort
             */
            document.querySelectorAll(".cdf-page").forEach((page) => {
                page.classList.add("cdf-grid");
            });
            this._pageThumbnail.enableMouseOver = false;

            /**
             * Setting helper
             */
            this.helperImages.forEach((image) => {
                document.querySelector("#cdf-helper").appendChild(image);
            });
            this.helper.visible = true;
        },
        onSort: () => {
            if (!this.canDrop) {
                this.sortable.first.sort(this.sortableOrigin);
            } else {
                this.movePage.emit(this.sortable.first.toArray());
            }
        },
        onEnd: (event: any) => {
            /**
             * Clean forbiddens in case of forbidden drop
             */
            event.item.classList.remove("forbidden");

            /**
             * Reset flatplan
             */
            const pages = event.from.querySelectorAll(".toMove");

            pages.forEach((page) => {
                Sortable.utils.deselect(page);
                page.classList.remove("toMove");
                page.querySelectorAll(".cdf-handle.active").forEach((button) => {
                    button.classList.remove("active");
                });
            });
            this._pageThumbnail.enableMouseOver = true;

            /**
             * Recalculate folio
             */
            let folio = 1;
            document.querySelectorAll("#cdf .folio").forEach((element) => {
                element.textContent = folio.toString();
                folio++;
            });

            this.helperImages = [];
            this.helper.visible = false;
            document.querySelector("#cdf-helper").textContent = "";

            /**
             * Reverse flatplan styling
             */
            document.querySelectorAll(".cdf-page").forEach((page) => {
                page.classList.remove("cdf-grid");
                page.classList.remove("highlight");
                page.removeAttribute("draggable");
            });
        },
    };

    @HostListener("mousemove", ["$event"])
    mouseMove($event: MouseEvent) {
        this.helper.top = $event.clientY + 5;
        this.helper.left = $event.clientX + 5;
    }

    constructor(
        private _toolbar: BreadcrumbFlatplanToolbarService,
        private _pageThumbnail: PageThumbnailService,
        private _exportService: ProjectExportService,
        private _route: ActivatedRoute,
        private _userService: UserService,
        private _sanitizer: DomSanitizer,
        private _roleService: RoleService,
        private cdRef: ChangeDetectorRef,
        private _swalModal: SwalModalService
    ) {
        this._toolbar.switcherEnabled = true;
        this._toolbar.flatplanView$.subscribe((flatplanView) => (this.flatplanView = flatplanView));
        this.sanitizer = _sanitizer;
    }

    ngOnInit(): void {
        this.isExportHtml = window.location.href.includes("html");

        this.version = this._route.queryParams["_value"]["version"];
        undefined !== this.version ? null : (this.version = null);

        // this._exportService.preparePreview(this.pages, this.version); // Already called by parent flatplan-view
        // this._exportService.preparePreview(this.covers, this.version); // Already called by parent flatplan-view
        this.helperImages = [];
        this.userId = this._userService.getUserIdFromToken();
        this._userService.getById(this.userId).subscribe((data) => {
            if (data.data.internalCode == "ADMIN") {
                this.showButtonAddCover = true;
            }

            this.cdRef.detectChanges();
        });
    }

    ngOnDestroy() {
        this._toolbar.switcherEnabled = false;
        URL.revokeObjectURL(this.blobUrl); // For memory management;
    }

    /**
     * Emit an event to open the preview
     * @param page
     */
    onPagePreview(page: MaestroPage) {
        this.pageAction.emit({ data: page, action: ThumbnailMenuAction.Preview });
    }

    eventSelectAndUnselectGroupPages(event, page) {
        const acl = this._userService.getUserAclFromToken();
        if (acl.MAESTRO_PROJECT_PROJECTS_UPDATE === 1) {
            const pages = document.querySelectorAll('[data-heading="' + page.heading + '"]');
            pages.forEach((pageLoop) => {
                if (pageLoop.classList.contains("toMove")) {
                    pageLoop.classList.remove("toMove");
                    pageLoop.classList.remove("selected");
                    event.currentTarget.classList.remove("active");
                    Sortable.utils.deselect(pageLoop);
                } else {
                    Sortable.utils.select(pageLoop);
                    pageLoop.classList.add("toMove");
                    pageLoop.classList.add("selected");
                }
            });
            this.mutation(event, page.heading);
        }
    }

    /**
     * Function to prepare Flatplan to be modified, or reverse if already selected
     *    - Select groups
     *    - Add toMove for sortable
     *    - Save old array for rollback
     * @param event
     * @param heading
     */
    sortableSelectByheading(event) {
        const pages = document.querySelectorAll(".cdf-page");
        pages.forEach((page) => {
            if (page.classList.contains("toMove") && !page.classList.contains("selected")) {
                Sortable.utils.deselect(page);
                page.classList.remove("toMove");
            }
        });
    }

    mutation(event, heading) {
        let me = this;
        const pages = document.querySelectorAll('[data-heading="' + heading + '"]');
        const mutationObserver = new MutationObserver(callback);
        function callback(mutationsList, observer) {
            me.sortableSelectByheading(event);
            mutationObserver.disconnect();
        }

        pages.forEach((page) => {
            mutationObserver.observe(page, { attributes: true });
        });
    }

    showAllPreviews() {
        let pages = [];

        if (this.covers) {
            for (let index = 0; index < this.covers.length; index++) {
                if (index == 2) {
                    this.pages.forEach((page) => {
                        pages.push(page);
                    });
                }
                pages.push(this.covers[index]);
            }
        } else {
            pages = this.pages;
        }

        this._exportService.getAllPreviews(pages).subscribe((response: any) => {
            const header = "application/pdf";
            const data = response.data;
            this.previewsUrl = [];
            for (let i = 0; i < data.length; i++) {
                this.blobUrl = this._exportService.createBlobUrl(data[i], header);
                this.previewsUrl.push(this._exportService.sanitize(this.blobUrl));
            }
            this.preview.fire();
        });
    }

    flipBook() {
        this.showFlipBook = !this.showFlipBook;
        if (this.showFlipBook) {
            document.getElementById("cover").style.display = "none";
            document.getElementById("cdf").style.display = "none";
            document.getElementById("div-flipbook").style.display = "block";
            if (!this.flipBookConfigured) {
                this.configureFlipBook();
                this.flipBookConfigured = true;
            }
        } else {
            document.getElementById("cover").style.display = "flex";
            document.getElementById("cdf").style.display = "flex";
            document.getElementById("div-flipbook").style.display = "none";
        }
    }

    configureFlipBook() {
        // Sélectionnez tous les éléments img dans la classe "page-content"
        const imgElements = document.querySelectorAll(".page-content img");
        const promises = [];

        // Parcourez chaque image pour obtenir les dimensions et créez des Promises pour les chargements
        imgElements.forEach((imgElement: HTMLImageElement) => {
            const promise = new Promise<void>((resolve) => {
                const img = new Image(); // Créez un nouvel élément d'image
                img.src = imgElement.src; // Définissez la source de l'image sur celle de l'élément img

                // Attendez que l'image soit chargée pour obtenir ses dimensions
                img.onload = () => {
                    const width = img.width;
                    const height = img.height;

                    // Comparez avec les dimensions maximales
                    if (width > this.maxWidth) {
                        this.maxWidth = width; // Nouvelle largeur maximale trouvée
                    }

                    if (height > this.maxHeight) {
                        this.maxHeight = height; // Nouvelle hauteur maximale trouvée
                    }

                    resolve(); // Marquez la Promise comme résolue une fois que l'image est chargée
                };
            });

            promises.push(promise);
        });

        // Utilisez Promise.all pour attendre que toutes les Promises soient résolues
        Promise.all(promises).then(() => {
            // Toutes les images ont été chargées, vous pouvez maintenant initialiser pageFlip
            //console.log(this.maxWidth, this.maxHeight);

            const pageFlip = new PageFlip(document.getElementById("flipbook"), {
                width: this.maxWidth, // base page width
                height: this.maxHeight, // base page height
                size: "stretch",
                // set threshold values:
                minWidth: 315,
                maxWidth: this.maxWidth,
                minHeight: 420,
                maxHeight: this.maxHeight,
                //autoSize: true,
                maxShadowOpacity: 0.5, // Half shadow intensity
                showCover: true,
                mobileScrollSupport: false, // disable content scrolling on mobile devices
            });

            // load pages
            pageFlip.loadFromHTML(document.querySelectorAll(".page"));
            document.querySelector(".page-total").textContent = pageFlip.getPageCount() - 1 + "";

            document.querySelector(".btn-prev").addEventListener("click", () => {
                pageFlip.flipPrev(); // Turn to the previous page (with animation)
            });

            document.querySelector(".btn-next").addEventListener("click", () => {
                pageFlip.flipNext(); // Turn to the next page (with animation)
            });

            // triggered by page turning
            pageFlip.on("flip", (e) => {
                document.querySelector(".page-current").textContent = e.data + 1;
            });
        });
    }

    romanize(num) {
        var lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 },
            roman = "",
            i;
        for (i in lookup) {
            while (num >= lookup[i]) {
                roman += i;
                num -= lookup[i];
            }
        }
        return roman;
    }

    createCover() {
        this._swalModal.confirm({}).then((result) => {
            if (result.isConfirmed) {
                this.reInitCover.emit();
            }
        });
    }
}
