import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "app/core/services/admin/common.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { MaestroRoles } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Subscription } from "rxjs";
import { RoleService } from "../../../../../core/services/admin/role/role.service";

@Component({
    selector: "app-role-list",
    templateUrl: "./role-list.component.html",
})
export class RoleListComponent implements TableView, OnInit, OnDestroy {
    private subscription: Subscription;
    table: [string, string][];
    columns: string[];
    public actions: ActionButtonsDef = [];
    private acl: ACL;
    private static envParamControl: string = "enable_role_control";

    constructor(
        private _layout: LayoutService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _tableDataPipe: TableDataPipe,
        private _modal: SwalModalService,
        private _roleService: RoleService,
        private _translateService: TranslateService,
        private _commonService: CommonService,
        private _userService: UserService
    ) {}

    ngOnInit(): void {
        const roles: MaestroRoles = this._route.snapshot.data.roles.data;
        this._initList(roles);

        this.acl = this._userService.getUserAclFromToken();

        this._commonService.checkParamControl(RoleListComponent.envParamControl).subscribe((data) => {
            const enableRoleControl = data.data;

            /**
             * Prepare the create button
             */
            if (!enableRoleControl && this.acl.SYSPAD_ADMIN_ROLE_CREATE) {
                this._layout.actionButton.enable = true;
                this._layout.actionButton.title = "admin.create.role";
                this.subscription = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
            }

            if (this.acl.SYSPAD_ADMIN_ROLE_UPDATE) {
                this.actions.push({ type: ActionType.Update, right: "SYSPAD_ADMIN_ROLE_UPDATE" });
            }

            if (!enableRoleControl && this.acl.SYSPAD_ADMIN_ROLE_DELETE) {
                this.actions.push({ type: ActionType.Delete, right: "SYSPAD_ADMIN_ROLE_DELETE" });
            }
        });
    }

    ngOnDestroy() {
        this._layout.actionButton.enable = false;
        this._layout.breadcrumb.setPath(null, 1);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * Open the create modal
     */
    onCreate() {
        this._openModal();
    }

    /**
     * Handle the action click
     * @param event
     */
    onActionClick(event: ActionEvent): void {
        const id = event.rowId;
        switch (event.action) {
            /**
             * If update redirect
             */
            case ActionType.Update:
                this._router.navigate([id, MAESTRO_ROUTES.actions.update], { relativeTo: this._route });
                break;
            case ActionType.Delete:
                this._delete(id);
                break;
            default:
                break;
        }
    }

    /**
     * Init the list using the tableDataPipe
     * @param roles
     */
    private _initList(roles: MaestroRoles) {
        const formatedData = this._tableDataPipe.transform(roles);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    /**
     * Create a new role
     * @param title
     */
    private _create(title) {
        this._roleService.create({ name: title }).subscribe(() => {
            this._roleService.getAll().subscribe((profiles) => {
                this._initList(profiles["data"]);
            });
        });
    }

    /**
     * Define and open a popup to create a new role
     */
    private _openModal() {
        this._modal
            .open({
                title: this._translateService.instant("admin.roles.create"),
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: this._translateService.instant("general.create"),
                confirmButtonColor: "#20a8d8",
                showCancelButton: true,
                cancelButtonText: this._translateService.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                html: '<div class="form-group">' + '<input class="form-control" type="text" placeholder="" id="ModalFieldsetTitle">' + "</div>",
            })
            .then((result) => {
                if (result.value) {
                    this._create($("#ModalFieldsetTitle").val());
                }
            });
    }

    /**
     * Delete a role
     *
     * @param id
     */
    private _delete(id: number) {
        this._roleService.deleteById(id).subscribe((_) =>
            this._roleService.getAll().subscribe((profiles) => {
                this._initList(profiles["data"]);
            })
        );
    }
}
