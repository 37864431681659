export enum ViewType {
    List,
    Preview,
    Thumbnails,
    Products,

    DamList,
    DamTile,
    DamGrid,
}
export type ViewButtonsDef = ViewButtonDef[];

export interface ViewButtonDef {
    type: ViewType;
    text?: string;
    options?: any;
}

export class ViewEvent {
    action: ViewType;
    rowId: number;
}
