import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PimHomeResources } from "app/shared/models/pim/pim-home-resources";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments";
import { TagService } from "../tag/tag.service";
import { ElementTypeService } from "./element-type.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class HomeService {
    protected modulePath = environment.pimUrl;

    constructor(private _http: HttpClient, private _tagService: TagService, private _elementTypeService: ElementTypeService, private _translateService: TranslateService) {}

    /**
     * Get home resources
     * @returns
     */
    homeResources(): Observable<PimHomeResources> {
        return forkJoin([this.getSidebarData(), this.workflow(), this.lastAction(), this.lastUpdates()]).pipe(
            map(
                (data) =>
                    <PimHomeResources>{
                        tags: data[0].tags,
                        types: data[0].types,
                        workflowList: data[1].data,
                        actionList: data[2].data,
                        updateList: data[3].data,
                    }
            )
        );
    }

    /**
     * Get sidebar data
     * @returns
     */
    getSidebarData(): Observable<any> {
        this._tagService.setModulePath("pim");
        return forkJoin([this._tagService.getSidebarTags(), this._elementTypeService.getSidebarTypes()]).pipe(
            map((data) => {
                return <any>{
                    tags: data[0].data,
                    types: data[1].data,
                };
            })
        );
    }

    /**
     * Get workflow for current user
     * @returns
     */
    workflow(maxResult: number = 5): Observable<any> {
        return this._http.post(`${environment.pimUrl}/list_step.json`, { maxResult: maxResult }).pipe(catchError((_) => of([])));
    }

    /**
     * Get last actions for current user
     * @returns
     */
    lastAction(maxResult: number = 5): Observable<any> {
        return this._http.post(`${environment.pimUrl}/user/last_action.json`, { maxResult: maxResult }).pipe(catchError((_) => of([])));
    }

    /**
     * Get last update for current user
     * @returns
     */
    lastUpdates(maxResult: number = 5): Observable<any> {
        return this._http.post(`${environment.pimUrl}/elements/last_update.json`, { maxResult: maxResult }).pipe(catchError((_) => of([])));
    }

    /**
     * Structure the workflow's data for the dashboard's table
     * @param workflowData
     * @returns
     */
    manageWorkflowData(workflowData: any) {
        let rows = [];



        workflowData.forEach((value) => {
            if (value.step.name === "workflowEnd") {
            rows.push([
                value.element.id,
                [
                    value.element.name,
                    value.element.version,
                    `
                        <span class="text-muted"> ${this._translateService.instant("workflowEnd")} </span>
                    `,
                    value.element.elementType,
                ],
            ]);
            } else {
                rows.push([
                    value.element.id,
                    [
                        value.element.name,
                        value.element.version,
                        `
                        <span class="px-2 rounded-lg text-white" style="background-color: ${value.step.color}">  </span>
                        <span class="ml-1"> ${value.step.name} </span>
                    `,
                        value.element.elementType,
                    ],
                ]);
            }
        });

        return rows;
    }
}
