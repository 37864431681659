<button pButton pRipple type="button" icon="pi pi-sort-alt" class="p-button-sm p-button-rounded btn-primary" pTooltip="{{ 'dataview.reorder' | translate }}"
[disabled]="!data.length" (click)="display = !display"></button>

<p-dialog [(visible)]="display" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [maximizable]="true" [style]="{width: '50vw'}">
    <p-orderList [value]="data" header="{{ 'dataview.reorder' | translate }}" [filterBy]="filterProperty" [dragdrop]="true" [listStyle]="{'max-height':'30rem'}" (onReorder)="reordered=true">
        <ng-template let-d pTemplate="item">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 700px;">
                <div *ngIf="d.hasOwnProperty('extension') && d.extension" class="px-2 py-1 mx-4 my-1 position-absolute right-0 rounded card-thumb-format">
                    {{ d.extension | uppercase }} - <span [ngClass]="_thumbnailsService.getIconClass(d.extension)"></span>
                </div>
                <img *ngIf="moduleUrl && d.id" [src]="d.thumbPath ? moduleUrl+d.thumbPath : _thumbnailsService.getThumb({ binImage: d.thumb })" [id]="'img-' + d.id" alt="{{ d.name }}" width="124" />
                <img *ngIf="moduleUrl && !d.id" [src]="d.thumbPath ? d.thumbPath : d.link" [id]="'img-' + d.id" alt="{{ d.name }}" width="124" style="max-height: 124px; object-fit: contain" (error)="_thumbnailsService.setDefaultImage($event)"/>
                <span class="ml-1 card-title">{{ d.name ? d.name : d.link }}</span>
            </div>
        </ng-template>
    </p-orderList>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-primary" [disabled]="!reordered" (click)="save()">{{ 'general.save' | translate }}</button>
    </ng-template>
</p-dialog>