import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { MAESTRO_ROUTES } from "app/shared/routes";

import { PimWorkflowListComponent } from "./list/pim-workflow-list.component";
import { PimWorkflowListGuard } from "./list/pim-workflow-list.guard";
import { WorkflowFormComponent } from "../form/workflow-form.component";
import { componentFactoryName } from "@angular/compiler";

const pimRoutes: Routes = [
    {
        path: "",
        component: PimWorkflowListComponent,
        canActivate: [PimWorkflowListGuard],
        resolve: { workflows: PimWorkflowListGuard },
    },
    {
        path: `:id/${MAESTRO_ROUTES.actions.update}`,
        component: WorkflowFormComponent,
        data: { entity: "workflow", module: "pim" },
        runGuardsAndResolvers: "always",
    },
    {
        path: `${MAESTRO_ROUTES.actions.create}`,
        component: WorkflowFormComponent,
        data: { entity: "workflow", module: "pim" },
    },
];

@NgModule({
    imports: [RouterModule.forChild(pimRoutes)],
    exports: [RouterModule],
})
export class PimWorkflowRoutingModule {}
