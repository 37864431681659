<div class="grid-container mb-5">
    <div class="column">
        <div *ngFor="let media of mediasColumns[0]; let i = index" [viewType]="viewType" [tags]="tags" app-thumb [dataModels]="dataModels" [media]="media" [buttons]="buttons" (tagFiltered)="tagFiltered.emit($event)" (unlinked)="onUnlinked($event)" (openModal)="open(media)" (deleted)="removeMedia($event)" (addCart)="addCart.emit($event)"></div>
    </div>
    <div class="column">
        <div *ngFor="let media of mediasColumns[1]; let i = index" [viewType]="viewType" [tags]="tags" app-thumb [dataModels]="dataModels" [media]="media" [buttons]="buttons" (tagFiltered)="tagFiltered.emit($event)" (unlinked)="onUnlinked($event)" (openModal)="open(media)" (deleted)="removeMedia($event)" (addCart)="addCart.emit($event)"></div>
    </div>
    <div class="column">
        <div *ngFor="let media of mediasColumns[2]; let i = index" [viewType]="viewType" [tags]="tags" app-thumb [dataModels]="dataModels" [media]="media" [buttons]="buttons" (tagFiltered)="tagFiltered.emit($event)" (unlinked)="onUnlinked($event)" (openModal)="open(media)" (deleted)="removeMedia($event)" (addCart)="addCart.emit($event)"></div>
    </div>
    <div class="column">
        <div *ngFor="let media of mediasColumns[3]; let i = index" [viewType]="viewType" [tags]="tags" app-thumb [dataModels]="dataModels" [media]="media" [buttons]="buttons" (tagRemoved)="tagRemoved.emit()" (tagFiltered)="tagFiltered.emit($event)" (unlinked)="onUnlinked($event)" (openModal)="open(media)" (deleted)="removeMedia($event)" (addCart)="addCart.emit($event)"></div>
    </div>
</div>
