import { Pipe, PipeTransform } from "@angular/core";
import { FormHeaderData } from "app/shared/components/form-header/form-header";
import { MaestroElement } from "app/shared/models";

enum FormHeaderMode {
    Edit = "edit",
    View = "view",
    Preview = "preview",
}

@Pipe({
    name: "elementToFormHeader",
})
export class ElementToFormHeaderPipe implements PipeTransform {
    transform(element: MaestroElement, mode: FormHeaderMode): FormHeaderData {
        let data: FormHeaderData;

        switch (mode) {
            case FormHeaderMode.Edit:
                data = {
                    imageSrc: element.image,
                    subTitle: element.elementType,
                    title: element.name,
                    version: element.version,
                    creationDate: element.dateCreation,
                    updateDate: element.dateUpdate,
                };
                break;
            case FormHeaderMode.View:
                data = {
                    creationDate: element.dateCreation,
                    imageSrc: element.image,
                    subTitle: element.elementType,
                    tagList: element.tagView,
                    title: element.name,
                    updateDate: element.dateUpdate,
                    version: element.version,
                };
                break;
            case FormHeaderMode.Preview:
                data = {
                    imageSrc: element.image,
                    subTitle: element.elementType,
                    tagList: element.tagView,
                    title: element.name,
                };
                break;

            default:
                // code...
                break;
        }

        /**pour le moment il y a que 2 modes, penser à une enum + switchcase si > 2 */

        return data;
    }
}
