import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { MAESTRO_ROUTES } from "app/shared/routes";

const routes: Routes = [
    { path: "", redirectTo: MAESTRO_ROUTES.public.password.forgot },
    { path: MAESTRO_ROUTES.public.password.forgot, component: ForgotPasswordComponent },
    { path: `${MAESTRO_ROUTES.public.password.change}/:token`, component: ChangePasswordComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PasswordRoutingModule {}
