<div *ngFor="let modification of history; let i = index" class="row text-left">
    <div class="col-12 ">
        <div class="row">
            <span class="mt-1 font-weight-bold">{{'table.actionType.'+modification.scenario | translate}}</span>
        </div>
        <div class="row">
            <span *ngIf="modification.username" class="mt-1 font-weight-bold">{{ modification.username }} : {{ modification.date }}</span>
            <span *ngIf="!modification.username" class="mt-1 font-weight-bold">  {{'general.userUnknown' | translate}} : {{ modification.date }}</span>
        </div>
        <div class="row" *ngIf="modification.scenario === 'update'">
            <div *ngFor="let val of modification.value">
                <div class="col-12" *ngIf="modification.fieldName">
                    <div class="text-center border p-1"  *ngFor="let key of objectKeys(val.oldValue)">
                        <span class="font-weight-bold" *ngIf="key ==='value'"> {{modification.fieldName}}: </span>
                        <span class="font-weight-bold" *ngIf="key !== 'value'">{{key}}:</span>
                        <span> {{val.oldValue[key]}} => {{val.newValue[key]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="modification.scenario === 'updateName' || modification.scenario === 'updateNameTabType'">
            <div class="col-12">
                <div class="text-center border p-1">
                    <span *ngIf="modification.value.old">{{modification.value.old}}</span>
                    <span *ngIf="modification.value.oldValue">{{modification.value.oldValue.value}}</span>
                     =>
                    <span *ngIf="modification.value.new">{{modification.value.new}}</span>
                    <span *ngIf="modification.value.newValue">{{modification.value.newValue.value}}</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="modification.scenario === 'addTag' || modification.scenario === 'removeTag'">
            <div class="col-12">
                <div class="text-center border p-1">
                    <span>{{modification.value.name}}</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="modification.scenario === 'updatePositionTabType'">
            <div class="col-12">
                <div class="text-center border p-1">
                    <span class="font-weight-bold">{{modification.name}}: </span> <span>{{modification.value.oldValue.value}}</span> => <span>{{modification.value.newValue.value}}</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="modification.scenario === 'duplicateElement'">
            <div class="col-12">
                <div class="text-center border p-1">
                    <span *ngIf="modification.value.nameOrigin">Dupliquer de {{modification.value.nameOrigin}}</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="modification.scenario === 'deleteTabType' || modification.scenario === 'deleteFieldset' || modification.scenario === 'delete' ">
            <div class="col-12">
                <div class="text-center border p-1" *ngIf="modification.name">
                    <span class="font-weight-bold">name: </span> <span> {{modification.name}}</span>
                </div>
                <div class="text-center border p-1" *ngIf="modification.value.value">
                    <span class="font-weight-bold">value: </span> <span> {{modification.value.value}}</span>
                </div>
                <div class="text-center border p-1" *ngIf="modification.value.name">
                    <span class="font-weight-bold">value: </span> <span> {{modification.value.name}}</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="modification.scenario === 'create' || modification.scenario === 'createTabType' || modification.scenario === 'createElementType' || modification.scenario === 'createFieldset' || modification.scenario === 'createList'">
            <div class="col-12">
                <div class="text-center border p-1">
                    <span class="font-weight-bold"> name: </span>
                    <span *ngIf="modification.value.name">{{modification.value.name}}</span>
                    <span *ngIf="modification.value.value">{{modification.value.value}}</span>
                </div>
            </div>
        </div>
        <hr/>
    </div>

</div>
<button class="btn btn-primary" *ngIf="history.length % 10 === 0 && history.length > 0" (click)="getMoreStory()">
    {{'general.displayMore' | translate }}
</button>
