import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-sidebar-routing-item",
    templateUrl: "./sidebar-routing-item.component.html",
    styleUrls: ["./sidebar-routing-item.component.scss"],
})
export class SidebarRoutingItemComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
