import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { HomeService } from "app/core/services/pim/home.service";
import { ActionButtonsDef, ActionType } from "app/shared/components/action-buttons";
import { ACL } from "app/shared/models/acl";

@Component({
    selector: "app-home-workflow-table",
    templateUrl: "./home-workflow-table.component.html",
})
export class HomeWorkflowTableComponent implements OnInit {
    @Input() data: any;
    @Input() type: string;

    @Output() action = new EventEmitter();

    readonly actions: ActionButtonsDef = [];

    public columns: string[] = ["name", "version", "step", "elementType"];
    public rows: [string, string[]][] = [];

    constructor(private _homeService: HomeService, private _userService: UserService) {}

    ngOnInit() {
        const acl: ACL = this._userService.getUserAclFromToken();

        if (acl.SYSPAD_PIM_ELEMENT_READ) {
            this.actions.push({ type: ActionType.View, right: "SYSPAD_PIM_ELEMENT_READ" });
        }

        if (acl.SYSPAD_PIM_ELEMENT_UPDATE) {
            this.actions.push({ type: ActionType.FormUpdate, right: "SYSPAD_PIM_ELEMENT_UPDATE" });
        }

        this.rows = this._homeService.manageWorkflowData(this.data);
    }

    onActionClick(data: { action: ActionType; rowId: number }) {
        this.action.emit(data);
    }
}
