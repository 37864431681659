import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments";

@Injectable()
export class UploadService {
    acceptList = new BehaviorSubject<any>([]);

    constructor(private _http: HttpClient) {
        this.getMediaTypes().subscribe((data) => {
            this.acceptList.next(data.map((v) => v.header).join(","));
        });
    }

    /**
     * Get medias type available (png, jpeg, ...)
     * @returns
     */
    getMediaTypes(id: number = 0): Observable<{ id: number; extension: string; header: string; isDisabled: boolean }[]> {
        return this._http.get(`${environment.damUrl}/media_types/media_model/${id}.json`).pipe(map((r: any) => r.data));
    }

    /**
     * Upload medias/picto
     *
     * @param files
     * @param isPicto
     * @returns
     */
    uploadMedias(files: File[], isPicto: boolean, isClaro: boolean): Observable<any> {
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });
        formData.append("picto", isPicto + "");
        formData.append("claro", isClaro + "");
        return this._http.post(`${environment.damUrl}/upload.json`, formData);
    }

    /**
     * Check Claro is Available
     */
    claroIsAvailable(): Observable<any> {
        return this._http.get(`${environment.damUrl}/check_claro.json`);
    }
    
}
