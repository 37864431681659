import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ElementService } from "app/core/services/pim/element.service";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";
import { PageTextTabService } from "../../../../../../../../../../core/services/project/export/page-text-tab.service";

@Component({
    selector: "app-edit-product-link",
    templateUrl: "./edit-product-link.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class EditProductLinkComponent implements OnInit {
    @Input() disabled: boolean;
    @Input() pageDuplicateId: boolean;
    @Input() field: any;

    productList: { id: number; text: string }[] = [];

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "text",
        selectAllText: this._translate.instant("general.select_all"),
        unSelectAllText: this._translate.instant("general.unselect_all"),
        searchPlaceholderText: this._translate.instant("general.search"),
        enableCheckAll: false,
        allowSearchFilter: true,
    };

    /**
     * Sortable options for product linked
     */
    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected",
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }

            const from = event.oldIndex;
            const to = event.newIndex;
            array_move(this.products, from, to);
            this.field.value = this.products;
            this._saveValue(this.field, true);
        }.bind(this),
    };

    products = [];

    form: FormGroup;

    @Output() historyClick = new EventEmitter<number>();

    private _exportId: number;
    private _pageId: number;
    private version: number;

    constructor(
        private _translate: TranslateService,
        private _loader: SpinnerService,
        private _toaster: ToastService,
        private _fb: FormBuilder,
        private _route: ActivatedRoute,
        private _pageService: PageTextTabService,
        private _elementService: ElementService
    ) {}

    ngOnInit() {
        this.products = this.field.value.lenght > 0 ? this.field.value : this.field.value;
        this.form = this._fb.group({
            selectedProducts: [this.products.map((v) => ({ id: v.id, text: v.name }))],
        });

        this.productList = this.field.products.map((v) => ({ id: v.id, text: v.name }));

        this._exportId = this._route.parent.parent.parent.snapshot.params.id;
        this._pageId = this._route.snapshot.params.id;
        this.version = this._route.queryParams["_value"]["version"];
    }

    /**
     * Prepare products to be used
     */
    prepareProducts(action = "", item = null): void {
        let newP = [];
        if (action == "DeSelect") {
            newP = this.field.products.filter((p) => this.form.value.selectedProducts.map((s) => s.id).includes(p.id));
            const union = this.products.filter((p) => newP.map((pr) => pr.id).includes(p.id));

            newP = newP.filter((p) => !this.products.map((pr) => pr.id).includes(p.id));

            this.products = union.concat(newP);

            this.field.value = this.products;

            this._saveValue(this.field, true);
        } else {
            newP = this.field.products.filter((p) => this.form.value.selectedProducts.map((s) => s.id).includes(p.id));

            this.getElementLinkValue(item.id, item.text);
        }
    }

    /**
     * Update a value
     * @param value
     * @param field
     */
    updateValue(value: string, field: any): void {
        field.value = value;
        this._saveValue(field);
    }

    /**
     * Update value in a list
     * @param value
     * @param field
     */
    updateValueList(value: any, field: any): void {
        let newValueName = [];
        if (value instanceof Array) {
            value.forEach((v) => {
                newValueName.push(v.name);
            });
        } else {
            return value;
        }
        field.value = JSON.stringify(newValueName);
        this._saveValue(field);
    }

    checkIfNumber(event: any) {
        if (event.which !== 8 && isNaN(Number(String.fromCharCode(event.which)))) {
            event.preventDefault(); //stop character from entering input
        }
    }

    /**
     * Save a value in db
     *
     * @param field
     * @param valuePim
     */
    private _saveValue(field: any, valuePim = false) {
        let objetsVisites: any[] = [];
        this._loader.disable();
        if (valuePim) {
            field.valuePimId = undefined;
        } else {
            field.valuePimId = field.valuePimId || -1;
        }

        field.isLoading = true;
        if (!field.countRequest) {
            field.countRequest = 1;
        } else {
            field.countRequest++;
        }

        let value = field.value;

        if (typeof field.value === "string") {
            value = field.value.trim();
        } else {
            if (field.fieldType == "productLink") {
                value = {
                    products: field.value,
                };
            }
            value = JSON.stringify(value, function (clef, valeur) {
                if (typeof valeur === "object" && valeur !== null) {
                    if (objetsVisites.indexOf(valeur) !== -1) {
                        // Référence circulaire trouvée, ignorez la clé
                        return;
                    }
                    objetsVisites.push(valeur);
                }
                return valeur;
            });
            // value = JSON.stringify(value); // @TOSEE: Did we keep this one or above ?
        }
        this._pageService
            .saveValue(
                value,
                this._exportId,
                field.valueProjectId,
                this.field.elemTypeFieldId,
                this.field.productId,
                this.field.variableProjectId,
                this._pageId,
                this.version,
                this.pageDuplicateId,
                field.valuePimId,
                field.variableProjectId,
                field.collection,
                field.pimInputId
            )
            .subscribe(
                (data) => {
                    field.countRequest--;
                    if (field.countRequest === 0) {
                        this._loader.activate();
                        field.isLoading = false;
                        field.valueProjectId = data.body.data.id;

                        this._toaster.show({
                            message: this._translate.instant("general.savedfield", { key: field.key || field.name }),
                            type: "success",
                        });
                    }
                },
                () => {
                    field.countRequest--;
                    if (field.countRequest === 0) {
                        this._loader.activate();
                        field.isLoading = false;
                        this._toaster.show({
                            message: this._translate.instant("general.nosavedfield", { key: field.key || field.name }),
                            type: "danger",
                        });
                    }
                }
            );
    }

    emitHistory(id: number): void {
        this.historyClick.emit(id);
    }

    getElementLinkValue(elementId: number, elementName: string) {
        this._loader.disable();
        this._pageService.getElementValue(elementId, this._pageId, this.field["variableId"], this.field["layoutId"]).subscribe((element) => {
            let newProduct = { id: elementId, name: elementName, value: element.data };
            this.products.push(newProduct);
            this.field.value = this.products;
            this._saveValue(this.field, true);
        });
    }
}
