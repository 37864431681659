import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { MediaService } from "app/core/services/dam/media.service";
import { HistoryService } from "app/core/services/global/history/history.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { MediaField, MediaModel } from "app/shared/models/media-model";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-edit-form",
    templateUrl: "./edit-form.component.html",
})
export class EditFormComponent implements OnInit, OnDestroy {
    media: MediaModel;
    showDiff = false;
    history: History;
    copyright: string;
    legend: string;
    dateValid: string;

    readonly faClone = faClone;

    @ViewChild("historyModal") swal: SwalComponent;

    private _ngUnsubscribe: Subject<void>;

    @Input() preview: boolean = false; // TODO USE THIS TO SWITCH CONF
    @Input() mediaId: number;

    constructor(
        private _route: ActivatedRoute,
        private _mediaService: MediaService,
        private _toaster: ToastService,
        private _translate: TranslateService,
        private _spinner: SpinnerService,
        private _layout: LayoutService,
        private _history: HistoryService,
        private _sanitizer: DomSanitizer,
        private _swalTargets: SwalPortalTargets
    ) {
        this._ngUnsubscribe = new Subject();
    }

    ngOnInit(): void {
        if (!this.preview) {
            /**
             * Get data from the resolver
             */
            this._route.data.pipe(takeUntil(this._ngUnsubscribe)).subscribe((data: { resources: MediaModel }) => {
                this.media = data.resources;
                this._layout.breadcrumb.setPath({ name: this.media.name, routerLink: null }, 1);
                this.copyright = this.media.copyright;
                this.legend = this.media.legend;
                this.dateValid = this.media.dateValid;
            });
        } else {
            this._mediaService.getMedia(this.mediaId).subscribe((res) => {
                this.media = res.data;
                this.copyright = this.media.copyright;
                this.legend = this.media.legend;
                this.dateValid = this.media.dateValid;
            });
        }
    }

    ngOnDestroy(): void {
        if (!this.preview) {
            this._ngUnsubscribe.next();
            this._ngUnsubscribe.complete();
            this._layout.breadcrumb.setPath(null, 1);
        }
    }

    /**
     * Save a field
     *
     * @param field
     * @param value
     */
    saveField(field: MediaField, value: any): void {
        const payload = {
            idField: field.fieldId,
            idValue: field.fieldValue ? field.fieldValue.id : -1,
            value: typeof value === "string" ? value.trim() : value,
        };
        field.isLoading = true;
        this._spinner.disable();
        this._mediaService
            .updateField(this.media.id, payload)
            .pipe(map((r: any) => r.data))
            .subscribe(
                (data: number) => {
                    field.isLoading = false;
                    this._spinner.activate();
                    this._toaster.show({ message: this._translate.instant("dam.model.savedField", { key: field.fieldName }), type: "success" });
                    /**
                     * Update the field value
                     */
                    if (field.fieldValue) {
                        field.fieldValue.id = data;
                        field.fieldValue.value = value;
                    } else {
                        field.fieldValue = { id: data, value: value };
                    }
                },
                () => {
                    field.isLoading = false;
                    this._spinner.activate();
                }
            );
    }

    /**
     * Open the history modal and fetch history information
     *
     * @param inputId
     */
    openHistory(inputId: number): void {
        this._history.getDamValueHistory(inputId).subscribe((history) => {
            this.history = history;
            this.swal.fire();
        });
    }

    /**
     * Switch diff functionality
     */
    switchDiff(): void {
        this.showDiff = !this.showDiff;
    }

    /**
     * Copy a value to the clipboard
     * @param value
     * @param index
     */
    copyToClip(value: string, index: number): void {
        function listener(e) {
            e.clipboardData.setData("text/html", value);
            e.clipboardData.setData("text/plain", value);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);

        (<any>$("#tooltip-" + index)).tooltip("show");
        setTimeout(() => (<any>$("#tooltip-" + index)).tooltip("hide"), 2000);
    }

    /**
     * Sanitize a value
     * @param value
     * @returns
     */
    sanitize(value: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }
}
