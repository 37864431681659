import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TagService } from "app/core/services/tag/tag.service";
import { MaestroTags } from "app/shared/models";
import { Observable } from "rxjs";
import { TagListModule } from "./tag-list.module";

@Injectable({ providedIn: TagListModule })
export class TagListResolver implements Resolve<MaestroTags> {
    constructor(private service: TagService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MaestroTags> {
        const url = state.url;

        return this.service.getSidebarTags(url);
    }
}
