import { NgModule } from "@angular/core";
import { WorkflowFormModule } from "app/modules/admin/features/workflow/form/workflow-form.module";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { WorkflowConfigurationTabFormComponent } from "./step-tab-form/step-tab-form.component";
import { WorkflowConfigurationComponent } from "./workflow.component";

@NgModule({
    declarations: [WorkflowConfigurationComponent, WorkflowConfigurationTabFormComponent],
    imports: [SharedModule, SharedComponentsModule, WorkflowFormModule],
    exports: [WorkflowConfigurationComponent],
})
export class WorkflowConfigurationModule {}
