import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { LoginModule } from "./login/login.module";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PublicComponent } from "./public.component";
import { PublicRoutingModule } from "./public.routing";

@NgModule({
    imports: [PublicRoutingModule, SharedModule],
    declarations: [NotFoundComponent, PublicComponent, ForbiddenComponent],
    exports: [LoginModule, NotFoundComponent],
})
export class PublicModule {}
