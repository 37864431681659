import { Injectable } from "@angular/core";
import { MaestroElement, Tabs } from "app/shared/models";
import { ElementService } from "./element.service";
import { BehaviorSubject } from "rxjs";
import { ElementExportDocxService } from "./export/docx.service";
import { ElementExportPdfService } from "./export/pdf.service";
import { ElementExportSheetService } from "./export/sheet.service";

@Injectable()
export class ElementExportService {
    element: MaestroElement;
    tabs: Tabs = [];
    private ready = new BehaviorSubject<any>(0);
    //html: string;
    docxContent: any[];
    exportType: any;
    //subscription: any;

    constructor(private _elementService: ElementService, private _docxExport: ElementExportDocxService, private _pdfExport: ElementExportPdfService, private _sheetExport: ElementExportSheetService) {
        this.ready.asObservable().subscribe((count) => {
            if (count !== 0 && count === this.element.tabs.length) {
                switch (this.exportType) {
                    case "docx":
                        this._docxExport.generate(this.element, this.tabs);
                        break;
                    case "pdf":
                        this._pdfExport.generate(this.element, this.tabs);
                        break;
                    case "sheet":
                        this._sheetExport.generate(this.element, this.tabs);
                        break;
                }
            }
        });
    }

    /**
     * Generate an export
     * @param id
     * @param exportType
     */
    generate(id, exportType = "docx") {
        /**
         * Free memory
         */
        this.ready.next(0);
        this.element = null;
        this.tabs = [];

        /**
         * Set export type
         */
        this.exportType = exportType;

        this._elementService.getElement(id).subscribe((element: any) => {
            this.element = element.data;

            this._elementService.getElementTabs(id).subscribe((tabs: any) => {
                tabs.data.forEach((tab, i) => {
                    this._elementService.getTabById(tab.id).subscribe((tabContent: any) => {
                        this.tabs[i] = tabContent.data;
                        this.ready.next(this.ready.value + 1);
                    });
                });
            });
        });
    }
}
