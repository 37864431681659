import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faTools, faTrash } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { MessageInformationService } from "app/core/services/admin/user/MessageInformation.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ActionButtonsDef, ActionType } from "app/shared/components/action-buttons";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES } from "app/shared/routes";

@Component({
    selector: "app-message-maestro",
    templateUrl: "./message.component.html",
})
export class MessageComponent implements OnInit, OnDestroy {
    messageInformation;
    private acl: ACL;
    public actions: ActionButtonsDef = [];
    lastMessageInformation;
    private subscription;
    readonly faTrash = faTrash;
    readonly faTools = faTools;
    userId: number;
    internalCode: string;
    isRoleMaestro: boolean;

    constructor(
        private _messageInformationService: MessageInformationService,
        private _userService: UserService,
        private _modalService: SwalModalService,
        private _layout: LayoutService,
        private _translateService: TranslateService,
        private _route: ActivatedRoute,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this.acl = this._userService.getUserAclFromToken();

        this._messageInformationService.getMessage().subscribe((message) => {
            this.userId = this._userService.getUserIdFromToken();

            this._userService.getById(this.userId).subscribe((data) => {
                this.internalCode = data.data.internalCode;
                this.isRoleMaestro = data.data.roles.includes("ROLE_MAESTRO");

                if ("MAESTRO" === this.internalCode || this.isRoleMaestro) {
                    this.messageInformation = message.data;
                } else if ("ADMIN" === this.internalCode) {
                    this.messageInformation = message.data.filter((d) => !d.isSystemMessage);
                }
            });

            this.actions.push({ type: ActionType.Delete, right: "SYSPAD_ADMIN_ROLE_DELETE" });
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "admin.create.message";
            this.subscription = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
        });
    }

    ngOnDestroy() {
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._layout.breadcrumb.setPath(null, 1);

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    deleteMessage(id: number) {
        this._modalService.delete(false).then((result) => {
            if (result.value) {
                this._messageInformationService.deleteMessage(id).subscribe((_) => {
                    this._messageInformationService.getMessage().subscribe((message) => {
                        if ("MAESTRO" === this.internalCode || this.isRoleMaestro) {
                            this.messageInformation = message.data;
                        } else if ("ADMIN" === this.internalCode) {
                            this.messageInformation = message.data.filter((d) => !d.isSystemMessage);
                        }

                        this._messageInformationService.emitEventMessage();
                    });
                });
            }
        });
    }

    onCreate() {
        this._router.navigate([MAESTRO_ROUTES.actions.create], { relativeTo: this._route });
    }

    onUpdate(id) {
        this._router.navigate([MAESTRO_ROUTES.actions.update, id], { relativeTo: this._route });
    }
}
