import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faIndent, faSearch, faTag } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { DataService } from "app/core/services/global/data/data.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { TreeService } from "app/core/services/global/tree/tree.service";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { ElementService } from "app/core/services/pim/element.service";
import { HomeService } from "app/core/services/pim/home.service";
import { TagService } from "app/core/services/tag/tag.service";
import { ActionType } from "app/shared/components/action-buttons";
import { SidebarTreeNodeComponent } from "app/shared/components/sidebar";
import { MaestroElementTypes, MaestroTags } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { PimHomeResources } from "app/shared/models/pim/pim-home-resources";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Subscription } from "rxjs";
declare var jQuery: any;

interface HomeTableItem {
    id: number;
    name: string;
    type: PimElementType; // not in response
}

enum PimElementType {
    Element = "pim.element",
    ElementType = "pim.elementType",
    List = "list",
    FieldSet = "fieldset",
    Tag = "tag",
}

@Component({
    selector: "app-pim-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class PimHomeComponent implements OnInit, OnDestroy {
    readonly faSearch = faSearch;

    workflowData = [];
    tasks = [];
    lastActions = [];

    faTag = faTag;
    faIndent = faIndent;
    _actionButtonClickedSource: Subscription;

    acl: ACL;

    chips: string[] = [];
    elementsSeparator: "";
    elementTypes = [];
    @ViewChild("createModal", { static: false }) createModal: ElementRef;

    private importButtonSource: Subscription;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _layout: LayoutService,
        private _tagService: TagService,
        private _elementService: ElementService,
        private _userService: UserService,
        private _treeService: TreeService,
        private _translateService: TranslateService,
        private _elementTypeService: ElementTypeService,
        private _modalService: SwalModalService,
        private _homeService: HomeService,
        private _dataService: DataService,
        private _toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.acl = this._userService.getUserAclFromToken();
        this._layout.sidebar.enable = true;
        this._layout.breadcrumb.setPath(null, 1);

        if (this.acl.SYSPAD_PIM_ELEMENT_LIST) {
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "pim.element.list";
            this._layout.actionButton.id = "showElements";
            this._actionButtonClickedSource = this._layout.actionButton.click$.subscribe((_) => this._router.navigate(["pim", "element"]));
        }

        if (this.acl.SYSPAD_PIM_ELEMENT_CREATE) {
            this._layout.importButton.enable = true;
            this._layout.importButton.title = "general.import";
            this.importButtonSource = this._layout.importButton.click$.subscribe((_) => this.onImport());
        }

        this._initData(this._route.snapshot.data.resources);

        this.elementsSeparator = this._translateService.instant("tags.separator", { data: this._translateService.instant("table.product") });
    }

    public openModal(): void {
        this._elementTypeService.getElementTypes().subscribe((res: any) => {
            this.elementTypes = res.data;
        });
    }

    onActionClick(data: { action: ActionType; rowId: number }) {
        const item = this.lastActions.find((la) => la.id === data.rowId);
        this._generateUrlThenRedirect(item);
    }

    onWorkflowClick(data: { action: ActionType; rowId: number }) {
        this._generateUrlThenRedirect(
            {
                id: data.rowId,
                name: "",
                type: PimElementType.Element,
            },
            ActionType.FormUpdate
        );
    }

    onGlobalLastEdit(data: { action: ActionType; rowId: number }, lastAction: boolean) {
        let item;

        if (lastAction) {
            item = this.lastActions.find((la) => la.id === data.rowId);
        } else {
            item = this.tasks.find((t) => t.id === data.rowId);
        }

        if (undefined !== item && "delete" !== item.action) {
            this._generateUrlThenRedirect(item, data.action);
        }
    }

    ngOnDestroy(): void {
        jQuery(this.createModal.nativeElement).modal("hide");
        this._layout.sidebar.enable = false;
        this._layout.sidebar.sideBarDef = null;
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;

        if (undefined !== this._actionButtonClickedSource) {
            this._actionButtonClickedSource.unsubscribe();
        }

        if (this.importButtonSource) {
            this.importButtonSource.unsubscribe();
        }

        this._layout.importButton.enable = false;
        this._layout.importButton.title = null;
    }

    /**
     * Init List & Sidebar
     *
     * @param resources
     */
    private _initData(resources: PimHomeResources) {
        this._initList(resources);
        this._initSideBar(resources.tags, resources.types);
    }

    /**
     * Init the three tables
     *
     * @param data
     */
    private _initList(data: PimHomeResources) {
        this.workflowData = data.workflowList;
        this.tasks = data.updateList;
        this.lastActions = data.actionList;
    }

    /**
     * Init the sidebar with tags & data models
     * @param tags
     * @param elementType
     */
    private _initSideBar(tags: MaestroTags, elementType: MaestroElementTypes) {
        const options = [];

        if (this.acl.SYSPAD_PIM_TAG_LIST) {
            options.push({
                title: "sidebar.tags",
                icon: faTag,
                click: this.onFilterByTag.bind(this),
                data: tags,
            });
        }
        if (this.acl.SYSPAD_PIM_ELEMENT_LIST) {
            options.push({
                title: "sidebar.elementType",
                icon: faIndent,
                click: this.onFilterByType.bind(this),
                data: elementType,
            });
        }

        this._layout.sidebar.sideBarDef = {
            component: SidebarTreeNodeComponent,
            options: options,
        };
    }

    /**
     * Launch a filter by type (elements)
     *
     * @param type
     */
    onFilterByType(type: any) {
        this._elementService.filterTypes.next([{ id: type.data.id, name: type.data.name }]);
        this._router.navigate([`${MAESTRO_ROUTES.pim.element}`], { relativeTo: this._route });
    }

    /**
     * Launch a filter by tag (elements)
     *
     * @param tag
     */
    onFilterByTag(tag: any) {
        const filterPath = this._tagService.getFilterPath();
        const children = this._treeService.getNestedData(tag.data.children, "children");
        let tags = [];
        const parent = { id: tag.data.id, name: tag.data.name };
        tags.push(parent);

        const objects = children.map((child) => {
            return { id: child.id, name: child.name };
        });

        tags = [...tags, ...objects];

        this._elementService.filterTags.next(tags);
        this._router.navigate([filterPath]);
    }

    /**
     * Redirect to the correct route
     * @param item
     * @param action
     */
    private _generateUrlThenRedirect(item: HomeTableItem, action: ActionType = null) {
        let base_url: string;
        switch (item.type) {
            case PimElementType.Element:
                base_url = MAESTRO_ROUTES.pim.element;
                break;
            case PimElementType.ElementType:
                base_url = MAESTRO_ROUTES.pim.elementType;
                break;
            case PimElementType.FieldSet:
                base_url = MAESTRO_ROUTES.pim.fieldset;
                break;
            case PimElementType.List:
                base_url = MAESTRO_ROUTES.pim.list;
                break;
            case PimElementType.Tag:
                base_url = MAESTRO_ROUTES.pim.tag;
                break;
            default:
                base_url = MAESTRO_ROUTES.pim.element;
        }

        let route = "";
        switch (action) {
            case ActionType.View:
                route = base_url + "/" + MAESTRO_ROUTES.actions.view + "/" + item.id;
                break;
            case ActionType.Update:
            case ActionType.FormUpdate:
                route = base_url + "/" + MAESTRO_ROUTES.actions.update + "/" + item.id;
                break;
        }

        this._router.navigate([route], { relativeTo: this._route });
    }

    create() {
        if (this.chips.length && $("#modalElements").val()) {
            this._elementService.create(this.chips, $("#modalElements").val(), 1 === this.chips.length).subscribe((res: any) => {
                this._modalService.success(this._translateService.instant("general.created"));
                jQuery(this.createModal.nativeElement).modal("hide");

                if (1 === this.chips.length) {
                    this._router.navigate([MAESTRO_ROUTES.pim.base, MAESTRO_ROUTES.pim.element, MAESTRO_ROUTES.actions.update, res.data]);
                } else {
                    this.chips = [];

                    this._homeService.lastAction().subscribe((res) => {
                        this.lastActions = res.data;
                    });
                }
            });
        }
    }

    onImport() {
        const infos = [this._translateService.instant("import.columnInfos.model"), this._translateService.instant("import.columnInfos.product")];

        this._modalService.import("import-product-base.xlsx", infos, "product").then((fileData) => {
            if (fileData) {
                this._dataService.convertFile(fileData).subscribe((res: any) => {
                    const file = { filename: fileData.name, mimeType: fileData.type, size: fileData.size, base64: res };

                    this._elementService.importFromFile(file).subscribe((res: any) => {
                        if (res.type === "success") {
                            this._toastService.show({ message: this._translateService.instant("import.succeeds"), type: "success" });
                            this._elementService.getElements().subscribe((elements: any) => this._initList(elements.data));
                        }
                    });
                });
            }
        });
    }
}
