<div class="tableComponent row">
    <div class="col-12">
        <div class="card">
            <form *ngIf="form" [formGroup]="form" (ngSubmit)="formSubmit()" autocomplete="off">
                <div class="card-body">
                    <div class="form-group row">
                        <label class="col-5 col-md-2" for="password">{{ 'change_password.old' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <input type="password" id="oldpassword" formControlName="oldpassword" class="form-control" [ngClass]="{ 'is-invalid': formControls.oldpassword.dirty && formControls.oldpassword.errors }" />
                            <div *ngIf="formControls.oldpassword.dirty && formControls.oldpassword.errors" class="invalid-feedback">
                                <div *ngIf="formControls.oldpassword.errors.required">
                                    {{ 'general.required' | translate }}
                                </div>
                                <div *ngIf="formControls.oldpassword.errors.invalidPassword">
                                    {{ 'login.password.error.invalid' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-5 col-md-2" for="password">{{ 'change_password.title' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <input type="password" id="newpassword" formControlName="newpassword" class="form-control" [ngClass]="{ 'is-invalid': formControls.newpassword.dirty && formControls.newpassword.errors }" />
                            <div *ngIf="formControls.newpassword.dirty && formControls.newpassword.errors" class="invalid-feedback">
                                <div *ngIf="formControls.newpassword.errors.required">
                                    {{ 'general.required' | translate }}
                                </div>
                                <div *ngIf="formControls.newpassword.errors.invalidPassword">
                                    {{ 'login.password.error.invalid' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-5 col-md-2" for="confirmPassword">{{ 'admin.users.confirm' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <input type="password" id="confirmPassword" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': formControls.confirmPassword.dirty && formControls.confirmPassword.errors }" />
                            <div *ngIf="formControls.confirmPassword.dirty && formControls.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="formControls.confirmPassword.errors.required">
                                    {{ 'general.required' | translate }}
                                </div>
                                <div *ngIf="formControls.confirmPassword.errors.mustMatch">
                                    {{ 'login.password.confirm.error' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <button class="btn btn-danger" type="button" (click)="onBack()">
                            <fa-icon [icon]="faTimes"></fa-icon>
                            <span class="d-none d-md-inline-block ml-2">{{ 'general.close' | translate }}</span>
                        </button>
                        <div class="btn-group ml-auto">

                            <button type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="savePassword()">
                                <fa-icon [icon]="faSave"></fa-icon>
                                <span class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>