import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { TemplatesListComponent } from "./templates-list.component";
import { TemplatesListGuard } from "./templates-list.guard";

@NgModule({
    declarations: [TemplatesListComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [TemplatesListComponent],
    providers: [TemplatesListGuard],
})
export class TemplatesListModule {}
