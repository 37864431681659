import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faPlusCircle, faSort, faSortAlphaDown, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ListService } from "app/core/services/pim/list.service";
import { MaestroList } from "app/shared/models";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-list-update",
    templateUrl: "./list-update.component.html",
})
export class ListUpdateComponent implements OnDestroy, OnInit {
    list: any;

    formArray: FormArray;

    updateName: boolean;
    nameAtTheOpening: string;
    listcascade: any = [];
    headercascade: any = [];
    listId: any = [];
    idList: number;

    readonly faSave = faSave;
    readonly faSort = faSort;
    readonly faPlus = faPlusCircle;
    readonly faTrash = faTrashAlt;
    readonly faEdit = faEdit;
    readonly faClose = faTimes;
    readonly faSortAlphaDown = faSortAlphaDown;

    private _ngUnsubscribe: Subject<void>;

    /**
     * Sort list's value
     */
    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }
            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(this.formArray.controls, from, to);
            array_move(this.formArray.value, from, to);
            this.formArray.markAsDirty();
        }.bind(this),
    };

    constructor(
        private _listService: ListService,
        private _route: ActivatedRoute,
        private _layout: LayoutService,
        private _translate: TranslateService,
        private _toaster: ToastService,
        private _spinner: SpinnerService,
        private _fb: FormBuilder,
        private _modal: SwalModalService
    ) {
        this._ngUnsubscribe = new Subject();
        this.updateName = false;
    }

    ngOnInit(): void {
        this._route.data.pipe(takeUntil(this._ngUnsubscribe)).subscribe((data: { resources: MaestroList }) => {
            this.list = data.resources;

            this._initData();

            this._layout.breadcrumb.setPath({ routerLink: "/pim/list", name: "breadcrumb.pim.lists.title" }, 1);
            this._layout.breadcrumb.setPath({ routerLink: null, name: this.list.name }, 2);
        });
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
        this._layout.breadcrumb.setPath(null, 2);
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
    }

    /**
     * Save list's name
     */
    saveName(): void {
        this._spinner.disable();
        this._listService.changeName(this.list.id, this.list.name).subscribe((data) => {
            this._layout.breadcrumb.setPath({ routerLink: null, name: this.list.name }, 2);
            this.updateName = false;
            this.nameAtTheOpening = this.list.name;
            this._toaster.show({
                message: this._translate.instant("pim.lists.listNameSaved"),
                type: "success",
            });
            this._spinner.activate();
        });
    }

    /**
     * Sort fields alphabetically
     */
    sortFields(): void {
        const collator = new Intl.Collator("fr", { numeric: true, sensitivity: "base" });
        this.formArray.controls = this.formArray.controls.sort((a: FormGroup, b: FormGroup) => collator.compare(a.controls.name.value, b.controls.name.value));
        this.formArray.setValue(this.formArray.value.sort((a: any, b: any) => collator.compare(a.name, b.name)));
        this.formArray.markAsDirty();
    }

    /**
     * Add a new value
     */
    addField(): void {
        const fG = this._fb.group({
            id: [-1],
            name: ["", Validators.required],
        });

        this.formArray.push(fG), this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
    }

    /**
     * Delete a value
     * @param index
     */
    deleteField(index: number): void {
        this._modal.delete().then((result) => {
            if (result.value) {
                this.formArray.removeAt(index);
                this.saveList();
            }
        });
    }

    disableListFirstButton(): boolean {
        let disable = false;
        if (this.formArray.value.length === 1) {
            disable = true;
            return disable
        }
        return disable;
    }

    /**
     * Save the list
     */
    saveList(): void {
        if (this.formArray.invalid) {
            return;
        }

        const payload = {
            model: {
                "Contener Field": this.formArray.value.map((v) => ({ FieldId: v.id, Nom: v.name.trim() })),
            },
        };
        this._listService
            .updateFormField(this.list.id, payload)
            .pipe(map((r: any) => r.data))
            .subscribe((data: any) => {
                this.formArray.markAsPristine();
                this._toaster.show({
                    message: this._translate.instant("general.saved"),
                    type: "success",
                });
                this.list = data;
                this._initData();
            });
    }

    /**
     * Check if each value is unique
     * @returns
     */
    uniqueValues(): ValidatorFn {
        return (formArray: FormArray): null => {
            const values = formArray.value.map((v) => v.name.toLowerCase());
            formArray.controls.forEach((formGroup: FormGroup) => {
                const control = formGroup.controls.name;
                if (values.filter((v: string) => v.trim().toLowerCase() === control.value.trim().toLowerCase()).length > 1 && control.value) {
                    control.setErrors({ uniqueValue: true });
                } else {
                    const errors = control.errors;
                    if (errors && errors.uniqueValue) {
                        delete errors.uniqueValue;
                    }
                    if (errors && Object.keys(errors).length === 0) {
                        control.setErrors(null);
                    } else {
                        control.setErrors(errors);
                    }
                }
            });
            return null;
        };
    }

    /**
     * Init the data
     */
    private _initData(): void {
        this.nameAtTheOpening = this.list.name;

        this.formArray = this._fb.array([], this.uniqueValues());

        this.list.fields.forEach((f) => {
            this.formArray.push(
                this._fb.group({
                    id: [f.id, Validators.required],
                    name: [f.name, [Validators.required]],
                })
            );
        });
    }

    addValue(ids, value){            
        if(ids.length == 0){
            
            this.listcascade.push({"name": value+"-"+this.listcascade.length, parent: null,"children": []});
        } else {
            let lists = this.listcascade;
            ids.forEach(id => {
                lists = this.getChildrenList(id,lists);
            });

            lists.push({"name": value, parent: ids,"children": []})
            
        }
    }

    getChildrenList(id, listcascade){
        return listcascade[id].children;
    }

    addHeader(){
        this.headercascade.push(this.headercascade.length + 1);
    }

    changeIds(ids, id){
        if(ids.length == 0){
            this.idList = id;
        }
        ids.push(id);
        this.listId = ids;
    }
}
