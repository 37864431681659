import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { SidebarRoutingFormComponent } from "./sidebar-routing-form/sidebar-routing-form.component";
import { SidebarRoutingItemComponent } from "./sidebar-routing-item/sidebar-routing-item.component";
import { SidebarRoutingComponent } from "./sidebar-routing.component";

@NgModule({
    imports: [SharedModule],
    declarations: [SidebarRoutingComponent, SidebarRoutingFormComponent, SidebarRoutingItemComponent],
    entryComponents: [SidebarRoutingComponent],
})
export class SidebarRoutingModule {}
