import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { ActionEvent, ActionType } from "app/shared/components/action-buttons";

@Component({
    selector: "app-preview-element",
    templateUrl: "./preview-element.component.html",
    styleUrls: ["./preview-element.component.scss"],
})
export class PreviewElementComponent implements OnInit {
    @Input() element: any;
    @Input() actions: ActionType[];

    @Output() action: EventEmitter<ActionEvent> = new EventEmitter();

    faEye = faEye;

    ngOnInit(): void {
        this.element = this.element.data;
    }

    onActionClick(action: ActionType, rowId: number) {
        this.action.emit({ action, rowId });
    }
}
