import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroTemplates } from "app/shared/models";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments";
import Swal from "sweetalert2";

@Injectable()
export class TemplateService {
    constructor(protected _http: HttpClient) {}

    /**
     * Get a template by id
     * @param id
     * @returns
     */
    getById(id: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/template/${id}.json`).pipe(map((a) => <any>this._extractData(a)));
    }

    /**
     * Get the template's list
     * @returns
     */
    getAll(): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/templates.json`);
    }

    /**
     * Delete a template
     * @param id
     * @returns
     */
    deleteById(id: number): Observable<any> {
        return this._http.delete(`${environment.projectsUrl}/template/${id}.json`);
    }

    /**
     * Create a template
     * @param template
     * @returns
     */
    createTemplate(template: any) {
        const formData = new FormData();

        formData.append("twigName", template.twigName);
        formData.append("exportType", template.exportType);
        formData.append("twigContent", template.twigContent);
        formData.append("twigId", template.twigId);
        formData.append("name", template.name);

        formData.append("fileJsx", template.fileJsx);
        formData.append("fileIndt", template.fileIndt);

        if (template.filesPreview !== null) {
            template.filesPreview.forEach((file, index) => {
                formData.append(`filesPreview[${index}]`, file);
            });
        }

        if (template.htmlFile !== null) {
            template.htmlFile.forEach((file, index) => {
                formData.append(`htmlFile[${index}]`, file);
            });
        }

        formData.append("regeneratedAt", template.regeneratedAt);
        formData.append("numberPage", template.numberPage);
        formData.append("numberElement", template.numberElement);
        formData.append("suffix", template.suffix);
        formData.append("fontPath", template.fontPath);
        formData.append("pictos", template.pictos);
        formData.append("options", template.options);
        formData.append("medias", template.medias);

        return this._http.post(`${environment.projectsUrl}/template.json`, formData);
    }

    /**
     * Update a template
     * @param id
     * @param template
     * @returns
     */
    updateTemplate(id: number, template: any): Observable<any> {
        const formData = new FormData();

        formData.append("twigName", template.twigName);
        formData.append("exportType", template.exportType);
        formData.append("twigContent", template.twigContent);
        formData.append("twigId", template.twigId);

        formData.append("name", template.name);

        formData.append("fileJsx", template.fileJsx);
        formData.append("fileIndt", template.fileIndt);

        if (template.filesPreview) {
            template.filesPreview.forEach((file, index) => {
                formData.append(`filesPreview[${index}]`, file);
            });
        }

        template.htmlFile.forEach((file, index) => {
            formData.append(`htmlFile[${index}]`, file);
        });

        formData.append("regeneratedAt", template.regeneratedAt);
        formData.append("numberPage", template.numberPage);
        formData.append("numberElement", template.numberElement);
        formData.append("suffix", template.suffix);
        formData.append("fontPath", template.fontPath);
        formData.append("pictos", template.pictos);
        formData.append("options", template.options);
        formData.append("medias", template.medias);

        return this._http.post(`${environment.projectsUrl}/template/${id}.json`, formData);
    }

    downloadDedicatedFile(id, extension, filename) {
        return this._http.get(`${environment.projectsUrl}/template/${id}/getLastDedicatedFile/${extension}.json`).subscribe((response: any) => {
            const base64Data = response.data;
            const fileData = this.base64ToBlob(base64Data);
            saveAs(fileData, filename);
        });
    }

    downloadJsx() {
        return this._http.get(`${environment.projectsUrl}/download/jsx.json`).subscribe((response: any) => {
            const base64Data = response.data;
            const fileData = this.base64ToBlob(base64Data);
            saveAs(fileData, "global.jsx");
            Swal.close();
        });
    }

    uploadJsx(files: File[]) {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append(`file`, file);
        });
        return this._http.post(`${environment.projectsUrl}/upload/jsx.json`, formData)
    }

    base64ToBlob(base64Data: string): Blob {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
        return blob;
    }

    /**
     * Get export types
     * @returns
     */
    getExportTypes(): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/exports_type.json`);
    }

    /**
     * Get templates filtered by export type
     * @param exportTypeId
     * @returns
     */
    getTemplatesByExportType(exportTypeId: number): Observable<MaestroTemplates> {
        return this._http.get<MaestroTemplates>(`${environment.projectsUrl}/export/${exportTypeId}/templates.json`);
    }

    /**
     * Get templates filtered by export type
     * @param exportTypeIds
     * @returns
     */
    getExportTypesTemplates(exportTypeIds: string): Observable<MaestroTemplates> {
        return this._http.get<MaestroTemplates>(`${environment.projectsUrl}/export_type/templates.json?exportTypes=${exportTypeIds}`);
    }

    /**
     * don't know wtf is this.
     */
    private _extractData(data: any) {
        const array = [];
        for (const key of Object.keys(data)) {
            array[key] = data[key];
        }
        return array;
    }

    /**
     * Get the twig list
     * @returns
     */
    getTwigs(): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/twigs.json`);
    }

    /**
     * Get the twig
     * @returns
     */
    getTwig(id: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/twig/${id}.json`);
    }

    /**
     * Get all pictos from DAM module
     * @returns
     */
    getPictos(): Observable<any> {
        return this._http.get(`${environment.damUrl}/media/pictos.json`).pipe(map((r: any) => r.data));
    }

    /**
     * Update a twig
     *
     * @param twigId
     * @param twigName
     * @param twigContent
     * @param exportType
     * @param templateId
     */
    updateTwig(twigId: number, twigName: string, twigContent: string, exportType: number, templateId: number) {
        return this._http.put(`${environment.projectsUrl}/twig/${twigId}.json`, {
            twigName,
            twigContent,
            exportType,
            templateId,
        });
    }

    /**
     * Create a twig
     *
     * @param twigName
     * @param twigContent
     * @param exportType
     * @param templateId
     */
    createTwig(twigName: string, twigContent: string, exportType: number, templateId: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/twig.json`, {
            twigName,
            twigContent,
            exportType,
            templateId,
        });
    }

    /**
     * save a twig
     *
     * @param twigName
     * @param twigContent
     * @param exportType
     * @param templateId
     */
    saveTwig(twigName: string, twigContent: string, exportType: number, templateId: number, action: string, twigId?: number): Observable<any> {
        if (action == "create") {
            return this._http.post(`${environment.projectsUrl}/twig.json`, {
                twigName,
                twigContent,
                exportType,
                templateId,
            });
        } else {
            return this._http.put(`${environment.projectsUrl}/twig/${twigId}.json`, {
                twigName,
                twigContent,
                exportType,
                templateId,
            });
        }
    }

    /**
     * Get Variables by twigId
     *
     * @param twigId
     */
    getVariables(twigId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/twig/${twigId}/variables.json`);
    }

    /**
     * Update Variables
     *
     * @param variables
     * @param twigId
     */
    updateVariables(variables: any, twigId: number, templateId: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/twig/${twigId}/variables.json`, { variables: variables, templateId: templateId });
    }

    /**
     * Delete Variable
     *
     * @param variableId
     */
    deleteVariable(variableId: number, typeField: string): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
            body: {
                typeField: typeField,
            },
        };
        return this._http.delete(`${environment.projectsUrl}/variable/${variableId}.json`, options);
    }

    projectNameByTemplate(templateId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/template/${templateId}/projects.json`);
    }

    validateContent(content: string, type: number /*, template: number*/): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/twig/validate/content.json`, {content: content, type: type /*, template: template*/});
    }

    /**
     * Get the previews
     * @returns
     */
    getPreviews(ids: string, type: string): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/template/${ids}/getDedicatedFiles/${type}.json`);
    }
}
