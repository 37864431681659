import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Sortable } from "sortablejs";



@Directive({
  selector:'[sortableSection]',
})


export class SortableSectionDirective implements OnInit {
  @Input() sortableSection: any;
  @Input() sortableEnabled: boolean = true;
  private sortable: Sortable;

  constructor(private el: ElementRef) { }

  ngOnInit() {
      if(this.sortableEnabled){
          this.initializeSortable()
      }
  }


  private initializeSortable() {
      if (!this.sortable) {

          this.sortable = new Sortable(this.el.nativeElement, this.sortableSection);
      }

      this.el.nativeElement.addEventListener('dragstart', (event: Event) => {
          if (!this.sortableEnabled) {
            event.preventDefault();
          }
        });
      
  }

  ngOnChanges(changes: SimpleChanges): void {
      if ('sortableEnabled' in changes) {
          const currentValue = changes.sortableEnabled.currentValue;
          if (currentValue) {
              this.initializeSortable();
          } else {
              this.destroySortable();
          }
      }
  }

  private destroySortable() {
      if (this.sortable) {
          this.sortable.destroy();
          this.sortable = null;
      }
  }

}