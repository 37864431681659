import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TreeService } from "app/core/services/global/tree/tree.service";
import { TreeNode } from "primeng-lts/api";

@Component({
    selector: "app-treeTag",
    templateUrl: "./treeTag.component.html",
})
export class TreeTagComponent implements OnInit {
    @Input() dataLength: number;
    @Input() value: any[];

    @Input() selectionMode: string = "checkbox";
    @Input() filter: boolean = true;
    @Input() filterMode: string = "strict";
    @Input() propagateSelectionUp: boolean = false;
    @Input() propagateSelectionDown: boolean = false;
    @Input() scrollHeight: string = "500px";

    massTagAdd: boolean = true;
    massTagNode: TreeNode[] = [];

    @Output() massTagStart: EventEmitter<any> = new EventEmitter(); // Emitted when add or remove tag

    constructor(private _translateService: TranslateService, public _treeService: TreeService) {}

    ngOnInit(): void {}

    massTagSelection(event: any, add: boolean): void {
        let par = [];
        let chi = [];

        const upParent = this.propagateSelectionUp && undefined !== event.node.parent;
        const downChildren = this.propagateSelectionDown && undefined !== event.node.children;

        if (upParent) {
            par = this._treeService.getNestedData([event.node.parent], "parent", "id");
        } else if (downChildren) {
            chi = this._treeService.getNestedData(event.node.children, "children", "id");
        }

        let newDataIds = [...par, ...chi];

        const dataId = event.node.id;

        newDataIds.push(dataId);

        const dataToEmit = { dataIds: newDataIds, add: add };
        this.massTagStart.emit(dataToEmit);
    }
}
