import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ElementService } from "app/core/services/pim/element.service";
import { Observable } from "rxjs";
import { ElementResources } from "../../../../../shared/models/pim/element-resources.model";

@Injectable()
export class ElementUpdateResolver implements Resolve<ElementResources> {
    constructor(private _elementUpdateService: ElementService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ElementResources> {
        return this._elementUpdateService.getElementResources(route.params.id);
    }
}
