import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ElementsConfigurationService } from "app/core/services/project/project/elements.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { forkJoin, Observable } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { ElementsConfigurationModule } from "./elements.module";

@Injectable({
    providedIn: ElementsConfigurationModule,
})
export class ElementsGuard implements CanActivate, Resolve<any> {
    constructor(private _service: ElementsConfigurationService, private _projectService: ProjectService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const projectId = route.parent.parent.parent.params.id;

        return forkJoin([
            this._service.getConfiguration(projectId),
            this._service.getElementTypes(),
            // this._projectService.getAuths(projectId),
        ]).pipe(
            concatMap((data) =>
                this._service.getVersionListByTypes((data[0] as any).map((config) => config.id)).pipe(
                    map(
                        (versionList) =>
                            <any>{
                                configs: (data[0] as any).map((config) => ({
                                    ...config,
                                    name: (data[1] as any).data.find((type) => type.id === config.id).name,
                                })),
                                types: (data[1] as any).data,
                                versionsByType: versionList,
                                projectId: projectId,
                                // auths: (data[2] as any).data
                            }
                    )
                )
            )
        );
    }
}
