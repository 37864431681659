import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { TagModule } from "../tag/tag.module";
import { ElementModule, ElementTypeModule, FieldsetModule, ListModule } from "./features";
import { PimHomeComponent, PimHomeResolver } from "./features/home";
import { ListCascadeModule } from "./features/listcascade/listcascade.module";
import { PimComponent } from "./pim.component";

const pimRoutes: Routes = [
    {
        path: "",
        component: PimComponent,
        children: [
            { path: "", component: PimHomeComponent, resolve: { resources: PimHomeResolver } },
            { path: MAESTRO_ROUTES.pim.element, loadChildren: () => ElementModule },
            { path: MAESTRO_ROUTES.pim.elementType, loadChildren: () => ElementTypeModule },
            { path: MAESTRO_ROUTES.pim.list, loadChildren: () => ListModule },
            { path: MAESTRO_ROUTES.pim.listcascade, loadChildren: () => ListCascadeModule },
            { path: MAESTRO_ROUTES.pim.fieldset, loadChildren: () => FieldsetModule },
            { path: MAESTRO_ROUTES.pim.tag, loadChildren: () => TagModule },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(pimRoutes)],
    exports: [RouterModule],
})
export class PimRoutingModule {}
