<div class="languageTooltip pr-2">
    <button type="button" class="btn btn-primary"  (click)="openJsxModal()">
        <fa-icon [icon]="faSave"></fa-icon>
        <span class="d-inline-block ml-2" >JSX</span>
    </button>
    <span class="tooltiptext" >{{'breadcrumb.downloadChangeJsx' | translate}}</span>
</div>

<swal #downloadJsxModal [showConfirmButton]="false" title="JSX" [showCloseButton]="true">
    <div *swalPortal>
        <input type="file" accept=".jsx" id="importedFile" (change)="handleFileInput($event.target.files)" > <button class="btn btn-primary" (click)="upload()" [disabled]="uploadImage.length == 0">{{"general.updateJsx" | translate}}</button>
        <hr/>
        <button class="btn btn-primary col-4" (click)="download()">{{"general.downloadJsx" | translate}}</button>
    </div>
</swal>