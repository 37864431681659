<div class="row">
    <div class="col-md-8 mx-auto">
        <div class="card-group">
            <div class="card p-4">
                <div class="card-body">
                    <form [formGroup]="form" (ngSubmit)="change()">
                        <h1>{{ 'change_password.title' | translate }}</h1>
                        <p class="text-muted">{{ 'change_password.subtitle' | translate }}</p>

                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="password">
                                        <fa-icon [icon]="faKey"></fa-icon>
                                    </label>
                                </div>
                                <input type="password" id="password" formControlName="password" class="form-control" placeholder="{{ 'login.password.field' | translate }}" [ngClass]="{ 'is-invalid': formControls.password.dirty && formControls.password.errors }"/>
                                <div *ngIf="formControls.password.dirty && formControls.password.errors" class="invalid-feedback">
                                    <div *ngIf="formControls.password.errors.required">
                                        {{ 'general.required' | translate }}
                                    </div>
                                    <div *ngIf="formControls.password.errors.invalidPassword">
                                        {{ 'login.password.error.invalid' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="confirmPassword">
                                        <fa-icon [icon]="faKey"></fa-icon>
                                    </label>
                                </div>
                                <input type="password" id="confirmPassword" formControlName="confirmPassword" class="form-control" placeholder="{{ 'login.password.confirm.field' | translate }}" [ngClass]="{ 'is-invalid': formControls.confirmPassword.dirty && formControls.confirmPassword.errors }"/>
                                <div *ngIf="formControls.confirmPassword.dirty && formControls.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="formControls.confirmPassword.errors.required">
                                        {{ 'general.required' | translate }}
                                    </div>
                                    <div *ngIf="formControls.confirmPassword.errors.mustMatch">
                                        {{ 'login.password.confirm.error' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <button type="submit" class="btn btn-primary px-4" [disabled]="form.invalid">{{ 'general.send' | translate }}</button>
                            </div>
                            <div class="col-6 text-right">
                                <a class="btn btn-link px-0" [routerLink]="['/', routes.public.password.base]">{{ 'login.password.forget' | translate }}</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
