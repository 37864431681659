import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { MAESTRO_ROUTES } from "app/shared/routes";

@Component({
    template: "<router-outlet></router-outlet>",
})
export class ProjectComponent implements OnInit {
    constructor(route: ActivatedRoute, private _layout: LayoutService, private _projectService: ProjectService) {
        const project = route.snapshot.data.project;

        _projectService.withProduct = project.data.productsImport;
        
        this._layout.breadcrumb.setPath(
            {
                routerLink: `/${MAESTRO_ROUTES.projects.base}/${project.data.id}/${MAESTRO_ROUTES.projects.dashboard}`,
                name: project.data.title,
            },
            1
        );
    }

    ngOnInit() {}
}
