<ng-container *ngIf="options && optionsInput">
    <div class="row">
        <div class="col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div class="bg-light border border-dashed border-secondary py-3 text-center justify-content-center" [ngxDragAndDrop]="options" *ngxInputFile="optionsInput">
                <fa-icon class="mx-auto font-5xl text-primary" [icon]="faUpload"></fa-icon>
                <div class="font-weight-bold text-center">{{ 'upload.file' | translate }}</div>
            </div>


            <div class="card table-responsive">
                <h3 class="text-center mt-1">
                    <span *ngIf="uploader.queue.length === 1">{{ 'upload.queue' | translate }} - {{ uploader.queue.length }} {{ 'upload.item' | translate }}</span>
                    <span *ngIf="uploader.queue.length > 1">{{ 'upload.queue' | translate }} - {{ uploader.queue.length }} {{ 'upload.items' | translate }}</span>
                    <span *ngIf="uploader.queue.length === 0">{{ 'upload.queue' | translate }} </span>
                </h3>

                <table class="table table-striped border-bottom m-0 text-center">
                    <thead>
                    <tr>
                        <th>{{ 'upload.name' | translate }}</th>
                        <th>{{ 'upload.size' | translate }}</th>
                        <th>{{ 'upload.progress' | translate }}</th>
                        <th>{{ 'general.action' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let itemFile of uploader.queue" [ngClass]="{ 'table-success': itemFile.isSuccess, 'table-danger': itemFile.isError }">
                        <td>{{ itemFile.file.name }}</td>
                        <td>{{ itemFile.file.size / 1024 / 1024 | number: '1.0-2' }} MB</td>
                        <td>
                            <div>
                                <ngb-progressbar type="success" showValue="true" [striped]="true" [animated]="true" [value]="itemFile.progress"></ngb-progressbar>
                            </div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-danger btn-sm" (click)="itemFile.remove()" [disabled]="itemFile.isSuccess || itemFile.uploadInProgress">
                                {{ 'general.remove' | translate }}
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="uploader.queue.length === 0">
                        <td class="font-weight-bold text-center" colspan="4">{{ "general.no.data" | translate }}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="mx-1 mt-3" *ngIf="acl.MAESTRO_DAM_PICTO_CREATE === 1">
                    <table>
                        <tr>
                            <td>
                                <label class="font-weight-bold">{{ "upload.picto" | translate }}</label>
                            </td>
                            <td class="pt-1 pl-2">
                                <label class="switch switch-pill switch-primary">
                                    <input id="picto" type="checkbox" class="switch-input">
                                    <span class="switch-slider"></span>
                                </label>
                            </td>
                        </tr>
                        <tr *ngIf="claroAvailable">
                            <td>
                                <label class="font-weight-bold">{{ "upload.claro" | translate }}</label>
                            </td>
                            <td class="pt-1 pl-2">
                                <label class="switch switch-pill switch-primary">
                                    <input id="claro" type="checkbox" class="switch-input">
                                    <span class="switch-slider"></span>
                                </label>
                            </td>
                        </tr>
                    </table>
                    
                </div>

                <button [disabled]="this.uploader.queue.length === 0" type="button" class="btn btn-primary m-1" (click)="upload()">
                    {{ 'upload.all' | translate }}
                </button>

                <a class="btn btn-outline-primary m-1" [routerLink]="['/' + routes.dam.base]">

                    {{ 'upload.backToDam' | translate }}
                </a>
            </div>
        </div>
    </div>
</ng-container>

