import { Injectable } from "@angular/core";
import { CanActivate, Resolve, UrlTree } from "@angular/router";
import { UserService } from "app/core/services/admin/user/user.service";
import { ACL } from "app/shared/models/acl";
import { Observable } from "rxjs";
import { DamWorkflowService } from "../../../../../../core/services/admin/workflow/dam-workflow.service";

@Injectable()
export class DamWorkflowListGuard implements CanActivate, Resolve<any> {
    constructor(private _service: DamWorkflowService, private _userService: UserService) {}

    resolve() {
        return this._service.getAll();
    }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return (this._userService.getUserAclFromToken() as ACL).MAESTRO_ADMIN_WORKFLOW_LIST === 1;
    }
}
