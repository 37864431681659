import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ElementsConfigurationComponent } from "./elements.component";

@NgModule({
    declarations: [ElementsConfigurationComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [ElementsConfigurationComponent],
})
export class ElementsConfigurationModule {}
