<!-- <div class="card mb-2 p-0">
    <div class="card-body p-0">
        <form [formGroup]="formArray">
            <table class="table table-striped mb-0">
                <thead>
                    <tr>
                        <th style="border: 0;" class="text-center" scope="col">{{ "pim.lists.value" | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ "projects.category" | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ "projects.fieldType" | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ "table.actions" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let control of formArray.controls; let i = index">
                        <td class="col-6">
                            <input class="form-control" [ngClass]="control.controls.name.valid ? 'is-valid' : 'is-invalid'" type="text" [formControl]="control.controls.name">
                            <div *ngIf="control.controls.name.invalid && !control.controls.name.pristine" class="invalid-feedback">
                                <span *ngIf="control.controls.name.hasError('required')">{{ "pim.lists.error.required" | translate }}</span>
                                <span *ngIf="control.controls.name.hasError('uniqueValue')">{{ "pim.lists.error.unique" | translate }}</span>
                            </div>
                        </td>
                        <td class="col-2">
                            <select class="form-control" [formControl]="control.controls.categorie">
                                <option value="variable">Variable</option>
                                <option value="input">Champs de Collection</option>
                                <option value="option">Champs Option</option>
                                <option value="media">Position Photo</option>
                            </select>
                        </td>
                        <td class="col-2">
                            <select *ngIf="control.value.categorie != 'media'" class="form-control" [formControl]="control.controls.fieldType">
                                <option value=""></option>
                                <option *ngFor="let fieldType of fieldTypes; let i = index" [value]="fieldType">{{ "fieldType." + fieldType | translate}}</option>
                            </select>
                        </td>
                        <td class="d-flex justify-content-center">
                            <button class="btn btn-danger" (click)="deleteField(i)">
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="formArray.controls.length === 0">
                        <td colspan="2" class="text-center font-weight-bold">{{ "general.no.data" | translate}}</td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
    <div class="card-footer">
        <div class="row">
            
            <div class="btn-group">
                <button class="btn btn-primary" (click)="addField()">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.field.add' | translate }}</span>
                </button>
                <button [disabled]="formArray.invalid || formArray.controls.length === 0 || formArray.pristine" class="btn btn-primary" (click)="saveList()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
 -->
 <div class="tableComponent row">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-md-12">
                <app-datatable
                    tableTitle="{{ 'model.variable' | translate }}"
                    [actions]="actions"
                    (action)="onActionClick($event)"
                    searchDataParameter="variable"
                    (lazyEvent)="loadList($event)"
                    [tableRows]="tableRows"
                    [tableColumns]="tableColumns"
                    [totalRecords]="totalRecords"
                    [recordsFiltered]="recordsFiltered"
                    [globalFilterFields]="globalFilterFields"
                    [_selectedColumns]="_selectedColumns"
                    [filters]="allFilters"
                    [shortPaginatorIsFirstPage]="shortPaginatorIsFirstPage"
                    [shortPaginatorIsLastPage]="shortPaginatorIsLastPage"
                    (shortPaginatorPrev)="shortPaginatorPrev()"
                    (shortPaginatorReset)="shortPaginatorReset()"
                    (shortPaginatorNext)="shortPaginatorNext()"
                    [first]=currentDatatableFilters.first
                    [showAdvancedSearch]="true"
                    (changeAdvancedSearch)="changeAdvancedSearch($event)"
                    >
                </app-datatable>
            </div>
        </div>
    </div>
</div>

<swal #swalForm title="{{ 'model.variable' | translate }}" confirmButtonText="{{ 'general.save' | translate }}" (preconfirm)="saveList()" (confirm)="saveList()" [width]="'95%'">
    <form *swalPortal [formGroup]="formVariable">
        <div class="modal-body">
            <div *ngIf="!variableId" class="alert alert-info alert-dismissible fade show col" role="alert">
                <i class="pi pi-info-circle"></i> {{ _translate.instant('tags.separator', { data: 'variable' }) }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="form-group row">
                <label class="col-12 col-form-label text-left">
                    {{ 'table.name' | translate }}
                </label>
                <div class="col-12">
                    <p-chips *ngIf="!variableId"  class="p-fluid" formControlName="name" allowDuplicate="false"></p-chips>
                    <input class="form-control" *ngIf="variableId" type="text" formControlName="name">
                </div>
                <label class="col-12 col-form-label text-left">
                    {{ 'table.categorie' | translate }}
                </label>
                <div class="col-12">
                    <select formControlName="categorie" class="form-control">
                        <option value="variable">{{ 'dataview.variable' | translate }}</option>
                        <option value="input">{{ 'dataview.input' | translate }}</option>
                        <option value="option">{{ 'dataview.option' | translate }}</option>
                        <option value="media">{{ 'dataview.media' | translate }}</option>
                    </select>
                </div>
                <label class="col-12 col-form-label text-left">
                    {{ 'table.fieldType' | translate }}
                </label>
                <div class="col-12">
                    <select [attr.disabled]="formVariable.get('categorie').value === 'media' ? true : null" formControlName="fieldType" class="form-control">
                        <option *ngFor="let fieldType of fieldTypes; let i = index" [value]="fieldType">{{ "fieldType." + fieldType | translate }}</option>
                    </select>
                </div>
            </div>
        </div>
    </form>
</swal>
