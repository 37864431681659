import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { CropperComponent } from "./cropper.component";

@NgModule({
    declarations: [CropperComponent],
    exports: [CropperComponent],
    imports: [SharedModule],
})
export class CropperModule {}
