import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faAlignJustify, faColumns, faTable, faTh } from "@fortawesome/free-solid-svg-icons";
import { ViewButtonDef, ViewButtonsDef, ViewType } from "./view";

@Component({
    selector: "app-view-buttons",
    templateUrl: "./view-buttons.component.html",
})
export class ViewButtonsComponent implements OnInit {
    @Output() view: EventEmitter<ViewButtonDef> = new EventEmitter();
    @Input() defaultView: ViewButtonDef;
    @Input() views: ViewButtonsDef = [];
    @Input() entity: string;
    @Input() buttonOutlined = false;

    viewType = ViewType;
    selected: ViewButtonDef;
    /**
     * Font Awesome
     */
    readonly faTh = faTh;
    readonly faAlignJustify = faAlignJustify;
    readonly faColumns = faColumns;
    readonly faTile = faTable;

    constructor() {}

    ngOnInit() {
        this.selected = this.defaultView;
    }

    onButtonClick(view: ViewButtonDef) {
        this.selected = view;
        this.view.emit(view);
    }
}
