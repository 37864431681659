import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { AccordionModule } from "primeng-lts/accordion";
import { CardModule } from "primeng-lts/card";
import { InputTextModule } from "primeng-lts/inputtext";
import { PickListModule } from "primeng-lts/picklist";
import { TooltipModule } from "primeng-lts/tooltip";
import { ChartModule } from "../charts/chart.module";
import { ListModule } from "../list/list.module";
import { DashboardViewComponent } from "./dashboard-view.component";

@NgModule({
    imports: [ChartModule, ListModule, SharedModule, TooltipModule, PickListModule, CardModule, AccordionModule, InputTextModule],
    declarations: [DashboardViewComponent],
})
export class DashboardViewModule {}
