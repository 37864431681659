import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { FlatplanViewService } from "app/core/services/project/export/flatplan-view.service";
import { MaestroExport } from "app/shared/models";
import { FlatplanResources } from "app/shared/models/project/flatplan-resources";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class FlatplanViewResolver implements Resolve<FlatplanResources> {
    constructor(private _service: FlatplanViewService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<FlatplanResources> {
        const exportData: MaestroExport = route.parent.data.export;
        const version = route.queryParams.version;

        return forkJoin([this._service.getExportElements(exportData.id), this._service.getCdfId(exportData.id)]).pipe(
            map(
                (responses) =>
                    <FlatplanResources>{
                        elements: responses[0].data,
                        export: exportData,
                        idCdf: responses[1].data.id,
                        version: version,
                    }
            )
        );
    }
}
