import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { TemplateCreateComponent } from "./template-create.component";

@NgModule({
    declarations: [TemplateCreateComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [TemplateCreateComponent],
})
export class TemplateCreateModule {}
