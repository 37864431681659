import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { MaestroHeaderComponent } from "./maestro-header.component";
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [MaestroHeaderComponent],
    exports: [MaestroHeaderComponent],
    imports: [SharedModule,NgbAlertModule],
})
export class MaestroHeaderModule {}
