import { Pipe, PipeTransform } from "@angular/core";
import { ITreeOptions } from "@circlon/angular-tree-component";

import { SidebarTreeNodeOptions, ITreeNodeItem } from "./sidebar-tree-node";

@Pipe({
    name: "sidebarTreeNode",
})
export class SidebarTreeNodePipe implements PipeTransform {
    transform(value: SidebarTreeNodeOptions): SidebarTreeNodes {
        return value.map(
            (option) =>
                <SidebarTreeNode>{
                    ...option,
                    config: this._setConfig(option.click),
                    total: this._computeTagLength(option.data),
                }
        );
    }

    /**TODO: makepipe */
    private _computeTagLength(items): number {
        let total = 0;
        if (items) {
            items.forEach((item) => (total += this._computeTagLength(item.children)));
            total += items.length;
        }
        return total;
    }

    private _setConfig(click: any): ITreeOptions {
        return {
            actionMapping: {
                mouse: {
                    click: (tree, node, $event) => click(node),
                },
            },
        };
    }
}

interface SidebarTreeNode extends ITreeNodeItem {
    total: number;
    config: ITreeOptions;
}
declare type SidebarTreeNodes = SidebarTreeNode[];
