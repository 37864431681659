<div *ngIf="forElementMedia" class="rounded-lg">
    <div class="bg-brand p-2 rounded-top d-flex navbar">
        <span class="text-white h4 m-0">{{ 'pim.media' | translate }}</span>
        <!-- @TODO: See if we keep this kind of search or not -->
        <!--<div class="col-sm-4 input-group">
            <input class="form-control" debouncetime="1500" placeholder="{{ 'general.search' | translate }}" type="text"
                (keyup)="searchLinkMedias($event.target.value)" />
            <div class="input-group-append">
                <div class="input-group-text">
                    <fa-icon class="ng-fa-icon" [icon]="faSearch">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search"
                            class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                            </path>
                        </svg>
                    </fa-icon>
                </div>
            </div>
        </div>-->
    </div>
    <div class="bg-white p-2">
        <app-dataview *ngIf="mediasLinkFiltered.length" titleNotTooltip="pim.medias" [filtered]="true" type="pim"
            [dataviewContent]="mediasLinkFiltered" [buttons]="buttons" (unlinked)="unlinkMedia($event)" [lazy]="false"
            [events]="eventsSubject.asObservable()" (filterExpireMedia)="changeHideExpireMedia($event)"
            (reorderMedia)="reorderMediaPosition($event)">
        </app-dataview>
        <span *ngIf="!mediasLinkFiltered.length">{{ 'general.no.data' | translate }}</span>
    </div>
</div>

<div *ngIf="!forElementMedia" class="bg-white p-2">
    <button class="btn btn-sm btn-primary" (click)="openDamModal()"
        [disabled]="acl && !acl.SYSPAD_PIM_MEDIA_CREATE"><fa-icon [icon]="faPlusSquare"></fa-icon>
        {{ 'breadcrumb.pim.media.add' | translate }}</button>
    <div class="row">
        <p-panel class="col-11" header="{{ mediasLinkFiltered.length + ' media(s)' }}"
            [toggleable]="!mediasLinkFiltered.length ? false : true" [collapsed]="true">
            <app-dataview *ngIf="mediasLinkFiltered.length" titleNotTooltip="pim.medias" [filtered]="true" type="pim"
                [dataviewContent]="mediasLinkFiltered" [buttons]="buttons" (unlinked)="unlinkMedia($event)"
                [lazy]="false" [events]="eventsSubject.asObservable()"
                (filterExpireMedia)="changeHideExpireMedia($event)" (reorderMedia)="reorderMediaPosition($event)">
            </app-dataview>
            <span *ngIf="!mediasLinkFiltered.length">{{ 'general.no.data' | translate }}</span>
        </p-panel>
    </div>
    <!-- @TODO: Instead of display dataview in panel display reorder component ? foreach media type ?-->
</div>

<swal #createModal [width]="'90%'" confirmButtonText="{{ 'modal.link' | translate }}" (confirm)="addSelectedMedias()"
    [showConfirmButton]="false">
    <div *swalPortal class="modal-body">
        <p-tabView [(activeIndex)]="tabIndex">
            <p-tabPanel header="{{ 'modal.media' | translate }}">
                <div *ngIf="filters.tags.length" class="tags mb-1">
                    <span class="mr-1">{{ 'filter.tag' | translate }} : </span>

                    <span *ngFor="let tag of filters.tags" class="badge badge-pill badge-primary mr-1">
                        <span class="mr-1">{{ tag.name }}</span>
                        <span class="pointer" (click)="manageFilter(tag, false)">x</span>
                    </span>
                </div>
                <div *ngIf="filters.type.length" class="mb-1">
                    <span class="mr-1">{{ 'filter.extension' | translate }} : </span>

                    <span *ngFor="let ext of filters.type" class="badge badge-pill badge-primary mr-1">
                        <span class="mr-1">{{ ext.id }}</span>
                        <span class="pointer" (click)="manageFilter(ext, false, 'type')">x</span>
                    </span>
                </div>
                <app-dataview [dataviewContent]="mediasModal" (lazyEvent)="loadList($event)" [sortOptions]="sortOptions"
                    [totalRecords]="recordsFiltered" [minified]="true" [selectable]="true" [poppedUp]="true"
                    [selectedMedias]="selectedMedias" [linkedMedias]="elementMedias"
                    (searchEvent)="loadListWithSearch($event)" (selectMedia)="selectMedia($event)"
                    (tagFiltered)="manageFilter($event)" (filterExpireMedia)="changeHideExpireMedia($event)"
                    (selectExtension)="manageFilter($event, true, 'type')"
                    [first]="currentDatatableFilters.first ? currentDatatableFilters.first : 0"
                    [singleSelection]="singleSelection" [linkAdded]="linkAdded">
                </app-dataview>
                <div class="card-footer">
                    <button class="btn btn-primary" (click)="addSelectedMedias()">{{ 'modal.link' | translate }}</button>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{ 'modal.mediaLink' | translate }}">
                <div *ngIf="mediaTypes.length" class="card-body p-0">
                    <form [formGroup]="formArray">
                        <div class="table-responsive">
                            <table class="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th style="min-width: 10rem; border: 0;" class="text-center" scope="col">{{
                                            'general.link' | translate }}</th>
                                        <th style="min-width: 10rem; border: 0;" class="text-center" scope="col">{{
                                            'general.type' | translate }}</th>
                                        <th style="min-width: 10rem; border: 0;" class="text-center" scope="col">{{
                                            'general.loadMetadata' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let control of formArray.controls; let i = index">
                                        <td style="min-width: 10rem;">
                                            <input class="form-control"
                                                [ngClass]="control.controls.link.valid ? 'is-valid' : 'is-invalid'"
                                                type="url" pattern="https://.*" placeholder="https://..."
                                                [formControl]="control.controls.link">
                                            <div *ngIf="!control.controls.link.pristine && control.controls.link.invalid"
                                                class="invalid-feedback">
                                                <span *ngIf="control.controls.link.hasError('required')">{{
                                                    "general.required" | translate }}</span>
                                            </div>
                                        </td>
                                        <td style="min-width: 10rem;">
                                            <select class="form-control"
                                                [ngClass]="control.controls.type.valid ? 'is-valid' : 'is-invalid'"
                                                [formControl]="control.controls.type">
                                                <option *ngFor="let mediaType of mediaTypes" [value]="mediaType">{{
                                                    "pim.mediasHelper." + mediaType | translate}}</option>
                                            </select>
                                            <div *ngIf="control.controls.type.invalid && !control.controls.type.pristine"
                                                class="invalid-feedback">
                                                <span *ngIf="control.controls.type.hasError('required')">{{
                                                    "general.required" | translate }}</span>
                                            </div>
                                        </td>
                                        <td style="min-width: 10rem;">
                                            <label class="switch switch-success mb-0">
                                                <input type="checkbox" class="switch-input"
                                                    [formControl]="control.controls.loadMetadata">
                                                <span class="switch-slider"></span>
                                            </label>
                                        </td>
                                        <td style="min-width: 10rem;">
                                            <button [ngbTooltip]="'tooltip.delete'| translate" class="btn btn-danger"
                                                (click)="deleteMediaLink(i)">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="btn-group ml-auto">
                            <button class="btn btn-primary" (click)="addMediaLink()"
                                [disabled]="singleSelection && (elementMedias.length || (selectedMedias.length || formArray.value.length))">
                                <fa-icon [icon]="faPlus"></fa-icon>
                                <span class="d-none d-md-inline-block mt-1 ml-2">{{ 'general.addLink' | translate
                                    }}</span>
                            </button>
                        </div>
                    </div>
                    <button class="btn btn-primary" (click)="addSelectedMedias()">{{ 'modal.link' | translate }}</button>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{ 'breadcrumb.dam.upload' | translate }}">
                <!-- <input type="file" class="custom-file-input" id="importedFile"
                    (change)="handleFileInput($event.target.files)" required>
                <label class="custom-file-label" for="importedFile">{{ "general.choose.file" | translate }}</label> -->
                <div class="bg-light border border-dashed border-secondary py-3 text-center justify-content-center"
                    [ngxDragAndDrop]="options" *ngxInputFile="optionsInput">
                    <fa-icon class="mx-auto font-5xl text-primary" [icon]="faUpload"></fa-icon>
                    <div class="font-weight-bold text-center">{{ 'upload.file' | translate }}</div>
                </div>


                <div class="card table-responsive">
                    <h3 class="text-center mt-1">
                        <span *ngIf="uploader.queue.length === 1">{{ 'upload.queue' | translate }} - {{
                            uploader.queue.length }} {{ 'upload.item' | translate }}</span>
                        <span *ngIf="uploader.queue.length > 1">{{ 'upload.queue' | translate }} - {{
                            uploader.queue.length }} {{ 'upload.items' | translate }}</span>
                        <span *ngIf="uploader.queue.length === 0">{{ 'upload.queue' | translate }} </span>
                    </h3>

                    <table class="table table-striped border-bottom m-0 text-center">
                        <thead>
                            <tr>
                                <th>{{ 'upload.name' | translate }}</th>
                                <th>{{ 'upload.size' | translate }}</th>
                                <th>{{ 'upload.progress' | translate }}</th>
                                <th>{{ 'general.action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let itemFile of uploader.queue"
                                [ngClass]="{ 'table-success': itemFile.isSuccess, 'table-danger': itemFile.isError }">
                                <td>{{ itemFile.file.name }}</td>
                                <td>{{ itemFile.file.size / 1024 / 1024 | number: '1.0-2' }} MB</td>
                                <td>
                                    <div>
                                        <ngb-progressbar type="success" showValue="true" [striped]="true"
                                            [animated]="true" [value]="itemFile.progress"></ngb-progressbar>
                                    </div>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-danger btn-sm" (click)="itemFile.remove()"
                                        [disabled]="itemFile.isSuccess || itemFile.uploadInProgress">
                                        {{ 'general.remove' | translate }}
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="uploader.queue.length === 0">
                                <td class="font-weight-bold text-center" colspan="4">{{ "general.no.data" | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mx-1 mt-3" *ngIf="acl.MAESTRO_DAM_PICTO_CREATE === 1">
                        <table>
                            <tr>
                                <td>
                                    <label class="font-weight-bold">{{ "upload.picto" | translate }}</label>
                                </td>
                                <td class="pt-1 pl-2">
                                    <label class="switch switch-pill switch-primary">
                                        <input id="picto" type="checkbox" class="switch-input">
                                        <span class="switch-slider"></span>
                                    </label>
                                </td>
                            </tr>
                            <tr *ngIf="claroAvailable">
                                <td>
                                    <label class="font-weight-bold">{{ "upload.claro" | translate }}</label>
                                </td>
                                <td class="pt-1 pl-2">
                                    <label class="switch switch-pill switch-primary">
                                        <input id="claro" type="checkbox" class="switch-input">
                                        <span class="switch-slider"></span>
                                    </label>
                                </td>
                            </tr>
                        </table>

                    </div>

                    <button [disabled]="this.uploader.queue.length === 0" type="button" class="btn btn-primary m-1"
                        (click)="upload()">
                        {{ 'upload.all' | translate }}
                    </button>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</swal>