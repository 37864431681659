import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { ListComponent } from "./list.component";

@NgModule({
    imports: [SharedModule],
    declarations: [ListComponent],
    exports: [ListComponent],
})
export class ListModule {}
