import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { DataModelRouting } from "./data-model.routing";
import { ListComponent } from "./list/list.component";
import { DataModelListResolver } from "./list/list.resolver";
import { UpdateComponent } from "./update/update.component";
import { DataModelUpdateResolver } from "./update/update.resolver";

@NgModule({
    declarations: [ListComponent, UpdateComponent],
    imports: [SharedModule, SharedComponentsModule, DataModelRouting],
    providers: [DataModelListResolver, DataModelUpdateResolver],
})
export class DataModelModule {}
