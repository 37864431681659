import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Cmyk, ColorPickerService } from "ngx-color-picker";

@Component({
    selector: "app-color-picker-maestro",
    templateUrl: "./color-picker-maestro.component.html",
    styleUrls: ["./color-picker-maestro.css"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerMaestroComponent),
            multi: true,
        },
    ],
})
export class ColorPickerMaestroComponent implements OnInit, ControlValueAccessor {
    color: string;
    public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
    cmykValue: string;
    previousColor = "";
    @Input() value = "";
    @Input() disabled: boolean = false;

    @Output() colorSelect = new EventEmitter<string>();

    private _changeCallbacks: Array<(str: string) => any> = [];
    private _touchedCallbacks: Array<(str: string) => any> = [];

    constructor(private cpService: ColorPickerService) {}

    ngOnInit() {
        try {
            let CmykFromBase = JSON.parse(this.value);
            this.cmykColor = new Cmyk(CmykFromBase.c, CmykFromBase.m, CmykFromBase.y, CmykFromBase.k, CmykFromBase.a);
            let Rgb = this.cpService.cmykToRgb(this.cmykColor);
            let hex = this.cpService.rgbaToHex(this.cpService.denormalizeRGBA(Rgb), true);
            this.color = hex;
        } catch (e) {
            this.resetColorValue();
        }
    }

    writeValue(obj: string): void {
        this.value = obj;
        this._onChange(this.value);
    }

    registerOnChange(fn: (str: string) => any) {
        this._changeCallbacks.push(fn);
    }

    registerOnTouched(fn: (str: string) => any): void {
        this._touchedCallbacks.push(fn);
    }

    private _onChange(str: string) {
        this._changeCallbacks.forEach((cb) => cb(str));
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChangeColorCmyk(color: string) {
        const hsva = this.cpService.stringToHsva(color);

        if (hsva) {
            const rgba = this.cpService.hsvaToRgba(hsva);

            return this.cpService.rgbaToCmyk(rgba);
        }

        return new Cmyk(0, 0, 0, 0);
    }

    saveField() {
        let newColor = JSON.stringify(this.cmykColor);

        if (this.previousColor !== newColor) {
            this.previousColor = newColor;
            this._onChange(newColor);

            this.colorSelect.emit(newColor);
        }
    }

    resetColorValue(emit: boolean = false) {
        this.cmykColor = new Cmyk(0, 0, 0, 0);
        let Rgb = this.cpService.cmykToRgb(this.cmykColor);
        let hex = this.cpService.rgbaToHex(this.cpService.denormalizeRGBA(Rgb));
        this.color = hex;

        if (emit) {
            this.colorSelect.emit(JSON.stringify(this.cmykColor));
        }
    }
}
