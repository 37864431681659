import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { MAESTRO_ROUTES as routes } from "app/shared/routes";
import { TemplateCreateComponent } from "./create/template-create.component";
import { TemplateCreateGuard } from "./create/template-create.guard";
import { TemplateComponent } from "./template.component";

const templateRouting: Routes = [
    {
        path: "",
        component: TemplateComponent,
        children: [
            {
                path: routes.actions.create,
                component: TemplateCreateComponent,
                canActivate: [TemplateCreateGuard],
                resolve: { resources: TemplateCreateGuard },
            },

            {
                path: `:id/${routes.actions.update}`,
                component: TemplateCreateComponent,
                canActivate: [TemplateCreateGuard],
                resolve: { resources: TemplateCreateGuard },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(templateRouting)],
    exports: [RouterModule],
})
export class TemplateRoutingModule {}
